/**
 * @typedef Patient
 * @property { number | null } id
 * @property { string } uuid
 * @property { string } name
 * @property { string } surname
 * @property { string } patronymic
 * @property { boolean } hasPatronymic
 * @property { string } birthDay
 * @property { string } fio
 * @property { string } age
 * @property { boolean } isMain
 * @property { boolean } isOther
 * */
/** @return { Patient } */
const getPatient = () => ({
  id: null,
  uuid: '',
  name: '',
  surname: '',
  patronymic: '',
  hasPatronymic: true,
  birthDay: '',
  fio: '',
  age: '',
  ageNumber: null,
  isMain: false,
  isOther: false,
  isEmptyMain: false,
})

/** @typedef { Patient[] } PatientList */
/** @return { PatientList } */
const getPatientList = () => []

export {
  getPatient,
  getPatientList,
}
