var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        value: _vm.isVisibleModal,
        fullscreen: "",
        persistent: "",
        "hide-overlay": "",
        "content-class": _vm.dialogContentClasses,
        transition: "fade-transition",
      },
    },
    [
      _c(
        "ModalLayout",
        {
          attrs: {
            "close-to": "",
            "limit-title": "",
            title: _vm.modalData.title,
            "class-body": "award-modal-mobile__body",
          },
          on: { "modal:back": _vm.handleCloseModal },
        },
        [
          _c("AwardModalContent", {
            staticClass: "p-4",
            attrs: {
              "is-dark-mode": _vm.isDarkMode,
              "modal-data": _vm.modalData,
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "award-modal-mobile__actions" },
            [
              _c(
                "VBtn",
                {
                  staticClass: "mb-2",
                  attrs: {
                    block: "",
                    elevation: "0",
                    color: _vm.isDarkMode ? "gold" : "primary",
                    dark: _vm.isDarkMode,
                  },
                  on: { click: _vm.handleClickRedirect },
                },
                [
                  _c(
                    "span",
                    { class: { "ui-text ui-kit-color-text": _vm.isDarkMode } },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.modalData.button.text) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "VBtn",
                {
                  attrs: {
                    text: "",
                    block: "",
                    color: _vm.isDarkMode ? "gold" : "black",
                    dark: _vm.isDarkMode,
                  },
                  on: { click: _vm.handleCloseModal },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "ui-text",
                      class: {
                        "ui-text_color_gold": _vm.isDarkMode,
                        "ui-kit-color-text": !_vm.isDarkMode,
                      },
                    },
                    [_vm._v("\n          Закрыть\n        ")]
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }