import { axiosClient, getCSRFToken } from 'utils'
import { AppointmentButtonStateRequestData } from 'components/common/DoctorServicePrices/types'

export function postAppointmentButtonState(data: AppointmentButtonStateRequestData[]) {
  return axiosClient({
    data,
    method: 'POST',
    url: '/ajax/services/schedule/doctors/',
    headers: {
      'X-CSRFToken': getCSRFToken(),
      'Content-Type': 'application/json',
    },
  })
}
