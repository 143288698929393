var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _c(
        "v-sheet",
        {
          staticClass: "favourite-btn d-flex align-center justify-center",
          attrs: {
            height: "32",
            width: _vm.url ? 72 : 32,
            elevation: "1",
            color: "white",
          },
        },
        [
          _vm.loading
            ? _c("v-progress-circular", {
                attrs: {
                  size: 20,
                  width: "2",
                  color: "primary",
                  indeterminate: "",
                },
              })
            : _c("span", {
                staticClass: "ui-icon_fz_medium ui-text",
                class: _vm.favouriteIconClass,
                on: { click: _vm.handleFavouriteClick },
              }),
          _vm._v(" "),
          _vm.url
            ? _c("span", {
                staticClass:
                  "ui-icon-share-circle ui-icon_fz_medium ui-text ui-kit-color-text-info ml-3",
                on: { click: _vm.handleShareClick },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }