var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VApp", { staticClass: "review-card-tooltips" }, [
    _c(
      "div",
      { staticClass: "d-flex" },
      [
        _c(
          "VMenu",
          {
            attrs: {
              "z-index": "110",
              attach: "",
              disabled: !_vm.assessmentsTransformed.length,
              "close-on-content-click": false,
              "open-on-hover": "",
              "offset-y": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b({ ref: "stars" }, "div", attrs, false),
                          on
                        ),
                        [_vm._t("stars")],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          [
            _vm._v(" "),
            _c(
              "VCard",
              { staticClass: "pa-5", attrs: { elevation: "0" } },
              _vm._l(_vm.assessmentsTransformed, function (assessment, index) {
                return _c(
                  "div",
                  { key: assessment.name, class: { "mt-4": index > 0 } },
                  [
                    _c("div", { staticClass: "text-no-wrap" }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(assessment.name) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _vm._l(assessment.filledStars, function (i) {
                          return _c(
                            "v-icon",
                            {
                              key: `filled-${i}`,
                              class: { "ml-1": i > 1 },
                              attrs: { color: "uiKitRating", small: "" },
                            },
                            [
                              _vm._v(
                                "\n              ui-icon-rating-star-filled\n            "
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm._l(assessment.outlineStars, function (i) {
                          return _c(
                            "v-icon",
                            {
                              key: `outline-${i}`,
                              staticClass: "ml-1",
                              attrs: { color: "uiKitRating", small: "" },
                            },
                            [
                              _vm._v(
                                "\n              ui-icon-rating-star-outline\n            "
                              ),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", {
                          staticClass: "text-transform-capitalize ml-2",
                          class: assessment.color,
                          domProps: { textContent: _vm._s(assessment.status) },
                        }),
                      ],
                      2
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
        _vm._v(" "),
        _vm.assessments.length ? _c("div", { staticClass: "ml-2" }) : _vm._e(),
        _vm._v(" "),
        _c(
          "VMenu",
          {
            attrs: {
              "z-index": "110",
              attach: "",
              "nudge-left": _vm.statusesTooltipOffset,
              "close-on-content-click": false,
              "open-on-hover": "",
              "offset-y": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            { on: { mouseenter: _vm.updateOffset } },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [_vm._t("statuses")],
                        2
                      ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          },
          [
            _vm._v(" "),
            _c(
              "VCard",
              {
                staticClass: "ui-kit-color-text",
                attrs: { width: "304", elevation: "0" },
              },
              [
                _c(
                  "div",
                  { staticClass: "pa-4" },
                  [
                    _c("div", { staticClass: "ui-text ui-text_subtitle-1" }, [
                      _vm._v("\n            Отзыв проверен\n          "),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.verificationStatuses, function (status) {
                      return _c(
                        "div",
                        { key: status, staticClass: "d-flex mt-2" },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(status) },
                          }),
                          _vm._v(" "),
                          _c("VSpacer"),
                          _vm._v(" "),
                          _c(
                            "VIcon",
                            { attrs: { color: "uiKitTextSuccess" } },
                            [
                              _vm._v(
                                "\n              ui-icon-check\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("VDivider"),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-center ui-text ui-text_caption ui-kit-color-text-secondary pa-4",
                  },
                  [
                    _c("VIcon", { attrs: { dense: "" } }, [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.originInfo.icon) +
                          "\n          "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-3" }, [
                      _vm._v(
                        "\n            Оставлен через " +
                          _vm._s(_vm.originInfo.text) +
                          "\n          "
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }