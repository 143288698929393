var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showBanner
        ? _c("AwardBanner", {
            class: _vm.isMobile ? "m-2" : "mb-6",
            attrs: {
              "is-visible-banner": _vm.isVisibleBanner,
              "is-dark-mode": _vm.currentContent.isDarkTheme,
              "banner-data": _vm.currentContent.banner,
            },
            on: {
              "award-banner:close": _vm.handleBannerClose,
              "award-banner:show-more": _vm.handleBannerShowMore,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(_vm.AwardModal, {
        tag: "component",
        attrs: {
          "is-visible-modal": _vm.isVisibleModal,
          "is-dark-mode": _vm.currentContent.isDarkTheme,
          "modal-data": _vm.currentContent.modal,
        },
        on: {
          "award-modal:close": function ($event) {
            _vm.isVisibleModal = false
          },
          "award-modal:click-redirect": _vm.handleModalClickRedirect,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }