var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          staticClass: "mtlink-banner",
          class: {
            "mtlink-banner_box-shadow": _vm.hasBoxShadow,
          },
          on: { click: _vm.handleOpenModal },
        },
        [
          _c("div", [
            _c("img", {
              staticClass: "mtlink-banner__medtochka-logo",
              attrs: {
                src: "/static/_v1/pd/logos/ui-kit/medtochka-logo.svg",
                alt: "Логотип МедТочки",
              },
            }),
            _vm._v(" "),
            _c("div", {
              staticClass: "ui-text ui-text_body-2",
              domProps: { textContent: _vm._s(_vm.text) },
            }),
          ]),
          _vm._v(" "),
          _vm._m(0),
        ]
      ),
      _vm._v(" "),
      _c("BannerMtLinkModal", {
        attrs: { "is-visible": _vm.isVisible },
        on: { "modal:close": _vm.handleCloseModal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-3" }, [
      _c("span", { staticClass: "ui-icon-circle-info" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }