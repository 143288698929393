import getInitialDay from 'modules/Schedule/classes/ScheduleData/functions/renderData/helpers/getInitialDay'

/**
 * @description
 * Возвращает объект Map, который используется дальше для рендера календарей для записи к врачу(на странице списка врачей и странице врача).
 * Функция возвращает список, который состоит из множества объектов рендера, или же только из одного.
 * При спецразмещении может быть несколько дубликатов карточек клиник. Сопоставление переменной 'isSingleRender' точно сообщает - в каком контейнере произошло изменение.
 * Если при клике передан parentCard и не найдено сопоставление - будет ошибка, которая ловится в вызывающем модуле.
 *
 * @param { Object } opts
 * @param { Object } opts.data - собранные данные со страницы - интерфейс 'getModuleDataInterface'
 * @param { HTMLElement } opts.parentCard - карточка в списке, в которой необходимо заменить расписание
 * @param { Array<Object> } opts.response - ответ на запрос
 * @param { String } opts.dataItem - data атрибут родителя
 *
 * @return { Map } - данные для рендера списка календарей
 * */

const getRenderDoctorsData = opts => {
  const {
    data,
    response,
    dataItem,
    parentCard,
  } = opts
  const resultData = new Map()

  data.items.forEach((pageData, scheduleContainer) => {
    let { lpuId } = pageData

    const {
      wpListId,
      doctorId,
      coverNode,
      doctorSpecialtyId,
      hasSpecialPlacement,
    } = pageData
    const scheduleDataList = wpListId.length // объекты с расписанием для всех workplace
    // Если список wpListId не пустой, значит рендер происходит на странице списка врачей, там несколько workplace.
    // На этой странице 'lpuId' заранее неизвестен, так как workplaces могут иметь разные id.
      ? response.filter(({ doctor_id: doctorIdResult }) => (
        doctorIdResult === doctorId
      ))
    // Значит рендер происходит на странице врача. На этой странице известен 'lpuId'.
    // Поэтому можно отфильтровать по 2 полям и найти сразу нужные данные, без дополнительных проверок
      : response.filter(({ doctor_id: doctorIdResult, lpu_id: lpuIdResult }) => (
        lpuId === lpuIdResult && doctorIdResult === doctorId
      ))
    const isListRender = !parentCard // рендер списка календарей
    const isSingleRender = parentCard === coverNode // рендер только 1 календаря

    if (!scheduleDataList.length) {
      return
    }

    if (!(isListRender || isSingleRender)) {
      return
    }

    scheduleDataList.forEach(scheduleData => {
      if (wpListId.length) { // Условие актуально только для списка врачей
        lpuId = scheduleData.lpu_id

        const hasScheduleDataInWpList = wpListId.includes(lpuId)
                    && Number(scheduleContainer.getAttribute('data-lpu-id')) === lpuId // ToDo schedule-important избавиться от привязки к DOM дереву

        if (!hasScheduleDataInWpList) { // Список lpu id должен совпадать с данными ответа и узел для монтирования расписания должен содержать такой же lpu id
          return
        }
      }

      const { slots } = scheduleData
      const days = Object.keys(slots)
      const daysWithSlots = days.filter(date => slots[date].length)
      const isEmptySlotList = !daysWithSlots.length

      if (isEmptySlotList) {
        return
      }

      const initDay = getInitialDay({
        daysWithSlots,
        currentSlots: slots,
      })

      resultData.set(scheduleContainer, {
        days,
        slots,
        lpuId,
        initDay,
        dataItem,
        doctorId,
        data: slots,
        options: slots,
        useRedirect: true,
        isAppointmentDoctor: true,
        doctorSpec: doctorSpecialtyId,
        specPlacement: hasSpecialPlacement,
      })
    })
  })

  return resultData
}

export default getRenderDoctorsData
