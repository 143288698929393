export const LOADING_STATE = {
  hidden: 'doctor-item-hidden',
  visible: 'doctor-item-visible',
  filtered: 'filtered',
}

export const VISIBLE_CARDS_QUANTITY = 20

export const NAMESPACE = {
  selectorScrollTo: '.b-clinic-employees',
  selectorNotFound: '.b-empty-doctors',
  selectorDoctorCard: '.b-doctor-card',
  selectorStaffEnabled: '.is-staff_employees',
  classNameNotFoundHidden: 'hidden',
  selectorMountPagination: '.b-pagination',
  selectorNavigationContainer: '.b-tab-navigation_bottom',
  classNameNavigationContainerHidden: 'doctor-item-hidden',
}

export const DOCTOR_FILTER_LIST = ['speciality', 'staj', 'gender', 'na_dom']
