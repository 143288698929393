var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { ref: "rootElement" },
    [
      _setup.filterType !== "home"
        ? [
            !_setup.isAppointmentOn ||
            _setup.lpuWithAppointmentIds.includes(_vm.lpuAddress.lpu.id)
              ? _c("div", [
                  (_vm.lpuAddress.has_slots && _vm.lpuAddress.appointment_on) ||
                  !_setup.selectedItem.isWorkplace
                    ? _c("div", { attrs: { "data-has-appointment": "" } }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _setup.isRequestSlotsDone,
                                expression: "isRequestSlotsDone",
                              },
                            ],
                          },
                          [
                            _c(_setup.PartCalendar, {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value:
                                    !_setup.isVisibleFullCalendar ||
                                    !_setup.isMobile,
                                  expression:
                                    "!isVisibleFullCalendar || !isMobile",
                                },
                              ],
                              attrs: { "lpu-id": _vm.lpuAddress.lpu.id },
                              on: {
                                "calendar-time-choice":
                                  _setup.handleCalendarTimeChoice,
                              },
                            }),
                            _vm._v(" "),
                            _setup.isVisibleFullCalendar && _setup.isMobile
                              ? _c(_setup.FullCalendar, {
                                  attrs: { "lpu-id": _vm.lpuAddress.lpu.id },
                                  on: {
                                    "calendar-time-choice":
                                      _setup.handleCalendarTimeChoice,
                                    close: _setup.handleCloseFullCalendar,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_setup.isRequestSlotsDone,
                                expression: "!isRequestSlotsDone",
                              },
                            ],
                          },
                          [
                            _c(
                              "VSheet",
                              {
                                staticClass:
                                  "d-flex align-center justify-center",
                                attrs: { height: "168" },
                              },
                              [
                                _c("VProgressCircular", {
                                  attrs: {
                                    color: "primary",
                                    size: "48",
                                    indeterminate: "",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        !_setup.isMobile &&
                        _setup.isUserStaff &&
                        _vm.lpuAddress.staff_appointment_type
                          ? _c(
                              "div",
                              { staticClass: "staff-appointment-type" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "staff-appointment-type__text",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(
                                          _vm.lpuAddress.staff_appointment_type
                                        ) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _c(
                        "div",
                        { attrs: { "data-has-appointment": "" } },
                        [
                          _setup.appointmentWillEnableTime
                            ? _c(
                                "VBtn",
                                { attrs: { color: "primary", disabled: "" } },
                                [
                                  _vm._v(
                                    "\n          Запись включится\n          "
                                  ),
                                  _c("time", [
                                    _vm._v(
                                      _vm._s(_setup.appointmentWillEnableTime)
                                    ),
                                  ]),
                                ]
                              )
                            : _setup.isAppointmentOn
                            ? _c(
                                "VBtn",
                                {
                                  attrs: {
                                    color: "primary",
                                    depressed: "",
                                    block: "",
                                  },
                                  on: {
                                    click:
                                      _setup.handleExpressAppointmentButtonClick,
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n          Запись на приём\n        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                ])
              : _vm._e(),
          ]
        : [
            typeof _vm.lpuAddress.tabs_data?.price_go === "number" &&
            !_vm.lpuAddress.appointment_direct
              ? _c(
                  "div",
                  { attrs: { "data-has-appointment": "" } },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-2 ui-kit-color-text-info mb-4",
                      },
                      [
                        _vm._v(
                          "\n        Цена зависит от удалённости клиники.\n        Уточните цену и способ оплаты во время звонка администратора.\n      "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "VBtn",
                      {
                        attrs: { color: "primary", depressed: "", block: "" },
                        on: {
                          click: _setup.handleExpressAppointmentButtonClick,
                        },
                      },
                      [
                        _c("VIcon", { staticClass: "mr-2" }, [
                          _vm._v("\n          ui-icon-car-clinic\n        "),
                        ]),
                        _vm._v(
                          "\n        Вызвать " +
                            _vm._s(
                              _setup.doctor?.is_medic ? "врача" : "специалиста"
                            ) +
                            "\n      "
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
      _vm._v(" "),
      !_setup.isMobile
        ? _c(
            "VDialog",
            {
              attrs: { width: "754" },
              model: {
                value: _setup.isVisibleFullCalendar,
                callback: function ($$v) {
                  _setup.isVisibleFullCalendar = $$v
                },
                expression: "isVisibleFullCalendar",
              },
            },
            [
              _c(
                "VCard",
                [
                  _c(
                    "VCardTitle",
                    { staticClass: "d-flex align-center px-2 py-4" },
                    [
                      _c(
                        "VIcon",
                        {
                          staticClass: "mr-8",
                          on: {
                            click: function ($event) {
                              _setup.isVisibleFullCalendar = false
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n          ui-icon-close-not-a-circle\n        "
                          ),
                        ]
                      ),
                      _vm._v("\n        Полное расписание врача\n      "),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("VDivider"),
                  _vm._v(" "),
                  _c(
                    "VCardText",
                    { staticClass: "pt-6" },
                    [
                      _c(_setup.FullCalendar, {
                        attrs: { "lpu-id": _vm.lpuAddress.lpu.id },
                        on: {
                          "calendar-time-choice":
                            _setup.handleCalendarTimeChoice,
                          close: _setup.handleCloseFullCalendar,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }