export const BLOCK_NAME = 'b-review-card'

export const CLASS_NAMES = {
  positive: `${BLOCK_NAME}_positive`,
  neutral: `${BLOCK_NAME}_neutral`,
  negative: `${BLOCK_NAME}_negative`,
  resolved: `${BLOCK_NAME}_resolved`,
  unconfirmed: `${BLOCK_NAME}_unconfirmed`,
  deleted: `${BLOCK_NAME}_deleted`,
  slide: [
    'b-swiper__slide',
    `${BLOCK_NAME}_slide`,
  ],
  modalItem: `${BLOCK_NAME}_modal-item`,
  expanded: `${BLOCK_NAME}_expanded`,
  content: `${BLOCK_NAME}__content`,
  gallery: `${BLOCK_NAME}__gallery`,
  embedImgWrapper: `${BLOCK_NAME}__embed-img-wrapper`,
  moreBtn: `${BLOCK_NAME}__more-btn`,
}
