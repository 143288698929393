import Swiper, { Navigation } from 'swiper/core'
import 'swiper/swiper-bundle.css'
import presetOptionSlider from 'blocks/library.mobile.blocks/common/b-slider/b-slider'
import { GRID_BREAKPOINT_LG } from 'constants/gridBreakpoints'

// Swiper

const containerClass = 'b-slider_js'
const container = document.querySelector(`.${containerClass}`)

if (container) {
  const slides = container.getElementsByClassName('b-slider__slide_js')
  const screenWidth = document.documentElement.clientWidth
  const slidesView = screenWidth < GRID_BREAKPOINT_LG ? 4 : 5

  if (slides.length > slidesView) {
    Swiper.use([Navigation])

    const sliderOptions = Object.assign(presetOptionSlider, {
      containerModifierClass: containerClass,
      spaceBetween: 24,
      slidesPerView: 5.3,
      slidesOffsetBefore: 24,
      slidesOffsetAfter: 24,
      breakpoints: {
        [GRID_BREAKPOINT_LG]: {
          slidesPerView: 4.28,
        },
      },
    })

    new Swiper(container, sliderOptions)
  } else {
    container.classList.add('b-medals__container_no-swiper')
  }
}
