import { format } from 'date-fns'
import { ru } from 'date-fns/locale'

export const MOUNT_SELECTOR = '.vue-award-mount'

/* TIME_DEATH_DAY - время, на которое должен исчезнуть баннер после закрытия */
export const TIME_DEATH_DAY = 1 / 2 // половина дня, или 12 часов

export const IMAGE_AWARD_PREMIUM = {
  src: '/static/_v1/pd/pictures/award/award.jpg',
  alt: 'Премия ПроДокторов',
}

export const IMAGE_AWARD_GRAND_PRIX = {
  src: '/static/_v1/pd/pictures/award/grand-prix.jpg',
  alt: 'Гран-при премии ПроДокторов',
}

const EVENT_YEAR = 2023

export const EVENT_DATES = {
  awardAnnounce: new Date(EVENT_YEAR, 9, 20), // 20 окт. - анонс премии
  awardResults: new Date(EVENT_YEAR, 10, 1), // 1 ноя. - результаты премии
  grandRegistration: new Date(EVENT_YEAR, 10, 7), // 7 ноя. - регистрация на Гран-при
  grandTranslation: new Date(EVENT_YEAR, 10, 18), // 18 ноя. - трансляция Гран-при на YouTube
  grandResults: new Date(EVENT_YEAR, 10, 19), // 19 ноя. - результаты Гран-при
  showOver: new Date(EVENT_YEAR, 11, 1), // 1 дек. - плашка скрывается
}

export const EVENT_DATE_TEXTS = {
  awardResults: format(EVENT_DATES.awardResults, 'd MMMM', { locale: ru }), // 1 ноября - результаты премии
  grandTranslation: format(EVENT_DATES.grandTranslation, 'd MMMM', { locale: ru }), // 18 ноября - трансляция Гран-при на YouTube
}

export const CONTENT_BY_DATE = {
  [EVENT_DATES.awardAnnounce]: {
    isDarkTheme: false,
    banner: {
      title: `Всероссийская премия ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Премия ПроДокторов ${EVENT_YEAR}`,
      image: IMAGE_AWARD_PREMIUM,
      events: [
        {
          isIconCheckCircle: false,
          title: 'Всероссийская премия ПроДокторов',
          date: `${EVENT_DATE_TEXTS.awardResults}, 18:00 Мск`,
          paragraphs: [
            'Определим звёзд медицины за этот год. Назовём лучших врачей и лучшие клиники по отзывам пациентов в каждом регионе.',
          ],
        },
        {
          isIconCheckCircle: false,
          title: 'Гран-при премии ПроДокторов',
          date: EVENT_DATE_TEXTS.grandTranslation,
          paragraphs: [
            `Определим номинантов на звание лучших врачей всей страны
                         и лучших клиник каждого округа, Москвы и Санкт-Петербурга.
                         Встретимся на\u00A0МедРокет days’23, чтобы назвать победителей и\u00A0вручить награды.`,
          ],
        },
      ],
      button: {
        text: 'На сайт премии',
        href: 'https://award.prodoctorov.ru/',
      },
    },
  },

  [EVENT_DATES.awardResults]: {
    isDarkTheme: false,
    banner: {
      title: `Итоги Всероссийской премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Премия ПроДокторов ${EVENT_YEAR}`,
      image: IMAGE_AWARD_PREMIUM,
      events: [
        {
          isIconCheckCircle: true,
          date: `${EVENT_DATE_TEXTS.awardResults}, 18:00 Мск`,
          paragraphs: [
            'Назвали лучших врачей и лучшие клиники по\u00A0отзывам пациентов в каждом регионе.',
          ],
        },
      ],
      button: {
        text: 'Смотреть результаты',
        href: `https://award.prodoctorov.ru/awards/${EVENT_YEAR}/`,
      },
    },
  },

  [EVENT_DATES.grandRegistration]: {
    isDarkTheme: true,
    banner: {
      title: `Гран-при премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Гран-при премии ${EVENT_YEAR}`,
      image: IMAGE_AWARD_GRAND_PRIX,
      events: [
        {
          isIconCheckCircle: false,
          date: `${EVENT_DATE_TEXTS.grandTranslation}, 17:30 Мск`,
          paragraphs: [
            `Определим номинантов на звание лучших врачей всей страны
                         и лучших клиник каждого округа, Москвы и Санкт-Петербурга.
                         Встретимся на\u00A0МедРокет days’23, чтобы назвать победителей и\u00A0вручить награды.`,
            'Ставьте напоминание, чтобы\u00A0первыми узнать имена победителей.',
          ],
        },
      ],
      button: {
        text: 'Установить напоминание',
        href: 'https://www.youtube.com/watch?v=wvMBjwNG6A4',
      },
    },
  },

  [EVENT_DATES.grandTranslation]: {
    isDarkTheme: true,
    banner: {
      title: `Прямая трансляция Гран-при премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Гран-при премии ${EVENT_YEAR}`,
      image: IMAGE_AWARD_GRAND_PRIX,
      events: [
        {
          isIconCheckCircle: true,
          date: 'Проходит прямо сейчас',
          paragraphs: [
            `Определим номинантов на звание лучших врачей всей страны
                         и лучших клиник каждого округа, Москвы и Санкт-Петербурга.
                         Встретимся на\u00A0МедРокет days’23, чтобы назвать победителей и\u00A0вручить награды.`,
          ],
        },
      ],
      button: {
        text: 'Смотреть трансляцию',
        href: 'https://www.youtube.com/watch?v=wvMBjwNG6A4',
      },
    },
  },

  [EVENT_DATES.grandResults]: {
    isDarkTheme: true,
    banner: {
      title: `Итоги Гран-при премии ПроДокторов ${EVENT_YEAR}`,
    },
    modal: {
      title: `Гран-при премии ${EVENT_YEAR}`,
      image: IMAGE_AWARD_GRAND_PRIX,
      events: [
        {
          isIconCheckCircle: true,
          date: EVENT_DATE_TEXTS.grandTranslation,
          paragraphs: [
            'Назвали лучших врачей всей страны и лучшие клиники каждого округа, Москвы и Санкт-Петербурга.',
          ],
        },
      ],
      button: {
        text: 'Смотреть результаты',
        href: `https://award.prodoctorov.ru/grand-prix/${EVENT_YEAR}/`,
      },
    },
  },
}
