var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: {
        "data-qa": "service_synonym_manage_slots",
        "data-synonym-id": _vm.selectedSynonym.id,
        "data-synonym-type-id": _vm.selectedSynonym.dtypeId,
      },
    },
    [
      _vm.synonymPreview.id
        ? _c("SynonymList", {
            attrs: {
              "lpu-id": _vm.lpuId,
              "is-used-club": _vm.isUsedClub,
              "class-id": _vm.dclassId,
              "category-id": _vm.dcategoryId,
              "type-id": _vm.dtypeId,
              "class-translit": _vm.dclassTranslit,
              "init-selected-synonym": _vm.synonymPreview,
            },
            on: { "synonym:change": _vm.handleChangeSynonym },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }