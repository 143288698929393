import BrowserTab from 'modules/BrowserTab'

let timerId = null
let instanceBrowserTab = null
let callCallbackByTimeout = () => {}

function initBrowserTab() {
  if (instanceBrowserTab) {
    return
  }

  instanceBrowserTab = new BrowserTab({
    useStorage: false,
    // Если пользователь перешёл на другую вкладку, значит элемент пропал из области видимости и callback не должен выполниться
    hidden: () => clearTimeout(timerId),
    // Если пользователь вернулся на текущую вкладку, то заново запускаем таймер
    visible: () => callCallbackByTimeout(),
  })
}

/**
 * Выполняет `${callback}`-функцию, когда `${percent}` процентов элемента
 * находится в области видимости `${viewingTime}` миллисекунд
 *
 * @param { Function } callback
 * @param { Number } percents
 * @param { Number } viewingTime
 *
 * @return { IntersectionObserver }
 */
function viewedNodeHandler(callback, percents, viewingTime) {
  initBrowserTab()

  return new IntersectionObserver((entries, observer) => {
    entries.forEach(entry => {
      // Если элемент отображён в области видимости
      if (entry.isIntersecting) {
        callCallbackByTimeout = () => {
          // Через viewingTime миллисекунд вызываем callback если элемент всё ещё был в области видимости
          timerId = setTimeout(() => {
            callCallbackByTimeout = () => {}
            observer.unobserve(entry.target)
            callback()
          }, viewingTime)
        }

        callCallbackByTimeout()
      } else {
        callCallbackByTimeout = () => {}
        clearTimeout(timerId)
      }
    })
  }, { threshold: percents / 100 })
}

export default viewedNodeHandler
