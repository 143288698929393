import ScheduleCore from 'modules/Schedule/classes/ScheduleCore'
import ScheduleLpuPreloader from 'modules/Schedule/classes/ScheduleLpuPreloader'
import { SCHEDULE_EVENTS, PAGE_TYPE_DATA } from 'modules/Schedule/constants'
import { scheduleRequest } from 'modules/Schedule/api'
import { getLpuRequestData, setAppointmentTypeStaff } from 'modules/Schedule/functions'
import { specialtiesRequest } from 'modules/Schedule/classes/ScheduleLpu/api'
import { renderSpecialities } from 'modules/Schedule/classes/ScheduleLpu/functions'

/**
 * @description
 * Модуль наследуется от базового и используется на страницах.
 * Его задача рендерить расписание на странице списка клиник и иных мест, где используется запрос за расписанием с выбором специальности врача.
 * Общие задачи модуля:
 * 1. Формирование данных для запроса => запрос => отрисовка расписания для списка клиник/1 клиники.
 * 2. Манипуляция прелоадерами, пока расписания нет на странице.
 * 3. Формировать данные для запроса за специальностями клиники, а также рендерить их.
 * */
class ScheduleLpu extends ScheduleCore {
  #preloaderInstance = new ScheduleLpuPreloader()

  /**
     * @description
     * Во время исполнения конструктора определяется поле 'pageType', которое нужно дла разграничения общих функций.
     * Навешивает обработчик на событие обновления выбранной специальности, и производится запрос за специальностями.
     * */
  constructor(opts) {
    opts.pageType = PAGE_TYPE_DATA.lpu

    super(opts)

    if (!this.validator({ getter: 'testPageData' })) {
      this.#preloaderInstance.hide()
      return
    }

    window.addEventListener(SCHEDULE_EVENTS.optionClick, this.optionClickEventListener.bind(this))

    this._requestSpecialties()
  }

  /**
     * @public
     * @description
     * Используется во время мутаций DOM дерева.
     * Поскольку дерево мутирует(меняется HTML во время фильтрации, пагинации) - меняются клиники, значит необходимо обновить у них расписание.
     * Собираются заново данные со страницы и производится запрос за специальностями, а также их рендер.
     * */
  init() {
    super.init()

    if (!this.validator({ getter: 'testPageData' })) {
      this.#preloaderInstance.hide()
      return
    }

    this._requestSpecialties()
  }

  /**
     * @public
     * @description
     * Срабатывает во время неудачного рендера расписания или ошибки.
     * */
  hookRenderError() { this.#preloaderInstance.hide() }

  /**
     * @public
     * @description
     * Срабатывает во время успешного рендера расписания.
     * */
  hookRenderSuccess() { this.#preloaderInstance.hide() }

  /**
     * @public
     * @description
     * Обрабатывает выбор специальности в клинике.
     * Формируются данные для запроса за расписанием и рендерится расписание.
     * Поле 'parentCard' используется как узел, в котором необходимо произвести замену расписания.
     * Иначе в случае дублирования карточек клиники на 1 странице можно отрендерить расписание не в нужной карточке.
     *
     * @param { Object } opts
     * @param { Object } opts.detail - данные выбранной специальности
     * @param { HTMLElement } opts.detail.target - узел, на котором произошло событие клика
     * @param { Number } opts.detail.lpuId - выбранный id клиники
     * @param { Object } opts.detail.option - данные выбранной специальности(id, name и тд)
     * */
  optionClickEventListener({ detail: { target, lpuId, option } }) {
    try {
      const parentCard = target?.closest(`[${this.options.dataItem}]`) // '?.' на случай если target не передан или не определен

      this.updateRequestData({
        requestData: getLpuRequestData({
          data: this.data,
          specialtiesData: { [lpuId]: [option] },
        }),
      })

      setAppointmentTypeStaff({ coverNode: parentCard, option })

      if (!this.validator({ getter: 'testRequestData' })) {
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(response => { this.resolveRequest(response, parentCard) })
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @private
     * @description
     * Производит запрос за специальностями.
     * */
  _requestSpecialties() {
    const { lpusId } = this.data.storage

    if (!lpusId.length) {
      this.#preloaderInstance.hide()
      return
    }

    specialtiesRequest({ lpusId })
      .then(this._resolveRequestSpecialties.bind(this))
      .catch(this._rejectRequestSpecialties.bind(this))
  }

  /**
     * @private
     * @description
     * Обрабатывает ответ на запрос за специальностями.
     * Рендерит список специальностей, собирает данные для запроса за расписанием, делает запрос и рендерит расписание.
     *
     * @param { Object } opts
     * @param { Object } opts.specialities_by_lpu - объект специальностей в формате { [lpuId]: [{}, {}, ...] }
     * */
  _resolveRequestSpecialties({ specialities_by_lpu: response }) {
    try {
      renderSpecialities({
        response,
        items: this.data.items,
        storage: this.data.storage,
      })

      this.updateRequestData({
        requestData: getLpuRequestData({
          data: this.data,
          specialtiesData: response,
        }),
      })

      if (!this.validator({ getter: 'testRequestData' })) {
        this.#preloaderInstance.hide()
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(this.resolveRequest.bind(this))
        .catch(this.rejectRequest.bind(this))
    } catch (error) {
      this.#preloaderInstance.hide()
      this.createNotice(error)
    }
  }

  /**
     * @private
     * @description
     * Обрабатывает неудачные исходы запроса за специальностями.
     * */
  _rejectRequestSpecialties(error) {
    this.#preloaderInstance.hide()
    this.createNotice(error)
  }
}

export default ScheduleLpu
