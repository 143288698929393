<template>
  <VApp>
    <VDialog
      :value="isVisible"
      fullscreen
      persistent
      hide-overlay
      transition="fade-transition"
      class="mtlink-modal"
    >
      <ModalLayout
        ref="modalLayout"
        close-to
        hide-dividers
        class="mtlink-modal__layout"
        class-body="d-flex flex-direction-column"
        @modal:back="handleCloseModal"
      >
        <div class="mtlink-modal__slider">
          <BannerMtLinkSlider />
        </div>
        <div class="mtlink-modal__actions">
          <button
            class="b-button b-button_full-width b-button_blue mb-2"
            @click="handleInstallApp"
          >
            <span class="ui-text ui-text_button">
              Установить приложение
            </span>
          </button>
          <button
            class="b-button b-button_full-width b-button_text b-button_blue"
            @click="handleCloseModal"
          >
            <span class="ui-text ui-text_button">
              Закрыть
            </span>
          </button>
        </div>
      </ModalLayout>
    </VDialog>
  </VApp>
</template>

<script>
import YaGoals from 'modules/YandexGoals'
import { VDialog } from 'vuetify/lib'
import ModalLayout from 'components/mobile/core/ModalLayout'
import BannerMtLinkSlider from 'components/common/BannerMtLink/components/common/BannerMtLinkSlider'

export default {
  name: 'BannerMtLinkModal',
  components: {
    VDialog,
    ModalLayout,
    BannerMtLinkSlider,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    appLinks: {
      android: 'https://play.google.com/store/apps/details?id=ru.medtochka',
      ios: 'https://apps.apple.com/ru/app/%D0%BC%D0%B5%D0%B4%D1%82%D0%BE%D1%87%D0%BA%D0%B0/id1591602076',
    },
    instanceYaGoals: new YaGoals(),
  }),
  computed: {
    isIOS() {
      return /iPhone|iPad|iPod/i.test(navigator.userAgent)
    },
    appLinkForUserAgent() {
      return this.isIOS
        ? this.appLinks.ios
        : this.appLinks.android
    },
  },
  methods: {
    handleInstallApp() {
      window.open(this.appLinkForUserAgent, '_blank')

      this.instanceYaGoals.send({
        name: 'mtlinkModal__installApp',
      })
    },
    handleCloseModal() {
      this.$emit('modal:close')
    },
  },
}
</script>

<style lang="scss" scoped>
.mtlink-modal {
  &__layout {
    height: 100%;
  }

  &__slider {
    flex-grow: 1;
  }

  &__actions {
    padding: 32px 16px 24px;
  }
}
</style>
