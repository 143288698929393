export default {
  props: {
    buttonBlock: {
      type: Boolean,
      required: false,
    },
    maxWidth: {
      type: [Number, String],
      required: false,
    },
    buttonClassList: {
      type: String,
      required: false,
    },
  },
}
