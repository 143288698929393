import ClassListMethods from 'modules/ClassListMethods'
import { NAMESPACE } from 'modules/Schedule/classes/ScheduleLpuPreloader/constants'

/**
 * @description
 * Производит показ/скрытие прелоадера для расписания в местах, где используется запрос за расписанием с выбором специальности врача.
 * Не принимает аргументы, только манипулирует классами у элементов на странице.
 * Методы показывают/скрывают прелоадеры у всех календарей на странице(то есть не у конкретного расписания).
 * Поскольку пространство имен разное в разных шаблонах - используется 2 инстанса со своим пространством имен(desktop/mobile)
 * */
class ScheduleLpuPreloader {
  constructor() {
    this.preloaderInstances = {
      first: new ClassListMethods({
        containerSelector: NAMESPACE.selectorOverlayFull, // ToDo schedule-important избавиться от привязки к DOM дереву
        containerClassNameActive: NAMESPACE.classNameOverlayActive, // ToDo schedule-important избавиться от привязки к DOM дереву
      }),
      second: new ClassListMethods({
        containerSelector: NAMESPACE.selectorOverlayPart, // ToDo schedule-important избавиться от привязки к DOM дереву
        containerClassNameActive: NAMESPACE.classNameOverlayActive, // ToDo schedule-important избавиться от привязки к DOM дереву
      }),
    }
  }

  show() {
    this.preloaderInstances.first.add()
    this.preloaderInstances.second.add()
  }

  hide() {
    this.preloaderInstances.first.remove()
    this.preloaderInstances.second.remove()
  }
}

export default ScheduleLpuPreloader
