<template>
  <VImg
    contain
    width="20"
    height="24"
    class="d-inline-flex cursor-pointer"
    :src="image.src"
    :alt="image.alt"
    @click="$emit('click', $event)"
  />
</template>

<script>
export default {
  name: 'SoftVersionImage',
  props: {
    image: {
      type: Object,
      required: true,
    },
  },
}
</script>
