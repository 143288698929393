var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "pb-3",
      class: [_setup.isClubItemVisible ? "pt-5" : "pt-4"],
      attrs: {
        "data-qa": "doctor-price-block-item-list",
        "data-club-switch-block": _setup.clubSwitchState,
        "data-active-tab-value": _setup.redirectValue,
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _setup.isClubItemVisible
            ? _c(_setup.DoctorPriceBlockItem, {
                staticClass: "mr-2",
                attrs: {
                  subtitle: "Клубная цена",
                  price: _vm.clubPrice,
                  active: _setup.isClubItemActive,
                  club: "",
                },
                nativeOn: {
                  click: function ($event) {
                    return _setup.changeClubState(true)
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _c(_setup.DoctorPriceBlockItem, {
            attrs: {
              price: _vm.price,
              subtitle: _vm.price ? _setup.priceSubtitle : undefined,
              active: !_setup.isClubItemActive || !_setup.isClubItemVisible,
              "price-prefix": _vm.noPrefix ? false : _vm.pageType !== "clinic",
            },
            nativeOn: {
              click: function ($event) {
                return _setup.changeClubState(false)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _setup.isClubItemVisible
        ? [
            _c(
              "div",
              {
                staticClass:
                  "d-flex align-center ui-kit-color-error cursor-pointer mt-3",
                on: { click: _setup.handleClickShowClubInfoModal },
              },
              [
                _c("img", {
                  attrs: {
                    src: "/static/_v1/pd/icons/ui-kit/club/club-percent.svg",
                    alt: "Иконка Процент Клуба",
                    width: "20",
                    height: "20",
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_body-2 ui-text_underline ml-2",
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_setup.clubInfoText) + "\n      "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(_setup.ClubDiscountInfoBottomSheet, {
              attrs: {
                attach: false,
                "full-price": _vm.price,
                "club-offer-data": _setup.clubOfferData,
              },
              model: {
                value: _setup.isClubInfoVisible,
                callback: function ($$v) {
                  _setup.isClubInfoVisible = $$v
                },
                expression: "isClubInfoVisible",
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }