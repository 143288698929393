export const POPUP_MAP_ID = 'yandex-map'

export const MOUNT_SELECTOR = '.vue-popup-map'

export const DATA_LPUS_ATTR_NAME = 'data-popup-map'

export const POPUP_MAP_CLASSES = {
  placemark: 'popup-map__placemark',
  placemarkSelected: 'popup-map__placemark_selected',
}

export const MAP_BUTTONS = [
  {
    selector: '.popup-map-button',
    readyClass: 'popup-map-button_ready',
  },
  {
    selector: '[data-qa="lpu_card_btn_addr"]',
    readyClass: '',
  },
]

export const CLUSTER_POPUP_CLASSES = {
  block: 'cluster-popup',
  desktop: 'cluster-popup_desktop',
  container: 'cluster-popup__container',
  lpu: 'cluster-popup__lpu-item',
  containerLpus: 'cluster-popup__container-lpus',
}

export const PANEL_OPTIONS = {
  mobile: {
    position: {
      bottom: 28,
      left: 8,
      right: 8,
    },
  },
  desktop: {
    position: {
      top: 24,
      left: 24,
    },
  },
}

export const NAVIGATION_PANEL_CLASSES = {
  block: 'navigation-panel',
  autoButton: 'navigation-panel__auto-button',
  masstransitButton: 'navigation-panel__masstransit-button',
  masstransitRoute: 'navigation-panel__masstransit-route',
  button: 'navigation-panel__button',
  buttonActive: 'navigation-panel__button_active',
  pin: 'navigation-panel__pin',
  pinMasstransit: 'navigation-panel__pin_masstransit',
  pinOnMap: 'navigation-panel__pin_on-map',
  route: 'navigation-panel__route',
  activeRoute: 'navigation-panel__route_active-route',
  closeNavigation: 'navigation-panel__close-navigaton',
  addressTextContainer: 'navigation-panel__address-text-container',
  backToMiniInfo: 'navigation-panel__back-to-mini-info-button',
  arrivalCircle: 'navigation-panel__arrival-circle',
  timeWalkContainer: 'navigation-panel__time-walk-container',
  pedestrianIconExternalInfo: 'navigation-panel__pedestrian-icon_external-info',
  durationMasstransitContainer: 'navigation-panel__duration-masstransit-container',
  durationText: 'navigation-panel__duration-text',
  durationTextMasstransit: 'navigation-panel__duration-text_masstransit',
  extendedRouteContainer: 'navigation-panel__extended-route-container',
  endpointText: 'navigation-panel__endpoint-text',
}

export const TRANSPORT_NAMES = {
  bus: 'Автобус',
  suburban: 'Электричка',
  underground: 'Метро',
  tramway: 'Трамвай',
  trolleybus: 'Троллейбус',
  minibus: 'Маршрутка',
}

export const TRANSPORT_ICONS = {
  tramway: 'ui-icon-tram',
  suburban: 'ui-icon-tram',
  underground: 'ui-icon-metro',
  bus: 'ui-icon-bus',
  trolleybus: 'ui-icon-bus',
  minibus: 'ui-icon-bus',
}

export const BUTTON_DEPARTURE_ADDRESS_CLASS = 'button-departure-address'

export const BUTTON_CLOSE_POPUP_CLASS = 'button-close-popup'

export const ICON_SHAPES = {
  rectangle: {
    type: 'Rectangle',
    coordinates: [
      [-16, -32],
      [16, 0],
    ],
  },
  bigRectangle: {
    type: 'Rectangle',
    coordinates: [
      [-24, -48],
      [24, 0],
    ],
  },
  circle: {
    type: 'Circle',
    coordinates: [0, 0],
    radius: 33,
  },
}

export const LPU_WORD_CASES = ['клиника', 'клиники', 'клиник']

export const MULTI_ROUTE_TYPES = {
  driving: 'driving',
  masstransit: 'masstransit',
}
