<template>
  <div class="patient-profile-skeleton p-4">
    <VSkeletonLoader
      class="patient-profile-skeleton__intro"
      type="heading, avatar, card-heading, list-item-avatar@4, date-picker-options, text"
    />
    <VSkeletonLoader
      class="patient-profile-skeleton__grade"
      type="date-picker-options@3"
    />
  </div>
</template>

<script>
export default {
  name: 'PatientProfileModalSkeleton',
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.patient-profile-skeleton {
  &__intro .v-skeleton-loader {
    &__heading {
      max-width: 150px;
      height: 20px;
      margin-bottom: 24px;
      border-radius: $border-radius-md;
    }

    &__avatar {
      width: 104px;
      height: 104px;
      margin: 0 auto;
    }

    &__text {
      height: 8px;
    }

    &__card-heading {
      .v-skeleton-loader__heading {
        max-width: 128px;
        height: 24px;
        margin: 16px auto 18px;
        border-radius: $border-radius-md;
      }
    }

    &__list-item-avatar {
      height: 32px;
      padding-left: 0;
      padding-right: 24px;

      .v-skeleton-loader__avatar {
        width: 16px;
        height: 16px;
        margin-right: 14px;
      }

      .v-skeleton-loader__text {
        height: 19px;
        border-radius: $border-radius-md;
      }
    }

    &__date-picker-options {
      padding: 34px 0 20px;

      .v-skeleton-loader__avatar {
        width: 15px;
        height: 20px;
        border-radius: $border-radius-sm;
        margin-right: 0 !important;
      }

      .v-skeleton-loader__avatar:last-child {
        display: none;
      }

      .v-skeleton-loader__text {
        max-width: 130px;
        height: 20px;
      }
    }
  }

  &__grade {
    margin-top: 6px;

    .v-skeleton-loader {
      &__date-picker-options {
        padding: 6px 0;

        /* stylelint-disable-next-line no-descending-specificity */
        .v-skeleton-loader__avatar {
          width: 15px;
          height: 20px;
          border-radius: $border-radius-sm;
          margin-right: 0 !important;
        }

        .v-skeleton-loader__avatar:last-child {
          display: none;
        }

        .v-skeleton-loader__text {
          max-width: 234px;
          height: 20px;
        }

        &:first-child {
          .v-skeleton-loader__avatar {
            display: none;
          }
        }

        &:last-child {
          padding-top: 20px;

          .v-skeleton-loader__text {
            max-width: 130px !important;
          }
        }
      }
    }
  }
}
</style>
