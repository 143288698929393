var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "full-calendar" },
    [
      _setup.isEmptyCalendar
        ? _c(_setup.CalendarStub)
        : _vm._l(
            _setup.slicedFullCalendarSlots,
            function ({ day, times, formattedDay }, indexDay) {
              return _c(
                "div",
                {
                  key: `day-${indexDay}`,
                  class: {
                    "mt-2": !_setup.isMobile && indexDay !== 0,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "full-calendar__day ui-text ui-text_subtitle-1",
                    },
                    [_vm._v("\n        " + _vm._s(formattedDay) + "\n      ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex",
                      class: [
                        _setup.isMobile ? "overflow-x-auto" : "flex-wrap",
                      ],
                    },
                    _vm._l(times, function ({ time, free }, indexTime) {
                      return _c(
                        "VBtn",
                        {
                          key: `time-${indexTime}`,
                          staticClass: "full-calendar__time",
                          attrs: {
                            disabled: !free,
                            width: "76",
                            color: "primary",
                            depressed: "",
                          },
                          on: {
                            click: function ($event) {
                              return _setup.handleCalendarTimeChoice({
                                day,
                                time,
                              })
                            },
                          },
                        },
                        [_vm._v("\n          " + _vm._s(time) + "\n        ")]
                      )
                    }),
                    1
                  ),
                ]
              )
            }
          ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "mt-6" },
        [
          !_setup.isEmptyCalendar &&
          !_setup.isVisibleAllSlots &&
          _setup.formattedFullCalendarSlots.length > _setup.maxCalendarDays
            ? _c(
                "VBtn",
                {
                  staticClass: "uiKitBgPrimary mb-4 w-100",
                  attrs: { color: "primary", text: "" },
                  on: { click: _setup.openAllSlots },
                },
                [_vm._v("\n      Всё расписание\n    ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _setup.isMobile
            ? _c(
                "VBtn",
                {
                  staticClass: "w-100",
                  attrs: { color: "uiKitTextSecondary", text: "" },
                  on: { click: _setup.closeFullCalendar },
                },
                [
                  _c("span", { staticClass: "ui-icon-arrow-left" }),
                  _vm._v("\n      Назад\n    "),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }