import { axiosClient, getCSRFToken } from 'utils'

function requestGoWithoutPrepayment({ data = {}, couponRequestId } = {}) {
  return new Promise((resolve, reject) => {
    axiosClient({
      data,
      method: 'POST',
      url: `${window.location.origin}/club/ajax/coupon_request/${couponRequestId}/cancel/`,
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => resolve(response.data))
      .catch(({ response }) => reject(response.data))
  })
}

export default requestGoWithoutPrepayment
