var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.lpuBranches.length
        ? _c("SelectModuleBottomSheet", {
            staticClass: "mb-4",
            attrs: {
              options: _vm.lpuBranches,
              "active-option-index": _vm.activeLpuBranchIndex,
            },
            on: { "option:selected": _vm.handleLpuBranchSelection },
            scopedSlots: _vm._u(
              [
                {
                  key: "select-option-icon",
                  fn: function ({ isActive }) {
                    return [
                      isActive
                        ? _c(
                            "v-icon",
                            { attrs: { color: "primary", size: "24" } },
                            [_vm._v("\n        ui-icon-check\n      ")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ],
              null,
              false,
              3238078646
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _c("SelectModuleModal", {
        attrs: {
          "modal-title": "Фильтр",
          options: _vm.filteredReviewTypes,
          "selected-option": _vm.selectedReviewType,
          "prepend-icon": _vm.createTemplateRadioButton,
          "append-icon": _vm.createTemplateAmount,
          "transform-option-title": _vm.getWithoutAmount,
        },
        on: { "select-modal:click-option": _vm.handleSelectedReviewType },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }