import {
  createDevNotice, deepMerge, isJSON, testRequiredOptions,
} from 'utils'
import Modal from 'modules/Modal'

import Swiper, { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper-bundle.css'

import { getNamespace, getSlideMarkup, toggleVideoPlayers } from './functions'
import {
  CONTENT_TYPE, MARKUP_LOOKUP_DATA_ATTR, PAGINATION_TYPE, VIDEO_AUTOPLAY,
} from './constants'

/**
 * В данном случае важно, чтобы стили этого модуля импортировались последними
 * Они должны перебивать стили Swiper и Modal
 * */

import './index.scss'

Swiper.use([Navigation, Pagination])

/**
 * Предназначен для работы с содержимым модального окна. Работает на основе модуля Modal и библиотеки Swiper.
 * @author Быков Александр(@bykov)
 * @copyright Денис Курочкин (@kurochkin)
 */
class PopupContent {
  /**
     * @param {Object} options
     * @param {boolean} [options.markupDataLookup] - определяет, собирать ли данные из разметки, используя data-атрибут "data-popup-content"
     * @param {('photo'|'video')} [options.contentType] - тип отображаемого контента
     */
  constructor(options = {}) {
    this.modifier = 'b-popup-content'

    this._createOptions(options)

    const {
      id,
      selector,
      modifier,
      beforeShow,
    } = this.options

    testRequiredOptions({
      mode: 'throw',
      module: 'PopupContent',
      requiredOptions: {
        id,
        selector,
        modifier,
        beforeShow,
      },
    })

    this._createNameSpace()
    this._createDependentsOptions()
    this._createData()
    this._createTemplates()
    this._createPopupBase()
    this._createPopup()
    this._updatePopupNamespace()

    document.addEventListener('click', this._eventClick)
  }

  hide = () => {
    if (this.options.id) {
      this.Modal.hide()
    }
  }

  /**
     * @param {Object} [data] - данные для отрисовки контента
     * @param {number} [slideTo] - если есть слайдер - до какого слайда необходимо пролистнуть
     * */
  render = (data, slideTo) => {
    if (!this.options.id) {
      return
    }

    if (data) {
      this.response = data
      this.slidesTotal = data.length

      this._searchNodes()
    }

    let detail = ''

    const {
      imageCaption,
      imageTrack, // TODO: отрефакторить; это уже не imageTrack, в слайдере могут быть не только изображения
      modalContent,
      modalHeaderTitle,
    } = this.nodes

    const {
      afterShow,
      createHead,
      createSlider,
      createDescription,
    } = this.options

    const position = slideTo || this.slidesCurrent
    const currentObject = this.response[position]
    const hasDescription = createDescription && currentObject?.attributes.length !== 0

    this.slidesCurrent = position

    if (!currentObject) {
      return
    }

    /**
         * Меняю заголовок
         * */

    if (modalHeaderTitle && createHead) {
      modalHeaderTitle.textContent = currentObject.name
    }

    /**
         * Оформляю описание
         * */

    if (hasDescription) {
      if (data) {
        detail += `<div class="${this.namespace.description.class} ${this.namespace.description.customize}">`
      }

      currentObject.attributes.forEach(item => {
        const nameElement = item.name
          ? `<p
                           class="${this.namespace.detailTitle.class} ${this.namespace.detailTitle.customize} ui-text ui-text_subtitle-1${this.options.truncateTitle ? ' ui-text_truncate' : ''}"
                           ${this.options.truncateTitle ? `title="${item.name}"` : ''}
                       >${item.name}</p>`
          : ''
        const descriptionElement = item.description ? `<p class="${this.namespace.detailText.class} ${this.namespace.detailText.customize} ui-text ui-text_body-1">${item.description}</p>` : ''

        detail += `<div class="${this.namespace.detail.class} ${this.namespace.detail.customize}">
                                ${nameElement}
                                ${descriptionElement}
                           </div>`
      })

      if (data) {
        detail += '</div>'
      }
    }

    if (currentObject.caption) {
      imageCaption.innerHTML = `<div class="text-center ui-text ui-text_body-2 ${currentObject.captionColor} mt-2">
          ${currentObject.caption}
        </div>`
    } else {
      imageCaption.innerHTML = ''
    }

    /**
         * Произвожу рендеринг слайдера
         * */

    if (createSlider && data) {
      imageTrack.innerHTML = data.reduce((result, item) => result + getSlideMarkup({
        item,
        namespace: this.namespace,
        type: this.options.contentType,
      }), '')
    }

    /**
         * Если данных нет - значит узел есть в модалке - меняю содержимое
         * Иначе произвожу вставку разметки на страницу
         * */

    if (hasDescription) {
      if (data) {
        modalContent.insertAdjacentHTML('beforeend', detail)
      } else {
        modalContent.querySelector(`.${this.namespace.description.class}`).innerHTML = detail
      }
    }

    /**
         * Произвожу завершающие действия:
         * Вставляю навигацию, пагинацию, инициализирую слайдер и открываю модалку
         * Исполняю option callback
         * */

    if (data) {
      this._appendPagination()
      this._appendNavigation()
      this._createSlider()

      setTimeout(() => {
        this._openPopup()

        afterShow({
          nodes: this.nodes,
          popup: this.Modal,
          slider: this.Slider,
          response: this.response,
        })
      })
    }
  }

  /**
     * Общие методы
     * */

  /**
     * Используется для поиска узлов перед рендерингом содержимого модального окна
     * Производится поиск базовых элементов шаблона
     * */

  _searchNodes() {
    this.nodes = {}

    const { headTitle } = this.Modal.attr
    const modalNodes = this.Modal.nodes
    const modalCover = modalNodes.cover
    const modalBody = modalNodes.body
    const modalHeader = modalNodes.head
    const modalContainer = modalNodes.container
    const modalHeaderTitle = modalCover.querySelector(`.${headTitle}`)

    if (!modalCover) {
      return
    }

    this.nodes = {
      modalBody,
      modalCover,
      modalHeader,
      modalContainer,
      modalHeaderTitle,
      modalContent: modalCover.querySelector(`.${this.namespace.content.class}`),
      imageBox: modalCover.querySelector(`.${this.namespace.imageBox.class}`),
      imageTrack: modalCover.querySelector(`.${this.namespace.imageTrack.class}`),
      imageCaption: modalBody.querySelector(`.${this.namespace.imageCaption.class}`),
    }
  }

  _eventClick = ({ target }) => {
    const { hideButton } = this.namespace
    const hideButtonNode = target.closest(`.${hideButton.class}`)

    if (hideButtonNode) {
      this.hide()
    }
  }

  _createNameSpace() {
    const customModifier = this.options.modifier

    this.namespace = getNamespace({
      id: this.options.id,
      baseModifier: this.modifier,
      customModifier,
    })
  }

  _createData() {
    this.slidesTotal = 0
    this.slidesCurrent = this.options.slideTo
  }

  /**
     * Сливаю опции зависимостей в основные
     * А именно опции модуля Modal и Swiper
     * */

  _createDependentsOptions() {
    const {
      imageTrack,
      slide,
      slideActive,
      navigationPrev,
      navigationNext,
      navigationButtonHidden,
    } = this.namespace

    const modalOptions = {
      useHash: true,
      hideKeyEsc: true,
      hideClickOutContainer: true,
      hasFixedBtn: false,
      animation: 'slide-in',
    }

    const sliderOptions = {
      containerModifierClass: `${this.modifier}-`,
      wrapperClass: imageTrack.class,
      slideClass: slide.class,
      slideActiveClass: slideActive.class,
      speed: 400,
      roundLengths: true,
      spaceBetween: 5,
      autoHeight: true,
      navigation: {
        prevEl: `.${navigationPrev.class}`,
        nextEl: `.${navigationNext.class}`,
        disabledClass: `${navigationButtonHidden.class}`,
      },
      on: {
        slideChange: this._slideChange,
      },
    }

    this.options.modalOptions = deepMerge(modalOptions, this.options.modalOptions)
    this.options.sliderOptions = deepMerge(sliderOptions, this.options.sliderOptions)
  }

  _createOptions(options) {
    this.options = deepMerge({
      id: null,
      slideTo: 0,
      selector: null,
      modifier: null,
      createHead: true,
      createSlider: true,
      createHideButton: true,
      createDescription: true,
      navigation: true,
      pagination: PAGINATION_TYPE.dynamic,
      markupDataLookup: false,
      contentType: CONTENT_TYPE.photo,
      truncateTitle: false,
      sliderOptions: {},
      modalOptions: {},
      beforeShow: () => {},
      afterShow: () => {},
      afterClose: () => {},
      afterSlideChange: () => {},
    }, options)
  }

  /**
     * Работа с модальным окном
     * */

  _createPopup() {
    const { createHead, modalOptions } = this.options

    this.Modal = new Modal({
      ...modalOptions,
      mobileTitle: createHead ? 'title' : null,
      id: this.options.id,
      render: this.popupBasis,
      afterHide: this._popupAfterClose,
      beforeShow: this._popupBeforeOpen,
      catchEventShow: [this.options.selector],
    })
  }

  /**
     * Часть разметки предусмотрена в модуле Modal
     * Но не предусмотрена модификация пространства имен
     * Добавляю пространство имен текущего класса нужным узлам
     * */

  _updatePopupNamespace() {
    if (!this.options.createHead) {
      return
    }

    const coverNode = this.Modal.nodes.cover
    const { head, cross, headTitle } = this.Modal.attr
    const headNode = coverNode.querySelector(`.${head}`)
    const crossNode = coverNode.querySelector(`.${cross}`)
    const headTitleNode = coverNode.querySelector(`.${headTitle}`)
    const { head: popupHead, cross: popupCross, headTitle: popupHeadTitle } = this.namespace

    headNode.classList.add(popupHead.class, popupHead.customize)
    crossNode.classList.add(popupCross.class, popupCross.customize)
    headTitleNode.classList.add(popupHeadTitle.class, popupHeadTitle.customize)
  }

  /**
     * Возвращает данные, необходимые для рендера слайдера, сканируя DOM на предмет необходимых данных.
     * @param  {HTMLElement} target
     * @returns {undefined|[Object, (string|number)]}
     * @private
     */
  _getSliderRenderData = target => {
    const rawData = target.closest(`[${MARKUP_LOOKUP_DATA_ATTR}]`)?.dataset?.popupContent

    if (!rawData) {
      return
    }

    if (!isJSON(rawData)) {
      createDevNotice({
        module: 'PopupContent',
        description: 'Неверный формат JSON в методе _getSliderRenderData',
      })

      return
    }

    const { data } = JSON.parse(rawData)
    const slideTo = target.dataset?.slideIndex ?? 0

    if (this.options.contentType === CONTENT_TYPE.video) {
      data[slideTo].src = data[slideTo].src.replace(VIDEO_AUTOPLAY.off, VIDEO_AUTOPLAY.on)
    }

    return [data, slideTo]
  }

  _popupBeforeOpen = ({ open, target } = {}) => {
    this._openPopup = open

    if (this.options.markupDataLookup) {
      this.render(...this._getSliderRenderData(target))
    }

    this.options.beforeShow({
      target,
      instance: this,
      render: this.render,
    })
  }

  _popupAfterClose = () => {
    if (!this.nodes?.modalBody) {
      return
    }

    if (this.nodes.modalHeaderTitle) {
      this.nodes.modalHeaderTitle.innerHTML = ''
    }

    this.nodes.modalBody.innerHTML = this.popupBasis

    this.nodes = {}
    this.response = {}
    this.slidesTotal = 0
    this.slidesCurrent = this.options.slideTo

    this._sliderDestroy()
    this.options.afterClose()
  }

  /**
     * Работа со слайдером
     * */

  _createSlider() {
    const { sliderOptions } = this.options
    const { imageContainer } = this.namespace
    const initialSlide = this.slidesCurrent

    sliderOptions.simulateTouch = this.slidesTotal > 1

    if (this.options.pagination && this.slidesTotal > 1) {
      sliderOptions.pagination = this._getPagination()
    }

    this.Slider = new Swiper(`#${imageContainer.id}`, sliderOptions)

    if (initialSlide > 0) {
      this.Slider.slideTo(initialSlide, 0)
    }
  }

  _appendNavigation() {
    const { imageBox } = this.nodes
    const { navigationBox } = this.templates
    const { createSlider, navigation } = this.options

    if (createSlider && navigation) {
      imageBox.insertAdjacentHTML('beforeend', navigationBox)
    }
  }

  _appendPagination() {
    const { paginationBox } = this.templates
    const { createSlider, pagination } = this.options

    if (createSlider && pagination) {
      this.nodes.modalContent.insertAdjacentHTML('beforeend', paginationBox)
    }
  }

  _getPagination() {
    const optionsPagination = this.options.pagination
    const paginationClassName = this.namespace.pagination.class
    const paginationDefault = {
      el: `.${paginationClassName}`,
    }

    const paginationFraction = {
      type: PAGINATION_TYPE.fraction,
      renderFraction: (current, total) => `<span class="${current}"></span> из <span class="${total}"></span>`,
    }

    const paginationDynamic = {
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: this.slidesTotal < 3 ? 1 : 3,
    }

    if (optionsPagination === PAGINATION_TYPE.fraction) {
      return { ...paginationDefault, ...paginationFraction }
    }

    if (optionsPagination === PAGINATION_TYPE.dynamic) {
      return { ...paginationDefault, ...paginationDynamic }
    }
  }

  _sliderDestroy() {
    if (this.Slider) {
      this.Slider.destroy(true)
    }
  }

  _slideChange = () => {
    this.slidesCurrent = this.Slider.activeIndex

    if (this.options.contentType === CONTENT_TYPE.video) {
      toggleVideoPlayers(
        this.Slider.slides[this.Slider.previousIndex],
        this.Slider.slides[this.Slider.activeIndex],
      )
    }

    this.render()

    this.options.afterSlideChange({
      slider: this.Slider,
      slidesCurrent: this.slidesCurrent,
    })
  }

  /**
     * Работа с разметкой
     * */

  _createPopupBase() {
    let markupBody = ''

    const { hideBox, sliderBox } = this.templates
    const { createSlider, createHideButton } = this.options
    const { content, contentRound } = this.namespace
    const contentBaseClassList = `${content.class} ${content.customize}`
    const contentRoundClassList = `${contentRound.class} ${content.customize}`
    const contentClassList = createHideButton ? contentBaseClassList : `${contentBaseClassList} ${contentRoundClassList}`

    if (createSlider) {
      markupBody += sliderBox
    }

    if (createHideButton) {
      markupBody += hideBox
    }

    this.popupBasis = `<div class="${contentClassList}">${markupBody}</div>`
  }

  _createTemplates() {
    const {
      detail,
      isEmpty,
      hideBox,
      hideButton,
      imageBox,
      pagination,
      navigation,
      detailText,
      detailTitle,
      imageTrack,
      imageCaption,
      imageContainer,
      navigationPrev,
      navigationNext,
      navigationButton,
      navigationButtonHidden,
    } = this.namespace

    this.templates = {
      detail: `<div class="${detail.class} ${detail.customize}">
                            <p class="${isEmpty.class} ${detailTitle.class} ${detailTitle.customize} ui-text ui-text_subtitle-1"></p>
                            <p class="${isEmpty.class} ${detailText.class} ${detailText.customize} ui-text ui-text_body-1 ui-kit-color-text"></p>
                        </div>`,

      sliderBox: `<div class="${imageBox.class} ${imageBox.customize}">
                            <div class="${imageContainer.class} ${imageContainer.customize}" id="${imageContainer.id}">
                                <div class="${imageTrack.class} ${imageTrack.customize}"></div>
                            </div>
                            <div class="${imageCaption.class} ${imageCaption.customize}"></div>
                        </div>`,

      hideBox: `<div class="${hideBox.class} ${hideBox.customize}">
                            <button class="${hideButton.class} ${hideButton.customize} b-button b-button_text b-button_blue"><span class="ui-text ui-text_button">Закрыть</span></button>
                        </div>`,

      paginationBox: `<div class="${isEmpty.class} ${pagination.class} ${pagination.customize} ui-text ui-text_body-2 ui-kit-color-text-secondary"></div>`,

      navigationBox: `<div class="${navigation.class} ${navigation.customize}">
                                <button class="${navigationButtonHidden.class} ${navigationButton.class} ${navigationButton.customize} ${navigationPrev.class} ${navigationPrev.customize}"></button>
                                <button class="${navigationButtonHidden.class} ${navigationButton.class} ${navigationButton.customize} ${navigationNext.class} ${navigationNext.customize}"></button>
                            </div>`,

    }
  }
}

export default PopupContent
