import YaGoals from 'modules/YandexGoals'
import PopupContent from 'modules/PopupContent'
import { BLOCK_NAME, CLASS_NAMES, SLIDES_DATA } from 'components/common/BannerMtLink/constants'

const instanceYaGoals = new YaGoals()

function initPopupContent() {
  new PopupContent({
    id: BLOCK_NAME,
    selector: `.${CLASS_NAMES.triggerOpenPopup}`,
    modifier: BLOCK_NAME,
    createHead: false,
    modalOptions: {
      useHash: false,
    },
    beforeShow: ({ render }) => {
      render(SLIDES_DATA, 0)

      instanceYaGoals.send({
        name: 'mtlinkModal__open',
      })
    },
  })
}

export default initPopupContent
