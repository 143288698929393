var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "appointment-type-filter-list",
      attrs: { "data-qa": "appointment-type-filter-list" },
    },
    [
      _c(
        "VChipGroup",
        {
          staticClass: "mt-2",
          attrs: { mandatory: "" },
          model: {
            value: _setup.activePlaceType,
            callback: function ($$v) {
              _setup.activePlaceType = $$v
            },
            expression: "activePlaceType",
          },
        },
        [
          _vm._l(_vm.placeTypes, function (placeType) {
            return [
              _c(
                "VChip",
                {
                  key: placeType,
                  staticClass: "appointment-type-filter-list__chip",
                  attrs: {
                    value: placeType,
                    color: `${_setup.styleVars.default.uiKitBgGray0}4D`,
                    "data-qa": "appointment-type-filter-list__chip",
                    "active-class": "uiKitBgPrimary",
                  },
                  on: { click: _setup.handleFilterTabClick },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_setup.CHIP_LABELS[placeType]) +
                      "\n      "
                  ),
                ]
              ),
            ]
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }