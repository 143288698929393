<template>
  <component
    :is="currentComponent"
    :lpu-id="lpuId"
    :is-used-club="isUsedClub"
    :class-translit="classTranslit"
    :class-id="classId"
    :category-id="categoryId"
    :type-id="typeId"
    :init-selected-synonym="initSelectedSynonym"
    @synonym:change="handleChangeSynonym"
  />
</template>

<script>
import SynonymListMobile from 'components/common/SynonymList/components/mobile/SynonymList'
import SynonymListDesktop from 'components/common/SynonymList/components/desktop/SynonymList'
import SynonymListProps from 'components/common/SynonymList/mixins/SynonymListProps'

export default {
  name: 'SynonymList',
  mixins: [
    SynonymListProps,
  ],
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    currentComponent() {
      return this.isMobile ? SynonymListMobile : SynonymListDesktop
    },
  },
  methods: {
    handleChangeSynonym(payload) {
      this.$emit('synonym:change', payload)
    },
  },
}
</script>
