/**
 * @description
 *
 * Псевдоинтерфейс для объекта отдельного таба
 *
 * @return { Object } {
 *     isInClub?: string | boolean
 *     dataQA?: string
 *     isActiveTab?: string | boolean
 *     sortPosition?: number,
 *     description: string
 *     priceList: {
 *         price: string | number,
 *         priceClub: string | number,
 *     }
 * }
 * */

const getTabInterface = () => ({
  isInClub: null,
  dataQA: null,
  isActiveTab: null,
  sortPosition: null,
  description: null,
  priceList: {
    price: null,
    priceClub: null,
  },
})

export default getTabInterface
