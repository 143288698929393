import { getLpuRequestInterface } from 'modules/Schedule/models'

/**
 * @description
 * Собирает данные для запроса за расписанием для клиник
 *
 * @param { Object } opts - объект с параметрами
 * @param { Object } opts.data - интерфейс 'getModuleDataInterface'
 * @param { Object } opts.specialtiesData - объект с данными в формате ответа 'specialtiesRequest'
 *
 * @return { Object } - объект в формате 'getLpuRequest'
 * */

const getLpuRequestData = opts => {
  const baseData = getLpuRequestInterface()

  const {
    data,
    specialtiesData,
  } = opts

  const {
    dateStart,
    timedelta,
    specialtyId,
    specialtyName,
    specialtyTranslit,
  } = data.storage

  const usedSpecialtyFilter = specialtyId && specialtyName && specialtyTranslit

  baseData.dt_start = dateStart
  baseData.town_timedelta = timedelta

  const lpuSpecialities = [...data.items].map(([scheduleContainer, itemData]) => {
    const { hasSlots, isAppointmentOn, lpuId } = itemData
    const lpuSpecialtiesList = specialtiesData[lpuId]
    const hasEveryNeedData = hasSlots && isAppointmentOn && scheduleContainer && lpuSpecialtiesList

    if (!hasEveryNeedData) {
      return
    }

    if (usedSpecialtyFilter) {
      const lpuSelectedSpecialty = lpuSpecialtiesList
        .find(({ id, translit }) => specialtyId === id && specialtyTranslit === translit)

      if (!lpuSelectedSpecialty) {
        return
      }

      return {
        lpu_id: lpuId,
        name: lpuSelectedSpecialty.name,
        translit: lpuSelectedSpecialty.translit,
        speciality_id: lpuSelectedSpecialty.id,
      }
    }

    return {
      lpu_id: lpuId,
      name: lpuSpecialtiesList[0].name,
      translit: lpuSpecialtiesList[0].translit,
      speciality_id: lpuSpecialtiesList[0].id,
    }
  })

  return { ...baseData, lpus_specialities: lpuSpecialities.filter(i => i) }
}

export default getLpuRequestData
