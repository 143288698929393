import { CONTENT_TYPE, VIDEO_AUTOPLAY } from 'modules/PopupContent/constants'

/**
 * Возвращает разметку слайда в зависимости от переданных параметров.
 * @param {Object} options
 * @param {Object} options.item - данные для слайда
 * @param {Object} options.namespace - объект пространства имен
 * @param {('photo'|'video')} options.type - тип контента
 * @returns {string}
 */
export const getSlideMarkup = ({ item, namespace, type }) => {
  if (type === CONTENT_TYPE.photo) {
    return `<img src="${item.src || item.image}"
                     alt="${item.name}"
                     class="${namespace.slide.class} ${namespace.slide.customize}"
                >`
  }

  if (type === CONTENT_TYPE.video) {
    return `<iframe class="${namespace.slide.class} ${namespace.slide.customize} ${namespace.video.class} ${namespace.video.customize}"
                        src="${item.src}"
                        frameborder="0"
                        allowfullscreen
                ></iframe>`
  }
}

/**
 * Останавливает плеер предыдущего слайда и запускает плеер текущего слайда.
 * @param {HTMLIFrameElement} previousSlideIframe
 * @param {HTMLIFrameElement} activeSlideIframe
 * @private
 */
export const toggleVideoPlayers = (previousSlideIframe, activeSlideIframe) => {
  previousSlideIframe.src = previousSlideIframe.src.replace(VIDEO_AUTOPLAY.on, VIDEO_AUTOPLAY.off)
  activeSlideIframe.src = activeSlideIframe.src.replace(VIDEO_AUTOPLAY.off, VIDEO_AUTOPLAY.on)
}

/**
 * @description функция возвращает объект с пространством имен модуля
 *
 * @param { Object } object
 * @param { String } object.id - уникальный id модального окна. Необходим для генерации id для слайдера
 * @param { String } object.baseModifier - базовый модификатор
 * @param { String } object.customModifier - кастомный модификатор
 * */

export function getNamespace({ id, baseModifier, customModifier }) {
  return {
    head: {
      class: `${baseModifier}__head`,
      customize: `${customModifier}__head`,
    },
    headTitle: {
      class: `${baseModifier}__head-title`,
      customize: `${customModifier}__head-title`,
    },
    cross: {
      class: `${baseModifier}__cross`,
      customize: `${customModifier}__cross`,
    },
    content: {
      class: `${baseModifier}__content`,
      customize: `${customModifier}__content`,
    },
    contentRound: {
      class: `${baseModifier}__content_round`,
      customize: `${customModifier}__content_round`,
    },
    imageBox: {
      class: `${baseModifier}__image-box`,
      customize: `${customModifier}__image-box`,
    },
    video: {
      class: `${baseModifier}__video`,
      customize: `${customModifier}__video`,
    },
    imageContainer: {
      id: `${id}__image-container`,
      class: `${baseModifier}__image-container`,
      customize: `${customModifier}__image-container`,
    },
    imageTrack: {
      class: `${baseModifier}__image-track`,
      customize: `${customModifier}__image-track`,
    },
    imageCaption: {
      class: `${baseModifier}__image-caption`,
      customize: `${customModifier}__image-caption`,
    },
    slide: {
      class: `${baseModifier}__slide`,
      customize: `${customModifier}__slide`,
    },
    slideActive: {
      class: `${baseModifier}__slide_active`,
      customize: `${customModifier}__slide_active`,
    },
    isEmpty: {
      class: `${baseModifier}_is-empty`,
      customize: `${customModifier}_is-empty`,
    },
    pagination: {
      class: `${baseModifier}__pagination`,
      customize: `${customModifier}__pagination`,
    },
    hideBox: {
      class: `${baseModifier}__hide-box`,
      customize: `${customModifier}__hide-box`,
    },
    hideButton: {
      class: `${baseModifier}__hide-button`,
      customize: `${customModifier}__hide-button`,
    },
    navigation: {
      class: `${baseModifier}__navigation`,
      customize: `${customModifier}__navigation`,
    },
    navigationButton: {
      class: `${baseModifier}__navigation-button`,
      customize: `${customModifier}__navigation-button`,
    },
    navigationButtonHidden: {
      class: `${baseModifier}__navigation-button_hidden`,
      customize: `${customModifier}__navigation-button_hidden`,
    },
    navigationPrev: {
      class: `${baseModifier}__navigation-prev`,
      customize: `${customModifier}__navigation-prev`,
    },
    navigationNext: {
      class: `${baseModifier}__navigation-next`,
      customize: `${customModifier}__navigation-next`,
    },
    description: {
      class: `${baseModifier}__description`,
      customize: `${customModifier}__description`,
    },
    detail: {
      class: `${baseModifier}__detail`,
      customize: `${customModifier}__detail`,
    },
    detailTitle: {
      class: `${baseModifier}__detail-title`,
      customize: `${customModifier}__detail-title`,
    },
    detailText: {
      class: `${baseModifier}__detail-text`,
      customize: `${customModifier}__detail-text`,
    },
  }
}

export default getNamespace
