export const MOUNT_SELECTOR = '.vue-banner-mtlink-mount'

export const BLOCK_NAME = 'mtlink-banner'

export const CLASS_NAMES = {
  triggerOpenPopup: `${BLOCK_NAME}__js-open-popup`,
}

export const SLIDES_DATA = [
  {
    name: 'Добавляйте в медкарту заключения и анализы',
    src: '/static/_v1/pd/screenshots/medtochka/medtochka-app-medcard-add-docs-qr.png',
    attributes: [{
      name: 'Добавляйте в медкарту заключения <br>и анализы',
    }],
  },
  {
    name: 'На приёме врач посмотрит всё на компьютере',
    src: '/static/_v1/pd/screenshots/medtochka/medtochka-app-medcard-see-desktop-qr.png',
    attributes: [{
      name: 'На приёме врач посмотрит всё <br>на компьютере',
    }],
  },
]
