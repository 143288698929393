<template>
  <div v-if="isMobile">
    <a
      v-if="lpuAddress.show_phone && lpuAddress.lpu.lpuphone?.phone"
      v-ripple
      data-qa="lpu_card_btn_phone"
      class="d-flex align-center text-decoration-none ui-text ui-text_body-1 ui-kit-color-primary py-2 mt-2"
      data-ya-goals-appointment="mobile_appointment__phone_action"
      :href="`tel:+7${clearPhone}`"
      :data-club-appointment-notice="/* TODO: переписать с импортом компонента */`{
        &quot;isClub&quot;: &quot;${lpuAddress.tabs_data?.in_club}&quot;,
        &quot;clubDiscount&quot;: &quot;${lpuAddress.tabs_data?.club_discount}&quot;,
        &quot;price&quot;: &quot;${lpuAddress.tabs_data?.club_price}&quot;,
        &quot;priceFull&quot;: &quot;${lpuAddress.tabs_data?.price}&quot;,
        &quot;phoneNumber&quot;: &quot;${lpuAddress.lpu.lpuphone.phone}&quot;,
        &quot;appointmentLink&quot;:
          &quot;${`${urls?.createAppointmentForm}?doctor=${doctor?.id}&type=club&lpu=${lpuAddress.lpu.id}`}&quot;,
        &quot;infoOption&quot;: &quot;callDoctor&quot;
      }`"
      @click="handleClickPhone"
    >
      <VIcon
        size="20"
        class="mr-4"
        color="uiKitIconSecondary"
      >
        ui-icon-phone-call
      </VIcon>
      {{ getPhoneFormatted(phone) }}
    </a>
    <div v-else-if="lpuAddress.lpu.is_show_registration_phone">
      <a
        v-ripple
        class="d-flex align-center text-decoration-none ui-text ui-text_body-1 ui-kit-color-primary py-2 mt-2"
        :href="`tel:+7${clearPhone}`"
      >
        <VIcon
          size="20"
          class="mr-4"
          color="uiKitIconSecondary"
        >
          ui-icon-phone-call
        </VIcon>
        {{ getPhoneFormatted(phone) }}
      </a>
      <div class="d-flex align-center ui-text ui-text_body-1 py-2 mt-2">
        <VIcon
          size="20"
          class="mr-4"
          color="uiKitIconSecondary"
        >
          ui-icon-wallet
        </VIcon>
        По ОМС приём бесплатный
      </div>
    </div>
  </div>
  <div v-else>
    <div
      v-if="lpuAddress.show_phone && lpuAddress.lpu.lpuphone?.phone || lpuAddress.lpu.is_show_registration_phone"
      class="d-flex align-center text-decoration-none ui-text ui-text_body-1 py-2 mt-2"
    >
      <VIcon
        size="20"
        class="mr-4"
        color="uiKitIconSecondary"
      >
        ui-icon-phone-call
      </VIcon>
      {{ getPhoneFormatted(phone) }}
    </div>
    <div
      v-if="lpuAddress.lpu.is_show_registration_phone"
      class="d-flex align-center ui-text ui-text_body-1 py-2 mt-2"
    >
      <VIcon
        size="20"
        class="mr-4"
        color="uiKitIconSecondary"
      >
        ui-icon-wallet
      </VIcon>
      По ОМС приём бесплатный
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import { getPhoneFormatted } from 'utils'
import YandexGoals from 'modules/YandexGoals'
import { LpuAddressList } from 'components/common/DoctorPageLpuList/types'
import { useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'

const props = defineProps<{
  lpuAddress: LpuAddressList[number]
}>()

const externalDataStore = useExternalDataStore()
const { urls, doctor, isMobile } = storeToRefs(externalDataStore)

const phone = computed(() => {
  if (props.lpuAddress.show_phone && props.lpuAddress.lpu.lpuphone?.phone) {
    return props.lpuAddress.lpu.lpuphone.phone
  }

  if (props.lpuAddress.lpu.is_show_registration_phone) {
    return props.lpuAddress.phone
  }

  return ''
})

const clearPhone = computed(() => phone.value?.replace(/\D+/g, ''))

const yandexGoalsInstance = new YandexGoals()

function handleClickPhone() {
  yandexGoalsInstance.send({ name: 'doctorPageLpuList__phoneClick' })
}
</script>
