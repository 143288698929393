var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.list.length
    ? _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "ui-text ui-text_h6 uiKitText--text pa-2" },
            [_vm._v("\n    " + _vm._s(_vm.title) + "\n  ")]
          ),
          _vm._v(" "),
          _c(
            "VList",
            _vm._l(_vm.list, function (item, index) {
              return _c(
                "VListItem",
                {
                  key: index,
                  on: {
                    click: function ($event) {
                      return _setup.handleClickItem(item)
                    },
                  },
                },
                [
                  _c("VListItemContent", { staticClass: "py-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-1 uiKitText--text text-break",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(item.name) + "\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-2 uiKitTextSecondary--text",
                      },
                      [
                        _setup.redirectValue === "club" &&
                        item.clubPrice &&
                        _vm.isLpuAppointmentTypeChosen
                          ? _c(
                              "span",
                              { staticClass: "uiKitTextError--text mr-1" },
                              [_vm._v(_vm._s(item.clubPrice))]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        item.isWorkplace
                          ? _c(
                              "span",
                              {
                                class: {
                                  "text-decoration-line-through":
                                    _setup.redirectValue === "club" &&
                                    item.clubPrice &&
                                    _vm.isLpuAppointmentTypeChosen,
                                },
                              },
                              [_vm._v(_vm._s(_setup.formattedPrice(item)))]
                            )
                          : _c(
                              "span",
                              {
                                class: {
                                  "text-decoration-line-through":
                                    _setup.redirectValue === "club" &&
                                    item.clubPrice &&
                                    _vm.isLpuAppointmentTypeChosen,
                                },
                              },
                              [_vm._v(_vm._s(item.price))]
                            ),
                        _vm._v(" "),
                        item.agesText
                          ? [
                              _c(
                                "span",
                                { staticClass: "uiKitBgGray80--text mx-1" },
                                [_vm._v("•")]
                              ),
                              _vm._v(
                                "\n            " +
                                  _vm._s(item.agesText) +
                                  "\n          "
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "VListItemIcon",
                    { staticClass: "align-self-auto" },
                    [
                      _c("VIcon", [
                        _vm._v("\n          ui-icon-arrow-right\n        "),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }