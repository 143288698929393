function getCurrentMoscowDate() {
  const moscowDate = new Date()
  const timezoneOffsetToServer = moscowDate.getTimezoneOffset() + 180

  moscowDate.setMinutes(moscowDate.getMinutes() + timezoneOffsetToServer)

  return moscowDate
}

export default getCurrentMoscowDate
