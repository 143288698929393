var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "metro-list ui-text ui-text_body-2 pl-8",
      attrs: { "data-qa": "metro_list" },
    },
    _vm._l(
      _vm.lpuAddress.lpu.lpumetro_set.all.slice(0, 3),
      function (metro, metroIndex) {
        return _c(
          "div",
          {
            key: metro.metro.name,
            staticClass:
              "d-flex align-items-center ui-kit-color-text-secondary",
            class: {
              "mt-1": !!metroIndex,
            },
          },
          [
            _c("span", {
              staticClass: "d-inline-block rounded-circle pa-1 mr-2",
              style: { "background-color": metro.metro.color || "" },
            }),
            _vm._v(" "),
            _c("span", { attrs: { "data-qa": "metro_name" } }, [
              _vm._v(_vm._s(metro.metro.name)),
            ]),
            _vm._v(" "),
            metro.walk_time !== null
              ? [
                  metro.walk_time < 60
                    ? [
                        _c("span", {
                          staticClass:
                            "ui-icon-pedestrian ui-icon_fz_smaller ml-2 mr-1",
                        }),
                        _vm._v(" "),
                        _c("span", { attrs: { "data-qa": "metro_distance" } }, [
                          _vm._v("1 мин"),
                        ]),
                      ]
                    : [
                        _c("span", {
                          staticClass:
                            "ui-icon-pedestrian ui-icon_fz_smaller ml-2 mr-1",
                        }),
                        _vm._v(" "),
                        _c("span", { attrs: { "data-qa": "metro_distance" } }, [
                          _vm._v(
                            _vm._s(Math.floor(metro.walk_time / 60)) + " мин"
                          ),
                        ]),
                      ],
                ]
              : _vm._e(),
          ],
          2
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }