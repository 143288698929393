var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.title,
          expression: "title",
        },
      ],
      class: [_vm.blockClassList, _vm.buttonClassList],
      style: {
        width: _vm.blockWidth,
      },
      attrs: { "data-qa": "select_module_button" },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("div", { class: _vm.namespace.content }, [
        _c(
          "div",
          {
            staticClass: "ui-text ui-text_body-1 ui-kit-color-text",
            class: [_vm.namespace.title, _vm.namespace.textLimit],
            attrs: { "data-qa": "select_module_button_title" },
          },
          [_vm._v("\n      " + _vm._s(_vm.title) + "\n    ")]
        ),
        _vm._v(" "),
        _vm.subtitle
          ? _c(
              "div",
              {
                staticClass: "ui-text ui-text_body-2 ui-kit-color-text-info",
                class: [_vm.namespace.subtitle, _vm.namespace.textLimit],
                attrs: { "data-qa": "select_module_button_subtitle" },
              },
              [
                _vm._t("subtitle", function () {
                  return [
                    _vm._v("\n        " + _vm._s(_vm.subtitle) + "\n      "),
                  ]
                }),
              ],
              2
            )
          : _vm._e(),
        _vm._v(" "),
        _c("span", { class: _vm.namespace.arrow }, [
          _c("span", {
            staticClass: "ui-icon-arrow-down ui-kit-color-text-secondary",
            class: _vm.namespace.icon,
            attrs: { "data-qa": "select_module_button_arrow" },
          }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }