<template>
  <SideMenu
    :title="sideMenuData.title"
    :side-menu-width="sideMenuData.contentWidth"
    :value="isTopDialog(componentName)"
    :z-index="210"
    side-menu-auto-scroll-y
    side-menu-is-block
    @side-menu:click-close="handleDialogClose"
  >
    <template
      v-if="isFFPdClubComponentsUpdate"
      #titleIcon
    >
      <img
        src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
        class="ml-1"
        width="24"
        height="24"
        alt="Иконка Процент Клуба"
      >
    </template>
    <div class="p-6">
      <template v-if="isFFPdClubComponentsUpdate">
        <div class="ui-text ui-text_h6 ui-kit-color-text mb-2">
          Что даёт клуб
        </div>
        <div class="ui-text ui-text_body-1 ui-kit-color-text">
          Возможность получить скидку 30% на консультацию у врача.
        </div>
        <div class="ui-text ui-text_h6 ui-kit-color-text mt-8 mb-2">
          Как получить скидку
        </div>
        <div class="b-club-list ui-text ui-text_body-1 ui-kit-color-text">
          <div class="b-club-list__item">
            Оплачиваете на сайте ПроДокторов 50% стоимости консультации по цене со скидкой
          </div>
          <div class="b-club-list__item">
            В день визита оплачиваете в клинике оставшиеся 50%
          </div>
        </div>
        <div class="ui-text ui-text_h6 ui-kit-color-text mt-8 mb-2">
          Как вступить в клуб
        </div>
        <div class="ui-text ui-text_body-1 ui-kit-color-text mb-4">
          Доступ к клубу стоит 99 ₽ на 6 месяцев.
        </div>
        <div class="ui-text ui-text_body-1 ui-kit-color-text">
          Но первые 6 месяцев вы получаете в подарок!
        </div>
      </template>
      <template v-else>
        <img
          :src="sideMenuData.mainImagePath"
          :alt="sideMenuData.title"
          width="273px"
          height="223px"
          class="d-block mt-4 mb-3 mx-auto"
        >
        <div
          v-for="({ extra, title, description }, index) in staticData"
          :key="`${componentName}DescriptionItem${index}`"
        >
          <div class="ui-text ui-text_h6 ui-kit-color-text mt-6 mb-2">
            {{ title }}
          </div>
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="description" />
          <div
            v-if="extra"
            class="ui-text ui-text_caption ui-kit-color-text-secondary mt-2"
          >
            {{ extra }}
          </div>
        </div>
      </template>
      <VDivider class="my-8" />
      <div class="ui-text ui-text_h6 ui-kit-color-text mb-4">
        Вопросы и ответы
      </div>
      <VExpansionPanels multiple>
        <VExpansionPanel
          v-for="(item, index) in expansionPanel"
          :key="`${componentName}ExpansionItem${index}`"
        >
          <VExpansionPanelHeader
            :expand-icon="vuetifyIcons.arrowDown"
            class="text-left justify-content-between"
          >
            <span class="ui-text ui-text_body-1">{{ item.title }}</span>
          </VExpansionPanelHeader>
          <VExpansionPanelContent class="ui-text ui-text_body-1">
            {{ item.description }}
          </VExpansionPanelContent>
        </VExpansionPanel>
      </VExpansionPanels>
      <div class="mt-10 mb-4">
        <div class="ui-text ui-text_body-2 ui-kit-color-text-secondary">
          © 2011 — {{ nowYear }}, ООО «МедРейтинг»
        </div>
        <div class="d-block ui-text ui-text_body-2 ui-kit-color-text-secondary mt-2">
          Оплачивая, вы соглашаетесь
          с&nbsp;<a
            :href="pdfData.coupon"
            rel="noopener noreferrer"
            target="_blank"
            class="ui-kit-color-text-secondary"
          >офертой</a>
          и&nbsp;<a
            :href="pdfData.participation"
            rel="noopener noreferrer"
            target="_blank"
            class="ui-kit-color-text-secondary"
          >правилами клуба</a>
        </div>
      </div>
    </div>
  </SideMenu>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'

import { ClubEventsNames } from 'clubCommonMixins'

import SideMenu from 'components/desktop/core/SideMenu'
import DialogManager from 'components/common/mixins/DialogManager'
import ClubInfoModalLogic from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalLogic'
import ClubInfoModalStaticData from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalStaticData'
import ClubInfoModalExpansionPanel from 'components/common/Club/components/dependencies/ClubInfoModal/mixins/ClubInfoModalExpansionPanel'

export default {
  name: 'ClubDesktopInfoModal',
  vuetify,
  components: {
    SideMenu,
  },
  mixins: [
    DialogManager,
    ClubEventsNames,
    ClubInfoModalLogic,
    ClubInfoModalStaticData,
    ClubInfoModalExpansionPanel,
  ],
  data: () => ({
    isFFPdClubComponentsUpdate: window.FEATURE_FLAGS.pd_club_components_update,
  }),
  mounted() {
    window.addEventListener(this.eventClubSelectInfoModal, this.handleSelectInfoModal)
  },
}
</script>
