import { declensionByNum } from 'utils'
import {
  BUTTON_CLOSE_POPUP_CLASS,
  CLUSTER_POPUP_CLASSES,
  LPU_WORD_CASES,
} from 'components/common/PopupMap/constants'
import { getLpuItemTemplate } from 'components/common/PopupMap/functions/common/getLpuItemTemplate'

function getClusterBalloonTemplate(cluster) {
  const quantityLpus = cluster.getGeoObjects().length
  let lpuItemsTemplate = ''

  cluster.getGeoObjects().forEach(placemark => {
    lpuItemsTemplate += getLpuItemTemplate(placemark.options.get('lpu'))
  })

  return `
        <div class="py-6 pl-6">
            <div
                class="ui-text ui-text_subtitle-1"
                data-qa="popup_map_quantity_lpus_text"
            >
                ${quantityLpus} ${declensionByNum(quantityLpus, LPU_WORD_CASES)}
            </div>
            <div class="mt-2 mr-6 d-flex">
                <span class="mr-6 ui-kit-color-text-info ui-icon-map-pin"></span>
                <div
                    class="ui-text ui-text_body-1"
                    data-qa="popup_map_address_lpu_text"
                >
                    ${cluster.getGeoObjects()[0].options.get('lpu').address}
                </div>
            </div>
            <div class="pr-1">
                <div
                    class="mt-4 ${CLUSTER_POPUP_CLASSES.containerLpus}"
                    data-qa="popup_map_lpus_container"
                >
                    <div class="mr-6">
                        ${lpuItemsTemplate}
                    </div>
                </div>
            </div>
            <button
                type="button"
                class="b-button b-button_text w-100 mt-4 ${BUTTON_CLOSE_POPUP_CLASS}"
                data-qa="popup_map_close_button"
            >
                <span class="ui-text ui-kit-color-text ui-icon-close-not-a-circle mr-2"></span>
                <span class="ui-text ui-text_button ui-kit-color-text">Закрыть</span>
            </button>
        </div>
    `
}

export default getClusterBalloonTemplate
