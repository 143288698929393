<template>
  <CalendarStub v-if="isEmptyCalendar" />
  <div v-else>
    <AppointmentCalendar
      v-bind="isMobile ? {
        slots: calendarSlots,
      } : {
        data: calendarSlots,
        days: Object.keys(calendarSlots),
      }"
      @calendar:time-choice="handleCalendarTimeChoice"
    />
    <div
      v-show="selectedItem.isWorkplace"
      class="ui-text primary--text text-center cursor-pointer"
      :class="[isMobile ? 'ui-text_body-2' : 'ui-text_body-1 text-decoration-underline mt-4']"
      @click="openFullCalendar"
    >
      Посмотреть полное расписание
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { storeToRefs } from 'pinia'
import AppointmentCalendarDesktop from 'components/desktop/AppointmentCalendar/AppointmentCalendar'
import AppointmentCalendarMobile from 'components/mobile/AppointmentCalendar/AppointmentCalendar'
import { useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import CalendarStub from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/components/CalendarStub'

const props = defineProps({
  lpuId: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['calendar-time-choice'])

const externalDataStore = useExternalDataStore()
const { isMobile } = storeToRefs(externalDataStore)

const AppointmentCalendar = isMobile.value ? AppointmentCalendarMobile : AppointmentCalendarDesktop

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuId)()
const {
  calendarSlots,
  selectedItem,
  isVisibleFullCalendar,
} = storeToRefs(doctorAppointmentCardStore)
const { requestCalendarSlots } = doctorAppointmentCardStore

const isEmptyCalendar = computed(() => Object.values(calendarSlots.value).every(times => !times.length))

function handleCalendarTimeChoice({ day, time }) {
  emit('calendar-time-choice', { day, time })
}

async function openFullCalendar() {
  await requestCalendarSlots({ days: 30, allSlots: true, isFullCalendar: true })
  isVisibleFullCalendar.value = true
}
</script>
