import { isAfter, isBefore, isSameDay } from 'date-fns'
import dayTimesPoints from 'modules/Filters/common/constants/day-times'

/**
 * @param timeString { String } - 'HH:MM'
 *
 * @return { Array }
 * */

function getTimeParse(timeString) {
  return timeString.split(':').map(x => parseInt(x, 10))
}

/**
 * @param time { String } - 'HH:MM'
 * @param intervalStart { String } - 'HH:MM'
 * @param intervalEnd { String } - 'HH:MM'
 *
 * @return { Boolean }
 * */

function isTimeInInterval(time, intervalStart, intervalEnd) {
  const [hours, minutes] = getTimeParse(time)
  const [endHours, endMinutes] = getTimeParse(intervalEnd)
  const [startHours, startMinutes] = getTimeParse(intervalStart)

  const compTime = hours * 60 + minutes
  const endTime = endHours * 60 + endMinutes
  const startTime = startHours * 60 + startMinutes

  return startTime <= compTime && compTime <= endTime
}

/**
 * @param dayStr { String } - 'YYYY-MM-DD'
 * @param dateFromStr { String } - 'YYYY-MM-DD'
 * @param dateToStr { String } - 'YYYY-MM-DD'
 *
 * @return { Boolean }
 * */

function isDayInInterval(dayStr, dateFromStr, dateToStr) {
  const day = new Date(dayStr)
  const dateTo = new Date(dateToStr)
  const dateFrom = new Date(dateFromStr)
  const isDateInInterval = isAfter(day, dateFrom) && isBefore(day, dateTo)

  return isSameDay(day, dateFrom) || isSameDay(day, dateTo) || isDateInInterval
}

/**
 * @param dayIntervalStr { String } - morning || evening || afternoon
 * @param slots { Array } - [{ time, free, duration }, {...}, ....]
 *
 * @return { Boolean } - Вернет true если хоть 1 слот содержится в 1 из 3 временных интервалов(утро, день, вечер)
 * */

function hasSlotsInTimeOfDay(dayIntervalStr = '', slots = []) {
  const dayIntervalArray = dayIntervalStr.split(',')
  const hasSlotsInInterval = (intervalStart, intervalEnd) => slots.some(slot => isTimeInInterval(
    slot.time,
    dayTimesPoints[intervalStart],
    dayTimesPoints[intervalEnd],
  ))

  /**
     * В ветках возвращаю только положительный результат до последней ветки
     * Массив "dayIntervalArray" может содержать несколько полей
     * Поэтому необходиом проверить каждое поле
     * Чтобы результат сопосталения интервалов был точным
     * */

  if (dayIntervalArray.includes('morning')) {
    const result = hasSlotsInInterval('MORNING_START', 'MORNING_END')

    if (result) {
      return result
    }
  }

  if (dayIntervalArray.includes('afternoon')) {
    const result = hasSlotsInInterval('AFTERNOON_START', 'AFTERNOON_END')

    if (result) {
      return result
    }
  }

  if (dayIntervalArray.includes('evening')) {
    return hasSlotsInInterval('EVENING_START', 'EVENING_END')
  }
}

/**
 * @return selectedDateTo { String } - 'YYYY-MM-DD'
 * @return selectedDayTime { String } - morning || evening || afternoon
 * @return selectedDateFrom { String } - 'YYYY-MM-DD'
 * */

function getSelectedDateFromSearch() {
  const queryString = decodeURIComponent(window.location.search)

  const patternDaytimeProp = /daytimes=(?<daytimes>[\w,]+)/
  const patternDateToProp = /date_to=(?<dateTo>\d{4}-\d{2}-\d{2})/
  const patternDateFromProp = /date_from=(?<dateFrom>\d{4}-\d{2}-\d{2})/

  return {
    selectedDateFrom: queryString.match(patternDateFromProp)?.groups.dateFrom,
    selectedDateTo: queryString.match(patternDateToProp)?.groups.dateTo,
    selectedDayTime: queryString.match(patternDaytimeProp)?.groups.daytimes,
  }
}

/**
 * @param daysWithSlots { Array } - массив строк с датами в формате ['YYYY-MM-DD', ...]
 * @param currentSlots { Object } - объект слотов, которые приходят в качестве ответа с МИС в формате {YYYY-MM-DD: [{time, free, duration}, ...], ...}
 *
 * @return { String } - 'YYYY-MM-DD'
 * */

function getInitialDay({ daysWithSlots, currentSlots } = {}) {
  const {
    selectedDateTo,
    selectedDayTime,
    selectedDateFrom,
  } = getSelectedDateFromSearch()

  if (selectedDateFrom && selectedDateTo && selectedDayTime) {
    return daysWithSlots.find(day => {
      const hasDayInInterval = isDayInInterval(day, selectedDateFrom, selectedDateTo)
      const hasSlotsInDayInterval = hasSlotsInTimeOfDay(selectedDayTime, currentSlots[day])

      return hasDayInInterval && hasSlotsInDayInterval
    })
  }

  if (selectedDateFrom && selectedDateTo) {
    return daysWithSlots.find(day => isDayInInterval(day, selectedDateFrom, selectedDateTo))
  }

  if (selectedDayTime) {
    return daysWithSlots.find(day => hasSlotsInTimeOfDay(selectedDayTime, currentSlots[day]))
  }
}

export default getInitialDay
