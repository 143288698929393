export default {
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
    arrivalAddress: {
      type: Array,
      required: true,
    },
    boundsAvailableRoutes: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    departureAddress: {
      text: '',
      coords: [],
    },
    errorMessage: '',
  }),
  watch: {
    isVisible(visible) {
      if (visible) {
        this.departureAddress.text = ''
        this.clearErrorMessagesAndDepartureCoords()
      }
    },
  },
  mounted() {
    const unwatchAddedSuggestView = this.$watch('isVisible', visible => {
      if (visible) {
        // свойство strictBounds было добавлено, потому что иначе свойство boundedBy перестает работать
        const suggestViewInstance = new ymaps.SuggestView(
          'departureAddressInput',
          {
            results: 2,
            strictBounds: true,
            boundedBy: this.boundsAvailableRoutes,
          },
        )

        suggestViewInstance.events.add('select', event => {
          this.clearErrorMessagesAndDepartureCoords()

          this.departureAddress.text = event.get('item')?.value || ''
        })

        unwatchAddedSuggestView()
      }
    })
  },
  methods: {
    checkDistanceRoute() {
      const distanceInMeters = ymaps.coordSystem.geo.getDistance(
        this.departureAddress.coords,
        this.arrivalAddress,
      )

      // смотрим на то, что конечная точка попадает в радиус 100км от выбранной больницы, так как иначе строить маршрут не имеет смысла
      if ((distanceInMeters / 1000) >= 100) {
        this.errorMessage = 'Слишком далеко от клиники'

        return false
      }

      return true
    },
    clearErrorMessagesAndDepartureCoords() {
      this.departureAddress.coords = []
      this.errorMessage = ''
    },
    handleCloseDepartureAddressPopup() {
      this.$emit('popup-departure-address:close')
    },
    async handlePlotRouteButton() {
      if (!this.departureAddress.coords.length) {
        const geocodeByText = await ymaps.geocode(this.departureAddress.text, {
          results: 1,
          boundedBy: this.boundsAvailableRoutes,
        })

        const determinatedGeoObject = geocodeByText.geoObjects.get(0)

        if (!determinatedGeoObject?.geometry) {
          this.errorMessage = 'Адрес не найден'

          return
        }

        this.departureAddress.coords = determinatedGeoObject.geometry.getCoordinates()
        this.departureAddress.text = determinatedGeoObject.properties.get('text')

        if (!this.checkDistanceRoute()) {
          return
        }
      }

      this.$emit('popup-departure-address:create-multi-route', this.departureAddress)
    },
    async determinateCoords() {
      this.clearErrorMessagesAndDepartureCoords()

      ymaps.geolocation.get({ provider: 'auto', autoReverseGeocode: true })
        .then(yaGeolacation => {
          this.departureAddress.coords = yaGeolacation.geoObjects.position
          this.departureAddress.text = yaGeolacation.geoObjects.get(0).properties.get('name')

          this.checkDistanceRoute()
        })
        .catch(() => {
          this.errorMessage = 'Адрес не найден'
        })
    },
  },
}
