<template>
  <div class="px-4 mb-2">
    <SelectModuleButton
      class="py-1"
      data-qa="service_select_synonym_button"
      :title="selectedSynonym.name"
      :subtitle="getFormattedPrice(selectedSynonym.price)"
      @click="handleClickSelect"
    >
      <template #subtitle>
        <template v-if="isUsedClub">
          <span
            v-if="formattedSelectedSynonym.priceWithDiscount"
            class="ui-text ui-kit-color-error pr-1"
          >
            {{ getFormattedPrice(formattedSelectedSynonym.priceWithDiscount) }}
          </span>
          <span :class="{ 'ui-text_line-through': formattedSelectedSynonym.priceWithDiscount }">
            {{ getFormattedPrice(selectedSynonym.price) }}
          </span>
        </template>
        <template v-else>
          {{ getFormattedPrice(selectedSynonym.price) }}
        </template>
      </template>
    </SelectModuleButton>
    <AlertDialog
      :active="isVisibleError"
      title="Произошла ошибка"
      content-text="Попробуйте ещё раз."
      close-text="Понятно"
      data-qa="service_select_synonym_error"
      @close="isVisibleError = false"
    />
    <VDialog
      :value="isVisibleModal"
      fullscreen
      persistent
      hide-overlay
      no-click-animation
      transition="fade-transition"
    >
      <ModalLayout
        :title="modalTitle"
        :close-to="isStackRoot"
        :back-to="!isStackRoot"
        data-qa="service_synonym_modal"
        @modal:back="handleClickModalBack"
      >
        <template #header>
          <SynonymListSearch
            ref="synonymListSearchComponent"
            :synonym-data="ajaxSynonymData"
            :is-loading="isLoadingSynonym"
            @search:found-results="handleSearchFoundResults"
          />
        </template>
        <SynonymListSearchEmpty
          v-if="isSearchResultsEmpty"
        />
        <SynonymListItems
          v-else
          :is-used-club="isUsedClub"
          :synonym-list="activeSynonymList"
          :diagnostic-level="diagnosticLevel"
          :is-loading-synonym="isLoadingSynonym"
          :is-search-results="hasSearchResults"
          @synonym-list:click-item="handleClickSynonymItem"
        />
      </ModalLayout>
    </VDialog>
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import ModalLayout from 'components/mobile/core/ModalLayout'
import AlertDialog from 'components/common/AlertDialog/AlertDialog'
import SelectModuleButton from 'components/common/SelectModule/SelectModuleModal/components/common/SelectModuleButton'
import SynonymListSearch from 'components/common/SynonymList/components/common/SynonymListSearch'
import SynonymListSearchEmpty from 'components/common/SynonymList/components/common/SynonymListSearchEmpty'
import SynonymListItems from 'components/common/SynonymList/components/common/SynonymListItems'
import SynonymListLogic from 'components/common/SynonymList/mixins/SynonymListLogic'

export default {
  name: 'SynonymList',
  vuetify,
  components: {
    ModalLayout,
    AlertDialog,
    SelectModuleButton,
    SynonymListSearch,
    SynonymListSearchEmpty,
    SynonymListItems,
  },
  mixins: [
    SynonymListLogic,
  ],
}
</script>
