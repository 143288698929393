import { EVENT_NAMES } from 'components/common/SelectModule/SelectModuleModal/constants'

export default {
  props: {
    customEventNames: {
      type: Object,
      default: () => EVENT_NAMES,
    },
  },
  data() {
    const eventNames = {
      ...EVENT_NAMES,
      ...this.customEventNames,
    }

    return {
      eventNames,
      eventClickSelect: eventNames.clickSelect,
      eventClickOption: eventNames.clickOption,
      eventClickModalClose: eventNames.clickModalClose,
    }
  },
}
