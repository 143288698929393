import {
  NAVIGATION_PANEL_CLASSES,
  TRANSPORT_ICONS,
} from 'components/common/PopupMap/constants'

function getIconTransportTemplate(iconClass) {
  return `
        <div
            class="${iconClass} ${NAVIGATION_PANEL_CLASSES.pin} ${NAVIGATION_PANEL_CLASSES.pinMasstransit}"
        ></div>
    `
}

function getMasstransitRoutesMiniInfoTemplate(multiRoute) {
  const {
    route,
    activeRoute,
    masstransitRoute,
  } = NAVIGATION_PANEL_CLASSES

  const indexActiveRoute = multiRoute.getActiveRoute().properties.get('index')
  const allRoutes = multiRoute.model.getRoutes()

  let routesTemplate = ''

  allRoutes.forEach((currentRoute, index) => {
    let miniInfoContentRoutes = ''

    currentRoute.getPaths().forEach(path => {
      path.getSegments().forEach(segment => {
        if (segment.properties.get('type') === 'walk' || segment.properties.get('type') === 'transfer') {
          // оберточный div необходим, чтобы в длинных маршрутах с большим количеством путей, все правильно переносилось
          miniInfoContentRoutes += `
                        <div class="d-flex align-center ui-text ui-kit-color-text-secondary">
                            <div class="ui-icon-pedestrian ui-text ui-text_body-1"></div>
                            <div 
                                class="mr-2 ui-text ui-text_body-2"
                                data-qa="popup_map_distance_masstransit_text"
                            >
                                ${segment.properties.get('distance').text}
                            </div>
                        </div>
                    `
        } else {
          const transportType = segment.properties.get('transports')[0].type
          const transportIcon = TRANSPORT_ICONS[transportType]

          miniInfoContentRoutes += getIconTransportTemplate(transportIcon)
        }
      })
    })

    const routeActiveClass = currentRoute.properties.get('index') === indexActiveRoute ? ` ${activeRoute}` : ''

    routesTemplate += `
            <div 
                data-route-index="${index}"
                class="cursor-pointer px-3 py-2 ${masstransitRoute} ${route}${routeActiveClass}"
                data-qa="popup_map_route_masstransit_info_container"
            >
                <div 
                    class="ui-text ui-text_body-1"
                    data-qa="popup_map_duration_masstransit_text"
                >
                    ${currentRoute.properties.get('duration').text}
                </div>
                <div class="d-flex align-center flex-wrap">
                    ${miniInfoContentRoutes}
                </div>
            </div>
        `
  })

  return `
        <div class="mt-4">
            ${routesTemplate}
        </div>
    `
}

export default getMasstransitRoutesMiniInfoTemplate
