var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isExistSoftVersion
    ? _c(
        "div",
        {
          staticClass: "d-inline-flex",
          style: { verticalAlign: "middle" },
          attrs: { "data-qa": "soft_version_icon" },
        },
        [
          _c("SoftVersionImage", {
            ref: "tooltipTrigger",
            attrs: { image: _vm.softData.image },
          }),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "tooltipContent",
              staticClass: "d-none",
              style: { maxWidth: "288px" },
            },
            [
              _c("div", { staticClass: "ui-text ui-text_subtitle-1 mb-1" }, [
                _vm._v(
                  "\n      Пользуется порталом с версией " +
                    _vm._s(_vm.softData.translate) +
                    "\n    "
                ),
              ]),
              _vm._v(" "),
              _c(
                "ul",
                { staticClass: "ui-text ui-text_body-1 pl-6" },
                _vm._l(_vm.listBenefits, function (benefit, i) {
                  return _c("li", { key: i }, [
                    _vm._v("\n        " + _vm._s(benefit) + "\n      "),
                  ])
                }),
                0
              ),
            ]
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }