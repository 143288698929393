var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "w-100 overflow-hidden": _vm.isMobile },
      attrs: { "data-qa": "doctor_service_prices_lpu_select_wrapper" },
    },
    [
      _c("SelectBottomSheet", {
        attrs: {
          items: _vm.lpuList,
          "selected-item": _vm.selectedLpuId,
          "select-props": {
            itemValue: "id",
            itemText: "name",
            itemTextSecondary: "address",
            class: [
              "doctor-service-prices-lpu-select",
              _vm.isMobile
                ? "doctor-service-prices-lpu-select_mobile"
                : "doctor-service-prices-lpu-select_desktop",
            ],
            menuProps: {
              attach: ".attach-doctor-service-prices-lpu-select-menu",
              contentClass: "menu-content",
              offsetY: true,
              bottom: true,
              maxWidth: "100%",
              maxHeight: "auto",
            },
          },
          "bottom-sheet-list-props": {
            maxHeight: "60vh",
          },
          "use-bottom-sheet": _vm.isMobile,
          "hide-details": "",
          "text-secondary-in-preview": "",
          "wide-items": "",
        },
        on: { "select:change": _vm.updateSelectedLpuId },
      }),
      _vm._v(" "),
      _c("div", {
        staticClass: "attach-doctor-service-prices-lpu-select-menu",
        attrs: { "data-qa": "doctor_service_prices_lpu_select_menu" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }