<template>
  <VSkeletonLoader
    class="search-synonym-skeleton"
    type="date-picker-options@9"
    data-qa="service_synonym_modal_skeleton"
  />
</template>

<script>
export default {
  name: 'SynonymListSkeleton',
}
</script>

<style lang="scss">
.search-synonym-skeleton {
  .v-skeleton-loader {
    &__text {
      flex-shrink: 1;
      max-width: initial !important;
      height: 20px;
      margin-right: 32px;
    }

    &__avatar {
      flex-shrink: 0;
      border-radius: 6px;
      flex-basis: 24px;
      width: 24px;
      height: 20px;
    }

    &__date-picker-options {
      padding: 16px 8px;

      .v-skeleton-loader__avatar:last-child {
        display: none;
      }
    }
  }
}
</style>
