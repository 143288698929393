import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import {
  isJSON,
  dispatchCustomEventGlobally,
} from 'utils'
import { POPUP_MAP_EVENTS } from 'www/constants/eventNames'
import {
  MOUNT_SELECTOR,
  DATA_LPUS_ATTR_NAME,
} from 'components/common/PopupMap/constants'
import ClickListener from 'modules/ClickListener'
import PopupMapMobile from 'components/common/PopupMap/components/mobile/PopupMapMobile'
import PopupMapDesktop from 'components/common/PopupMap/components/desktop/PopupMapDesktop'
import 'www/vue_components/common/PopupMap/index.scss'
import { addMapButtonReadyClass } from 'components/common/PopupMap/functions/utils'

const eventNameOpenPopup = window.MOBILE_VERSION
  ? POPUP_MAP_EVENTS.openMobile
  : POPUP_MAP_EVENTS.openDesktop

const component = window.MOBILE_VERSION
  ? PopupMapMobile
  : PopupMapDesktop

const mountElement = document.querySelector(MOUNT_SELECTOR)

window.addEventListener(POPUP_MAP_EVENTS.mounted, () => {
  if (window.POPUP_MAP_DATA) {
    window.POPUP_MAP_DATA.isMounted = true
  } else {
    window.POPUP_MAP_DATA = { isMounted: true }
  }

  addMapButtonReadyClass()
})

if (mountElement) {
  new Vue({
    el: mountElement,
    name: 'PopupMapRoot',
    vuetify,
    render: h => h(component),
  })

  new ClickListener({
    click: [{
      target: `[${DATA_LPUS_ATTR_NAME}]`,
      handler: ({ currentTarget }) => {
        const dataLpus = currentTarget.getAttribute(DATA_LPUS_ATTR_NAME)

        if (!dataLpus || !isJSON(dataLpus)) {
          return
        }

        window.history.pushState(null, null, new URL('', window.location.href))

        dispatchCustomEventGlobally(eventNameOpenPopup, {
          lpusData: JSON.parse(dataLpus),
        })
      },
    }],
  })
}
