import { declensionByNum } from 'utils'
import { BUTTON_CLOSE_POPUP_CLASS, CLUSTER_POPUP_CLASSES, LPU_WORD_CASES } from 'components/common/PopupMap/constants'
import { getLpuItemTemplate } from 'components/common/PopupMap/functions/common/getLpuItemTemplate'

export default function getAllPlacemarksBalloonTemplates(lpus) {
  let lpuItemsTemplate = ''
  const quantityLpus = lpus.length

  lpus.forEach(lpu => {
    lpuItemsTemplate += getLpuItemTemplate(lpu)
  })

  return `
        <div class="py-6 pl-6">
            <div
                class="ui-text ui-text_h6"
                data-qa="popup_map_quantity_lpus_text"
            >
                ${quantityLpus} ${declensionByNum(quantityLpus, LPU_WORD_CASES)}
            </div>
            <div class="pr-1">
                <div
                    class="mt-6 ${CLUSTER_POPUP_CLASSES.containerLpus}"
                    data-qa="popup_map_lpus_container"
                >
                    <div class="mr-6">
                        ${lpuItemsTemplate}
                    </div>
                </div>
            </div>
             <button
                 type="button"
                 class="b-button b-button_text w-100 mt-4 ${BUTTON_CLOSE_POPUP_CLASS}"
                 data-qa="popup_map_close_button"
             >
                <span class="ui-text ui-kit-color-text ui-icon-close-not-a-circle mr-2"></span>
                <span class="ui-text ui-text_button ui-kit-color-text">Закрыть</span>
            </button>
        </div>
    `
}
