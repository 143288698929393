<template>
  <div
    data-qa="doctor_service_prices_lpu_select_wrapper"
    :class="{ 'w-100 overflow-hidden': isMobile }"
  >
    <SelectBottomSheet
      :items="lpuList"
      :selected-item="selectedLpuId"
      :select-props="{
        itemValue: 'id',
        itemText: 'name',
        itemTextSecondary: 'address',
        class: [
          'doctor-service-prices-lpu-select',
          isMobile ? 'doctor-service-prices-lpu-select_mobile' : 'doctor-service-prices-lpu-select_desktop',
        ],
        menuProps: {
          attach: '.attach-doctor-service-prices-lpu-select-menu',
          contentClass: 'menu-content',
          offsetY: true,
          bottom: true,
          maxWidth: '100%',
          maxHeight: 'auto',
        },
      }"
      :bottom-sheet-list-props="{
        maxHeight: '60vh',
      }"
      :use-bottom-sheet="isMobile"
      hide-details
      text-secondary-in-preview
      wide-items
      @select:change="updateSelectedLpuId"
    />
    <div
      data-qa="doctor_service_prices_lpu_select_menu"
      class="attach-doctor-service-prices-lpu-select-menu"
    />
  </div>
</template>

<script>
import { getLpuList } from 'components/common/DoctorServicePrices/components/LpuSelect/interfaces'
import SelectBottomSheet from 'components/common/core/SelectBottomSheet/SelectBottomSheet'

export default {
  name: 'LpuSelect',
  components: { SelectBottomSheet },
  model: {
    prop: 'selectedLpuId',
    event: 'change',
  },
  props: {
    lpuList: {
      type: Array,
      default: () => getLpuList(),
    },
    selectedLpuId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  methods: {
    updateSelectedLpuId(value) {
      this.$emit('change', value)
    },
  },
}
</script>

<style lang="scss">
.attach-doctor-service-prices-lpu-select-menu {
  position: relative;

  & > .menu-content {
    left: 0 !important;
    top: 0 !important;
  }
}

.doctor-service-prices-lpu-select {
  white-space: nowrap !important;
  text-overflow: ellipsis !important;
  min-height: 56px;

  &_desktop {
    width: 359px;
  }

  &_mobile {
    // Исправляет отображение выделения селекта с `overflow-hidden`
    padding-top: 1px !important;
  }
}
</style>
