import { AppointmentModules } from 'modules/appointment-modules'
import { getItemDataWithParent } from 'modules/Schedule/functions/helpers'

const { redirectToNewAppointment } = new AppointmentModules()

/**
 * @description
 * Производит редирект в форму записи врача при клике на кнопку 'Записаться'
 *
 * @param { Object } opts
 * @param { Object } opts.data - интерфейс 'getModuleDataInterface'
 * @param { String } opts.visitType - значение, которое возвращает 'getVisitType' - тип приема(клуб, онлайн, на дом)
 * @param { String } opts.dataItem - data атрибут родителя
 * @param { HTMLElement } opts.buttonNode - узел кнопки
 * */

const redirectToDoctorApp = opts => {
  const {
    data,
    dataItem,
    visitType,
    buttonNode,
  } = opts
  const [, itemData] = getItemDataWithParent({ data, target: buttonNode })

  let doctorSpec

  if (!data.items.size || !itemData) {
    doctorSpec = buttonNode // если пользователь находится в мобильной версии на странице врача - он может выбрать специальность в 'select'
      ?.closest(`[${dataItem}]`)
      ?.querySelector('[data-selected-speciality-id]')
      ?.getAttribute('data-selected-speciality-id') // ToDo schedule-important избавиться от привязки к DOM дереву

    // Это означает, что расписания у врача нет и редирект происходит по кнопке.
    // Данные со страницы не были собраны, поэтому приходится ориентироваться на значения атрибутов на странице.
    redirectToNewAppointment({
      type: visitType,
      lpu: buttonNode.closest('[data-lpu-id]')?.getAttribute('data-lpu-id'), // ToDo schedule-important избавиться от привязки к DOM дереву
      doctor: buttonNode.closest('[data-doctor]')?.getAttribute('data-doctor'), // ToDo schedule-important избавиться от привязки к DOM дереву
      doctorSpec,
      spec_placement: buttonNode.closest('[data-special-placement]')?.getAttribute('data-special-placement'), // ToDo schedule-important избавиться от привязки к DOM дереву
    })

    return
  }

  const {
    doctorId,
    coverNode,
    doctorSpecialtyId,
    hasSpecialPlacement,
    lpuId,
  } = itemData

  const chosenLpuId = Number(coverNode.dataset.wpBlockId || coverNode.querySelector('option:checked')?.dataset.lpu || lpuId) // ToDo schedule-important избавиться от привязки к DOM дереву

  doctorSpec = coverNode // если пользователь находится в мобильной версии на странице врача - он может выбрать специальность в 'select'
    .querySelector('[data-selected-speciality-id]')
    ?.getAttribute('data-selected-speciality-id') // ToDo schedule-important избавиться от привязки к DOM дереву

  redirectToNewAppointment({
    type: visitType,
    lpu: chosenLpuId,
    doctor: doctorId,
    doctorSpec: doctorSpecialtyId || doctorSpec, // doctorSpecialtyId для страницы списка врачей, doctorSpec для страницы врача
    spec_placement: hasSpecialPlacement,
  })
}

export default redirectToDoctorApp
