export default {
  props: {
    lpuId: {
      type: [Number, null],
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    selectedOption: {
      type: [Object, null],
      default: () => null,
    },
    modalTitle: {
      type: String,
      default: '',
    },
    selectButtonClass: {
      type: String,
      default: '',
    },
    modalLimitTitle: {
      type: Boolean,
      default: true,
    },
    isVisibleModal: {
      type: Boolean,
      default: false,
    },
    prependIcon: {
      type: [String, Function],
      default: '',
    },
    appendIcon: {
      type: [String, Function],
      default: '',
    },
    transformOptionTitle: {
      type: Function,
      default: t => t,
    },
  },
}
