import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import ReviewsFilter from './ReviewsFilter'

const instance = new Vue({
  vuetify,
  components: {
    ReviewsFilter,
  },
})

export default instance
