export default {
  props: {
    initSelectedSynonym: {
      type: Object,
      default: () => ({}),
    },
    classTranslit: {
      type: String,
      required: true,
    },
    lpuId: {
      type: [Number, String],
      required: true,
    },
    classId: {
      type: Number,
      required: true,
    },
    categoryId: {
      type: Number,
      default: null,
    },
    typeId: {
      type: Number,
      default: null,
    },
    isUsedClub: {
      type: Boolean,
      default: false,
    },
  },
}
