<template>
  <VDialog
    v-model="isVisibleModal"
    fullscreen
    data-qa="popup_map_popup_container"
    @keydown.esc="closeModalWithBackHistory"
  >
    <VBtn
      :color="vuetifyColors.primary"
      icon
      width="32"
      height="32"
      class="ui-kit-bg-bg-gray-0 popup-map__close-button"
      data-qa="popup_map_close_top_button"
      @click="closeModalWithBackHistory"
    >
      <VIcon
        size="20"
      >
        ui-icon-close-not-a-circle
      </VIcon>
    </VBtn>
    <div
      :id="mapId"
      class="ui-kit-bg-bg-gray-0 popup-map__map-container"
      data-qa="popup_map_map_container"
    />
    <div class="popup-map__bottom-button-container">
      <VBtn
        :color="vuetifyColors.primary"
        block
        data-qa="popup_map_close_bottom_button"
        @click="closeModalWithBackHistory"
      >
        <span class="ui-text ui-text_body-1 ui-kit-color-bg-gray-0">
          Закрыть карту
        </span>
      </VBtn>
    </div>
    <PopupDepartureAddressMobile
      :is-visible="isVisibleDepartureAddressPopup"
      :bounds-available-routes="boundsAvailableRoutes"
      :arrival-address="getArrivalAddress"
      @popup-departure-address:close="closeDepartureAddressPopup"
      @popup-departure-address:create-multi-route="createMultiRouteInstance"
    />
    <div
      :ref="navigationPanelBlock"
      :class="navigationPanelBlock"
    />
  </VDialog>
</template>

<script>
import { NAVIGATION_PANEL_CLASSES } from 'components/common/PopupMap/constants'
import { PopupMapLogic } from 'components/common/PopupMap/mixins'
import PopupDepartureAddressMobile from 'components/common/PopupMap/components/mobile/PopupDepartureAddressMobile'

export default {
  name: 'PopupMapMobile',
  components: { PopupDepartureAddressMobile },
  mixins: [PopupMapLogic],
  data: () => ({
    navigationPanelBlock: NAVIGATION_PANEL_CLASSES.block,
  }),
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.popup-map {
  &__close-button {
    position: fixed;
    top: 16px;
    right: 16px;
    z-index: 2;

    @include new-elevation-1;
  }

  &__map-container {
    height: calc(100% - 56px);
  }

  &__bottom-button-container {
    padding: 8px;
    background-color: $ui-kit-bg-gray-0;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
      0 8px 10px 1px rgba(0, 0, 0, 0.14),
      0 3px 14px 2px rgba(0, 0, 0, 0.12);
  }
}

.navigation-panel {
  background-color: $ui-kit-bg-gray-0;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
}
</style>
