var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isMobile
    ? _c("div", [
        _vm.lpuAddress.show_phone && _vm.lpuAddress.lpu.lpuphone?.phone
          ? _c(
              "a",
              {
                directives: [{ name: "ripple", rawName: "v-ripple" }],
                staticClass:
                  "d-flex align-center text-decoration-none ui-text ui-text_body-1 ui-kit-color-primary py-2 mt-2",
                attrs: {
                  "data-qa": "lpu_card_btn_phone",
                  "data-ya-goals-appointment":
                    "mobile_appointment__phone_action",
                  href: `tel:+7${_setup.clearPhone}`,
                  "data-club-appointment-notice": /* TODO: переписать с импортом компонента */ `{
      "isClub": "${_vm.lpuAddress.tabs_data?.in_club}",
      "clubDiscount": "${_vm.lpuAddress.tabs_data?.club_discount}",
      "price": "${_vm.lpuAddress.tabs_data?.club_price}",
      "priceFull": "${_vm.lpuAddress.tabs_data?.price}",
      "phoneNumber": "${_vm.lpuAddress.lpu.lpuphone.phone}",
      "appointmentLink":
        "${`${_setup.urls?.createAppointmentForm}?doctor=${_setup.doctor?.id}&type=club&lpu=${_vm.lpuAddress.lpu.id}`}",
      "infoOption": "callDoctor"
    }`,
                },
                on: { click: _setup.handleClickPhone },
              },
              [
                _c(
                  "VIcon",
                  {
                    staticClass: "mr-4",
                    attrs: { size: "20", color: "uiKitIconSecondary" },
                  },
                  [_vm._v("\n      ui-icon-phone-call\n    ")]
                ),
                _vm._v(
                  "\n    " +
                    _vm._s(_setup.getPhoneFormatted(_setup.phone)) +
                    "\n  "
                ),
              ],
              1
            )
          : _vm.lpuAddress.lpu.is_show_registration_phone
          ? _c("div", [
              _c(
                "a",
                {
                  directives: [{ name: "ripple", rawName: "v-ripple" }],
                  staticClass:
                    "d-flex align-center text-decoration-none ui-text ui-text_body-1 ui-kit-color-primary py-2 mt-2",
                  attrs: { href: `tel:+7${_setup.clearPhone}` },
                },
                [
                  _c(
                    "VIcon",
                    {
                      staticClass: "mr-4",
                      attrs: { size: "20", color: "uiKitIconSecondary" },
                    },
                    [_vm._v("\n        ui-icon-phone-call\n      ")]
                  ),
                  _vm._v(
                    "\n      " +
                      _vm._s(_setup.getPhoneFormatted(_setup.phone)) +
                      "\n    "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex align-center ui-text ui-text_body-1 py-2 mt-2",
                },
                [
                  _c(
                    "VIcon",
                    {
                      staticClass: "mr-4",
                      attrs: { size: "20", color: "uiKitIconSecondary" },
                    },
                    [_vm._v("\n        ui-icon-wallet\n      ")]
                  ),
                  _vm._v("\n      По ОМС приём бесплатный\n    "),
                ],
                1
              ),
            ])
          : _vm._e(),
      ])
    : _c("div", [
        (_vm.lpuAddress.show_phone && _vm.lpuAddress.lpu.lpuphone?.phone) ||
        _vm.lpuAddress.lpu.is_show_registration_phone
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex align-center text-decoration-none ui-text ui-text_body-1 py-2 mt-2",
              },
              [
                _c(
                  "VIcon",
                  {
                    staticClass: "mr-4",
                    attrs: { size: "20", color: "uiKitIconSecondary" },
                  },
                  [_vm._v("\n      ui-icon-phone-call\n    ")]
                ),
                _vm._v(
                  "\n    " +
                    _vm._s(_setup.getPhoneFormatted(_setup.phone)) +
                    "\n  "
                ),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.lpuAddress.lpu.is_show_registration_phone
          ? _c(
              "div",
              {
                staticClass:
                  "d-flex align-center ui-text ui-text_body-1 py-2 mt-2",
              },
              [
                _c(
                  "VIcon",
                  {
                    staticClass: "mr-4",
                    attrs: { size: "20", color: "uiKitIconSecondary" },
                  },
                  [_vm._v("\n      ui-icon-wallet\n    ")]
                ),
                _vm._v("\n    По ОМС приём бесплатный\n  "),
              ],
              1
            )
          : _vm._e(),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }