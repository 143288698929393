export const MARKUP_LOOKUP_DATA_ATTR = 'data-popup-content'
export const DATA_POPUP_CONTENT_TITLE = 'data-popup-content-title'

export const PAGINATION_TYPE = {
  dynamic: 'dynamic',
  fraction: 'fraction',
}

export const CONTENT_TYPE = {
  photo: 'photo',
  video: 'video',
}

export const VIDEO_AUTOPLAY = {
  on: 'autoplay=1',
  off: 'autoplay=0',
}
