<template>
  <div
    class="search-synonym"
    data-qa="service_synonym_search"
  >
    <VSkeletonLoader
      v-if="isLoading"
      class="search-synonym__skeleton"
      type="text"
      data-qa="service_synonym_search_skeleton"
    />
    <div
      v-else
      class="v-application v-application--is-ltr theme--light"
    >
      <VTextField
        v-model.trim="searchQuery"
        outlined
        clearable
        full-width
        hide-details
        placeholder="Найти"
        clear-icon="ui-icon-close-not-a-circle"
        prepend-inner-icon="ui-icon-search"
        data-qa="service_synonym_search_input"
        @input="handleInputDebounced"
        @click:clear="handleClearInput"
      />
    </div>
  </div>
</template>

<script>
import { debounce, escapeStringRegexp } from 'utils'

export default {
  name: 'SynonymListSearch',
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    synonymData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    searchQuery: '',
    handleInputDebounced: () => {},
  }),
  computed: {
    synonymList() {
      return this.getSynonymList(this.synonymData)
    },
  },
  created() {
    this.handleInputDebounced = debounce(this.makeResultList, 300)
  },
  methods: {
    makeResultList() {
      if (!this.searchQuery) {
        this.$emit('search:found-results', { listResult: null })
        return
      }

      const uniqueSynonyms = {}
      const listResult = this.synonymList.filter(synonym => {
        if (uniqueSynonyms[synonym.id]) {
          return false
        }

        uniqueSynonyms[synonym.id] = true

        return synonym.name.toLocaleLowerCase().includes(this.searchQuery.toLocaleLowerCase())
      })
      const regExp = new RegExp(`(${escapeStringRegexp(this.searchQuery)})`, 'ig')

      listResult.forEach(synonym => {
        synonym.nameWithMarkedQuery = synonym.name.replace(regExp, '<span class="ui-text ui-kit-color-primary">$1</span>')
      })

      this.$emit('search:found-results', { listResult })
    },
    /**
         * Функция возвращает список синонимов.
         * Синонимами считаются те объекты, которые не содержат в себе свойство `sublist`. Поэтому
         * функция рекурсивно проходит по объектам, и возвращает те которые без `sublist`
         * */
    getSynonymList(synonymData) {
      if (!synonymData) {
        return []
      }

      if (!synonymData.sublist) {
        return [synonymData]
      }

      if (!synonymData.sublist[0]?.sublist) {
        return synonymData.sublist
      }

      const synonyms = []

      synonymData.sublist.forEach(listItem => {
        synonyms.push(...this.getSynonymList(listItem))
      })

      return synonyms
    },
    handleClearInput() {
      if (this.searchQuery) {
        this.searchQuery = ''
        this.makeResultList()
      }
    },
  },
}
</script>

<style lang="scss">
.search-synonym {
  &__skeleton .v-skeleton-loader__text {
    height: 56px;
    margin-bottom: 0;
  }
}
</style>
