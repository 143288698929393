import { HIDDEN } from 'constants/classNames'

const BLOCK_NAME = 'b-recommendations'
const CLASS_NAMES = {
  moreBtn: `${BLOCK_NAME}__more-btn`,
  moreBtnContainer: `${BLOCK_NAME}__more-btn-container`,
  list: `${BLOCK_NAME}__list`,
}

const getBlockNode = childElement => childElement.closest(`.${BLOCK_NAME}`)

const getRecommendationNodes = blockNode => [...blockNode.querySelector(`.${CLASS_NAMES.list}`).children]

const showItem = item => item.classList.remove(HIDDEN)

const removeMoreButton = button => button.closest(`.${CLASS_NAMES.moreBtnContainer}`).remove()

const moreBtnClickHandler = ({ target }) => {
  getRecommendationNodes(getBlockNode(target)).forEach(showItem)
  removeMoreButton(target)
};

[...document.querySelectorAll(`.${CLASS_NAMES.moreBtn}`)].forEach(button => {
  button.addEventListener('click', moreBtnClickHandler)
})
