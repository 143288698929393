export * from './snackbar'

export const MOUNT_SELECTOR = '.vue-favourite-btn-mount'

export const FAVOURITE_ICON_CLASS = {
  active: 'ui-icon-heart-active ui-kit-color-error',
  inactive: 'ui-icon-heart ui-kit-color-text-info',
}

export const MED_TYPES = {
  doctors: 'doctors',
  lpu: 'lpu',
}

export const QUERY_PARAMS = {
  next: 'next',
  addToFavourites: 'addToFavourites',
}

export const DIALOG_LOGIN = {
  title: 'Необходимо войти в личный кабинет',
  closeText: 'Не сейчас',
  confirmText: 'Войти',
}

export const URL_NAMES_TO_GOAL_SOURCES = {
  town_vrach: 'doctorList',
  doctorlist: 'doctorList',
  doctor: 'doctor',
  lpu: 'lpu',
  town_lechenie: 'doctorListLechenie',
  online_town_vrach: 'doctorListOnline',
  online_doctorlist: 'doctorListOnline',
  town_lpu: 'lpuList',
  lpulist: 'lpuList',
  lpuset: 'lpuset',
}
