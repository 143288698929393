import { AppointmentModules } from 'modules/appointment-modules'
import { getTimezoneOffsetInHours } from 'utils'

type LpuId = number
type LpuTimedelta = number

type DoctorsAndLpu = {
  lpuId: number
  doctorId: number
  timedelta: number
  hasSlots: boolean
}

type Service = {
  lpuId: number
  doctorId: number
  serviceId: number
}

type SlotsData = {
  days: number | string
  allSlots: boolean
  nowDateString: string
  doctorsAndLpus?: Array<DoctorsAndLpu>
  lpuTimedeltas?: Array<[LpuId, LpuTimedelta]>
  services?: Array<Service>
  timedelta?: number | string
}

function getCalendarData({ requestData, isWorkplace }: { requestData: any, isWorkplace: boolean }) {
  if (isWorkplace) {
    return new AppointmentModules().getAppointmentDoctorsSlots(requestData)
  }

  return new AppointmentModules().getAppointmentServicesSlots(requestData)
}

async function requestSlots({
  slotsData,
  isWorkplace,
  isTelemed,
  isAllLpus = false,
}: {
  slotsData: SlotsData
  isWorkplace: boolean
  isTelemed: boolean
  isAllLpus?: boolean
}) {
  const requestData = {
    days: slotsData.days,
  }

  if (isWorkplace && slotsData.doctorsAndLpus) {
    Object.assign(requestData, {
      dt_start: slotsData.nowDateString,
      all_slots: slotsData.allSlots,
      doctors_lpus: slotsData.doctorsAndLpus
        .filter(({ hasSlots }) => hasSlots)
        .map(({
          doctorId,
          lpuId,
          timedelta,
          hasSlots,
        }) => ({
          doctor_id: doctorId,
          lpu_id: lpuId,
          lpu_timedelta: timedelta,
          has_slots: hasSlots,
        })),
    })

    if (isTelemed) {
      Object.assign(requestData, {
        user_timedelta: getTimezoneOffsetInHours(),
        lpu_timedelta: slotsData.doctorsAndLpus.map(({ lpuId, timedelta }) => [lpuId, timedelta]),
      })
    } else {
      Object.assign(requestData, {
        town_timedelta: slotsData.timedelta,
      })
    }
  } else if (!isWorkplace && slotsData.services) {
    Object.assign(requestData, {
      town_timedelta: slotsData.timedelta,
      day_start: slotsData.nowDateString,
      only_free: !slotsData.allSlots,
      services: slotsData.services.map(({ serviceId, lpuId, doctorId }) => ({
        service_id: serviceId,
        lpu_id: lpuId,
        doctor_id: doctorId,
      })),
    })
  }

  const { result } = await getCalendarData({
    requestData,
    isWorkplace,
  })

  if (!isAllLpus) {
    return result[0]?.slots || {}
  }

  return result
}

export default requestSlots
