var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "patient-profile-skeleton p-4" },
    [
      _c("VSkeletonLoader", {
        staticClass: "patient-profile-skeleton__intro",
        attrs: {
          type: "heading, avatar, card-heading, list-item-avatar@4, date-picker-options, text",
        },
      }),
      _vm._v(" "),
      _c("VSkeletonLoader", {
        staticClass: "patient-profile-skeleton__grade",
        attrs: { type: "date-picker-options@3" },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }