import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import MarkOfShame from 'components/common/MarkOfShame/MarkOfShame'

const elements = [...document.querySelectorAll('.vue-mark-of-shame')]

elements.forEach(el => {
  new Vue({
    el,
    vuetify,
    name: 'MarkOfShameRoot',
    components: { MarkOfShame },
  })
})
