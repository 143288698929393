<template>
  <PatientProfileModal
    :is-visible="isVisible"
    :patient-data="patientData"
    @modal:close="handleCloseModal"
  />
</template>

<script>
import { getPatientData } from 'components/common/PatientProfile/api'
import { PATIENT_PROFILE_EVENTS } from 'components/common/PatientProfile/constants'
import PatientProfileModal from 'components/common/PatientProfile/components/common/PatientProfileModal'

export default {
  name: 'PatientProfile',
  components: {
    PatientProfileModal,
  },
  data: () => ({
    isVisible: false,
    patientData: {
      id: null,
      title: '',
      isLoaded: true,
    },
    currentCachedKey: null,
    cachedPatientData: {},
  }),
  mounted() {
    window.addEventListener(PATIENT_PROFILE_EVENTS.showModal, this.handleShowModal)
  },
  methods: {
    async handleShowModal({ detail }) {
      this.isVisible = true

      this.resetPatientData()

      const { patientTitle, patientMobileId, patientId } = detail
      const patientDataId = Number(patientMobileId || patientId) || null

      // Если при вызове модалки нет id пациента, то ничего не делаем
      if (!patientDataId) {
        return
      }

      const prefixForCached = patientMobileId ? 'mobile' : 'default'
      const cachedKey = `${prefixForCached}-${patientDataId}`

      this.currentCachedKey = cachedKey // `currentCachedKey` и `cachedKey` могут отличаться на момент ответа ajax-запроса

      // Проверяем, если существуют закэшированные данные пациента, то выводим их, иначе делаем запрос
      if (this.cachedPatientData[cachedKey]) {
        this.patientData = this.cachedPatientData[cachedKey]

        return
      }

      this.patientData.title = patientTitle // Моментально выводим заголовок в модалке до ajax-ответа

      try {
        // Перед ajax-запросом кэшируем уже имеющиеся данные, которые необходимы для того,
        // чтобы срабатывала проверка выше при повторном одинаковом запросе
        this.cachedPatientData[cachedKey] = {
          id: patientDataId,
          title: patientTitle,
        }

        const ajaxPatientData = await getPatientData({ patientMobileId, patientId })

        this.cachedPatientData[cachedKey] = {
          ...this.cachedPatientData[cachedKey],
          ...ajaxPatientData,
          isLoaded: true,
        }

        // Т.к. ajax-запрос может занимать время, то возможна ситуация когда пользователь выполнит следующий запрос
        // не дожидаясь первый. В таком случае, на момент успешного ответа первого запроса, `currentCachedKey` и `cachedKey` будут отличаться
        this.patientData = this.cachedPatientData[this.currentCachedKey]
      } catch {
        this.cachedPatientData[cachedKey] = null
      }
    },
    handleCloseModal() {
      this.isVisible = false
    },
    resetPatientData() {
      this.patientData = {
        id: null,
        title: '',
        isLoaded: false,
      }
    },
  },
}
</script>
