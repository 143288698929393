<template>
  <div
    ref="specialization-prices"
    class="mt-4 ui-text ui-text_body-2 my-2"
    data-qa="specialization-prices"
  >
    <div
      v-for="(specialization, index) in currentSpecializations"
      :key="`${specialization.name}-${index}`"
      class="mb-2"
      data-qa="specialization-price"
    >
      {{ specialization.name }}
      <span v-if="specialization.ageRangeText">
        ({{ specialization.ageRangeText }})
      </span>
      —
      <span v-if="specialization.club && isClubEnabled && appointmentType === APPOINTMENT_TYPES.default">
        <span class="ui-text ui-kit-color-text-error">
          {{ specialization.club }}{{ currency.symbol }}
        </span>
        <span class="ui-text ui-text_line-through">
          {{ specialization.default }}{{ currency.symbol }}
        </span>
      </span>
      <span v-else>
        <span v-if="specialization[appointmentType]">
          {{ specialization[appointmentType] }}{{ currency.symbol }}
        </span>
        <span v-else>
          Бесплатно
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import {
  APPOINTMENT_TYPES,
  SELECTORS,
} from 'components/common/SpecializationPrices/constants'
import { CLUB_EVENTS as CLUB_SWITCH_EVENTS } from 'www/constants/eventNames'
import { CLUB_EVENTS as TABS_EVENTS } from 'components/common/Club/constants'
import CurrencyData from 'components/common/mixins/CurrencyData'

export default {
  name: 'SpecializationPrices',
  mixins: [
    CurrencyData,
  ],
  props: {
    dataSpecializations: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isClubEnabled: true,
    APPOINTMENT_TYPES,
    appointmentType: APPOINTMENT_TYPES.default,
  }),
  computed: {
    currentSpecializations() {
      if (this.appointmentType === APPOINTMENT_TYPES.default) {
        return this.dataSpecializations.filter(price => price.default !== null)
      }

      return this.dataSpecializations
        .filter(price => price.hasOwnProperty(this.appointmentType) && price.default !== null)
    },
  },
  mounted() {
    window.addEventListener(CLUB_SWITCH_EVENTS.discountSwitchClick, this.handleClickSwitch)
    window.addEventListener(TABS_EVENTS.clubAppointmentTypeSelected, this.handleChangeAppointmentType)
  },
  methods: {
    handleClickSwitch({ detail }) {
      this.isClubEnabled = detail.isEnabled
    },
    handleChangeAppointmentType({ detail }) {
      const targetNode = detail.target
      const cardClinicNode = targetNode.closest(SELECTORS.cardClinic)
      const isCurrentClinicNode = cardClinicNode?.contains(this.$refs['specialization-prices'])

      if (!isCurrentClinicNode) {
        return
      }

      this.appointmentType = APPOINTMENT_TYPES[detail.redirectValue]
    },
  },
}
</script>
