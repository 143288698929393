import { deepMerge, isEmptyObject } from 'utils'
import ClassListMethods from 'modules/ClassListMethods'
import CardsDisplayClass from 'modules/CardsDisplay'
import { getModuleOptionsInterface } from 'modules/DoctorListPagination/models'
import {
  NAMESPACE,
  LOADING_STATE,
  DOCTOR_FILTER_LIST,
  VISIBLE_CARDS_QUANTITY,
} from 'modules/DoctorListPagination/constants'
import 'modules/DoctorListPagination/index.scss'

class DoctorListPagination {
  #options = getModuleOptionsInterface()

  #notFoundInstance = {}

  #navigationInstance = {}

  #paginationInstance = {}

  constructor(opts) {
    this.options = opts

    this._addEventsListeners()
    this._createPaginationInstance()
    this._createPageElementsInstances()

    if (!this.isStaff) {
      this.hookCardListUpdated()
    }
  }

  get options() { return this.#options }

  set options(opts) { this.#options = deepMerge(getModuleOptionsInterface(), opts) }

  get isStaff() {
    return document.querySelector(NAMESPACE.selectorStaffEnabled)
  }

  get isUsedFilter() {
    return DOCTOR_FILTER_LIST.some(i => window.location.href.includes(i))
  }

  get paginationOptions() {
    const {
      selectorScrollTo,
      selectorDoctorCard,
      selectorMountPagination,
    } = NAMESPACE

    return {
      typeDevice: window.MOBILE_VERSION ? 'mobile' : 'desktop',
      currentPage: 1,
      visibleCardsQuantity: VISIBLE_CARDS_QUANTITY,
      attrs: {
        selectorCard: this.isUsedFilter ? `.${LOADING_STATE.filtered}` : selectorDoctorCard,
        classNameHidden: LOADING_STATE.hidden,
        classNameVisible: LOADING_STATE.visible,
      },
      pagination: {
        updated: this._paginationChangeListener.bind(this),
        selectorScrollTo,
        selectorMountPagination,
      },
    }
  }

  get searchResults() { return [...document.querySelectorAll(`.${LOADING_STATE.filtered}`)] }

  get hasSearchResults() { return this.searchResults.length }

  _showNavigation() { this.#navigationInstance.remove() }

  _hideNavigation() { this.#navigationInstance.add() }

  _showNotFound() { this.#notFoundInstance.remove() }

  _hideNotFound() { this.#notFoundInstance.add() }

  _createPageElementsInstances() {
    this.#navigationInstance = new ClassListMethods({
      containerSelector: NAMESPACE.selectorNavigationContainer,
      containerClassNameActive: NAMESPACE.classNameNavigationContainerHidden,
    })

    this.#notFoundInstance = new ClassListMethods({
      containerSelector: NAMESPACE.selectorNotFound,
      containerClassNameActive: NAMESPACE.classNameNotFoundHidden,
    })
  }

  _createPaginationInstance() {
    if (this.isStaff) {
      return
    }

    setTimeout(() => { // поле геттера 'isUsedFilter' обновляется с опозданием, поэтому отрисовка необходима с задержкой
      if (!isEmptyObject(this.#paginationInstance)) {
        this.#paginationInstance.init(this.paginationOptions)
        return
      }

      this.#paginationInstance = new CardsDisplayClass(this.paginationOptions)
    })
  }

  _addEventsListeners() {
    document.addEventListener('clinic-filter:filter', this._usedFilterListener.bind(this))
    document.addEventListener('clinic-filter:unfilter', this._resetFilterListener.bind(this))
  }

  _usedFilterListener() {
    if (this.hasSearchResults) {
      this._hideNotFound()
      this._showNavigation()
    } else {
      this._showNotFound()
      this._hideNavigation()
    }

    if (!this.isStaff) {
      this._createPaginationInstance()
      this.hookCardListUpdated()
    } else {
      this.searchResults.forEach(card => card.classList.remove(NAMESPACE.classNameNotFoundHidden)) // фикс legacy, связанный с добавлением класса hidden !important
    }
  }

  _resetFilterListener() {
    this._hideNotFound()
    this._showNavigation()

    if (!this.isStaff) {
      this._createPaginationInstance()
      this.hookCardListUpdated()
    }
  }

  _paginationChangeListener() {
    if (!this.isStaff) {
      this.hookCardListUpdated()
    }
  }

  hookCardListUpdated() {
    setTimeout(() => {
      const {
        groups: cardGroups,
        currentPosition: position,
      } = this.#paginationInstance

      this.options.hookCardListUpdated({
        position,
        cardGroups,
      })
    })
  }
}

export default DoctorListPagination
