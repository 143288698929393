var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calendar-stub ui-text ui-text_body-1" }, [
    _vm._v("\n  Запись недоступна\n"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }