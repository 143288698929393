import {
  axiosClient,
  getCSRFToken,
  createDevNotice,
  getFormDataFromObject,
} from 'utils'

function sendViewStatusRequest(viewStatusRequestData) {
  const csrfmiddlewaretoken = getCSRFToken()

  if (!csrfmiddlewaretoken) {
    return
  }

  const formViewStatusRequestData = getFormDataFromObject({
    ...viewStatusRequestData,
    csrfmiddlewaretoken,
  })

  axiosClient
    .post('/ajax/view_status/', formViewStatusRequestData)
    .catch(event => {
      if (!window.navigator.onLine || event.message === 'Network Error') {
        return
      }

      createDevNotice({
        module: 'sendViewStatusRequest.js',
        description: JSON.stringify({
          message: event.message,
          response: event.response?.data,
        }),
      })
    })
}

export default sendViewStatusRequest
