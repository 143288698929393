require('libs/magnific-popup/jquery.magnific-popup.min')

$(() => {
  const $openButton = $('.b-doctor-intro__js-bug-report, [data-open-mistake-form]')

  $openButton.magnificPopup({
    removalDelay: 200,
    showCloseBtn: false,
    items: {
      src: `
                <div class="b-doctor-intro__mistake-popup mfp-with-anim">
                    <div class="b-doctor-intro__mistake-popup-body">
                        <iframe class="b-doctor-intro__mistake-form" scrolling="no" src="${$openButton.data('href')}?iframe=true"></iframe>
                    </div>
                </div>`,
      type: 'inline',
    },
    callbacks: {
      beforeOpen() {
        this.st.mainClass = 'mag-popup'
        this.st.mainClass += ` ${this.st.el.attr('data-effect')}`
      },
      open() {
        const iframe = document.querySelector('.b-doctor-intro__mistake-form')
        const body = document.querySelector('.b-doctor-intro__mistake-popup-body')

        window.addEventListener('message', event => {
          if (event.data.sender === 'mistakeForm') {
            if (event.data.action === 'close') {
              this.st.el.magnificPopup('close')
            }

            if (event.data.action === 'resize') {
              const iframeContentHeight = `${event.data.height}px`

              iframe.style.height = iframeContentHeight
              body.style.height = iframeContentHeight
            }
          }
        })
      },
    },
  })
})
