var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", [
    _c(
      "div",
      { staticClass: "reviews-filter" },
      [
        _vm._l(_vm.reviewTypes, function (type, index) {
          return [
            _vm.useRenderType(type)
              ? _c(
                  "v-chip",
                  {
                    key: index,
                    staticClass: "mx-1 my-2 review-type",
                    class: [
                      `review-type_${type.value}`,
                      { "review-type_active": _vm.activeType === index },
                    ],
                    attrs: {
                      disabled: type.disabled || _vm.isFiltering,
                      color: _vm.getTypeBackgroundColor(type.value),
                      link: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.handleTypeChange(index)
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(type.title) +
                        " " +
                        _vm._s(type.amount || 0) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }