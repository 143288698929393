import {
  BUTTON_CLOSE_POPUP_CLASS,
  BUTTON_DEPARTURE_ADDRESS_CLASS,
} from 'components/common/PopupMap/constants'

function getPlacemarkBalloonTemplate({ name, phone, address }) {
  const phoneTemplate = phone
    ? `
            <div class="mt-4 d-flex">
                <span class="mr-6 ui-kit-color-text-info ui-icon-phone-call"></span>
                <div 
                    class="ui-text ui-text_body-1"
                    data-qa="popup_map_phone_lpu_text"
                >
                    +7 ${phone}
                </div>
            </div>
        `
    : ''

  return `
        <div class="p-6">
            <div 
                class="ui-text ui-text_h6 ui-kit-color-primary"
                data-qa="popup_map_lpu_name_text"
            >
                ${name}
            </div>
            <div class="mt-4 d-flex">
                <span class="mr-6 ui-kit-color-text-info ui-icon-map-pin"></span>
                <div 
                    class="ui-text ui-text_body-1"
                    data-qa="popup_map_adderess_lpu_text"
                >
                    ${address}
                </div>
            </div>
            ${phoneTemplate}
            <button 
                type="button" 
                class="b-button b-button_light w-100 mt-4 ${BUTTON_DEPARTURE_ADDRESS_CLASS}"
                data-qa="popup_map_plot_route_button"
            >
                <span class="ui-text ui-text_button ui-kit-color-primary">Проложить маршрут</span>
            </button>
            <button 
                type="button" 
                class="b-button b-button_text w-100 mt-2 ${BUTTON_CLOSE_POPUP_CLASS}"
                data-qa="popup_map_close_button"
            >
                <span class="ui-text ui-kit-color-text ui-icon-close-not-a-circle mr-2"></span>
                <span class="ui-text ui-text_button ui-kit-color-text">Закрыть</span>
            </button>
        </div>
    `
}

export default getPlacemarkBalloonTemplate
