import { declensionByNum } from 'utils'
import { YEAR_CASES } from 'constants/wordCases'

function getAgeLimitCaseText(limit) {
  const ageLimitCase = declensionByNum(limit, YEAR_CASES.genitive)

  return `\u00A0${limit}\u00A0${ageLimitCase}`
}

function getAgesText({ min, max } = {}) {
  if (!min && !max) {
    return ''
  }

  const ageLimitsStrings = []

  if (min) {
    ageLimitsStrings.push(`от${getAgeLimitCaseText(min)}`)
  }

  if (max) {
    ageLimitsStrings.push(`до${getAgeLimitCaseText(max)}`)
  }

  return ageLimitsStrings.join(' ')
}

export default getAgesText
