import getInitialDay from 'modules/Schedule/classes/ScheduleData/functions/renderData/helpers/getInitialDay'

/**
 * @description
 * Возвращает объект Map, который используется дальше для рендера календарей(календари для записи на услугу).
 * Функция возвращает список, который состоит из множества объектов рендера, или же только из одного.
 * При спецразмещении может быть несколько дубликатов карточек клиник. Сопоставление переменной 'isSingleRender' точно сообщает - в каком контейнере произошло изменение.
 * Если при клике передан parentCard и не найдено сопоставление - будет ошибка, которая ловится в вызывающем модуле.
 *
 * @param { Object } opts
 * @param { Object } opts.data - собранные данные со страницы - интерфейс 'getModuleDataInterface'
 * @param { HTMLElement } opts.parentCard - карточка в списке, в которой необходимо заменить расписание
 * @param { Array<Object> } opts.response - ответ на запрос
 * @param { String } opts.dataItem - data атрибут родителя
 * @param { Boolean } opts.[isPreliminaryConsultation] - является ли процедура инвазивной. Если поле передано, значит обновляется только 1 расписание. Будет использовано оно во время рендера расписания.
 * В противном случае будет использовано поле isPreliminaryConsultation со страницы из 'synonymPreview'.
 *
 * @return { Map } - данные для рендера списка календарей
 * */

const getRenderServicesData = opts => {
  const {
    data,
    response,
    dataItem,
    isPreliminaryConsultation,
    parentCard,
  } = opts
  const resultData = new Map()

  data.items.forEach((pageData, scheduleContainer) => {
    const {
      lpuId,
      coverNode,
      serviceIsDirect,
      isEnabledLpuClub,
    } = pageData
    const scheduleData = response.find(({ lpu_id: idResult }) => idResult === lpuId)
    const isListRender = !parentCard // рендер списка календарей
    const isSingleRender = parentCard === coverNode // рендер только 1 календаря

    if (!scheduleData) {
      return
    }

    if (!(isListRender || isSingleRender)) {
      return
    }

    const { slots, service_id: synonymId } = scheduleData
    const days = Object.keys(slots)
    const daysWithSlots = days.filter(date => slots[date].length)
    const isEmptySlotList = !daysWithSlots.length
    const { dtypeId, isPreliminaryConsultation: serviceIsPreliminaryConsultation } = pageData.synonymPreview

    if (isEmptySlotList) {
      return
    }

    const initDay = getInitialDay({
      daysWithSlots,
      currentSlots: slots,
    })

    resultData.set(scheduleContainer, {
      days,
      slots,
      lpuId,
      dtypeId,
      initDay,
      dataItem,
      synonymId,
      data: slots,
      options: slots,
      serviceIsDirect,
      isEnabledLpuClub,
      serviceIsPreliminaryConsultation: isPreliminaryConsultation !== undefined
        ? isPreliminaryConsultation
        : serviceIsPreliminaryConsultation,
      useRedirect: true,
      isAppointmentLpu: true,
    })
  })

  return resultData
}

export default getRenderServicesData
