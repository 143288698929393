import { NAVIGATION_PANEL_CLASSES } from 'components/common/PopupMap/constants'

function getPinTemplate({
  text,
  classes = '',
  isOnMap,
}) {
  const pinOnMapClass = isOnMap ? ` ${NAVIGATION_PANEL_CLASSES.pinOnMap}` : ''

  return `
        <div 
            class="${NAVIGATION_PANEL_CLASSES.pin}${pinOnMapClass} ${classes}"
        >
            <div class="ui-text ui-kit-color-bg-gray-0">${text}</div>
        </div>
    `
}

export default getPinTemplate
