const AppCalendarProps = {
  props: {
    // desktop
    data: {
      type: Object,
      default: () => ({}),
    },
    days: {
      type: Array,
      default: () => [],
    },
    selectedDate: {
      type: Object,
      default: () => ({}),
    },
    doctorId: {
      type: [Number, String],
      default: 0,
    },
    lpuId: {
      type: Number,
      default: null,
    },
    lpuAddrId: {
      type: [Number, String],
      default: null,
    },
    specialPlacement: {
      type: [Number, String],
      default: null,
    },
    doctorSpec: {
      type: [Number, String],
      default: null,
    },
    initDay: {
      type: String,
      default: '',
    },
    deviceId: {
      type: [Number, String],
      default: null,
    },
    synonymId: {
      type: [Number, String],
      default: null,
    },
    dtypeId: {
      type: Number,
      default: null,
    },
    useRedirect: {
      type: Boolean,
      default: false,
    },
    isAppointmentLpu: {
      type: Boolean,
      default: false,
    },
    isAppointmentDoctor: {
      type: Boolean,
      default: false,
    },
    dataItem: {
      type: String,
      default: '',
    },
    serviceIsPreliminaryConsultation: { // если true, значит необходим первичный приём
      type: Boolean,
      default: false,
    },
    serviceIsDirect: { // передается только для вывода расписания на услугах, используется при отправке Yandex целей
      type: Boolean,
      default: false,
    },
    isEnabledLpuClub: { // передается только на услугах - идентификатор наличия клубного договора у клиники
      type: Boolean,
      default: false,
    },
    isLpuPriceList: { // передается только на странице списка цен клиники и используется только для 'Яндекс' метрики
      type: Boolean,
      default: false,
    },
    isDoctorPriceList: { // передается только на странице списка цен врача и используется только для 'Яндекс' метрики
      type: Boolean,
      default: false,
    },
    // mobile
    slots: {
      type: Object,
      default: () => ({}),
    },
    initialSlot: {
      type: Object,
      default: () => ({}),
    },
    specPlacement: {
      type: [Number, String],
      default: null,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
    assignment: {
      type: String,
      default: '',
    },
    isHidden: {
      type: Boolean,
      default: false,
    },
  },
}

export default AppCalendarProps
