import { POPUP_MAP_CLASSES } from 'components/common/PopupMap/constants'

function getPlacemarkTemplate({ isSelected } = {}) {
  const {
    placemark,
    placemarkSelected,
  } = POPUP_MAP_CLASSES

  const selectedClass = isSelected ? ` ${placemarkSelected}` : ''

  return `
        <div
            class="${placemark}${selectedClass} ui-icon-map-pin-filled"
        >
        </div>
    `
}

export default getPlacemarkTemplate
