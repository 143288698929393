<template>
  <div
    v-show="title"
    :class="[blockClassList, buttonClassList]"
    :style="{
      width: blockWidth,
    }"
    data-qa="select_module_button"
    @click="$emit('click', $event)"
  >
    <div
      :class="namespace.content"
    >
      <div
        :class="[namespace.title, namespace.textLimit]"
        class="ui-text ui-text_body-1 ui-kit-color-text"
        data-qa="select_module_button_title"
      >
        {{ title }}
      </div>
      <div
        v-if="subtitle"
        :class="[namespace.subtitle, namespace.textLimit]"
        class="ui-text ui-text_body-2 ui-kit-color-text-info"
        data-qa="select_module_button_subtitle"
      >
        <slot name="subtitle">
          {{ subtitle }}
        </slot>
      </div>
      <span :class="namespace.arrow">
        <span
          :class="namespace.icon"
          class="ui-icon-arrow-down ui-kit-color-text-secondary"
          data-qa="select_module_button_arrow"
        />
      </span>
    </div>
  </div>
</template>

<script>
import SelectModuleButtonMixin from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModuleButtonMixin'

export default {
  name: 'SelectModuleButton',
  mixins: [
    SelectModuleButtonMixin,
  ],
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    subtitle: {
      type: String,
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    blockName: 'select-button',
  }),
  computed: {
    namespace() {
      return {
        block: this.blockName,
        disabled: `${this.blockName}_disabled`,
        textLimit: `${this.blockName}_text-limit`,
        content: `${this.blockName}__content`,
        title: `${this.blockName}__title`,
        subtitle: `${this.blockName}__subtitle`,
        arrow: `${this.blockName}__arrow`,
        icon: `${this.blockName}__icon`,
      }
    },
    blockClassList() {
      const arrayResult = []

      arrayResult.push(this.namespace.block)

      if (this.isDisabled) {
        arrayResult.push(this.namespace.disabled)
      }

      return arrayResult
    },
    blockWidth() {
      if (this.buttonBlock) {
        return '100%'
      }

      if (this.maxWidth) {
        if (typeof this.maxWidth === 'number') {
          return `${this.maxWidth}px`
        }

        if (typeof this.maxWidth === 'string') {
          return this.maxWidth
        }
      }

      return 'auto'
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.select-button {
  cursor: pointer;
  box-sizing: border-box;
  border-radius: $border-radius-sm;
  border: 1px solid $ui-kit-text-info;
  background-color: $ui-kit-bg-gray-0;

  &_text-limit {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    min-height: 40px;
    position: relative;
    box-sizing: inherit;
    padding: 8px 32px 8px 16px;
  }

  &__arrow {
    position: absolute;
    top: calc(50% - 2px);
    right: 10px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    font-size: 20px;
  }

  &__icon {
    font-size: inherit;
  }

  &_disabled {
    pointer-events: none;
    border-color: transparent;
  }

  &_disabled &__content {
    padding: 8px 0;
  }

  &_disabled &__arrow {
    display: none;
  }
}
</style>
