import {
  callChain,
  getTypeObject,
  isEmptyObject,
  deepMerge,
  createDevNotice,
} from 'utils'
import Swiper, { Navigation, Pagination } from 'swiper/core'
import 'swiper/swiper-bundle.css'
import './index.scss'

Swiper.use([Navigation, Pagination])

/**
 * @author Александр Быков (@bykov)
 */
class SwiperModule {
  constructor(options) {
    this.init(options)
  }

  init(options = {}) {
    this._changeValueIsInit({ value: false })
    this._createPromiseCounter()
    this._createDataProps() // Собираю настройки из HTML

    const sliderOptions = !isEmptyObject(this.dataValue) ? this.dataValue : options // Если нашел использую их, иначе - опции js

    if (!sliderOptions?.module?.modifier) { // Без опции modifier не сработает
      throw Error('"modifier" in "module" - is a required field')
    }

    this.data = [] // Глобальные данные класса, они также будут возвращаться с помощью метода - getInstances

    this._createOptions(sliderOptions)

    if (!this.options) {
      return
    }

    callChain({ // Последовательная цепочка вызовов, разгружаю основной поток
      context: this, // Контекст
      chain: [ // Массив функций
        [this._createModifiers], // Формирую this.modifiers (Все модификаторы класса)
        [this._createNamespace], // Формирую this.namespace (Базловое пространство имен)
        [this._createInstanceData], // Формирую базовые данные для инициализации всех слайдеров(в namespace)
        [this._createTemplates], // Формирую this.templates(шаблоны пагинации и навигации)
        [this._modificationHTML], // Модифицирую HTML под нужды инициализации
        [this._createPaginationOptions], // Произвожу настройку пагинации(для уникального объявления)
        [this._createNavigationOptions], // Произвожу настройку навигации(для уникального объявления)
        [this._initSliders], // Инициализирую все слайдеры на странице
        [this._changeValueIsInit, [{ value: true }]],
      ],
    })
  }

  redraw({
    before = () => {},
    after = () => {},
  } = {}) {
    this._changeValueIsInit({ value: false })

    const { data, namespace, options } = this
    const { instance } = namespace
    const sliderOptions = options.swiper
    const containers = [...document.getElementsByClassName(namespace.container.customize)]

    if (!containers.length) { // Если нет контейнеров - инициализировать нечего
      this._changeValueIsInit({ value: true })
      return
    }

    this._callFunction(before) // Исполняю коллбек before(без вклинивания в основной поток обработки)

    // Исполняю цепочку модификаций и инициализирую слайдеры.
    // Во время исполнения каждого метода происходит проверка на this.data и currentData.Swiper(проинициализированный экземпляр)
    // Метод redraw можно использовать сколько угодно раз - он безопасен
    callChain({
      context: this,
      chain: [
        [this._pushEachInstanceData, [{
          containers,
          namespace,
          instance,
          data,
          sliderOptions,
        }]],
        [this._modificationHTML],
        [this._createPaginationOptions],
        [this._createNavigationOptions],
        [this._initSliders],
        [this._callFunction, [after]],
        [this._changeValueIsInit, [{ value: true }]],
      ],
    })
  }

  /**
     * @description
     * Метод реализован с помощью рекурсивного таймаута, поскольку это единственный способ отслеживания завершения работы callChain(setTimeout).
     * Функция вызывается в случае, если инициализация еще не прошла quantity количество раз через delay задержку.
     * Возможно 2 исхода - исполнение переданной функции, после успешной инициализации или возврат(переданная функция не выполнится)
     *
     * @params { Function } - функция, которая должна исполниться и которой нужны данные экземпляра для исполнения
     * */

  // TODO Hint использует идентичный метод с идентичными параметрами.
  //  Если алгоритм себя зарекомендует и не последует доработок - нужно вынести метод в utils(за подробностями обращаться к автору)

  promise(fn = () => {}) {
    const { delay, quantity } = this.promiseCounter

    if (!this.isInit) {
      if (this.promiseCounter.counter < quantity) {
        setTimeout(() => {
          this.promiseCounter.counter++
          this.promise(fn)
        }, delay)
      }

      this.promiseCounter.counter = 0

      createDevNotice({
        mode: 'warn',
        method: 'promise',
        module: 'SwiperModule',
        description: 'Метод "promise" не выполнился.',
      })

      return
    }

    this.promiseCounter.counter = 0

    fn({ data: this.data })
  }

  _getResultCompareLength(slidesLength, slidesPerView) {
    return slidesPerView === 'auto' ? true : slidesLength > slidesPerView
  }

  _callFunction(fn) {
    if (getTypeObject(fn) === 'function') {
      fn()
    }
  }

  _initSliders() {
    const { data, options } = this
    const { slidesPerView } = options.swiper

    if (!data.length) {
      return
    }

    data.forEach(currentData => {
      if (currentData.Swiper) {
        return
      }

      const initCondition = this._getResultCompareLength(currentData.slides.length, slidesPerView)

      if (initCondition) {
        const { container } = currentData
        const sliderOptions = currentData.options

        currentData.Swiper = new Swiper(container, sliderOptions)
      }
    })
  }

  _pushEachInstanceData({
    containers, namespace, instance, data, sliderOptions,
  }) {
    containers.forEach((container, index) => {
      if (!container.classList.contains(namespace.initialized)) {
        const track = container.getElementsByClassName(namespace.track.class)[0]
        const slides = [...container.getElementsByClassName(namespace.slide.class)]

        instance.push({ // Добавляю уникальное пространство имен для инициализации каждого экземпляра слайдера
          container: `${namespace.container.customize}_${index}`,
          pagination: `${namespace.pagination.customize}_${index}`,
          navigation: `${namespace.navigation.customize}_${index}`,
          navigationNext: `${namespace.navigationNext.customize}_${index}`,
          navigationPrev: `${namespace.navigationPrev.customize}_${index}`,
        })

        data.push({
          container,
          track,
          slides,
          pagination: null,
          navigationNext: null,
          navigationPrev: null,
          options: { ...sliderOptions },
        })
      }
    })
  }

  _modificationHTML() {
    const {
      data,
      namespace,
      templates,
      options,
    } = this

    if (!data.length) {
      return
    }

    const sliderOptions = options.swiper
    const { slidesPerView } = sliderOptions

    data.forEach((currentData, index) => {
      if (currentData.Swiper) {
        return
      }

      const { container, track, slides } = currentData
      const currentInstanceNamespace = namespace.instance[index]
      const initCondition = this._getResultCompareLength(slides.length, slidesPerView)

      if (!initCondition) { // Если слайдов меньше чем параметр slidesPerView - нет нужды добавлять классы и вставлять HTML-шаблоны
        return
      }

      // Добавляю пространство имен текущему контейнеру
      container.classList.add(namespace.container.class)
      container.classList.add(namespace.container.original)
      container.classList.add(currentInstanceNamespace.container)

      container.dataset.instanceIndex = index

      // Добавляю пространство имен текущему треку
      track.classList.add(namespace.track.class)
      track.classList.add(namespace.track.original)
      track.classList.add(namespace.track.customize)

      // Добавляю пространство имен всем слайдам текущего трека
      slides.forEach(slide => {
        slide.classList.add(namespace.slide.class)
        slide.classList.add(namespace.slide.original)
        slide.classList.add(namespace.slide.customize)
      })

      // Модицирую шаблоны навигации и пагинации, вставляю их на страницу и добавляю в глобальное хранилище
      this._insertTemplates({
        sliderOptions,
        currentInstanceNamespace,
        templates,
        container,
        currentData,
        options,
      })
    })
  }

  _createPaginationOptions() {
    const { data, options, namespace } = this
    const sliderOptions = options.swiper
    const { slidesPerView } = sliderOptions
    const customOptionsPagination = sliderOptions.pagination

    if (!(customOptionsPagination && data.length)) {
      return
    }

    data.forEach((currentData, index) => {
      if (currentData.Swiper) {
        return
      }

      const initCondition = this._getResultCompareLength(currentData.slides.length, slidesPerView)

      if (initCondition) {
        const currentDataOptions = currentData.options
        const currentInstanceNamespace = namespace.instance[index]
        const paginationOptions = {
          el: `.${currentInstanceNamespace.pagination}`,
          dynamicMainBullets: 3,
        }

        currentDataOptions.pagination = {
          ...customOptionsPagination,
          ...paginationOptions,
        }
      }
    })
  }

  _createNavigationOptions() {
    const { data, options, namespace } = this
    const sliderOptions = options.swiper
    const { slidesPerView } = sliderOptions
    const customOptionsNavigation = sliderOptions.navigation

    if (!(customOptionsNavigation && data.length)) {
      return
    }

    data.forEach(currentData => {
      if (currentData.Swiper) {
        return
      }

      const initCondition = this._getResultCompareLength(currentData.slides.length, slidesPerView)

      if (initCondition) {
        const currentDataOptions = currentData.options
        const navigationOptions = {
          nextEl: currentData.navigationNext,
          prevEl: currentData.navigationPrev,
          hiddenClass: namespace.hidden,
          disabledClass: namespace.disabled,
        }

        currentDataOptions.navigation = {
          ...customOptionsNavigation,
          ...navigationOptions,
        }
      }
    })
  }

  _insertTemplates({
    sliderOptions,
    currentInstanceNamespace,
    templates,
    container,
    currentData,
    options,
  }) {
    if (sliderOptions.pagination) { // Если пагинация включена в параметрах
      const { insertPagination } = options.module // Объект настройки вставки пагинации
      const whereInsert = insertPagination.where // Куда вставлять
      const relativeInsert = insertPagination.relative ? insertPagination.relative(container) : container // Относительно какого узла на странице производить вставку
      const templatePagination = templates // Добавляю кастомный класс для инициализации пагинации
        .pagination
        .replace(/\{\{initializePaginationClassName\}\}/g, currentInstanceNamespace.pagination)

      relativeInsert.insertAdjacentHTML(whereInsert, templatePagination) // Произвожу вставку HTML-шаблона относительно узла на странице
      currentData.pagination = document.querySelector(`.${currentInstanceNamespace.pagination}`) // Ищу узел пагинации, присваиваю в глобальный объект данных
    }

    if (sliderOptions.navigation) { // Если навигация включена в параметрах
      const { insertNavigation } = options.module // Объект настройки вставки навигации
      const whereInsert = insertNavigation.where // Куда вставлять
      const relativeInsert = insertNavigation.relative ? insertNavigation.relative(container) : container // Относительно какого узла на странице производить вставку
      const templateNavigation = templates // Добавляю кастомные классы для инициализации кнопок навигации(цепочка replace -> контейнер -> кнопка назад -> кнопка вперед)
        .navigation
        .replace(/\{\{initializeNavigationClassName\}\}/g, currentInstanceNamespace.navigation)
        .replace(/\{\{initializeNavigationPrevClassName\}\}/g, currentInstanceNamespace.navigationPrev)
        .replace(/\{\{initializeNavigationNextClassName\}\}/g, currentInstanceNamespace.navigationNext)

      relativeInsert.insertAdjacentHTML(whereInsert, templateNavigation) // Произвожу вставку HTML-шаблона относительно узла на странице
      currentData.navigation = document.querySelector(`.${currentInstanceNamespace.navigation}`) // Ищу узел контейнера навигации, присваиваю в глобальный объект данных
      currentData.navigationPrev = document.querySelector(`.${currentInstanceNamespace.navigationPrev}`) // Ищу узел кнопки назад, присваиваю в глобальный объект данных
      currentData.navigationNext = document.querySelector(`.${currentInstanceNamespace.navigationNext}`) // Ищу узел кнопки вперед, присваиваю в глобальный объект данных
    }
  }

  _createTemplates() {
    if (!this.namespace) {
      return
    }

    const {
      pagination,
      navigation,
      navigationItem,
      navigationIcon,
      navigationPrev,
      navigationNext,
    } = this.namespace

    const paginationTextClassList = 'ui-text ui-text_body-2 ui-kit-color-text-secondary'
    const navigationPrevIconClassList = 'ui-icon-arrow-left ui-icon_fz_smaller ui-kit-color-primary'
    const navigationNextIconClassList = 'ui-icon-arrow-right ui-icon_fz_smaller ui-kit-color-primary'

    this.templates = {
      pagination: `<div class="${pagination.class} ${pagination.customize} {{initializePaginationClassName}} ${pagination.original} ${paginationTextClassList}"></div>`,
      navigation: `<div class="${navigation.class} ${navigation.customize} {{initializeNavigationClassName}}">
                            <button class="${navigationItem.class} ${navigationItem.customize} {{initializeNavigationPrevClassName}} ${navigationPrev.class} ${navigationPrev.customize}"
                                    title="Предыдущий слайд"
                            >
                                <span class="${navigationIcon.class} ${navigationIcon.customize} ${navigationPrevIconClassList}" aria-hidden="true"></span>
                            </button>
                            <button class="${navigationItem.class} ${navigationItem.customize} {{initializeNavigationNextClassName}} ${navigationNext.class} ${navigationNext.customize}"
                                    title="Следующий слайд"
                            >
                                <span class="${navigationIcon.class} ${navigationIcon.customize} ${navigationNextIconClassList}" aria-hidden="true"></span>
                            </button>
                         </div>`,
    }
  }

  _createInstanceData() {
    const { data, namespace, options } = this
    const sliderOptions = options.swiper

    if (!namespace || !data) {
      return
    }

    const containers = [...document.getElementsByClassName(namespace.container.customize)]
    const instance = [] // Уникальное пространство имен для инициализации каждого экземпляра слайдера

    namespace.instance = instance

    if (!containers.length) { // Если нет контейнеров на странице, значит инициализировать нечего
      return
    }

    this._pushEachInstanceData({ // Добавляю данные в instance. Также использую этот метод при redraw
      containers,
      namespace,
      instance,
      data,
      sliderOptions,
    })
  }

  _createModifiers() {
    this.modifiers = {
      modifierClass: 'b-swiper',
      modifierOriginal: 'swiper',
      modifierCustomize: this.options.module.modifier,
    }
  }

  _createNamespace() {
    if (!this.modifiers) {
      return
    }

    const { modifierClass, modifierOriginal, modifierCustomize } = this.modifiers

    this.namespace = {
      container: {
        class: modifierClass,
        customize: modifierCustomize,
        original: `${modifierOriginal}-container`,
      },
      track: {
        class: `${modifierClass}__track`,
        customize: `${modifierCustomize}__track`,
        original: `${modifierOriginal}-wrapper`,
      },
      slide: {
        class: `${modifierClass}__slide`,
        customize: `${modifierCustomize}__slide`,
        original: `${modifierOriginal}-slide`,
      },
      pagination: {
        class: `${modifierClass}__pagination`,
        customize: `${modifierCustomize}__pagination`,
        original: `${modifierOriginal}-pagination`,
      },
      navigation: {
        class: `${modifierClass}__navigation`,
        customize: `${modifierCustomize}__navigation`,
        original: null,
      },
      navigationItem: {
        class: `${modifierClass}__navigation-item`,
        customize: `${modifierCustomize}__navigation-item`,
        original: null,
      },
      navigationIcon: {
        class: `${modifierClass}__navigation-icon`,
        customize: `${modifierCustomize}__navigation-icon`,
        original: null,
      },
      navigationPrev: {
        class: `${modifierClass}__navigation-prev`,
        customize: `${modifierCustomize}__navigation-prev`,
        original: `${modifierOriginal}-button-prev`,
      },
      navigationNext: {
        class: `${modifierClass}__navigation-next`,
        customize: `${modifierCustomize}__navigation-next`,
        original: `${modifierOriginal}-button-next`,
      },
      hidden: `${modifierClass}_hidden`,
      disabled: `${modifierClass}_disabled`,
      initialized: `${modifierOriginal}-container-initialized`,
    }
  }

  _createOptions(options) {
    this.options = deepMerge({
      module: {
        modifier: null,
        insertPagination: {
          where: 'beforeend',
          relative: null,
        },
        insertNavigation: {
          where: 'beforeend',
          relative: null,
        },
      },
      swiper: {
        spaceBetween: 8,
        slidesPerView: 1,
        roundLengths: true,
        pagination: {
          type: 'bullets',
          dynamicBullets: true,
        },
        navigation: {},
        containerModifierClass: 'swiper-container-',
      },
    }, options)
  }

  _createDataProps() {
    this.dataName = 'data-b-swiper'
    this.dataValue = {}

    const dataNode = document.querySelector(`[${this.dataName}]`)

    if (!dataNode) {
      return
    }

    const dataNodeValue = dataNode.getAttribute(this.dataName)

    try { // Классическая проверка JSON
      this.dataValue = JSON.parse(dataNodeValue)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  /**
     * @description
     * Создается объект с данными, которые необходимы для работы публичного метода promise
     * Логика метода описана выше
     *
     * @return { Object } - объект с параметрами
     * */

  _createPromiseCounter() {
    this.promiseCounter = {
      delay: 100,
      counter: 0,
      quantity: 10,
    }
  }

  _changeValueIsInit({ value } = {}) {
    this.isInit = value
  }
}

export default SwiperModule
