import { defineStore } from 'pinia'
import { createDevNotice, setQueryParam, deleteQueryParam } from 'utils'
import { createRequestClubActiveCard } from 'components/common/Club/api'
import { CLUB_DISCOUNT } from 'components/common/Club/constants'
import { getUserClubCardData } from 'components/common/Club/components/core/ClubCardGetting/api'
import {
  getOfferData,
  getCouponData,
  getInitClubStatusData,
} from 'components/common/AppointmentPage/interfaces'
import { APPOINTMENT_TYPES } from 'constants'
import { PAYMENT_TYPES } from 'components/common/AppointmentPage/constants'
import useLpuDataStore from 'components/common/AppointmentPage/stores/useLpuDataStore'
// eslint-disable-next-line import/no-cycle
import useDoctorDataStore from 'components/common/AppointmentPage/stores/useDoctorDataStore'
// eslint-disable-next-line import/no-cycle
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'
import usePreliminaryConsultationStore from 'components/common/AppointmentPage/stores/usePreliminaryConsultationStore'

const useClubDataStore = defineStore('clubDataStore', {
  state: () => ({
    clubStatusData: getInitClubStatusData(),
    offerData: getOfferData(),
    couponData: getCouponData(),
    selectedPaymentType: null,
    isClubModalVisible: false,
    isInitClubViewVisibilityAllowed: true,
    userClubData: window.USER.club,
  }),
  getters: {
    isInitClubViewVisible() {
      return this.isInitClubViewVisibilityAllowed && !!this.clubStatusData.couponData?.id
    },
    isClubPaymentTypeSelected() {
      return this.selectedPaymentType === PAYMENT_TYPES.club
    },
    isClubSelectTypePaymentVisible() {
      const doctorDataStore = useDoctorDataStore()
      const appointmentDataStore = useAppointmentDataStore()

      return Boolean(
        appointmentDataStore.appointmentRegistrationTypes.isDirect
                && doctorDataStore.selectedWorkplace.isInClub
                && this.selectedPaymentType,
      )
    },
    isClubAvailable() {
      const lpuDataStore = useLpuDataStore()
      const preliminaryConsultationStore = usePreliminaryConsultationStore()

      // TODO: убрать проверку, когда добавится клуб для консультаций
      if (preliminaryConsultationStore.isPreliminaryConsultation) {
        return false
      }

      return lpuDataStore.lpuData.isEnabledClubDiscount && this.userClubData.isInClub
    },
  },
  actions: {
    updateOfferData(payload) {
      this.offerData = {
        ...this.offerData,
        ...payload,
      }
    },
    updateCouponData(payload) {
      this.couponData = {
        ...this.couponData,
        ...payload,
      }
    },
    updateUserClubData(payload) {
      this.userClubData = {
        ...this.userClubData,
        ...payload,
      }
    },
    updateStateSelectedPaymentType(payload) {
      this.selectedPaymentType = payload
    },
    updateStateIsClubModalVisible(payload) {
      this.isClubModalVisible = payload
    },
    updateStateIsInitClubViewVisibilityAllowed(payload) {
      this.isInitClubViewVisibilityAllowed = payload
    },

    setStateSelectedPaymentType(paymentType) {
      const appointmentDataStore = useAppointmentDataStore()

      this.updateStateSelectedPaymentType(paymentType)

      const isClubSelected = paymentType === PAYMENT_TYPES.club && this.isClubSelectTypePaymentVisible
      const isLpuSelected = (paymentType === PAYMENT_TYPES.lpu && this.isClubSelectTypePaymentVisible)
        || !this.isClubSelectTypePaymentVisible

      if (isClubSelected) {
        setQueryParam('type', APPOINTMENT_TYPES.club)
        appointmentDataStore.updateInitAdditionalPlaceType(APPOINTMENT_TYPES.club)
      } else if (isLpuSelected) {
        deleteQueryParam('type')
        appointmentDataStore.updateInitAdditionalPlaceType(APPOINTMENT_TYPES.lpu)
      }
    },
    async requestOfferData() {
      const doctorDataStore = useDoctorDataStore()

      if (!doctorDataStore.selectedWorkplace.isInClub) {
        return
      }

      try {
        const offerData = await createRequestClubActiveCard({
          wpID: doctorDataStore.selectedWorkplace.id,
        })

        this.updateOfferData({
          lpuPrice: offerData.club_lpu_price,
          couponPrice: offerData.club_coupon_price,
          cardDateEnd: offerData.club_card_dt_end,
          cardPrice: offerData.club_card_price,
          totalPrice: offerData.club_offer_total_price,
          cardLifetimeMonths: offerData.CLUB_CARD_LIFETIME_MONTHS,
          clubDiscount: CLUB_DISCOUNT,
        })
      } catch (response) {
        createDevNotice({
          module: 'useClubDataStore',
          method: 'requestOfferData',
          description: response?.data?.detail || 'Произошла ошибка',
        })
      }
    },
    async requestUserClubData() {
      try {
        const userClubData = await getUserClubCardData()

        this.updateUserClubData(userClubData)
      } catch {
        createDevNotice({
          module: 'useClubDataStore',
          method: 'requestUserClubData',
          description: 'Произошла ошибка при попытке запросить клубные данные пользователя.',
        })
      }
    },
  },
})

export default useClubDataStore
