<template>
  <VApp data-patient-profile-modal>
    <component
      :is="modal"
      :is-visible="isVisible"
      :patient-data="patientData"
      @modal:close="handleCloseModal"
    />
  </VApp>
</template>

<script>
import PatientProfileModalMobile from 'components/common/PatientProfile/components/mobile/PatientProfileModal'
import PatientProfileModalDesktop from 'components/common/PatientProfile/components/desktop/PatientProfileModal'

export default {
  name: 'PatientProfileModal',
  components: {
    PatientProfileModalMobile,
    PatientProfileModalDesktop,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    modal() {
      return this.isMobile
        ? 'PatientProfileModalMobile'
        : 'PatientProfileModalDesktop'
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('modal:close')
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-patient-profile-modal] {
  @include v-app-reset;
}

html {
  // Перезаписывает стили от ress.css (сброс стилей от Vuetify)
  overflow: initial;
}
</style>
