var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "appointment-tab-item",
      class: {
        "appointment-tab-item_club": _vm.isInClub && _vm.isClubSwitchEnabled,
        "appointment-tab-item_active": _vm.isActiveTab,
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click", $event)
        },
      },
    },
    [
      _c("div", { staticClass: "appointment-tab-item__inner" }, [
        _c(
          "div",
          {
            staticClass: "appointment-tab-item__title ui-text ui-text_overline",
          },
          [_vm._v("\n      " + _vm._s(_vm.description) + "\n    ")]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "ui-text ui-text_nowrap" },
          [
            _vm.priceList.priceClub && _vm.isClubSwitchEnabled
              ? [
                  _c(
                    "span",
                    {
                      staticClass:
                        "appointment-tab-item__price ui-text ui-text_subtitle-1",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.priceList.priceClub) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "ui-text ui-text_body-2 ui-text_line-through ui-kit-color-text-secondary",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.priceList.price) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              : [
                  _c(
                    "span",
                    {
                      staticClass:
                        "appointment-tab-item__price ui-text ui-text_subtitle-1",
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.priceList.price) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
          ],
          2
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }