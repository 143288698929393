<template>
  <div :data-selected-speciality-id="selectValue[optionKeys.id]">
    <SelectModuleButton
      :class="selectButtonClass"
      :title="selectValue[optionKeys.title]"
      :subtitle="selectValue[optionKeys.subtitle]"
      @click="handleClickSelect"
    />

    <VDialog
      :value="isVisibleModal"
      fullscreen
      persistent
      hide-overlay
      no-click-animation
      transition="fade-transition"
    >
      <ModalLayout
        close-to
        :title="modalTitle"
        :limit-title="modalLimitTitle"
        @modal:back="handleCloseModal"
      >
        <SelectModalOptions
          v-bind="{ ...$attrs, ...$props }"
          @[eventClickOption]="handleClickOption"
        />
      </ModalLayout>
    </VDialog>
  </div>
</template>

<script>
import ModalLayout from 'components/mobile/core/ModalLayout'
import SelectModuleButton from 'components/common/SelectModule/SelectModuleModal/components/common/SelectModuleButton'
import SelectModalOptions from 'components/common/SelectModule/SelectModuleModal/components/common/SelectModalOptions'
import SelectModalLogic from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalLogic'

export default {
  name: 'SelectModal',
  components: {
    ModalLayout,
    SelectModuleButton,
    SelectModalOptions,
  },
  mixins: [
    SelectModalLogic,
  ],
}
</script>
