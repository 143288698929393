import ModalLayout from 'components/mobile/core/ModalLayout'
import AwardModalContent from 'components/common/Award/components/common/AwardModalContent'

const AwardModalLogic = {
  components: {
    ModalLayout,
    AwardModalContent,
  },
  props: {
    modalData: {
      type: Object,
      default: () => ({}),
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
    isVisibleModal: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dialogContentClasses() {
      return this.isDarkMode
        ? `${this.blockName} ${this.blockDarkName}`
        : `${this.blockName}`
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('award-modal:close')
    },
    handleClickRedirect() {
      this.$emit('award-modal:click-redirect')
    },
  },
}

export default AwardModalLogic
