import { emitOpenGettingCard, isEnabledClubServicesSwitch } from 'www/global.js.blocks/common/club-card-getting/functions'
import YaGoals from 'modules/YandexGoals'
// noinspection ES6CheckImport
import { APPOINTMENT_TYPES } from 'constants'
import { AppointmentModules } from 'modules/appointment-modules'
import { getVisitType } from 'modules/Schedule/functions'
import { CLUB_YANDEX_GOALS, APPOINTMENT_YANDEX_GOALS } from 'yandexGoals'

const yaGoalsInstance = new YaGoals()

const {
  redirectToNewAppointment,
  redirectToNewAppointmentClinic,
} = new AppointmentModules()

const AppCalendarRedirect = {
  methods: {
    redirectToAppointment(payload) {
      const {
        target,
        dataItem,
        slotDoctorId,
        slotInGetOptions,
      } = payload

      if (this.isAppointmentDoctor) {
        let visitType = ''
        let doctorSpec = ''
        const parentContainer = target?.closest('.b-doctor-card__lpus-info') || target?.closest('.b-doctor-contacts__wp-info')
        const clubSwitchElement = parentContainer?.querySelector?.('[data-club-switch-block]')

        if (dataItem) {
          visitType = clubSwitchElement
            ? clubSwitchElement.getAttribute('data-club-switch-block')
            : getVisitType({ target, dataItem })

          doctorSpec = target?.closest(`[${dataItem}]`) // если пользователь находится в мобильной версии на странице врача - он может выбрать специальность в 'select'
            ?.querySelector('[data-selected-speciality-id]')
            ?.getAttribute('data-selected-speciality-id') // ToDo schedule-important избавиться от привязки к DOM дереву
        }

        if (visitType === APPOINTMENT_TYPES.club) {
          const { clickClubSlot } = CLUB_YANDEX_GOALS

          yaGoalsInstance.send({ name: clickClubSlot })
        }

        redirectToNewAppointment({
          lpu: this.lpuId,
          slot: slotInGetOptions,
          type: visitType,
          doctor: this.doctorId || slotDoctorId, // this.doctorId - для страницы врача/списка врачей, slotDoctorId - для страницы клиники/списка клиник
          doctorSpec: this.doctorSpec || doctorSpec, // this.doctorSpec для страницы списка врачей, doctorSpec для страницы врача
          spec_placement: this.specPlacement,
        })

        return
      }

      if (this.isAppointmentLpu) {
        const redirectData = {
          slot: slotInGetOptions,
          lpu: this.lpuId,
          synonymId: this.synonymId,
        }

        if (dataItem) { // Поле dtype необязательно должно использоваться для редиректа на форму записи. Backend имеет механизм, с помощью которого его достает у себя(например он используется при переходе со страницы цен клинки)
          const selectedSynonymTypeId = target.closest(`[${dataItem}]`)
            .querySelector('[data-synonym-type-id]')
            .getAttribute('data-synonym-type-id') // ToDo schedule-important избавиться от привязки к DOM дереву

          redirectData.dTypeId = selectedSynonymTypeId || this.dtypeId // Если пользователь выбрал конкретный синоним, значит у синонима есть его 'dtypeId'. Если он неизвестен - берется 'dtypeId' со страницы.
        }

        /**
         * Поскольку перед редиректом на форму записи
         * если пользователь неизвестен и включен клубный switch
         * или если пользователь известен, но не имеет клубной карты
         * сначала показываем уведомление о покупке клубной карты.
         * Поэтому останавливаем редирект и делегируем переход в форму компоненту.
         * */
        if (isEnabledClubServicesSwitch() && this.isEnabledLpuClub) {
          emitOpenGettingCard({ appointmentData: redirectData })
          return
        }

        const {
          clickServiceListSlotDirect,
          clickServiceListSlotExpress,
          clickSlotServicePriceListDirect,
          clickSlotServicePriceListExpress,
        } = APPOINTMENT_YANDEX_GOALS

        yaGoalsInstance.send({
          name: this.serviceIsDirect ? clickServiceListSlotDirect : clickServiceListSlotExpress,
        })

        if (this.isLpuPriceList) {
          yaGoalsInstance.send({
            name: this.serviceIsDirect ? clickSlotServicePriceListDirect : clickSlotServicePriceListExpress,
          })
        }

        if (this.isDoctorPriceList) {
          yaGoalsInstance.send({
            name: 'doctor-price-list-click-slot',
          })
        }

        redirectToNewAppointmentClinic(redirectData)
      }
    },
  },
}

export default AppCalendarRedirect
