import { createDevNotice } from 'utils'
import Vue from 'components'
import CalendarMobile from 'components/mobile/AppointmentCalendar/AppointmentCalendar'
import CalendarDesktop from 'components/desktop/AppointmentCalendar/AppointmentCalendar'
import { getModuleDataInterface } from 'modules/Schedule/models'

/**
 * @description
 * Модуль содержит методы, необходимые для рендера и уничтожения расписания.
 *
 * @param { Object } - данные интерфейса 'getModuleDataInterface', которые собраны со страницы
 * */
class ScheduleCalendar {
  #data = getModuleDataInterface()

  constructor(opts) { this.update(opts) }

  /**
     * @public
     * @description
     * Производит обновление приватного поля '#data'
     *
     * @param { Object } opts
     * @param { Object } opts.data - данные интерфейса 'getModuleDataInterface', которые собраны со страницы
     * */
  update({ data }) { this.#data = data }

  /**
     * @public
     * @description
     * Производит рендер расписания, основываясь на данных, которые передаются в 'constructor'.
     * В зависимости от устройства 'mobile' | 'desktop' вызывается нужный компонент
     *
     * @return { Map } - возвращает список отрендеренных компонентов в формате { HTMLElement: VueComponent }
     * */
  render() {
    const instances = new Map()
    const component = window.MOBILE_VERSION ? CalendarMobile : CalendarDesktop

    this.#data.render.forEach((props, container) => {
      container.innerHTML = '<div class="schedule"></div>'

      instances.set(container, new Vue({
        el: container.querySelector('.schedule'),
        render: h => h(component, { props }),
      }))
    })

    return instances
  }

  /**
     * @public
     * @description
     * Производит частичный дестрой компонентов(только того списка, который передан)
     *
     * @param { Object } opts
     * @param { Map } opts.instances - список компонентов, у которых необходимо вызвать '$destroy' в формате, возвращаемым методом 'render'
     * */
  destroyMap({ instances = new Map() } = {}) {
    instances.forEach((instance, node) => {
      if (this.#data.instances.has(node)) {
        this.#data.instances.get(node).$destroy()
      }
    })
  }

  /**
     * @public
     * @description
     * Производит полный дестрой компонентов(основываясь на данных, которые переданы в 'constructor')
     * */
  destroyAll() {
    try {
      this.#data.instances.forEach(instance => instance.$destroy())
      this.#data.instances.clear()
    } catch (error) { this._createNotice(error) }
  }

  /**
     * @private
     * @description
     * Логирует ошибку и отправляет ее в 'Sentry'
     *
     * @param { Object } - объект ошибки
     * */
  _createNotice({ message }) {
    createDevNotice({
      module: this.constructor.name,
      description: message,
    })
  }
}

export default ScheduleCalendar
