import { AWARD_YANDEX_GOALS } from 'yandexGoals'

const AwardCommonData = {
  data: () => ({
    isMobile: window.MOBILE_VERSION,
    redirectLink: 'https://award.prodoctorov.ru/',
    yaGoalsList: { ...AWARD_YANDEX_GOALS },
  }),
}

export default AwardCommonData
