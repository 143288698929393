import { dispatchCustomEventGlobally } from 'utils'
import { MODIFIER } from 'modules/Modal/constants'
import { BLOCK_NAME, CLASS_NAMES } from 'www/doctors.blocks/common/b-review-card/constants'

function addListenerClickReviewMore({ target }) {
  // Если браузер древний и не поддерживает closest или произошло неоригинальное событие(например от бота) могут возникнуть ошибки.
  // Catch пустой, чтобы в Sentry не сыпались лишние логи
  try {
    const more = target.closest(`.${CLASS_NAMES.replyBodyMore}`)

    if (!more) {
      return
    }

    const body = more.closest(`.${CLASS_NAMES.replyBody}`)

    if (!body) {
      return
    }

    const modal = more.closest(`.${MODIFIER}__body`)
    const truncated = body.querySelector(`.${CLASS_NAMES.replyBodyPartTruncated}`)
    const swiperContainer = body.closest('.swiper-container') // Класс добавляется по умолчанию библиотекой, нет нужды выносить в константу

    if (truncated) {
      more.style.display = 'none'
      truncated.classList.remove(CLASS_NAMES.replyBodyPartTruncated)
    }

    // Если клик происходит в модальном окне - скролл там производится некорректно
    // Чтобы это исправить нужен фрагмент ниже
    if (modal) {
      const article = more.closest(`.${BLOCK_NAME}`) // Конкретный отзыв в модальном окне
      const cards = [...modal.querySelectorAll(`.${BLOCK_NAME}`)] // Все отзывы в модальном окне
      const modalHeaderHeight = document.querySelector(`.${MODIFIER}_active`)
        .querySelector(`.${MODIFIER}__head`)?.scrollHeight || 0

      let hasDesiredNode = false

      const scrollTop = cards.reduce((acc, card) => { // Считает высоту всех проскроленных отзывов, исключая высоту ответа на отзыв
        if (hasDesiredNode) {
          return acc
        }

        if (card === article) {
          hasDesiredNode = true
          return acc + card.scrollHeight - card.querySelector(`.${CLASS_NAMES.reply}`).scrollHeight
        }

        return acc + card.scrollHeight
      }, 0)

      modal.scrollTop = scrollTop - modalHeaderHeight
    }

    if (swiperContainer) { // dispatch используется для обновления высоты слайда, если отзыв находится в слайдере swiper
      dispatchCustomEventGlobally('update-swiper-slider', { swiperContainer })
    }
    } catch {} // eslint-disable-line
}

export default addListenerClickReviewMore
