var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.text
    ? _c(
        "VApp",
        { attrs: { "data-mark-of-shame": "" } },
        [
          _vm.hasBackground
            ? _c(
                _setup.BaseAlert,
                { attrs: { type: "red", icon: "ui-icon-circle-info" } },
                [
                  _c("div", {
                    staticClass: "ui-text ui-text_body-2",
                    domProps: { textContent: _vm._s(_setup.text) },
                  }),
                ]
              )
            : _c("div", { staticClass: "d-flex align-center" }, [
                _c("div", {
                  staticClass:
                    "ui-icon-circle-info ui-kit-color-text-error mr-2",
                }),
                _vm._v(" "),
                _c("div", {
                  staticClass: "ui-text ui-text_body-2 ui-kit-color-text-error",
                  domProps: { textContent: _vm._s(_setup.text) },
                }),
              ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }