var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VApp", { attrs: { "data-club-discount-switch": "" } }, [
    _c(
      "div",
      {
        staticClass: "club-discount-switch",
        class: [
          _vm.contentClass,
          { "club-discount-switch_fixed-bottom": _vm.fixedBottomValue },
        ],
        style: {
          bottom: _vm.fixedBottomValue ? _vm.fixedBottomValue : "initial",
        },
        attrs: { "data-qa": "club_discount_switch" },
        on: { click: _vm.emitClickClubDiscountSwitch },
      },
      [
        _c(
          "div",
          {
            staticClass: "club-discount-switch__inner",
            class: { "flex-row-reverse": !_vm.isMobile },
          },
          [
            _vm.discount
              ? _c(
                  "div",
                  {
                    staticClass: "club-discount-switch__price-discount",
                    class: _vm.isMobile ? "mr-3" : "ml-3",
                    attrs: { "data-qa": "club_discount_switch_percent" },
                  },
                  [_vm._v("\n        -" + _vm._s(_vm.discount) + "%\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_subtitle-1 ui-kit-color-error w-100",
                attrs: { "data-qa": "club_discount_switch_title" },
              },
              [_vm._v("\n        " + _vm._s(_vm.title) + "\n      ")]
            ),
            _vm._v(" "),
            _c("VSwitch", {
              staticClass: "pt-0 mt-0 pl-1",
              class: _vm.isMobile ? "ml-2" : "mr-3",
              attrs: {
                value: _vm.isEnabled,
                "input-value": _vm.isEnabled,
                color: "uiKitError",
                "data-qa": "club_discount_switch_input",
                "hide-details": "",
                dense: "",
                inset: "",
              },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _vm.$slots.append && _vm.isFFPdClubComponentsUpdate
          ? _c(
              "div",
              { staticClass: "club-discount-switch__append" },
              [_vm._t("append")],
              2
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }