import { getPinTemplate } from 'components/common/PopupMap/functions/utils'
import { NAVIGATION_PANEL_CLASSES } from 'components/common/PopupMap/constants'

function getStartEndAddressTextTemplate({ departureAddressText, arrivalAddress }) {
  const pinA = getPinTemplate({
    text: 'A',
    classes: 'ui-kit-bg-error d-flex align-center mr-2',
  })

  const pinB = getPinTemplate({
    text: 'B',
    classes: 'ui-kit-bg-primary d-flex align-center mr-2',
  })

  return `
        <div class="mt-4">
            <div class="d-flex">
                ${pinA}
                <div 
                    class="ui-text ui-text_body-1 ${NAVIGATION_PANEL_CLASSES.addressTextContainer}"
                    data-qa="popup_map_departure_address_text"
                >
                    ${departureAddressText}
                </div>
            </div>
            <div class="d-flex mt-5">
                ${pinB}
                <div
                    class="ui-text ui-text_body-1 ${NAVIGATION_PANEL_CLASSES.addressTextContainer}"
                    data-qa="popup_map_arrival_address_text"
                >
                    ${arrivalAddress}
                </div>
            </div>
        </div>
    `
}

export default getStartEndAddressTextTemplate
