import deepMerge from 'deepmerge'

import {
  getDataJSON,
  getObjectTyping,
} from 'utils'

import {
  getModuleDataInterface,
  getNodesItemInterface,
  getNodesStorageInterface,
} from 'modules/Schedule/models'

import { VALIDATION_DATA } from 'modules/Schedule/constants'
import { getPageDataInterface } from 'modules/Schedule/functions'

/**
 * @description
 * Производит первоначальную сборку данных со страницы, а также форматирует их и производит преобразование полей для более простого использования в 'js'
 *
 * @param { Object } opts - опции с данными
 * @param { Object } opts.options - объект с настройками при вызове модуля 'Schedule'
 *
 * @return { Object | never } data - объект собранных данных или выбрасывает ошибку, если возникает во время сбора
 * */

const getPageConvertedData = ({ options }) => {
  const resultData = getModuleDataInterface()
  const { dataJSONMessage } = VALIDATION_DATA

  const {
    pageType,
    dataItem,
    useLogger,
    dataStorage,
    selectorContainersSlots,
  } = options

  const itemDataList = getDataJSON({ dataName: dataItem || null }) // null передается, чтобы IDE не ругался на передаваемый тип
  const storageDataList = getDataJSON({ dataName: dataStorage || null })

  /**
     * Функция 'getDataJSON' возвращает массив с объектами в формате { node, data }(узел, у которого был искомый атрибут и данные в формате JSON).
     * Если функция не может парсить JSON, значит объект невалидный и возвращается вместо объекта null.
     * Соответственно если нет данных со страницы - выбрасывается ошибка.
     * */

  const isValidPageData = itemDataList.every(({ data: item }) => item)
        && storageDataList.every(({ data: item }) => item)
        && itemDataList.length
        && storageDataList.length

  if (!isValidPageData) {
    if (useLogger) {
      throw new Error(dataJSONMessage)
    }

    return resultData
  }

  const {
    itemsInterface,
    storageInterface,
  } = getPageDataInterface(pageType)

  /**
     * После того как данные собраны производится слияние с базовыми интерфейсами и преобразование полей к формату 'JS'.
     * Также объект дополняется иными полезными данными для более удобного доступа.
     * Возможно модуль вызывается с неправильным 'pageType' - для этого используется проверка 'item[pageType]'
     * */

  const mergedItems = itemDataList.map(
    ({ data: item, node }) => {
      if (!item[pageType]) {
        return
      }

      const merged = deepMerge.all([
        { ...itemsInterface },
        getNodesItemInterface(),
        getObjectTyping(item[pageType]),
      ])

      merged.coverNode = node
      merged.scheduleNodeList = [...node.querySelectorAll(selectorContainersSlots)] // временное поле, ниже в коде оно удаляется

      return merged
    },
  ).filter(i => i)

  const [storageData] = storageDataList.map(
    ({ data: item, node }) => {
      if (!item[pageType]) {
        return
      }

      const merged = deepMerge.all([
        { ...storageInterface },
        getNodesStorageInterface(),
        getObjectTyping(item[pageType]),
      ])

      merged.coverNode = node

      return merged
    },
  )

  if (!(mergedItems.length && storageData)) {
    return resultData
  }

  resultData.storage = storageData

  /**
     * scheduleNodeList используется как временное поле, необходимое для правильной привязки узла для рендера расписания.
     * Оно актуально в случае, если в карточке несколько календарей, например в списке врачей(несколько workplace, у каждого workplace свое расписание)
     * В остальных местах у каждой карточки одно расписание.
     * Поскольку поле 'scheduleNodeList' временно - оно не будет использоваться в дальнейшем, поэтому удаляется
     * */

  mergedItems.forEach(item => {
    item.scheduleNodeList.forEach(scheduleNode => {
      resultData.items.set(scheduleNode, {
        ...item,
        scheduleNode,
        scheduleNodeHTML: scheduleNode.innerHTML,
      })
    })
  })

  resultData.items.forEach(data => { delete data.scheduleNodeList })

  return resultData
}

export default getPageConvertedData
