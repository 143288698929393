import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { PlaceType } from 'components/common/DoctorPriceBlock/types'
import { REDIRECT_VALUES } from 'www/constants'
import type { LpuAddressList } from 'components/common/DoctorPageLpuList/types'
import { useExternalDataStore } from 'components/common/DoctorPageLpuList/stores/index'

export default defineStore('doctorPageLpuListStore', () => {
  const externalDataStore = useExternalDataStore()
  const { lpuAddressList } = storeToRefs(externalDataStore)

  const filterType = ref<PlaceType>('clinic')
  const redirectValue = ref<typeof REDIRECT_VALUES[keyof typeof REDIRECT_VALUES]>('default')
  const expandedPanels = ref<number[]>([0])

  function checkAppointmentPriceByFilter(workplace: LpuAddressList[number]['workplaces'][number]) {
    switch (filterType.value) {
      case 'clinic':
        return typeof workplace.price === 'number'
      case 'home':
        return typeof workplace.price_go === 'number'
      case 'telemed':
        return !!workplace.in_telemed && typeof workplace.pd_price_online === 'number'
    }
  }

  const lpuAddressListFiltered = computed(
    () => lpuAddressList.value?.filter(
      lpuAddress => filterType.value === 'clinic'
      || lpuAddress.workplaces.some(workplace => checkAppointmentPriceByFilter(workplace)),
    ) || [],
  )

  return {
    filterType,
    redirectValue,
    expandedPanels,
    lpuAddressListFiltered,
  }
})
