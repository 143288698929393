var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "VSheet",
    {
      staticClass: "d-flex align-center rounded-t-lg px-4",
      class: [
        _setup.isFilterListVisible
          ? "pt-4 pb-2"
          : _setup.isMobile
          ? "py-4"
          : "py-2",
      ],
      attrs: { color: "primary" },
    },
    [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "ui-kit-color-bg-gray-0 ui-text ui-text_h6" },
            [_vm._v("\n      Запись на приём\n    ")]
          ),
          _vm._v(" "),
          _setup.isFilterListVisible
            ? _c(_setup.AppointmentTypeFilterList, {
                attrs: { "place-types": _setup.placeTypes },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("VSpacer"),
      _vm._v(" "),
      !_setup.isMobile && _setup.filterType !== "telemed"
        ? _c(
            "VBtn",
            {
              staticClass: "ui-kit-color-primary align-self-end",
              attrs: { color: "uiKitBgPrimary", depressed: "" },
              on: { click: _setup.handlePopupMapButtonClick },
            },
            [
              _c("VIcon", { staticClass: "mr-2" }, [
                _vm._v("\n      ui-icon-place\n    "),
              ]),
              _vm._v("\n    На карте\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }