<template>
  <VApp data-club-discount-switch>
    <div
      class="club-discount-switch"
      :class="[contentClass, { 'club-discount-switch_fixed-bottom': fixedBottomValue }]"
      :style="{ bottom: fixedBottomValue ? fixedBottomValue : 'initial' }"
      data-qa="club_discount_switch"
      @click="emitClickClubDiscountSwitch"
    >
      <div
        class="club-discount-switch__inner"
        :class="{ 'flex-row-reverse': !isMobile }"
      >
        <div
          v-if="discount"
          class="club-discount-switch__price-discount"
          :class="isMobile ? 'mr-3' : 'ml-3'"
          data-qa="club_discount_switch_percent"
        >
          -{{ discount }}%
        </div>
        <div
          class="ui-text ui-text_subtitle-1 ui-kit-color-error w-100"
          data-qa="club_discount_switch_title"
        >
          {{ title }}
        </div>
        <VSwitch
          :value="isEnabled"
          :input-value="isEnabled"
          color="uiKitError"
          class="pt-0 mt-0 pl-1"
          :class="isMobile ? 'ml-2' : 'mr-3'"
          data-qa="club_discount_switch_input"
          hide-details
          dense
          inset
        />
      </div>
      <div
        v-if="$slots.append && isFFPdClubComponentsUpdate"
        class="club-discount-switch__append"
      >
        <slot name="append" />
      </div>
    </div>
  </VApp>
</template>

<script>
import vuetify from 'modules/vuetify-plugin-without-reset-scss'
import { dispatchCustomEventGlobally } from 'utils'

export default {
  name: 'ClubDiscountSwitch',
  vuetify,
  model: {
    prop: 'enabled',
    event: 'club-discount-switch:click',
  },
  props: {
    enabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    discount: {
      type: Number,
      default: null,
    },
    fixedBottomValue: {
      type: String,
      default: '',
    },
    useGlobalDispatch: {
      type: Boolean,
      default: false,
    },
    contentClass: {
      type: String,
      default: 'pa-3',
    },
  },
  data: vm => ({
    isFFPdClubComponentsUpdate: window.FEATURE_FLAGS.pd_club_components_update,
    isMobile: window.MOBILE_VERSION,
    isEnabled: vm.enabled,
  }),
  mounted() {
    if (this.useGlobalDispatch) {
      window.addEventListener('club-discount-switch:change-state', this.handleDiscountSwitchChangeState)
    }
  },
  beforeDestroy() {
    if (this.useGlobalDispatch) {
      window.removeEventListener('club-discount-switch:change-state', this.handleDiscountSwitchChangeState)
    }
  },
  methods: {
    handleDiscountSwitchChangeState({ detail }) {
      this.isEnabled = detail.isEnabled
    },
    emitClickClubDiscountSwitch() {
      this.isEnabled = !this.isEnabled

      this.$emit('club-discount-switch:click', this.isEnabled)

      if (this.useGlobalDispatch) {
        dispatchCustomEventGlobally('club-discount-switch:click', {
          isEnabled: this.isEnabled,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.club-discount-switch {
  border-radius: $border-radius-md;
  background-color: $ui-kit-bg-club;
  cursor: pointer;

  &__inner {
    display: flex;
    align-items: center;
  }

  &__price-discount {
    background-color: $ui-kit-error;
    background-image: $ui-kit-club-gradient;
    border-radius: $border-radius-sm;
    color: $ui-kit-bg-gray-0;
    font-weight: $weight-medium;
    font-size: $font-size-subtitle-2;
    line-height: $line-height-base;
    letter-spacing: 0;
    padding: 2px 4px;
  }

  &_fixed-bottom {
    z-index: 5;
    left: 8px;
    right: 8px;
    position: fixed;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-club-discount-switch] {
  @include v-app-reset;

  .v-input {
    width: 44px;
    height: 20px;
  }
}
</style>
