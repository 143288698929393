<template>
  <VDialog
    :value="isVisibleModal"
    fullscreen
    persistent
    hide-overlay
    :content-class="dialogContentClasses"
    transition="fade-transition"
  >
    <ModalLayout
      close-to
      limit-title
      :title="modalData.title"
      class-body="award-modal-mobile__body"
      @modal:back="handleCloseModal"
    >
      <AwardModalContent
        :is-dark-mode="isDarkMode"
        :modal-data="modalData"
        class="p-4"
      />
      <div class="award-modal-mobile__actions">
        <VBtn
          block
          class="mb-2"
          elevation="0"
          :color="isDarkMode ? 'gold' : 'primary'"
          :dark="isDarkMode"
          @click="handleClickRedirect"
        >
          <span :class="{ 'ui-text ui-kit-color-text': isDarkMode }">
            {{ modalData.button.text }}
          </span>
        </VBtn>
        <VBtn
          text
          block
          :color="isDarkMode ? 'gold' : 'black'"
          :dark="isDarkMode"
          @click="handleCloseModal"
        >
          <span
            class="ui-text"
            :class="{
              'ui-text_color_gold': isDarkMode,
              'ui-kit-color-text': !isDarkMode,
            }"
          >
            Закрыть
          </span>
        </VBtn>
      </div>
    </ModalLayout>
  </VDialog>
</template>

<script>
import AwardModalLogic from 'components/common/Award/mixins/AwardModalLogic'

export default {
  name: 'AwardModal',
  mixins: [
    AwardModalLogic,
  ],
  data: () => ({
    blockName: 'award-modal-mobile',
    blockDarkName: 'award-modal-mobile_dark',
  }),
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.award-modal-mobile {
  &__actions {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px;
    background-color: $ui-kit-bg-gray-0;
  }

  &_dark &__actions {
    background-color: $ui-kit-text;
    color: $ui-kit-bg-gray-0;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.award-modal-mobile {
  &__body {
    padding-bottom: 120px;
  }

  &_dark {
    .modal,
    .modal__header {
      background-color: $ui-kit-text !important;
      color: $ui-kit-bg-gray-0;
    }

    .modal__header-button .v-icon {
      color: $ui-kit-bg-gray-0 !important;
      caret-color: $ui-kit-bg-gray-0 !important;
    }

    .modal__header .ui-text_h6 {
      color: $ui-kit-bg-gray-0;
    }

    .modal__header-divider {
      background-color: $ui-kit-text-secondary;
    }
  }
}
</style>
