import { axiosClient, getCSRFToken } from 'utils'

/**
 * @description Запрашивает связки врач-оборудование
 *
 * @param { Object } requestData - данные запроса
 * @param { number } requestData.lpuId - id клиники
 * @param { number } requestData.synonymId - id синонима/услуги
 * @param { string } requestData.appointmentDate - выбранное время записи (слот)
 * */
function getDoctorEquipmentRelations({ lpuId, synonymId, appointmentDate }) {
  return axiosClient({
    url: '/ajax/services/relations/',
    method: 'POST',
    data: {
      lpu: lpuId,
      synonym: synonymId,
      slot: appointmentDate,
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCSRFToken(),
    },
  })
    .then(({ data }) => data)
}

export default getDoctorEquipmentRelations
