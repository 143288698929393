export const MOUNT_SELECTOR = '.vue-soft-version-icon-mount'

export const SOFT_VERSION = {
  light: 'LIGHT',
  standard: 'STANDARD',
  pro: 'PRO',
}

export const SOFT_VERSION_DATA = {
  [SOFT_VERSION.light]: {
    translate: 'Лайт',
    image: {
      src: '/static/_v1/pd/icons/soft-version/light.svg',
      alt: 'Версия ПО "Лайт"',
    },
  },
  [SOFT_VERSION.standard]: {
    translate: 'Стандарт',
    image: {
      src: '/static/_v1/pd/icons/soft-version/standard.svg',
      alt: 'Версия ПО "Стандарт"',
    },
  },
  [SOFT_VERSION.pro]: {
    translate: 'Профи',
    image: {
      src: '/static/_v1/pd/icons/soft-version/pro.svg',
      alt: 'Версия ПО "Профи"',
    },
  },
}
