import { APP_CALENDAR_QA_GOALS } from 'dataQA'

const AppCalendarDataQA = {
  data: () => ({
    QALocators: {
      ...APP_CALENDAR_QA_GOALS,
    },
  }),
}

export default AppCalendarDataQA
