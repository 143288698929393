import { AppointmentModules } from 'modules/appointment-modules'
import { emitOpenGettingCard, isEnabledClubServicesSwitch } from 'www/global.js.blocks/common/club-card-getting/functions'
import { getItemDataWithParent } from 'modules/Schedule/functions/helpers'
import { VALIDATION_DATA } from 'modules/Schedule/constants'

const { redirectToNewAppointmentClinic } = new AppointmentModules()

/**
 * @description
 * Производит редирект в форму записи на услугу при клике на кнопку 'Записаться'
 *
 * @param { Object } opts
 * @param { Object } opts.data - интерфейс 'getModuleDataInterface'
 * @param { HTMLElement } opts.target - узел кнопки, на котором произошло событие клика
 * @param { Number | null } opts.selectedSynonymId - если известен выбранный id синонима
 * @param { Number | null } opts.selectedSynonymTypeId - если известен выбранный type id синонима
 * */

const redirectToLpuApp = opts => {
  const {
    data,
    target,
    selectedSynonymId,
    selectedSynonymTypeId,
  } = opts
  const [, itemData] = getItemDataWithParent({ data, target })

  if (!itemData) {
    throw new Error(VALIDATION_DATA.isEmptyItemData)
  }

  const { lpuId, isEnabledLpuClub } = itemData
  const { id: synonymId } = itemData.synonymPreview
  const { dtypeId, dclassId } = data.storage

  const redirectData = {
    lpu: lpuId,
    dTypeId: selectedSynonymTypeId || dtypeId, // если пользователь выбрал конкретный синоним, значит у синонима есть его 'dtypeId'. Если он неизвестен - берется 'dtypeId' со страницы.
    dClassId: dclassId,
    // Если пользователь сменил синоним - должно использоваться поле 'synonymId: selectedSynonymId'.
    // Если это поле пустое, значит пользователь не менял синоним и записывается с выбором по умолчанию - 'synonymId: synonymId'(т.е selectedSynonymId === null)
    // synonymId по умолчанию так же может быть пустым. Это значит, что у клиники нет синонимов
    synonymId: selectedSynonymId || synonymId,
  }

  /**
     * Поскольку перед редиректом на форму записи
     * если пользователь неизвестен и включен клубный switch
     * или если пользователь известен, но не имеет клубной карты
     * сначала показываем уведомление о покупке клубной карты.
     * Поэтому останавливаем редирект и делегируем переход в форму компоненту.
     * */
  if (isEnabledClubServicesSwitch() && isEnabledLpuClub) {
    emitOpenGettingCard({ appointmentData: redirectData })
    return
  }

  redirectToNewAppointmentClinic(redirectData)
}

export default redirectToLpuApp
