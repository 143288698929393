/**
 * Возвращает transition duration переданного элемента в ms.
 * @param {HTMLElement} element
 * @returns {number}
 */
export const getTransitionDurationMs = element => (
  getComputedStyle(element).getPropertyValue('transition-duration').replace('s', '') * 1000
)

/**
 * @description формирую пространство имен для всех модальных окон
 *
 * @params { Object } options
 * @params { String } [options.modifier] - название блока
 *
 * @return { Object } - пространство имен
 * */

function getNamespace({ modifier } = {}) {
  return {
    cover: `${modifier}`,
    active: `${modifier}_active`,
    cross: `${modifier}__cross`,
    head: `${modifier}__head`,
    headInner: `${modifier}__head-inner`,
    headAfter: `${modifier}__head-after`,
    headTitle: `${modifier}__head-title`,
    body: `${modifier}__body`,
    crossIcon: `${modifier}__cross-icon`,
    container: `${modifier}__container`,
    listItem: `${modifier}__list-item`,
    dataTemplate: 'data-modal-template',
    dataZIndex: 'data-modal-z-index',
  }
}

export default getNamespace
