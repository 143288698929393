import covidMessage from 'blocks/doctors.mobile.blocks/common/b-covid-message/b-covid-message';

// eslint-disable-next-line func-names
(function () {
  // eslint-disable-next-line func-names
  $(document).on('click', '.b-covid', function () {
    const lpuId = $(this).attr('data-addr')
    const headerText = $(this).attr('data-lpu-name')
    const desktop = $(this).attr('data-desktop')

    covidMessage.show({ lpuId, headerText, desktop }, message => {
      $(document.body).prepend($(message))
    })
  })
}())
