<template>
  <div>
    <div
      class="mtlink-banner"
      :class="{
        'mtlink-banner_box-shadow': hasBoxShadow,
      }"
      @click="handleOpenModal"
    >
      <div>
        <img
          class="mtlink-banner__medtochka-logo"
          src="/static/_v1/pd/logos/ui-kit/medtochka-logo.svg"
          alt="Логотип МедТочки"
        >
        <div
          class="ui-text ui-text_body-2"
          v-text="text"
        />
      </div>
      <div class="ml-3">
        <span class="ui-icon-circle-info" />
      </div>
    </div>

    <BannerMtLinkModal
      :is-visible="isVisible"
      @modal:close="handleCloseModal"
    />
  </div>
</template>

<script>
import YaGoals from 'modules/YandexGoals'
import BannerMtLinkModal from 'components/common/BannerMtLink/components/mobile/BannerMtLinkModal'

export default {
  name: 'BannerMtLink',
  components: {
    BannerMtLinkModal,
  },
  props: {
    hasBoxShadow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isVisible: false,
    instanceYaGoals: new YaGoals(),
  }),
  methods: {
    handleOpenModal() {
      this.isVisible = true

      this.instanceYaGoals.send({
        name: 'mtlinkModal__open',
      })
    },
    handleCloseModal() {
      this.isVisible = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.mtlink-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  border-radius: $border-radius-sm;
  background-color: $ui-kit-bg-secondary;
  color: $ui-kit-primary;
  cursor: pointer;

  &_box-shadow {
    box-shadow: 0 15px 20px rgba(#979EA7, 0.1);
  }

  &__medtochka-logo {
    max-width: 84px;
    margin-bottom: 8px;
  }
}
</style>
