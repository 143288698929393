<template>
  <div>
    <div
      class="mtlink-banner mtlink-banner_js_open-modal"
      :class="[
        classNames.triggerOpenPopup,
        { 'mtlink-banner_box-shadow': hasBoxShadow },
      ]"
    >
      <img
        class="mtlink-banner__medtochka-logo mr-8"
        src="/static/_v1/pd/logos/ui-kit/medtochka-logo.svg"
        alt="Логотип МедТочки"
      >
      <div
        class="ui-text ui-text_body-2 mr-3"
        v-text="text"
      />
      <span
        class="ui-icon-circle-info ml-auto"
      />
    </div>
  </div>
</template>

<script>
import { CLASS_NAMES } from 'components/common/BannerMtLink/constants'

export default {
  name: 'BannerMtLink',
  props: {
    hasBoxShadow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      classNames: CLASS_NAMES,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.mtlink-banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: $border-radius-sm;
  background-color: $ui-kit-bg-secondary;
  color: $ui-kit-primary;
  cursor: pointer;

  &_box-shadow {
    box-shadow: 0 15px 20px rgba(#979EA7, 0.1);
  }

  &__medtochka-logo {
    max-width: 84px;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.mtlink-banner {
  &__detail-title {
    text-align: center;
    font-weight: $weight-medium;
    font-size: $font-size-h6;
    line-height: 130%;
    letter-spacing: 0.15px;
  }

  &__detail:last-of-type {
    margin-bottom: 0;
  }

  &__image-box {
    width: 460px;
    height: 314px;
  }

  &__pagination {
    margin-bottom: 24px;
  }
}
</style>
