import { defineStore } from 'pinia'
import { addHours, format } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  createDevNotice,
  isEmptyObject,
  getTimezoneOffsetInHours,
  isNetworkDisconnect,
} from 'utils'
import {
  getEditableCalendar,
  getInitCalendarCommonData,
  getInitCalendarDoctorData,
  getInitCalendarServicesData,
  getSelectedDate,
} from 'components/common/AppointmentPage/interfaces'
import { getCalendarData } from 'components/common/AppointmentPage/api'
import { findAvailableSlotBySchedule } from 'components/common/AppointmentPage/functions'
import { APPOINTMENT_ERRORS } from 'components/common/AppointmentPage/constants'
import useLpuDataStore from 'components/common/AppointmentPage/stores/useLpuDataStore'
import useGlobalDataStore from 'components/common/AppointmentPage/stores/useGlobalDataStore'
// eslint-disable-next-line import/no-cycle
import useDoctorDataStore from 'components/common/AppointmentPage/stores/useDoctorDataStore'
// eslint-disable-next-line import/no-cycle
import useServiceDataStore from 'components/common/AppointmentPage/stores/useServiceDataStore'
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'
// eslint-disable-next-line import/no-cycle
import usePreliminaryConsultationStore from 'components/common/AppointmentPage/stores/usePreliminaryConsultationStore'

const useCalendarDataStore = defineStore('calendarDataStore', {
  state: () => ({
    initCalendarData: {
      ...getInitCalendarCommonData(),
      ...getInitCalendarServicesData(),
      ...getInitCalendarDoctorData(),
    },
    editableCalendar: getEditableCalendar(),
    lastSelectedDate: getSelectedDate(),
    isLoadingSlots: false,
    appointmentCalendarEl: null,
    isZeroRequests: true,
    isErrorState: false,
  }),
  getters: {
    hasSlotsRequestData() {
      const serviceDataStore = useServiceDataStore()
      const appointmentDataStore = useAppointmentDataStore()

      if (appointmentDataStore.appointmentObjectTypes.isServices) {
        return Boolean(this.initCalendarData.synonymId && serviceDataStore.serviceData.hasIntervals)
      }

      if (appointmentDataStore.appointmentObjectTypes.isDoctor) {
        return Boolean(this.initCalendarData.doctorsAndLpus?.length && this.initCalendarData.lpuTimedeltas?.length)
      }

      return false
    },
    lastSelectedDateString() {
      if (this.lastSelectedDate.day && this.lastSelectedDate.time) {
        return `${this.lastSelectedDate.day} ${this.lastSelectedDate.time}`
      }

      return ''
    },
    editableCalendarTitle() {
      const { time, dateName } = this.isCalendarDateSelected
        ? this.editableCalendar.selectedDate
        : this.initCalendarData

      if (time && dateName) {
        return `${dateName.toLowerCase()}, ${time}`
      }

      return ''
    },
    editableCalendarDays() {
      return Object.keys(this.editableCalendar.calendarList)
    },
    isEmptyCalendarList() {
      return isEmptyObject(this.editableCalendar.calendarList)
    },
    isValidCalendar() {
      if (this.isLoadingSlots) {
        return false
      }

      const appointmentDataStore = useAppointmentDataStore()
      const isNotDirect = !appointmentDataStore.appointmentRegistrationTypes.isDirect
        || appointmentDataStore.useServiceExpress

      return this.isAvailableSlot || (isNotDirect && this.isEmptyCalendarList)
    },
    isCalendarDateSelected() {
      const { day, time } = this.editableCalendar.selectedDate

      return Boolean(day && time)
    },
    selectedSlotDate() {
      if (!this.isCalendarDateSelected) {
        return null
      }

      const { day, time } = this.editableCalendar.selectedDate

      return new Date(`${day}T${time}`)
    },
    selectedFullDate() {
      if (!this.selectedSlotDate) {
        return ''
      }

      return format(this.selectedSlotDate, 'dd.MM.yyyy HH:mm', { locale: ru })
    },
    isAvailableSlot() {
      const appointmentDataStore = useAppointmentDataStore()

      if (this.editableCalendar.selectedDate.isSlotFixed) {
        return true
      }

      // Не проверяем доступность слота по расписанию клиники для ПЗ
      if (
        appointmentDataStore.appointmentRegistrationTypes.isDirect
        && !appointmentDataStore.useServiceExpress
      ) {
        return !this.isEmptyCalendarList && this.isCalendarDateSelected
      }

      if (!this.isCalendarDateSelected || !this.nearestFreeAppointmentDate || this.isEmptyCalendarList) {
        return false
      }

      const { day, time } = this.editableCalendar.selectedDate
      const selectedSlotDate = new Date(`${day}T${time}`)

      return selectedSlotDate >= this.nearestFreeAppointmentDate
    },
    nearestFreeAppointmentDate() {
      if (this.isEmptyCalendarList || this.isLoadingSlots) {
        return null
      }

      const lpuDataStore = useLpuDataStore()
      const appointmentDataStore = useAppointmentDataStore()

      const { times: callTimes, minHoursToCall } = appointmentDataStore.appointmentCallTimes
      const { calendarList } = this.editableCalendar
      let nearestFreeAppointmentDate = null
      const { schedule } = lpuDataStore.lpuData

      if (!callTimes.length) {
        const [day, slots] = Object.entries(calendarList).find(([, slotList]) => slotList.length) || []

        if (!slots) {
          return null
        }

        return new Date(`${day}T${slots[0].time}`)
      }

      const minAvailableDate = addHours(new Date(callTimes[0].date), minHoursToCall)
      const minAvailableDayDate = new Date(minAvailableDate)

      minAvailableDayDate.setUTCHours(0, 0, 0, 0)

      Object.entries(calendarList).some(([day, slots]) => {
        if (new Date(day) < minAvailableDayDate) {
          return false
        }

        const availableSlot = findAvailableSlotBySchedule({
          day,
          slots,
          schedule,
          minDate: minAvailableDate,
        })

        if (availableSlot) {
          nearestFreeAppointmentDate = new Date(`${day}T${availableSlot.time}`)
        }

        return nearestFreeAppointmentDate
      })

      return nearestFreeAppointmentDate
    },
  },
  actions: {
    updateCalendarIsChanging(payload) {
      this.editableCalendar.isChanging = payload
    },
    updateCalendarList(payload) {
      this.editableCalendar.calendarList = payload
    },
    updateSelectedDate(payload) {
      this.editableCalendar.selectedDate = {
        ...this.editableCalendar.selectedDate,
        ...payload,
      }
    },
    resetSelectedDate() {
      this.editableCalendar.selectedDate = {}
    },
    updateIsLoadingSlots(payload) {
      this.isLoadingSlots = !!payload
    },
    updateAppointmentCalendarEl(payload) {
      this.appointmentCalendarEl = payload
    },
    updateLastSelectedDate(payload) {
      this.lastSelectedDate = payload
    },
    updateIsZeroRequests(payload) {
      this.isZeroRequests = payload
    },
    updateIsErrorState(payload) {
      this.isErrorState = payload
    },

    validateCalendar() {
      this.updateIsErrorState(!this.isValidCalendar)

      if (!this.isValidCalendar) {
        return this.appointmentCalendarEl
      }
    },
    requestForSlots({ afterRequestCallback = () => {}, errorRequestCallback = () => {} }) {
      const doctorDataStore = useDoctorDataStore()
      const serviceDataStore = useServiceDataStore()
      const appointmentDataStore = useAppointmentDataStore()
      const preliminaryConsultationStore = usePreliminaryConsultationStore()

      this.updateIsLoadingSlots(true)

      if (!this.hasSlotsRequestData) {
        errorRequestCallback()
        this.updateIsLoadingSlots(false)

        return
      }

      const { appointmentObjectTypes } = appointmentDataStore
      let requestData = {}

      if (appointmentObjectTypes.isServices) {
        const service = {
          service_id: this.initCalendarData.synonymId,
          lpu_id: this.initCalendarData.lpuId,
        }

        if (preliminaryConsultationStore.isPreliminaryConsultation) {
          const {
            selectedConsultingDoctor,
            selectedDoctorConsultation,
          } = preliminaryConsultationStore

          if (selectedConsultingDoctor.id) {
            service.doctor_id = selectedConsultingDoctor.id
          }

          if (selectedDoctorConsultation.id) {
            service.service_id = selectedDoctorConsultation.id
            service.service_requires_consultation_id = this.initCalendarData.synonymId
          }
        } else {
          if (serviceDataStore.selectedEquipment.id) {
            service.device_id = serviceDataStore.selectedEquipment.id
          }

          if (doctorDataStore.selectedDoctor.id) {
            service.doctor_id = doctorDataStore.selectedDoctor.id
          }
        }

        requestData = {
          days: this.initCalendarData.days,
          day_start: this.initCalendarData.dateNow,
          only_free: true,
          town_timedelta: this.initCalendarData.timedelta,
          services: [service],
        }
      } else if (appointmentObjectTypes.isDoctor) {
        requestData = {
          days: this.initCalendarData.days,
          dt_start: this.initCalendarData.dateNow,
          doctors_lpus: this.initCalendarData.doctorsAndLpus.map(({ doctorId, lpuId }) => ({
            doctor_id: doctorId,
            lpu_id: lpuId,
          })),
          lpu_timedelta: this.initCalendarData.lpuTimedeltas,
        }

        if (appointmentDataStore.appointmentPlaceTypes.isTelemed) {
          requestData.user_timedelta = getTimezoneOffsetInHours()
          requestData.dt_start = format(new Date(), 'yyyy-MM-dd')
        }
      }

      getCalendarData({ requestData, appointmentObjectTypes })
        .finally(() => {
          this.updateIsLoadingSlots(false)
          this.updateIsZeroRequests(false)
        })
        .then(({ result: [{ slots = {} } = {}] }) => {
          this.updateCalendarList(slots)

          const { day: selectedDay, time: selectedTime } = this.editableCalendar.selectedDate
          const haveSelectedDate = this.editableCalendar.calendarList[selectedDay]?.some(
            ({ time }) => time === selectedTime,
          )

          if (!haveSelectedDate) {
            this.resetSelectedDate()
          }

          if (!this.isValidCalendar) {
            this.updateCalendarIsChanging(true)
          }

          if (
            this.isEmptyCalendarList
            && appointmentDataStore.appointmentRegistrationTypes.isDirect
            && !appointmentDataStore.useServiceExpress
          ) {
            errorRequestCallback()
            return
          }

          afterRequestCallback()
        })
        .catch(({ message, response } = {}) => {
          const { status } = response || {}

          if (isNetworkDisconnect({ status, message })) {
            const globalDataStore = useGlobalDataStore()

            globalDataStore.updateErrorData({
              isEnabled: true,
              isDialogRender: true,
              ...APPOINTMENT_ERRORS.notNetwork,
            })
            this.updateIsLoadingSlots(true)

            return
          }

          errorRequestCallback()

          createDevNotice({
            description: message,
            method: 'requestForSlots',
            module: 'AppointmentPage/useCalendarDataStore',
          })
        })
    },
  },
})

export default useCalendarDataStore
