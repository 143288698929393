import { defineStore } from 'pinia'

import { getTimezoneOffsetInHours } from 'utils'
import {
  FORM_CHECKBOXES_OF_CLASSES,
} from 'components/common/AppointmentPage/constants'
// eslint-disable-next-line import/no-cycle
import useServiceDataStore from 'components/common/AppointmentPage/stores/useServiceDataStore'
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'

const useUserDataStore = defineStore('userDataStore', {
  state: () => ({
    isReadWarningInfo: false,
    isErrorWarningInfo: false,
    warningInfoTelemedEl: null,
    checkedCheckboxes: [],
    formCheckboxesRef: null,
    userTimeDelta: getTimezoneOffsetInHours(),
  }),
  getters: {
    checkboxesData() {
      const serviceDataStore = useServiceDataStore()
      const data = FORM_CHECKBOXES_OF_CLASSES[serviceDataStore.serviceData.classTranslit] || []

      data.forEach(datum => {
        datum.label = datum.label.replace('\n', window.MOBILE_VERSION ? '<br>' : '')
      })

      return data
    },
    isCheckboxesChecked() {
      const appointmentDataStore = useAppointmentDataStore()

      return this.checkboxesData.length === this.checkedCheckboxes.length
        || !appointmentDataStore.appointmentRegistrationTypes.isDirect
    },
    isVisibleWarningInfoTelemed() {
      const appointmentDataStore = useAppointmentDataStore()

      return appointmentDataStore.isTelemedPaymentInfoVisible
    },
    isCheckedWarningInfoTelemed() {
      return this.isVisibleWarningInfoTelemed ? this.isReadWarningInfo : true
    },
  },
  actions: {
    updateCheckedCheckboxes(payload) {
      this.checkedCheckboxes = payload
    },
    updateFormCheckboxesRef(payload) {
      this.formCheckboxesRef = payload
    },
    updateIsReadWarningInfo(payload) {
      this.isReadWarningInfo = payload
    },
    updateIsErrorWarningInfo(payload) {
      this.isErrorWarningInfo = payload
    },
    updateWarningInfoTelemedEl(payload) {
      this.warningInfoTelemedEl = payload
    },

    validateCheckboxes() {
      if (!this.formCheckboxesRef) {
        return null
      }

      this.formCheckboxesRef.validate()

      return !this.isCheckboxesChecked
        ? this.formCheckboxesRef.$el
        : null
    },
    validateWarningInfoTelemed() {
      if (!this.isCheckedWarningInfoTelemed) {
        this.updateIsErrorWarningInfo(true)
        return this.warningInfoTelemedEl
      }

      return null
    },
  },
})

export default useUserDataStore
