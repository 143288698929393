import { defineStore } from 'pinia'
import {
  differenceInYears,
  parse,
} from 'date-fns'
import { ru } from 'date-fns/locale'

import {
  getPatient,
  getPatientList,
} from 'components/common/AppointmentPage/interfaces'
import {
  AGE_OF_MAJORITY,
  DEFAULT_PATIENTS_DATA,
  REQUIRED_FIO_DESCRIPTION,
} from 'components/common/AppointmentPage/constants'
import { getFamilyList } from 'components/common/AppointmentPage/api'
import {
  getFioValidationRules,
  getPartialPatientData,
  getBirthDayValidationRulesAllSpecialties,
} from 'components/common/AppointmentPage/functions'
import useLpuDataStore from 'components/common/AppointmentPage/stores/useLpuDataStore'
import useGlobalDataStore from 'components/common/AppointmentPage/stores/useGlobalDataStore'
// eslint-disable-next-line import/no-cycle
import useDoctorDataStore from 'components/common/AppointmentPage/stores/useDoctorDataStore'
// eslint-disable-next-line import/no-cycle
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'

const useFamilyProfilesStore = defineStore('familyProfilesStore', {
  state: () => ({
    selectedPatient: getPatient(),
    patientList: getPatientList(),
    isFamilyRequestDone: false,
    isSelectedPatient: false,
    patientCardEl: null,
    patientFormRef: null,
    patientFormFieldRefs: [],
    modalContentEl: null,
    isErrorState: false,
  }),
  getters: {
    fioValidationRules() {
      const getFioRules = requiredDescription => {
        const { onlyRu, maxLength, required } = getFioValidationRules(requiredDescription)

        // Последовательность имеет значение
        return [required, maxLength, onlyRu]
      }

      return {
        name: getFioRules(REQUIRED_FIO_DESCRIPTION.name),
        surname: getFioRules(REQUIRED_FIO_DESCRIPTION.surname),
        patronymic: getFioRules(REQUIRED_FIO_DESCRIPTION.patronymic),
      }
    },
    birthDayValidationRules() {
      const doctorDataStore = useDoctorDataStore()
      const { appointmentObjectTypes } = useAppointmentDataStore()
      const { required, ageLimit } = getBirthDayValidationRulesAllSpecialties(
        doctorDataStore.rangeAgeLimitAllSpecialities,
        { isServices: appointmentObjectTypes.isServices },
      )

      // Последовательность имеет значение
      return [required, ageLimit]
    },
    isValidFio() {
      const isValidPatronymic = this.selectedPatient.hasPatronymic
        ? this.fioValidationRules.patronymic.every(rule => rule(this.selectedPatient.patronymic) === true)
        : true

      return this.fioValidationRules.name.every(rule => rule(this.selectedPatient.name) === true)
        && this.fioValidationRules.surname.every(rule => rule(this.selectedPatient.surname) === true)
        && isValidPatronymic
    },
    isValidBirthDay() {
      return this.birthDayValidationRules.every(rule => rule(this.selectedPatient.birthDay) === true)
    },
    invalidBirthDayText() {
      return this.isValidBirthDay
        ? null
        : this.birthDayValidationRules
          .map(rule => (typeof rule(this.selectedPatient.birthDay) === 'string' ? rule(this.selectedPatient.birthDay) : null))
          .find(i => typeof i === 'string')
    },
    isValidPatient() {
      return this.isValidFio && this.isValidBirthDay && this.isSelectedPatient
    },
    isUnderagePatient() {
      if (!this.isValidBirthDay) {
        return false
      }

      const now = new Date()
      const parsedBirthDay = parse(
        this.selectedPatient.birthDay,
        'dd.MM.yyyy',
        now,
        { locale: ru },
      )

      return differenceInYears(now, parsedBirthDay) < AGE_OF_MAJORITY
    },
  },
  actions: {
    updateSelectedPatient(payload) {
      this.selectedPatient = {
        ...this.selectedPatient,
        ...payload,
      }
    },
    updatePatientList(payload) {
      this.patientList = payload
    },
    updateIsFamilyRequestDone(payload) {
      this.isFamilyRequestDone = !!payload
    },
    updateIsSelectedPatient(payload) {
      this.isSelectedPatient = !!payload
    },
    updatePatientCardEl(payload) {
      this.patientCardEl = payload
    },
    updatePatientFormRef(payload) {
      this.patientFormRef = payload
    },
    updatePatientFormFieldRefs(payload) {
      this.patientFormFieldRefs = payload
    },
    updateModalContentEl(payload) {
      this.modalContentEl = payload
    },
    updateIsErrorState(payload) {
      this.isErrorState = payload
    },

    validatePatientCard() {
      this.updateIsErrorState(!this.isValidPatient)

      if (!this.isValidPatient) {
        return this.patientCardEl
      }
    },
    async requestFamilyProfiles() {
      const lpuDataStore = useLpuDataStore()
      const appointmentDataStore = useAppointmentDataStore()

      const getDefaultMainPatient = () => ({
        ...getPatient(),
        ...DEFAULT_PATIENTS_DATA.main,
        hasPatronymic: true,
      })

      const setDefaultPatientList = () => {
        const mainPatient = getDefaultMainPatient()

        this.updatePatientList([
          { ...mainPatient },
          {
            ...getPatient(),
            ...DEFAULT_PATIENTS_DATA.other,
          },
        ])
      }

      try {
        const familyProfiles = await getFamilyList({
          lpuId: lpuDataStore.lpuData.id,
          appointmentTypeName: appointmentDataStore.appointmentTypeName,
        })

        const now = new Date()

        const patientList = familyProfiles.map(({
          uuid = '',
          last_name: surname = '',
          first_name: name = '',
          second_name: patronymic = '',
          birthday: birthDay,
          is_main: isMain = false,
        }, index) => ({
          id: index,
          uuid,
          name,
          surname,
          patronymic,
          hasPatronymic: !!patronymic || (!name && !surname && !patronymic),
          isMain,
          isOther: false,
          isEmptyMain: false,
          ...getPartialPatientData({
            name,
            surname,
            patronymic,
            birthDay: birthDay
              ? parse(birthDay, 'yyyy-MM-dd', now, { locale: ru })
              : null,
          }),
        }))

        // Сортируем, чтобы главный профиль стал первым
        patientList.sort(a => (a.isMain ? -1 : 1))

        if (patientList.length) {
          const mainPatient = patientList[0].isMain
            ? patientList[0]
            : getDefaultMainPatient()

          this.updatePatientList([
            { ...mainPatient },
            ...patientList.slice(1),
            {
              ...getPatient(),
              ...DEFAULT_PATIENTS_DATA.other,
            },
          ])
        } else {
          setDefaultPatientList()
        }
      } catch (error) {
        if (error?.response?.status === 423) {
          const globalDataStore = useGlobalDataStore()

          globalDataStore.updateErrorData({
            isEnabled: true,
            title: 'Клиника ограничила для вас онлайн-запись',
            titleMaxWidth: '350px',
            buttonState: {
              get: appointmentDataStore.appointmentObjectTypes.isDoctor ? 'toListDoctor' : 'toListLpu',
            },
          })

          return
        }

        setDefaultPatientList()
      }

      this.updateIsFamilyRequestDone(true)
    },
  },
})

export default useFamilyProfilesStore
