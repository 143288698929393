var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VBtn",
    {
      class: { "appointment-button_club-gradient": _vm.clubGradient },
      attrs: {
        "data-qa": "appointment_form_appointment_button",
        color: "primary",
        elevation: "0",
        disabled: !_vm.isFamilyRequestDone,
        block: "",
      },
      on: { click: _vm.handleAppointment },
    },
    [
      _vm._t("default", function () {
        return [
          _vm.iconName && !_vm.appointmentPlaceTypes.isTelemed
            ? _c("VIcon", { staticClass: "mr-2" }, [
                _vm._v("\n      " + _vm._s(_vm.iconName) + "\n    "),
              ])
            : _vm._e(),
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.appointmentPlaceTypes.isTelemed ? "К оплате" : "Записаться"
              ) +
              "\n  "
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }