import { axiosClient, getCSRFToken } from 'utils'

function getFamilyList({ lpuId, appointmentTypeName } = {}) {
  return axiosClient({
    url: '/ajax/medtochka/patient/profile/family/',
    method: 'GET',
    params: {
      lpu_id: lpuId,
      appointment_type: appointmentTypeName,
    },
    headers: {
      'X-CSRFToken': getCSRFToken(),
    },
    timeout: 5000,
  })
    .then(({ data }) => data)
}

export default getFamilyList
