import { axiosClient, serializeObject } from 'utils'

async function getSynonymAndDevicesData({
  lpuId, // ID клиники (обязательное)
  classId, // ID класса услуги (обязательное)
  categoryId, // ID категории услуги (не обязательное)
  typeId, // ID типа услуги (не обязательное)
}) {
  const payloadData = {
    lpu_id: lpuId,
    class_id: classId,
  }

  if (categoryId) {
    payloadData.category_id = categoryId
  }

  if (typeId) {
    payloadData.type_id = typeId
  }

  const queryParams = serializeObject(payloadData)

  return axiosClient.get(`/ajax/services/tree/?${queryParams}`)
}

export default getSynonymAndDevicesData
