<template>
  <VCombobox
    ref="combobox"
    class="search-combobox"
    v-bind="$attrs"
    append-icon=""
    attach=""
    clear-icon="ui-icon-close-not-a-circle"
    prepend-inner-icon="ui-icon-search ui-kit-color-text-info"
    :background-color="combobox.isFocused ? 'uiKitBgGray0' : 'uiKitBgGray40'"
    :items="dataItems"
    :menu-props="{
      maxHeight: 252,
      allowOverflow: true,
      offsetOverflow: false,
      rounded: true,
    }"
    :hide-no-data="!visibleSearchHint"
    outlined
    clearable
    no-filter
    full-width
    hide-details
    @update:search-input="handleSearchInputDebounced"
    @click:clear="handleClearInput"
    @input="handleInput"
  >
    <template #item="{ item, on }">
      <VListItem v-on="on">
        <VListItemContent @click="handleClickMenuItem(item)">
          <VListItemTitle>
            {{ item }}
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
    <template #no-data>
      <VListItem>
        <VListItemContent>
          <VListItemTitle class="ui-text ui-kit-color-text-secondary p-2">
            Введите {{ minQueryLength }} символа для поиска
          </VListItemTitle>
        </VListItemContent>
      </VListItem>
    </template>
  </VCombobox>
</template>

<script>
import { debounce } from 'utils'

export default {
  name: 'SearchCombobox',
  props: {
    initialData: {
      type: Array,
      default: () => [],
    },
    minQueryLength: {
      type: Number,
      default: 2,
    },
  },
  data() {
    return {
      searchQuery: '',
      dataItems: this.initialData,
      wasSelectedItem: false,
      combobox: {},
      hideNoData: false,
      handleSearchInputDebounced: () => {},
    }
  },
  computed: {
    visibleSearchHint() {
      return this.searchQuery.length < this.minQueryLength && !this.hideNoData
    },
  },
  watch: {
    initialData(val) {
      this.dataItems = val
    },
  },
  mounted() {
    this.combobox = this.$refs.combobox
  },
  created() {
    this.handleSearchInputDebounced = debounce(this.emitSearchQuery, 300)
  },
  methods: {
    /** @public */
    refreshHint() {
      this.hideNoData = true
      this.$nextTick(() => {
        this.hideNoData = false
      })
    },
    clearServiceClassList() {
      this.dataItems = []
    },
    resetServiceClassList() {
      this.dataItems = this.initialData
    },
    emitSearchQuery(searchString) {
      // Такая проверка нужна, т.к. при выборе элемента из списка
      // также вызывается `update:search-input` из-за изменения текста в инпуте
      if (this.wasSelectedItem) {
        this.wasSelectedItem = false
        return
      }

      const searchQuery = (searchString || '').toLowerCase().trim()

      if (this.searchQuery === searchQuery) {
        return
      }

      this.searchQuery = searchQuery

      // Поисковая строка была очищена, либо пустая, значит надо вернуть начальные значения
      if (!searchQuery) {
        this.resetServiceClassList()
        this.$emit('search:default')
        return
      }

      this.clearServiceClassList() // Ввели хотя бы 1 символ, выпадающий список скрывается

      if (this.visibleSearchHint) {
        return
      }

      // Отправляем строку, по которой необходимо искать услуги
      this.$emit('search:text', searchQuery)
    },
    handleClearInput() {
      this.resetServiceClassList()
      this.emitSearchQuery()
    },
    // Данный обработчик добавлен, т.к. необходимо выводить услуги
    // только по выбранному классу (его имени), если он выбран из списка
    handleClickMenuItem(selectedClass) {
      this.searchQuery = selectedClass
      this.wasSelectedItem = true

      // Отправляем имя класса, по которому необходимо вывести услуги
      this.$emit('search:class', selectedClass)
    },
    // Обработчик нужен, чтобы обнулить список после того, как был выбран элемент,
    // т.к. обработка клика по элементу из списка происходит гораздо раньше, чем обновление модели VCombobox
    handleInput(value) {
      if (value && this.wasSelectedItem) {
        this.clearServiceClassList()
      }
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.search-combobox {
  &.v-text-field--outlined fieldset {
    border-width: 0;
  }

  &.v-input--is-focused fieldset {
    border-width: 2px;
  }

  &.v-input input {
    &::placeholder {
      opacity: 1;
      color: $ui-kit-text-info;
    }
  }

  &.v-input--is-focused input {
    &::placeholder {
      opacity: 0;
    }
  }
}
</style>
