<template>
  <div
    class="b-appointment-calendar"
    :data-lpu="lpuId"
    :data-doctor="doctorId"
    :class="{
      'b-appointment-calendar_expanded': !!expanded.state,
    }"
  >
    <div
      ref="days-container"
      class="b-appointment-calendar__days"
    >
      <div class="b-appointment-calendar__days-container">
        <div
          ref="days-wrapper"
          class="b-appointment-calendar__days-wrapper"
        >
          <div
            ref="active-day"
            class="b-appointment-calendar__active-day"
            :data-active-day="curSlots"
            :style="{
              left: `${daysLeftCoordHash[curSlots]}px`,
            }"
            :class="{
              'b-appointment-calendar__active-day_start_coord': !daysLeftCoordHash[curSlots],
            }"
          >
            <div class="b-appointment-calendar__active-day-inner" />
          </div>
          <div
            v-for="(day, index) in days"
            :key="`day-${index}`"
            ref="day"
            class="b-appointment-calendar__day"
            :data-day="day"
            :data-week="Math.floor(index / 7) + 1"
            :class="{
              'b-appointment-calendar__day_is_active': curSlots === day,
              'b-appointment-calendar__day_is_disabled': !data[day].length,
            }"
            @click="onClickDay($event, day)"
          >
            <div class="b-appointment-calendar__day-inner">
              <div class="b-appointment-calendar__day-name">
                {{ getWeekDay(day) }}
              </div>
              <div class="b-appointment-calendar__day-num">
                {{ getWeekDay(day, 'num') }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="b-appointment-calendar__next-days-btn"
        :class="{
          'b-appointment-calendar__next-days-btn_last-week': curWeek === weeks,
        }"
        :data-qa="QALocators[curWeek === weeks ? 'button-prev' : 'button-next']"
        @click="onClickGetNextDays"
      >
        <span
          aria-hidden="true"
          class="b-appointment-calendar__next-icon ui-icon-arrow-right ui-icon_fz_smaller"
        />
      </div>
    </div>
    <div
      class="b-appointment-calendar__slots-container"
      :class="{
        'b-appointment-calendar__slots-container_expanded': !!expanded.state,
        'b-appointment-calendar__slots-container_start-coord': !(daysLeftCoordHash[curSlots] % (DAY_WIDTH * 7)),
      }"
    >
      <AppCalendarSlotSet
        :doctor-id="doctorId"
        :base-slot-set="data"
        :selected-slot="dataFromSelectedDate"
        :selected-date="curSlots"
        :expanded-state="expanded.state"
        :dynamic-slot-set="slotsDataObj"
        @[eventClickSlot]="handleClickOnTime"
        @[eventClickExpand]="onClickExpandBtn"
        @[eventClickCollapse]="onClickCollapseBtn"
      />
    </div>
    <PreliminaryConsultationModal
      :is-visible="isVisibleInvasiveModal"
      @preliminary-consultation-modal:click-close="handleInvasiveModalClose"
      @preliminary-consultation-modal:click-continue="handleInvasiveModalContinue"
      @preliminary-consultation-modal:visible="handleInvasiveModalVisible"
    />
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import { ru } from 'date-fns/locale'
import {
  format,
  isValid,
  parseISO,
  startOfDay,
  differenceInWeeks,
} from 'date-fns'

import YandexGoals from 'modules/YandexGoals'
import { APPOINTMENT_YANDEX_GOALS } from 'yandexGoals'
import PreliminaryConsultationModal from 'components/common/PreliminaryConsultationModal/PreliminaryConsultationModal'

import {
  AppCalendarProps,
  AppCalendarEvents,
  AppCalendarDataQA,
  AppCalendarRedirect,
} from 'components/common/AppointmentCalendar/mixins/index'
import AppCalendarSlotSet from 'components/common/AppointmentCalendar/components/AppCalendarSlotSet/AppCalendarSlotSet'

const DAY_WIDTH = 49

export default {
  name: 'AppointmentCalendar',
  vuetify,
  components: {
    AppCalendarSlotSet,
    PreliminaryConsultationModal,
  },
  mixins: [
    AppCalendarProps,
    AppCalendarEvents,
    AppCalendarDataQA,
    AppCalendarRedirect,
  ],
  data() {
    return {
      yandexGoalsInstance: new YandexGoals(),
      expanded: {
        state: false,
        itemsNum: 9,
      },
      daysLeftCoordHash: {},
      weeks: 1,
      curWeek: 0,
      curSlots: this.initDay || this.days[0],
      dataFromSelectedDate: this.selectedDate,
      DAY_WIDTH,
      unwatchIsHidden: () => {},
      handleInvasiveModalContinue: () => {},
      isVisibleInvasiveModal: false,
    }
  },
  computed: {
    /**
         * Свойство слотов для записи определенной даты, служит для скрытия или развертки всех слотов для показа
         * @returns {({} & default.props.data) | ({} & {default, type})}
         */
    slotsDataObj() {
      const slotsLimit = 5 * 2 // количество слотов, которое может быть выведено в 2 строки

      if (!this.curSlots) {
        return
      }

      if (this.data[this.curSlots].length === slotsLimit) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.expanded.itemsNum = slotsLimit // проверка на скрытие кнопки "показать ещё"
      }

      return {
        ...this.data,
        [this.curSlots]: this.data[this.curSlots].slice(0, this.expanded.itemsNum),
      }
    },
    /**
         * Ближайший для записи день
         */
    availDay() {
      let resultDay

      const idx = Math.max(0, this.days.indexOf(this.curSlots))
      const days = [...this.days.slice(idx), ...this.days.slice(0, idx)]

      days.some(day => {
        const hasDays = this.data[day].length

        if (hasDays) {
          resultDay = day

          return true
        }

        return false
      })

      return resultDay
    },
    dateName() {
      if (!this.dataFromSelectedDate.day) {
        return ''
      }

      const selectedDayISO = parseISO(this.dataFromSelectedDate.day)

      if (!isValid(selectedDayISO)) {
        return ''
      }

      const day = format(selectedDayISO, 'cccccc', { locale: ru })
      const formattedDay = day[0].toLowerCase() + day.slice(1)
      const formattedDayAndMonth = format(selectedDayISO, 'd MMMM', { locale: ru })

      return `${formattedDayAndMonth} (${formattedDay})` // 6 июня (пн)
    },
  },
  watch: {
    curWeek: {
      immediate: true,
      handler() {
        this.scrollToCurrentWeek()
      },
    },
    curSlots: {
      immediate: true,
      handler(value) {
        this.curWeek = differenceInWeeks(startOfDay(new Date(value)), startOfDay(new Date(this.days[0])))
      },
    },
    data: {
      immediate: true,
      deep: true,
      handler() {
        // Скролл не работает, если элемент скрыт, поэтому необходимо подождать, когда элемент появится
        if (this.isHidden) {
          this.unwatchIsHidden()
          this.unwatchIsHidden = this.$watch('isHidden', isHidden => {
            if (!isHidden) {
              this.handleChangeData()
              this.unwatchIsHidden()
            }
          })

          return
        }

        this.handleChangeData()
      },
    },
  },
  created() {
    this.$on('reset', this.onReset)
  },
  mounted() {
    this.collectDaysLeftCoordHash()
    this.curSlots = this.availDay
  },
  beforeDestroy() {
    this.$off('reset')
  },
  methods: {
    sendYaGoal(name) {
      this.yandexGoalsInstance.send({ name })
    },
    handleChangeData() {
      this.curSlots = this.availDay
      this.scrollToCurrentWeek()
    },
    scrollToCurrentWeek() {
      this.$nextTick(() => {
        const daysWrapper = this.$refs['days-wrapper']

        if (!daysWrapper) {
          return
        }

        if (daysWrapper.scrollTo) {
          daysWrapper.scrollTo({
            left: DAY_WIDTH * 7 * this.curWeek,
            top: 0,
            behavior: 'smooth',
          })
        } else {
          daysWrapper.scrollLeft = DAY_WIDTH * 7 * this.curWeek
        }
      })
    },
    /**
         * Создает хэш из координат левых отступов у DOM элементов "b-appointment-calendar__active-day"
         * Нужно для показа активного дня
         */
    collectDaysLeftCoordHash() {
      if (!this.$refs.day.length) {
        return
      }

      this.$refs.day.forEach((day, i) => {
        const leftCoord = i * DAY_WIDTH

        this.$set(this.daysLeftCoordHash, day.getAttribute('data-day'), leftCoord)
      })
    },
    /**
         *
         * @param date - дата в формате yyyy-mm-dd
         * @param formatType - форматировать, по сокращенному названию дня недели или числу в месяце
         * @returns {*|string}
         */
    getWeekDay(date, formatType) {
      if (!date) {
        return
      }

      const days = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
      const dPat = date.split('-')
      const dt = new Date(dPat[0], dPat[1] - 1, dPat[2]) // year, month, day

      if (formatType === 'name') {
        return days[dt.getDay()]
      }

      if (formatType === 'num') {
        return dPat[2]
      }

      return days[dt.getDay()]
    },
    /**
         * Обработчик события клика по дню
         * @param event - объект события
         * @param daySlots - дата в формате yyyy-mm-dd
         */
    onClickDay(event, daySlots) {
      if (!this.data[daySlots].length) {
        return
      }

      this.curSlots = daySlots

      this.expanded.itemsNum = 9
      this.expanded.state = false
    },
    closeInvasiveModal() {
      this.isVisibleInvasiveModal = false
    },
    handleInvasiveModalVisible() {
      this.sendYaGoal(APPOINTMENT_YANDEX_GOALS.serviceOpenModalPreliminaryConsultation)
    },
    handleInvasiveModalClose() {
      this.sendYaGoal(APPOINTMENT_YANDEX_GOALS.serviceClickDeclinePreliminaryConsultation)
      this.closeInvasiveModal()
    },
    handleClickOnTime(event, slot) {
      if (this.serviceIsPreliminaryConsultation) {
        this.isVisibleInvasiveModal = true

        this.handleInvasiveModalContinue = () => {
          this.sendYaGoal(APPOINTMENT_YANDEX_GOALS.serviceClickConfirmPreliminaryConsultation)

          this.closeInvasiveModal()
          this.onClickTime(event, slot)
        }

        return
      }

      this.onClickTime(event, slot)
    },
    /**
         * Обработчик события клика по слоту с временем записи
         * @param event - объект события
         * @param slot
         */
    onClickTime(event, slot) {
      this.dataFromSelectedDate = {
        time: slot.time,
        day: this.curSlots,
      }

      const payload = {
        event,
        options: slot,
        slot: slot.time,
        lpuId: this.lpuId,
        dtypeId: this.dtypeId,
        deviceId: this.deviceId,
        synonymId: this.synonymId,
        day: this.curSlots,
        doctorId: this.doctorId,
        lpuAddrId: this.lpuAddrId,
        doctorSpec: this.doctorSpec,
        specialPlacement: this.specialPlacement,
      }

      if (this.useRedirect) {
        this.redirectToAppointment({
          target: event.target,
          dataItem: this.dataItem,
          slotDoctorId: slot.doctor_id,
          slotInGetOptions: `${format(parseISO(this.curSlots), 'dd.MM.yyyy', { locale: ru })} ${slot.time}`,
        })

        return
      }

      const dayFormatDot = format(parseISO(this.curSlots), 'dd.MM.yyyy', { locale: ru })
      const dayFormatDash = format(parseISO(this.curSlots), 'yyyy-MM-dd', { locale: ru })

      this.$emit('calendar:time-choice', {
        day: dayFormatDash,
        time: payload.slot,
        dateName: this.dateName,
        selectedFullDate: `${dayFormatDot} ${payload.slot}`,
      })
    },
    /**
         * Обработчик события клика, получить следующую неделю
         */
    onClickGetNextDays() {
      this.curWeek = this.curWeek < this.weeks ? this.curWeek + 1 : 0
    },
    /**
         * Обработчик события, развернуть все слоты
         */
    onClickExpandBtn() {
      this.expanded.itemsNum = this.data[this.curSlots].length
      this.expanded.state = true
    },
    /**
         * Обработчик события, свернуть слоты до 9 штук
         */
    onClickCollapseBtn() {
      this.expanded.itemsNum = 9
      this.expanded.state = false
    },
    /**
         * Обработчик события сброса (на данный момент выбранной даты)
         */
    onReset() {
      this.dataFromSelectedDate = {}
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.v-application {
  line-height: initial;
}

.b-appointment-calendar {
  $day-width: 49px;

  font-family: $font-family-regular;
  position: relative;

  &_expanded {
    z-index: 10;
  }

  &__days {
    display: flex;
    position: relative;
    width: 100%;
    top: 4px;
  }

  &__days-container {
    overflow: hidden;
    width: $day-width * 7;
    height: 76px;
  }

  &__days-wrapper {
    display: flex;
    overflow: hidden;
    position: relative;
  }

  &__active-day {
    width: 45px;
    height: 76px;
    position: absolute;
    top: 0;
    left: 0;
    border-top: 1px solid $color-deep-soft-blue;
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    background: $ui-kit-bg-gray-0;
    transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &__active-day-inner {
    border-radius: $border-radius-sm $border-radius-sm 0 0;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    background: $ui-kit-bg-gray-0;
    transform-style: preserve-3d;
    margin-left: 1px;

    &::after {
      content: '';
      position: absolute;
      display: block;
      border-radius: 0 $border-radius-sm 0 0;
      top: -1px;
      bottom: 4px;
      background: $ui-kit-bg-gray-0;
      right: -1px;
      width: 50 / 2 + px;
      z-index: -1;
      transform: translateZ(-1px);
      border-top: 1px solid $color-deep-soft-blue;
      border-right: 1px solid $color-deep-soft-blue;
      box-sizing: border-box;
    }

    &::before {
      content: '';
      position: absolute;
      display: block;
      border-radius: $border-radius-sm 0 0 0;
      top: -1px;
      bottom: 4px;
      background: $ui-kit-bg-gray-0;
      left: -1px;
      width: 50 / 2 + px;
      z-index: -1;
      transform: translateZ(-1px);
      border-top: 1px solid $color-deep-soft-blue;
      border-left: 1px solid $color-deep-soft-blue;
      box-sizing: border-box;
    }
  }

  &__day {
    font-size: $font-size-body-1;
    padding: 13px 13px 16px;
    text-align: center;
    color: $color-neur-dark;
    cursor: pointer;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    width: $day-width;
    user-select: none;
    flex: 0 0 auto;

    &_is_active {
      color: $color-neur-dark;

      &:hover {
        &::before {
          opacity: 0 !important;
        }
      }
    }

    &_is_disabled {
      color: $color-deep-soft-blue;
      cursor: not-allowed;
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      inset: 3px;
      border-radius: $border-radius-sm;
      background: $color-soft-grey;
      opacity: 0;
      transition: 0.2s ease-in-out;
    }

    &:hover:not(&_is_disabled) {
      &::before {
        opacity: 0.5;
      }
    }
  }

  &__day-name {
    margin-bottom: 8px;
    position: relative;
  }

  &__day-num {
    position: relative;
    transition: 0.3s cubic-bezier(0.77, 0, 0.175, 1);
  }

  &__next-icon {
    text-indent: initial;
    background-image: initial;
    transition: 0.2s ease-in-out;
  }

  &__next-days-btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-top: 18px;
    margin-left: 10px;
    color: $ui-kit-primary;
    border-radius: $border-radius-round;
    background-color: $ui-kit-bg-gray-0;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }

  &__next-days-btn_last-week &__next-icon {
    transform: rotate(180deg);
  }

  &__slots-container {
    background: $ui-kit-bg-gray-0;
    position: relative;
    border: 1px solid $color-deep-soft-blue;
    box-sizing: border-box;
    border-radius: $border-radius-sm;
    width: 389px;

    &_start-coord {
      border-radius: 0 $border-radius-sm $border-radius-sm $border-radius-sm;
    }
  }
}
</style>
