import { testRequiredOptions } from 'utils'

/**
 * @description
 *
 * Производит дестрой каждого инстанса табов
 *
 * @param { Object } options
 * @param { Array<VueInstance> } options.vueInstances - инстансы, которые возвращаются методом "renderComponents"
 * */

function destroyInstances({ vueInstances } = {}) {
  const testOptions = testRequiredOptions({
    module: 'AppointmentTabList/destroyInstance',
    requiredOptions: { vueInstances },
  })

  if (!testOptions) {
    return
  }

  vueInstances.forEach(vueInstance => vueInstance.$destroy())
}

export default destroyInstances
