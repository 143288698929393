import NodeListLoader from 'modules/NodeListLoader'
import ReviewsFilterMobile from 'components/mobile/ReviewsFilter'
import ReviewsFilterDesktop from 'components/desktop/ReviewsFilter'
import {
  axiosClient,
  dispatchCustomEventGlobally,
  getLazyLoadInstance,
} from 'utils'
import {
  addListenerUnloadReviews,
  intersectionObserverReviews,
} from 'www/doctors.blocks/common/b-review-card/functions'
import { getMarkup as getPreloaderMarkup } from 'blocks/library.blocks/common/b-preloader/functions'
import { HIDDEN } from 'constants/classNames'

import {
  ACTIONS, CLASS_NAMES, REVIEW_TYPES, SELECTORS,
} from './constants'
import { getInitialReviewsType, setUrlReviewsType } from './functions'
import ReviewsStorage from './modules/ReviewsStorage'
import './index.scss'

/**
 * Создает элементы страницы с отзывами: фильтр, подгрузку отзывов из списка, подгрузку отзывов по AJAX.
 * @author Денис Курочкин (@kurochkin)
 */
class ReviewsPage {
  constructor() {
    const reviewsContainer = document.querySelector(SELECTORS.container)

    if (!reviewsContainer) {
      return
    }

    this.lazyLoadInstance = getLazyLoadInstance()

    this.containerNode = reviewsContainer

    this._removeDuplicatedNodes()

    this.storage = new ReviewsStorage({
      container: reviewsContainer,
    })

    this.useAjax = this.containerNode.matches(SELECTORS.ajaxUrl)
    this._init()
  }

  /**
   * Удаляет задублированные отзывы, если у контейнера присутствует подходящий селектор.
   * @private
   */
  _removeDuplicatedNodes() {
    if (this.containerNode.matches(SELECTORS.containerToClear)) {
      this.containerNode.innerHTML = ''
    }
  }

  /** @private */
  async _init() {
    this._setData()
    this._setState()
    this._setNodes()

    await this._pageLoadHandler()

    if (this.data.initialReviewsType) {
      await this._onFiltering(this.data.initialReviewsType)
    }

    (window.MOBILE_VERSION ? ReviewsFilterMobile : ReviewsFilterDesktop).$mount(`.${CLASS_NAMES.filterVue}`)

    this._addFilterChangeListener()

    addListenerUnloadReviews()
  }

  /**
   * @private
   */
  _setData() {
    const nodesPreloadedDefault = window.MOBILE_VERSION ? 20 : 10
    const nodesNumber = Number(this.containerNode?.dataset?.rpNodesPreloaded) || nodesPreloadedDefault

    this.data = {
      nodesPreloaded: Number(this.containerNode?.dataset?.rpNodesPreloaded) || nodesPreloadedDefault,
      nodesPerLoad: nodesNumber,
      ajaxUrl: this.useAjax ? this.containerNode.dataset.rpAjaxUrl : null,
      initialReviewsType: getInitialReviewsType(),
    }
  }

  /** @private */
  _setState() {
    this.state = {
      page: 0,
      maxNodesToLoad: 0,
      isLoading: false,
      previousReviewsType: null,
      reviewsType: this.data.initialReviewsType || REVIEW_TYPES[0],
    }
  }

  /**
   * Устанавливает значение узлов контейнера экземпляра класса.
   * @private
   */
  _setNodes() {
    this.nodes = {
      container: document.querySelector(SELECTORS.container),
      loader: null,
    }
  }

  /**
   * Обработчик загрузки страницы и инициализации класса.
   * @returns {Promise<void>}
   * @private
   */
  _pageLoadHandler = async () => {
    try {
      if (this.useAjax && this._ajaxPreloadNeeded()) {
        await this._preloadAjaxNodes()
      }

      this._showFilteredNodes()
      this._initNodeListLoader()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  /**
   * Коллбэк, срабатывающий до подгрузки NodeListLoader'ом собственных узлов.
   * @description Обратить внимание: второй вызов метода `this._preloadAjaxNodes` используется, если при первой подгрузке загруженных отзывов не хватает.
   * @param {NodeListLoader} NodeListLoaderInstance
   * @returns {Promise<void>}
   * @private
   */
  _onBeforeNodeListLoaderLoad = async (NodeListLoaderInstance = this.NodeListLoader) => {
    this._updateNodeListLoaderNodes(NodeListLoaderInstance)

    NodeListLoaderInstance.data.nodesLoadedTotal = this._getVisibleNodes(NodeListLoaderInstance)

    if (!(this.useAjax && this._ajaxPreloadNeeded())) {
      return
    }

    try {
      await this._preloadAjaxNodes()

      if (this._ajaxPreloadNeeded()) {
        await this._preloadAjaxNodes()
      }

      this._updateNodeListLoaderNodes(NodeListLoaderInstance)
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  _getVisibleNodes(NodeListLoaderInstance) {
    return NodeListLoaderInstance.nodes.items.reduce(
      (count, item) => (item.classList.contains(HIDDEN) ? count : count + 1),
      0,
    )
  }

  /**
   * Коллбэк, срабатывающий после подгрузки NodeListLoader'ом собственных узлов.
   * @private
   */
  _onAfterNodeListLoaderLoad = () => {
    this._incrementPage()

    intersectionObserverReviews(this.containerNode)

    dispatchCustomEventGlobally('review-card-tooltips:init')
  }

  /**
   * Обрабатывает фильтрацию отзывов.
   * @param {string} option
   * @private
   */
  _onFiltering = async option => {
    try {
      this._toggleLoading()
      this._hideAllNodes()
      this._rememberSelectedOption(option)
      this._resetPage()

      if (this.data.initialReviewsType) {
        /**
         * Небольшая задержка нужно, чтобы подождать,
         * пока DialogManager завершит обработку события `popstate` при закрытии модалки в мобильной версии.
         * Иначе, текущее изменение Get-параметра `type` будет перезаписано
         * */
        setTimeout(() => {
          setUrlReviewsType(option)
        }, 10)
      }

      if (this.useAjax) {
        this.storage.removeAjaxNodes(this.state.previousReviewsType)

        if (this.storage.hasCachedNodes(this.state.reviewsType)) {
          const cachedNodes = this.storage.getCachedNodes(this.state.reviewsType)

          this.nodes.container.append(...cachedNodes)
        } else if (this._ajaxPreloadNeeded()) {
          await this._preloadAjaxNodes()
        }
      }

      this._incrementPage()

      setTimeout(() => {
        this._showFilteredNodes()

        if (!this.data.initialReviewsType) {
          this._updateNodeListLoader()
        }

        this._updateNodeListLoaderNodes(this.NodeListLoader)
        this._toggleLoading()

        const action = this._getVisibleNodes(this.NodeListLoader) >= this.data.maxNodesToLoad
          ? ACTIONS.remove
          : ACTIONS.add

        this.NodeListLoader.toggleControlsDisplay(action)

        dispatchCustomEventGlobally('review-type:filtered')
      })
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  /**
   * Проверяет, нужно ли подгружать дополнительные отзывы по AJAX.
   * @returns {boolean}
   * @private
   */
  _ajaxPreloadNeeded() {
    const nodesWillBeLoadedAmount = this.data.nodesPreloaded + (this.data.nodesPerLoad * this.state.page)
    const isEnoughReviewNodes = this.storage.getTotalAmount(this.state.reviewsType) > nodesWillBeLoadedAmount

    return this.storage.ajaxPage[this.state.reviewsType] && !isEnoughReviewNodes
  }

  /**
   * Подгружает шаблон отзывов по AJAX.
   * @returns { Promise<{ template: string, next_page_number: number }> } Разметка подгружаемых отзывов.
   * @private
   */
  async _ajaxLoad() {
    return axiosClient.get(this.data.ajaxUrl, {
      params: {
        page: this.storage.ajaxPage[this.state.reviewsType],
        ratetype: this.state.reviewsType,
      },
    })
      .then(({ data }) => data)
  }

  /**
   * Вставляет разметку отзывов, подгруженную по AJAX.
   * @param {string} markup
   * @private
   */
  _insertAjaxLoadedMarkup = markup => this.nodes.container.insertAdjacentHTML('beforeend', markup)

  /**
   * Извлекает узлы подгруженных по AJAX отзывов.
   * @returns {Element[]}
   * @private
   */
  _retrieveAjaxLoadedNodes() {
    const outsiderNodesAmount = this.state.isLoading ? 1 : 0
    const amount = this.storage.nodes.static.all.length + this.storage.nodes.ajax[this.state.reviewsType].length

    return [...this.nodes.container.children].slice(amount + outsiderNodesAmount)
  }

  /**
   * Загружает, вставляет на страницу и сохраняет в хранилище подгруженные по AJAX узлы.
   * @returns {Promise<void>}
   * @private
   */
  _preloadAjaxNodes = async () => {
    try {
      const {
        template,
        next_page_number: nextPageNumber,
      } = await this._ajaxLoad()

      this.storage.ajaxPage[this.state.reviewsType] = nextPageNumber
      this._insertAjaxLoadedMarkup(template)
      this.storage.storeAjaxNodes(this.state.reviewsType, this._retrieveAjaxLoadedNodes())

      this.lazyLoadInstance.update()
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error)
    }
  }

  /**
   * Показывает отфильтрованные узлы в соответствии с необходимым для показа количеством.
   * @private
   */
  _showFilteredNodes() {
    this.storage.getAllNodes(this.state.reviewsType)
      .slice(0, this.data.nodesPreloaded)
      .forEach(node => node.classList.remove(HIDDEN))
  }

  /**
   * Инициализирует `NodeListLoader`.
   * @see NodeListLoader
   * @private
   */
  _initNodeListLoader() {
    this.NodeListLoader = new NodeListLoader({
      nodes: this.storage.getAllNodes(this.state.reviewsType),
      nodesPreloaded: this.data.nodesPreloaded,
      hideItemsOnInit: false,
      onLoadEventSelectors: [SELECTORS.button],
      onLoadFinishedEventSelectors: [SELECTORS.buttonToHide],
      onBeforeLoad: this._onBeforeNodeListLoaderLoad,
      onAfterLoad: this._onAfterNodeListLoaderLoad,
    })
  }

  /**
   * Обновляет экземпляр класса `NodeListLoader`.
   * @private
   */
  _updateNodeListLoader = () => {
    this.NodeListLoader.update({
      nodes: this.storage.getAllNodes(this.state.reviewsType),
    })
  }

  /**
   * Обновляет узлы экземпляра класса `NodeListLoader`.
   * @param {NodeListLoader} NodeListLoaderInstance
   * @private
   */
  _updateNodeListLoaderNodes = NodeListLoaderInstance => {
    NodeListLoaderInstance.updateNodes(this.storage.getAllNodes(this.state.reviewsType))
  }

  /**
   * Меняет состояние прелоадера на противоположное текущему.
   * @param { boolean } isActionRemoved
   * @private
   */
  _togglePreloader(isActionRemoved = false) {
    this.nodes.container.classList.toggle(CLASS_NAMES.containerLoading)

    if (isActionRemoved) {
      this.nodes.loader.remove()
      return
    }

    if (this.nodes.loader) {
      this.nodes.container.prepend(this.nodes.loader)
    } else {
      this.nodes.container.insertAdjacentHTML('afterbegin', getPreloaderMarkup())
      this.nodes.loader = this.nodes.container.firstChild
    }
  }

  /**
   * Меняет состояние загрузки отзывов на противоположное текущему.
   * @private
   */
  _toggleLoading() {
    this.state.isLoading = !this.state.isLoading

    this._togglePreloader(!this.state.isLoading)
    this.NodeListLoader.toggleControlsDisplay(this.state.isLoading ? ACTIONS.remove : ACTIONS.add)
  }

  /**
   * Запоминает выбранную опцию при фильтрации.
   * @param {string} option
   * @private
   */
  _rememberSelectedOption(option) {
    this.state.previousReviewsType = this.state.reviewsType
    this.state.reviewsType = option
  }

  /**
   * Скрывает все статические узлы из хранилища.
   * @private
   */
  _hideAllNodes = () => {
    this.storage.nodes.static.all.forEach(node => node.classList.add(HIDDEN))
  }

  /**
   * Инкрементирует значение текущей страницы в состоянии экземпляра класса.
   * @returns {number}
   * @private
   */
  _incrementPage = () => this.state.page++

  /**
   * Приводит значение текущей страницы в состоянии экземпляра класса к исходному.
   * @private
   */
  _resetPage() {
    this.state.page = 0
    this.NodeListLoader.data.pageNumber = 0
  }

  /**
   * Данный метод является багфиксом для Iphone - вызывает перерисовку элемента на странице (Reflow/Layout)
   * проблема: на странице врача (mobile), в модалке с отзывами, при фильтрации отзывов в момент инерционного скролла пропадают отзывы
   * решение: в момент применения фильтрации скрыть контейнер с отзывами, а после отобразить
   * @private
   */
  _updateVisibilityReviews() {
    this.nodes.container.hidden = true

    setTimeout(() => {
      this.nodes.container.hidden = false
    })
  }

  /** @private */
  _addFilterChangeListener() {
    window.addEventListener('review-type:selected', ({ detail }) => {
      this.data.maxNodesToLoad = Number(detail.amount)
      this._onFiltering(detail.value)

      if (window.MOBILE_VERSION) {
        this._updateVisibilityReviews()
      }
    })
  }
}

export default ReviewsPage
