import Vue from 'components'
import AppointmentTabList from 'components/common/AppointmentTabList/AppointmentTabList'
import getRenderData from 'components/common/AppointmentTabList/functions/getRenderData'

/**
 * @description
 *
 * Производит рендер компонентов
 *
 * @return { Array<VueInstance> } - массив отрендеренных компонентов
 * */

function renderComponents() {
  const renderData = getRenderData()
  const vueInstancesResult = []

  renderData.forEach((value, key) => {
    const instance = new Vue({
      el: key,
      render: h => h(AppointmentTabList, { props: value }),
    })

    vueInstancesResult.push(instance)
  })

  return vueInstancesResult
}

export default renderComponents
