import { renderComponents, destroyInstances } from 'components/common/AppointmentTabList/functions'

let vueInstances

function eventContentReplace() {
  destroyInstances({ vueInstances })

  vueInstances = renderComponents()
}

document.addEventListener('content-replace', eventContentReplace, false)
vueInstances = renderComponents()
