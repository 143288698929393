export default {
  containerModifierClass: 'b-slider_js',
  wrapperClass: 'b-slider__container',
  slideClass: 'b-slider__slide',
  slideActiveClass: 'b-slider__slide_active',
  slideVisibleClass: 'b-slider__slide_visible',
  slideNextClass: 'b-slider__slide-prev',
  slidePrevClass: 'b-slider__slide-next',
  slideDuplicateClass: 'b-slider__slide-duplicate',
  slideDuplicateActiveClass: 'b-slider__slide-duplicate_active',
  slideDuplicateNextClass: 'b-slider__slide-duplicate-prev',
  slideDuplicatePrevClass: 'b-slider__slide-duplicate-next',
  speed: 200,
  initialSlide: 0,
  slidesPerView: 1.1,
  roundLengths: true,
  navigation: {
    nextEl: '.b-slider__control_next',
    prevEl: '.b-slider__control_prev',
    disabledClass: 'b-slider__control_disabled',
  },
  pagination: {
    el: '.swiper-pagination',
    dynamicBullets: true,
    dynamicMainBullets: 3,
  },
}
