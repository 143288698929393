<template>
  <VDialog
    class="patient-profile-modal"
    :value="isVisible"
    fullscreen
    persistent
    hide-overlay
    transition="fade-transition"
  >
    <ModalLayout
      ref="modalLayout"
      close-to
      limit-title
      :title="`Пациент ${patientData.title}`"
      :has-fixed-btn="patientData.isLoaded"
      @modal:back="handleCloseModal"
    >
      <PatientProfileModalContent
        :patient-data="patientData"
        @modal:close="handleCloseModal"
      />
    </ModalLayout>
  </VDialog>
</template>

<script>
import { VDialog } from 'vuetify/lib'
import ModalLayout from 'components/mobile/core/ModalLayout'
import PatientProfileModalContent from 'components/common/PatientProfile/components/common/PatientProfileModalContent'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

export default {
  name: 'PatientProfileModal',
  components: {
    VDialog,
    ModalLayout,
    PatientProfileModalContent,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  watch: {
    isVisible(isVisible) {
      if (this.$refs.modalLayout?.$el) {
        this.toggleBodyScrollLock(this.$refs.modalLayout.$el, isVisible)
        return
      }

      this.$nextTick(() => {
        if (this.$refs.modalLayout?.$el) {
          this.toggleBodyScrollLock(this.$refs.modalLayout.$el, isVisible)
        }
      })
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('modal:close')
    },
    toggleBodyScrollLock(targetElement, isDisable) {
      const modalBody = targetElement.querySelector('.modal__body')

      if (isDisable) {
        disableBodyScroll(modalBody)
      } else {
        enableBodyScroll(modalBody)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.patient-profile-modal {
  // z-index необходим для того, чтобы vue-модалка 'профиль пациента',
  // которая монтируется в первый попавшийся VApp, перекрывала модалку с отзывами
  z-index: 1005;
}
</style>
