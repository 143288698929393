var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.MainComponent,
    {
      tag: "component",
      attrs: { width: _setup.isMobile ? undefined : 360 },
      model: {
        value: _setup.isVisible,
        callback: function ($$v) {
          _setup.isVisible = $$v
        },
        expression: "isVisible",
      },
    },
    [
      _c("VCard", { staticClass: "ui-kit-color-text" }, [
        !_setup.isMobile
          ? _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "d-flex px-6 py-4" },
                  [
                    _c(
                      "VIcon",
                      {
                        staticClass: "mr-6",
                        attrs: { color: "uiKitIconSecondary", size: "24" },
                        on: {
                          click: function ($event) {
                            _setup.isVisible = false
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          ui-icon-close-not-a-circle\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ui-text ui-text_h6 ui-kit-color-text" },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_setup.title) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("VDivider"),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "px-4", class: [_setup.isMobile ? "py-6" : "py-4"] },
          [
            _setup.isMobile
              ? _c(
                  "div",
                  { staticClass: "d-flex align-center mb-2" },
                  [
                    _c(
                      "VIcon",
                      { attrs: { color: "uiKitSuccess", size: "24" } },
                      [
                        _vm._v(
                          "\n          ui-icon-shield-checkmark-40\n        "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_h6 ui-kit-color-text-success ml-4",
                      },
                      [
                        _vm._v(
                          "\n          " + _vm._s(_setup.title) + "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_body-1 ui-kit-color-text-secondary mb-2",
              },
              [
                _setup.isTypeLicense
                  ? [
                      _vm._v(
                        "\n          Лицензия клиники в реестре Росздравнадзора актуальна:\n          "
                      ),
                      _c("br"),
                      _vm._v(
                        _vm._s(_setup.documents.licenseNumber) + "\n        "
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n          Специалист предоставил документы, а мы проверили их достоверность\n        "
                      ),
                    ],
              ],
              2
            ),
            _vm._v(" "),
            _setup.documents.isEducationConfirmed
              ? _c(
                  "div",
                  { staticClass: "d-flex py-3" },
                  [
                    _c(
                      "VIcon",
                      { attrs: { color: "uiKitTextSuccess", size: "24" } },
                      [_vm._v("\n          ui-icon-check-circle\n        ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ui-text ui-text_body-1 ml-4" }, [
                      _vm._v(
                        "\n          Диплом о базовом образовании\n        "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _setup.documents.isCategoryConfirmed
              ? _c(
                  "div",
                  { staticClass: "d-flex py-2" },
                  [
                    _setup.documents.isCategoryValid &&
                    !_setup.documents.isCategoryExpiringSoon
                      ? _c(
                          "VIcon",
                          { attrs: { color: "uiKitTextSuccess", size: "24" } },
                          [_vm._v("\n          ui-icon-check-circle\n        ")]
                        )
                      : _c(
                          "VIcon",
                          { attrs: { color: "uiKitTextError", size: "24" } },
                          [
                            _vm._v(
                              "\n          ui-icon-circle-warning\n        "
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ml-4" }, [
                      _c("div", { staticClass: "ui-text ui-text_body-1" }, [
                        _vm._v(
                          "\n            Удостоверение о категории\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      !_setup.documents.isCategoryValid
                        ? _c(
                            "div",
                            {
                              staticClass:
                                "ui-text ui-text_body-2 ui-kit-color-text-error",
                            },
                            [
                              _vm._v(
                                "\n            Документ уже недействителен\n          "
                              ),
                            ]
                          )
                        : _setup.documents.categoryExpiresDate
                        ? _c(
                            "div",
                            {
                              staticClass: "ui-text ui-text_body-2",
                              class: _setup.documents.isCategoryExpiringSoon
                                ? "ui-kit-color-text-error"
                                : "ui-kit-color-text-secondary",
                            },
                            [
                              _vm._v(
                                "\n            Действительно до " +
                                  _vm._s(_setup.categoryExpiresDateFormatted) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _setup.documents.isScienceConfirmed
              ? _c(
                  "div",
                  { staticClass: "d-flex py-3" },
                  [
                    _c(
                      "VIcon",
                      { attrs: { color: "uiKitTextSuccess", size: "24" } },
                      [_vm._v("\n          ui-icon-check-circle\n        ")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "ui-text ui-text_body-1 ml-4" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_setup.scienceText) +
                          "\n        "
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "VBtn",
              {
                staticClass: "ui-kit-color-primary mt-3",
                attrs: { color: "uiKitBgPrimary", depressed: "", block: "" },
                on: { click: _setup.handleClickButton },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _setup.documents.linkToDocuments
                        ? "Посмотреть документы"
                        : "Хорошо"
                    ) +
                    "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }