import { createDevNotice } from 'utils'

/**
 * Скроллит к текущему блоку контактов клиники и возвращает его, если есть он и слоты для этой клиники.
 * @description
 * При переходе с МТ по клику на "Записаться повторно" надо, чтобы нас проскроллило до клиники,
 * в которой была предыдущая запись, если для нее доступны слоты.
 * Если доступных слотов нет, то скроллим до начала списка контактов всех клиник.
 * @returns {Element|void}
 * */
export function reAppointmentScroll() {
  const reAppointmentLpuId = window.location.hash.match(/^#(?<id>\d+)$/)?.groups.id

  if (!reAppointmentLpuId) { // проверяем, передан ли id клиники в хэше; если в хэше есть "rate-/d+", то это отзыв, его не обрабатываем
    return
  }

  const wpBlock = document.querySelector(`[data-wp-block-id="${reAppointmentLpuId}"]`)
  const isAvailableDirectAppointment = wpBlock?.dataset.availableDirectAppointment

  if (wpBlock && isAvailableDirectAppointment) {
    return wpBlock
  }

  window.location.hash = 'doctor-contacts'
}

/**
 * @description раскрывает блок с подробной инфой о клинике, если он не первый
 * @param { Element } wpBlock - контейнер, содержащий инфу о клинике для записи
 * */
export function expandWpBlock(wpBlock) {
  const lpuNodes = [...document.querySelectorAll('.b-doctor-contacts__lpu')]
  const lpuNodeSelected = wpBlock.querySelector('.b-doctor-contacts__lpu')

  if (!lpuNodeSelected) {
    createDevNotice({
      module: 'b-doctor-contacts/functions',
      method: 'expandWpBlock',
      description: 'lpuNodeSelected не найден',
    })

    return
  }

  // первый по дефолту развернут
  if (lpuNodeSelected !== lpuNodes[0]) {
    lpuNodeSelected.dispatchEvent(new Event('click'))
  }
}
