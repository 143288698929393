import { defineStore } from 'pinia'
import { format, isEqual, parse } from 'date-fns'
import { ru } from 'date-fns/locale'

import {
  createDevNotice,
  deleteQueryParam,
  getFormattedPrice,
  isNetworkDisconnect,
} from 'utils'
import { APPOINTMENT_YANDEX_GOALS } from 'yandexGoals'
import {
  getDoctorEquipmentRelationsData,
  getEquipmentsData,
  getSelectedEquipment,
  getServiceData,
} from 'components/common/AppointmentPage/interfaces'
import { getDoctorEquipmentRelations } from 'components/common/AppointmentPage/api'
import {
  getIdFromQuery,
  setQueryDoctorEquipment,
  deleteQueryDoctorEquipment,
} from 'components/common/AppointmentPage/functions'
import { APPOINTMENT_ERRORS } from 'components/common/AppointmentPage/constants'
import useLpuDataStore from 'components/common/AppointmentPage/stores/useLpuDataStore'
import useGlobalDataStore from 'components/common/AppointmentPage/stores/useGlobalDataStore'
// eslint-disable-next-line import/no-cycle
import useDoctorDataStore from 'components/common/AppointmentPage/stores/useDoctorDataStore'
import useYandexGoalsStore from 'components/common/AppointmentPage/stores/useYandexGoalsStore'
// eslint-disable-next-line import/no-cycle
import useCalendarDataStore from 'components/common/AppointmentPage/stores/useCalendarDataStore'
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'

const useServiceDataStore = defineStore('serviceDataStore', {
  state: () => ({
    serviceData: getServiceData(),
    equipmentsData: getEquipmentsData(),
    selectedEquipment: getSelectedEquipment(),
    doctorEquipmentRelations: getDoctorEquipmentRelationsData(),
    isLoadingRelations: true,
    countRequestsDoctorEquipmentRelations: 0,
  }),
  getters: {
    serviceDataFormattedPrice() {
      return getFormattedPrice(this.serviceData.price)
    },
    serviceDataFormattedPriceWithDiscount() {
      return getFormattedPrice(this.serviceData.priceWithDiscount)
    },
    selectedEquipmentDescription() {
      return this.selectedEquipment.isOpen !== null
        ? `Томограф ${this.selectedEquipment.isOpen ? 'открытого' : 'закрытого'} типа`
        : ''
    },
    selectedEquipmentPatientWeightDescription() {
      return this.selectedEquipment.isIncreasedWeight !== null
        ? `Вес пациента: ${this.selectedEquipment.isIncreasedWeight ? '120+ кг' : 'до 120 кг'}`
        : ''
    },
    selectedEquipmentNumberOfSlicesDescription() {
      return this.selectedEquipment.numberOfSlices ? `Количество срезов: ${this.selectedEquipment.numberOfSlices}` : ''
    },
    isSelectedDoctorEquipmentRelation() {
      const doctorDataStore = useDoctorDataStore()

      return Boolean(
        this.selectedEquipment.nearestAppointmentDateString
        || doctorDataStore.selectedDoctor.nearestAppointmentDateString,
      )
    },
  },
  actions: {
    updateSelectedEquipment(payload) {
      this.selectedEquipment = payload
    },
    resetSelectedEquipment() {
      this.selectedEquipment = getSelectedEquipment()
    },
    updateDoctorEquipmentRelations(payload) {
      this.doctorEquipmentRelations = payload
    },
    resetDoctorEquipmentRelations() {
      this.doctorEquipmentRelations = getDoctorEquipmentRelationsData()
    },
    updateIsLoadingRelations(payload) {
      this.isLoadingRelations = payload
    },
    incCountRequestsDoctorEquipmentRelations() {
      this.countRequestsDoctorEquipmentRelations++
    },

    sendYaGoalsInFirstLoading() {
      if (!this.countRequestsDoctorEquipmentRelations) {
        return
      }

      const sendYaGoalByShowTargetCard = ({ targetIdName, targetsData, goalName }) => {
        const ids = this.doctorEquipmentRelations.map(relation => relation[targetIdName])
        const uniqueIds = new Set(ids)

        if (uniqueIds.size < 1) {
          return
        }

        /**
         * Если уникальных id всего 1, проверяем, известное ли это id врача/оборудования.
         * Т.е. проверяем, содержится ли это id в списке данных о врачах/оборудований.
         */
        if (uniqueIds.size === 1) {
          const hasKnownTargetData = targetsData.some(({ id }) => uniqueIds.has(id))

          /**
           * Если id неизвестно, то соответствующая карточка врача/оборудования не будет выводится в форме.
           * Поэтому соответствующую цель не отправляем.
           * */
          if (!hasKnownTargetData) {
            return
          }
        }

        const yandexGoalsStore = useYandexGoalsStore()

        yandexGoalsStore.sendYaGoal({
          name: goalName,
          formType: 'direct',
          objectType: 'service',
        })
      }

      const doctorDataStore = useDoctorDataStore()

      sendYaGoalByShowTargetCard({
        targetIdName: 'doctorId',
        targetsData: doctorDataStore.doctorsData,
        goalName: APPOINTMENT_YANDEX_GOALS.serviceOpenFormWithDoctor,
      })
      sendYaGoalByShowTargetCard({
        targetIdName: 'equipmentId',
        targetsData: this.equipmentsData,
        goalName: APPOINTMENT_YANDEX_GOALS.serviceOpenFormWithEquipment,
      })
    },
    async requestDoctorEquipmentRelations({ day, time } = {}) {
      const lpuDataStore = useLpuDataStore()
      const globalDataStore = useGlobalDataStore()
      const doctorDataStore = useDoctorDataStore()
      const calendarDataStore = useCalendarDataStore()
      const appointmentDataStore = useAppointmentDataStore()

      this.updateIsLoadingRelations(true)

      const showErrorAppointmentNotAvailable = globalDataStore.updateErrorData.bind(null, {
        isDialogRender: true,
        isEnabled: true,
        isPersistent: true,
        ...APPOINTMENT_ERRORS.appointmentNotAvailable,
      })

      if (!day || !time) {
        this.updateIsLoadingRelations(false)
        return
      }

      try {
        const appointmentDate = `${day} ${time}`

        let { relations } = await getDoctorEquipmentRelations({
          lpuId: lpuDataStore.lpuData.id,
          synonymId: calendarDataStore.initCalendarData.synonymId,
          appointmentDate,
        })

        /**
         * Необходимо отфильтровать тех врачей и оборудований, что отсутствуют в контексте,
         * т.к. иначе по ним будет выводиться заглушка.
         * А заглушка должна выводиться только раз для врача/оборудования у которых id = null.
         * id = null означает, что врача/оборудование назначит клиника.
         * */
        const doctorsIds = relations.map(relation => relation.doctor_id)
        const equipmentsIds = relations.map(relation => relation.device_id)
        const getKnownTargetsIds = (ids, data) => ids.filter(
          targetId => (targetId === null || data.some(({ id }) => id === targetId)),
        )
        const knownDoctorsIds = getKnownTargetsIds(doctorsIds, doctorDataStore.doctorsData)
        const knownEquipmentsIds = getKnownTargetsIds(equipmentsIds, this.equipmentsData)

        relations = relations.filter(relation => (
          knownDoctorsIds.includes(relation.doctor_id) && knownEquipmentsIds.includes(relation.device_id)
        ))

        if (!relations.length) {
          this.resetDoctorEquipmentRelations()
          this.resetSelectedEquipment()
          doctorDataStore.resetSelectedDoctor()
          this.incCountRequestsDoctorEquipmentRelations()
          this.updateIsLoadingRelations(false)

          if (this.countRequestsDoctorEquipmentRelations === 1) {
            calendarDataStore.requestForSlots({
              errorRequestCallback: () => {
                if (!appointmentDataStore.useServiceExpressWithoutRelations) {
                  showErrorAppointmentNotAvailable()
                }
              },
            })
          } else {
            showErrorAppointmentNotAvailable()
          }

          return
        }

        const now = new Date(calendarDataStore.initCalendarData.dateNow)
        const doctorEquipmentRelations = relations.map(relation => {
          const nearestAppointmentDate = parse(relation.nearest_dt, 'yyyy-MM-dd HH:mm', now, { locale: ru })
          const formattedNearestAppointmentDate = format(
            nearestAppointmentDate,
            'd\u00A0MMMM,\u00A0HH:mm',
            { locale: ru },
          )

          return {
            doctorId: relation.doctor_id,
            equipmentId: relation.device_id,
            nearestAppointmentDate,
            nearestAppointmentDateString: relation.nearest_dt,
            formattedNearestAppointmentDate,
          }
        })

        this.updateDoctorEquipmentRelations(doctorEquipmentRelations)
        this.sendYaGoalsInFirstLoading()

        // Если связка врач-оборудование не предвыбрана (например, только зашли в форму)
        if (!this.isSelectedDoctorEquipmentRelation) {
          let initRelation = null
          const hasTargetDoctorId = getIdFromQuery('targetDoctorId') !== undefined

          initRelation = await this.getInitRelation()

          /**
           * Если по указанным get-параметрам не нашлась связка и не было запросов за слотами,
           * то отображается общее расписание.
           *
           * Если связка ищется по целевому врачу, и врач не был найден в связке,
           * то выводится ошибка.
           */
          if (!initRelation && calendarDataStore.isZeroRequests) {
            deleteQueryDoctorEquipment()

            if (hasTargetDoctorId) {
              showErrorAppointmentNotAvailable()

              return
            }

            calendarDataStore.resetSelectedDate()
            calendarDataStore.updateCalendarIsChanging(true)
            calendarDataStore.requestForSlots({
              errorRequestCallback: () => {
                if (!appointmentDataStore.useServiceExpressWithoutRelations) {
                  showErrorAppointmentNotAvailable()
                }
              },
            })

            return
          }

          // Если уже был запрос за слотами и не нашлась связка, то берётся максимально подходящая
          if (!initRelation) {
            deleteQueryDoctorEquipment()

            // Если поиск производился по целевому врачу, то выдаём ошибку
            if (hasTargetDoctorId) {
              showErrorAppointmentNotAvailable()

              return
            }

            initRelation = await this.getInitRelation()
          }

          const {
            doctorId,
            equipmentId,
            nearestAppointmentDate,
            nearestAppointmentDateString,
            formattedNearestAppointmentDate,
          } = initRelation

          const findById = (data, searchId) => data.find(({ id }) => id === searchId) || {}
          const selectedDoctor = findById(doctorDataStore.doctorsData, doctorId)
          const selectedEquipment = findById(this.equipmentsData, equipmentId)

          doctorDataStore.updateSelectedDoctor({
            id: doctorId,
            ...selectedDoctor,
            relatedPairId: equipmentId,
            nearestAppointmentDate,
            nearestAppointmentDateString,
            formattedNearestAppointmentDate,
          })
          this.updateSelectedEquipment({
            id: equipmentId,
            ...selectedEquipment,
            relatedPairId: doctorId,
            nearestAppointmentDate,
            nearestAppointmentDateString,
            formattedNearestAppointmentDate,
          })

          setQueryDoctorEquipment(doctorId, equipmentId)
        }

        const selectedRelation = doctorEquipmentRelations.find(({ doctorId, equipmentId }) => (
          doctorId === doctorDataStore.selectedDoctor.id && equipmentId === this.selectedEquipment.id
        ))

        if (!selectedRelation) {
          showErrorAppointmentNotAvailable()
          this.incCountRequestsDoctorEquipmentRelations()

          deleteQueryDoctorEquipment()

          return
        }

        const {
          nearestAppointmentDate,
          nearestAppointmentDateString,
          formattedNearestAppointmentDate,
        } = selectedRelation

        doctorDataStore.updateSelectedDoctor({
          ...doctorDataStore.selectedDoctor,
          nearestAppointmentDate,
          nearestAppointmentDateString,
          formattedNearestAppointmentDate,
        })
        this.updateSelectedEquipment({
          ...this.selectedEquipment,
          nearestAppointmentDate,
          nearestAppointmentDateString,
          formattedNearestAppointmentDate,
        })

        deleteQueryParam('targetDoctorId')
        setQueryDoctorEquipment(doctorDataStore.selectedDoctor.id, this.selectedEquipment.id)

        if (nearestAppointmentDateString !== appointmentDate) {
          calendarDataStore.resetSelectedDate()
          calendarDataStore.updateCalendarIsChanging(true)
          calendarDataStore.requestForSlots({
            errorRequestCallback: () => {
              if (!appointmentDataStore.useServiceExpressWithoutRelations) {
                showErrorAppointmentNotAvailable()
                deleteQueryDoctorEquipment()
              }
            },
          })
        } else {
          calendarDataStore.updateSelectedDate(calendarDataStore.lastSelectedDate)
          calendarDataStore.updateCalendarIsChanging(false)
          calendarDataStore.updateIsErrorState(false)
        }
      } catch (error) {
        const { response, message } = error || {}
        const { status } = response || {}

        if (isNetworkDisconnect({ status, message })) {
          globalDataStore.updateErrorData({
            isEnabled: true,
            isDialogRender: true,
            ...APPOINTMENT_ERRORS.notNetwork,
          })
        } else if (!response && message) {
          createDevNotice({
            module: 'useServiceDataStore',
            method: 'requestDoctorEquipmentRelations',
            description: message,
          })
        }

        if (status === 403) {
          globalDataStore.updateErrorData({
            isEnabled: true,
            isDialogRender: true,
            isPersistent: true,
            ...APPOINTMENT_ERRORS.notAuthorization,
          })
          return
        }
      }

      this.incCountRequestsDoctorEquipmentRelations()
      this.updateIsLoadingRelations(false)
    },
    /**
     * Возвращает связку врач/оборудование при загрузке страницы
     * */
    async getInitRelation() {
      const hasTargetDoctorId = getIdFromQuery('targetDoctorId') !== undefined
      const calendarDataStore = useCalendarDataStore()
      const initDoctorId = hasTargetDoctorId
        ? getIdFromQuery('targetDoctorId')
        : getIdFromQuery('doctorId')
      const initEquipmentId = getIdFromQuery('equipmentId')
      const isExistIdInQuery = initDoctorId !== undefined
        || initEquipmentId !== undefined

      // Если присутствуют get-параметры выбранного врача или оборудования,
      // то возвращаем связку по точному совпадению, или undefined если не нашли её
      if (isExistIdInQuery) {
        let findFunction = () => {}

        if (hasTargetDoctorId && initEquipmentId === undefined) {
          findFunction = ({ nearestAppointmentDate, doctorId }) => (
            isEqual(nearestAppointmentDate, calendarDataStore.selectedSlotDate)
            && doctorId === initDoctorId
          )
        } else {
          findFunction = ({ nearestAppointmentDate, doctorId, equipmentId }) => (
            isEqual(nearestAppointmentDate, calendarDataStore.selectedSlotDate)
            && doctorId === initDoctorId
            && equipmentId === initEquipmentId
          )
        }

        return this.doctorEquipmentRelations.find(findFunction)
      }

      const [firstRelation] = this.doctorEquipmentRelations
      const relationsFilteredBySlot = this.doctorEquipmentRelations.filter(
        relation => firstRelation.nearestAppointmentDateString === relation.nearestAppointmentDateString,
      )

      const relationMaxInfo = relationsFilteredBySlot.find(relation => relation.doctorId && relation.equipmentId)
      const relationMinInfo = relationsFilteredBySlot.find(relation => relation.doctorId || relation.equipmentId)

      // Возвращаем связку в которой присутствует врач и оборудование,
      // иначе возвращаем связку в которой присутствует или врач или оборудование,
      // иначе возвращаем первую связку по умолчанию
      return relationMaxInfo || relationMinInfo || firstRelation
    },
  },
})

export default useServiceDataStore
