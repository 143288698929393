import 'intersection-observer' // polyfill
import { createDevNotice, getBooleanFromString } from 'utils'
import {
  BLOCK_NAME,
  VIEWED_IDS,
  CLASS_NAMES,
  TIME_IN_VIEWPORT_MS,
  INTERSECTION_OBSERVER_OPTIONS,
} from 'www/doctors.blocks/common/b-review-card/constants'

function showError(description) {
  createDevNotice({
    module: 'intersectionObserverReviews.js',
    description,
  })
}

function createObserver() {
  const timeouts = {} // позволяет определить что отзыв был виден какое-то время
  const observerCallback = (entries, observer) => {
    entries.forEach(entry => {
      const reviewCardBody = entry.target
      const reviewCard = reviewCardBody.closest(`.${BLOCK_NAME}`)
      const reviewId = Number(reviewCard.dataset.reviewId)
      const { reviewType } = reviewCard.dataset

      if (!reviewId || !reviewType) {
        showError('Отсутствует идентификатор отзыва или его тип')
        return
      }

      // Если отзыв уже просмотрен, ничего не делаем
      if (VIEWED_IDS[reviewType]?.has(reviewId)) {
        observer.unobserve(reviewCardBody)
        return
      }

      const timeoutId = `${reviewType}-${reviewId}`

      // Если isIntersecting - true, значит отзыв появился в области видимости, иначе исчез
      if (entry.isIntersecting) {
        timeouts[timeoutId] = setTimeout(() => {
          if (VIEWED_IDS[reviewType]) {
            VIEWED_IDS[reviewType].add(reviewId)
          }

          observer.unobserve(reviewCardBody)
        }, TIME_IN_VIEWPORT_MS)
      } else {
        clearTimeout(timeouts[timeoutId])
      }
    })
  }

  return new IntersectionObserver(observerCallback, INTERSECTION_OBSERVER_OPTIONS)
}

let instanceObserver

/*
* Добавляет слежение за появлением карточек отзывов в области просмотра (viewport).
* Если они отображались во viewport `TIME_IN_VIEWPORT_MS` миллисекунд - сохраняются идентификаторы
* отзывов для отправки на сервер (на сервере будет производиться подсчитывание просмотров каждого отзыва)
* */
function intersectionObserverReviews(reviewsContainer) {
  if (!instanceObserver) {
    instanceObserver = createObserver()
  }

  if (!instanceObserver) {
    return
  }

  const reviewCards = [...reviewsContainer.querySelectorAll(`.${BLOCK_NAME}`)]

  /* filteredReviewCards - содержит отзывы которые ещё не просмотрены и не удалены */
  const filteredReviewCards = reviewCards.filter(reviewCard => {
    const { reviewId, reviewType, reviewIsDeleted } = reviewCard.dataset
    const isViewed = VIEWED_IDS[reviewType]?.has(Number(reviewId))
    const isDeleted = getBooleanFromString(reviewIsDeleted)

    return !isViewed && !isDeleted
  })

  filteredReviewCards.forEach(card => {
    instanceObserver.observe(card.querySelector(`.${CLASS_NAMES.body}`))
  })
}

export default intersectionObserverReviews
