var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "doctor-service-prices-price-list",
      attrs: { "data-qa": "doctor_service_prices_price_list" },
    },
    _vm._l(
      _vm.categoryList,
      function ({ name: categoryName, priceList }, categoryIndex) {
        return _c(
          "div",
          {
            key: categoryIndex,
            class: {
              "mb-8":
                _setup.isMobile && categoryIndex < _vm.categoryList.length - 1,
              "mb-12":
                !_setup.isMobile && categoryIndex < _vm.categoryList.length - 1,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-6",
                attrs: { "data-qa": "doctor_service_prices_category" },
              },
              [_vm._v("\n      " + _vm._s(categoryName) + "\n    ")]
            ),
            _vm._v(" "),
            _vm._l(priceList, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "doctor-service-prices-price-list__item",
                  class: {
                    "d-flex": !_setup.isMobile,
                    "mb-4": _setup.isMobile && index < priceList.length - 1,
                    "mb-6": !_setup.isMobile && index < priceList.length - 1,
                    "flex-wrap": _setup.isEnabledFFOnlineAppointmentDoctorsPage,
                  },
                  attrs: { "data-qa": "doctor_service_prices_price_item" },
                },
                [
                  _setup.isMobile
                    ? [
                        _c("div", { staticClass: "mb-3" }, [
                          _c("div", {
                            staticClass:
                              "ui-text ui-text_body-1 uiKitText--text",
                            attrs: {
                              "data-qa":
                                "doctor_service_prices_price_item_name",
                            },
                            domProps: { textContent: _vm._s(item.name) },
                          }),
                          _vm._v(" "),
                          item.agesText
                            ? _c("div", {
                                staticClass:
                                  "ui-text ui-text_body-2 uiKitTextSecondary--text",
                                attrs: {
                                  "data-qa":
                                    "doctor_service_prices_price_item_ages_text",
                                },
                                domProps: {
                                  textContent: _vm._s(item.agesText),
                                },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "ui-text ui-text_subtitle-1 uiKitText--text",
                              attrs: {
                                "data-qa":
                                  "doctor_service_prices_price_item_price",
                              },
                              domProps: {
                                textContent: _vm._s(item.formattedPrice),
                              },
                            }),
                            _vm._v(" "),
                            _setup.isEnabledFFOnlineAppointmentDoctorsPage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    style: {
                                      width: "180px",
                                    },
                                  },
                                  [
                                    item.hasSlots &&
                                    item.isEnabledServiceDirect &&
                                    !item.isEnabledCalendar
                                      ? _c(
                                          "VBtn",
                                          {
                                            attrs: {
                                              block: "",
                                              depressed: "",
                                              color: "primary",
                                              "data-qa":
                                                "doctor_service_prices_price_item_show_calendar",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _setup.emitShowCalendar({
                                                  synonymId: item.synonymId,
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "ui-icon-calendar-date-and-clock ui-kit-color-bg-gray-0",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ui-text ui-text_subtitle-1 ui-kit-color-bg-gray-0",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Запись онлайн\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.isEnabledCalendar
                                      ? _c(
                                          "VBtn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              width: "56",
                                              height: "40",
                                              "min-width": "56",
                                              color: "ui-kit-bg-bg-gray-40",
                                              "data-qa":
                                                "doctor_service_prices_price_item_hide_calendar",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _setup.emit(
                                                  "click:hide-calendar"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 h-100 d-flex align-items-center justify-content-center",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "b-lpu-services__toggle-calendar-icon ui-icon-arrow-up ui-kit-color-icon-secondary",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isEnabledCalendar,
                              expression: "item.isEnabledCalendar",
                            },
                          ],
                          staticClass: "doctor-price-calendar-wrapper mt-4",
                          attrs: {
                            id: `doctor-price-calendar-wrapper-${item.synonymId}`,
                            "data-qa": "doctor_service_prices_calendar_wrapper",
                          },
                        }),
                        _vm._v(" "),
                        index < priceList.length - 1
                          ? _c("VDivider", { staticClass: "mt-4" })
                          : _vm._e(),
                      ]
                    : [
                        _c(
                          "div",
                          {
                            class: {
                              "w-100":
                                !_setup.isEnabledFFOnlineAppointmentDoctorsPage,
                            },
                            style: {
                              width:
                                _setup.isEnabledFFOnlineAppointmentDoctorsPage
                                  ? "calc(100% - 400px)"
                                  : "",
                            },
                          },
                          [
                            _c("div", {
                              staticClass:
                                "ui-text ui-text_body-1 uiKitText--text",
                              attrs: {
                                "data-qa":
                                  "doctor_service_prices_price_item_name",
                              },
                              domProps: { textContent: _vm._s(item.name) },
                            }),
                            _vm._v(" "),
                            item.agesText
                              ? _c("div", {
                                  staticClass:
                                    "ui-text ui-text_body-2 uiKitTextSecondary--text",
                                  attrs: {
                                    "data-qa":
                                      "doctor_service_prices_price_item_ages_text",
                                  },
                                  domProps: {
                                    textContent: _vm._s(item.agesText),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex justify-content-between align-items-center",
                          },
                          [
                            _c("div", {
                              staticClass:
                                "ui-text ui-text_subtitle-1 uiKitText--text text-right",
                              class: {
                                "pl-2":
                                  !_setup.isEnabledFFOnlineAppointmentDoctorsPage,
                                "d-flex justify-end align-items-center pr-6":
                                  _setup.isEnabledFFOnlineAppointmentDoctorsPage,
                              },
                              style: {
                                width: "130px",
                              },
                              attrs: {
                                "data-qa":
                                  "doctor_service_prices_price_item_price",
                              },
                              domProps: {
                                textContent: _vm._s(item.formattedPrice),
                              },
                            }),
                            _vm._v(" "),
                            _setup.isEnabledFFOnlineAppointmentDoctorsPage
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "d-flex justify-content-end",
                                    style: {
                                      width: "270px",
                                    },
                                  },
                                  [
                                    item.hasSlots &&
                                    item.isEnabledServiceDirect &&
                                    !item.isEnabledCalendar
                                      ? _c(
                                          "VBtn",
                                          {
                                            attrs: {
                                              block: "",
                                              depressed: "",
                                              color: "primary",
                                              "data-qa":
                                                "doctor_service_prices_price_item_show_calendar",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _setup.emitShowCalendar({
                                                  synonymId: item.synonymId,
                                                })
                                              },
                                            },
                                          },
                                          [
                                            _c("span", {
                                              staticClass:
                                                "ui-icon-calendar-date-and-clock ui-kit-color-bg-gray-0",
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "ui-text ui-text_subtitle-1 ui-kit-color-bg-gray-0",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Запись онлайн\n              "
                                                ),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.isEnabledCalendar
                                      ? _c(
                                          "VBtn",
                                          {
                                            attrs: {
                                              depressed: "",
                                              width: "56",
                                              height: "40",
                                              "min-width": "56",
                                              color: "ui-kit-bg-bg-gray-40",
                                              "data-qa":
                                                "doctor_service_prices_price_item_hide_calendar",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _setup.emit(
                                                  "click:hide-calendar"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-100 h-100 d-flex align-items-center justify-content-center",
                                              },
                                              [
                                                _c("span", {
                                                  staticClass:
                                                    "b-lpu-services__toggle-calendar-icon ui-icon-arrow-up ui-kit-color-icon-secondary",
                                                }),
                                              ]
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: item.isEnabledCalendar,
                              expression: "item.isEnabledCalendar",
                            },
                          ],
                          staticClass: "doctor-price-calendar-wrapper mt-4",
                          attrs: {
                            id: `doctor-price-calendar-wrapper-${item.synonymId}`,
                            "data-qa": "doctor_service_prices_calendar_wrapper",
                          },
                        }),
                      ],
                ],
                2
              )
            }),
          ],
          2
        )
      }
    ),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }