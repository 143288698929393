<template>
  <VApp id="award-banner-app">
    <div
      v-if="isVisibleBanner"
      class="award-banner"
      :class="{ 'award-banner_dark': isDarkMode }"
      @click="handleShowMore"
    >
      <div
        class="d-flex align-items-center"
        :class="isMobile ? 'px-2 pt-1 mb-2' : 'mr-8'"
      >
        <img
          alt="Золотая звезда"
          height="32"
          width="32"
          class="flex-grow-0"
          :class="isMobile ? 'mr-6' : 'mr-8'"
          :src="starIcon"
        >
        <div class="ui-text ui-text_body-1">
          {{ bannerData.title }}
        </div>
      </div>
      <div
        class="ml-auto"
        :class="{ 'mr-2': !isMobile }"
      >
        <VBtn
          text
          class="px-2 mr-2"
          :dark="isDarkMode"
          @click.stop="handleClose"
        >
          Скрыть
        </VBtn>
        <VBtn
          text
          class="px-2"
          :dark="isDarkMode"
          :color="isDarkMode ? 'gold' : 'primary'"
          @click.stop="handleShowMore"
        >
          Подробнее
        </VBtn>
      </div>
    </div>
  </VApp>
</template>

<script>
import Cookie from 'js-cookie'
import AwardCommonData from 'components/common/Award/mixins/AwardCommonData'
import { TIME_DEATH_DAY } from 'components/common/Award/constants'

export default {
  name: 'AwardBanner',
  mixins: [
    AwardCommonData,
  ],
  props: {
    bannerData: {
      type: Object,
      default: () => ({
        title: '',
      }),
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
    isVisibleBanner: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    starIcon() {
      return this.isDarkMode
        ? '/static/_v1/pd/icons/stars/star-gold-outlined.svg'
        : '/static/_v1/pd/icons/stars/star-gold-filled.svg'
    },
  },
  methods: {
    handleClose() {
      this.$emit('award-banner:close')
      this.setCookie()
    },
    handleShowMore() {
      this.$emit('award-banner:show-more')
    },
    setCookie() {
      Cookie.set('award_banner', 'hidden', {
        expires: TIME_DEATH_DAY,
        path: '/',
      })
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/mixins';

#award-banner-app {
  @include v-app-reset;
}

.award-banner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: $border-radius-md;
  border: 1px solid $new-color-gold;
  background-color: $ui-kit-bg-gray-0;
  color: $ui-kit-text;
  padding: 12px 8px;
  cursor: pointer;

  &_dark {
    border-color: $ui-kit-text;
    background-color: $ui-kit-text;
    color: $ui-kit-bg-gray-0;
  }
}
</style>
