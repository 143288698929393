import SelectModalProps from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalProps'
import SelectModalEventNames from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalEventNames'
import SelectModalOptionKeys from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalOptionKeys'

export default {
  mixins: [
    SelectModalProps,
    SelectModalEventNames,
    SelectModalOptionKeys,
  ],
  data() {
    return {
      selectValue: this.selectedOption || this.options[0] || {},
    }
  },
  computed: {
    hasPrependIcon() {
      return this.hasIcon(this.prependIcon)
    },
    hasAppendIcon() {
      return this.hasIcon(this.appendIcon)
    },
  },
  methods: {
    hasIcon(icon) {
      return (typeof icon === 'string' && icon.length)
                || this.isFunction(icon)
    },
    isFunction(fn) {
      return typeof fn === 'function'
    },
    handleClickOption({ option, target }) {
      this.selectValue = option

      this.handleCloseModal()

      this.$emit(this.eventClickOption, { option, target })
    },
    handleClickSelect() {
      this.$emit(this.eventClickSelect)
    },
    handleCloseModal() {
      this.$emit(this.eventClickModalClose)
    },
  },
}
