import { createDevNotice, isUsedLocalStorage, testRequiredOptions } from 'utils'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { STATE, ACTIONS, MODIFIER } from './constants'
import getNamespace, { getTransitionDurationMs } from './functions'
import Router from './Router'
import './index.scss'

/**
 * @author Быков Александр(@bykov)
 * */

class Modal {
  constructor(options = {}) {
    this._createOptions(options)

    testRequiredOptions({
      mode: 'throw',
      module: 'Modal',
      requiredOptions: {
        id: this.options.id,
      },
    })

    this._createBaseProps()

    // TODO удалить спустя месяц после написания - пока пусть очистится хранилище пользователя от ненужных данных
    if (this.usedStorage) {
      window.localStorage.removeItem('ModalRouter')
      window.localStorage.removeItem('ModalStorage')
    }

    this._createCoverTemplate()
    this._findNodes()
    this._switchEventListeners('add')
    this._createRouterData()
  }

  show() {
    const isActive = this._isActiveModal()

    if (isActive) {
      return
    }

    if (this.options.beforeShow && !this.isClosing) {
      this.hookBeforeShow()
      return
    }

    this.open()
  }

  open = () => {
    const isActive = this._isActiveModal()

    if (isActive) {
      return
    }

    if (this.isClosing) {
      clearTimeout(this.isClosing)
      this.isClosing = false
    }

    this.updateVisibility({ method: this.actions.add })
    this.nodes.cover.classList.add(this.attr.active)

    this.changeScroll({ method: this.state.disable })
    this._forward()
  }

  hide() {
    const isActive = this._isActiveModal()

    if (!isActive) {
      return
    }

    this.nodes.cover.classList.remove(this.attr.active)
    this.updateVisibility({ method: this.actions.remove })

    this._back()
    this.changeScroll({ method: this.state.enable })

    this.hookAfterHide()
  }

  toggle() {
    const { hide, show } = this.actions
    const isActive = this._isActiveModal()
    const method = isActive ? hide : show

    this[method]()
  }

  render = ({ template, title } = {}) => {
    this.nodes.body.innerHTML = template

    if (this.nodes.title && title) {
      this.nodes.title.innerHTML = title
    }
  }

  getHeightHeader = () => {
    const { header } = this.nodes

    return header ? header.offsetHeight : 0
  }

  changeScroll = ({ method, target } = {}) => {
    if (!this.usedStorage) {
      return
    }

    const storageData = this.storage.get()

    if (!storageData) {
      return
    }

    const { stack } = storageData
    const cover = target || this.nodes.cover
    const body = cover.querySelector(`.${this.attr.body}`)

    /**
         * Метод должен вызываться перед добавлением в стек
         * И после удаления из стека
         * Если последовательность соблюдена верно - условие ниже выполнится и скролл будет переключаться правильно
         * В случае открытия нескольких модальных окон
         * */

    if (stack.length) {
      return
    }

    if (method === this.state.enable) {
      enableBodyScroll(body)
      return
    }

    if (method === this.state.disable) {
      disableBodyScroll(body)
    }
  }

  updateVisibility({ method, target }) {
    const cover = target || this.nodes.cover
    const zIndex = cover.getAttribute(this.attr.dataZIndex)

    if (method === this.actions.add) {
      cover.style.zIndex = zIndex
    }

    if (method === this.actions.remove) {
      cover.style.zIndex = ''
    }
  }

  hookBeforeShow() {
    const { beforeShow } = this.options

    if (beforeShow && !this.isClosing) {
      beforeShow({
        attr: this.attr,
        nodes: this.nodes,
        target: this.target,
        open: this.open,
        render: this.render,
        heightHeader: this.getHeightHeader(),
      })
    }
  }

  hookAfterHide() {
    const { afterHide } = this.options

    // HACK: ждем окончания анимации с помощью setTimeout, чтобы предотвратить баги со скрытием модалки;
    //       стоит заменить на непосредственно управление анимацией или использовать другой способ.
    this.isClosing = setTimeout(() => {
      if (afterHide) {
        afterHide({
          attr: this.attr,
          nodes: this.nodes,
          render: this.render,
          target: this.target,
        })
      }

      this.isClosing = false
    }, this.transitionDuration)
  }

  /**
     * Общие методы
     * */

  _createRouterData() {
    const router = new Router({
      instance: this,
    })

    this._back = router.back
    this._forward = router.forward
    this.storage = router.storage
  }

  _createBaseProps() {
    this.target = null
    this.state = STATE // MISLEADING: означает статичное состояние, а не состояние экземпляра
    this.isClosing = false
    this.actions = ACTIONS
    this.modifier = MODIFIER
    this.usedStorage = isUsedLocalStorage()
    this.attr = getNamespace({ modifier: this.modifier })
    this.transitionDuration = 0
  }

  _isActiveModal() {
    return this.nodes.cover.classList.contains(this.attr.active)
  }

  _createOptions(options) {
    this.options = {
      id: null,
      render: '',
      useHash: false,
      afterHide: null,
      beforeShow: null,
      hideKeyEsc: true,
      catchEventShow: [],
      catchEventHide: [],
      mobileTitle: null,
      afterHeadElement: null,
      hideClickOutContainer: true,
      hasFixedBtn: false,
      animation: 'slide-in',
      zIndex: 1000,
      bodyClassList: '',
      useBackIcon: false,
      hideCloseBtn: false,
      ...options,
    }
  }

  _findNodes() {
    const cover = document.getElementById(this.options.id)

    this.nodes = {
      cover,
      container: cover.querySelector(`.${this.attr.container}`),
      body: cover.querySelector(`.${this.attr.body}`),
      header: cover.querySelector(`.${this.attr.head}`),
      title: cover.querySelector(`.${this.attr.headTitle}`),
    }

    this.transitionDuration = getTransitionDurationMs(this.nodes.cover)
  }

  _switchEventListeners(action) {
    const method = `${action}EventListener`

    document[method]('click', this._eventClick)
    document[method]('mousedown', this._eventMousedown)

    if (this.options.hideKeyEsc) {
      document[method]('keyup', this._eventKeyup)
    }
  }

  /**
     * Закрытие последнего модального окна при условии включенного localStorage
     * Иначе закрываю все
     */

  _hideLast() {
    if (!this.storage) {
      return
    }

    const storageData = this.storage.get()

    if (this.usedStorage && storageData) {
      const { stack } = storageData
      const lastId = stack[stack.length - 1]

      if (lastId === this.options.id) {
        setTimeout(() => {
          this.hide()
        })
      }

      return
    }

    this.hide()
  }

  /**
     * Слушатели событий
     * */

  _eventMousedown = event => {
    const { target } = event
    const { attr, options } = this
    const isActive = this._isActiveModal()

    /**
         * Обработка клика в пустое пространство если модалка открыта и поле имеет значение true
         * */

    if (options.hideClickOutContainer && isActive) {
      const cover = target.closest(`.${attr.cover}`)
      const container = target.closest(`.${attr.container}`)

      if (cover && !container) {
        this.target = cover
        this._hideLast()
      }
    }
  }

  _eventClick = event => {
    const { target } = event
    const { attr, options } = this
    const { catchEventShow, catchEventHide } = options
    const cross = target.closest(`.${attr.cross}`)
    const isActive = this._isActiveModal()

    /**
         * Получаю искомый массив в зависимости от состояния модалки
         * И получаю метод в зависимости от состояния модалки
         * */

    const requiredSelectors = isActive ? catchEventHide : catchEventShow
    const requiredMethod = isActive ? 'hide' : 'show'

    if (cross) {
      this.target = cross
      this._hideLast()
      return
    }

    /**
         * Такой подход нужен, чтобы одновременно можно было в разных массивах
         * CatchEventShow и catchEventHide задать одинаковый селектор
         * Но в зависимости от состояния модалки производить нужное действие
         * */

    requiredSelectors.some(selectorName => {
      const button = target.closest(selectorName)

      if (button) {
        event.preventDefault()

        this.target = button
        this[requiredMethod]()
      }

      return !!button
    })
  }

  _eventKeyup = ({ keyCode }) => {
    if (!this.usedStorage) {
      this.target = null
      this.hide()
      return
    }

    const { id, hideKeyEsc } = this.options
    const { stack } = this.storage.get() || {}

    if (!stack || !stack.length) {
      return
    }

    const lastId = stack[stack.length - 1]

    if (keyCode === 27 && hideKeyEsc && id === lastId) {
      /**
             * Задержка необходима чтобы id из массива удалялся не сразу, а после того, как пройдут обработчики всех экземпляров
             * В итоге сработает только 1 - последний в массиве
             * */

      setTimeout(() => {
        this.target = null
        this.hide()
      })
    }
  }

  /**
     * Работа с шаблонами
     * */

  _createCoverTemplate() {
    const {
      id,
      zIndex,
      animation,
    } = this.options

    const templateHead = this._getHeadTemplate()
    const templateBody = this._getBodyTemplate()

    const animationClassName = `${this.modifier}_anim_${animation}`
    const bodyClassNames = this._getBodyClassList()

    const templateRender = `<div class="${this.attr.cover} ${animationClassName}" id="${id}" ${this.attr.dataZIndex}=${zIndex}>
                                      <div class="${this.attr.container}">
                                          ${templateHead}
                                          <div class="${bodyClassNames}">${templateBody}</div>
                                      </div>
                                  </div>`

    document.body.insertAdjacentHTML('beforeend', templateRender)
  }

  _getHeadTemplate() {
    const { mobileTitle, useBackIcon, hideCloseBtn } = this.options
    const titleIcon = useBackIcon ? 'ui-icon-arrow-back' : 'ui-icon-close-not-a-circle'
    const templateAfterHead = this._getAfterHeadTemplate()

    if (!mobileTitle) {
      return ''
    }

    const closeBtnTemplate = hideCloseBtn
      ? ''
      : `<button class="${this.attr.cross} ui-text ui-kit-color-text" type="button">
                   <span class="${this.attr.crossIcon} ui-icon ${titleIcon}"></span>
               </button>`

    return `<div class="${this.attr.head}">
                    <div class="${this.attr.headInner}">
                        ${closeBtnTemplate}
                        <div class="${this.attr.headTitle} ui-text ui-kit-color-text ui-text_h6">${mobileTitle}</div>
                    </div>
                    ${templateAfterHead}
                </div>`
  }

  _getAfterHeadTemplate() {
    if (!this.options.afterHeadElement) {
      return ''
    }

    const afterHeadTemplate = `<div class="${this.attr.headAfter}">
                                       ${this.options.afterHeadElement.outerHTML}
                                   </div>`

    this.options.afterHeadElement.remove()

    return afterHeadTemplate
  }

  _getBodyTemplate() {
    const { id, render } = this.options

    if (render === 'html') {
      const container = document.querySelector(`[${this.attr.dataTemplate}="${id}"]`)

      if (container) {
        const template = container.innerHTML

        container.remove()

        return template
      }

      createDevNotice({
        module: 'Modal',
        method: '_getBodyTemplate',
        description: 'Контейнер, который содержит рендер-шаблон не найден на странице. Модальное будет пустым',
      })

      return ''
    }

    return render
  }

  _getBodyClassList() {
    let bodyClassListResult = this.attr.body

    const {
      hasFixedBtn,
      bodyClassList,
    } = this.options

    if (hasFixedBtn) {
      bodyClassListResult += ` ${this.attr.body}_has-fixed-btn`
    }

    if (bodyClassList.length) {
      bodyClassListResult += ` ${bodyClassList}`
    }

    return bodyClassListResult
  }
}

export default Modal
