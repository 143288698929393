var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.currentComponent, {
    tag: "component",
    attrs: {
      "lpu-id": _vm.lpuId,
      "is-used-club": _vm.isUsedClub,
      "class-translit": _vm.classTranslit,
      "class-id": _vm.classId,
      "category-id": _vm.categoryId,
      "type-id": _vm.typeId,
      "init-selected-synonym": _vm.initSelectedSynonym,
    },
    on: { "synonym:change": _vm.handleChangeSynonym },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }