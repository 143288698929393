export const IN_USER_FAVOURITES = {
  message: 'Добавлено в избранное',
  actionText: 'Перейти',
  handleAction: () => {
    window.location.href = `${window.MEDTOCHKA_URL}/favourites`
  },
}

export const NOT_IN_USER_FAVOURITES = {
  message: 'Удалено из избранного',
  actionText: 'Отменить',
}

export const RESTORED = {
  message: 'Восстановлено',
}

export const COPIED = {
  message: 'Ссылка скопирована',
}
