import { LPU_AD_ELEMENT_CLASS_SELECTOR } from 'www/doctors.blocks/common/b-lpu-ad/constants'
import getSelected from 'www/doctors.blocks/common/b-lpu-ad/functions/getSelected'

function redirectToAdsPage(element) {
  const lpuAdNode = element.target.closest(LPU_AD_ELEMENT_CLASS_SELECTOR)

  if (!lpuAdNode || element.target.classList.contains('lpu_remove_advertising')) {
    return
  }

  const { campaign, type } = lpuAdNode.dataset
  const selection = getSelected()

  if (selection === '' && campaign) {
    window.open(`/ad/event/c/${campaign}/?banner_type=${type}`, '_blank')
  }
}

export default redirectToAdsPage
