var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VSkeletonLoader", {
    staticClass: "search-synonym-skeleton",
    attrs: {
      type: "date-picker-options@9",
      "data-qa": "service_synonym_modal_skeleton",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }