<template>
  <VDialog
    v-model="isVisibleModal"
    content-class="popup-map"
    data-qa="popup_map_popup_container"
    @keydown.esc="closeModalWithBackHistory"
  >
    <VBtn
      :color="vuetifyColors.primary"
      icon
      width="32"
      height="32"
      class="ui-kit-bg-bg-gray-0 popup-map__close-button"
      data-qa="popup_map_close_button"
      @click="closeModalWithBackHistory"
    >
      <VIcon
        size="20"
      >
        ui-icon-close-not-a-circle
      </VIcon>
    </VBtn>
    <div
      :id="mapId"
      class="h-100 ui-kit-bg-bg-gray-0"
      data-qa="popup_map_map_container"
    />
    <PopupDepartureAddressDesktop
      :is-visible="isVisibleDepartureAddressPopup"
      :bounds-available-routes="boundsAvailableRoutes"
      :arrival-address="getArrivalAddress"
      @popup-departure-address:close="closeDepartureAddressPopup"
      @popup-departure-address:create-multi-route="createMultiRouteInstance"
    />
  </VDialog>
</template>

<script>
import PopupDepartureAddressDesktop from 'components/common/PopupMap/components/desktop/PopupDepartureAddressDesktop'
import { PopupMapLogic } from 'components/common/PopupMap/mixins'

export default {
  name: 'PopupMapDesktop',
  components: { PopupDepartureAddressDesktop },
  mixins: [PopupMapLogic],
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.popup-map {
  &__close-button {
    position: fixed;
    top: 24px;
    right: 24px;
    z-index: 2;

    @include new-elevation-1;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

// Данные стили находятся не в scoped, так как теги с этими стилями образуются внутри js
.popup-map {
  // max-height и height необходимы для того, чтобы перебить стандартную высоту vuetify для popup
  max-height: calc(100% - 80px) !important;
  height: calc(100% - 80px) !important;
  max-width: calc(100% - 80px);
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 24px 38px 3px rgba(0, 0, 0, 0.14),
    0 9px 46px 8px rgba(0, 0, 0, 0.12);
}
</style>
