<template>
  <div>
    <SynonymListSkeleton
      v-if="isLoadingSynonym"
    />
    <VList
      v-else
      class="py-2"
    >
      <VListItem
        v-for="synonym in synonymList"
        :key="`${diagnosticLevel}${synonym.name}`"
        class="synonym-list__item"
        data-qa="service_synonym_item"
        @click="handleClickItem(synonym)"
      >
        <div class="mr-2">
          <!-- eslint-disable vue/no-v-html -->
          <div
            v-if="isSearchResults"
            class="ui-text ui-text_body-1 ui-kit-color-text"
            data-qa="service_synonym_item_title_marked"
            v-html="synonym.nameWithMarkedQuery"
          />
          <!-- eslint-enable vue/no-v-html -->
          <div
            v-else
            class="ui-text ui-text_body-1 ui-kit-color-text"
            data-qa="service_synonym_item_title"
          >
            {{ synonym.name }}
          </div>
          <div
            v-if="synonym.price"
            class="ui-text ui-text_body-2 ui-kit-color-text-info"
            data-qa="service_synonym_item_price"
          >
            <template v-if="isUsedClub">
              <span
                v-if="synonym.price_with_club"
                class="ui-text ui-kit-color-error pr-1"
              >
                {{ getFormattedPrice(synonym.price_with_club) }}
              </span>
              <span :class="{ 'ui-text_line-through': synonym.price_with_club }">
                {{ getFormattedPrice(synonym.price) }}
              </span>
            </template>
            <template v-else>
              {{ getFormattedPrice(synonym.price) }}
            </template>
          </div>
        </div>
        <span class="ml-auto ui-icon-arrow-right ui-text ui-kit-color-text-info" />
      </VListItem>
    </VList>
  </div>
</template>

<script>
import SynonymListSkeleton from 'components/common/SynonymList/components/common/SynonymListSkeleton'
import CurrencyData from 'components/common/mixins/CurrencyData'

export default {
  name: 'SynonymListItems',
  components: {
    SynonymListSkeleton,
  },
  mixins: [
    CurrencyData,
  ],
  props: {
    synonymList: {
      type: Array,
      default: () => [],
    },
    diagnosticLevel: {
      type: Number,
      default: null,
    },
    isLoadingSynonym: {
      type: Boolean,
      default: false,
    },
    isSearchResults: {
      type: Boolean,
      default: false,
    },
    isUsedClub: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClickItem(synonym) {
      this.$emit('synonym-list:click-item', { synonym })
    },
    getFormattedPrice(price) {
      return `${price.toLocaleString('ru-RU')} ${this.currency.symbol}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/keyframes';

.synonym-list {
  &__item {
    min-height: 0;
    padding: 16px 8px;
    animation: 0.3s ease-in-out fadeIn;
  }
}
</style>
