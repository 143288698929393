<template>
  <component
    :is="banner"
    :has-box-shadow="hasBoxShadow"
    :text="text"
  />
</template>

<script>
import BannerMtLinkMobile from 'components/common/BannerMtLink/components/mobile/BannerMtLink'
import BannerMtLinkDesktop from 'components/common/BannerMtLink/components/desktop/BannerMtLink'

export default {
  name: 'BannerMtLink',
  components: {
    BannerMtLinkMobile,
    BannerMtLinkDesktop,
  },
  props: {
    hasBoxShadow: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    banner() {
      return this.isMobile
        ? 'BannerMtLinkMobile'
        : 'BannerMtLinkDesktop'
    },
  },
}
</script>
