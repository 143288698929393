import {
  getLpuRequestInterface,
  getDoctorRequestInterface,
  getServicesRequestInterface,
  getDoctorOnlineRequestInterface,
} from 'modules/Schedule/models'

import { PAGE_TYPE_DATA } from 'modules/Schedule/constants'

/**
 * @description
 * Возвращает нужный интерфейс запроса за расписанием в зависимости от переданных аргументов
 *
 * @param { Object } opts
 * @param { String } opts.pageType - одна из констант 'PAGE_TYPE_DATA'
 * @param { Boolean } opts[isDoctorOnline] - если pageType === 'doctors', то может быть онлайн город или выбран фильтр по месту онлайн.
 * Если это так - должен вернуться другой интерфейс запроса расписания для врачей.
 *
 * @return { Object } - один из интерфейсов - 'getLpuRequestInterface' | 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface' | 'getServicesRequestInterface'
 * */

const getRequestDataInterface = ({ pageType, isDoctorOnline = false }) => {
  switch (pageType) {
    case PAGE_TYPE_DATA.lpu:
      return getLpuRequestInterface()
    case PAGE_TYPE_DATA.doctors:
    case PAGE_TYPE_DATA.doctorsLpu:
      return isDoctorOnline ? getDoctorOnlineRequestInterface() : getDoctorRequestInterface()
    case PAGE_TYPE_DATA.services:
      return getServicesRequestInterface()
  }
}

export default getRequestDataInterface
