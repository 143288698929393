var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isEmptyCalendar
    ? _c(_setup.CalendarStub)
    : _c(
        "div",
        [
          _c(
            _setup.AppointmentCalendar,
            _vm._b(
              {
                on: { "calendar:time-choice": _setup.handleCalendarTimeChoice },
              },
              "AppointmentCalendar",
              _setup.isMobile
                ? {
                    slots: _setup.calendarSlots,
                  }
                : {
                    data: _setup.calendarSlots,
                    days: Object.keys(_setup.calendarSlots),
                  },
              false
            )
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _setup.selectedItem.isWorkplace,
                  expression: "selectedItem.isWorkplace",
                },
              ],
              staticClass: "ui-text primary--text text-center cursor-pointer",
              class: [
                _setup.isMobile
                  ? "ui-text_body-2"
                  : "ui-text_body-1 text-decoration-underline mt-4",
              ],
              on: { click: _setup.openFullCalendar },
            },
            [_vm._v("\n    Посмотреть полное расписание\n  ")]
          ),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }