import { axiosClient, scrollLock } from 'utils'

export default {
  container: $('.b-covid-message__overflow'),
  dialogTemplate(items, headerText = 'Информация', desktop = undefined) {
    let ruleItems = ''

    items.forEach(item => {
      ruleItems += `<div class="b-covid-message__item">
                            <div class="b-covid-message__item-icon-container">
                                <svg class="b-icon b-icon_fill_none b-covid-message__item-icon ${desktop ? 'b-icon_size_desktop' : 'b-icon_size_mobile'}">
                                    <use xlink:href="/static/_v1/pd/svg-sprites/global.svg#icon-complete"></use>
                                </svg>
                            </div>

                            <div class="b-covid-message__item-text b-text-unit b-text-unit_color_dark b-text-unit_weight_medium">${item}</div>
                        </div>`
    })

    return `<div class="b-covid-message__overflow">
                    <div class="b-covid-message ${desktop ? 'b-covid-message_desktop' : ''}">
                        <div class="b-covid-message__header">
                            <div class="b-covid-message__header-title b-text-unit b-text-unit_color_dark b-text-unit_weight_medium">
                                ${headerText}
                            </div>

                            <div class="b-covid-message__exit">
                                <svg class="b-icon b-icon_fill_none ${desktop ? 'b-icon_size_desktop' : 'b-icon_size_mobile'}">
                                    <use xlink:href="/static/_v1/pd/svg-sprites/global.svg#icon-x"></use>
                                </svg>
                             </div>
                        </div>

                        <div class="b-covid-message__content">
                            ${ruleItems}
                        </div>

                        <div class="b-covid-message__footer">
                            <div class="b-btn b-btn_color_blue b-covid-message__exit b-covid-message__exit_btn">Понятно</div>
                        </div>
                    </div>
                </div>`
  },
  show({ lpuId, headerText, desktop }, callBack) {
    axiosClient.get(`/ajax/lpu/${lpuId}/covid_rules/`)
      .then(res => {
        callBack(this.dialogTemplate(res.data, headerText, desktop))
        scrollLock.enable()

        $('.b-covid-message__exit').one('click', () => {
          this.remove()
        })

        return res
      })
      .catch(() => {})
  },
  remove() {
    $('.b-covid-message__overflow').remove()
    scrollLock.disable()
  },
}
