<template>
  <div v-if="hasOptions">
    <component
      :is="currentComponent"
      v-bind="{ ...$attrs, ...$props }"
      data-qa="select_modal"
      :is-visible-modal="isSelectModalVisible"
      @[eventClickSelect]="handleClickSelect"
      @[eventClickOption]="handleClickOption"
      @[eventClickModalClose]="handleCloseModal"
    />
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import { SCHEDULE_EVENTS } from 'modules/Schedule/constants'
import ModalManager from 'components/common/mixins/DialogManager'
import SelectModalMobile from 'components/common/SelectModule/SelectModuleModal/components/mobile/SelectModal'
import SelectModalDesktop from 'components/common/SelectModule/SelectModuleModal/components/desktop/SelectModal'
import SelectModalProps from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalProps'
import SelectModalEventNames from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalEventNames'
import SelectModalOptionKeys from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalOptionKeys'
import { dispatchCustomEventGlobally } from 'utils'

export default {
  name: 'SelectModal',
  vuetify,
  mixins: [
    ModalManager,
    SelectModalProps,
    SelectModalEventNames,
    SelectModalOptionKeys,
  ],
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    hasOptions() {
      return !!this.options.length
    },
    isSelectModalVisible() {
      return this.isTopDialog(this.dialogManagerName)
    },
    currentComponent() {
      return this.isMobile ? SelectModalMobile : SelectModalDesktop
    },
  },
  methods: {
    handleClickSelect() {
      this.openDialog(this.dialogManagerName)

      this.$emit(this.eventClickSelect)
    },
    handleCloseModal() {
      this.closeDialog()

      this.$emit(this.eventClickModalClose)
    },
    handleClickOption({ option }) {
      dispatchCustomEventGlobally(this.eventClickOption, {
        option,
        selectModalElement: this.$el,
      })

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.optionClick, {
        option,
        target: this.$el,
        lpuId: this.lpuId,
      })

      this.$emit(this.eventClickOption, { option })
    },
  },
}
</script>
