<template>
  <v-app>
    <div class="reviews-filter">
      <template
        v-for="(type, index) in reviewTypes"
      >
        <v-chip
          v-if="useRenderType(type)"
          :key="index"
          :disabled="type.disabled || isFiltering"
          :color="getTypeBackgroundColor(type.value)"
          :class="[`review-type_${type.value}`, { 'review-type_active': activeType === index }]"
          class="mx-1 my-2 review-type"
          link
          @click="handleTypeChange(index)"
        >
          {{ type.title }} {{ type.amount || 0 }}
        </v-chip>
      </template>
    </div>
  </v-app>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import { onInitialFiltration } from 'modules/ReviewsPage/functions'
import { CHIP_BG_COLORS } from './constants'

export default {
  name: 'ReviewsFilter',
  props: {
    reviewTypes: {
      type: Array,
      required: true,
    },
    redirectUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  data: () => ({
    activeType: 0,
    isFiltering: false,
    typesToShowAlways: ['all', 'positive', 'negative'],
  }),
  computed: {
    shouldRedirect() {
      return !!this.redirectUrl
    },
  },
  created() {
    window.addEventListener('review-type:filtered', this.handleFiltrationEnd)

    onInitialFiltration(initialType => {
      this.activeType = this.reviewTypes.findIndex(type => initialType === type.value)

      // Инициализация прослушивателей может быть позже, поэтому нужна задержка
      setTimeout(() => {
        dispatchCustomEventGlobally('review-type:selected', this.reviewTypes[this.activeType])
      })
    })
  },
  methods: {
    useRenderType(type) {
      return !type.disabled
                || this.typesToShowAlways.includes(type.value)
    },
    getTypeBackgroundColor(type) {
      return CHIP_BG_COLORS[type] ?? CHIP_BG_COLORS.default
    },
    handleTypeChange(newActiveType) {
      if (this.activeType === newActiveType) {
        return
      }

      this.isFiltering = true
      this.activeType = newActiveType

      if (this.shouldRedirect) {
        window.location.href = this.redirectUrl.replace('$0', this.reviewTypes[this.activeType].value)
        return
      }

      dispatchCustomEventGlobally('review-type:selected', this.reviewTypes[this.activeType])
    },
    handleFiltrationEnd() {
      this.isFiltering = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.reviews-filter {
  margin: 0 -4px;
}

.reviews-filter .review-type {
  &::after {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: inherit;
    border: 1px solid transparent;
  }

  &_active::after {
    border-color: $ui-kit-text-secondary;
  }

  &_active {
    &.review-type_positive::after {
      border-color: $ui-kit-success;
    }

    &.review-type_neutral::after {
      border-color: $ui-kit-rating;
    }

    &.review-type_negative::after {
      border-color: $ui-kit-secondary;
    }
  }
}
</style>
