import JSON5 from 'process-js-objects'
import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import { dispatchCustomEventGlobally } from 'utils'
import DocumentsVerified from 'components/common/DocumentsVerified/DocumentsVerified'

const el = document.querySelector('.vue-documents-verified-mount')

if (el) {
  new Vue({
    vuetify,
    el,
    render: h => h(DocumentsVerified),
  })
}

function dispatchDocumentsVerifiedOpen(event) {
  const element = event.target.closest('[data-documents-verified-open]')

  if (!element) {
    return
  }

  event.preventDefault()

  const data = JSON5.parse(element.getAttribute('data-documents-verified-open'))

  dispatchCustomEventGlobally('documents-verified:open', data)
}

window.addEventListener('click', dispatchDocumentsVerifiedOpen)
