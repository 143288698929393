import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import SpecializationPrices from 'components/common/SpecializationPrices/SpecializationPrices'
import { SELECTORS } from 'components/common/SpecializationPrices/constants'

const mountNodes = [...document.querySelectorAll(SELECTORS.mount)]

mountNodes.forEach(el => {
  new Vue({
    vuetify,
    el,
    components: {
      SpecializationPrices,
    },
  })
})
