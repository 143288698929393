import { MAP_BUTTONS } from 'components/common/PopupMap/constants'

const filteredMapButtons = MAP_BUTTONS.filter(({ readyClass }) => Boolean(readyClass))

function addMapButtonReadyClass() {
  filteredMapButtons.forEach(({ selector, readyClass }) => {
    document.querySelectorAll(selector)
      .forEach(mapButton => {
        if (!mapButton.classList.contains(readyClass)) {
          mapButton.classList.add(readyClass)
        }
      })
  })
}

export default addMapButtonReadyClass
