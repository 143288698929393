import { getPinTemplate } from 'components/common/PopupMap/functions/utils'

function getPinForMultiRouteInstance() {
  const startTemplate = getPinTemplate({
    text: 'A',
    classes: 'ui-kit-bg-error',
    isOnMap: true,
  })
  const finishTemplate = getPinTemplate({
    text: 'B',
    classes: 'ui-kit-bg-primary',
    isOnMap: true,
  })

  const startPin = ymaps.templateLayoutFactory.createClass(startTemplate)
  const finishPin = ymaps.templateLayoutFactory.createClass(finishTemplate)

  return [startPin, finishPin]
}

export default getPinForMultiRouteInstance
