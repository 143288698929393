<template>
  <div
    class="appointment-type-filter-list"
    data-qa="appointment-type-filter-list"
  >
    <VChipGroup
      v-model="activePlaceType"
      class="mt-2"
      mandatory
    >
      <template v-for="placeType in placeTypes">
        <VChip
          :key="placeType"
          :value="placeType"
          :color="`${styleVars.default.uiKitBgGray0}4D`"
          class="appointment-type-filter-list__chip"
          data-qa="appointment-type-filter-list__chip"
          active-class="uiKitBgPrimary"
          @click="handleFilterTabClick"
        >
          {{ CHIP_LABELS[placeType] }}
        </VChip>
      </template>
    </VChipGroup>
  </div>
</template>

<script setup>
import { ref, nextTick } from 'vue'
import { dispatchCustomEventGlobally } from 'utils'
import * as styleVars from 'www/themes/doctors/common/variables-export.module.scss'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'
import { PLACE_TYPES } from 'components/common/DoctorPriceBlock/constants'

defineProps({
  placeTypes: {
    type: Array,
    default: () => [],
  },
})

const CHIP_LABELS = {
  clinic: 'В клинике',
  home: 'На дому',
  telemed: 'Онлайн',
}

const activePlaceType = ref('clinic')

const doctorPageLpuListStore = useDoctorPageLpuListStore()

async function handleFilterTabClick() {
  await nextTick()
  doctorPageLpuListStore.filterType = activePlaceType.value
  await nextTick()

  if (window.MOBILE_VERSION) {
    doctorPageLpuListStore.expandedPanels = [0]
  } else {
    dispatchCustomEventGlobally('doctor-page-lpu-list:updated')
  }
}

const locationHash = window.location.hash

if (locationHash.includes('#filter=')) {
  const appointmentTypeFromHash = locationHash.match(/#filter=(\w+)/)[1]

  if (PLACE_TYPES.includes(appointmentTypeFromHash)) {
    activePlaceType.value = appointmentTypeFromHash
  }

  handleFilterTabClick()
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.appointment-type-filter-list {
  &__chip {
    margin-top: 0;
    margin-bottom: 0;
    height: 40px;
    border-radius: 87px;
    color: $ui-kit-bg-gray-0;

    &.v-chip--active {
      color: $ui-kit-primary;
    }
  }
}

:deep(.v-slide-group__content) {
  padding: 0;
}
</style>
