<template>
  <div
    v-if="isExistSoftVersion"
    data-qa="soft_version_icon"
    class="d-inline-flex"
    :style="{ verticalAlign: 'middle' }"
  >
    <SoftVersionImage
      ref="tooltipTrigger"
      :image="softData.image"
    />
    <div
      ref="tooltipContent"
      class="d-none"
      :style="{ maxWidth: '288px' }"
    >
      <div class="ui-text ui-text_subtitle-1 mb-1">
        Пользуется порталом с&nbsp;версией {{ softData.translate }}
      </div>
      <ul class="ui-text ui-text_body-1 pl-6">
        <li
          v-for="(benefit, i) in listBenefits"
          :key="i"
        >
          {{ benefit }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import Hint from 'modules/Hint'
import SoftVersionImage from 'components/common/SoftVersionIcon/components/common/SoftVersionImage'
import { SoftVersionGlobalData } from 'components/common/SoftVersionIcon/mixins'

export default {
  name: 'SoftVersionIcon',
  components: {
    SoftVersionImage,
  },
  mixins: [
    SoftVersionGlobalData,
  ],
  mounted() {
    if (!this.isExistSoftVersion) {
      return
    }

    const tooltipContent = this.$refs.tooltipContent.cloneNode(true)

    tooltipContent.classList.remove('d-none')
    this.$refs.tooltipContent.remove()

    new Hint({
      isMobile: false,
      templateLinking: () => tooltipContent,
      target: this.$refs.tooltipTrigger.$el,
      tippyOptions: {
        trigger: 'mouseenter',
      },
    })
  },
}
</script>
