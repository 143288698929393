<template>
  <VApp
    v-if="lpuAddressList.length"
    data-show-btn-fixed
  >
    <div :class="{ 'pa-2': externalDataStore.isMobile }">
      <DoctorPageLpuListHeader />
      <DoctorPageLpuListBody />
    </div>
  </VApp>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { dispatchCustomEventGlobally, getElementOffsetTop, scrollTo } from 'utils'
import { APPOINTMENT_YANDEX_GOALS } from 'www/yandexGoals'
import YandexGoals from 'modules/YandexGoals'
import BtnFixed from 'www/doctors.mobile.blocks/common/b-btn-fixed/b-btn-fixed'
import type {
  Doctor,
  LpuAddressList,
  SynonymPriceList,
  Urls,
} from 'components/common/DoctorPageLpuList/types'
import { initPopupContent } from 'components/common/BannerMtLink/functions'
/* eslint-disable import/extensions */
import DoctorPageLpuListHeader from 'components/common/DoctorPageLpuList/components/DoctorPageLpuListHeader.vue'
import DoctorPageLpuListBody from 'components/common/DoctorPageLpuList/components/DoctorPageLpuListBody.vue'
/* eslint-enable import/extensions */
import { useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'

const props = defineProps<{
  currentDate: string
  doctor: Doctor
  lpuAddressList: LpuAddressList
  synonymPriceList: SynonymPriceList
  isAppointmentOn: boolean
  appointmentWillEnableTime: string
  lpuWithAppointmentIds: number[]
  townTranslit: string
  townTimedelta: number | null
  urls: Urls,
}>()

const externalDataStore = useExternalDataStore()

externalDataStore.currentDate = props.currentDate
externalDataStore.doctor = props.doctor
externalDataStore.lpuAddressList = props.lpuAddressList
externalDataStore.synonymPriceList = props.synonymPriceList
externalDataStore.isAppointmentOn = props.isAppointmentOn
externalDataStore.appointmentWillEnableTime = props.appointmentWillEnableTime
externalDataStore.lpuWithAppointmentIds = props.lpuWithAppointmentIds
externalDataStore.townTranslit = props.townTranslit
externalDataStore.townTimedelta = props.townTimedelta
externalDataStore.urls = props.urls

onMounted(() => {
  if (externalDataStore.isMobile) {
    if (document.querySelector('[data-has-appointment]')) {
      const yandexGoalsInstance = new YandexGoals()
      const btnFixed = document.querySelector('[data-appointment-block-btn-fixed]')
      const scrollTarget = document.querySelector('[data-show-btn-fixed]')

      if (btnFixed && scrollTarget) {
        new BtnFixed({
          buttonSelector: '[data-appointment-block-btn-fixed]',
          limitSelectors: {
            top: '[data-show-btn-fixed]',
            bottom: '[data-hide-btn-fixed]',
          },
          hookAfterShow() {
            // @ts-ignore
            yandexGoalsInstance.send({ name: APPOINTMENT_YANDEX_GOALS.doctorPageBtnFixedShown })
          },
        })

        btnFixed.addEventListener('click', () => {
          // @ts-ignore
          scrollTo(getElementOffsetTop(scrollTarget))

          // @ts-ignore
          yandexGoalsInstance.send({ name: APPOINTMENT_YANDEX_GOALS.doctorPageBtnFixedClick })
        })
      }
    }
  } else {
    dispatchCustomEventGlobally('doctor-page-lpu-list:updated')

    initPopupContent()
  }
})
</script>
