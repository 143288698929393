var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VList",
    { staticClass: "py-2", attrs: { "data-qa": "select_modal_options" } },
    _vm._l(_vm.options, function (option, index) {
      return _c(
        "VListItem",
        {
          key: index,
          staticClass: "select-modal-option",
          attrs: {
            "data-qa": "select_modal_options_item",
            disabled: option[_vm.optionKeys.disabled],
          },
          on: {
            click: function ($event) {
              return _vm.handleClickOption({ option })
            },
          },
        },
        [
          _vm.hasPrependIcon
            ? [
                _vm.isFunction(_vm.prependIcon)
                  ? _c("div", {
                      domProps: {
                        innerHTML: _vm._s(_vm.prependIcon({ option })),
                      },
                    })
                  : _c("div", {
                      staticClass: "mr-8 ui-text ui-kit-color-text-info",
                      class: _vm.prependIcon,
                    }),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("div", { class: { "mr-2": _vm.hasAppendIcon } }, [
            _c(
              "div",
              {
                staticClass: "ui-text ui-text_body-1 ui-kit-color-text",
                attrs: { "data-qa": "select_modal_options_title" },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.transformOptionTitle(option[_vm.optionKeys.title])
                    ) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            option[_vm.optionKeys.subtitle]
              ? _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_body-2 ui-kit-color-text-info",
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(option[_vm.optionKeys.subtitle]) +
                        "\n      "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.hasAppendIcon
            ? [
                _vm.isFunction(_vm.appendIcon)
                  ? _c("div", {
                      staticClass: "ml-auto",
                      domProps: {
                        innerHTML: _vm._s(_vm.appendIcon({ option })),
                      },
                    })
                  : _c("div", {
                      staticClass: "ml-auto ui-text ui-kit-color-text-info",
                      class: _vm.appendIcon,
                    }),
              ]
            : _vm._e(),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }