<template>
  <div
    data-qa="service_synonym_manage_slots"
    :data-synonym-id="selectedSynonym.id"
    :data-synonym-type-id="selectedSynonym.dtypeId"
  >
    <SynonymList
      v-if="synonymPreview.id"
      :lpu-id="lpuId"
      :is-used-club="isUsedClub"
      :class-id="dclassId"
      :category-id="dcategoryId"
      :type-id="dtypeId"
      :class-translit="dclassTranslit"
      :init-selected-synonym="synonymPreview"
      @synonym:change="handleChangeSynonym"
    />
  </div>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import { SCHEDULE_EVENTS } from 'modules/Schedule/constants'
import SynonymList from 'components/common/SynonymList/components/common/SynonymList'

export default {
  name: 'SynonymListManage',
  components: { SynonymList },
  props: {
    lpuId: {
      type: Number,
      required: true,
    },
    synonymPreview: {
      type: Object,
      required: true,
    },
    isEnabledLpuClub: {
      type: Boolean,
      default: false,
    },
    dtypeId: {
      type: Number,
      default: null,
    },
    dclassId: {
      type: Number,
      default: null,
    },
    dcategoryId: {
      type: Number,
      default: null,
    },
    dclassTranslit: {
      type: String,
      required: true,
    },
  },
  data: vm => ({
    selectedSynonym: vm.synonymPreview,
    isMobile: window.MOBILE_VERSION,
    isClubSwitchesEnabled: false,
    userInClub: window.USER.club.isInClub,
  }),
  computed: {
    isUsedClub() {
      return this.isEnabledLpuClub
                  && (this.userInClub || this.isClubSwitchesEnabled)
    },
    synonymPayloadData() {
      return {
        lpuId: this.lpuId,
        serviceId: this.selectedSynonym.id,
        isPreliminaryConsultation: this.selectedSynonym.isPreliminaryConsultation,
        hasIntervals: this.selectedSynonym.hasIntervals,
      }
    },
  },
  mounted() {
    window.addEventListener('club-discount-switch:change-state', this.handleDiscountSwitchChangeState)
    window.addEventListener('club-service-discount:updated-user-data', ({ detail }) => {
      this.userInClub = detail.userClubData.isInClub
    })
  },
  methods: {
    handleDiscountSwitchChangeState({ detail }) {
      this.isClubSwitchesEnabled = detail.isEnabled
    },
    handleChangeSynonym({ synonym }) {
      if (synonym.id === this.selectedSynonym.id) {
        return
      }

      this.selectedSynonym = synonym

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.updateSynonym, {
        ...this.synonymPayloadData,
      })
    },
  },
}
</script>
