var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-right",
      attrs: { "data-qa": "doctor_service_prices_update_info_wrapper" },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "doctor-service-prices-update-info ui-text ui-text_caption",
          class: { [_vm.componentClasses]: _vm.text },
          attrs: { "data-qa": "doctor_service_prices_update_info" },
        },
        [
          _vm.text
            ? [_vm._v("\n      " + _vm._s(_vm.text) + "\n    ")]
            : _vm._e(),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }