export const NAMESPACE = {
  dataCurrentLpu: 'data-current-lpu',
  lpuInfoClassName: 'b-doctor-card__lpu-info',
  lpuInfoClassNameActive: 'b-doctor-card__lpu-info_active',
  appointmentContainerClassName: 'b-doctor-card__appointment-calendar-container',
  appointmentContainerClassNameActive: 'b-doctor-card__appointment-calendar-container_is_active',
}

/**
 * @description
 * Производит переключение выбранного workplace у врача при его выборе в 'select'
 *
 * @param { Object } opts
 * @param { HTMLElement } opts.card - карточка врача, в которой происходит событие
 * @param { Number } opts.position - выбранная позиция, номер
 *
 * // ToDo schedule-important
 * */

const changeSelectedWorkplace = ({ card, position }) => {
  if (!(card && position)) {
    return
  }

  const lpuInfoNodes = [...card.getElementsByClassName(NAMESPACE.lpuInfoClassName)]
  const appointmentContainerNodes = [...card.getElementsByClassName(NAMESPACE.appointmentContainerClassName)]

  if (!lpuInfoNodes.length || !appointmentContainerNodes.length) {
    return
  }

  lpuInfoNodes.forEach(n => n.classList.remove(NAMESPACE.lpuInfoClassNameActive))
  appointmentContainerNodes.forEach(n => n.classList.remove(NAMESPACE.appointmentContainerClassNameActive))

  const currentPosition = `[${NAMESPACE.dataCurrentLpu}="${position}"]`
  const lpuInfoActive = card.querySelector(`.${NAMESPACE.lpuInfoClassName}${currentPosition}`)
  const appointmentContainerActive = card.querySelector(`.${NAMESPACE.appointmentContainerClassName}${currentPosition}`)

  lpuInfoActive.classList.add(NAMESPACE.lpuInfoClassNameActive)
  appointmentContainerActive.classList.add(NAMESPACE.appointmentContainerClassNameActive)
}

export default changeSelectedWorkplace
