import Vue from 'components'
import { pinia, vuetify } from 'www/plugins'
import YandexGoals from 'modules/YandexGoals'
// eslint-disable-next-line import/extensions
import DoctorPageLpuList from 'components/common/DoctorPageLpuList/DoctorPageLpuList.vue'

const el = document.querySelector('.vue-doctor-page-lpu-list-mount')

if (el) {
  new Vue({
    el,
    name: 'DoctorPageLpuListRoot',
    vuetify,
    pinia,
    components: {
      DoctorPageLpuList,
    },
  })
}

if (!window.FEATURE_FLAGS.pd_price_tabs_doctor) {
  new YandexGoals({ dataName: 'data-ya-goals-doctor-page-lpu-list' })
}
