import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import SoftVersionIcon from 'components/common/SoftVersionIcon/components/desktop/SoftVersionIcon'
import { MOUNT_SELECTOR } from 'components/common/SoftVersionIcon/constants'

const mountElement = document.querySelector(MOUNT_SELECTOR)

if (mountElement) {
  new Vue({
    vuetify,
    el: mountElement,
    components: {
      SoftVersionIcon,
    },
  })
}
