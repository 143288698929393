import type {
  Doctor,
  LpuAddressList,
  SynonymPriceList,
  Urls,
} from 'components/common/DoctorPageLpuList/types'
import { ref } from 'vue'
import { defineStore } from 'pinia'

export default defineStore('externalDataStore', () => {
  const currentDate = ref('')
  const doctor = ref<Doctor | null>(null)
  const lpuAddressList = ref<LpuAddressList | null>(null)
  const synonymPriceList = ref<SynonymPriceList | null>(null)
  const isAppointmentOn = ref(false)
  const appointmentWillEnableTime = ref('')
  const lpuWithAppointmentIds = ref<number[]>([])
  const townTranslit = ref('')
  const townTimedelta = ref<number | null>(null)
  const urls = ref<Urls | null>(null)

  const isMobile = window.MOBILE_VERSION

  return {
    currentDate,
    doctor,
    lpuAddressList,
    synonymPriceList,
    isAppointmentOn,
    appointmentWillEnableTime,
    lpuWithAppointmentIds,
    townTranslit,
    townTimedelta,
    urls,
    isMobile,
  }
})
