var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-4 mb-2" },
    [
      _c("SelectModuleButton", {
        staticClass: "py-1",
        attrs: {
          "data-qa": "service_select_synonym_button",
          title: _vm.selectedSynonym.name,
          subtitle: _vm.getFormattedPrice(_vm.selectedSynonym.price),
        },
        on: { click: _vm.handleClickSelect },
        scopedSlots: _vm._u([
          {
            key: "subtitle",
            fn: function () {
              return [
                _vm.isUsedClub
                  ? [
                      _vm.formattedSelectedSynonym.priceWithDiscount
                        ? _c(
                            "span",
                            { staticClass: "ui-text ui-kit-color-error pr-1" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.getFormattedPrice(
                                      _vm.formattedSelectedSynonym
                                        .priceWithDiscount
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          class: {
                            "ui-text_line-through":
                              _vm.formattedSelectedSynonym.priceWithDiscount,
                          },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.getFormattedPrice(_vm.selectedSynonym.price)
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  : [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.getFormattedPrice(_vm.selectedSynonym.price)
                          ) +
                          "\n      "
                      ),
                    ],
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("AlertDialog", {
        attrs: {
          active: _vm.isVisibleError,
          title: "Произошла ошибка",
          "content-text": "Попробуйте ещё раз.",
          "close-text": "Понятно",
          "data-qa": "service_select_synonym_error",
        },
        on: {
          close: function ($event) {
            _vm.isVisibleError = false
          },
        },
      }),
      _vm._v(" "),
      _c(
        "VDialog",
        {
          attrs: {
            value: _vm.isVisibleModal,
            fullscreen: "",
            persistent: "",
            "hide-overlay": "",
            "no-click-animation": "",
            transition: "fade-transition",
          },
        },
        [
          _c(
            "ModalLayout",
            {
              attrs: {
                title: _vm.modalTitle,
                "close-to": _vm.isStackRoot,
                "back-to": !_vm.isStackRoot,
                "data-qa": "service_synonym_modal",
              },
              on: { "modal:back": _vm.handleClickModalBack },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("SynonymListSearch", {
                        ref: "synonymListSearchComponent",
                        attrs: {
                          "synonym-data": _vm.ajaxSynonymData,
                          "is-loading": _vm.isLoadingSynonym,
                        },
                        on: {
                          "search:found-results": _vm.handleSearchFoundResults,
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _vm._v(" "),
              _vm.isSearchResultsEmpty
                ? _c("SynonymListSearchEmpty")
                : _c("SynonymListItems", {
                    attrs: {
                      "is-used-club": _vm.isUsedClub,
                      "synonym-list": _vm.activeSynonymList,
                      "diagnostic-level": _vm.diagnosticLevel,
                      "is-loading-synonym": _vm.isLoadingSynonym,
                      "is-search-results": _vm.hasSearchResults,
                    },
                    on: {
                      "synonym-list:click-item": _vm.handleClickSynonymItem,
                    },
                  }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }