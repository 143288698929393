/**
 * @author Быков Александр(@bykov)
 * */

export default class {
  constructor(options = {}) {
    this._createOptions(options)
  }

  add(options) {
    this._change(options, 'add')
  }

  remove(options) {
    this._change(options, 'remove')
  }

  has({ parent = document } = {}) {
    const { containerSelector, containerClassNameActive } = this.options
    const container = parent.querySelector(containerSelector)

    return container.classList.contains(containerClassNameActive)
  }

  _change({ parent = document } = {}, method = undefined) {
    const { containerSelector, containerClassNameActive } = this.options
    const containers = parent.querySelectorAll(containerSelector)

    containers.forEach(container => {
      container.classList[method](containerClassNameActive)
    })
  }

  _createOptions(options) {
    this.options = {
      containerSelector: '',
      containerClassNameActive: null,
      ...options,
    }
  }
}
