var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.clubEnabled
    ? _c(
        "div",
        { staticClass: "club-discount-info" },
        [
          _c("div", [
            _c("div", { staticClass: "d-flex align-stretch" }, [
              _c("div", {
                staticClass:
                  "club-discount-info__line club-discount-info__line_gradient",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ml-3" }, [
                _c("div", { staticClass: "ui-text ui-text_subtitle-1" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.priceList.sum) + "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ui-text ui-text_body-2 mt-1" }, [
                  _vm._v(
                    "\n          оплачиваете при записи на приём невозвратную сумму и получаете купон\n        "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex mt-2" }, [
              _c("div", {
                staticClass:
                  "club-discount-info__line club-discount-info__line_solid",
              }),
              _vm._v(" "),
              _c("div", { staticClass: "ml-3" }, [
                _c("div", { staticClass: "ui-text ui-text_subtitle-1" }, [
                  _vm._v(
                    "\n          " + _vm._s(_vm.priceList.lpu) + "\n        "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "ui-text ui-text_body-2 mt-1" }, [
                  _vm._v(
                    "\n          останется оплатить в клинике, когда покажете купон администратору\n        "
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-4 mb-5" }, [
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-1 ui-kit-color-primary cursor-pointer",
                  on: { click: _vm.handleClickInfoIcon },
                },
                [_vm._v("\n        Подробнее про клуб\n      ")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "club-discount-info__payment" }, [
            _c(
              "div",
              {
                staticClass:
                  "club-discount-info__payment-content ui-kit-color-text-secondary",
              },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_body-1 d-flex justify-space-between mb-3",
                  },
                  [
                    _c("span", [_vm._v("\n          Обычная цена\n        ")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "ui-kit-color-text",
                        attrs: { "data-qa": "club_discount_full_price" },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.priceList.full) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                !_vm.clubOfferData.cardDateEnd
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "ui-text ui-text_body-1 d-flex justify-space-between mb-4",
                      },
                      [
                        _c("div", [
                          _c("div", { staticClass: "d-flex align-center" }, [
                            _c("span", { staticClass: "mr-2" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm.isFFPdClubComponentsUpdate
                                      ? "Доступ к клубу"
                                      : "Членство в клубе"
                                  ) +
                                  "\n            "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          !_vm.clubOfferData.cardDateEnd
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "ui-text ui-text_body-2 ui-kit-color-text-info",
                                },
                                [_vm._v("\n            6 месяцев\n          ")]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        !_vm.clubOfferData.cardDateEnd
                          ? [
                              _vm.clubOfferData.cardPrice
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-qa":
                                          "club_discount_card_price ui-kit-color-text",
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.priceList.card) +
                                          "\n          "
                                      ),
                                    ]
                                  )
                                : [_vm._m(0)],
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_body-1 d-flex justify-space-between mb-3",
                  },
                  [
                    _c("span", [_vm._v("\n          Скидка клуба\n        ")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "ui-text ui-kit-color-error",
                        attrs: { "data-qa": "club_discount_profit" },
                      },
                      [
                        _vm._v(
                          "\n          -" +
                            _vm._s(_vm.priceList.profit) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_h6 ui-kit-color-text d-flex justify-space-between",
                  },
                  [
                    _c("span", [_vm._v("\n          Итого\n        ")]),
                    _vm._v(" "),
                    _c(
                      "span",
                      { attrs: { "data-qa": "club_discount_total_price" } },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.priceList.total) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.withAppointmentButton
            ? _c(
                "VSheet",
                { staticClass: "p-5 mt-2", attrs: { elevation: "1" } },
                [
                  _c(
                    "AppointmentButton",
                    { staticClass: "mt-4", attrs: { "club-gradient": "" } },
                    [
                      _vm._v(
                        "\n      Записаться и оплатить " +
                          _vm._s(_vm.priceList.sum) +
                          "\n    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("AppointmentPersonalData", { staticClass: "mt-3" }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        "div",
        { staticClass: "club-discount-info" },
        [
          _c("VSheet", { staticClass: "p-5", attrs: { elevation: "1" } }, [
            _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_h6 ui-kit-color-text d-flex justify-space-between",
              },
              [
                _c("span", [_vm._v("\n        Итого\n      ")]),
                _vm._v(" "),
                _c(
                  "span",
                  { attrs: { "data-qa": "club_discount_full_price" } },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.priceList.full) + "\n      "
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_body-1 ui-kit-color-text-secondary mt-2",
              },
              [_vm._v("\n      Оплата в клинике\n    ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "club-discount-info__payment cursor-pointer mt-2",
              attrs: { "data-qa": "club_discount_club_info" },
              on: {
                click: function ($event) {
                  return _vm.$emit("enable-club")
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "club-discount-info__payment-content ui-text ui-text_body-1 ui-kit-color-text-secondary px-4",
                },
                [
                  _c("div", { staticClass: "d-flex align-center" }, [
                    _c("span", [
                      _vm._v("\n          🔥\n          "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "ui-kit-color-text-error font-weight-medium",
                          attrs: { "data-qa": "club_discount_profit" },
                        },
                        [_vm._v("-" + _vm._s(_vm.priceList.profit))]
                      ),
                      _vm._v(
                        "\n          при оплате невозвратного купона\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm._m(1),
                  ]),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("AppointmentButton", { staticClass: "mt-3" }),
          _vm._v(" "),
          _c("AppointmentPersonalData", { staticClass: "mt-3" }),
        ],
        1
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex align-center" }, [
      _c("div", { staticClass: "ui-kit-color-text mr-1" }, [
        _vm._v("\n                в подарок\n              "),
      ]),
      _vm._v(" "),
      _c("img", {
        attrs: {
          src: "/static/_v1/pd/icons/emoji/wrapped-present.png",
          width: "20",
          height: "20",
          alt: "Иконка Завёрнутый подарок",
          "data-qa": "club_discount_card_free_icon",
        },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ml-2" }, [
      _c("span", {
        staticClass:
          "ui-icon-arrow-right ui-icon ui-icon_fz_medium ui-kit-color-text-error",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }