import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import { EVENT_NAMES as REVIEW_EVENT_NAMES } from 'www/doctors.blocks/common/b-review-card/constants'
import ReviewCardTooltips from 'components/common/ReviewCardTooltips/ReviewCardTooltips'

function init() {
  const mountElements = [...document.querySelectorAll('.b-review-card:not(.d-none) .vue-review-card-tooltips-mount')]

  mountElements.forEach(mountElement => {
    mountElement.classList = ''

    new Vue({
      vuetify,
      el: mountElement,
      components: { ReviewCardTooltips },
    })
  })
}

window.addEventListener(REVIEW_EVENT_NAMES.reviewAjaxLoaded, init)
window.addEventListener('review-card-tooltips:init', init)

/**
   * setTimeout используется для небольшого ускорения загрузки страницы,
   * чтобы рендер компонентов вызывался не блокирующе (асинхронно)
   */
setTimeout(init)
