/* todo (pd_club_components_update): удалить файл после выпиливания флага, т.к. он больше не используется */
export default {
  data: () => ({
    staticData: [
      {
        imageSrc: '/static/_v1/pd/pictures/ui-kit/club.svg',
        title: 'Как работает клубная карта',
        extra: '',
        description:
                    `<div class="ui-text ui-text_body-1 ui-kit-color-text mt-2">
                        Карта даёт вам цену ниже на 30% на приёмы врачей.
                        Для этого вы оплачиваете невозвратный купон на сайте за половину стоимости приёма.
                        Вторую половину оплачиваете уже на месте, когда приходите в клинику.
                     </div>`,
      }, {
        title: 'Карта действует полгода',
        extra: '',
        description:
                    `<div class="ui-text ui-text_body-1 ui-kit-color-text mt-2">
                        Первая карта – в подарок при покупке невозвратного купона на сайте.
                        Следующую можно будет купить за 99 ${window.CURRENCY_DATA.symbol}.
                     </div>`,
      }, {
        title: 'Отдельно про услуги',
        extra: '',
        description:
                    `<div class="ui-text ui-text_body-1 ui-kit-color-text mt-2">
                       В некоторых случаях врач не просто проводит консультацию, но и оказывает
                       дополнительные услуги: взятие мазка, биопсия, инъекция и т.д. Цена даётся только на приём.
                       Услуги на приёме оплачиваются отдельно.
                     </div>`,
      },
    ],
  }),
}
