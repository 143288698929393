<template>
  <div
    v-if="isMobile"
    class="b-card__appointment-calendar"
  >
    <a
      :href="doctorHref"
      class="b-card__appointment-btn b-button b-button_blue"
    >
      <span class="ui-text ui-text_button">
        Запись на приём
      </span>
    </a>
  </div>
  <div
    v-else
    class="b-card__appointment-calendar"
  >
    <div class="b-card__appointment-calendar-empty-slots">
      <div class="b-card__appointment-calendar-empty-slots-inner">
        <div>Расписание недоступно</div>
        <br>
        <a
          :href="doctorHref"
          class="b-button b-button_blue b-card__appointment-btn"
        >
          <span class="ui-text ui-text_button">
            Запись на приём
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppCalendarButton',
  props: {
    doctorHref: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
}
</script>
