import { SELECT_OPTION_KEYS } from 'components/common/SelectModule/SelectModuleModal/constants'

export default {
  props: {
    customOptionKeys: {
      type: Object,
      default: () => SELECT_OPTION_KEYS,
    },
  },
  data() {
    return {
      optionKeys: {
        ...SELECT_OPTION_KEYS,
        ...this.customOptionKeys,
      },
    }
  },
}
