var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "DialogBottomSheet",
    {
      attrs: { "is-visible": _vm.isVisible },
      on: {
        "click:outside": _vm.handleClose,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.handleClose.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "VCard",
        {
          ref: "preliminary-consultation-modal",
          attrs: { "data-qa": "preliminary_consultation_modal" },
        },
        [
          !_vm.isMobile
            ? _c(
                "div",
                { staticClass: "p-2" },
                [
                  _c(
                    "VBtn",
                    {
                      attrs: {
                        icon: "",
                        text: "",
                        depressed: "",
                        color: "uiKitText",
                        "data-qa": "preliminary_consultation_modal_close",
                      },
                      on: { click: _vm.handleClose },
                    },
                    [
                      _c("VIcon", [
                        _vm._v(
                          "\n          ui-icon-close-not-a-circle\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "text-center",
              class: {
                "pb-4 px-4 pt-6": _vm.isMobile,
                "pb-6 px-6": !_vm.isMobile,
              },
            },
            [
              _c("VImg", {
                staticClass: "mx-auto mb-6",
                attrs: {
                  contain: "",
                  width: "280",
                  height: "168",
                  src: "/static/_v1/pd/mascots/doctor-checking-pulse.svg",
                  alt: "Доктор проверяющий пульс - ПроДокторов",
                  "data-qa": "preliminary_consultation_img",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-2",
                  attrs: { "data-qa": "preliminary_consultation_title" },
                },
                [
                  _vm._v(
                    "\n        Сначала нужно\n        проконсультироваться с врачом\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "ui-text ui-text_body-1 ui-kit-color-text-secondary",
                  class: _vm.isMobile ? "mb-6" : "mb-8",
                  attrs: { "data-qa": "preliminary_consultation_subtitle" },
                },
                [
                  _vm._v(
                    "\n        Он соберёт анамнез, проверит наличие показаний и противопоказаний\n        и уже после этого назначит процедуру.\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { class: { "d-flex flex-row-reverse": !_vm.isMobile } },
                [
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-4",
                      class: { "mb-2": _vm.isMobile },
                      attrs: {
                        depressed: "",
                        color: "primary",
                        "data-qa": "preliminary_consultation_btn_continue",
                        block: _vm.isMobile,
                      },
                      on: { click: _vm.handleContinue },
                    },
                    [_vm._v("\n          Записаться к врачу\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-2",
                      class: { "mr-4": !_vm.isMobile },
                      attrs: {
                        text: "",
                        color: "uiKitText",
                        "data-qa": "preliminary_consultation_btn_cancel",
                        block: _vm.isMobile,
                      },
                      on: { click: _vm.handleClose },
                    },
                    [_vm._v("\n          Отменить\n        ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }