export const EVENT_NAMES = {
  clickSelect: 'select-modal:click-select',
  clickOption: 'select-modal:click-option',
  clickModalClose: 'select-modal:click-modal-close',
}

export const SELECT_OPTION_KEYS = {
  id: 'id',
  title: 'title',
  subtitle: 'subtitle',
  disabled: 'disabled',
}
