import ScheduleCore from 'modules/Schedule/classes/ScheduleCore'
import { SCHEDULE_EVENTS, PAGE_TYPE_DATA } from 'modules/Schedule/constants'
import { scheduleRequest } from 'modules/Schedule/api'
import { getServicesRequestData, redirectToLpuApp } from 'modules/Schedule/functions'
import { renderSynonyms, restoreButtonState } from 'modules/Schedule/classes/ScheduleServices/functions'
import { NAMESPACE } from 'modules/Schedule/classes/ScheduleServices/constants'

/**
 * @description
 * Модуль наследуется от базового и используется на страницах.
 * Его задача рендерить расписание на страницах диагностики и услуг.
 * Общие задачи модуля:
 * 1. Делать запросы за обновлением списка синонимов и рендерить список на странице.
 * 2. Производить рендер расписания и отслеживать изменения синонимов.
 * 3. Переключать кнопку 'Записаться' в зависимости от условий(mobile/desktop/express/direct).
 * 4. Производить редирект в форму записи при клике на кнопку 'Записаться'.
 * */
class ScheduleServices extends ScheduleCore {
  /**
     * @private
     * @description
     * HTMLElement последнего выбранного синонима. На его основе вычисляется 'parentCard'.
     * */
  #selectSynonymLastTarget = {}

  /**
     * @public
     * @description
     * Если пользователь выбирает синоним в модальном окне - узел фиксируется и на его основе вычисляется узел карточки клиники на странице.
     * Она используется для рендера расписания и для восстановления состояния кнопок 'Записаться'.
     * */
  get parentCard() { return this.#selectSynonymLastTarget?.closest?.(`[${this.options.dataItem}]`) }

  /**
     * @description
     * Во время исполнения конструктора определяется поле 'pageType', которое нужно дла разграничения общих функций.
     * В случае успешной валидации данных навешивается группа обработчиков страницы, и вызывается инициализация модуля.
     * */
  constructor(opts) {
    opts.pageType = PAGE_TYPE_DATA.services

    super(opts)
    this.init()

    if (this.validator({ getter: 'testPageData' })) {
      this._addEventListeners()
    }
  }

  /**
     * @public
     * @description
     * Метод собирает данные со страницы, рендерит список синонимов, формирует данные для запроса, делает запрос и рендерит расписание.
     * */
  init() {
    super.init()

    try {
      if (!this.validator({ getter: 'testPageData' })) {
        return
      }

      this.updateRequestData({
        requestData: getServicesRequestData({ data: this.data }),
      })

      renderSynonyms({ data: this.data })

      if (!this.validator({ getter: 'testRequestData' })) {
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(this.resolveRequest.bind(this))
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Метод производит переключение кнопок 'Записаться' на странице в зависимости от ряда условий.
     *
     * @param { Object } opts
     * @param { Boolean } opts.hasSchedule - флаг, который говорит о том, есть ли расписание или отсутствует на момент вызова метода.
     * Проставляется статично в зависимости от того, какой хук был вызван - 'hookRenderSuccess' | 'hookRenderError'
     * */
  restoreButtonState({ hasSchedule = false } = {}) {
    try {
      restoreButtonState({
        hasSchedule,
        data: this.data,
        parentCard: this.parentCard,
      })
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Срабатывает во время успешного рендера расписания.
     * */
  hookRenderSuccess() { this.restoreButtonState({ hasSchedule: true }) }

  /**
     * @public
     * @description
     * Срабатывает во время неудачного рендера расписания или ошибки.
     * */
  hookRenderError() { this.restoreButtonState({ hasSchedule: false }) }

  /**
     * @private
     * @description
     * Метод навешивает слушатели нужных модулю событий(клик по кнопке 'Записаться', обновление синонима и клик по синониму).
     * */
  _addEventListeners() {
    document.addEventListener('click', this._clickEventListener.bind(this))
    window.addEventListener(SCHEDULE_EVENTS.updateSynonym, this._updateServiceEventListener.bind(this))
    window.addEventListener(SCHEDULE_EVENTS.selectSynonymClick, ({ detail }) => {
      this.#selectSynonymLastTarget = detail.event.target
    })
  }

  /**
     * @private
     * @description
     * Метод производит сбор данных для запроса, делает запрос и рендерит расписание для конкретной карточки клиники.
     *
     * @param { Object } opts
     * @param { Object } opts.detail - данные выбранного синонима
     * @param { Number } opts.detail.lpuId - id клиники
     * @param { Boolean } opts.detail.hasIntervals - наличие расписания
     * @param { Number } opts.detail.serviceId - id синонима
     * @param { Boolean } opts.detail.isPreliminaryConsultation - является ли процедура инвазивной
     * */
  _updateServiceEventListener({ detail }) {
    try {
      const {
        lpuId,
        serviceId,
        isPreliminaryConsultation,
        hasIntervals,
      } = detail

      if (!hasIntervals) {
        this.restoreButtonState({ hasSchedule: false })
        return
      }

      const requestData = getServicesRequestData({
        data: this.data,
        servicesPart: [{
          lpu_id: lpuId,
          service_id: serviceId,
        }],
      })

      this.updateRequestData({ requestData })

      if (!this.validator({ getter: 'testRequestData' })) {
        this.restoreButtonState({ hasSchedule: false })
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(response => { this.resolveRequest(response, this.parentCard, isPreliminaryConsultation) })
        .catch(this.rejectRequest.bind(this))
    } catch (error) {
      this.createNotice(error)
    }
  }

  /**
     * @private
     * @description
     * Метод является обработчиком события клика на документе.
     * Производит редирект в форму записи при клике на кнопку 'Записаться'.
     *
     * @param { Object } opts
     * @param { HTMLElement } opts.target - узел, на котором произошло события клика.
     * */
  _clickEventListener({ target }) {
    try {
      if (!target) {
        return
      }

      const isAppButton = target.closest(NAMESPACE.appointmentButtonDesktop) // ToDo schedule-important избавиться от привязки к DOM дереву
                || target.closest(NAMESPACE.appointmentButtonMobile) // ToDo schedule-important избавиться от привязки к DOM дереву

      if (isAppButton) {
        const card = target.closest(`[${this.options.dataItem}]`)
        const synonymSelect = card.querySelector(`[${NAMESPACE.dataSynonymId}]`) // ToDo schedule-important избавиться от привязки к DOM дереву
        // Если редирект на форму происходит через кнопку 'Записаться' нужно достать выбранный id синонима и dtype id синонима
        // Но у клиники может и не быть синонимов. В этом случае передавать нечего.

        // Функция 'redirectToLpuApp' проверит - был ли передан дефолтный синоним в 'synonymPreview'. Если да - использует его. Иначе редирект произойдет без синонима
        const selectedSynonymId = synonymSelect
          ? synonymSelect.getAttribute(NAMESPACE.dataSynonymId)
          : null // ToDo schedule-important избавиться от привязки к DOM дереву

        // Функция 'redirectToLpuApp' проверит - был ли передан дефолтный dtype id синонима. Если да - использует его. Иначе редирект произойдет с дефолтным значением со страницы
        const selectedSynonymTypeId = synonymSelect
          ? synonymSelect.getAttribute(NAMESPACE.dataSynonymTypeId)
          : null // ToDo schedule-important избавиться от привязки к DOM дереву

        redirectToLpuApp({
          target,
          data: this.data,
          selectedSynonymId,
          selectedSynonymTypeId,
        })
      }
    } catch (error) { this.createNotice(error) }
  }
}

export default ScheduleServices
