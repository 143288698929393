import { scrollLock, createDevNotice, dispatchCustomEventGlobally } from 'utils'
import { APPOINTMENT_YANDEX_GOALS } from 'yandexGoals'
import { SCHEDULE_EVENTS } from 'modules/Schedule/constants'
import SynonymListProps from 'components/common/SynonymList/mixins/SynonymListProps'
import getSynonymAndDevicesData from 'components/common/SynonymList/api/getSynonymAndDevicesData'
import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import CurrencyData from 'components/common/mixins/CurrencyData'

export default {
  mixins: [
    SynonymListProps,
    ManageYandexGoals,
    CurrencyData,
  ],
  data() {
    return {
      stackList: [],
      isVisibleError: false,
      isVisibleModal: false,
      isLoadingSynonym: false,
      ajaxSynonymData: null,
      ajaxDevicesData: null,
      searchResultList: null,
      selectedSynonym: this.initSelectedSynonym,
      yaGoalsList: {
        ...APPOINTMENT_YANDEX_GOALS,
      },
      classLocale: {
        mrt: 'МРТ',
        kt: 'КТ',
      },
    }
  },
  computed: {
    modalTitle() {
      const serviceName = this.classLocale[this.classTranslit] || 'услугу'

      return `Выбрать ${serviceName}`
    },
    activeSynonymList() {
      return this.hasSearchResults
        ? this.searchResultList
        : this.stackList[this.stackList.length - 1]
    },
    isStackRoot() {
      return this.stackList.length <= 1
    },
    diagnosticLevel() {
      if (this.typeId) { return 3 }

      if (this.categoryId) { return 2 }

      if (this.classId) { return 1 }

      return null
    },
    hasSearchResults() {
      return this.searchResultList !== null
    },
    isSearchResultsEmpty() {
      return this.hasSearchResults && this.searchResultList.length === 0
    },
    formattedSelectedSynonym() {
      return {
        id: this.selectedSynonym.id,
        name: this.selectedSynonym.name,
        price: this.selectedSynonym.price,
        dtypeId: this.selectedSynonym.type_id,
        hasIntervals: this.selectedSynonym.has_intervals,
        isPreliminaryConsultation: this.selectedSynonym.requires_primary_consultation,
        priceWithDiscount: this.selectedSynonym.priceWithDiscount || this.selectedSynonym.price_with_club,
      }
    },
    formattedDevices() {
      return this.ajaxDevicesData?.map(device => ({
        id: device.id,
        name: device.name,
        image: device.equipment_image_100,
        power: device.mrt_tesla_power,
        isOpen: device.mrt_open_tomograph,
        isIncreasedWeight: device.mrt_overweight_patients !== null
          ? device.mrt_overweight_patients
          : device.kt_overweight_patients,
        numberOfSlices: device.kt_slice,
        isDigital: device.rentgen_digital,
        isVideoEndoscopy: device.video_endoscopy,
      }))
    },
  },
  created() {
    // В Sentry падает ошибка, что обращение к this.stackList возвращает undefined
    if (!this.stackList) {
      this.stackList = []
    }
  },
  methods: {
    async handleClickSelect(event) {
      this.openModal()

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.selectSynonymClick, { event })

      if (this.ajaxSynonymData) {
        return
      }

      try {
        this.isLoadingSynonym = true

        const { data: response } = await getSynonymAndDevicesData({
          lpuId: this.lpuId,
          classId: this.classId,
          categoryId: this.categoryId,
          typeId: this.typeId,
        })

        this.ajaxSynonymData = response.services
        this.ajaxDevicesData = response.devices

        this.initStackList()
      } catch (e) {
        this.showError(e)
        this.closeModal()
      } finally {
        this.isLoadingSynonym = false
      }
    },
    handleClickModalBack() {
      this.clearSearchQuery()

      if (this.isStackRoot) {
        this.closeModal()
        return
      }

      this.stackList.pop()
    },
    handleClickSynonymItem({ target, synonym }) {
      if (synonym.sublist) {
        this.stackList.push(synonym.sublist)
        return
      }

      this.closeModal({
        /**
                 * Метод `this.clearSearchQuery()` необходимо вызывать обязательно до закрытия модалки, иначе
                 * обращение через `this.$refs` внутри будет `undefined` на десктопе, т.к. модалка исчезает из DOM
                 * */
        hookBeforeClose: () => this.clearSearchQuery(),
        /**
                 * Метод `this.initStackList()` вызывается после анимации закрытия модалки
                 * для того, чтобы не было глитч эффектов
                 * */
        hookAfterClose: () => this.initStackList(),
      })

      this.selectedSynonym = synonym

      this.sendYandexGoal('servicesSynonymSelected')

      this.$emit('synonym:change', {
        target,
        synonym: this.formattedSelectedSynonym,
        devices: this.formattedDevices,
      })
    },
    initStackList() {
      const firstLevel = this.ajaxSynonymData.sublist

      if (this.diagnosticLevel === 1) {
        this.stackList = [firstLevel]

        return
      }

      const secondLevel = firstLevel[0].sublist

      if (this.diagnosticLevel === 2) {
        this.stackList = [secondLevel]

        return
      }

      const thirdLevel = secondLevel[0].sublist

      if (this.diagnosticLevel === 3) {
        this.stackList = [thirdLevel || secondLevel]
      }
    },
    closeModal({
      hookBeforeClose = () => {},
      hookAfterClose = () => {},
    } = {}) {
      hookBeforeClose()

      this.isVisibleModal = false

      scrollLock.disable()

      setTimeout(() => hookAfterClose(), 300)
    },
    openModal() {
      this.isVisibleModal = true

      scrollLock.enable()

      this.sendYandexGoal('servicesSynonymModalOpen')
    },
    showError({ message }) {
      if (!this.isVisibleModal) {
        return
      }

      this.isVisibleError = true

      this.sendYandexGoal('servicesSynonymModalOpenError')

      createDevNotice({
        module: 'SynonymList',
        description: `Ошибка при загрузке списка синонимов. Message: ${message}`,
      })
    },
    handleSearchFoundResults({ listResult }) {
      this.searchResultList = listResult
    },
    clearSearchQuery() {
      const { synonymListSearchComponent } = this.$refs

      if (!synonymListSearchComponent) {
        return
      }

      synonymListSearchComponent.handleClearInput()
    },
    getFormattedPrice(price) {
      return `${price.toLocaleString('ru-RU')} ${this.currency.symbol}`
    },
  },
}
