import { NAMESPACE } from 'modules/Schedule/classes/ScheduleServices/constants'
import { getItemDataWithParent } from 'modules/Schedule/functions/helpers'

/**
 * @description
 * Производит смену состояния кнопок 'Записаться' во время смены выбранного синонима.
 * Если расписание есть - одна логика, иначе - другая.
 * Если расписания нет, для ЭЗ должна быть кнопка 'Записаться'. Если есть только ПЗ - кнопки быть не должно.
 * Если расписания нет и нет родительского контейнера - значит произошла ошибка, которая не связана с отсутствием расписания.
 * В мобильной версии также добавляется/удаляется отступ в верстке.
 * Метод учитывает особенности верстки мобильной/десктопной версий
 *
 * @param { Object } opts
 * @param { Object } opts.data - собранные данные со страницы - интерфейс 'getModuleDataInterface'
 * @param { Boolean } opts.hasSchedule - состояние от которого зависит поведение модуля.
 * @param { HTMLElement | undefined } opts.parentCard - карточка клиники, если она найдена при возникновении события открытия модального окна с синонимами.
 * Если undefined - значит пользователь не открывал модальное окно.
 *
 * ToDo schedule-important
 * */

const restoreButtonState = opts => {
  const {
    data,
    parentCard,
    hasSchedule,
  } = opts

  if (!hasSchedule && !parentCard) {
    return
  }

  const instances = hasSchedule
    ? data.instances
    : new Map(
      [getItemDataWithParent({ data, target: parentCard })],
    )

    instances.forEach((_, scheduleNode) => { // eslint-disable-line
    const {
      coverNode,
      serviceIsDirect,
      serviceIsExpress,
      scheduleNodeHTML,
    } = data.items.get(scheduleNode)

    if (!hasSchedule) {
      if (window.MOBILE_VERSION) {
        scheduleNode.classList.remove(NAMESPACE.mobileCalendarLoaded) // удаляет отступы у календаря mobile
      }

      if (serviceIsExpress) {
        scheduleNode.innerHTML = scheduleNodeHTML // добавляет кнопку 'Записаться' на mobile или '' на desktop
      }

      if (serviceIsDirect && !serviceIsExpress) { // если доступна только ПЗ - кнопки быть не должно
        scheduleNode.innerHTML = ''
      }

      if (!window.MOBILE_VERSION && serviceIsExpress) {
        coverNode.classList.remove(NAMESPACE.scheduleLoaded) // добавляет кнопку 'Записаться' на desktop
      }

      if (!window.MOBILE_VERSION && serviceIsDirect && !serviceIsExpress) {
        coverNode.classList.add(NAMESPACE.scheduleLoaded) // удаляет кнопку 'Записаться' на desktop
      }

      return
    }

    if (window.MOBILE_VERSION) {
      scheduleNode.classList.add(NAMESPACE.mobileCalendarLoaded) // добавляет отступы у календаря mobile
    } else {
      coverNode.classList.add(NAMESPACE.scheduleLoaded) // удаляет кнопку 'Записаться' на desktop
    }
  })
}

export default restoreButtonState
