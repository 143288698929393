import Vue from 'components'
import SynonymListManage from 'components/common/SynonymListManage/SynonymListManage'
import { NAMESPACE } from 'modules/Schedule/classes/ScheduleServices/constants'

/**
 * @description
 * Производит рендер компонента списка синонимов.
 *
 * @param { Object } opts
 * @param { Object } opts.data - собранные данные со страницы - интерфейс 'getModuleDataInterface'
 * */

const components = []

const renderSynonyms = ({ data }) => {
  const {
    dtypeId,
    dclassId,
    dcategoryId,
    dclassTranslit,
  } = data.storage

  components.forEach(instance => instance.$destroy())
  components.length = 0

  data.items.forEach(itemData => {
    const {
      lpuId,
      coverNode,
      synonymPreview,
      isEnabledLpuClub,
    } = itemData

    const props = {
      lpuId,
      dtypeId,
      dclassId,
      dcategoryId,
      dclassTranslit,
      synonymPreview,
      isEnabledLpuClub,
    }

    const mountId = `synonym-container-${lpuId}`
    const selectNode = coverNode.querySelector(NAMESPACE.synonymListMountNode) // ToDo schedule-important избавиться от привязки к DOM дереву

    selectNode.insertAdjacentHTML('afterbegin', `<div id="${mountId}"></div>`)

    const el = selectNode.querySelector(`#${mountId}`)
    const instance = new Vue({
      el,
      name: 'SynonymListManageRoot',
      render: h => h(SynonymListManage, { props }),
    })

    components.push(instance)
  })
}

export default renderSynonyms
