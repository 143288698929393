import { dispatchCustomEventGlobally } from 'utils'
import { CLUB_EVENTS } from 'www/constants'

function emitOpenGettingCard({
  appointmentData,
  isRedirectViaBanner = false,
} = {}) {
  dispatchCustomEventGlobally(CLUB_EVENTS.cardGettingOpen, {
    appointmentData,
    isRedirectViaBanner,
  })
}

export default emitOpenGettingCard
