import { SOFT_VERSION } from 'components/common/SoftVersionIcon/constants'

function getListBenefits({ softVersion, isLpuPage }) {
  const isStandard = softVersion === SOFT_VERSION.standard
  const isPro = softVersion === SOFT_VERSION.pro

  const listBenefits = [
    `может обновлять данные о ${isLpuPage ? 'врачах' : 'себе'}`,
    'может отвечать на отзывы',
    (isPro || isStandard) ? 'отключает рекламу на странице' : '',
    (isPro && isLpuPage) ? 'может писать об акциях и скидках' : '',
    (isPro) ? 'отслеживает эффективность своей страницы для пациентов' : '',
  ]

  return listBenefits.filter(n => n)
}

export default getListBenefits
