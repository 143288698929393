<template>
  <div>
    <VSelect
      :value="1"
      :label="label"
      append-icon="ui-icon-arrow-down"
      :items="[1]"
      :data-selected-speciality-id="selectedItem.speciality?.id"
      :menu-props="{
        disabled: true,
      }"
      outlined
      hide-details
      @click="openDialog"
    >
      <template #selection>
        <div class="text-break">
          <div v-if="selectedItem.isWorkplace">
            {{ selectedItem.name }} - приём
          </div>
          <div v-else>
            {{ selectedItem.name }}
          </div>
          <div class="ui-text ui-text_body-2 uiKitTextSecondary--text">
            <span v-if="selectedItem.isWorkplace">{{ formattedSelectPrice }}</span>
            <span v-else>{{ selectedItem.price }}</span>
            <template v-if="selectedItem.agesText">
              <span class="uiKitBgGray80--text mx-1">•</span>
              {{ selectedItem.agesText }}
            </template>
          </div>
        </div>
      </template>
    </VSelect>
    <WorkplaceOrServiceDialog
      v-model="isDialogVisible"
      :lpu-id="lpuId"
      :init-workplaces="filterWorkplaces"
      :init-services="filterType === 'clinic' ? initServices : []"
      @item-selected="handleSelectItem"
    />
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { storeToRefs } from 'pinia'
import { getFormattedPrice } from 'utils'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import { getSpecialityAgesText, getWorkplaceNonNullPrices } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/functions'
import { Services, Workplaces } from 'www/vue_components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceDialog/interfaces'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'
import WorkplaceOrServiceDialog from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceDialog/WorkplaceOrServiceDialog'

const props = defineProps({
  lpuId: {
    type: Number,
    required: true,
  },
  initSpecialityTranslit: {
    type: String,
    default: '',
  },
  initWorkplaces: {
    type: Array,
    default: Workplaces,
  },
  initServices: {
    type: Array,
    default: Services,
  },
  label: {
    type: [String, undefined],
    default: undefined,
  },
})

const isDialogVisible = ref(false)

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuId)()
const {
  selectedItem,
  translatedChosenFilter,
} = storeToRefs(doctorAppointmentCardStore)
const { requestCalendarSlots, resetSlots } = doctorAppointmentCardStore

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType } = storeToRefs(doctorPageLpuListStore)

const formattedSelectPrice = computed(() => {
  const isClubPriceDefined = typeof selectedItem.value.priceList.priceClub === 'number'
  const pricePropertyName = isClubPriceDefined && translatedChosenFilter.value === 'price'
    ? 'priceClub'
    : translatedChosenFilter.value

  return getFormattedPrice(selectedItem.value.priceList[pricePropertyName], {
    prefix: filterType.value === 'home' || (filterType.value === 'clinic' && isClubPriceDefined) ? 'от' : '',
  })
})

const workplaceWithChosenAppointmentType = computed(
  () => props.initWorkplaces.find(workplace => workplace.priceList[translatedChosenFilter.value] !== null),
)
const workplaceWithClub = computed(() => props.initWorkplaces.find(workplace => workplace.priceList.priceClub !== null))
const filterWorkplaces = computed(() => props.initWorkplaces.filter(workplace => {
  const filterType = translatedChosenFilter.value

  return workplace.priceList[filterType] !== null
}))

function setSelectedItem(isInitial = false) {
  let isItemSelected = false

  if (props.initWorkplaces.length) {
    const hashSpecialityTranslit = window.location.hash.match(/speciality=([a-z-]+)/)?.[1]

    const findWorkplaceBySpecialityTranslit = specialityTranslit => (
      specialityTranslit
        ? props.initWorkplaces.find(workplace => (
          workplace.speciality.translit === specialityTranslit
                && workplace.priceList[translatedChosenFilter.value] !== null
        ))
        : undefined
    )

    let selectedWorkplace

    selectedWorkplace = findWorkplaceBySpecialityTranslit(hashSpecialityTranslit)
      || findWorkplaceBySpecialityTranslit(props.initSpecialityTranslit)

    if (!selectedWorkplace) {
      const defaultSelectedWorkplace = workplaceWithChosenAppointmentType.value || props.initWorkplaces[0]

      selectedWorkplace = filterType.value === 'clinic'
        ? workplaceWithClub.value || defaultSelectedWorkplace
        : defaultSelectedWorkplace
    }

    if (selectedWorkplace) {
      const notNullPrices = getWorkplaceNonNullPrices(selectedWorkplace.priceList)

      selectedItem.value = {
        ...selectedWorkplace,
        name: selectedWorkplace.speciality.name,
        price: getFormattedPrice(Math.min(...notNullPrices)),
        clubPrice: getFormattedPrice(selectedWorkplace.priceList.priceClub),
        agesText: getSpecialityAgesText(selectedWorkplace.age),
        isWorkplace: true,
      }

      isItemSelected = true
    }
  }

  if (props.initServices.length && !isItemSelected) {
    const selectedService = props.initServices[0]

    selectedItem.value = {
      ...selectedService,
      price: getFormattedPrice(selectedService.price),
      clubPrice: null,
      agesText: getSpecialityAgesText(selectedService.age),
      isWorkplace: false,
    }

    if (isInitial) {
      requestCalendarSlots()
    }
  }
}

watch(filterType, () => {
  if (workplaceWithChosenAppointmentType.value) {
    setSelectedItem()
  }
})

setSelectedItem(true)

function openDialog() {
  isDialogVisible.value = true
}

function handleSelectItem(item) {
  const prevSelectedItem = selectedItem.value

  selectedItem.value = item

  if (item.isWorkplace && prevSelectedItem.isWorkplace) {
    return
  }

  resetSlots()
  requestCalendarSlots()
}
</script>

<style scoped lang="scss">
// Потому что есть глобальные стили с селектором `#app input`, которые ломают отображение
:deep(input) {
  height: 0 !important;
}
</style>
