function getSelected() {
  try {
    if (window.getSelection !== undefined) {
      return window.getSelection().toString()
    }

    if (document.getSelection !== undefined) {
      return document.getSelection().toString()
    }

    const selection = document.selection && document.selection.createRange()

    if (selection.text) {
      return selection.text.toString()
    }
  } catch {
    return ''
  }

  return ''
}

export default getSelected
