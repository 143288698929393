export const APPOINTMENT_TYPES = {
  default: 'default',
  telemed: 'telemed',
  na_dom: 'na_dom',
  club: 'default', // default, так как при включенном клубе должны отображаться и дефолтные цены
}

export const SELECTORS = {
  mount: '.specialization-prices-mount',
  cardClinic: '[data-wp-block-id]',
}
