import {
  MULTI_ROUTE_TYPES,
  NAVIGATION_PANEL_CLASSES,
} from 'components/common/PopupMap/constants'

function getNavigationPanelTemplate({
  multiRoute,
  contentTemplate,
}) {
  const {
    block,
    button,
    autoButton,
    masstransitButton,
    buttonActive,
    closeNavigation,
  } = NAVIGATION_PANEL_CLASSES

  const activeClassButtonAuto = multiRoute.model.getRoutes()[0].getType() === MULTI_ROUTE_TYPES.driving
    ? ` ${buttonActive}`
    : ''
  const activeClassButtonMasstransit = multiRoute.model.getRoutes()[0].getType() === MULTI_ROUTE_TYPES.masstransit
    ? ` ${buttonActive}`
    : ''

  const containerButtonClass = window.MOBILE_VERSION
    ? 'p-2'
    : 'px-4 pb-4'

  return `
        <div class="${block} p-4">
            <div class="d-flex justify-center">
                <div 
                    class="cursor-pointer ui-icon-auto mr-2 p-2 ${button} ${autoButton}${activeClassButtonAuto}"
                    data-qa="popup_map_select_car_type_routes_button"
                ></div>
                <div 
                    class="cursor-pointer ui-icon-bus ml-2 p-2 ${button} ${masstransitButton}${activeClassButtonMasstransit}"
                    data-qa="popup_map_select_masstransit_type_routes_button"
                ></div>
            </div>
            ${contentTemplate}
        </div>
        <div class="${containerButtonClass}">
            <button 
                type="button" 
                class="b-button b-button_light w-100 ${closeNavigation}"
                data-qa="popup_map_reset_route_button"
            >
                <span class="ui-icon-close-not-a-circle ui-text ui-kit-color-primary pr-2"></span>
                <span class="ui-text ui-text_button ui-kit-color-primary">Сбросить маршрут</span>
            </button>
        </div>
    `
}

export default getNavigationPanelTemplate
