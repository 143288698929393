var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      staticClass: "patient-profile-modal",
      attrs: {
        value: _vm.isVisible,
        fullscreen: "",
        persistent: "",
        "hide-overlay": "",
        transition: "fade-transition",
      },
    },
    [
      _c(
        "ModalLayout",
        {
          ref: "modalLayout",
          attrs: {
            "close-to": "",
            "limit-title": "",
            title: `Пациент ${_vm.patientData.title}`,
            "has-fixed-btn": _vm.patientData.isLoaded,
          },
          on: { "modal:back": _vm.handleCloseModal },
        },
        [
          _c("PatientProfileModalContent", {
            attrs: { "patient-data": _vm.patientData },
            on: { "modal:close": _vm.handleCloseModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }