export const BLOCK_NAME = 'b-reviews-page'

export const CLASS_NAMES = {
  containerLoading: `${BLOCK_NAME}__container_loading`,
  filterVue: 'reviews-filter',
}

export const SELECTORS = {
  container: '[data-rp-container]',
  containerToClear: '[data-rp-container-clear]',
  button: '[data-rp-button]',
  buttonToHide: '[data-rp-to-hide]',
  ajaxUrl: '[data-rp-ajax-url]',
}

export const ACTIONS = {
  add: 'add',
  remove: 'remove',
}

export const REVIEW_TYPES = [
  'all',
  'positive',
  'neutral',
  'negative',
  'resolved',
  'unconfirmed',
  'deleted',
]
