<template>
  <div>
    <AwardBanner
      v-if="showBanner"
      :class="isMobile ? 'm-2' : 'mb-6'"
      :is-visible-banner="isVisibleBanner"
      :is-dark-mode="currentContent.isDarkTheme"
      :banner-data="currentContent.banner"
      @award-banner:close="handleBannerClose"
      @award-banner:show-more="handleBannerShowMore"
    />
    <component
      :is="AwardModal"
      :is-visible-modal="isVisibleModal"
      :is-dark-mode="currentContent.isDarkTheme"
      :modal-data="currentContent.modal"
      @award-modal:close="isVisibleModal = false"
      @award-modal:click-redirect="handleModalClickRedirect"
    />
  </div>
</template>

<script>
import AwardBanner from 'components/common/Award/components/common/AwardBanner'
import AwardModalMobile from 'components/common/Award/components/mobile/AwardModal'
import AwardModalDesktop from 'components/common/Award/components/desktop/AwardModal'
import AwardCommonData from 'components/common/Award/mixins/AwardCommonData'
import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import { getCurrentMoscowDate } from 'components/common/Award/functions'
import { EVENT_DATES, CONTENT_BY_DATE } from 'components/common/Award/constants'

export default {
  // eslint-disable-next-line
    name: 'Award',
  components: {
    AwardBanner,
    AwardModalMobile,
    AwardModalDesktop,
  },
  mixins: [
    AwardCommonData,
    ManageYandexGoals,
  ],
  data: () => ({
    isVisibleBanner: true,
    isVisibleModal: false,
  }),
  computed: {
    AwardModal() {
      return this.isMobile ? AwardModalMobile : AwardModalDesktop
    },
    currentEventDate() {
      const currentMoscowDate = getCurrentMoscowDate()
      const eventDatesArray = Object.values(EVENT_DATES).sort((a, b) => b - a)

      return eventDatesArray.find(date => currentMoscowDate >= date)
    },
    currentContent() {
      return CONTENT_BY_DATE[this.currentEventDate] || CONTENT_BY_DATE[EVENT_DATES.awardAnnounce]
    },
    showBanner() {
      return this.currentEventDate < EVENT_DATES.showOver
    },
  },
  methods: {
    handleBannerClose() {
      this.isVisibleBanner = false

      this.sendYandexGoal('bannerClose')
    },
    handleBannerShowMore() {
      this.isVisibleModal = true

      this.sendYandexGoal('bannerShowMore')
    },
    handleModalClickRedirect() {
      this.sendYandexGoal('modalClickRedirect')

      if (this.currentContent.modal) {
        window.open(this.currentContent.modal.button.href, '_blank')
      }
    },
  },
}
</script>
