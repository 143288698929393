<template>
  <VList
    class="py-2"
    data-qa="select_modal_options"
  >
    <VListItem
      v-for="(option, index) in options"
      :key="index"
      class="select-modal-option"
      data-qa="select_modal_options_item"
      :disabled="option[optionKeys.disabled]"
      @click="handleClickOption({ option })"
    >
      <template v-if="hasPrependIcon">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="isFunction(prependIcon)"
          v-html="prependIcon({ option })"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div
          v-else
          :class="prependIcon"
          class="mr-8 ui-text ui-kit-color-text-info"
        />
      </template>

      <div :class="{ 'mr-2': hasAppendIcon }">
        <div
          class="ui-text ui-text_body-1 ui-kit-color-text"
          data-qa="select_modal_options_title"
        >
          {{ transformOptionTitle(option[optionKeys.title]) }}
        </div>
        <div
          v-if="option[optionKeys.subtitle]"
          class="ui-text ui-text_body-2 ui-kit-color-text-info"
        >
          {{ option[optionKeys.subtitle] }}
        </div>
      </div>

      <template v-if="hasAppendIcon">
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-if="isFunction(appendIcon)"
          class="ml-auto"
          v-html="appendIcon({ option })"
        />
        <!-- eslint-enable vue/no-v-html -->
        <div
          v-else
          :class="appendIcon"
          class="ml-auto ui-text ui-kit-color-text-info"
        />
      </template>
    </VListItem>
  </VList>
</template>

<script>
import SelectModalLogic from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModalLogic'

export default {
  name: 'SelectModalOptions',
  mixins: [
    SelectModalLogic,
  ],
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/keyframes';

.select-modal-option {
  min-height: 0;
  padding: 16px 8px;
  animation: 0.3s ease-in-out fadeIn;

  &.v-list-item--disabled {
    animation: none;
    opacity: 0.3;
  }
}
</style>
