<template>
  <VBtn
    data-qa="appointment_form_appointment_button"
    color="primary"
    elevation="0"
    :class="{ 'appointment-button_club-gradient': clubGradient }"
    :disabled="!isFamilyRequestDone"
    block
    @click="handleAppointment"
  >
    <slot>
      <VIcon
        v-if="iconName && !appointmentPlaceTypes.isTelemed"
        class="mr-2"
      >
        {{ iconName }}
      </VIcon>
      {{ appointmentPlaceTypes.isTelemed ? 'К оплате' : 'Записаться' }}
    </slot>
  </VBtn>
</template>

<script>
import { mapState, mapActions } from 'pinia'
import { APPOINTMENT_YANDEX_GOALS } from 'yandexGoals'
import {
  useYandexGoalsStore,
  useFamilyProfilesStore,
  useAppointmentDataStore,
} from 'components/common/AppointmentPage/stores'

export default {
  name: 'AppointmentButton',
  props: {
    clubGradient: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState(useFamilyProfilesStore, [
      'isFamilyRequestDone',
    ]),
    ...mapState(useAppointmentDataStore, [
      'appointmentPlaceTypes',
      'appointmentTypeName',
    ]),
    iconName() {
      return { direct: 'ui-icon-flashlight', express: 'ui-icon-phone-none' }[this.appointmentTypeName]
    },
  },
  methods: {
    handleAppointment() {
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.clickAppointmentDirect,
        formType: 'anyDirect',
        objectType: 'doctor',
      })
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.serviceClickAppointmentDirect,
        formType: 'direct',
        objectType: 'service',
      })
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.clickAppointmentExpress,
        formType: 'express',
        objectType: 'doctor',
      })
      this.sendYaGoal({
        name: APPOINTMENT_YANDEX_GOALS.serviceClickAppointmentExpress,
        formType: 'express',
        objectType: 'service',
      })

      this.confirmAppointment()
    },
    ...mapActions(useAppointmentDataStore, [
      'confirmAppointment',
    ]),
    ...mapActions(useYandexGoalsStore, [
      'sendYaGoal',
    ]),
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.appointment-button {
  &_club-gradient {
    background-color: $ui-kit-text-warning;
    background-image: $ui-kit-club-gradient;
  }
}
</style>
