import DoctorCardEmitter from 'modules/DoctorCardEmitter'
import DoctorListPagination from 'modules/DoctorListPagination'
import ScheduleCore from 'modules/Schedule/classes/ScheduleCore'
import { PAGE_TYPE_DATA } from 'modules/Schedule/constants'
import { scheduleRequest } from 'modules/Schedule/api'
import {
  getVisitType,
  redirectToDoctorApp,
  getDoctorsRequestData,
  getDoctorsRequestDataSingle,
} from 'modules/Schedule/functions'

/**
 * @description
 * Модуль наследуется от базового и используется на страницах.
 * Его задача рендерить расписание на странице списка врачей клиники.
 * Общие задачи модуля:
 * 1. Формирование данных для запроса => запрос => отрисовка расписания списка врачей/группы врачей.
 * 2. Работа с фильтрацией/пагинацией. За это отвечает модуль 'DoctorListPagination', который работает независимо от расписания.
 *    Сам модуль 'ScheduleDoctorsLpu' напрямую зависит от модуля 'DoctorListPagination', так как во время исполнения хука 'DoctorListPagination' возвращает нужные данные.
 * 3. Редирект на форму записи во время клика по кнопке 'Записаться' | 'Вызвать врача на дом'.
 * 4. Обработка переключения табов типа приема(клуб, онлайн и тд) и соответствующие запросы, если это необходимо(онлайн-город и пересчет расписания)
 * 5. Переключение кнопки 'Вызвать врача на дом'.
 *    За это отвечает модуль 'DoctorCardEmitter'.
 *    Он в свою очередь также отслеживает действия пользователя и исполняет переданные callback во время наступления событий.
 * */
class ScheduleDoctorsLpu extends ScheduleCore {
  /**
     * @private
     * Номер страницы в пагинации. Используется JS исчисление(у пользователя 1, в JS 0).
     * Это поле необходимо для доступа к группе элементов на странице.
     * */
  #position = 0

  /**
     * @private
     * Разбивка карточек врачей, которую предоставляет модуль 'DoctorListPagination'.
     * Он формирует многомерный массив([[card, card, ...], [card, card...], ...]), в котором каждый массив содержит узлы страницы пагинации.
     * Например, чтобы достать список элементов на текущей странице пагинации, достаточно обратиться к 'this.#cardGroups[this.#position]'
     * */
  #cardGroups = []

  /**
     * @private
     * Хранилище самого модуля 'ScheduleDoctorsLpu', которое в итоге будет использоваться геттером 'doctorsWithSchedule'
     * */
  #cacheData = new Map()

  /**
     * @public
     * Массив узлов, которые видимы для пользователя. Остальные должны быть скрыты.
     * */
  get visibleCards() { return this.#cardGroups[this.#position] || [] }

  /**
     * @public
     * Возвращает поле 'doctorsLpuList', которое используется для запроса за расписанием.
     * По сути вся работа, которую производит геттер - это проверка - нужно ли делать запрос за расписанием или нет.
     * Если нет - 'doctorsLpuList' не дополняется объектом.
     * Иначе - дополняется.
     * За проверку отвечает 2 переменные - 'isNeedRequest' и 'isRequested'.
     * 'isRequested' - поле, которое сохраняется в '#cacheData' со значением true, если запрос за расписанием для карточки уже делался.
     * 'isNeedRequest' - основывается на значении 'isRequested' и других полей, которые ранее были собраны со страницы.
     * */
  get doctorsWithSchedule() {
    const doctorsLpuList = []

    if (!this.visibleCards.length) {
      return doctorsLpuList
    }

    this.visibleCards.forEach(card => {
      const cacheCardData = this.#cacheData.get(card)

      if (!cacheCardData) {
        return
      }

      const {
        lpuId,
        doctorId,
        hasSlots,
        doctorFio,
        isRequested,
        isAppointmentOn,
      } = cacheCardData
      const isNeedRequest = !isRequested && hasSlots && isAppointmentOn

      if (!isNeedRequest) {
        return
      }

      doctorsLpuList.push({
        lpu_id: lpuId,
        doctor_id: doctorId,
        doctor_fio: doctorFio,
      })

      this.#cacheData.set(card, {
        ...cacheCardData,
        isRequested: true,
      })
    })

    return doctorsLpuList
  }

  /**
     * @description
     * Во время исполнения конструктора определяется поле 'pageType', которое нужно дла разграничения общих функций.
     * Создается инстанс 'DoctorListPagination'.
     * Создается инстанс 'DoctorCardEmitter', собираются данные для поля 'cacheData' с дефолтным значением 'isRequested: false'.
     * Сам запрос не производится. Его тригеррит 'DoctorListPagination', который во время инициализации вызывает хук 'hookCardListUpdated'.
     * Именно хук 'hookCardListUpdated' собирает данные для нужной группы карточек врачей и делает запрос с нужными данными, после чего производится рендер.
     * */
  constructor(opts) {
    opts.pageType = PAGE_TYPE_DATA.doctorsLpu

    super(opts)

    new DoctorListPagination({ // модуль для работы с пагинацией и фильтрацией работает независимо от расписания
      hookCardListUpdated: this.hookCardListUpdated.bind(this),
    })

    new DoctorCardEmitter({
      cardSelector: `[${this.options.dataItem}]`,
      doctorPageType: this.options.doctorPageType,
      hookTabChange: this.hookTabChange.bind(this),
      hookAppButtonClick: this.hookAppButtonClick.bind(this),
    })

    if (!this.validator({ getter: 'testPageData' })) {
      return
    }

    this.data.items.forEach(value => { // создается объект, который будет использоваться для загрузки расписания по частям
      this.#cacheData.set(value.coverNode, {
        ...value,
        isRequested: false,
      })
    })
  }

  /**
     * @public
     * Обработка события фильтрации.
     * Как только произошло изменение в фильтрации или пагинации - срабатывает этот хук.
     * Также он срабатывает во время инициализации 'DoctorListPagination'(который вызывается в конструкторе 'ScheduleDoctorsLpu').
     * Иными словами - сразу во время вызова 'ScheduleDoctorsLpu'.
     * Собирает данные для запроса, делает запрос и производит рендер расписания.
     *
     * @param { Object } opts
     * @param { Number } opts.position - номер страницы пагинации.
     * @param { Array<Array<HTMLElement>> } opts.cardGroups - все карточки врачей, разбитые по страницам.
     * */
  hookCardListUpdated({ position, cardGroups }) {
    this.#position = position - 1 // коллизия, связанная началом исчисления в массиве(0 в JS, 1 для пользователя)
    this.#cardGroups = cardGroups

    try {
      if (!this.visibleCards.length) {
        return
      }

      this.updateRequestData({
        requestData: getDoctorsRequestData({
          data: this.data,
          doctorsLpusPart: this.doctorsWithSchedule,
        }),
      })

      if (!this.validator({ getter: 'testRequestData' })) {
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(this.resolveRequest.bind(this))
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Метод, который вызывается во время смены таба типа приема.
     * В этом методе производится обработка выбора таба 'Онлайн' и пересчет расписания.
     *
     * @param { Object } payload
     * @param { HTMLElement } payload.target - элемент, по которому произошел клик.
     * @param { Boolean } payload.isOtherSelected - true, если ушли с таба `Онлайн` (телемед).
     * @param { Boolean } payload.isOnlineSelected - true, если перешли на таб `Онлайн` (телемед).
     * @param { Boolean } payload.isNeedRecalcCalendar - true, если необходим пересчёт слотов по времени пользователя или клиники.
     * */
  hookTabChange(payload) {
    const {
      target,
      isOnlineSelected,
      isNeedRecalcCalendar,
    } = payload

    if (!isNeedRecalcCalendar) {
      return
    }

    try {
      const parentCard = target?.closest(`[${this.options.dataItem}]`) // '?.' на случай если target не передан или не определен
      const requestData = getDoctorsRequestDataSingle({
        target,
        data: this.data,
        isOnlineSelected,
      })

      if (!requestData) { // если не найдено данных для врача или timezone пользователя и сервера совпадает - запрос не нужен
        return
      }

      this.updateRequestData({ requestData })

      if (!this.validator({ getter: 'testRequestData' })) {
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(response => { this.resolveRequest(response, parentCard) })
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Производит редирект на форму записи по клику на кнопку 'Записаться' | 'Вызвать врача на дом'.
     *
     * @param { Object } opts
     * @param { HTMLElement } opts.appointmentButton - кнопка записи, по которой произошел клик.
     * */
  hookAppButtonClick({ appointmentButton }) {
    try {
      const visitType = getVisitType({
        target: appointmentButton,
        dataItem: this.options.dataItem,
      })

      redirectToDoctorApp({
        visitType,
        data: this.data,
        dataItem: this.options.dataItem,
        buttonNode: appointmentButton,
      })
    } catch (error) { this.createNotice(error) }
  }
}

export default ScheduleDoctorsLpu
