import { deepMerge, getDataJSON } from 'utils'
import getDefaultTabsData from 'components/common/AppointmentTabList/functions/getDefaultTabsData'
import { DATA_ATTR_STORAGE, INNER_MOUNT_SELECTOR } from 'components/common/AppointmentTabList/constants'

/**
 * @description
 *
 * Возвращает данные для рендера компонентов на странице
 *
 * @return { Map } - [узел, куда рендерить]: [пропы для компонента]
 * */

function getRenderData() {
  const renderData = new Map()
  const pageData = getDataJSON({
    dataName: DATA_ATTR_STORAGE,
  })

  pageData.forEach(({ data, node }) => {
    const innerNode = node.querySelector(INNER_MOUNT_SELECTOR)

    renderData.set(innerNode || node, deepMerge(getDefaultTabsData(), data))
  })

  return renderData
}

export default getRenderData
