import 'intersection-observer' // polyfill
import { getCSRFToken } from 'utils'
import {
  redirectToAdsPage,
  viewedNodeHandler,
} from 'www/doctors.blocks/common/b-lpu-ad/functions'
import {
  sendViewStatusRequest,
  sendViewBannerRequest,
} from 'www/doctors.blocks/common/b-lpu-ad/api'
import {
  VISIBLE_BANNER_AREA_PERCENTS,
  VIEWING_TIME,
  VISIBLE_BANNER_AREA_PERCENTS_DEMO,
  LPU_AD_ELEMENT_CLASS_SELECTOR,
} from 'www/doctors.blocks/common/b-lpu-ad/constants'

function lpuAd() {
  const lpuAdElement = document.querySelector(LPU_AD_ELEMENT_CLASS_SELECTOR)

  if (!lpuAdElement || !getCSRFToken()) {
    return
  }

  const viewKey = Number(lpuAdElement.dataset.key)
  const redisId = Number(lpuAdElement.dataset.redisId)
  const { campaign, type } = lpuAdElement.dataset

  if (viewKey) {
    const viewStatusRequestData = {
      key: viewKey,
      redis_id: redisId,
    }

    // Отправляю запрос первый раз, при загрузке страницы
    sendViewStatusRequest({ ...viewStatusRequestData, js: true })

    // Второй раз отправление запроса произойдет
    // при попадании баннера в область просмотра пользователя
    const observerViewStatus = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          sendViewStatusRequest({ ...viewStatusRequestData, visible: true })
          observer.unobserve(entry.target)
        }
      })
    })

    observerViewStatus.observe(lpuAdElement)
  }

  const viewBannerRequestData = {
    campaign,
    type,
    redisId,
  }

  const observerViewBanner = viewedNodeHandler(() => sendViewBannerRequest({
    ...viewBannerRequestData,
    percents: VISIBLE_BANNER_AREA_PERCENTS,
  }), VISIBLE_BANNER_AREA_PERCENTS, VIEWING_TIME)

  observerViewBanner.observe(lpuAdElement)

  if (lpuAdElement.dataset.demo) {
    const demoObserver = viewedNodeHandler(() => sendViewBannerRequest({
      ...viewBannerRequestData,
      percents: VISIBLE_BANNER_AREA_PERCENTS_DEMO,
    }), VISIBLE_BANNER_AREA_PERCENTS_DEMO, VIEWING_TIME)

    demoObserver.observe(lpuAdElement)
  }

  document.addEventListener('click', redirectToAdsPage)
}

window.addEventListener('executeLpuAdScript', lpuAd)

lpuAd()
