import { isFeatureFlagEnabledHTML } from 'utils'
import ClassSwitcher from 'modules/ClassSwitcher/ClassSwitcher'

/* eslint-disable */
$(function () {
    if (isFeatureFlagEnabledHTML({ dataAttr: 'data-use-doctor-page-redesign' })) {
        new ClassSwitcher({
            parent: '.b-doctor-details__item',
            button: '.b-doctor-details__item-show-more',
            parentActiveClass: 'b-doctor-details__item_active',
            afterAdd: ({ button }) => { button.hidden = true; },
        });
    }

    const showMsg = 'Показать весь список';
    const hideMsg = 'Скрыть';

    $('.b-doctor-details__more-btn-wrapper').each(function (i, el) {
        const $el = $(el);
        let hidden = true;

        $el.on('click', function () {
            const $parent = $(this).closest('.b-doctor-details__item');
            const $btn = $parent.find('.b-doctor-details__more-btn');
            const $stroke = $parent.find('.b-doctor-details__more-btn-stroke');
            const $hideables = $parent.find('.b-doctor-details__list-item_hideable');

            if (hidden) {
                $btn.text(hideMsg);
                $stroke.addClass('b-doctor-details__more-btn-stroke_opened');
                $hideables.each(function (i, el) {
                    $(el).css('display', 'flex');
                });
            } else {
                $btn.text(showMsg);
                $stroke.removeClass('b-doctor-details__more-btn-stroke_opened');
                $hideables.each(function (i, el) {
                    $(el).css('display', 'none');
                });
            }
            hidden = !hidden;
            $(window).trigger('redraw'); // для пересчета ширин и высот у элементов
        });
    });

    (function lazyActivities () { // -------------------------------Код, отвечающий за первую загрузку активностей врача
        const $lazyElement = $('.b-doctor-details__lazy');

        if (!$lazyElement.length) return;

        const doctorId = $lazyElement.data('doctor-id');
        let loadingTriggered = false;
        let activitiesOffset = $lazyElement.offset().top;

        $(window).on('resize redraw load', function () {
            activitiesOffset = $lazyElement.offset().top;
        });

        /**
         * Если страница загружена по ссылке с якорем на активности врача, то функция ниже загружает активности и скроллит к якорю
         */
        function loadActivitiesByURL () {
            const { hash } = window.location;

            if (!hash) {
                return;
            }

            const linksActivities = [...document.querySelectorAll('[data-true-target]')];

            linksActivities.forEach(link => {
                if (hash !== link.dataset.trueTarget) {
                    return;
                }

                $('.b-doctor-details__toc-item_lazy').off('click', loadIfScrolledEnough);
                $(window).off('scroll', loadIfScrolledEnough);

                $(window).on('ajax-loaded', function () {
                    const target = document.querySelector(link.dataset.trueTarget);
                    target.scrollIntoView(true);
                });

                triggerAjax();
            })
        }

        loadActivitiesByURL();

        /**
         * Инициализирует пагинацию отдельных активностей врача
         */
        function moreActivities () {
            function chooseCase (num, casesStr) {
                const cases = casesStr.split(',');

                if (num % 10 === 1 && num % 100) {
                    return cases[0];
                } else if (num % 10 >= 2 && num % 10 <= 4 && num % 100) {
                    return cases[1];
                } else {
                    return cases[2];
                }
            }

            $('.b-doctor-details__btn_lazy').on('click', function () {
                const $btn = $(this);
                const $parent = $btn.closest('.b-doctor-details__item');
                const $placeholder = $parent.find('.b-doctor-details__list-item-placeholder');
                const doctorId = $btn.data('doctor-id');
                const activityType = $btn.data('section');
                const pageNum = $btn.data('page-num');
                const typeCases = $btn.data('type-cases');

                $.ajax({
                    url: `/ajax/doctor/${doctorId}/activities/${activityType}/?page=${pageNum + 1}`,
                    dataType: 'html',
                    success: function (data) {
                        $placeholder.replaceWith(data);

                        const activityNextCount = $parent.find('.b-doctor-details__list-item-placeholder').val();

                        if (activityNextCount > 0) {
                            $btn.data('page-num', pageNum + 1);
                            $btn.find('.b-doctor-details__next-activities-num').text(`${activityNextCount} ${chooseCase(activityNextCount, typeCases)}`);
                        } else {
                            $btn.detach();
                        }

                        $(window).trigger('redraw');
                    },
                    error: function (xhr, error, errorMessage) {
                        console.error(errorMessage);
                    }
                });
            })
        }

        function loadIfScrolledEnough () {
            if (!loadingTriggered && $(window).height() + $(window).scrollTop() > activitiesOffset - 300) {
                $('.b-doctor-details__toc-item_lazy').off('click', lazyAnchorScroll);
                triggerAjax();
            }
        }

        /**
         * Загружает активности, встраивает в страницу и вызывает кастомное событие прерисовки
         */
        function triggerAjax () {
            if (!loadingTriggered) {
                $.ajax({
                    url: `/ajax/doctor/${doctorId}/activities/`,
                    dataType: 'html',
                    success: function (data) {
                        $lazyElement.html(data);
                        $(window).trigger('redraw');

                        $('.b-doctor-details__toc-item_lazy').each(function (i, el) {
                            const $el = $(el);
                            $el.attr('href', $el.data('true-target'));
                        });

                        moreActivities();

                        $(window).trigger('ajax-loaded');
                    },
                    error: function (xhr, error, errorMessage) {
                        console.error(errorMessage);
                    }
                });
                loadingTriggered = true;
            }
        }

        /**
         * Обработчик клика для якорей, цели которых должны быть загруженны по AJAX
         */
        function lazyAnchorScroll () {
            const $el = $(this);
            $('.b-doctor-details__toc-item_lazy').off('click', lazyAnchorScroll);
            $(window).on('ajax-loaded', function () {
                window.location = $el.data('true-target');
            });
            triggerAjax();
        }

        loadIfScrolledEnough();
        $(window).on('scroll', loadIfScrolledEnough);
        $('.b-doctor-details__toc-item_lazy').on('click', lazyAnchorScroll);
    })();

    (function addAuthors () { // Код, отвечающий за добавление соавторов (нужно проверять был ли упомянут сам врач, потому JS)
        $('.b-doctor-details__item-subtitle_printed').each(function (i, el) {
            const $subtitle = $(el);
            const $printed = $subtitle.closest('.b-doctor-details__list-item');
            const docmameTokens = $printed.data('doctor-name').split(' ');
            let coauthors = $printed.data('fio');

            const surname = docmameTokens[0];
            const firstnameChar = docmameTokens[1][0];
            const secondnameChar = docmameTokens[2][0];

            if (!coauthors.includes(surname)) {
                coauthors = coauthors.trim();
                let comma = '';
                if (coauthors.length > 0) {
                    comma = ', ';
                }
                coauthors += comma + surname + ' ' + firstnameChar + '. ' +
                    secondnameChar + '.';
            }

            $subtitle.append(', ' + coauthors);
        });
    })();

    (function tocHighLighting () { // ----------------------------Код, отвечающий за подсветку нужного пункта содержания
        function getCoords (elem) {
            const box = elem.getBoundingClientRect();

            return {
                top: box.top + pageYOffset,
                left: box.left + pageXOffset
            };
        }

        function onScroll () {
            const sections = $('.b-doctor-details__main').find('.b-doctor-details__item');

            let minDist = 999999;
            let translitName = '';

            sections.each(function (index, el) {
                const idObj = $(el).attr('id');

                if (!idObj) return;

                const cordsElement = getCoords(document.getElementById(idObj));
                const dist = $(window).scrollTop() - cordsElement['top'];

                if (dist >= -75) {
                    if (minDist > dist) {
                        minDist = dist;
                        translitName = idObj;
                    }
                }
            });

            if (translitName === '') {
                $('.b-doctor-details__toc-item:first-child').addClass('b-doctor-details__toc-item_active');
                $('.b-doctor-details__toc-item:not(:first-child)').removeClass('b-doctor-details__toc-item_active');
            } else if (($('html').height() - $(window).height()) - $(window).scrollTop() < 30) {
                $('.b-doctor-details__toc-item:last-child').addClass('b-doctor-details__toc-item_active');
                $('.b-doctor-details__toc-item:not(:last-child)').removeClass('b-doctor-details__toc-item_active');
            } else {
                $('.b-doctor-details__toc-item').each(function () {
                    const anchorTarget = $(this).data('anchor-target');
                    const href = $(this).attr('href');

                    if (href && href.startsWith('#') && href.slice(1) === translitName) {
                        $(this).addClass('b-doctor-details__toc-item_active');
                    } else {
                        if (anchorTarget && anchorTarget === translitName) {
                            $(this).addClass('b-doctor-details__toc-item_active');
                        } else {
                            $(this).removeClass('b-doctor-details__toc-item_active');
                        }
                    }
                });
            }
        }
        if ($('.b-doctor-details:not(.b-doctor-details_static)').length > 0) {
            $(window).on('scroll redraw', onScroll);
            onScroll();
        }
    })();

    (function () {
        $('.b-doctor-details__toc-item_js_scroll-top').click(function (evt) {
            evt.preventDefault();

            $('html, body').animate({
                scrollTop: $("#doctor-details-top").offset().top
            }, 600);
        })
    })();

    (function tocPosition () { // -----------------------------------------------Код, отвечающий за положение содержания
        const $profileToc = $('.b-doctor-details__toc');
        const $profileTocContainer = $profileToc.parent();
        const $profileMain = $('.b-doctor-details__main');

        let profileTocHeight;
        let containerBottom;
        let offset;
        let state = null;

        if ($profileToc.length > 0) {
            profileTocHeight = $profileToc.outerHeight(true);
            computeSizes();
            $(window).on('resize redraw', computeSizes);
            $(window).on('scroll redraw', onScroll);
            onScroll();
        }

        function computeSizes () {
            offset = $profileMain.offset().top;
            containerBottom = $profileMain.offset().top + $profileMain.outerHeight();
            $profileToc.css('width', $profileTocContainer.width());
        }

        window.addEventListener('doctor-page-lpu-list:updated', computeSizes)

        function onScroll () {
            const winScrollTop = $(window).scrollTop();

            containerBottom = $profileMain.offset().top + $profileMain.outerHeight();

            if ((winScrollTop + profileTocHeight < containerBottom - 16) && winScrollTop + 16 > offset) {
                if (state === 1) {
                    return;
                }
                $profileToc.removeClass('b-doctor-details__toc_down');
                $profileToc.addClass('b-doctor-details__toc_fixed');
                state = 1;
            } else {
                $profileToc.removeClass('b-doctor-details__toc_fixed');
                if (winScrollTop + profileTocHeight >= containerBottom - 16) {
                    if (state === 2) {
                        return;
                    }
                    $profileToc.addClass('b-doctor-details__toc_down');
                    state = 2;
                } else {
                    if (state === 0) {
                        return;
                    }
                    $profileToc.removeClass('b-doctor-details__toc_down');
                    state = 0;
                }
            }
        }
    })();
});
