/**
 * @author Кирилл Шабанов (@shabanov)
 */

import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import { observeDOM, createDevNotice } from 'utils'
import {
  MED_TYPES,
  MOUNT_SELECTOR,
} from 'components/common/FavouriteBtn/constants'
import FavouriteBtn from 'components/common/FavouriteBtn/FavouriteBtn'
import { FavouritesService } from 'components/common/FavouriteBtn/api'

const vueInstances = []
const lpuData = {
  idArray: [],
  userFavouritesArray: [],
}
const doctorsData = {
  idArray: [],
  userFavouritesArray: [],
}
const componentTagName = 'favourite-btn'

function collectIdArraysFromPage() {
  [...document.querySelectorAll(componentTagName)].forEach(el => {
    const id = +el.getAttribute(':id')
    const type = el.getAttribute('type')

    if (type === MED_TYPES.lpu) {
      lpuData.idArray.push(id)
      return
    }

    doctorsData.idArray.push(id)
  })
}

async function fetchUserFavouritesArray(type, idArray) {
  if (!idArray.length) {
    return
  }

  try {
    const res = await FavouritesService.getList(type, idArray)

    if (type === MED_TYPES.lpu) {
      lpuData.userFavouritesArray = res.data
      return
    }

    doctorsData.userFavouritesArray = res.data
  } catch (e) {
    createDevNotice({
      module: 'FavouriteBtn/index.js',
      method: 'fetchUserFavouritesArray',
      description: `type: ${type}, error: ${e}`,
    })
  }
}

function setInUserFavouritesAttribute(el) {
  const favouriteBtnNode = el.querySelector(componentTagName)

  if (!favouriteBtnNode) {
    return
  }

  if (window.USER.isAnonymous) {
    favouriteBtnNode.setAttribute(':in-user-favourites', false)
    return
  }

  const favouriteBtnId = +favouriteBtnNode.getAttribute(':id')
  const type = favouriteBtnNode.getAttribute('type')
  const userFavouritesArray = type === MED_TYPES.lpu
    ? lpuData.userFavouritesArray
    : doctorsData.userFavouritesArray

  // eslint-disable-next-line camelcase
  const inUserFavourites = userFavouritesArray.find(element => +element.id === favouriteBtnId)?.in_user_favourites

  favouriteBtnNode.setAttribute(':in-user-favourites', inUserFavourites)
}

function createInstances() {
  [...document.querySelectorAll(MOUNT_SELECTOR)].forEach(el => {
    setInUserFavouritesAttribute(el)
    vueInstances.push(
      new Vue({
        el,
        vuetify,
        components: {
          FavouriteBtn,
        },
      }),
    )
  })
}

function destroyInstances() {
  vueInstances.forEach(vm => vm.$destroy())
  vueInstances.length = 0
}

async function updateInstances() {
  /**
     * Дополнительная проверка на isRealPatient помимо проверки в шаблоне favourite_btn.html
     * Нужна, т.к. срабатывает кэш и кнопки могут рендериться в обход условий в шаблоне.
     */
  if (!window.USER.isRealPatient && !window.USER.isAnonymous) {
    return
  }

  if (vueInstances.length) {
    // Если не найден элемент с тегом `componentTagName`, то значит он уже отрендерен.
    // Иначе, считаем что отрендеренный FavouriteBtn был удалён и заменён новым неотрендеренным.
    if (!document.querySelector(componentTagName)) {
      return
    }

    destroyInstances()
  }

  if (!window.USER.isAnonymous) {
    collectIdArraysFromPage()
    await fetchUserFavouritesArray(MED_TYPES.lpu, lpuData.idArray)
    await fetchUserFavouritesArray(MED_TYPES.doctors, doctorsData.idArray)
  }

  createInstances()
}

updateInstances()

observeDOM({
  selectorParent: '[data-cards-list-container]',
  callbacks: [updateInstances],
})
