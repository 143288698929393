<template>
  <VDialog
    :value="isVisibleModal"
    width="456"
    :content-class="dialogContentClasses"
    @click:outside="handleCloseModal"
    @keydown.esc="handleCloseModal"
  >
    <ModalLayout
      close-to
      limit-title
      body-flex-basis-auto
      :is-dark-mode="isDarkMode"
      :title="modalData.title"
      @modal:back="handleCloseModal"
    >
      <div class="px-6 py-4">
        <AwardModalContent
          :is-dark-mode="isDarkMode"
          :modal-data="modalData"
        />
        <div class="d-flex justify-content-end py-2">
          <VBtn
            text
            class="px-2 mr-4"
            :color="isDarkMode ? 'gold' : 'black'"
            :dark="isDarkMode"
            @click="handleCloseModal"
          >
            Закрыть
          </VBtn>
          <VBtn
            class="px-2"
            elevation="0"
            :color="isDarkMode ? 'gold' : 'primary'"
            :dark="isDarkMode"
            @click="handleClickRedirect"
          >
            <span :class="{ 'ui-text ui-kit-color-text': isDarkMode }">
              {{ modalData.button.text }}
            </span>
          </VBtn>
        </div>
      </div>
    </ModalLayout>
  </VDialog>
</template>

<script>
import AwardModalLogic from 'components/common/Award/mixins/AwardModalLogic'

export default {
  name: 'AwardModal',
  mixins: [
    AwardModalLogic,
  ],
  data: () => ({
    blockName: 'award-modal-desktop',
    blockDarkName: 'award-modal-desktop_dark',
  }),
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.award-modal-desktop {
  &_dark {
    .modal,
    .modal__header {
      background-color: $ui-kit-text !important;
      color: $ui-kit-bg-gray-0;
    }

    .modal__header-button .v-icon {
      color: $ui-kit-bg-gray-0 !important;
      caret-color: $ui-kit-bg-gray-0 !important;
    }

    .modal__header .ui-text_h6 {
      color: $ui-kit-bg-gray-0;
    }

    .modal__header-divider {
      background-color: $ui-kit-text-secondary;
    }
  }
}
</style>
