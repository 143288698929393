<template>
  <VApp
    v-if="text"
    data-mark-of-shame
  >
    <BaseAlert
      v-if="hasBackground"
      type="red"
      icon="ui-icon-circle-info"
    >
      <div
        class="ui-text ui-text_body-2"
        v-text="text"
      />
    </BaseAlert>
    <div
      v-else
      class="d-flex align-center"
    >
      <div class="ui-icon-circle-info ui-kit-color-text-error mr-2" />
      <div
        class="ui-text ui-text_body-2 ui-kit-color-text-error"
        v-text="text"
      />
    </div>
  </VApp>
</template>

<script setup>
import BaseAlert from 'components/common/core/BaseAlert'
import { ALERT_TEXT_DATA } from 'components/common/MarkOfShame/constants'

const props = defineProps({
  type: {
    type: String,
    default: '',
  },
  hasBackground: {
    type: Boolean,
    default: false,
  },
})

const text = ALERT_TEXT_DATA[props.type] || ''
</script>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app[data-mark-of-shame] {
  @include v-app-reset;
}
</style>
