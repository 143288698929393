(async () => {
  const mountElement = document.querySelector('.vue-doctor-service-prices-mount')

  if (!mountElement) {
    return
  }

  const { default: Vue } = await import(/* webpackMode: "eager" */'components')
  const { default: vuetify } = await import(/* webpackMode: "eager" */'modules/vuetify-plugin-without-reset-scss')
  const { default: DoctorServicePrices } = await import(/* webpackMode: "eager" */'components/common/DoctorServicePrices/DoctorServicePrices')

  new Vue({
    vuetify,
    el: mountElement,
    name: 'DoctorServicePricesRoot',
    components: {
      DoctorServicePrices,
    },
  })
})()
