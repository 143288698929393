<template>
  <div
    data-qa="doctor-price-block-item-list"
    class="pb-3"
    :class="[isClubItemVisible ? 'pt-5' : 'pt-4']"
    :data-club-switch-block="clubSwitchState"
    :data-active-tab-value="redirectValue"
  >
    <div class="d-flex">
      <DoctorPriceBlockItem
        v-if="isClubItemVisible"
        class="mr-2"
        subtitle="Клубная цена"
        :price="clubPrice"
        :active="isClubItemActive"
        club
        @click.native="changeClubState(true)"
      />
      <DoctorPriceBlockItem
        :price="price"
        :subtitle="price ? priceSubtitle : undefined"
        :active="!isClubItemActive || !isClubItemVisible"
        :price-prefix="noPrefix ? false : pageType !== 'clinic'"
        @click.native="changeClubState(false)"
      />
    </div>
    <template v-if="isClubItemVisible">
      <div
        class="d-flex align-center ui-kit-color-error cursor-pointer mt-3"
        @click="handleClickShowClubInfoModal"
      >
        <img
          src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
          alt="Иконка Процент Клуба"
          width="20"
          height="20"
        >
        <div class="ui-text ui-text_body-2 ui-text_underline ml-2">
          {{ clubInfoText }}
        </div>
      </div>
      <ClubDiscountInfoBottomSheet
        v-model="isClubInfoVisible"
        :attach="false"
        :full-price="price"
        :club-offer-data="clubOfferData"
      />
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from 'vue'
import { getFormattedPrice } from 'utils'
import { REDIRECT_VALUES } from 'constants/redirectValues'
import { DoctorPriceBlockItem } from 'components/common/DoctorPriceBlock/components'
import { APPOINTMENT_TYPES } from 'constants/appointmentTypes'
/* @ts-ignore */
import ClubDiscountInfoBottomSheet from 'components/common/Club/components/core/ClubDiscount/components/common/ClubDiscountInfoBottomSheet'
import { CLUB_DISCOUNT } from 'components/common/Club/constants'

const props = defineProps<{
  pageType: 'clinic' | 'telemed' | 'home'
  price?: number | null
  clubPrice?: number | null
  noPrefix?: boolean
}>()

const emit = defineEmits(['update:redirect-value'])

const isClubInfoVisible = ref(false)
const isClubItemVisible = computed(() => props.pageType === 'clinic' && !!props.clubPrice)
const isClubItemActive = ref(isClubItemVisible.value)

const clubSwitchState = computed(() => {
  if (props.pageType !== 'clinic') {
    return undefined
  }

  return isClubItemActive.value ? APPOINTMENT_TYPES.club : APPOINTMENT_TYPES.lpu
})

const clubInfoText = computed(() => {
  if (!props.price || !props.clubPrice) {
    return ''
  }

  return isClubItemActive.value
    ? 'Как работает клубная цена'
    : `Клубная цена ниже на ${getFormattedPrice(props.price - props.clubPrice)}`
})

const clubOfferData = computed(() => {
  if (typeof props.clubPrice !== 'number') {
    return {}
  }

  const couponPrice = Math.floor(props.clubPrice / 2)
  const cardPrice = window.USER.club.isInClub ? 0 : window.USER.club.cardNextPrice

  return {
    lpuPrice: props.clubPrice - couponPrice,
    couponPrice,
    cardDateEnd: window.USER.club.cardDateEnd,
    cardPrice,
    totalPrice: props.clubPrice + (cardPrice || 0),
    clubDiscount: CLUB_DISCOUNT,
  }
})

const priceSubtitle = computed(() => {
  switch (props.pageType) {
    case 'clinic':
      return 'Оплата в клинике'
    case 'telemed':
      return 'Оплата на сайте'
  }

  return ''
})

const redirectValue = computed<typeof REDIRECT_VALUES[keyof typeof REDIRECT_VALUES]>(() => {
  if (isClubItemActive.value && isClubItemVisible.value) {
    return REDIRECT_VALUES.club
  }

  if (props.pageType === 'clinic') {
    return REDIRECT_VALUES.default
  }

  return REDIRECT_VALUES[props.pageType]
})

watch(redirectValue, () => {
  emit('update:redirect-value', redirectValue.value)
})

emit('update:redirect-value', redirectValue.value)

function changeClubState(state: boolean) {
  isClubItemActive.value = state
}

function handleClickShowClubInfoModal() {
  isClubInfoVisible.value = true
}
</script>
