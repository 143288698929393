var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "mtlink-banner mtlink-banner_js_open-modal",
        class: [
          _vm.classNames.triggerOpenPopup,
          { "mtlink-banner_box-shadow": _vm.hasBoxShadow },
        ],
      },
      [
        _c("img", {
          staticClass: "mtlink-banner__medtochka-logo mr-8",
          attrs: {
            src: "/static/_v1/pd/logos/ui-kit/medtochka-logo.svg",
            alt: "Логотип МедТочки",
          },
        }),
        _vm._v(" "),
        _c("div", {
          staticClass: "ui-text ui-text_body-2 mr-3",
          domProps: { textContent: _vm._s(_vm.text) },
        }),
        _vm._v(" "),
        _c("span", { staticClass: "ui-icon-circle-info ml-auto" }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }