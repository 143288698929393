var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasOptions
    ? _c(
        "div",
        [
          _c(
            "div",
            { on: { click: _vm.switchVisible } },
            [
              _c("select-module-button", {
                attrs: {
                  title: _vm.buttonTitle,
                  subtitle: _vm.buttonSubtitle,
                  "is-disabled": _vm.isDisabledButton,
                  "button-block": _vm.buttonBlock,
                  "max-width": _vm.maxWidth,
                  "button-class-list": _vm.buttonClassList,
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-bottom-sheet",
            {
              attrs: {
                "overlay-opacity": _vm.overlayOpacity,
                attach: _vm.customOverlayAttach,
                scrollable: "",
              },
              on: { "click:outside": _vm.handleBottomSheetClose },
              model: {
                value: _vm.isVisible,
                callback: function ($$v) {
                  _vm.isVisible = $$v
                },
                expression: "isVisible",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { "max-height": _vm.maxHeightSheet } },
                [
                  _c(
                    "v-card-text",
                    { staticClass: "p-0" },
                    _vm._l(_vm.options, function (option, index) {
                      return _c(
                        "v-list-item",
                        {
                          key: `bottom-sheet-option-${index}`,
                          staticClass: "px-2 py-1",
                          attrs: { disabled: !!option.disabled },
                          on: {
                            click: function ($event) {
                              return _vm.selectOption({
                                index,
                                option,
                              })
                            },
                          },
                        },
                        [
                          _c(
                            "v-list-item-content",
                            { staticClass: "p-0" },
                            [
                              _c(
                                "v-list-item-title",
                                [
                                  _vm._t(
                                    "select-option-title",
                                    function () {
                                      return [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(option.title) +
                                            "\n              "
                                        ),
                                      ]
                                    },
                                    { title: option.title }
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              option.subtitle
                                ? _c("v-list-item-subtitle", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(option.subtitle) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._t("select-option-icon", null, {
                            option: option,
                            isActive: _vm.isActiveOptionIndex(index),
                          }),
                        ],
                        2
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }