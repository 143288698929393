var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-empty",
      attrs: { "data-qa": "service_synonym_search_empty" },
    },
    [
      _c("VImg", {
        staticClass: "search-empty__image",
        attrs: {
          contain: "",
          width: "150",
          height: "150",
          src: "/static/_v1/pd/pictures/ui-kit/search.svg",
          alt: "Иконка поиска",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "ui-text ui-text_body-1 ui-kit-color-text" }, [
        _vm._v("\n    Такой услуги нет в списке\n  "),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }