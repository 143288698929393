var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.lpuAddressList.length
    ? _c("VApp", { attrs: { "data-show-btn-fixed": "" } }, [
        _c(
          "div",
          { class: { "pa-2": _setup.externalDataStore.isMobile } },
          [
            _c(_setup.DoctorPageLpuListHeader),
            _vm._v(" "),
            _c(_setup.DoctorPageLpuListBody),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }