var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    { attrs: { "data-patient-profile-modal": "" } },
    [
      _c(_vm.modal, {
        tag: "component",
        attrs: { "is-visible": _vm.isVisible, "patient-data": _vm.patientData },
        on: { "modal:close": _vm.handleCloseModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }