var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.doctorVideoUrl
    ? _c(
        "VApp",
        { attrs: { "data-video-card": "" } },
        [
          _c(
            "VSheet",
            {
              staticClass: "rounded-pill overflow-hidden cursor-pointer",
              attrs: { "data-qa": "doctor_video_card_play_color" },
            },
            [
              _c("VImg", {
                directives: [{ name: "ripple", rawName: "v-ripple" }],
                attrs: {
                  width: "32",
                  height: "32",
                  src: "/static/_v1/pd/icons/ui-kit/video-card/video-card-play.svg",
                  alt: "Открыть видеовизитку",
                },
                on: { click: _vm.handleOpenDialog },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "VDialog",
            {
              attrs: {
                "data-qa": "doctor_video_card_dialog",
                fullscreen: _vm.isMobile,
                width: !_vm.isMobile ? "456px" : "",
              },
              on: { "click:outside": _vm.handleCloseDialog },
              model: {
                value: _vm.isDialogVisible,
                callback: function ($$v) {
                  _vm.isDialogVisible = $$v
                },
                expression: "isDialogVisible",
              },
            },
            [
              !_vm.isMobile
                ? _c(
                    "ModalLayout",
                    {
                      staticClass: "height-inherit",
                      attrs: {
                        "close-to": "",
                        title: "Видеовизитка",
                        "hide-overflow-y": "",
                        "body-height-inherit": "",
                        "body-flex-basis-auto": "",
                      },
                      on: { "modal:back": _vm.handleCloseDialog },
                    },
                    [
                      _c("div", { staticClass: "height-inherit p-6" }, [
                        _c("video", {
                          ref: "video",
                          staticClass: "video-card-player",
                          attrs: { controls: "", src: _vm.doctorVideoUrl },
                          on: { ended: _vm.handleEnded },
                        }),
                      ]),
                    ]
                  )
                : _c(
                    "div",
                    {
                      staticClass: "video-card-wrapper ui-kit-bg-text",
                      attrs: { "data-qa": "doctor_video_card_dialog" },
                    },
                    [
                      _c("div", {
                        staticClass:
                          "video-card-player-close ui-icon ui-icon_reset ui-kit-color-bg-gray-0 ui-icon-close-not-a-circle",
                        on: { click: _vm.handleCloseDialog },
                      }),
                      _vm._v(" "),
                      _c("div", {
                        staticClass: "video-card-switch-state",
                        on: { click: _vm.videoSwitchState },
                      }),
                      _vm._v(" "),
                      !_vm.isVideoPlaying
                        ? _c("div", { staticClass: "video-card-play" }, [
                            _c("div", {
                              staticClass:
                                "ui-icon ui-icon_reset ui-kit-color-primary ui-icon-player-play",
                            }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.videoTimeTotal && _vm.videoTimeCurrent
                        ? _c("div", { staticClass: "video-card-time-cover" }, [
                            _c("div", { staticClass: "video-card-time" }, [
                              _c("div", {
                                staticClass:
                                  "ui-text ui-text_body-1 ui-kit-color-bg-gray-0",
                                domProps: {
                                  textContent: _vm._s(_vm.videoTimeCurrent),
                                },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "ui-text ui-text_body-1 ui-kit-color-bg-gray-60 px-1",
                                domProps: { textContent: _vm._s("/") },
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass:
                                  "ui-text ui-text_body-1 ui-kit-color-bg-gray-60",
                                domProps: {
                                  textContent: _vm._s(_vm.videoTimeTotal),
                                },
                              }),
                            ]),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("video", {
                        ref: "video",
                        staticClass: "video-card-player",
                        attrs: { playsinline: "", src: _vm.doctorVideoUrl },
                        on: {
                          ended: _vm.handleEnded,
                          timeupdate: _vm.handleTimeupdate,
                          loadedmetadata: _vm.handleLoadedmetadata,
                        },
                      }),
                    ]
                  ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }