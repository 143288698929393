import { dispatchCustomEventGlobally } from 'utils'

/**
 * Миксин для возможности исправить баг с оверлеем при использовании BottomSheet в кастомной модалке
 */
export default {
  props: {
    customOverlayAttach: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  computed: {
    overlayOpacity() {
      return this.customOverlayAttach ? 0 : undefined
    },
  },
  methods: {
    handleBottomSheetClose() {
      dispatchCustomEventGlobally('sm-bottom-sheet:closed')
    },
    handleBottomSheetOpen() {
      dispatchCustomEventGlobally('sm-bottom-sheet:opened')
    },
  },
}
