var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "ui-text ui-text_body-2 ui-kit-color-text-secondary text-center",
      class: {
        "px-8": _vm.isMobile && _vm.appointmentObjectTypes.isServices,
        "px-4": _vm.isMobile && _vm.appointmentObjectTypes.isDoctor,
      },
      attrs: { "data-qa": "appointment_form_personal_data" },
    },
    [
      _vm._v(
        "\n  Записываясь на " +
          _vm._s(
            _vm.appointmentObjectTypes.isServices ? "диагностику" : "приём"
          ) +
          ", вы даете согласие на\n  "
      ),
      _c(
        "a",
        {
          staticClass:
            "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
          attrs: { target: "_blank", href: "/info/privacy-policy/" },
        },
        [_vm._v("\n    обработку персональных данных\n  ")]
      ),
      _vm._v("\n  и принимаете\n  "),
      _c(
        "a",
        {
          staticClass:
            "b-link b-link_underline_hover b-link_color_neur-blue d-inline",
          attrs: { target: "_blank", href: "/info/terms-of-use/" },
        },
        [_vm._v("\n    пользовательское соглашение\n  ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }