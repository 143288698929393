import { axiosClient, getCSRFToken } from 'utils'

function sendClubData({ data, couponRequestId } = {}) {
  return new Promise((resolve, reject) => {
    axiosClient({
      data,
      method: 'POST',
      url: `/club/ajax/coupon_request/${couponRequestId}/online_payment/`,
      headers: {
        'X-CSRFToken': getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest',
      },
    })
      .then(response => resolve(response.data))
      .catch(({ response }) => reject(response?.data || {})) // eslint-disable-line prefer-promise-reject-errors
  })
}

export default sendClubData
