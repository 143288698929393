<template>
  <div
    class="my-3"
    :class="{
      'appointment-tab-list': true,
      'appointment-tab-list_mobile': isMobile,
      'appointment-tab-list_desktop': !isMobile,
    }"
    :data-active-tab-value="redirectValue"
  >
    <ClubDiscountSwitch
      v-if="isExistClubTab"
      class="appointment-tab-list__switch mb-3"
      title="Клубная цена"
      use-global-dispatch
      :discount="30"
      :enabled="isClubSwitchEnabled"
      @club-discount-switch:click="handleClickSwitch"
    />
    <div class="appointment-tab-list__inner">
      <AppointmentTabItem
        v-for="(tabData, index) in tabsDataArray"
        ref="tabRefs"
        :key="index"
        :is-in-club="tabData.isInClub"
        :is-club-switch-enabled="isClubSwitchEnabled"
        :is-active-tab="tabData.isActiveTab"
        :price-list="formatPriceList(tabData)"
        :description="tabData.description"
        :data-qa="tabData.dataQA"
        @click="handleClickTab({ tabData, event: $event })"
      />
    </div>
    <div
      v-if="isVisibleActivatorText"
      class="d-inline-flex align-items-center ui-kit-color-error cursor-pointer py-1"
      @click="handleClickShowClubInfoModal"
    >
      <VImg
        src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
        alt="Иконка Процент Клуба"
        width="20"
        height="20"
        class="mr-2"
      />
      <span class="ui-text ui-text_body-2 ui-text_underline">
        {{ activatorText }}
      </span>
    </div>
    <ClubDiscountInfoBottomSheet
      v-if="isVisibleActivatorText"
      v-model="isClubInfoVisible"
      :full-price="clubTabData.priceList.price"
      :club-offer-data="clubOfferData"
    />
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import { dispatchCustomEventGlobally, getFormattedPrice } from 'utils'
import { ClubEventsNames } from 'clubCommonMixins'
import { REDIRECT_VALUES } from 'www/constants'
import { getTabsData } from 'components/common/AppointmentTabList/interfaces'
import AppointmentTabItem from 'components/common/AppointmentTabList/AppointmentTabItem'
import { CLUB_DISCOUNT } from 'components/common/Club/constants'
import ClubDiscountSwitch from 'components/common/Club/components/core/ClubDiscount/components/common/ClubDiscountSwitch'
import ClubDiscountInfoBottomSheet from 'components/common/Club/components/core/ClubDiscount/components/common/ClubDiscountInfoBottomSheet'

export default {
  vuetify,
  name: 'AppointmentTabList',
  components: {
    ClubDiscountInfoBottomSheet,
    AppointmentTabItem,
    ClubDiscountSwitch,
  },
  mixins: [
    ClubEventsNames,
  ],
  props: {
    tabsData: {
      type: Object,
      default: getTabsData,
    },
  },
  data: () => ({
    isClubSwitchEnabled: true,
    isMobile: window.MOBILE_VERSION,
    isClubInfoVisible: false,
    redirectValue: null,
    tabsDataArray: [],
  }),
  computed: {
    clubTabData() {
      return this.tabsDataArray.find(tab => tab.isInClub)
    },
    activatorText() {
      const prefixText = this.isClubSwitchEnabled ? 'Цена' : this.clubTabData.priceList.priceClub

      return `${prefixText} при оплате невозвратного купона`
    },
    isVisibleActivatorText() {
      return this.isExistClubTab
                && [REDIRECT_VALUES.club, REDIRECT_VALUES.default].includes(this.redirectValue)
    },
    isExistClubTab() {
      return !!this.clubTabData
    },
    clubOfferData() {
      const clubPrice = this.clubTabData.priceList.priceClub
      const couponPrice = Math.floor(clubPrice / 2)
      const cardPrice = window.USER.club.isInClub ? 0 : window.USER.club.cardNextPrice

      return {
        lpuPrice: clubPrice - couponPrice,
        couponPrice,
        cardDateEnd: window.USER.club.cardDateEnd,
        cardPrice,
        totalPrice: clubPrice + cardPrice,
        clubDiscount: CLUB_DISCOUNT,
      }
    },
  },
  watch: {
    isClubSwitchEnabled: {
      immediate: true,
      handler(isEnabled) {
        this.$emit('change-club-switch-enabled', isEnabled)
        this.updateClubTabRedirectValue(isEnabled)
      },
    },
    tabsData(newTabsData) {
      this.tabsDataArray = this.getTabsDataArray(newTabsData)

      if (this.tabsDataArray.length) {
        this.setDefaultActiveTab()
      }

      this.updateClubTabRedirectValue(this.isClubSwitchEnabled)
    },
  },
  created() {
    this.tabsDataArray = this.getTabsDataArray()

    if (this.tabsDataArray.length) {
      this.setDefaultActiveTab()
    }

    window.addEventListener('club-discount-switch:click', this.handleClubDiscountSwitchClick)
  },
  beforeDestroy() {
    window.removeEventListener('club-discount-switch:click', this.handleClubDiscountSwitchClick)
  },
  methods: {
    formatPriceList(tabData) {
      return Object.fromEntries(
        Object.entries(tabData.priceList).map(
          ([key, value]) => [key, getFormattedPrice(value, { prefix: tabData.isInClub || !value ? '' : 'от' })],
        ),
      )
    },
    handleClickSwitch(isEnabled) {
      this.isClubSwitchEnabled = isEnabled

      dispatchCustomEventGlobally('club-discount-switch:change-state', {
        isEnabled,
      })
    },
    handleClubDiscountSwitchClick({ detail }) {
      this.isClubSwitchEnabled = detail.isEnabled
    },
    handleClickShowClubInfoModal() {
      this.isClubInfoVisible = true
    },
    updateClubTabRedirectValue(isClubSwitchEnabled) {
      /**
       * При переключении isClubSwitchEnabled меняется redirectValue у клубного таба.
       * Необходимо для того, чтобы его правильно обрабатывали слоты при переходе в форму записи.
       * */
      this.$nextTick(() => {
        const clubTabIndex = this.tabsDataArray.findIndex(tabData => tabData.isInClub)

        if (clubTabIndex === -1) {
          return
        }

        const clubTab = this.tabsDataArray[clubTabIndex]

        clubTab.redirectValue = isClubSwitchEnabled ? REDIRECT_VALUES.club : REDIRECT_VALUES.default

        if (clubTab.isActiveTab) {
          this.redirectValue = clubTab.redirectValue
        }
      })
    },
    getTabsDataArray(tabsData = this.tabsData) {
      let tabsList = Object.values(tabsData)

      // Отбрасываются табы которые не нужно выводить
      tabsList = tabsList.filter(this.isDefinePrice)

      // Производится сортировка табов
      // (активный таб должен быть первым, остальные по порядку)
      tabsList.sort((a, b) => {
        if (a.isActiveTab) {
          return -1
        }

        return a.sortPosition - b.sortPosition
      })

      return tabsList
    },
    isDefinePrice({ priceList }) {
      return typeof priceList?.price === 'number'
    },
    handleClickTab({ tabData, event }) {
      if (tabData.isActiveTab) {
        return
      }

      this.prevRedirectValue = this.redirectValue
      this.resetActiveTab()

      tabData.isActiveTab = true

      this.redirectValue = tabData.redirectValue

      this.$emit('change-tab', tabData)
      dispatchCustomEventGlobally(this.eventClubAppointmentTypeSelected, {
        prevRedirectValue: this.prevRedirectValue,
        redirectValue: this.redirectValue,
        target: event.target,
      })
    },
    resetActiveTab() {
      const activeTab = this.tabsDataArray.find(tabData => tabData.isActiveTab)

      if (!activeTab) {
        return
      }

      activeTab.isActiveTab = false
    },
    setDefaultActiveTab() {
      const targetTabSelected = this.$refs.tabRefs?.find(tabRef => tabRef.isActiveTab)?.$el
      const activeOrFirstTab = this.tabsDataArray.find(tabData => tabData.isActiveTab) || this.tabsDataArray[0]

      activeOrFirstTab.isActiveTab = true
      this.prevRedirectValue = this.redirectValue
      this.redirectValue = activeOrFirstTab.redirectValue

      if (targetTabSelected) {
        dispatchCustomEventGlobally(this.eventClubAppointmentTypeSelected, {
          prevRedirectValue: this.prevRedirectValue,
          redirectValue: this.redirectValue,
          target: targetTabSelected,
        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.appointment-tab-list {
  $block: &;

  &_mobile {
    #{$block}__inner {
      display: flex;
      overflow: auto hidden;
      margin-bottom: 8px;
      -webkit-overflow-scrolling: touch;
    }

    #{$block}__inner::-webkit-scrollbar {
      background-color: transparent;
    }
  }

  &_desktop {
    #{$block}__switch {
      max-width: 460px;
    }

    #{$block}__inner {
      display: flex;
      overflow: auto hidden;
      padding-bottom: 8px;
      border-color: $ui-kit-bg-gray-60;
      transition: border-color 0.1s ease-in;
      max-width: 500px;

      &:hover {
        border-color: $ui-kit-bg-gray-80;
      }
    }

    #{$block}__inner::-webkit-scrollbar-thumb {
      border-radius: $border-radius-sm;
      border-right-width: calc(100vw + 100vh);
      border-right-style: inset;
      border-color: inherit;
    }

    #{$block}__inner::-webkit-scrollbar {
      height: 8px;
      border-radius: $border-radius-sm;
      background-color: $ui-kit-bg-gray-40;
    }
  }
}
</style>
