import YaGoals from 'modules/YandexGoals'
import { CLUB_YANDEX_GOALS } from 'yandexGoals/index'

const ClubInfoModalLogic = {
  props: {
    pdfData: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    componentName: 'ClubInfoModal',
    nowYear: window.NOW_YEAR,
    isMobileDevice: window.MOBILE_VERSION,
    instanceYaGoals: new YaGoals(),
    percentIcon: {
      alt: 'Иконка Процент Клуба',
      src: '/static/_v1/pd/icons/ui-kit/club/club-percent.svg',
    },
    sideMenuData: {
      title: window.FEATURE_FLAGS.pd_club_components_update ? 'Клуб ПроДокторов' : 'Клубная карта ПроДокторов',
      contentWidth: '667px',
      mainImagePath: '/static/_v1/pd/pictures/ui-kit/club.svg',
    },
  }),
  computed: {
    vuetifyIcons() {
      return { ...this.$vuetify.icons.values }
    },
    vuetifyColors() {
      return { ...this.$vuetify.theme.themes.light }
    },
  },
  methods: {
    handleDialogClose() {
      this.closeAllDialogs()
    },
    handleSelectInfoModal() {
      this.openDialog(this.componentName)

      if (!this.instanceYaGoals) {
        return
      }

      const { infoModalGlobal } = CLUB_YANDEX_GOALS

      this.instanceYaGoals.send({
        name: infoModalGlobal,
      })
    },
  },
}

export default ClubInfoModalLogic
