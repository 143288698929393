var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mtlink-slider b-swiper" }, [
    _c(
      "div",
      { staticClass: "mtlink-slider__track b-swiper__track" },
      _vm._l(_vm.slides, function (slide) {
        return _c("div", { key: slide.title, staticClass: "b-swiper__slide" }, [
          _c("div", { staticClass: "mtlink-slider__image-container" }, [
            _c("img", {
              staticClass: "mtlink-slider__image",
              attrs: { src: slide.imgSrc, alt: slide.title },
            }),
          ]),
          _vm._v(" "),
          _c("div", {
            staticClass: "ui-text ui-text_h6 text-center",
            domProps: { innerHTML: _vm._s(slide.title) },
          }),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }