import { getListBenefits } from 'components/common/SoftVersionIcon/functions'
import { SOFT_VERSION, SOFT_VERSION_DATA } from 'components/common/SoftVersionIcon/constants'

const SoftVersionGlobalData = {
  props: {
    softVersion: {
      type: String,
      default: '',
    },
    isLpuPage: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isExistSoftVersion() {
      return Object.values(SOFT_VERSION).includes(this.softVersion)
    },
    listBenefits() {
      return getListBenefits({
        softVersion: this.softVersion,
        isLpuPage: this.isLpuPage,
      })
    },
    softData() {
      return SOFT_VERSION_DATA[this.softVersion] || {}
    },
  },
}

export default SoftVersionGlobalData
