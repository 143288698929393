<template>
  <div
    class="appointment-tab-item"
    :class="{
      'appointment-tab-item_club': isInClub && isClubSwitchEnabled,
      'appointment-tab-item_active': isActiveTab,
    }"
    @click="$emit('click', $event)"
  >
    <div class="appointment-tab-item__inner">
      <div class="appointment-tab-item__title ui-text ui-text_overline">
        {{ description }}
      </div>
      <div class="ui-text ui-text_nowrap">
        <template
          v-if="priceList.priceClub && isClubSwitchEnabled"
        >
          <span class="appointment-tab-item__price ui-text ui-text_subtitle-1">
            {{ priceList.priceClub }}
          </span>
          <span class="ui-text ui-text_body-2 ui-text_line-through ui-kit-color-text-secondary">
            {{ priceList.price }}
          </span>
        </template>
        <template
          v-else
        >
          <span class="appointment-tab-item__price ui-text ui-text_subtitle-1">
            {{ priceList.price }}
          </span>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppointmentTabItem',
  props: {
    isInClub: {
      type: Boolean,
      default: false,
    },
    isClubSwitchEnabled: {
      type: Boolean,
      default: false,
    },
    isActiveTab: {
      type: Boolean,
      default: false,
    },
    priceList: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.appointment-tab-item {
  $block: &;

  flex-shrink: 0;
  padding: 1px;
  min-width: 136px;
  color: $ui-kit-text;
  border-radius: $border-radius-md;
  background-color: $ui-kit-bg-gray-60;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 8px;
  }

  &_active {
    background-color: $ui-kit-primary;

    #{$block}__inner {
      background-color: $ui-kit-bg-secondary;
    }

    #{$block}__price {
      color: $ui-kit-primary;
    }
  }

  &_active#{&}_club {
    background-image: $club-coral-linear-gradient;

    #{$block}__inner {
      background-color: $ui-kit-bg-club;
    }

    #{$block}__title {
      color: $ui-kit-text;
    }
  }

  &_club &__price {
    color: $ui-kit-error;
  }

  &__inner {
    padding: 12px 16px;
    border-radius: inherit;
    background-color: $ui-kit-bg-gray-0;
  }
}
</style>
