import { axiosClient } from 'utils'
import { PATIENT_PROFILE_API_PATH } from 'components/common/PatientProfile/constants'

function getPatientData({ patientId, patientMobileId }) {
  return new Promise((resolve, reject) => {
    axiosClient.get(PATIENT_PROFILE_API_PATH, {
      params: {
        patient_id: patientId,
        patient_mobile_id: patientMobileId,
      },
    })
      .then(res => resolve(res.data))
      .catch(reject)
  })
}

export default getPatientData
