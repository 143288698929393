<template>
  <div>
    <img
      height="165"
      width="302"
      class="d-block mb-4 mx-auto"
      :src="modalData.image.src"
      :alt="modalData.image.alt"
    >
    <div
      v-for="(event, index) in modalData.events"
      :key="event.date"
      :class="{ 'mb-6': index !== modalData.events.length - 1 }"
    >
      <div class="ui-text ui-text_subtitle-1 mb-1">
        {{ event.title }}
      </div>
      <div class="d-flex align-items-center mb-4">
        <div
          class="ui-text mr-2"
          :class="{
            'ui-text_color_gold': isDarkMode,
            'ui-kit-color-text-secondary': !isDarkMode,
            'ui-icon-check-circle': event.isIconCheckCircle,
            'ui-icon-clock': !event.isIconCheckCircle,
          }"
        />
        <div class="ui-text ui-text_body-1">
          {{ event.date }}
        </div>
      </div>
      <p
        v-for="(paragraph, i) in event.paragraphs"
        :key="i"
        class="ui-text ui-text_body-1 mb-4"
      >
        {{ paragraph }}
      </p>
    </div>
  </div>
</template>

<script>
import AwardCommonData from 'components/common/Award/mixins/AwardCommonData'

export default {
  name: 'AwardModalContent',
  mixins: [
    AwardCommonData,
  ],
  props: {
    modalData: {
      type: Object,
      default: () => ({}),
    },
    isDarkMode: {
      type: Boolean,
      default: false,
    },
  },
}
</script>
