/**
 * @description
 * Иные модели, которые используются в модулях.
 * */

export const getModuleOptionsInterface = () => ({ // базовые опции настройки модуля 'Schedule'
  useLogger: false, // использовать логирование причины ошибки во время валидации
  pageType: null, // одно из полей 'PAGE_TYPE_DATA'. Не используется для настройки модуля. Определяется внутри класса перед вызовом функции 'super' в 'constructor'
  doctorPageType: null, // используется для класса 'DoctorCardEmitter', задает используемое пространство имен
  dataItem: 'data-schedule-item', // data атрибут на странице(1 и более на странице), который хранит в себе данные для модуля 'Schedule'
  dataStorage: 'data-schedule-storage', // data атрибут на странице(только 1), который хранит в себе общие данные для модуля 'Schedule'
  selectorContainersSlots: null, // селектор контейнера, в котором необходимо отрендерить календарь
  selectorContainerObserver: null, // селектор контейнера, который впоследствии будет мутировать, тем самым триггерить модуль отрисовывать календари
})

export const getModuleDataInterface = () => ({ // данные, которые содержит в себе модуль 'ScheduleData'
  items: new Map(), // данные, собранные со страницы из атрибута 'data-schedule-item'
  storage: {}, // данные, собранные со страницы из атрибута 'data-schedule-storage'
  instances: new Map(), // данные для хранения связки узел => компонент, который был отрисован
  request: {}, // данные, которые будут отправляться за получением расписания
  render: new Map(), // данные, которые будут использованы для рендера компонентов
})
