import { defineStore } from 'pinia'
import { format, parse, isEqual } from 'date-fns'
import { ru } from 'date-fns/locale'
import {
  createDevNotice,
  deleteQueryParam,
  isNetworkDisconnect,
  setQueryParam,
} from 'utils'
import {
  getInitConsultingDoctorsData,
  getSelectedDoctorConsultation,
  getInitDoctorsConsultationsData,
  getSelectedConsultingDoctor,
  getDoctorsAndConsultationsData,
} from 'components/common/AppointmentPage/interfaces'
import { APPOINTMENT_ERRORS } from 'components/common/AppointmentPage/constants'
import { getDoctorsAndConsultations } from 'components/common/AppointmentPage/api'
import getIdFromQuery from 'components/common/AppointmentPage/functions/getIdFromQuery'
import useLpuDataStore from 'components/common/AppointmentPage/stores/useLpuDataStore'
import useGlobalDataStore from 'components/common/AppointmentPage/stores/useGlobalDataStore'
// eslint-disable-next-line import/no-cycle
import useCalendarDataStore from 'components/common/AppointmentPage/stores/useCalendarDataStore'
// eslint-disable-next-line import/no-cycle
import useAppointmentDataStore from 'components/common/AppointmentPage/stores/useAppointmentDataStore'

const usePreliminaryConsultationStore = defineStore('preliminaryConsultationStore', {
  state: () => ({
    isPreliminaryConsultation: false,
    initConsultingDoctorsData: getInitConsultingDoctorsData(),
    initDoctorsConsultationsData: getInitDoctorsConsultationsData(),
    selectedConsultingDoctor: getSelectedConsultingDoctor(),
    selectedDoctorConsultation: getSelectedDoctorConsultation(),
    doctorsAndConsultations: getDoctorsAndConsultationsData(),
    isLoadingDoctorsAndConsultations: true,
    countRequestsDoctorsAndConsultations: 0,
    isFirstSelectDoctorConsultation: true,
    isIgnoreErrorConsultationNotAvailable: false,
  }),
  getters: {
    isSelectedConsultingDoctor() {
      return !!this.selectedConsultingDoctor.nearestAppointmentDateString
    },
    isSelectedDoctorConsultation() {
      return !!this.selectedDoctorConsultation.nearestAppointmentDateString
    },
    isEmptyDoctorsAndConsultations() {
      return !this.doctorsAndConsultations.length
    },
    useServiceExpressWithoutConsultation() {
      const calendarDataStore = useCalendarDataStore()
      const appointmentDataStore = useAppointmentDataStore()
      const isIgnoreEmptyConsultingDoctors = this.isEmptyDoctorsAndConsultations
        && this.countRequestsDoctorsAndConsultations === 1
        && !this.isLoadingDoctorsAndConsultations
      const isIgnoreEmptySlots = calendarDataStore.isEmptyCalendarList
        && (
          !calendarDataStore.isZeroRequests
          || !calendarDataStore.hasSlotsRequestData
        )
        && !this.countRequestsDoctorsAndConsultations

      return appointmentDataStore.appointmentObjectTypes.isServices
        && (isIgnoreEmptyConsultingDoctors || isIgnoreEmptySlots)
    },
    isShowPreliminaryConsultationInfo() {
      return this.isPreliminaryConsultation
        && !this.useServiceExpressWithoutConsultation
    },
  },
  actions: {
    updateSelectedConsultingDoctor(payload) {
      this.selectedConsultingDoctor = payload
    },
    resetSelectedConsultingDoctor() {
      this.selectedConsultingDoctor = getSelectedConsultingDoctor()
    },
    updateSelectedDoctorConsultation(payload) {
      this.selectedDoctorConsultation = payload
    },
    resetSelectedDoctorConsultation() {
      this.selectedDoctorConsultation = getSelectedDoctorConsultation()
    },
    updateDoctorsAndConsultations(payload) {
      this.doctorsAndConsultations = payload
    },
    resetDoctorsAndConsultations() {
      this.doctorsAndConsultations = getDoctorsAndConsultationsData()
    },
    updateIsLoadingDoctorsAndConsultations(payload) {
      this.isLoadingDoctorsAndConsultations = payload
    },
    incCountRequestsDoctorsAndConsultations() {
      this.countRequestsDoctorsAndConsultations += 1
    },
    resetCountRequestsDoctorsAndConsultations() {
      this.countRequestsDoctorsAndConsultations = 0
    },
    updateIsFirstSelectDoctorConsultation(payload) {
      this.isFirstSelectDoctorConsultation = payload
    },
    updateIsIgnoreErrorConsultationNotAvailable(payload) {
      this.isIgnoreErrorConsultationNotAvailable = payload
    },

    async getFormattedDoctorsAndConsultationsResponse(doctorsAndConsultationsResponse) {
      const calendarDataStore = useCalendarDataStore()
      const now = new Date(calendarDataStore.initCalendarData.dateNow)

      return doctorsAndConsultationsResponse.map(({
        doctor_id: doctorId,
        consultations,
      }) => {
        const doctorConsultations = consultations.map(({
          service_id: consultationId,
          nearest_dt: nearestAppointmentDateString,
        }) => {
          const nearestAppointmentDate = parse(
            nearestAppointmentDateString,
            'yyyy-MM-dd HH:mm',
            now,
            { locale: ru },
          )
          const formattedNearestAppointmentDate = format(
            nearestAppointmentDate,
            'd\u00A0MMMM,\u00A0HH:mm',
            { locale: ru },
          )

          return {
            id: consultationId,
            nearestAppointmentDate,
            nearestAppointmentDateString,
            formattedNearestAppointmentDate,
          }
        })

        const [{
          nearestAppointmentDate,
          nearestAppointmentDateString,
          formattedNearestAppointmentDate,
        }] = doctorConsultations

        return {
          id: doctorId,
          consultations: doctorConsultations,
          nearestAppointmentDate,
          nearestAppointmentDateString,
          formattedNearestAppointmentDate,
        }
      })
    },
    async handleBadDoctorOrConsultation(selectedNearestAppointmentDate) {
      const globalDataStore = useGlobalDataStore()

      const errorDefaultData = {
        isDialogRender: true,
        isEnabled: true,
        isPersistent: true,
      }
      // Нужны только врачи, которые имеют выбранную консультацию.
      const satisfyingConsultingDoctors = this.doctorsAndConsultations.filter(
        ({ consultations }) => consultations.some(
          ({ id }) => id === this.selectedDoctorConsultation.id,
        ),
      )

      deleteQueryParam('consultingDoctor')
      deleteQueryParam('doctorConsultation')

      if (!satisfyingConsultingDoctors.length) {
        // Если врач всего 1, то оставляем его. Иначе, берём любого другого.
        const satisfyingConsultingDoctor = this.doctorsAndConsultations.length === 1
          ? this.doctorsAndConsultations[0]
          : this.doctorsAndConsultations.find(({ id }) => id !== this.selectedConsultingDoctor.id)
        const satisfyingConsultingDoctorData = this.initConsultingDoctorsData.find(
          ({ id }) => id === satisfyingConsultingDoctor.id,
        ) || {}
        const buttonSecondCallback = () => {
          setQueryParam('consultingDoctor', satisfyingConsultingDoctor.id || '')
          deleteQueryParam('doctorConsultation')
          this.updateSelectedConsultingDoctor({
            ...satisfyingConsultingDoctor,
            ...satisfyingConsultingDoctorData,
          })
          this.resetSelectedDoctorConsultation()
          this.updateIsLoadingDoctorsAndConsultations(false)
        }

        if (this.isIgnoreErrorConsultationNotAvailable) {
          buttonSecondCallback()
          this.updateIsIgnoreErrorConsultationNotAvailable(false)
        } else {
          globalDataStore.updateErrorData({
            ...errorDefaultData,
            ...APPOINTMENT_ERRORS.appointmentPreliminaryConsultationNotAvailable,
            buttonSecondCallback,
          })
        }

        return
      }

      // Поиск подходящего врача с консультацией, время которой совпадает с текущим выбранным временем.
      let satisfyingConsultingDoctor = satisfyingConsultingDoctors.find(
        ({ consultations }) => consultations.some(
          ({ nearestAppointmentDate }) => isEqual(
            nearestAppointmentDate,
            selectedNearestAppointmentDate,
          ),
        ),
      )

      /**
             * Если такого врача нет, то берём врача с консультацией,
             * время которой ближайшее к выбранному времени.
             * */
      if (!satisfyingConsultingDoctor) {
        const findConsultation = ({ consultations }) => consultations.find(
          ({ id }) => id === this.selectedDoctorConsultation.id,
        )

        satisfyingConsultingDoctors.sort((a, b) => {
          const consultationA = findConsultation(a)

          if (isEqual(consultationA.nearestAppointmentDate, selectedNearestAppointmentDate)) {
            return -1
          }

          const consultationB = findConsultation(b)
          const diffDateA = Math.abs(consultationA.nearestAppointmentDate - selectedNearestAppointmentDate)
          const diffDateB = Math.abs(consultationB.nearestAppointmentDate - selectedNearestAppointmentDate)

          if (diffDateA === diffDateB) {
            return consultationA.nearestAppointmentDate - consultationB.nearestAppointmentDate
          }

          return diffDateA - diffDateB
        });

        [satisfyingConsultingDoctor] = satisfyingConsultingDoctors
      }

      const satisfyingDoctorConsultation = satisfyingConsultingDoctor.consultations.find(
        ({ id }) => id === this.selectedDoctorConsultation.id,
      )
      const satisfyingConsultingDoctorData = this.initConsultingDoctorsData.find(
        ({ id }) => id === satisfyingConsultingDoctor.id,
      ) || {}

      setQueryParam('consultingDoctor', satisfyingConsultingDoctor.id || '')
      setQueryParam('doctorConsultation', satisfyingDoctorConsultation.id)
      this.updateSelectedConsultingDoctor({
        ...satisfyingConsultingDoctor,
        ...satisfyingConsultingDoctorData,
      })
      this.updateSelectedDoctorConsultation({
        ...this.selectedDoctorConsultation,
        ...satisfyingDoctorConsultation,
      })

      const buttonSecondCallback = () => {
        this.updateIsFirstSelectDoctorConsultation(false)
        this.updateIsLoadingDoctorsAndConsultations(false)
      }

      if (this.isIgnoreErrorConsultationNotAvailable) {
        buttonSecondCallback()
        this.updateIsIgnoreErrorConsultationNotAvailable(false)
      } else {
        globalDataStore.updateErrorData({
          ...errorDefaultData,
          ...APPOINTMENT_ERRORS.appointmentPreliminaryConsultationNotAvailable,
          buttonSecondCallback,
        })
      }
    },
    async updateSelectedFromUrl() {
      const calendarDataStore = useCalendarDataStore()

      if (
        this.isSelectedConsultingDoctor
        || this.isSelectedDoctorConsultation
        || !calendarDataStore.isCalendarDateSelected
      ) {
        return false
      }

      const consultingDoctorId = getIdFromQuery('consultingDoctor')
      const doctorConsultationId = getIdFromQuery('doctorConsultation')

      if (consultingDoctorId === undefined || doctorConsultationId === undefined) {
        return false
      }

      const consultingDoctor = this.doctorsAndConsultations.find(
        ({ id }) => id === consultingDoctorId,
      )

      if (!consultingDoctor) {
        return false
      }

      const doctorConsultation = consultingDoctor.consultations.find(
        ({ id, nearestAppointmentDate }) => (
          id === doctorConsultationId
          && isEqual(nearestAppointmentDate, calendarDataStore.selectedSlotDate)
        ),
      )

      if (!doctorConsultation) {
        return false
      }

      const consultingDoctorData = this.initConsultingDoctorsData.find(
        ({ id }) => id === consultingDoctorId,
      )
      const doctorConsultationData = this.initDoctorsConsultationsData.find(
        ({ id }) => id === doctorConsultationId,
      )

      this.updateSelectedConsultingDoctor({
        ...consultingDoctor,
        ...consultingDoctorData,
      })
      this.updateSelectedDoctorConsultation({
        ...doctorConsultation,
        ...doctorConsultationData,
      })
      this.updateIsFirstSelectDoctorConsultation(false)

      return true
    },
    async updateSelectedFromRequest() {
      let consultingDoctor
      let consultingDoctorData

      if (this.isSelectedConsultingDoctor) {
        consultingDoctor = this.doctorsAndConsultations.find(
          ({ id }) => id === this.selectedConsultingDoctor.id,
        )

        if (!consultingDoctor) {
          return false
        }

        consultingDoctorData = this.selectedConsultingDoctor
      } else {
        [consultingDoctor] = this.doctorsAndConsultations
        consultingDoctorData = this.initConsultingDoctorsData.find(
          ({ id }) => id === consultingDoctor.id,
        ) || {}
      }

      setQueryParam('consultingDoctor', consultingDoctor.id)
      this.updateSelectedConsultingDoctor({
        ...consultingDoctorData,
        ...consultingDoctor,
      })

      let doctorConsultation
      let doctorConsultationData

      if (this.isSelectedDoctorConsultation) {
        doctorConsultation = this.selectedConsultingDoctor.consultations.find(
          ({ id }) => id === this.selectedDoctorConsultation.id,
        )

        if (!doctorConsultation) {
          return false
        }

        doctorConsultationData = this.selectedDoctorConsultation
      } else {
        if (this.selectedConsultingDoctor.consultations.length > 1) {
          deleteQueryParam('doctorConsultation')
          return true
        }

        [doctorConsultation] = this.selectedConsultingDoctor.consultations
        doctorConsultationData = this.initDoctorsConsultationsData.find(
          ({ id }) => id === doctorConsultation.id,
        )
      }

      setQueryParam('doctorConsultation', doctorConsultation.id)
      this.updateSelectedDoctorConsultation({
        ...doctorConsultationData,
        ...doctorConsultation,
      })
      this.updateIsFirstSelectDoctorConsultation(false)

      return true
    },
    async requestDoctorsAndConsultations({ day, time } = {}) {
      if (!day || !time) {
        return
      }

      const lpuDataStore = useLpuDataStore()
      const globalDataStore = useGlobalDataStore()
      const calendarDataStore = useCalendarDataStore()

      this.updateIsLoadingDoctorsAndConsultations(true)
      this.incCountRequestsDoctorsAndConsultations()

      try {
        const errorDefaultData = {
          isDialogRender: true,
          isEnabled: true,
          isPersistent: true,
        }
        const appointmentDate = `${day} ${time}`
        let doctorsAndConsultationsResponse = await getDoctorsAndConsultations({
          lpuId: lpuDataStore.lpuData.id,
          synonymId: calendarDataStore.initCalendarData.synonymId,
          appointmentDate,
        })

        /**
         * Необходимо отфильтровать услуги, данных по которым нет в контексте,
         * т.к. заглушки для них нет.
         */
        doctorsAndConsultationsResponse.forEach(doctor => {
          doctor.consultations = doctor.consultations.filter(
            ({ service_id: consultationId }) => this.initDoctorsConsultationsData.some(
              ({ id }) => id === consultationId,
            ),
          )
        })
        doctorsAndConsultationsResponse = doctorsAndConsultationsResponse.filter(
          ({ consultations }) => consultations.length,
        )

        if (!doctorsAndConsultationsResponse.length) {
          this.resetDoctorsAndConsultations()
          this.resetSelectedDoctorConsultation()
          this.resetSelectedConsultingDoctor()
          deleteQueryParam('consultingDoctor')
          deleteQueryParam('doctorConsultation')

          if (this.countRequestsDoctorsAndConsultations > 1) {
            globalDataStore.updateErrorData({
              ...errorDefaultData,
              ...APPOINTMENT_ERRORS.slotsIsOver,
            })
          } else {
            this.updateIsLoadingDoctorsAndConsultations(false)
          }

          return
        }

        const doctorsAndConsultations = await this.getFormattedDoctorsAndConsultationsResponse(
          doctorsAndConsultationsResponse,
        )

        this.updateDoctorsAndConsultations(doctorsAndConsultations)

        const isSuccessUpdateFromUrl = await this.updateSelectedFromUrl()

        if (!isSuccessUpdateFromUrl) {
          deleteQueryParam('consultingDoctor')
          deleteQueryParam('doctorConsultation')

          const isCompleteUpdateSelectedFromRequest = await this.updateSelectedFromRequest()

          if (!isCompleteUpdateSelectedFromRequest) {
            await this.handleBadDoctorOrConsultation(new Date(`${day}T${time}`))
            return
          }
        }

        this.updateIsLoadingDoctorsAndConsultations(false)

        if (!this.isSelectedDoctorConsultation) {
          return
        }

        const { nearestAppointmentDateString } = this.selectedDoctorConsultation

        if (nearestAppointmentDateString !== appointmentDate) {
          calendarDataStore.resetSelectedDate()
          calendarDataStore.updateCalendarIsChanging(true)
          await calendarDataStore.requestForSlots({
            errorRequestCallback: () => {
              if (!this.useServiceExpressWithoutConsultation) {
                this.requestDoctorsAndConsultations({ day, time })
              }
            },
          })
        } else {
          calendarDataStore.updateSelectedDate(calendarDataStore.lastSelectedDate)
          calendarDataStore.updateCalendarIsChanging(false)
          calendarDataStore.updateIsErrorState(false)
        }
      } catch (error) {
        const { response, message } = error || {}
        const { status } = response || {}

        if (isNetworkDisconnect({ status, message })) {
          globalDataStore.updateErrorData({
            isEnabled: true,
            isDialogRender: true,
            ...APPOINTMENT_ERRORS.notNetwork,
          })
        } else if (!response && message) {
          createDevNotice({
            module: 'usePreliminaryConsultationStore',
            method: 'requestDoctorConsultationRelations',
            description: message,
          })
        }

        if (status === 403) {
          globalDataStore.updateErrorData({
            isEnabled: true,
            isDialogRender: true,
            isPersistent: true,
            ...APPOINTMENT_ERRORS.notAuthorization,
          })

          return
        }
      }

      this.updateIsLoadingDoctorsAndConsultations(false)
    },
  },
})

export default usePreliminaryConsultationStore
