import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import PatientProfile from 'components/common/PatientProfile/components/common/PatientProfile'
import { MOUNT_SELECTOR } from 'components/common/PatientProfile/constants'

const el = document.querySelector(MOUNT_SELECTOR)

if (el) {
  new Vue({
    el,
    vuetify,
    render: h => h(PatientProfile),
  })
}
