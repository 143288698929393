import { DATA_POPUP_CONTENT_TITLE } from 'www/modules/PopupContent/constants'

/**
 * Создает слайдер с фото в модальном окне со слайдером с фото.
 * @param {string} imagesContainerSelector Элемент-контейнер изображений для слайдера.
 * @returns {Function}
 */
export const getSliderRenderFn = imagesContainerSelector => ({ target, render }) => {
  if (!target) {
    return
  }

  const imagesContainer = target.closest(imagesContainerSelector)

  if (!imagesContainer) {
    return
  }

  /*
     * Если в элемент-контейнере изображений есть атрибут 'DATA_POPUP_CONTENT_TITLE',
     * добавляем его значение в свойство 'name' для каждого фото.
     * В результате получается "статичный" заголовок.
     */
  const popupContentTitle = imagesContainer.getAttribute(DATA_POPUP_CONTENT_TITLE) || ''

  let slideTo = 0
  const imagesSlidesData = [...imagesContainer.querySelectorAll('[data-orig]')].map((imageNode, index) => {
    const { orig: imagePath, caption, captionColor = 'ui-kit-color-text-secondary' } = imageNode.dataset

    if (imageNode === target) {
      slideTo = index
    }

    return {
      name: popupContentTitle,
      src: imagePath,
      caption,
      captionColor,
    }
  })

  render(imagesSlidesData, slideTo)
}
