<template>
  <div class="full-calendar">
    <CalendarStub v-if="isEmptyCalendar" />
    <template v-else>
      <div
        v-for="({ day, times, formattedDay }, indexDay) in slicedFullCalendarSlots"
        :key="`day-${indexDay}`"
        :class="{
          'mt-2': !isMobile && indexDay !== 0,
        }"
      >
        <div class="full-calendar__day ui-text ui-text_subtitle-1">
          {{ formattedDay }}
        </div>
        <div
          class="d-flex"
          :class="[isMobile ? 'overflow-x-auto' : 'flex-wrap']"
        >
          <VBtn
            v-for="({ time, free }, indexTime) in times"
            :key="`time-${indexTime}`"
            :disabled="!free"
            width="76"
            class="full-calendar__time"
            color="primary"
            depressed
            @click="handleCalendarTimeChoice({ day, time })"
          >
            {{ time }}
          </VBtn>
        </div>
      </div>
    </template>
    <div class="mt-6">
      <VBtn
        v-if="(
          !isEmptyCalendar
          && !isVisibleAllSlots
          && formattedFullCalendarSlots.length > maxCalendarDays
        )"
        class="uiKitBgPrimary mb-4 w-100"
        color="primary"
        text
        @click="openAllSlots"
      >
        Всё расписание
      </VBtn>
      <VBtn
        v-if="isMobile"
        class="w-100"
        color="uiKitTextSecondary"
        text
        @click="closeFullCalendar"
      >
        <span class="ui-icon-arrow-left" />
        Назад
      </VBtn>
    </div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { format, isToday, parse } from 'date-fns'
import { ru } from 'date-fns/locale'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import CalendarStub from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/components/CalendarStub'
import { useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'

const props = defineProps({
  lpuId: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['calendar-time-choice', 'close'])

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuId)()
const {
  isVisibleFullCalendar,
  fullCalendarSlots,
  nowDateString,
} = storeToRefs(doctorAppointmentCardStore)

const externalDataStore = useExternalDataStore()
const { isMobile } = storeToRefs(externalDataStore)

const isVisibleAllSlots = ref(false)
const maxCalendarDays = ref(6)

const formattedFullCalendarSlots = computed(() => {
  const nowDate = parse(nowDateString.value, 'yyyy-MM-dd', new Date(), { locale: ru })
  const slots = []

  Object.entries(fullCalendarSlots.value)
    .filter(([, times]) => times.length)
    .forEach(([day, times]) => {
      const dayDate = parse(day, 'yyyy-MM-dd', nowDate, { locale: ru })
      const weekDayWord = format(dayDate, 'EEEE', { locale: ru })
      const weekDayWordFormatted = `${weekDayWord[0].toUpperCase()}${weekDayWord.slice(1)}`

      slots.push({
        day,
        times,
        formattedDay: isToday(dayDate)
          ? `Сегодня — ${weekDayWordFormatted}`
          : `${format(dayDate, 'd MMMM', { locale: ru })} — ${weekDayWordFormatted}`,
      })
    })

  return slots
})

const slicedFullCalendarSlots = computed(
  () => (isVisibleAllSlots.value
    ? formattedFullCalendarSlots.value
    : formattedFullCalendarSlots.value.slice(0, maxCalendarDays.value)),
)

const isEmptyCalendar = computed(() => Object.values(fullCalendarSlots.value).every(times => !times.length))

function handleCalendarTimeChoice({ day, time }) {
  emit('calendar-time-choice', { day, time })
}
function closeFullCalendar() {
  emit('close')
  isVisibleFullCalendar.value = false
}
function openAllSlots() {
  isVisibleAllSlots.value = true
}
</script>

<style scoped lang="scss">
@import '~www/themes/doctors/common/variables';

.full-calendar {
  &__day {
    margin-bottom: 14px;
    color: $ui-kit-text;
  }

  &__time {
    margin: 0 10px 7px 0;
    padding: 0 16px;
  }
}
</style>
