import Vue from 'vue'
import AppCalendarButton from 'components/common/AppointmentCalendar/components/AppCalendarButton/AppCalendarButton'
import { NAMESPACE } from 'modules/Schedule/classes/ScheduleData/constants'
import getInitialDay from 'modules/Schedule/classes/ScheduleData/functions/renderData/helpers/getInitialDay'

/**
 * @description
 * Возвращает объект Map, который используется дальше для рендера календарей(календари с выбором специальности).
 * Функция возвращает список, который состоит из множества объектов рендера, или же только из одного.
 * При спецразмещении может быть несколько дубликатов карточек клиник. Сопоставление переменной 'isSingleRender' точно сообщает - в каком контейнере произошло изменение.
 * Если при клике передан parentCard и не найдено сопоставление - будет ошибка, которая ловится в вызывающем модуле.
 *
 * @param { Object } opts
 * @param { Object } opts.data - собранные данные со страницы - интерфейс 'getModuleDataInterface'
 * @param { HTMLElement } opts.parentCard - карточка в списке, в которой необходимо заменить расписание
 * @param { Array<Object> } opts.response - ответ на запрос
 * @param { String } opts.dataItem - data атрибут родителя
 *
 * @return { Map } - данные для рендера списка календарей
 * */

const getRenderLpuData = opts => {
  const {
    data,
    response,
    dataItem,
    parentCard,
  } = opts
  const resultData = new Map()

  data.items.forEach((pageData, scheduleContainer) => {
    const {
      lpuId,
      coverNode,
      doctorsLpuHref,
    } = pageData
    const scheduleData = response.find(({ lpu_id: idResult }) => idResult === lpuId)
    const isListRender = !parentCard // рендер списка календарей
    const isSingleRender = parentCard === coverNode // рендер только 1 календаря

    if (!scheduleData) {
      return
    }

    if (!(isListRender || isSingleRender)) {
      return
    }

    const { slots } = scheduleData
    const days = Object.keys(slots)
    const daysWithSlots = days.filter(date => slots[date].length)
    const isEmptySlotList = !daysWithSlots.length
    const specialtyId = coverNode // ToDo schedule-important избавиться от привязки к DOM дереву
      .querySelector(`[${NAMESPACE.dataSelectedSpecialityId}]`)
      .getAttribute(NAMESPACE.dataSelectedSpecialityId)

    if (isEmptySlotList) {
      if (doctorsLpuHref) {
        scheduleContainer.innerHTML = '<div class="schedule-button"></div>'

        new Vue({
          el: scheduleContainer.querySelector('.schedule-button'),
          render: h => h(AppCalendarButton, { props: { doctorHref: doctorsLpuHref } }),
        })
      }

      return
    }

    const initDay = getInitialDay({
      daysWithSlots,
      currentSlots: slots,
    })

    resultData.set(scheduleContainer, {
      days,
      slots,
      lpuId,
      initDay,
      dataItem,
      data: slots,
      options: slots,
      useRedirect: true,
      isAppointmentDoctor: true,
      doctorSpec: specialtyId,
    })
  })

  return resultData
}

export default getRenderLpuData
