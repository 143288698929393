import deepMerge from 'deepmerge'
import { createDevNotice } from 'utils'
import { getModuleDataInterface, getModuleOptionsInterface } from 'modules/Schedule/models'
import ScheduleData from 'modules/Schedule/classes/ScheduleData'
import ScheduleCalendar from 'modules/Schedule/classes/ScheduleCalendar'
import ScheduleValidator from 'modules/Schedule/classes/ScheduleValidator'

/**
 * @description
 * Модуль является базовым. От него наследуются другие модули, которые в итоге и будут работать в основном коде.
 * Важно: все вспомогательные инстансы являются приватными и недоступны в наследниках. Доступны только публичные методы и объект с хранимыми данными.
 * Приватные методы лучше не использовать внутри наследуемых классов.
 * Соответственно поскольку все вспомогательные инстансы являются приватными - обновление данных производится только с помощью методов базового класса.
 *
 * @param { Object } opts - опции настройки модуля, которые имеют интерфейс 'getModuleOptionsInterface'.
 * */
class ScheduleCore {
  data = getModuleDataInterface() // this.data = this.#dataInstance.data;

  #options = getModuleOptionsInterface() // настройки модуля

  #dataInstance = {} // инстанс модуля 'ScheduleData'

  #calendarInstance = {} // инстанс модуля 'ScheduleCalendar'

  #validatorInstance = {} // инстанс модуля 'ScheduleValidator'

  get options() { return this.#options }

  set options(opts) { this.#options = deepMerge.all([getModuleOptionsInterface(), opts]) }

  /**
     * @description
     * Конструктор производит сбор первоначальных данных со страницы, создает вспомогательные инстансы и навешивает наблюдателя за мутациями в DOM дереве.
     * Все остальные действия(сбор данных для запросов, запросы и обработка ответов) производится в наследуемых классах.
     *
     * @param { Object } opts - опции настройки модуля, которые имеют интерфейс 'getModuleOptionsInterface'.
     * */
  constructor(opts) {
    this.options = opts

    this.#dataInstance = new ScheduleData({
      options: this.options,
    })

    this.#calendarInstance = new ScheduleCalendar({
      data: this.#dataInstance.data,
    })

    this.#validatorInstance = new ScheduleValidator({
      data: this.#dataInstance.data,
      pageType: this.options.pageType,
    })

    this.data = this.#dataInstance.data

    if (this.validator({ getter: 'testPageData' })) {
      this._createObserver()
    }
  }

  /**
     * @public
     * @description
     * Производит дестрой всех инстансов расписания на странице.
     * Сбрасывает и собирает данные со страницы заново, а также обновляет зависимые инстансы.
     * */
  init() {
    this.#calendarInstance.destroyAll()

    this.#dataInstance.reset()
    this.#dataInstance.create()

    this.#calendarInstance.update({ data: this.#dataInstance.data })
    this.#validatorInstance.update({ data: this.#dataInstance.data })

    this.data = this.#dataInstance.data
  }

  /**
     * @public
     * @description
     * Производит валидацию собранных данных, используя геттеры вспомогательного класса 'ScheduleValidator'.
     * Валидируются поля интерфейса 'getModuleDataInterface'. Каждый геттер валидирует отдельное поле 'getModuleDataInterface'.
     *
     * @param { Object } opts
     * @param { String } opts.getter - название геттера, который будет вызван.
     *
     * @return { Boolean } - результат валидации.
     * Но в случае, если валидатор вернул false и используется логер в настройках модуля - сгенерируется ошибка и будет выведена в консоль браузера.
     * */
  validator({ getter }) {
    const { title, subtitle, result } = this.#validatorInstance[getter]

    if (this.options.useLogger && !result) {
      this.createNotice({ message: `${title}. ${subtitle}` })
    }

    return result
  }

  /**
     * @public
     * @description
     * Производит обновление данных для запроса за расписанием.
     * Например, когда данные для запроса собираются наследуемым классом.
     * Модуль валидации использует данные, который передаются по ссылке this.data.
     * В this.data обновляются данные для запроса, эти данные валидируются и после этого отправляется запрос.
     *
     * @param { Object } requestData - один из интерфейсов 'getLpuRequestInterface' | 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface' | 'getServicesRequestInterface'.
     * */
  updateRequestData(requestData) { this.#dataInstance.updateRequestData(requestData) }

  /**
     * @public
     * @description
     * Метод производит обработку ответа на запрос за расписанием.
     * Уничтожает нужный инстанс компонента календаря и монтирует новое расписание.
     * В случае неудачного исхода - вызывается хук 'hookRenderError'.
     * В случае успеха вызывается 'hookRenderSuccess'.
     *
     * @param { Object } response - ответ на запрос
     * @param { Boolean } [isPreliminaryConsultation] - требует ли процедура первичный приём. Используется только на услугах
     * @param { HTMLElement } parentCard - если есть необходимость обновить расписание только в 1 карточке, передается это поле.
     * Он является источником события и с его помощью можно точно обновить расписание в нужной карточке.
     * Это актуально, если в списке есть спецразмещение, потому что карточек в одинаковым id может быть 2.
     * Если этого поля не будет - можно обновить расписание в не той карточке(например в карточке со спецразмещением, вместо обычной или наоборот)
     * */

  resolveRequest(response, parentCard, isPreliminaryConsultation) {
    try {
      this.#dataInstance.updateRenderData(response, parentCard, isPreliminaryConsultation)

      if (!this.validator({ getter: 'testRenderData' })) {
        this.hookRenderError()
        return
      }

      const instances = this.#calendarInstance.render()

      this.#calendarInstance.destroyMap({ instances })
      this.#dataInstance.updateInstanceData({ instances })

      this.hookRenderSuccess()
    } catch (error) {
      this.hookRenderError()
      this.createNotice(error)
    }
  }

  /**
     * @public
     * @description
     * Обработка ошибки запроса.
     * Вызывается хук 'hookRenderError', выводится ошибка.
     *
     * @param { Object } error - объект ошибки.
     * */
  rejectRequest(error) {
    this.hookRenderError()
    this.createNotice(error)
  }

  /**
     * @public
     * @description
     * В базовом классе это пустая функция, но в наследниках она переопределяется и выполняет нужную последовательность действий.
     * Вызывается в случае неудачного рендера расписания.
     * */
  hookRenderError() {}

  /**
     * @public
     * @description
     * В базовом классе это пустая функция, но в наследниках она переопределяется и выполняет нужную последовательность действий.
     * Вызывается в случае успешного рендера расписания.
     * */
  hookRenderSuccess() {}

  /**
     * @public
     * Выводит ошибку в консоль с названием модуля, в котором метод вызывается.
     *
     * @param { Object } error - объект ошибки.
     * @param { String } error.message - строка с пояснением.
     * */
  createNotice({ message }) {
    createDevNotice({
      module: this.constructor.name,
      description: message,
    })
  }

  /**
     * @private
     * @description
     * Создает объект наблюдателя за изменениями в DOM дереве, и вызывает метод 'init' в случае изменений в дереве.
     * Если узла наблюдения на странице не найдено - наблюдатель создан не будет.
     * */
  _createObserver() {
    try {
      const target = document.querySelector(this.options.selectorContainerObserver)

      if (!target) {
        return
      }

      new MutationObserver(records => {
        try {
          const [record] = records
          const { addedNodes, removedNodes } = record

          if (addedNodes.length && removedNodes.length) {
            this.init()
          }
        } catch (error) { this.createNotice(error) }
      }).observe(target, { childList: true })
    } catch (error) { this.createNotice(error) }
  }
}

export default ScheduleCore
