import Vue from 'components'
import { MOUNT_SELECTOR, DATA_PDF_COUPON, DATA_PDF_PARTICIPATION } from 'components/desktop/Club/components/dependencies/ClubDesktopInfoModal/constants'
import ClubDesktopInfoModal from 'components/desktop/Club/components/dependencies/ClubDesktopInfoModal/ClubDesktopInfoModal'

const mountNode = document.querySelector(MOUNT_SELECTOR)

if (mountNode) {
  const dataCouponPDF = mountNode.getAttribute(DATA_PDF_COUPON)
  const dataParticipationPDF = mountNode.getAttribute(DATA_PDF_PARTICIPATION)

  if (dataCouponPDF && dataParticipationPDF) {
    new Vue({
      el: mountNode,
      render: h => h(ClubDesktopInfoModal, {
        props: {
          pdfData: {
            coupon: dataCouponPDF,
            participation: dataParticipationPDF,
          },
        },
      }),
    })
  }
}
