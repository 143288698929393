export const MOUNT_SELECTOR = '.vue-mount-appointment-type-filters-list'

// значения APPOINTMENT_TYPES должны быть равны lpuAddresses[n].workplaces[n].priceList[*]
// lpuAddresses - те, которые приходят в props в AppointmentTypeFilterList.vue
export const APPOINTMENT_TYPES = {
  lpu: 'default',
  telemed: 'telemed',
  home: 'na_dom',
}
export const APPOINTMENT_TYPES_FILTER = [
  {
    type: APPOINTMENT_TYPES.lpu,
    text: 'В клинике',
    isHidden: false,
  },
  {
    type: APPOINTMENT_TYPES.home,
    text: 'На дому',
    isHidden: false,
  },
  {
    type: APPOINTMENT_TYPES.telemed,
    text: 'Онлайн',
    isHidden: false,
  },
]

export const APPOINTMENT_TYPE_FILTER_EVENTS = {
  filterChosenEvent: 'filterChosenEvent',
  changeCardContent: 'changeCardContent',
  recalcSlots: 'recalcSlots',
}
