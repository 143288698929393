var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SideMenu",
    {
      attrs: {
        title: _vm.sideMenuData.title,
        "side-menu-width": _vm.sideMenuData.contentWidth,
        value: _vm.isTopDialog(_vm.componentName),
        "z-index": 210,
        "side-menu-auto-scroll-y": "",
        "side-menu-is-block": "",
      },
      on: { "side-menu:click-close": _vm.handleDialogClose },
      scopedSlots: _vm._u(
        [
          _vm.isFFPdClubComponentsUpdate
            ? {
                key: "titleIcon",
                fn: function () {
                  return [
                    _c("img", {
                      staticClass: "ml-1",
                      attrs: {
                        src: "/static/_v1/pd/icons/ui-kit/club/club-percent.svg",
                        width: "24",
                        height: "24",
                        alt: "Иконка Процент Клуба",
                      },
                    }),
                  ]
                },
                proxy: true,
              }
            : null,
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "p-6" },
        [
          _vm.isFFPdClubComponentsUpdate
            ? [
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-2" },
                  [_vm._v("\n        Что даёт клуб\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_body-1 ui-kit-color-text" },
                  [
                    _vm._v(
                      "\n        Возможность получить скидку 30% на консультацию у врача.\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_h6 ui-kit-color-text mt-8 mb-2",
                  },
                  [_vm._v("\n        Как получить скидку\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "b-club-list ui-text ui-text_body-1 ui-kit-color-text",
                  },
                  [
                    _c("div", { staticClass: "b-club-list__item" }, [
                      _vm._v(
                        "\n          Оплачиваете на сайте ПроДокторов 50% стоимости консультации по цене со скидкой\n        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "b-club-list__item" }, [
                      _vm._v(
                        "\n          В день визита оплачиваете в клинике оставшиеся 50%\n        "
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_h6 ui-kit-color-text mt-8 mb-2",
                  },
                  [_vm._v("\n        Как вступить в клуб\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "ui-text ui-text_body-1 ui-kit-color-text mb-4",
                  },
                  [
                    _vm._v(
                      "\n        Доступ к клубу стоит 99 ₽ на 6 месяцев.\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "ui-text ui-text_body-1 ui-kit-color-text" },
                  [
                    _vm._v(
                      "\n        Но первые 6 месяцев вы получаете в подарок!\n      "
                    ),
                  ]
                ),
              ]
            : [
                _c("img", {
                  staticClass: "d-block mt-4 mb-3 mx-auto",
                  attrs: {
                    src: _vm.sideMenuData.mainImagePath,
                    alt: _vm.sideMenuData.title,
                    width: "273px",
                    height: "223px",
                  },
                }),
                _vm._v(" "),
                _vm._l(
                  _vm.staticData,
                  function ({ extra, title, description }, index) {
                    return _c(
                      "div",
                      { key: `${_vm.componentName}DescriptionItem${index}` },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_h6 ui-kit-color-text mt-6 mb-2",
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(title) + "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", {
                          domProps: { innerHTML: _vm._s(description) },
                        }),
                        _vm._v(" "),
                        extra
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "ui-text ui-text_caption ui-kit-color-text-secondary mt-2",
                              },
                              [
                                _vm._v(
                                  "\n          " + _vm._s(extra) + "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    )
                  }
                ),
              ],
          _vm._v(" "),
          _c("VDivider", { staticClass: "my-8" }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "ui-text ui-text_h6 ui-kit-color-text mb-4" },
            [_vm._v("\n      Вопросы и ответы\n    ")]
          ),
          _vm._v(" "),
          _c(
            "VExpansionPanels",
            { attrs: { multiple: "" } },
            _vm._l(_vm.expansionPanel, function (item, index) {
              return _c(
                "VExpansionPanel",
                { key: `${_vm.componentName}ExpansionItem${index}` },
                [
                  _c(
                    "VExpansionPanelHeader",
                    {
                      staticClass: "text-left justify-content-between",
                      attrs: { "expand-icon": _vm.vuetifyIcons.arrowDown },
                    },
                    [
                      _c("span", { staticClass: "ui-text ui-text_body-1" }, [
                        _vm._v(_vm._s(item.title)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "VExpansionPanelContent",
                    { staticClass: "ui-text ui-text_body-1" },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item.description) + "\n        "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "mt-10 mb-4" }, [
            _c(
              "div",
              {
                staticClass:
                  "ui-text ui-text_body-2 ui-kit-color-text-secondary",
              },
              [
                _vm._v(
                  "\n        © 2011 — " +
                    _vm._s(_vm.nowYear) +
                    ", ООО «МедРейтинг»\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "d-block ui-text ui-text_body-2 ui-kit-color-text-secondary mt-2",
              },
              [
                _vm._v("\n        Оплачивая, вы соглашаетесь\n        с "),
                _c(
                  "a",
                  {
                    staticClass: "ui-kit-color-text-secondary",
                    attrs: {
                      href: _vm.pdfData.coupon,
                      rel: "noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [_vm._v("офертой")]
                ),
                _vm._v("\n        и "),
                _c(
                  "a",
                  {
                    staticClass: "ui-kit-color-text-secondary",
                    attrs: {
                      href: _vm.pdfData.participation,
                      rel: "noopener noreferrer",
                      target: "_blank",
                    },
                  },
                  [_vm._v("правилами клуба")]
                ),
              ]
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }