var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    {
      staticClass: "doctor-price-block-item",
      class: {
        "doctor-price-block-item_active": _vm.active,
      },
    },
    [
      _vm.club
        ? _c(
            "div",
            {
              staticClass:
                "doctor-price-block-item__club-badge ui-text ui-text_subtitle-3",
            },
            [_vm._v("\n    -30%\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "ui-text ui-text_subtitle-1" }, [
        _vm._v(
          "\n    " +
            _vm._s(
              _setup.getFormattedPrice(_vm.price, {
                prefix: _vm.pricePrefix ? "от" : "",
              })
            ) +
            "\n  "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "ui-text ui-text_caption" }, [
        _vm._v("\n    " + _vm._s(_vm.subtitle) + "\n  "),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }