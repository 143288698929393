<template>
  <v-app>
    <v-sheet
      height="32"
      :width="url ? 72 : 32"
      elevation="1"
      color="white"
      class="favourite-btn d-flex align-center justify-center"
    >
      <v-progress-circular
        v-if="loading"
        :size="20"
        width="2"
        color="primary"
        indeterminate
      />
      <span
        v-else
        class="ui-icon_fz_medium ui-text"
        :class="favouriteIconClass"
        @click="handleFavouriteClick"
      />
      <span
        v-if="url"
        class="ui-icon-share-circle ui-icon_fz_medium ui-text ui-kit-color-text-info ml-3"
        @click="handleShareClick"
      />
    </v-sheet>
  </v-app>
</template>

<script>
import copy from 'copy-to-clipboard'
import { LOGIN_LINKS } from 'constants/links'
import { getQueryParam, dispatchCustomEventGlobally } from 'utils'
import YandexGoals from 'modules/YandexGoals'
import { EVENTS as DIALOG_EVENTS } from 'components/common/core/BaseDialog/constants'
import { EVENTS as SNACKBAR_EVENTS, ERROR as SNACKBAR_ERROR } from 'components/common/core/SnackbarNotification/constants'
import {
  IN_USER_FAVOURITES,
  NOT_IN_USER_FAVOURITES,
  RESTORED,
  FAVOURITE_ICON_CLASS,
  MED_TYPES,
  QUERY_PARAMS,
  DIALOG_LOGIN,
  COPIED,
  URL_NAMES_TO_GOAL_SOURCES,
} from 'components/common/FavouriteBtn/constants'
import { FavouritesService } from 'components/common/FavouriteBtn/api'

const yandexGoalsInstance = new YandexGoals()

export default {
  name: 'FavouriteBtn',
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return Object.values(MED_TYPES).includes(value)
      },
    },
    id: {
      type: Number,
      required: true,
      validator(value) {
        return value > 0
      },
    },
    inUserFavourites: {
      type: Boolean,
      default: false,
    },
    url: {
      type: String,
      required: true,
    },
    urlName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isMobile: window.MOBILE_VERSION,
      dialogWidth: 304,
      loading: false,
      isFavourite: this.inUserFavourites,
    }
  },
  computed: {
    favouriteIconClass() {
      return this.isFavourite ? FAVOURITE_ICON_CLASS.active : FAVOURITE_ICON_CLASS.inactive
    },
  },
  created() {
    const addToFavouritesQueryParam = Number(getQueryParam(QUERY_PARAMS.addToFavourites))

    if (!window.USER.isAnonymous && !this.isFavourite && addToFavouritesQueryParam === this.id) {
      this.addToFavourites()
    }
  },
  methods: {
    goToLogin() {
      const loginUrl = new URL(LOGIN_LINKS.medtochka)
      const nextQueryParam = loginUrl.searchParams.get(QUERY_PARAMS.next)
      const nextUrl = new URL(nextQueryParam)

      nextUrl.searchParams.set(QUERY_PARAMS.addToFavourites, String(this.id))
      loginUrl.searchParams.set(QUERY_PARAMS.next, nextUrl.href)

      window.location.href = loginUrl.href
    },
    async handleFavouriteClick() {
      if (window.USER.isAnonymous) {
        dispatchCustomEventGlobally(DIALOG_EVENTS.open, {
          ...DIALOG_LOGIN,
          beforeConfirm: this.goToLogin,
        })
        return
      }

      if (this.isFavourite) {
        await this.removeFromFavourites()
        return
      }

      await this.addToFavourites()
    },
    async addToFavourites() {
      if (this.loading) {
        return
      }

      this.loading = true

      try {
        await FavouritesService.add(this.type, this.id)
        this.isFavourite = true
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, IN_USER_FAVOURITES)
      } catch (e) {
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, SNACKBAR_ERROR)
      } finally {
        this.loading = false
      }
    },
    async removeFromFavourites() {
      if (this.loading) {
        return
      }

      this.loading = true

      try {
        await FavouritesService.delete(this.type, this.id)
        this.isFavourite = false
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, {
          ...NOT_IN_USER_FAVOURITES,
          handleAction: this.restoreToFavourite,
        })
      } catch (e) {
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, SNACKBAR_ERROR)
      } finally {
        this.loading = false
      }
    },
    async restoreToFavourite() {
      if (this.loading) {
        return
      }

      dispatchCustomEventGlobally(SNACKBAR_EVENTS.close)
      this.loading = true

      try {
        await FavouritesService.add(this.type, this.id)
        this.isFavourite = true
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, RESTORED)
      } catch (e) {
        dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, SNACKBAR_ERROR)
      } finally {
        this.loading = false
      }
    },
    async handleShareClick() {
      if (!this.isMobile) {
        this.copyToClipboard()
        return
      }

      try {
        await navigator.share({
          url: this.url,
        })

        this.sendGoal()
      } catch (error) {
        if (error.name === 'AbortError') {
          return
        }

        this.copyToClipboard()
      }
    },
    copyToClipboard() {
      copy(this.url)
      dispatchCustomEventGlobally(SNACKBAR_EVENTS.open, COPIED)
      this.sendGoal()
    },
    sendGoal() {
      let goalSource = URL_NAMES_TO_GOAL_SOURCES[this.urlName]
      const goalObjectType = this.type === 'lpu' ? 'lpu' : 'doctor'

      if (this.urlName === 'services_all' || this.urlName === 'services_category') {
        goalSource = window.location.href.includes('/diagnostika/') ? 'diagnostics' : 'services'
      }

      yandexGoalsInstance.send({
        name: `shareButton_${goalObjectType}_${goalSource}`,
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.favourite-btn {
  cursor: pointer;
  border-radius: 20px;
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';

#app {
  @include v-app-reset;
}
</style>
