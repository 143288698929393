var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VApp", { attrs: { id: "award-banner-app" } }, [
    _vm.isVisibleBanner
      ? _c(
          "div",
          {
            staticClass: "award-banner",
            class: { "award-banner_dark": _vm.isDarkMode },
            on: { click: _vm.handleShowMore },
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex align-items-center",
                class: _vm.isMobile ? "px-2 pt-1 mb-2" : "mr-8",
              },
              [
                _c("img", {
                  staticClass: "flex-grow-0",
                  class: _vm.isMobile ? "mr-6" : "mr-8",
                  attrs: {
                    alt: "Золотая звезда",
                    height: "32",
                    width: "32",
                    src: _vm.starIcon,
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "ui-text ui-text_body-1" }, [
                  _vm._v(
                    "\n        " + _vm._s(_vm.bannerData.title) + "\n      "
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "ml-auto", class: { "mr-2": !_vm.isMobile } },
              [
                _c(
                  "VBtn",
                  {
                    staticClass: "px-2 mr-2",
                    attrs: { text: "", dark: _vm.isDarkMode },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleClose.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        Скрыть\n      ")]
                ),
                _vm._v(" "),
                _c(
                  "VBtn",
                  {
                    staticClass: "px-2",
                    attrs: {
                      text: "",
                      dark: _vm.isDarkMode,
                      color: _vm.isDarkMode ? "gold" : "primary",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.handleShowMore.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("\n        Подробнее\n      ")]
                ),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }