<template>
  <VExpansionPanels
    v-if="isMobile"
    v-model="expandedPanels"
    accordion
    multiple
    tile
  >
    <DoctorPageLpuListLpu
      v-for="(lpuAddress, lpuAddressIndex) in lpuAddressListFiltered"
      :key="`${lpuAddress.id}-${lpuAddressIndex}-${filterType}`"
      :is-expanded="expandedPanels.includes(lpuAddressIndex)"
      :lpu-address="lpuAddress"
    />
  </VExpansionPanels>
  <VCard
    v-else
    class="rounded-t-0"
    elevation="1"
  >
    <DoctorPageLpuListLpu
      v-for="lpuAddress in lpuAddressListFiltered"
      :key="lpuAddress.id"
      :lpu-address="lpuAddress"
    />
  </VCard>
</template>

<script setup lang="ts">
import { watch } from 'vue'
import { storeToRefs } from 'pinia'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
// eslint-disable-next-line import/extensions
import DoctorPageLpuListLpu from 'components/common/DoctorPageLpuList/components/DoctorPageLpuListLpu.vue'
import { requestSlots } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/api'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'

const externalDataStore = useExternalDataStore()
const {
  currentDate,
  lpuAddressList,
  townTimedelta,
  isMobile,
} = storeToRefs(externalDataStore)

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { filterType, expandedPanels, lpuAddressListFiltered } = storeToRefs(doctorPageLpuListStore)

watch(filterType, async () => {
  if (lpuAddressList.value && filterType.value !== 'home') {
    lpuAddressList.value.forEach(lpuAddress => {
      useDoctorAppointmentCardStore(lpuAddress.lpu.id)().isRequestSlotsDone = false
    })

    try {
      const slotsResponse: Array<{
        doctor_id: number
        lpu_id: number
        slots: Array<{
          [date: string]: {
            duration: number
            free: boolean
            time: string
          }
        }>
      }> = await requestSlots({
        slotsData: {
          days: 14,
          allSlots: false,
          nowDateString: currentDate.value,
          timedelta: townTimedelta.value ?? 0,
          doctorsAndLpus: lpuAddressList.value.map(lpuAddress => ({
            doctorId: lpuAddress.doctor_id ?? 0,
            lpuId: lpuAddress.lpu_id,
            timedelta: lpuAddress.lpu.town.timedelta ?? 0,
            hasSlots: lpuAddress.has_slots,
          })),
        },
        isWorkplace: true,
        isTelemed: filterType.value === 'telemed',
        isAllLpus: true,
      })

      slotsResponse.forEach(slotsResponseItem => {
        useDoctorAppointmentCardStore(slotsResponseItem.lpu_id)().calendarSlots = slotsResponseItem.slots
      })
    } catch { /* empty */ } finally {
      lpuAddressList.value?.forEach(lpuAddress => {
        useDoctorAppointmentCardStore(lpuAddress.lpu.id)().isRequestSlotsDone = true
      })
    }
  }
}, {
  immediate: true,
})
</script>
