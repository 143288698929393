/* eslint-disable */
$(function () {
    function triggerDataEvents ($carousel) {
        if ($carousel.attr('data-events-to-trigger')) {
            const eventList = $carousel.data('events-to-trigger').split(' ');
            for (let event of eventList) {
                $carousel.trigger(event);
            }
        }
    }

    // значения по-умолчанию
    const sliderOpts = {
        items: 1,
        dots: false,
        navText: [""],
        slideBy: 1
    };

    $('.b-photo-carousel').each(function (i, el) {
        const $el = $(el);

        // Устанавливается количество слайдов, если параметр отсутствует, то задаётся дефолтное значение 1
        if ($el.attr("data-slides-count")) {
            sliderOpts.items = parseInt($el.data("slides-count"));
        }
        // Атрибут, который включает отображение кнопок nav, если items > slides-count, если не указывать, то их не будет
        if ($el.attr("data-slides-nav")) {
            sliderOpts.nav = $el.data('slides-nav');
        }
        // Отступ справа
        if ($el.attr("data-margin-right")) {
            sliderOpts.margin = parseInt($el.data("margin-right"));
        }
        // Количество слайдов, которые будут пролистаны за одно нажатие стрелки
        if ($el.attr("data-slide-by")) {
            sliderOpts.slideBy = parseInt($el.data("slide-by"));
            sliderOpts.dotsEach = parseInt($el.data("slide-by"));
        }

        $el.on('initialized.owl.carousel', function () {
            triggerDataEvents($el);
        });

        $el.owlCarousel(sliderOpts);
    });
});
