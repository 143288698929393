/**
 * @typedef UpdateInfo
 * @property { number | null } status
 * @property { string } dateString
 * */

/**
 * @typedef ServicePricesData
 * @property { Lpu } lpu
 * @property { UpdateInfo } updateInfo
 * @property { Array<ServicePrice> } servicePrices
 * */

/** @return { ServicePricesData } */
const getServicePricesData = () => ({
  lpu: {
    id: null,
    name: '',
    address: '',
  },
  updateInfo: {
    status: null,
    dateString: '',
  },
  servicePrices: [],
})

/**
 * @typedef Age
 * @property { number } min
 * @property { number } max
 * */

/**
 * @typedef ServicePrice
 * @property { number } id
 * @property { number } synonymId
 * @property { number } doctorId
 * @property { string } name
 * @property { string } price
 * @property { Age } age
 * @property { boolean } hasSlots
 * */

/**
 * @typedef ServiceCategory
 * @property { string } name
 * @property { ServicePrice[] } ServicePrices
 * */

/**
 * @typedef FormattedServicePricesData
 * @property { Lpu } lpu
 * @property { UpdateInfo } updateInfo
 * @property { Array<ServicePrice> } servicePrices
 * @property { Array<ServiceCategory> } [serviceCategories]
 * @property { Array<PriceItem> } priceList
 * */

/** @return { FormattedServicePricesData } */
const getFormattedServicePricesData = () => ({
  lpu: {
    id: null,
    name: '',
    address: '',
    isServiceDirect: false,
  },
  updateInfo: {
    status: null,
    dateString: '',
  },
  serviceCategories: [],
  servicePrices: [],
})

/** @return { Array<ServicePricesData> } */
const getServicePricesDataList = () => []

export {
  getServicePricesData,
  getServicePricesDataList,
  getFormattedServicePricesData,
}
