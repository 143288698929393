<template>
  <div
    class="b-appointment-calendar"
    :data-lpu="lpuId"
    :data-doctor="doctorId"
  >
    <div class="b-appointment-calendar__day-selector">
      <div
        class="b-appointment-calendar__btn"
        :class="{ 'b-appointment-calendar__btn_disabled': isFirstDay }"
        :data-qa="QALocators['button-prev']"
        @click="prevDay"
      >
        <span
          aria-hidden="true"
          class="b-appointment-calendar__next-icon ui-icon-arrow-left ui-icon_fz_smaller"
        />
      </div>
      <div class="b-appointment-calendar__cur-day">
        {{ dateName }}
      </div>
      <div
        class="b-appointment-calendar__btn"
        :class="{ 'b-appointment-calendar__btn_disabled': isLastDay }"
        :data-qa="QALocators['button-next']"
        @click="nextDay"
      >
        <span
          aria-hidden="true"
          class="b-appointment-calendar__next-icon ui-icon-arrow-right ui-icon_fz_smaller"
        />
      </div>
    </div>
    <AppCalendarSlotSet
      :doctor-id="doctorId"
      :base-slot-set="slots"
      :selected-slot="selectedSlot"
      :selected-date="slotSelectedDate"
      :expanded-state="expanded.state"
      :dynamic-slot-set="slotsDataObj"
      @[eventClickSlot]="handleClickOnTime"
      @[eventClickExpand]="onClickExpandBtn"
      @[eventClickCollapse]="onClickCollapseBtn"
    />
    <AppCalendarInvasiveModal
      :is-visible="isVisibleInvasiveModal"
      @invasive-modal:click-close="handleInvasiveModalClose"
      @invasive-modal:click-continue="handleInvasiveModalContinue"
    />
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import { ru } from 'date-fns/locale'
import {
  format,
  isValid,
  parseISO,
} from 'date-fns'
import { dispatchCustomEventGlobally } from 'utils'
import {
  AppCalendarProps,
  AppCalendarEvents,
  AppCalendarDataQA,
  AppCalendarRedirect,
} from 'components/common/AppointmentCalendar/mixins/index'
import AppCalendarSlotSet from 'components/common/AppointmentCalendar/components/AppCalendarSlotSet/AppCalendarSlotSet'
import AppCalendarInvasiveModal from 'components/common/AppointmentCalendar/components/AppCalendarInvasiveModal/AppCalendarInvasiveModal'

export default {
  name: 'AppointmentCalendar',
  vuetify,
  components: {
    AppCalendarSlotSet,
    AppCalendarInvasiveModal,
  },
  mixins: [
    AppCalendarProps,
    AppCalendarEvents,
    AppCalendarDataQA,
    AppCalendarRedirect,
  ],
  data() {
    return {
      expanded: {
        state: false,
        itemsNum: 7,
      },
      selectedSlot: this.initialSlot,
      dayIndex: 0,
      slotDays: [],
      handleInvasiveModalContinue: () => {},
      isVisibleInvasiveModal: false,
    }
  },
  computed: {
    /**
         * Свойство слотов для записи определенной даты, служит для скрытия или развертки всех слотов для показа
         * @returns {({} & default.props.data) | ({} & {default, type})}
         */
    slotsDataObj() {
      if (!this.slotSelectedDate) {
        return
      }

      const slotsLimit = 4 * 2 // количество слотов, которое может быть выведено в 2 строки

      if (this.slots[this.slotSelectedDate].length === slotsLimit) { // проверка на скрытие кнопки "показать ещё"
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.expanded.itemsNum = slotsLimit
      }

      return {
        ...this.slots,
        [this.slotSelectedDate]: this.slots[this.slotSelectedDate].slice(0, this.expanded.itemsNum),
      }
    },
    slotSelectedDate() {
      return this.slotDays[this.dayIndex]
    },
    isFirstDay() {
      return this.dayIndex === 0
    },
    isLastDay() {
      return this.dayIndex === this.slotDays.length - 1
    },
    dateName() {
      if (!this.slotSelectedDate) {
        return ''
      }

      const selectedDayISO = parseISO(this.slotSelectedDate)

      if (!isValid(selectedDayISO)) {
        return ''
      }

      const day = format(selectedDayISO, 'cccccc', { locale: ru })
      const formattedDay = day[0].toLowerCase() + day.slice(1)
      const formattedDayAndMonth = format(selectedDayISO, 'd MMMM', { locale: ru })

      return `${formattedDayAndMonth} (${formattedDay})` // 6 июня (пн)
    },
    hasExpandButton() {
      return this.slots[this.slotSelectedDate].length > 8
                && this.slots[this.slotSelectedDate].length !== this.slotsDataObj[this.slotSelectedDate].length
    },
  },
  watch: {
    slots: {
      immediate: true,
      deep: true,
      handler() {
        this.slotDays = Object.keys(this.slots)
          .filter(day => this.slots[day].length > 0)
          .sort((a, b) => new Date(a) - new Date(b))

        const selectedDay = this.initDay || this.selectedSlot.day

        this.dayIndex = Math.max(0, this.slotDays.indexOf(selectedDay))
      },
    },
  },
  created() {
    this.$on('reset', this.onReset)
  },
  mounted() {
    dispatchCustomEventGlobally('calendar:updated', { type: 'mounted', el: this.$el })
  },
  updated() {
    dispatchCustomEventGlobally('calendar:updated', { type: 'updated', el: this.$el })
  },
  beforeDestroy() {
    this.$off('reset')
  },
  methods: {
    prevDay() {
      this.onClickCollapseBtn()

      if (!this.isFirstDay) {
        this.dayIndex--
      }
    },
    nextDay() {
      this.onClickCollapseBtn()

      if (!this.isLastDay) {
        this.dayIndex++
      }
    },
    handleInvasiveModalClose() {
      this.isVisibleInvasiveModal = false
    },
    handleClickOnTime(event, slot) {
      if (this.serviceIsPreliminaryConsultation) {
        this.isVisibleInvasiveModal = true

        this.handleInvasiveModalContinue = () => {
          this.handleInvasiveModalClose()
          this.onClickSlot(event, slot)
        }

        return
      }

      this.onClickSlot(event, slot)
    },
    /**
         * Обработчик события клика по слоту с временем записи
         * @param event - объект события
         * @param slot
         */
    onClickSlot(event, slot) {
      this.selectedSlot = {
        time: slot.time,
        day: this.slotSelectedDate,
      }

      const selectedDoctorSpec = document.querySelector(
        `[data-select-doctor-speciality="${this.lpuId}"] [data-selected-speciality-id]`,
      )?.dataset.selectedSpecialityId

      const payload = {
        event,
        options: slot,
        slot: slot.time,
        lpuId: this.lpuId,
        dtypeId: this.dtypeId,
        deviceId: this.deviceId,
        dateName: this.dateName,
        doctorId: this.doctorId,
        synonymId: this.synonymId,
        lpuAddrId: this.lpuAddrId,
        doctorSpec: selectedDoctorSpec || this.doctorSpec,
        specPlacement: this.specPlacement,
        day: format(parseISO(this.slotSelectedDate), 'dd.MM.yyyy', { locale: ru }),
        dayCalendarFormat: format(parseISO(this.slotSelectedDate), 'yyyy-MM-dd', { locale: ru }),
      }

      if (this.useRedirect) {
        this.redirectToAppointment({
          target: event.target,
          dataItem: this.dataItem,
          slotDoctorId: slot.doctor_id,
          slotInGetOptions: `${format(parseISO(this.slotSelectedDate), 'dd.MM.yyyy', { locale: ru })} ${slot.time}`,
        })

        return
      }

      this.$emit('calendar:time-choice', {
        day: this.slotSelectedDate,
        time: slot.time,
        dateName: this.dateName,
        selectedFullDate: `${payload.day} ${payload.slot}`,
      })
    },
    /**
         * Обработчик события сброса (на данный момент выбранной даты)
         */
    onReset() {
      this.selectedSlot = {}
    },
    /**
         * Обработчик события, развернуть все слоты
         */
    onClickExpandBtn() {
      this.expanded.itemsNum = this.slots[this.slotSelectedDate].length
      this.expanded.state = true
    },
    /**
         * Обработчик события, свернуть слоты до 7 штук
         */
    onClickCollapseBtn() {
      this.expanded.itemsNum = 7
      this.expanded.state = false
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.b-appointment-calendar {
  width: 100%;
  font-family: $font-family-regular;
  font-style: normal;

  &__day-selector {
    width: 100%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__next-icon {
    text-indent: initial;
    background-image: initial;
    transition: 0.2s ease-in-out;
  }

  &__btn {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: $ui-kit-primary;
    border-radius: $border-radius-round;
    background-color: $ui-kit-bg-gray-0;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
      0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 1px 5px 0 rgba(0, 0, 0, 0.12);

    &_disabled {
      color: $ui-kit-disabled;
    }
  }

  &__cur-day {
    font-weight: $weight-medium;
    font-size: $font-size-lg;
    line-height: $line-height-base;
    text-align: center;
    color: $color-neur-dark;
  }
}
</style>
