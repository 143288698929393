export const DAYS = 14 // количество дней с расписанием
export const ONLY_FREE = true // только свободные ячейки

export const STAFF_NAMESPACE = {
  appointmentType: 'staff-appointment-type',
  appointmentTypeInner: 'staff-appointment-type__text',
}

export const PAGE_TYPE_DATA = { // тип страницы(от него зависит логика связанных элементов, например фильтр или пагинация)
  lpu: 'lpu',
  doctors: 'doctors',
  services: 'services',
  doctorsLpu: 'doctorsLpu',
}

export const SCHEDULE_EVENTS = { // события, которые генерируются с помощью 'dispatchCustomEventGlobally' и прослушиваются модулем
  optionClick: 'schedule:option-click',
  renderSuccess: 'schedule:render-success',
  hideAllToggleCalendar: 'schedule:hide-all-toggle-calendar',
  updateSynonym: 'schedule:update-synonym',
  clickSynonymPrice: 'schedule:price-click',
  selectSynonymClick: 'schedule:select-synonym-click',
  redirectSynonymPrice: 'schedule:redirect-synonym-price',
  clickAppointmentDoctorButton: 'schedule:click-appointment-doctor-button',
  redirectAppointmentDoctor: 'schedule:redirect-appointment-doctor',
}

export const VALIDATION_DATA = { // объект, который содержит список 'контролируемых' ошибок
  dataJSONMessage: 'Массив не должен быть пустым и должен содержать объекты с данными',
  isEmptyItemData: 'Массив с данными "itemData" не должен быть пустым',
  observerErrorMessage: 'Ошибка создания наблюдателя за изменениями в DOM дереве',
  pageData: { // проверка собранных со страницы данных
    title: 'Данные, собранные со страницы не прошли валидацию',
    detailData: {
      isMapMessage: 'Объект "items" должен быть "Map" и иметь длину',
      isObjectMessage: 'Объект "storage" должен быть "Object" и иметь ключи',
      isItemsInterfaceMessage: 'Объект "items" должен совпадать с базовым интерфейсом',
      isStorageInterfaceMessage: 'Объект "storage" должен совпадать с базовым интерфейсом',
    },
  },
  requestData: { // проверка данных, которые собираются для запроса за расписанием
    title: 'Данные для запроса некорректны',
    detailData: {
      isObjectMessage: 'Объект "request" должен быть "Object" и иметь ключи',
      interfaceMessage: 'Объект "request" должен совпадать с базовым интерфейсом',
      isEmptyLpusSpecialities: 'Массив "lpus_specialities" не должен быть пустым',
      isEmptyDoctorsLpus: 'Массив "doctors_lpus" не должен быть пустым',
      isEmptyDoctorsLpuTimedelta: 'Массив "lpu_timedelta" не должен быть пустым',
      isEmptyServices: 'Массив "services" не должен быть пустым',
    },
  },
  renderData: { // проверка данных, которые будут переданы в компонент расписания
    title: 'Данные не могут быть переданы компоненту рендера',
    detailData: {
      isMapMessage: 'Объект "render" должен быть "Map" и иметь длину',
    },
  },
}
