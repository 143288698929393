import { render, staticRenderFns } from "./FavouriteBtn.vue?vue&type=template&id=6af890df&scoped=true&"
import script from "./FavouriteBtn.vue?vue&type=script&lang=js&"
export * from "./FavouriteBtn.vue?vue&type=script&lang=js&"
import style0 from "./FavouriteBtn.vue?vue&type=style&index=0&id=6af890df&prod&lang=scss&scoped=true&"
import style1 from "./FavouriteBtn.vue?vue&type=style&index=1&id=6af890df&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6af890df",
  null
  
)

export default component.exports