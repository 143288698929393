<template>
  <div v-if="list.length">
    <div class="ui-text ui-text_h6 uiKitText--text pa-2">
      {{ title }}
    </div>
    <VList>
      <VListItem
        v-for="(item, index) in list"
        :key="index"
        @click="handleClickItem(item)"
      >
        <VListItemContent class="py-2">
          <div class="ui-text ui-text_body-1 uiKitText--text text-break">
            {{ item.name }}
          </div>
          <div class="ui-text ui-text_body-2 uiKitTextSecondary--text">
            <span
              v-if="redirectValue === 'club' && item.clubPrice && isLpuAppointmentTypeChosen"
              class="uiKitTextError--text mr-1"
            >{{ item.clubPrice }}</span>
            <span
              v-if="item.isWorkplace"
              :class="{ 'text-decoration-line-through': redirectValue === 'club' && item.clubPrice && isLpuAppointmentTypeChosen }"
            >{{ formattedPrice(item) }}</span>
            <span
              v-else
              :class="{ 'text-decoration-line-through': redirectValue === 'club' && item.clubPrice && isLpuAppointmentTypeChosen }"
            >{{ item.price }}</span>
            <template v-if="item.agesText">
              <span class="uiKitBgGray80--text mx-1">•</span>
              {{ item.agesText }}
            </template>
          </div>
        </VListItemContent>
        <VListItemIcon class="align-self-auto">
          <VIcon>
            ui-icon-arrow-right
          </VIcon>
        </VListItemIcon>
      </VListItem>
    </VList>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { getFormattedPrice } from 'utils'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'

const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  list: {
    type: Array,
    default: () => [],
  },
  isLpuAppointmentTypeChosen: {
    type: Boolean,
    required: true,
  },
  lpuId: {
    type: Number,
    required: true,
  },
})

const emit = defineEmits(['click-item'])

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuId)()
const { translatedChosenFilter } = storeToRefs(doctorAppointmentCardStore)

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { redirectValue } = storeToRefs(doctorPageLpuListStore)

function handleClickItem(item) {
  emit('click-item', item)
}

function formattedPrice(item) {
  return getFormattedPrice(item.priceList[translatedChosenFilter.value])
}
</script>
