<template>
  <div class="mtlink-slider b-swiper">
    <div class="mtlink-slider__track b-swiper__track">
      <div
        v-for="slide in slides"
        :key="slide.title"
        class="b-swiper__slide"
      >
        <div class="mtlink-slider__image-container">
          <img
            :src="slide.imgSrc"
            :alt="slide.title"
            class="mtlink-slider__image"
          >
        </div>
        <!-- eslint-disable vue/no-v-html -->
        <div
          class="ui-text ui-text_h6 text-center"
          v-html="slide.title"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </div>
  </div>
</template>

<script>
import SwiperModule from 'modules/SwiperModule'

export default {
  name: 'BannerMtLinkSlider',
  data: () => ({
    slides: [
      {
        title: 'Добавляйте в медкарту <br>заключения и анализы',
        imgSrc: '/static/_v1/pd/screenshots/medtochka/medtochka-app-medcard-add-docs.png',
      },
      {
        title: 'На приёме врач посмотрит <br>всё на компьютере',
        imgSrc: '/static/_v1/pd/screenshots/medtochka/medtochka-app-medcard-see-desktop.png',
      },
    ],
  }),
  mounted() {
    new SwiperModule({
      module: {
        modifier: 'mtlink-slider',
      },
      swiper: {
        navigation: false,
        centeredSlides: true,
      },
    })
  },
}
</script>

<style lang="scss">
.mtlink-slider {
  &__pagination {
    margin-top: 16px;
  }
}
</style>

<style lang="scss" scoped>
.mtlink-slider {
  height: 100%;

  &__track {
    height: calc(100% - 24px);
  }

  &__image-container {
    position: relative;
    height: calc(100% - 68px);
    margin-bottom: 16px;
  }

  &__image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
</style>
