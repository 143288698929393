import DoctorCardEmitter from 'modules/DoctorCardEmitter'
import ScheduleCore from 'modules/Schedule/classes/ScheduleCore'
import ScheduleDoctorPreloader from 'modules/Schedule/classes/ScheduleDoctorPreloader'
import { PAGE_TYPE_DATA } from 'modules/Schedule/constants'
import { scheduleRequest } from 'modules/Schedule/api'
import {
  getVisitType,
  redirectToDoctorApp,
  getDoctorsRequestData,
  changeSelectedWorkplace,
  getDoctorsRequestDataSingle,
} from 'modules/Schedule/functions'

/**
 * @description
 * Модуль наследуется от базового и используется на страницах.
 * Его задача рендерить расписание на странице списка врачей и странице врача.
 * Он учитывает пользовательские параметры для правильного запроса(для онлайн-города или нет).
 * Общие задачи модуля и связность с расписанием:
 * 1. Формирование данных для запроса => запрос => отрисовка расписания списка врачей/1 врача.
 * 2. Манипуляция прелоадерами, пока расписания нет на странице.
 * 3. Редирект на форму записи во время клика по кнопке 'Записаться' | 'Вызвать врача на дом'.
 * 4. Обработка переключения выбранного workplace(если модуль вызван на странице списка врачей).
 * 5. Обработка переключения табов типа приема(клуб, онлайн и тд) и соответствующие запросы, если это необходимо(онлайн-город и пересчет расписания)
 * 6. Переключение кнопки 'Вызвать врача на дом'.
 *    За это отвечает модуль 'DoctorCardEmitter'.
 *    Он в свою очередь также отслеживает действия пользователя и исполняет переданные callback во время наступления событий.
 * */
class ScheduleDoctors extends ScheduleCore {
  #preloaderInstance = new ScheduleDoctorPreloader()

  /**
     * @description
     * Во время исполнения конструктора определяется поле 'pageType', которое нужно дла разграничения общих функций.
     * Создается инстанс 'DoctorCardEmitter', собираются данные для запроса за расписанием и производится его рендер.
     *
     * @param { Object } opts - опции настройки модуля, которые имеют интерфейс 'getModuleOptionsInterface'.
     * */
  constructor(opts) {
    opts.pageType = PAGE_TYPE_DATA.doctors

    super(opts)

    new DoctorCardEmitter({
      cardSelector: `[${this.options.dataItem}]`,
      doctorPageType: this.options.doctorPageType,
      hookTabChange: this.hookTabChange.bind(this),
      hookOptionClick: this.hookOptionClick.bind(this),
      hookAppButtonClick: this.hookAppButtonClick.bind(this),
    })

    if (!this.validator({ getter: 'testPageData' })) {
      this.#preloaderInstance.hide()
      return
    }

    try {
      this.updateRequestData({
        requestData: getDoctorsRequestData({ data: this.data }),
      })

      if (!this.validator({ getter: 'testRequestData' })) {
        this.#preloaderInstance.hide()
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(this.resolveRequest.bind(this))
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Используется во время мутаций DOM дерева.
     * Поскольку дерево мутирует(меняется HTML во время фильтрации, пагинации) - меняются врачи, значит необходимо обновить у них расписание.
     * */
  init() {
    super.init()

    try {
      if (!this.validator({ getter: 'testPageData' })) {
        this.#preloaderInstance.hide()
        return
      }

      this.updateRequestData({
        requestData: getDoctorsRequestData({ data: this.data }),
      })

      if (!this.validator({ getter: 'testRequestData' })) {
        this.#preloaderInstance.hide()
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(this.resolveRequest.bind(this))
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Метод, который вызывается во время смены таба типа приема.
     * В этом методе производится обработка выбора таба 'Онлайн' и пересчет расписания.
     *
     * @param { Object } payload
     * @param { HTMLElement } payload.target - элемент, по которому произошел клик.
     * @param { Boolean } payload.isOtherSelected - true, если ушли с таба `Онлайн` (телемед).
     * @param { Boolean } payload.isOnlineSelected - true, если перешли на таб `Онлайн` (телемед).
     * @param { Boolean } payload.isNeedRecalcCalendar - true, если необходим пересчёт слотов по времени пользователя или клиники.
     * @param { Boolean } payload.isForFilter - true, если hookTabChange вызывается на странице врача при фильтрации по типу приёма.
     * */
  hookTabChange(payload) {
    const {
      target,
      isOnlineSelected,
      isNeedRecalcCalendar,
      isForFilter,
    } = payload

    if (!isNeedRecalcCalendar) {
      return
    }

    try {
      const parentCard = target?.closest(`[${this.options.dataItem}]`) // '?.' на случай если target не передан или не определен
      const requestData = getDoctorsRequestDataSingle({
        target,
        data: this.data,
        isOnlineSelected,
        isForFilter,
      })

      if (!requestData) { // если не найдено данных для врача или timezone пользователя и сервера совпадает - запрос не нужен
        return
      }

      this.updateRequestData({ requestData })

      if (!this.validator({ getter: 'testRequestData' })) {
        this.#preloaderInstance.hide()
        return
      }

      scheduleRequest({
        data: this.data.request,
        pageType: this.options.pageType,
      })
        .then(response => { this.resolveRequest(response, parentCard) })
        .catch(this.rejectRequest.bind(this))
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Производит переключение выбранного workplace на странице списка врачей.
     *
     * @param { Object } opts
     * @param { HTMLElement } opts.card - карточка врача, в которой необходима смена workplace
     * @param { Number } opts.position - номер workplace(в select с выбором workplace есть атрибут, который содержит номер позиции, а переключаемый div имеет также соответствующий номер)
     * */
  hookOptionClick({ card, position }) { changeSelectedWorkplace({ card, position }) }

  /**
     * @public
     * @description
     * Производит редирект на форму записи по клику на кнопку 'Записаться' | 'Вызвать врача на дом'.
     *
     * @param { Object } opts
     * @param { HTMLElement } opts.appointmentButton - кнопка записи, по которой произошел клик.
     * */
  hookAppButtonClick({ appointmentButton }) {
    try {
      const visitType = getVisitType({
        target: appointmentButton,
        dataItem: this.options.dataItem,
      })

      redirectToDoctorApp({
        visitType,
        data: this.data,
        dataItem: this.options.dataItem,
        buttonNode: appointmentButton,
      })
    } catch (error) { this.createNotice(error) }
  }

  /**
     * @public
     * @description
     * Срабатывает во время неудачного рендера расписания или ошибки.
     * */
  hookRenderError() { this.#preloaderInstance.hide() }

  /**
     * @public
     * @description
     * Срабатывает во время успешного рендера расписания
     * */
  hookRenderSuccess() { this.#preloaderInstance.hide() }
}

export default ScheduleDoctors
