import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import Award from 'components/common/Award/components/common/Award'
import { MOUNT_SELECTOR } from 'components/common/Award/constants'

const mountElement = document.querySelector(MOUNT_SELECTOR)

if (mountElement) {
  new Vue({
    vuetify,
    el: MOUNT_SELECTOR,
    render: h => h(Award),
  })
}
