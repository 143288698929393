import { deepMerge, getTypeObject } from 'utils'

/**
 * @author Быков Александр(@bykov)
 * */

class ClassSwitcher {
  constructor(options) {
    this.init(options)
  }

  init(options = {}) {
    const merged = this._extendOptions(options)

    this.options = merged

    if (!merged.button) {
      return
    }

    this._searchButtonCollection()

    document.addEventListener('click', this._click)
  }

  add(position) {
    this._getButton(position)

    if (!this.button) {
      return
    }

    const status = this.button.classList.contains(this.options.buttonActiveClass)

    if (status) {
      return
    }

    this.method = 'add'

    const parent = this.button.closest(this.options.parent)

    this.parent = parent

    if (this._hasField('beforeAdd')) {
      this.options.beforeAdd({
        parent,
        button: this.button,
        options: this.options,
      }, this._toggleClass)

      return
    }

    this._toggleClass()
  }

  remove(position) {
    this._getButton(position)

    const hasField = this._hasField

    if (!this.button) {
      return
    }

    const status = this.button.classList.contains(this.options.buttonActiveClass)

    if (!status) {
      return
    }

    this.method = 'remove'

    const parent = this.button.closest(this.options.parent)

    this.parent = parent

    if (hasField('beforeRemove')) {
      this.options.beforeRemove({
        parent,
        button: this.button,
        options: this.options,
      }, this._toggleClass)

      return
    }

    this._toggleClass()
  }

  switch(position) {
    this._getButton(position)

    if (!this.button) {
      return
    }

    const status = this.button.classList.contains(this.options.buttonActiveClass)
    const method = status ? 'remove' : 'add'

    this[method](this.button)
  }

  _toggleClass = () => {
    const actionText = this.method === 'add' ? 'added' : 'removed'

    if (this.parent) {
      this.parent.classList[this.method](this.options.parentActiveClass)
    }

    if (this.options.buttonText[actionText]) {
      this.button.textContent = this.options.buttonText[actionText]
    }

    this.button.classList[this.method](this.options.buttonActiveClass)

    if (this.method === 'add' && this._hasField('afterAdd')) {
      this.options.afterAdd({
        parent: this.parent,
        button: this.button,
        options: this.options,
      })

      return
    }

    if (this.method === 'remove' && this._hasField('afterRemove')) {
      this.options.afterRemove({
        parent: this.parent,
        button: this.button,
        options: this.options,
      })

      this.button = null
    }
  }

  _hasField = field => this.options[field]

  _getButton(position) {
    const type = getTypeObject(position)

    if (type === 'number') {
      const sought = this.buttonCollection[position]

      if (sought) {
        this.button = sought
      }
    } else if (type.includes('element')) {
      this.button = position
    }
  }

  _searchButtonCollection() {
    this.buttonCollection = [...document.querySelectorAll(this.options.button)]
  }

  _click = event => {
    const { eventOutTarget } = this.options
    const button = event.target.closest(this.options.button)

    if (button) {
      this.button = button
      this.switch()

      return
    }

    eventOutTarget({
      event,
      options: this.options,
    })
  }

  _extendOptions(options) {
    return deepMerge({
      parent: null,
      button: null,
      buttonText: {},
      afterAdd: null,
      beforeAdd: null,
      afterRemove: null,
      beforeRemove: null,
      parentActiveClass: 'b-class-switcher__parent_active',
      buttonActiveClass: 'b-class-switcher__button_active',
      eventOutTarget: () => {},
    }, options)
  }
}

export default ClassSwitcher
