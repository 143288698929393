var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("VSelect", {
        attrs: {
          value: 1,
          label: _vm.label,
          "append-icon": "ui-icon-arrow-down",
          items: [1],
          "data-selected-speciality-id": _setup.selectedItem.speciality?.id,
          "menu-props": {
            disabled: true,
          },
          outlined: "",
          "hide-details": "",
        },
        on: { click: _setup.openDialog },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function () {
              return [
                _c("div", { staticClass: "text-break" }, [
                  _setup.selectedItem.isWorkplace
                    ? _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_setup.selectedItem.name) +
                            " - приём\n        "
                        ),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(_setup.selectedItem.name) +
                            "\n        "
                        ),
                      ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "ui-text ui-text_body-2 uiKitTextSecondary--text",
                    },
                    [
                      _setup.selectedItem.isWorkplace
                        ? _c("span", [
                            _vm._v(_vm._s(_setup.formattedSelectPrice)),
                          ])
                        : _c("span", [
                            _vm._v(_vm._s(_setup.selectedItem.price)),
                          ]),
                      _vm._v(" "),
                      _setup.selectedItem.agesText
                        ? [
                            _c(
                              "span",
                              { staticClass: "uiKitBgGray80--text mx-1" },
                              [_vm._v("•")]
                            ),
                            _vm._v(
                              "\n            " +
                                _vm._s(_setup.selectedItem.agesText) +
                                "\n          "
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(_setup.WorkplaceOrServiceDialog, {
        attrs: {
          "lpu-id": _vm.lpuId,
          "init-workplaces": _setup.filterWorkplaces,
          "init-services":
            _setup.filterType === "clinic" ? _vm.initServices : [],
        },
        on: { "item-selected": _setup.handleSelectItem },
        model: {
          value: _setup.isDialogVisible,
          callback: function ($$v) {
            _setup.isDialogVisible = $$v
          },
          expression: "isDialogVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }