import Hint from 'modules/Hint'

const mobileSelector = '.b-without-gratitude_mobile'
const targetHintSelector = '.b-without-gratitude__question'

const isMobile = document.querySelector(mobileSelector)
const placement = isMobile ? 'bottom' : 'right'
const trigger = isMobile ? 'click' : 'mouseenter'

new Hint({
  isMobile,
  text: 'Если вы благодарны врачу за лечение, достаточно сказать ему об этом в отзыве. Врач считает, что деньги или конфеты — лишнее.',
  target: targetHintSelector,
  setMaxWidth: 'small',
  tippyOptions: {
    placement,
    trigger,
    zIndex: 5,
    offset: [0, 15],
  },
})
