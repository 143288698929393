var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "slot-set" },
    [
      _c(
        "div",
        { class: _vm.containerClassList },
        [
          _vm._l(
            _vm.dynamicSlotSet[_vm.selectedDate],
            function (currentSlot, index) {
              return _c(
                "VBtn",
                {
                  key: `${_vm.selectedDate}-${index}`,
                  class: _vm.getSlotClassList(currentSlot),
                  attrs: {
                    "data-doctor": _vm.doctorId,
                    "data-qa": _vm.QALocators["button-slot"],
                    color: _vm.getSlotColor(currentSlot),
                    depressed: "",
                    height: "40",
                    "min-width": "initial",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickSlot($event, currentSlot)
                    },
                  },
                },
                [
                  _c("span", { class: _vm.getTimeClassList(currentSlot) }, [
                    _vm._v(
                      "\n        " + _vm._s(currentSlot.time) + "\n      "
                    ),
                  ]),
                ]
              )
            }
          ),
          _vm._v(" "),
          _vm.hasExpandButton
            ? _c(
                "VBtn",
                {
                  class: _vm.getSlotClassList(),
                  attrs: {
                    color: _vm.vuetifyColors.uiKitBgPrimary,
                    "data-qa": _vm.QALocators["button-expand"],
                    depressed: "",
                    height: "40",
                    "min-width": "initial",
                  },
                  on: { click: _vm.handleClickExpand },
                },
                [
                  _c(
                    "VIcon",
                    {
                      staticClass: "slot-set__toggle-icon",
                      attrs: { size: 20 },
                    },
                    [_vm._v("\n        ui-icon-arrow-down\n      ")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.expandedState
        ? _c(
            "VBtn",
            {
              attrs: {
                text: "",
                block: "",
                "data-qa": _vm.QALocators["button-collapse"],
                color: _vm.vuetifyColors.primary,
              },
              on: { click: _vm.handleClickCollapse },
            },
            [
              _c(
                "VIcon",
                {
                  staticClass: "slot-set__toggle-icon mr-2",
                  attrs: { size: 20 },
                },
                [_vm._v("\n      ui-icon-arrow-up\n    ")]
              ),
              _vm._v("\n    Свернуть\n  "),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }