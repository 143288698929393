/**
 * @author Быков Александр(@bykov)
 * */

export default class {
  constructor(options) {
    this.init(options)
  }

  init(options = {}) {
    this._createOptions(options)
    this._changeStateListener('add')
  }

  detach() {
    this._changeStateListener('remove')
  }

  _changeStateListener(action) {
    const { options } = this.options
    const method = `${action}EventListener`

    document[method]('click', this._eventClick, options)
  }

  _eventClick = event => {
    const { options } = this
    const { target } = event
    const { click } = options
    const defaultFn = options.default

    const hasCurrentTarget = click.some(data => {
      const currentTarget = target.closest(data.target)

      if (currentTarget) {
        data.handler({ event, currentTarget })
      }

      return !!currentTarget
    })

    if (!hasCurrentTarget) {
      defaultFn({ event })
    }
  }

  _createOptions(options) {
    this.options = {
      click: [],
      options: undefined,
      default: () => {},
      ...options,
    }
  }
}
