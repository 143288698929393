import { STAFF_NAMESPACE } from 'modules/Schedule/constants'

/**
 * @description
 * Производит вывод типа записи под расписанием, в случае если пользователь - staff.
 *
 * @param { Object } opts
 * @param { HTMLElement } opts.coverNode - контейнер, который должен содержать узел для вывода информации о типе записи
 * @param { Object } opts.option - данные выбранной специальности(для страниц с расписанием и выбора специальности)
 * */

const setAppointmentTypeStaff = ({ coverNode, option }) => {
  if (!coverNode) {
    return
  }

  const appTypeStaffElement = coverNode.querySelector(`.${STAFF_NAMESPACE.appointmentType}`)

  if (!appTypeStaffElement) {
    return
  }

  const appTypeStaffInner = appTypeStaffElement.querySelector(`.${STAFF_NAMESPACE.appointmentTypeInner}`)

  if (option?.staff_appointment_type) {
    appTypeStaffElement.hidden = false
    appTypeStaffInner.innerHTML = option.staff_appointment_type
  } else {
    appTypeStaffElement.hidden = true
  }
}

export default setAppointmentTypeStaff
