import { render, staticRenderFns } from "./CalendarStub.vue?vue&type=template&id=34d4f4a1&scoped=true&"
var script = {}
import style0 from "./CalendarStub.vue?vue&type=style&index=0&id=34d4f4a1&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34d4f4a1",
  null
  
)

export default component.exports