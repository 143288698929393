/* eslint-disable */
import { format } from 'date-fns';
import { getTimezoneOffsetInHours } from 'utils';
import { AppointmentModules } from 'modules/appointment-modules';
import { getVisitType } from 'modules/Timetable/functions';
import { APPOINTMENT_TYPES } from 'constants';

const appointmentModules = new AppointmentModules();

function getAppointmentVisitType(target) {
    return getVisitType({
        target,
        options: {
            core: {
                typeRequest: 'doctor',
                namespace: {
                    dataItem: 'data-wp-block-id',
                },
            },
        },
    });
}

$(function () {
    $('.b-doctor-schedule').on('click', function () {
        const $el = $(this);
        const doctorFio = $el.data('doctor-fio');
        const doctorId = $el.data('doctor-id');
        const lpuId = $el.data('lpu-id');
        const lpuTimedelta = $el.data('town-timedelta');
        const lpuName = $el.data('lpu-name');
        const requestData = JSON.parse(this.dataset.reqProps);
        const props = this.dataset['reqProps'];// нужна строка. jQuery data() парсит и приходится обратно переводить в строку
        const appointmentVisitType = getAppointmentVisitType($el[0]);
        const userTimedelta = getTimezoneOffsetInHours();
        const isUserTimedeltaEqualLpu = userTimedelta === lpuTimedelta;

        if (isUserTimedeltaEqualLpu) {
            $(this).off();
        }

        if (appointmentVisitType === APPOINTMENT_TYPES.telemed && !isUserTimedeltaEqualLpu) {
            requestData.town_timedelta = undefined;
            requestData.dt_start = format(new Date(), 'yyyy-MM-dd');
            requestData.user_timedelta = userTimedelta;
            requestData.lpu_timedelta = [[lpuId, lpuTimedelta]];
        }

        $.ajax({
            url: '/ajax/schedule/slots_bulk/',
            type: 'POST',
            data: JSON.stringify(requestData),
            async: true,
            crossDomain: true,
            processData: false,
            headers: {
                "content-type": "application/json"
            },
            success: function (dat) {
                const data = dat.result[0];

                /**
                 * меняет формат представления даты с YYYY-MM-DD на DD.MM.YYY
                 * @param date - строкое представление даты в формате YYYY-MM-DD
                 */
                function dotsDateFormat (date) {
                    return date.split('-').reverse().join('.');
                }

                /**
                 * создает название дня со слотами
                 * @param date - строкое представление даты в формате YYYY-MM-DD
                 * @returns {string} - название дня со слотами (1 января — Пондельник)
                 */
                function spetialFormat (date) {
                    const dayNames = ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'];
                    const monthGenetive = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];

                    /**
                     * К дате добавляется `T00:00:00` для того, чтобы дата интерпретировалась как местное время,
                     * а не как UTC. Это фиксит проблему, когда для даты `2023-09-07` объект возвращался с датой
                     * `2023-09-06T13:00:00`. Это связано с исторической ошибкой спецификации, которая не соответствовала
                     * стандарту ISO 8601, но не могла быть изменена из-за веб-совместимости.
                     * См. - https://maggiepint.com/2017/04/11/fixing-javascript-date-web-compatibility-and-reality/
                     * */
                    const dateObj = new Date(`${date}T00:00:00`);
                    const today = new Date(`${requestData.dt_start}T00:00:00`);

                    if (dateObj.getDate() === today.getDate() && dateObj.getMonth() === today.getMonth() && dateObj.getFullYear() === today.getFullYear()) {
                        return `Сегодня — ${dayNames[dateObj.getDay()]}`;
                    }

                    return `${dateObj.getDate()} ${monthGenetive[dateObj.getMonth()]} — ${dayNames[dateObj.getDay()]}`;
                }

                function renderSlots (slots, date) {
                    let rendered = '';
                    for (let slot of slots) {
                        let slotTemplate = `
                            <div class="b-doctor-schedule__slot b-button ${slot.free ? 'b-button_blue' : 'b-button_disabled'}"
                                data-slot="${dotsDateFormat(date)} ${slot.time}"
                                data-lpu="${lpuId}"
                                data-doctor="${doctorId}"
                                ${slot.free ? '' : 'disabled'}
                            ><span class="ui-text ui-text_button">${slot.time}</span></div>
                        `;
                        rendered += slotTemplate.trim();
                    }
                    return rendered;
                }

                function renderDays (data) {
                    let rendered = '';
                    let days = Object.keys(data.slots);
                    days = days.sort((a, b) => {
                        return new Date(a) - new Date(b);
                    });
                    for (let day of days) {
                        if (data.slots[day].length > 0) {
                            let dayTemplate = `
                                <div class="b-doctor-schedule__day">
                                    ${spetialFormat(day)}
                                </div>
                                <div class="b-doctor-schedule__slots-wrapper">
                                    ${renderSlots(data.slots[day], day)}
                                </div>
                            `;
                            rendered += dayTemplate.trim();
                        }
                    }
                    return rendered;
                }

                let commonTemplate = `
                    <div class="b-doctor-schedule__popup mfp-with-anim">
                        <div class="b-doctor-schedule__header">
                            <div class="b-doctor-schedule__sub-title">Полное расписание врача</div>
                            <div class="b-doctor-schedule__title">${lpuName}</div>
                            <button type="button" class="b-doctor-schedule__close-btn mfp-close">×</button>
                        </div>
                        <div id="schedule" class="b-doctor-schedule__body schedule_doctor" data-source="doctor" data-fio="${doctorFio}">
                            ${renderDays(data)}
                        </div>
                    </div>
                `;

                function redirectToNewAppointment () {
                    const $slotElement = $(this);
                    const appointmentData = {
                        doctor: $slotElement.data('doctor'),
                        lpu: $slotElement.data('lpu'),
                        type: getAppointmentVisitType($el[0]),
                        slot: $slotElement.data('slot'),
                        fio: doctorFio,
                        source: 'doctor'
                    };

                    appointmentModules.redirectToNewAppointment(appointmentData);
                }

                $el.magnificPopup({
                    removalDelay: 200,
                    items: {
                        src: commonTemplate,
                        type: 'inline'
                    },
                    callbacks: {
                        beforeOpen: function () {
                            this.st.mainClass = 'mag-popup';
                            this.st.mainClass += ' ' + $el.attr('data-effect');
                        },
                        open: function () {
                            $(this.contentContainer).on('click', '.b-doctor-schedule__slot:not([disabled])', redirectToNewAppointment);
                        },
                        close: function () {
                            $(this.contentContainer).off('click', '.b-doctor-schedule__slot:not([disabled])', redirectToNewAppointment);
                        },
                    }
                }).magnificPopup('open');
            },
            error: function (x, e, err) {
                console.log(e + '-' + err);
            }
        });
    });
});
