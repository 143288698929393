<template>
  <DialogBottomSheet
    :is-visible="isVisible"
    @click:outside="handleClose"
    @keydown.esc="handleClose"
  >
    <VCard
      ref="preliminary-consultation-modal"
      data-qa="preliminary_consultation_modal"
    >
      <div
        v-if="!isMobile"
        class="p-2"
      >
        <VBtn
          icon
          text
          depressed
          color="uiKitText"
          data-qa="preliminary_consultation_modal_close"
          @click="handleClose"
        >
          <VIcon>
            ui-icon-close-not-a-circle
          </VIcon>
        </VBtn>
      </div>
      <div
        class="text-center"
        :class="{
          'pb-4 px-4 pt-6': isMobile,
          'pb-6 px-6': !isMobile,
        }"
      >
        <VImg
          contain
          width="280"
          height="168"
          class="mx-auto mb-6"
          src="/static/_v1/pd/mascots/doctor-checking-pulse.svg"
          alt="Доктор проверяющий пульс - ПроДокторов"
          data-qa="preliminary_consultation_img"
        />
        <div
          class="ui-text ui-text_h6 ui-kit-color-text mb-2"
          data-qa="preliminary_consultation_title"
        >
          Сначала нужно
          проконсультироваться с&nbsp;врачом
        </div>
        <div
          :class="isMobile ? 'mb-6' : 'mb-8'"
          class="ui-text ui-text_body-1 ui-kit-color-text-secondary"
          data-qa="preliminary_consultation_subtitle"
        >
          Он соберёт&nbsp;анамнез, проверит наличие показаний и противопоказаний
          и&nbsp;уже&nbsp;после этого назначит процедуру.
        </div>
        <div :class="{ 'd-flex flex-row-reverse': !isMobile }">
          <VBtn
            depressed
            color="primary"
            data-qa="preliminary_consultation_btn_continue"
            class="px-4"
            :class="{ 'mb-2': isMobile }"
            :block="isMobile"
            @click="handleContinue"
          >
            Записаться к врачу
          </VBtn>
          <VBtn
            text
            color="uiKitText"
            data-qa="preliminary_consultation_btn_cancel"
            class="px-2"
            :class="{ 'mr-4': !isMobile }"
            :block="isMobile"
            @click="handleClose"
          >
            Отменить
          </VBtn>
        </div>
      </div>
    </VCard>
  </DialogBottomSheet>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import DialogBottomSheet from 'components/common/core/DialogBottomSheet'

export default {
  name: 'PreliminaryConsultationModal',
  components: {
    DialogBottomSheet,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  watch: {
    async isVisible(isVisible) {
      await this.$nextTick() // дожидаемся рендера модалки чтобы получить содержимое через $refs

      this.changeBodyScrollLock(isVisible)

      if (isVisible) {
        this.$emit('preliminary-consultation-modal:visible')
      }
    },
  },
  methods: {
    changeBodyScrollLock(isLock) {
      const modalElement = this.$refs['preliminary-consultation-modal'].$el

      if (isLock) {
        disableBodyScroll(modalElement)
      } else {
        enableBodyScroll(modalElement)
      }
    },
    handleClose() {
      this.$emit('preliminary-consultation-modal:click-close')
    },
    handleContinue() {
      this.$emit('preliminary-consultation-modal:click-continue')
    },
  },
}
</script>
