var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.hasOptions
    ? _c(
        "div",
        [
          _c(
            _vm.currentComponent,
            _vm._b(
              {
                tag: "component",
                attrs: {
                  "data-qa": "select_modal",
                  "is-visible-modal": _vm.isSelectModalVisible,
                },
                on: _vm._d({}, [
                  _vm.eventClickSelect,
                  _vm.handleClickSelect,
                  _vm.eventClickOption,
                  _vm.handleClickOption,
                  _vm.eventClickModalClose,
                  _vm.handleCloseModal,
                ]),
              },
              "component",
              { ..._vm.$attrs, ..._vm.$props },
              false
            )
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }