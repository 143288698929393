import { axiosClient, testRequiredOptions } from 'utils'

/**
 * @description
 * Производит запрос за списком специальностей переданных клиник
 *
 * @param { Object } opts
 * @param { Array<Number> } opts.lpusId - список lpu id
 *
 * @return { Promise }
 * */

const specialtiesRequest = ({ lpusId }) => (
  new Promise((resolve, reject) => {
    const testOptions = testRequiredOptions({
      module: 'specialtiesRequest',
      requiredOptions: { lpusId },
    })

    if (!testOptions) {
      reject()
      return
    }

    axiosClient.post('/ajax/lpus/specialities/', { lpus: lpusId })
      .then(({ data }) => resolve(data))
      .catch(error => reject(error))
  })
)

export default specialtiesRequest
