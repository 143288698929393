class Tooltip {
  constructor() {
    this.mainClass = 'b-tooltip'
    this.buttonClass = 'b-tooltip__button'
    this.hintClass = 'b-tooltip__hint'
    this.container = document.querySelector(`.${this.mainClass}`)
    this.heightViewport = document.documentElement.clientHeight
    this.isShow = false
    this.init()
  }

  init() {
    const { container } = this

    if (!container) {
      return
    }

    this.deviceType = container.dataset.deviceType

    if (this.deviceType === 'full') {
      return
    }

    this.button = container.querySelector(`.${this.buttonClass}`)
    this.hint = container.querySelector(`.${this.hintClass}`)
    this.hintHorizontalPosition = this.hint.dataset.horizontalPosition || 'left'

    if (!this.button && !this.hint) {
      return
    }

    this._setPositionHint()
    document.addEventListener('click', this._eventClick)
  }

  hintHide() {
    this.isShow = false
    this.hint.classList.remove(`${this.hintClass}_show`)
  }

  hintShow() {
    this.isShow = true
    this.hint.classList.add(`${this.hintClass}_show`)
  }

  _eventClick = ({ target }) => {
    if (target.closest(`.${this.buttonClass}`) && !this.isShow) {
      this._setPositionHint()
      this.hintShow()
    } else {
      this.hintHide()
    }
  }

  _setPositionHint() {
    const { button, hint } = this
    const btnCoordinate = button.getBoundingClientRect().top + button.getBoundingClientRect().height
    const diffPosition = btnCoordinate + hint.getBoundingClientRect().height >= this.heightViewport

    this._changePosition(diffPosition)
  }

  _changePosition(diffPosition) {
    const { hint, hintClass, hintHorizontalPosition } = this
    const position = diffPosition ? 'top' : 'bottom'
    const reversePosition = !diffPosition ? 'top' : 'bottom'

    hint.classList.remove(`${hintClass}_${reversePosition}-${hintHorizontalPosition}`)
    hint.classList.add(`${hintClass}_${position}-${hintHorizontalPosition}`)
  }
}

new Tooltip()
