import { isUsedLocalStorage, getBrowserCore } from 'utils'
import UserStorage from 'modules/UserStorage'
import getNamespace from 'modules/Modal/functions'
import {
  STATE,
  ACTIONS,
  MODIFIER,
  ROUTER_KEY,
} from 'modules/Modal/constants'
import { TRIDENT } from 'constants/browserCores'

/**
 * @author Быков Александр(@bykov)
 * */

class Router {
  constructor({ instance } = {}) {
    this._createBaseProps(instance)

    if (!this.usedStorage) {
      this._removeHashId()
      return
    }

    this._createUserStorage()
    this._createLoadedStack()
    this._createEventListeners()

    /**
         * Вывожу из потока, чтобы оба инстанса успели инициализироваться
         * Иначе при вызове метода без задержки и открытии окна вылетают ошибки
         * */

    setTimeout(this._showStarted)
  }

  /**
     * @description
     * Добавляю данные в стек и работаю с историей записей(при условии включенного useHash)
     * Исключение составляет IE(подробнее в readme)
     * */

  back = () => {
    if (!this.usedStorage) {
      return
    }

    const { useHash } = this.options

    this._updateStack({ method: this.actions.remove })

    if (useHash && this.browser !== TRIDENT) {
      window.history.back()
    }
  }

  forward = () => {
    if (!this.usedStorage) {
      return
    }

    const { id, useHash } = this.options

    this._updateStack({ method: this.actions.add })

    if (useHash && this.browser !== TRIDENT) {
      window.history.pushState(null, null, `#${id}`)
    }
  }

  /**
     * @description
     * Метод обновляет стеки вызовов, которые используются в модуле(каждый стек для своих нужд)
     * stack - последовательная история вызовов(вне зависимости от настройки useHash в инстансе)(используется для работы со скроллом)
     * stackWithHash - последовательная история вызовов тех модалок, у которых ВКЛЮЧЕНА опция useHash(используется для работы с кнопками back/forward в браузере)
     * stackWithoutHash - последовательная история вызовов тех модалок, у которых ВЫКЛЮЧЕНА опция useHash(пока не используется)
     *
     * Метод единовременно работает со всеми 3-мя стеками. Добавляет id когда нужно/удаляет если есть
     *
     * @params { Object } options
     * @params { String } [options.method] - название метода(add || remove)
     * @params { String } options.customId - принудительно работает с переданным id в стеках. Имеет приоритет над id из настроек(реализовано для внутренних нужд)
     * */

  _updateStack({ method, customId } = {}) {
    const { id, useHash } = this.options
    const currentId = customId || id
    const storageData = this.storage.get()

    if (!storageData) {
      return
    }

    const {
      stack,
      historyFull,
      historyAnchor,
      stackWithHash,
      stackWithoutHash,
    } = storageData
    const storageStack = useHash ? stackWithHash : stackWithoutHash

    if (method === this.actions.add && storageStack.indexOf(currentId) === -1) {
      stack.push(currentId)
      storageStack.push(currentId)
    }

    if (method === this.actions.remove) {
      const positionInCommon = stack.indexOf(currentId)
      const positionInUsedStack = storageStack.indexOf(currentId)

      if (positionInUsedStack !== -1) {
        storageStack.splice(positionInUsedStack, 1)
      }

      if (positionInCommon !== -1) {
        stack.splice(positionInCommon, 1)
      }
    }

    if (historyAnchor.length > 10) {
      historyAnchor.shift()
    }

    if (historyFull.length > 10) {
      historyFull.shift()
    }

    historyAnchor.push(currentId)
    historyFull.push(window.location.pathname)

    this.storage.set(storageData)
  }

  /**
     * @description
     * Метод обновляет данные стека в зависимости от параметров
     * При инициализации класса
     * Сильно упрощает разработку и отладку при переключении опции "useHash"
     * */

  _createLoadedStack() {
    const storageData = this.storage.get()
    const { id, useHash } = this.options
    const { stack, stackWithHash } = storageData
    const positionInStack = stack.indexOf(id)
    const positionInStackWithHash = stackWithHash.indexOf(id)

    if (!useHash && positionInStackWithHash !== -1) {
      stackWithHash.splice(positionInStackWithHash, 1)
    }

    if (!useHash && positionInStack !== -1) {
      stack.splice(positionInStack, 1)
    }

    storageData.stackWithoutHash = []

    this.storage.set(storageData)
  }

  /**
     * Общие методы
     * */

  _createBaseProps(instance) {
    this.browser = getBrowserCore()
    this.state = STATE
    this.actions = ACTIONS
    this.routerKey = ROUTER_KEY
    this.attr = getNamespace({ modifier: MODIFIER })
    this.instanceModal = instance
    this.nodes = instance.nodes
    this.options = instance.options
    this.changeScroll = instance.changeScroll
    this.updateVisibility = instance.updateVisibility
    this.usedStorage = isUsedLocalStorage()
  }

  _createUserStorage() {
    this.storage = new UserStorage({
      key: this.routerKey,
      data: {
        stack: [],
        historyFull: [],
        historyAnchor: [],
        stackWithHash: [],
        stackWithoutHash: [],
      },
    })
  }

  _createEventListeners() {
    if (this.browser === TRIDENT) {
      return
    }

    window.removeEventListener('hashchange', this._eventHashchange)
    window.addEventListener('hashchange', this._eventHashchange)
  }

  _removeHashId() {
    const hashId = window.location.hash.replace('#', '')

    if (hashId === this.options.id) {
      window.history.replaceState(null, null, './')
    }
  }

  _testIsModal({ target } = {}) {
    if (!target) {
      return
    }

    return target.classList.contains(this.attr.cover)
  }

  /**
     * Работа с событиями
     * */

  _eventHashchange = ({ newURL, oldURL }) => {
    const { add, remove } = this.actions
    const { stackWithHash } = this.storage.get() || {}
    const oldHashId = new URL(oldURL).hash.replace('#', '')
    const newHashId = new URL(newURL).hash.replace('#', '')
    const hasIdInStack = stackWithHash ? stackWithHash.find(item => newHashId === item) : false

    /**
         * Если новый хеш непустая строка и этого хеша нет в стеке и узел на этому id найден на странице
         * Это значит, что пользователь нажал вперед
         * Все остальное - клик назад
         * */

    if (newHashId.length && !hasIdInStack) {
      const cover = document.getElementById(newHashId)
      const isModal = this._testIsModal({ target: cover })

      if (isModal) {
        this._show({
          target: cover,
          callback: () => {
            this._updateStack({ method: add, customId: newHashId })
          },
        })

        return
      }
    }

    this._hide({
      id: oldHashId,
      callback: () => {
        this._updateStack({ method: remove, customId: oldHashId })
      },
    })
  }

  /**
     * @description
     * Работа с состоянием модальных окон после перезагрузки страницы.
     * Включает возможность поделиться(когда пользователь сразу открывает окно с помощью анкорной ссылки)
     * Если стек пустой, но есть совпадение id экземпляра с хешем - значит пользователь на странице впервые
     * Иначе выставляю состояние только по позиции в стеке
     * Ибо пользователь может открыть 2 окна и перезагрузить страницу - должно открыться 2(поэтому не смотрю на хеш)
     * */

  _showStarted = () => {
    const { id, useHash } = this.options
    const storageData = this.storage.get()

    if (!storageData) {
      return
    }

    const { historyFull, historyAnchor, stackWithHash } = storageData
    const hashId = window.location.hash.replace('#', '')
    const position = stackWithHash.indexOf(id)
    const lastEntryAnchor = historyAnchor[historyAnchor.length - 1]
    const lastEntryStack = stackWithHash[stackWithHash.length - 1]
    const isFirstVisit = !stackWithHash.length && hashId === id
    const isFirstVisitReload = lastEntryStack === lastEntryAnchor === id === hashId
    const isSecondVisit = position !== -1
    const isReloadPage = window.location.pathname === historyFull[historyFull.length - 1]

    if (!useHash) {
      return
    }

    if (isFirstVisit || isFirstVisitReload) {
      window.history.replaceState(null, null, './')

      setTimeout(() => {
        this._show({
          target: this.nodes.cover,
          callback: () => {
            this._updateStack({ method: this.actions.add })
          },
        })

        window.history.pushState(null, null, `#${id}`)
      })

      return
    }

    if (isSecondVisit && isReloadPage) {
      setTimeout(() => {
        this._show({ target: this.nodes.cover })
      })
    }
  }

  /**
     * @description
     * Переключение состояние окон
     * Без добавления/удаления записей в History
     * А также без добавления в стек вызовов
     *
     * Используется при переключении состояний
     * Когда меняется хеш на странице, а также внутри роутера(переиспользуется однообразный функционал)
     * Поскольку хеш может поменяться и без участия работы модуля
     * Происходит проверка на наличие соответствующего пространства имен
     *
     * @params { Object } options
     * @params { String } options.id - id модального окна, состояние которого необходимо переключить
     * @params { Object } options.target - найденный узел на странице, состояние которого необходимо переключить
     * @params { Function } options.callback - функция, которая предназначена для обновления стека
     *
     * Либо id, либо target - одно поле должно быть обязательно. Если есть оба - приоритет будет у строки [id]
     * */

  _show({ id, target, callback = () => {} } = {}) {
    const { add } = this.actions
    const { disable } = this.state
    const cover = typeof id === 'string' ? document.getElementById(id) : target
    const isModal = this._testIsModal({ target: cover })

    if (!isModal) {
      return
    }

    this.instanceModal.hookBeforeShow()

    cover.classList.add(this.attr.active)

    this.updateVisibility({ method: add, target: cover })
    this.changeScroll({ method: disable, target: cover })

    callback()
  }

  _hide({ id, target, callback = () => {} } = {}) {
    const { remove } = this.actions
    const { enable } = this.state
    const cover = typeof id === 'string' ? document.getElementById(id) : target
    const isModal = this._testIsModal({ target: cover })

    if (!isModal) {
      return
    }

    cover.classList.remove(this.attr.active)

    callback()

    this.updateVisibility({ method: remove, target: cover })
    this.changeScroll({ method: enable, target: cover })

    this.instanceModal.hookAfterHide()
  }
}

export default Router
