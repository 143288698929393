import { format } from 'date-fns'
import { getTimezoneOffsetInHours } from 'utils'
import { getItemDataWithParent } from 'modules/Schedule/functions/helpers'

import {
  getDoctorRequestInterface,
  getDoctorOnlineRequestInterface,
} from 'modules/Schedule/models'

/**
 * @description
 * Собирает данные для запроса за расписанием для 1 врача.
 * Если timezone пользователя и сервера совпадают - запроса быть не должно.
 *
 *
 * @param { Object } opts
 * @param { Object } opts.data - данные 'ScheduleData'
 * @param { Boolean } opts.isOnlineSelected - является ли выбранным таб 'Онлайн'
 * @param { HTMLElement } opts.target - узел, который послужил триггером события
 * @param { Boolean } opts.isForFilter - true, если hookTabChange вызывается на странице врача при фильтрации по типу приёма.
 *
 * @return { Object | void } - объект в формате 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface' если данные успешной собраны.
 * Если не найдено данных для врача или timezone пользователя и сервера совпадает - undefined, так как запрос в этих случаях не нужен
 * */

const getDoctorsRequestDataSingle = ({
  data,
  isOnlineSelected,
  target,
  isForFilter,
}) => {
  const {
    dateStart,
    timedelta,
    doctorsLpus,
  } = data.storage

  const baseData = isOnlineSelected
    ? getDoctorOnlineRequestInterface()
    : getDoctorRequestInterface()

  let coverNode
  let chosenDoctorId

  if (isForFilter) {
    coverNode = target
    chosenDoctorId = Number(JSON.parse(target.getAttribute('data-schedule-item')).doctors.doctorId)
  } else {
    const [, itemData] = getItemDataWithParent({ data, target })

    chosenDoctorId = itemData.doctorId
    coverNode = itemData.coverNode
  }

  const chosenLpuId = Number(
    coverNode.dataset.wpBlockId
        || coverNode.querySelector('option:checked')?.dataset.lpu, // ToDo schedule-important избавиться от привязки к DOM дереву
  )

  const doctorHasSlots = doctorsLpus
    .find(({ has_slots: hasSlots, doctor_id: doctorId, lpu_id: lpuId }) => (
      (typeof hasSlots === 'undefined' ? true : hasSlots) // на странице врачей клиники поле 'has_slots' отсутствует
      && doctorId === chosenDoctorId
      && lpuId === chosenLpuId
    ))

  if (!doctorHasSlots) {
    return
  }

  const lpuTimedelta = Number(doctorHasSlots.lpu_timedelta)
  const userTimedelta = getTimezoneOffsetInHours()
  const isUserTimedeltaEqualLpu = userTimedelta === lpuTimedelta

  if (isUserTimedeltaEqualLpu) {
    return
  }

  baseData.doctors_lpus = [doctorHasSlots]

  if (isOnlineSelected) {
    baseData.dt_start = format(new Date() || 0, 'yyyy-MM-dd') // оператор '||' нужен, чтоб IDE не ругался
    baseData.user_timedelta = userTimedelta
    baseData.lpu_timedelta = [[doctorHasSlots.lpu_id, doctorHasSlots.lpu_timedelta]]
  } else {
    baseData.dt_start = dateStart
    baseData.town_timedelta = timedelta
  }

  return baseData
}

export default getDoctorsRequestDataSingle
