import ClassListMethods from 'modules/ClassListMethods'
import { NAMESPACE } from 'modules/Schedule/classes/ScheduleDoctorPreloader/constants'

/**
 * @description
 * Производит показ/скрытие прелоадера для расписания на врачах(только в мобильной версии).
 * Не принимает аргументы, только манипулирует классами у элементов на странице.
 * Методы показывают/скрывают прелоадеры у всех календарей на странице(то есть не у конкретного расписания).
 * */
class ScheduleDoctorPreloader {
  constructor() {
    this.preloaderInstance = new ClassListMethods({
      containerSelector: NAMESPACE.selectorPreloader, // ToDo schedule-important избавиться от привязки к DOM дереву
      containerClassNameActive: NAMESPACE.classNamePreloaderActive, // ToDo schedule-important избавиться от привязки к DOM дереву
    })
  }

  show() { this.preloaderInstance.remove() }

  hide() { this.preloaderInstance.add() }
}

export default ScheduleDoctorPreloader
