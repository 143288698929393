var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoadingSynonym
        ? _c("SynonymListSkeleton")
        : _c(
            "VList",
            { staticClass: "py-2" },
            _vm._l(_vm.synonymList, function (synonym) {
              return _c(
                "VListItem",
                {
                  key: `${_vm.diagnosticLevel}${synonym.name}`,
                  staticClass: "synonym-list__item",
                  attrs: { "data-qa": "service_synonym_item" },
                  on: {
                    click: function ($event) {
                      return _vm.handleClickItem(synonym)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "mr-2" }, [
                    _vm.isSearchResults
                      ? _c("div", {
                          staticClass:
                            "ui-text ui-text_body-1 ui-kit-color-text",
                          attrs: {
                            "data-qa": "service_synonym_item_title_marked",
                          },
                          domProps: {
                            innerHTML: _vm._s(synonym.nameWithMarkedQuery),
                          },
                        })
                      : _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_body-1 ui-kit-color-text",
                            attrs: { "data-qa": "service_synonym_item_title" },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(synonym.name) +
                                "\n        "
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    synonym.price
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "ui-text ui-text_body-2 ui-kit-color-text-info",
                            attrs: { "data-qa": "service_synonym_item_price" },
                          },
                          [
                            _vm.isUsedClub
                              ? [
                                  synonym.price_with_club
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ui-text ui-kit-color-error pr-1",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(
                                                _vm.getFormattedPrice(
                                                  synonym.price_with_club
                                                )
                                              ) +
                                              "\n            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      class: {
                                        "ui-text_line-through":
                                          synonym.price_with_club,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(
                                            _vm.getFormattedPrice(synonym.price)
                                          ) +
                                          "\n            "
                                      ),
                                    ]
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.getFormattedPrice(synonym.price)
                                      ) +
                                      "\n          "
                                  ),
                                ],
                          ],
                          2
                        )
                      : _vm._e(),
                  ]),
                  _vm._v(" "),
                  _c("span", {
                    staticClass:
                      "ml-auto ui-icon-arrow-right ui-text ui-kit-color-text-info",
                  }),
                ]
              )
            }),
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }