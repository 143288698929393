import { deepMerge } from 'utils'
// noinspection ES6CheckImport
import { REDIRECT_VALUES } from 'constants'
import { CLUB_EVENTS } from 'components/common/Club/constants'
import { NAMESPACE } from 'modules/DoctorCardEmitter/constants'
import { getModuleOptionsInterface } from 'modules/DoctorCardEmitter/models'
import { changeHomeListener, changeCardContent } from 'modules/DoctorCardEmitter/functions'
import { APPOINTMENT_TYPE_FILTER_EVENTS, APPOINTMENT_TYPES } from 'components/common/AppointmentTypeFilterList/constants'

class DoctorCardEmitter {
  #options = getModuleOptionsInterface()

  get options() { return this.#options }

  set options(opts) { this.#options = deepMerge(getModuleOptionsInterface(), opts) }

  constructor(opts) {
    this.options = opts
    this._addEventListener()
  }

  _addEventListener() {
    document.addEventListener('click', this._clickEventListener.bind(this))

    window.addEventListener(
      CLUB_EVENTS.clubAppointmentTypeSelected,
      this._changeTabEventListener.bind(this),
    )

    window.addEventListener(
      APPOINTMENT_TYPE_FILTER_EVENTS.changeCardContent,
      this._changeAppointmentTypeFilter,
    )

    window.addEventListener(
      APPOINTMENT_TYPE_FILTER_EVENTS.recalcSlots,
      this._recalcSlots.bind(this),
    )

    window.addEventListener(
      APPOINTMENT_TYPE_FILTER_EVENTS.filterChosenEvent,
      this._openOnlyFirstVisibleLpuCard,
    )
  }

  _recalcSlots({
    detail: {
      cardContainer,
      redirectValue,
      prevRedirectValue,
    },
  }) {
    const isRedirectToOnline = redirectValue === APPOINTMENT_TYPES.telemed
    const isRedirectFromOnline = redirectValue !== APPOINTMENT_TYPES.telemed
            && prevRedirectValue === APPOINTMENT_TYPES.telemed
    const isNeedRecalcCalendar = isRedirectToOnline || isRedirectFromOnline
    const payload = {
      target: cardContainer,
      isOtherSelected: isRedirectFromOnline,
      isOnlineSelected: isRedirectToOnline,
      isNeedRecalcCalendar,
    }

    this.options.hookTabChange({ ...payload, isForFilter: true })
  }

  _openOnlyFirstVisibleLpuCard() {
    const lpuCards = [...document.querySelectorAll(`.${NAMESPACE.expansionPanelsHead}`)]
    const lpuCardToOpen = lpuCards.find(lpuCard => lpuCard.closest(`.${NAMESPACE.lpuAddressCardWrapper}`).style.display !== 'none')
    const lpuCardToOpenParent = lpuCardToOpen?.closest(`.${NAMESPACE.lpuAddressCardWrapper}`)

    if (!lpuCardToOpenParent?.classList.contains(NAMESPACE.expansionPanelsPanelActive)) {
      lpuCardToOpen?.click()
    }

    [...document.querySelectorAll(`.${NAMESPACE.lpuAddressCardWrapper}`)].forEach(lpuCard => {
      if (lpuCard.isEqualNode(lpuCardToOpenParent)) {
        return
      }

      if (lpuCard.classList.contains(NAMESPACE.expansionPanelsPanelActive)) {
        lpuCard.querySelector(`.${NAMESPACE.expansionPanelsHead}`).click()
      }
    })
  }

  _changeAppointmentTypeFilter({
    detail: {
      cardContainer,
      redirectValue,
    },
  }) {
    changeCardContent({
      cardContainer,
      redirectValue,
    })
  }

  _clickEventListener(event) {
    const { target } = event

    if (!target) {
      return
    }

    const option = target.closest(NAMESPACE.selectorSelectOption)
    const formPRG = target.closest(NAMESPACE.prgSelector)
    const appointmentHomeButton = target.closest(NAMESPACE.selectorDoctorAppointmentHomeButton)
    const appointmentListPageButton = target.closest(NAMESPACE.selectorDoctorAppointmentButton)
    const appointmentInnerPageButton = target.closest(NAMESPACE.selectorDoctorInnerAppointmentButton)
    const bottomSheetAppointmentButton = target.closest(NAMESPACE.selectorBottomSheetAppointmentButton)
    const appointmentButton = appointmentListPageButton
            || appointmentHomeButton
            || appointmentInnerPageButton
            || bottomSheetAppointmentButton // на различных страницах используются разные селекторы

    if (formPRG) { // если обработчик ловит событие на PRG ссылке - редиректить через JS не нужно
      return
    }

    if (option) { // 'option' выбранного workplace врача в 'select'
      const card = target.closest(this.#options.cardSelector)
      const position = option.getAttribute(NAMESPACE.dataValue)

      this.options.hookOptionClick({ card, position })

      return
    }

    if (appointmentButton) { // одна из кнопок редиректа на форму записи('Записаться', 'Вызвать врача на дом')
      event.preventDefault() // на странице может быть кнопка <button> или <a href="#">. Если анкорная ссылка, значит по клику будет скролл к верху страницы. Предотвращаем

      this.options.hookAppButtonClick({ appointmentButton })
    }
  }

  _changeTabEventListener({ detail: { target, redirectValue, prevRedirectValue } }) {
    changeHomeListener({
      target,
      redirectValue,
      cardSelector: this.#options.cardSelector,
      doctorPageType: this.#options.doctorPageType,
    })

    const isOnlineSelected = redirectValue === REDIRECT_VALUES.telemed
    const isOtherSelected = redirectValue !== REDIRECT_VALUES.telemed
            && prevRedirectValue === REDIRECT_VALUES.telemed
    const isNeedRecalcCalendar = isOnlineSelected || isOtherSelected
    const payload = {
      target,
      isOtherSelected, // true - если ушли с таба `Онлайн` (телемед)
      isOnlineSelected, // true - если перешли на таб `Онлайн` (телемед)
      isNeedRecalcCalendar, // true - если необходим пересчёт слотов по времени пользователя или клиники
    }

    this.options.hookTabChange(payload)
  }
}

export default DoctorCardEmitter
