<template>
  <VBottomSheet
    v-model="isVisible"
    data-qa="popup_map_departure_address_container"
    @click:outside="handleCloseDepartureAddressPopup"
    @keydown.esc="handleCloseDepartureAddressPopup"
  >
    <div class="ui-kit-bg-bg-gray-0">
      <div class="text-h6 py-6 pl-4">
        Откуда поедем?
      </div>
      <VTextField
        id="departureAddressInput"
        v-model="departureAddress.text"
        label="Адрес отправления"
        append-icon="ui-icon-geo-detect"
        class="mx-4"
        color="grey darken-1"
        :error-messages="errorMessage"
        outlined
        data-qa="popup_map_departure_address_text_input"
        @input="clearErrorMessagesAndDepartureCoords"
        @click:append="determinateCoords"
      />
      <div class="d-flex justify-content-end pb-4">
        <VBtn
          class="mr-2 px-2"
          text
          data-qa="popup_map_cancel_button"
          @click="handleCloseDepartureAddressPopup"
        >
          Отменить
        </VBtn>
        <VBtn
          color="primary"
          class="mr-2 px-2"
          text
          data-qa="popup_map_plot_route_button"
          :disabled="Boolean(!departureAddress.text || errorMessage)"
          @click="handlePlotRouteButton"
        >
          Проложить маршрут
        </VBtn>
      </div>
    </div>
  </VBottomSheet>
</template>

<script>
import { PopupDepartureAddressLogic } from 'components/common/PopupMap/mixins'

export default {
  name: 'PopupDepartureAddressMobile',
  mixins: [PopupDepartureAddressLogic],
}
</script>
