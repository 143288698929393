import {
  getLpuItemInterface,
  getDoctorsItemInterface,
  getServicesItemInterface,
  getDoctorsLpuItemInterface,

  getLpuStorageInterface,
  getDoctorsStorageInterface,
  getServicesStorageInterface,
  getDoctorsLpuStorageInterface,
} from 'modules/Schedule/models'

import { PAGE_TYPE_DATA } from 'modules/Schedule/constants'

/**
 * @description
 * Возвращает нужный интерфейс данных страницы в зависимости от переданного аргумента
 *
 * @param { String } pageType - одна из констант 'PAGE_TYPE_DATA'
 * @return { Object } - объект с 2 интерфейсами
 * */

const getPageDataInterface = pageType => {
  switch (pageType) {
    case PAGE_TYPE_DATA.lpu:
      return {
        itemsInterface: getLpuItemInterface(),
        storageInterface: getLpuStorageInterface(),
      }
    case PAGE_TYPE_DATA.doctors:
      return {
        itemsInterface: getDoctorsItemInterface(),
        storageInterface: getDoctorsStorageInterface(),
      }
    case PAGE_TYPE_DATA.services:
      return {
        itemsInterface: getServicesItemInterface(),
        storageInterface: getServicesStorageInterface(),
      }
    case PAGE_TYPE_DATA.doctorsLpu:
      return {
        itemsInterface: getDoctorsLpuItemInterface(),
        storageInterface: getDoctorsLpuStorageInterface(),
      }
  }
}

export default getPageDataInterface
