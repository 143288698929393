var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isMobile
    ? _c("div", { staticClass: "b-card__appointment-calendar" }, [
        _c(
          "a",
          {
            staticClass: "b-card__appointment-btn b-button b-button_blue",
            attrs: { href: _vm.doctorHref },
          },
          [
            _c("span", { staticClass: "ui-text ui-text_button" }, [
              _vm._v("\n      Запись на приём\n    "),
            ]),
          ]
        ),
      ])
    : _c("div", { staticClass: "b-card__appointment-calendar" }, [
        _c("div", { staticClass: "b-card__appointment-calendar-empty-slots" }, [
          _c(
            "div",
            { staticClass: "b-card__appointment-calendar-empty-slots-inner" },
            [
              _c("div", [_vm._v("Расписание недоступно")]),
              _vm._v(" "),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "b-button b-button_blue b-card__appointment-btn",
                  attrs: { href: _vm.doctorHref },
                },
                [
                  _c("span", { staticClass: "ui-text ui-text_button" }, [
                    _vm._v("\n          Запись на приём\n        "),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }