import { serializeObject, getBrowserCore, axiosClient } from 'utils'
import Modal from 'modules/Modal/index'
import YaGoals from 'modules/YandexGoals'

let receivedRequest = false

function iframeTemplate(path) {
  return `<iframe src="${path}"
                frameborder="0"
                class="b-gratitudes__iframe"
                allow="payment"
            ></iframe>`
}

function renderIframe(path) {
  const containerIframe = document.querySelector('.b-gratitudes__iframe-wrapper')

  containerIframe.innerHTML = iframeTemplate(path)
}

async function requestPayment({ url, csrfData }) {
  const response = await axiosClient.post(url, csrfData)
    .then(res => res.data)
    .catch(() => {
      // eslint-disable-next-line no-console
      console.error('Ошибка создания кошелька врача')
    })

  if (response) {
    receivedRequest = true

    const { link } = response

    renderIframe(link)

    const gratitudesNode = document.querySelector('.b-gratitudes')

    if (gratitudesNode && !gratitudesNode.getAttribute('data-iframe-link')) {
      gratitudesNode.setAttribute('data-iframe-link', link)
    }
  }
}

function getRequestData() {
  const paymentLink = document.getElementById('payment-link')

  if (!paymentLink) {
    return
  }

  const url = paymentLink.dataset.request
  const csrfToken = paymentLink.dataset.csrf

  if (!url || !csrfToken) {
    return
  }

  const csrfData = serializeObject({
    csrfmiddlewaretoken: csrfToken,
  })

  return {
    url,
    csrfData,
  }
}

function isOldBrowserVersion() {
  const agent = navigator.userAgent
  const iphone = agent.match(/iPhone OS (?<version>\d\d)/)
  const isOldIphone = iphone ? iphone.groups.version < 13 : false
  const isOldBrowser = getBrowserCore() === 'trident'

  return isOldIphone || isOldBrowser
}

function getTemplate() {
  const gratitudesNode = document.querySelector('.b-gratitudes')
  const agreementLink = gratitudesNode.getAttribute('data-agreement-link')
  const iframeLink = gratitudesNode.getAttribute('data-iframe-link')
  const deviceType = gratitudesNode.getAttribute('data-device-type')

  const headerTemplate = `<p class="ui-text ui-text_h6 ui-kit-color-text mb-6">Благодарность врачу</p>
                            <button class="b-gratitudes__close ui-icon-close-not-a-circle ui-text ui-kit-color-text-secondary" type="button"></button>`
  const preloaderTemplate = '<div class="b-gratitudes__preloader"></div>'

  return `<div class="b-gratitudes__container">
                ${deviceType === 'mobile' ? '' : headerTemplate}
                <dl class="b-gratitudes__faq">
                    <dt class="ui-text ui-text_subtitle-1 ui-kit-color-text">Для чего это?</dt>
                    <dd class="b-gratitudes__answer ui-text ui-text_body-1 ui-kit-color-text">
                        На случай если вы желаете поблагодарить врача за лечение,
                        но не хотите дарить деньги лично.
                    </dd>
                    <dt class="ui-text ui-text_subtitle-1 ui-kit-color-text">Как это работает?</dt>
                    <dd class="b-gratitudes__answer ui-text ui-text_body-1 ui-kit-color-text">
                        Деньги отправляются на личный счёт врача. Клиника здесь не участвует.
                    </dd>
                    <dt class="ui-text ui-text_subtitle-1 ui-kit-color-text">Врач не будет против?</dt>
                    <dd class="b-gratitudes__answer ui-text ui-text_body-1 ui-kit-color-text">
                        Нет. Врач разрешил добавить «благодарность» на свою страницу.
                    </dd>
                    <dt class="ui-text ui-text_subtitle-1 ui-kit-color-text">ПроДокторов берёт себе процент?</dt>
                    <dd class="b-gratitudes__answer ui-text ui-text_body-1 ui-kit-color-text">
                        Нет. Мы отправляем врачу ту сумму, которую вы указываете.
                        Процент за использование сервиса может снимать сама платёжная система.
                    </dd>
                </dl>

                <span class="b-gratitudes__description ui-text ui-text_body-2 ui-kit-color-text-secondary mb-4">
                    Отправляя благодарность врачу, вы принимаете
                    <a href="${agreementLink}"
                       class="ui-text ui-kit-color-primary" target="_blank"
                    >условия использования</a>.
                </span>
            </div>

            <div class="b-gratitudes__iframe-wrapper">
                ${iframeLink ? iframeTemplate(iframeLink) : preloaderTemplate}
            </div>`
}

function initModal() {
  const gratitudesNode = document.querySelector('.b-gratitudes')

  if (!gratitudesNode) {
    return
  }

  if (isOldBrowserVersion()) {
    gratitudesNode.style.display = 'none'
    return
  }

  const iframePath = gratitudesNode.getAttribute('data-iframe-link')

  new Modal({
    id: 'gratitudes-modal',
    mobileTitle: 'Благодарность врачу',
    catchEventShow: ['.b-gratitudes__button'],
    catchEventHide: ['.b-gratitudes__close'],
    bodyClassList: 'b-gratitudes b-gratitudes_padding',
    beforeShow: ({ open, render }) => {
      render({ template: getTemplate() })
      open()

      if (!iframePath && !receivedRequest) {
        const requestData = getRequestData()

        if (requestData) {
          requestPayment(requestData)
        }
      }
    },
  })
}

initModal()

// Яндекс метрика
new YaGoals({
  dataName: 'data-ya-goals-gratitude',
})
