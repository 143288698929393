<template>
  <div
    class="text-right"
    data-qa="doctor_service_prices_update_info_wrapper"
  >
    <div
      data-qa="doctor_service_prices_update_info"
      class="doctor-service-prices-update-info ui-text ui-text_caption"
      :class="{ [componentClasses]: text }"
    >
      <template v-if="text">
        {{ text }}
      </template>
    </div>
  </div>
</template>

<script>
import { STATUS_TYPES } from 'components/common/DoctorServicePrices/components/UpdateInfo/constants'

export default {
  name: 'UpdateInfo',
  props: {
    status: {
      type: Number,
      default: null,
    },
    dateString: {
      type: String,
      default: '',
    },
  },
  computed: {
    statuses() {
      return {
        isSuccess: this.status === STATUS_TYPES.success,
        isWarning: this.status === STATUS_TYPES.warning,
        isError: this.status === STATUS_TYPES.error,
      }
    },
    componentClasses() {
      if (this.statuses.isSuccess) {
        return 'uiKitBgSuccess uiKitSuccess--text'
      }

      if (this.statuses.isWarning) {
        return 'uiKitBgWarning uiKitWarning--text'
      }

      if (this.statuses.isError) {
        return 'uiKitBgError uiKitError--text'
      }

      return ''
    },
    text() {
      if (!this.dateString) {
        return ''
      }

      const baseText = `Обновлено клиникой ${this.dateString}`

      if (this.statuses.isSuccess) {
        return baseText
      }

      if (this.statuses.isWarning) {
        return `${baseText}. Цены могли устареть`
      }

      if (this.statuses.isError) {
        return `${baseText}. Цены устарели`
      }

      return ''
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.doctor-service-prices-update-info {
  display: inline-block;
  border-radius: $border-radius-sm;
  padding: 4px 8px;

  // Хак, чтобы высота элемента не менялась при отсутствии текста
  &:empty::before {
    content: '\00A0';
  }
}
</style>
