var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.patientData.isLoaded
    ? _c("div", [_c("PatientProfileModalSkeleton")], 1)
    : _c("div", [
        _c("div", { staticClass: "ui-text ui-kit-color-text p-4" }, [
          _c("div", { staticClass: "ui-text ui-text_subtitle-1 mb-6" }, [
            _vm._v("\n      Доверие к профилю\n    "),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column align-items-center mb-6" },
            [
              _c(
                "VProgressCircular",
                {
                  staticClass: "patient-profile-modal__progress-circular mb-4",
                  attrs: {
                    rotate: -90,
                    size: 104,
                    width: 8,
                    value: _vm.patientData.trust_percent,
                    color: _vm.patientData.can_trust
                      ? "uiKitSuccess"
                      : "uiKitRating",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ui-text ui-text_h5 ui-kit-color-text" },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.patientData.trust_percent) +
                          "%\n        "
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.patientData.can_trust
                ? _c(
                    "span",
                    {
                      class: [
                        "ui-text ui-text_body-2",
                        "patient-profile-modal__status patient-profile-modal__status_green",
                      ],
                    },
                    [_vm._v("\n        Можно доверять\n      ")]
                  )
                : _c(
                    "span",
                    {
                      class: [
                        "ui-text ui-text_body-2",
                        "patient-profile-modal__status patient-profile-modal__status_dark-yellow",
                      ],
                    },
                    [_vm._v("\n        Доверяйте с осторожностью\n      ")]
                  ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-10" },
            [
              _vm._l(_vm.criteriaTrust, function (criterion, index) {
                return _c(
                  "div",
                  {
                    key: criterion.title,
                    staticClass: "patient-profile-modal__criterion",
                    class: {
                      "mb-6":
                        _vm.criteriaWithoutTrust.length &&
                        index === _vm.criteriaTrust.length - 1,
                    },
                  },
                  [
                    _c("div", {
                      staticClass:
                        "ui-text ui-icon_fz_medium ui-kit-color-success ui-icon-check-circle mr-3",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ui-text ui-text_body-1",
                        attrs: { "data-qa": _vm.dataQALocators.node_criterion },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(criterion.title) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                )
              }),
              _vm._v(" "),
              _vm._l(_vm.criteriaWithoutTrust, function (criterion) {
                return _c(
                  "div",
                  {
                    key: criterion.title,
                    staticClass: "patient-profile-modal__criterion",
                  },
                  [
                    _c("div", {
                      staticClass:
                        "ui-text ui-icon_fz_medium ui-kit-color-warning ui-icon-circle-warning mr-3",
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "ui-text ui-text_body-1",
                        attrs: { "data-qa": _vm.dataQALocators.node_criterion },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(criterion.title) +
                            "\n        "
                        ),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "mb-10" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-between ui-text ui-text_subtitle-1 mb-4",
                },
                [
                  _c("span", { staticClass: "mr-4" }, [
                    _vm._v("\n          Отзывы\n        "),
                  ]),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.countAllReviews) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm.countAccurateReviews
                ? _c("VProgressLinear", {
                    staticClass: "mb-4",
                    attrs: {
                      height: "8",
                      rounded: "",
                      value: _vm.valueProgressReviews,
                      color: "uiKitSuccess",
                      "background-color": "uiKitError",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.patientData.count_rates_positive
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-between mb-2" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "ui-text ui-text_body-1 ui-kit-color-text-secondary mr-4",
                        },
                        [_vm._v("\n          Положительные\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "amount-icon amount-icon_green ui-text ui-text_body-2",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.patientData.count_rates_positive) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.patientData.count_rates_negative
                ? _c(
                    "div",
                    { staticClass: "d-flex justify-content-between mb-2" },
                    [
                      _c(
                        "span",
                        {
                          staticClass:
                            "ui-text ui-text_body-1 ui-kit-color-text-secondary mr-4",
                        },
                        [_vm._v("\n          Отрицательные\n        ")]
                      ),
                      _vm._v(" "),
                      _c(
                        "span",
                        {
                          staticClass:
                            "amount-icon amount-icon_coral ui-text ui-text_body-2",
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.patientData.count_rates_negative) +
                              "\n        "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between ui-text ui-text_subtitle-1 mb-6",
            },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm.patientData.count_appointments
                ? _c(
                    "span",
                    {
                      attrs: {
                        "data-qa": _vm.dataQALocators.node_count_appointments,
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.patientData.count_appointments) +
                          "\n      "
                      ),
                    ]
                  )
                : _c(
                    "span",
                    {
                      attrs: {
                        "data-qa": _vm.dataQALocators.node_count_appointments,
                      },
                    },
                    [_vm._v("\n        Нет\n      ")]
                  ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            class: [
              "b-btn-fixed b-btn-fixed_active patient-profile-modal__fixed-container",
              { "p-2": _vm.btnFixedFloat },
            ],
          },
          [
            _c(
              "button",
              {
                class: [
                  "b-btn-fixed__control b-button b-button_blue patient-profile-modal__fixed-btn",
                  {
                    "patient-profile-modal__fixed-btn_float": _vm.btnFixedFloat,
                  },
                ],
                attrs: { "data-qa": _vm.dataQALocators.node_btn_close },
                on: { click: _vm.handleCloseModal },
              },
              [
                _c("span", { staticClass: "ui-text ui-text_button" }, [
                  _vm._v("\n        Закрыть\n      "),
                ]),
              ]
            ),
          ]
        ),
      ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "mr-4" }, [
      _vm._v("\n        Записи на приём через "),
      _c("br"),
      _vm._v("ПроДокторов\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }