import { setLocation } from 'utils'

export default () => {
  const buttonEl = document.querySelector('.js-list-button')

  if (!buttonEl) {
    return
  }

  const baseTown = buttonEl.getAttribute('data-base-town')

  const clickHandler = () => {
    const speciality = document.querySelector('select.lpulist_spec').value
    const illness = document.querySelector('select.doctorlist_ills').value
    const clinic = document.querySelector('select.lpulist_lputype').value
    const service = document.querySelector('input.lpulist_service')

    const serviceValue = service.value
    const serviceType = service.getAttribute('data-service-type')

    if (illness && illness !== 'all') {
      return setLocation(`${window.location.origin}/${baseTown}/lechenie/${illness}`)
    }

    if (clinic && clinic !== 'all') {
      return setLocation(`${window.location.origin}/${baseTown}/top/${clinic}`)
    }

    if (speciality && speciality !== 'all') {
      return setLocation(`${window.location.origin}/${baseTown}/${speciality}`)
    }

    if (serviceValue && serviceValue !== 'all') {
      return setLocation(`${window.location.origin}/${baseTown}/${serviceType}/${serviceValue}`)
    }

    setLocation(`${window.location.origin}/${baseTown}/`)
  }

  buttonEl.addEventListener('click', clickHandler)
}
