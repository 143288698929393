import {
  isProd,
  axiosClient,
} from 'utils'
import Cookie from 'js-cookie'
import BrowserTab from 'modules/BrowserTab'
import {
  REVIEW_OBJECT_TYPES,
  SEND_VIEWED_IDS_PATHS,
  SET_SESSION_PATHS,
  VIEWED_IDS,
} from 'www/doctors.blocks/common/b-review-card/constants'

// sentIds - содержит отправленные идентификаторы отзывов
const sentIds = {
  [REVIEW_OBJECT_TYPES.lpu]: [],
  [REVIEW_OBJECT_TYPES.doctor]: [],
}

const sendPath = isProd ? 'prod' : 'stage'

function setCookieForRequests() {
  const sessionCookie = Cookie.get('session')

  if (sessionCookie) {
    return
  }

  axiosClient.options(SET_SESSION_PATHS[sendPath], {
    withCredentials: true,
  })
}

function getUnsentIdsByType(type) {
  return [...VIEWED_IDS[type]].filter(viewedId => !sentIds[type].includes(viewedId))
}

function sendViewedReviews() {
  const ratesFieldName = 'rates_id'
  const lpuFieldName = 'lpu_rates_id'
  const doctorFieldName = 'doctor_rates_id'
  const unsentIds = {
    [REVIEW_OBJECT_TYPES.lpu]: getUnsentIdsByType(REVIEW_OBJECT_TYPES.lpu),
    [REVIEW_OBJECT_TYPES.doctor]: getUnsentIdsByType(REVIEW_OBJECT_TYPES.doctor),
  }
  const data = {
    [lpuFieldName]: unsentIds[REVIEW_OBJECT_TYPES.lpu],
    [doctorFieldName]: unsentIds[REVIEW_OBJECT_TYPES.doctor],
  }

  if (data[lpuFieldName].length || data[doctorFieldName].length) {
    const body = JSON.stringify(data)
    const formData = new FormData()

    formData.append(ratesFieldName, body)

    /**
         * Посылает данные в фоне - переход к другой странице не задерживается
         * */
    const isSuccess = navigator.sendBeacon?.(SEND_VIEWED_IDS_PATHS[sendPath], formData)

    if (isSuccess) {
      sentIds[REVIEW_OBJECT_TYPES.lpu].push(...unsentIds[REVIEW_OBJECT_TYPES.lpu])
      sentIds[REVIEW_OBJECT_TYPES.doctor].push(...unsentIds[REVIEW_OBJECT_TYPES.doctor])
    }
  }
}

function addListenerUnloadReviews() {
  setCookieForRequests()

  new BrowserTab({
    useStorage: false,
    hidden: sendViewedReviews,
  })
}

export default addListenerUnloadReviews
