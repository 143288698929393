import { defineStore } from 'pinia'
import {
  errorData,
  successData,
  getUrlsData,
} from 'components/common/AppointmentPage/interfaces'
import { APPOINTMENT_ERRORS, APPOINTMENT_SUCCESSES } from 'components/common/AppointmentPage/constants'

const useGlobalDataStore = defineStore('globalDataStore', {
  state: () => ({
    urls: getUrlsData(),
    errorData: errorData(),
    successData: successData(),
    sendingUserData: false,
  }),
  actions: {
    updateStateSendingUserData({ state }) {
      this.sendingUserData = state
    },
    updateErrorData(payload) {
      this.errorData = {
        ...errorData(),
        ...payload,
      }
    },
    updateErrorDataIsEnabled(payload) {
      this.errorData.isEnabled = payload
    },
    updateSuccessData(payload) {
      this.successData = {
        ...successData(),
        ...payload,
      }
    },
    updateSuccessDataIsEnabled(payload) {
      this.successData.isEnabled = payload
    },

    showDefaultError() {
      this.updateErrorData({
        ...APPOINTMENT_ERRORS.defaultTryAgain,
        isEnabled: true,
        isDialogRender: true,
      })
    },
    showDefaultSuccess() {
      this.updateSuccessData({
        ...APPOINTMENT_SUCCESSES.defaultSuccess,
        isEnabled: true,
      })
    },
  },
})

export default useGlobalDataStore
