import { declensionByNum } from 'utils'
import { YEAR_CASES } from 'constants/wordCases'

function getAgeLimitCaseText(limit) {
  const ageLimitCase = declensionByNum(limit, YEAR_CASES.genitive)

  return `\u00A0${limit}\u00A0${ageLimitCase}`
}

function getSpecialityAgesText({ min, max }) {
  let agesString = ''

  if (min || max) {
    const ageLimitsStrings = []

    if (min) {
      ageLimitsStrings.push(`от${getAgeLimitCaseText(min)}`)
    }

    if (max) {
      ageLimitsStrings.push(`до${getAgeLimitCaseText(max)}`)
    }

    agesString = ` ${ageLimitsStrings.join(' ')}`
  }

  return agesString
}

export default getSpecialityAgesText
