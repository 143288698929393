var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "search-synonym",
      attrs: { "data-qa": "service_synonym_search" },
    },
    [
      _vm.isLoading
        ? _c("VSkeletonLoader", {
            staticClass: "search-synonym__skeleton",
            attrs: {
              type: "text",
              "data-qa": "service_synonym_search_skeleton",
            },
          })
        : _c(
            "div",
            { staticClass: "v-application v-application--is-ltr theme--light" },
            [
              _c("VTextField", {
                attrs: {
                  outlined: "",
                  clearable: "",
                  "full-width": "",
                  "hide-details": "",
                  placeholder: "Найти",
                  "clear-icon": "ui-icon-close-not-a-circle",
                  "prepend-inner-icon": "ui-icon-search",
                  "data-qa": "service_synonym_search_input",
                },
                on: {
                  input: _vm.handleInputDebounced,
                  "click:clear": _vm.handleClearInput,
                },
                model: {
                  value: _vm.searchQuery,
                  callback: function ($$v) {
                    _vm.searchQuery = typeof $$v === "string" ? $$v.trim() : $$v
                  },
                  expression: "searchQuery",
                },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }