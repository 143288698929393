<template>
  <VCard
    flat
    color="uiKitBgClub"
    class="pl-4 py-3 mb-2 uiKitTextError--text"
    :data-club-switch-block="appointmentType"
    data-qa="club_price_block"
  >
    <div
      class="d-flex justify-space-between align-center"
    >
      <div :class="{ 'less-opacity': !isClubSwitchEnabled }">
        <div class="d-flex align-center">
          <div class="ui-text ui-text_h6 mr-3">
            {{ clubPrice }} {{ currencySymbol }}
          </div>
          <div
            v-if="discount"
            class="ui-text ui-text_subtitle-2 uiKitBgGray0--text rounded club-discount-switch__price-discount"
            data-qa="club_discount_switch_percent"
          >
            -{{ discount }}%
          </div>
        </div>
        <div class="ui-text ui-text_body-2">
          Клубная цена
        </div>
      </div>
      <VSwitch
        :value="isClubSwitchEnabled"
        :input-value="isClubSwitchEnabled"
        color="uiKitError"
        class="pt-0 mt-0 pl-1"
        data-qa="club_discount_switch_input"
        hide-details
        dense
        inset
        @click="handleClickClubDiscountSwitch"
      />
    </div>
    <div
      class="d-inline-flex align-items-center ui-kit-color-error cursor-pointer py-1 mt-1"
      @click="handleClickShowClubInfoModal"
    >
      <VImg
        src="/static/_v1/pd/icons/ui-kit/club/club-percent.svg"
        alt="Иконка Процент Клуба"
        width="20"
        height="20"
        class="mr-2"
      />
      <span class="ui-text ui-text_body-2 ui-text_underline">
        При оплате невозвратного купона
      </span>
    </div>
    <ClubDiscountInfoBottomSheet
      v-model="isClubInfoVisible"
      :full-price="price"
      :club-offer-data="clubOfferData"
    />
  </VCard>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import { APPOINTMENT_TYPES } from 'constants'
import ClubDiscountInfoBottomSheet
  from 'components/common/Club/components/core/ClubDiscount/components/common/ClubDiscountInfoBottomSheet'
import { CLUB_DISCOUNT } from 'components/common/Club/constants'

export default {
  name: 'ClubPrice',
  components: { ClubDiscountInfoBottomSheet },
  props: {
    price: {
      type: Number,
      required: true,
    },
    clubPrice: {
      type: Number,
      required: true,
    },
    discount: {
      type: String,
      default: '',
    },
    isEnabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    currencySymbol: window.CURRENCY_DATA.symbol,
    isClubInfoVisible: false,
    isClubSwitchEnabled: true,
  }),
  computed: {
    appointmentType() {
      return this.isClubSwitchEnabled ? APPOINTMENT_TYPES.club : APPOINTMENT_TYPES.lpu
    },
    clubOfferData() {
      const couponPrice = Math.floor(this.clubPrice / 2)
      const cardPrice = window.USER.club.isInClub ? 0 : window.USER.club.cardNextPrice

      return {
        lpuPrice: this.clubPrice - couponPrice,
        couponPrice,
        cardDateEnd: window.USER.club.cardDateEnd,
        cardPrice,
        totalPrice: this.clubPrice + cardPrice,
        clubDiscount: CLUB_DISCOUNT,
      }
    },
  },
  created() {
    window.addEventListener('club-discount-switch:change-state', this.handleClubSwitchClick)
  },
  beforeDestroy() {
    window.removeEventListener('club-discount-switch:change-state', this.handleClubSwitchClick)
  },
  methods: {
    handleClubSwitchClick({ detail }) {
      this.isClubSwitchEnabled = detail.isEnabled

      this.$emit('change-club-switch-enabled', this.isClubSwitchEnabled)
    },
    handleClickClubDiscountSwitch() {
      this.isClubSwitchEnabled = !this.isClubSwitchEnabled

      dispatchCustomEventGlobally('club-discount-switch:change-state', {
        isEnabled: this.isClubSwitchEnabled,
      })
    },
    handleClickShowClubInfoModal() {
      this.isClubInfoVisible = true
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.club-discount-switch__price-discount {
  background-color: $ui-kit-error;
  background-image: $ui-kit-club-gradient;
  height: max-content;
  padding: 2px 4px;
}

.less-opacity {
  opacity: 0.5;
}
</style>
