import {
  axiosClient,
  getCSRFToken,
  createDevNotice,
  getFormDataFromObject,
} from 'utils'

function sendViewBannerRequest(viewBannerRequestData) {
  const csrfmiddlewaretoken = getCSRFToken()

  if (!csrfmiddlewaretoken) {
    return
  }

  const {
    percents,
    redisId,
    campaign,
    type,
  } = viewBannerRequestData

  if (!campaign) {
    return
  }

  const requestData = {
    p: percents,
    c: campaign,
    btype: type,
    csrfmiddlewaretoken,
    redis_id: redisId,
  }
  const requestFormData = getFormDataFromObject(requestData)

  axiosClient
    .post('/ajax/view_banner/', requestFormData)
    .catch(event => {
      if (!window.navigator.onLine || event.message === 'Network Error') {
        return
      }

      createDevNotice({
        module: 'sendViewBannerRequest.js',
        description: JSON.stringify({
          message: event.message,
          response: event.response?.data,
        }),
      })
    })
}

export default sendViewBannerRequest
