var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: {
        "data-selected-speciality-id": _vm.selectValue[_vm.optionKeys.id],
      },
    },
    [
      _c("SelectModuleButton", {
        class: _vm.selectButtonClass,
        attrs: {
          title: _vm.selectValue[_vm.optionKeys.title],
          subtitle: _vm.selectValue[_vm.optionKeys.subtitle],
        },
        on: { click: _vm.handleClickSelect },
      }),
      _vm._v(" "),
      _c(
        "VDialog",
        {
          attrs: {
            value: _vm.isVisibleModal,
            fullscreen: "",
            persistent: "",
            "hide-overlay": "",
            "no-click-animation": "",
            transition: "fade-transition",
          },
        },
        [
          _c(
            "ModalLayout",
            {
              attrs: {
                "close-to": "",
                title: _vm.modalTitle,
                "limit-title": _vm.modalLimitTitle,
              },
              on: { "modal:back": _vm.handleCloseModal },
            },
            [
              _c(
                "SelectModalOptions",
                _vm._b(
                  {
                    on: _vm._d({}, [
                      _vm.eventClickOption,
                      _vm.handleClickOption,
                    ]),
                  },
                  "SelectModalOptions",
                  { ..._vm.$attrs, ..._vm.$props },
                  false
                )
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }