import SwiperModule from 'modules/SwiperModule'

const isMobile = window.MOBILE_VERSION

new SwiperModule({
  module: {
    modifier: 'b-popular-doctors-slider',
    insertNavigation: {
      where: 'beforeend',
      relative: container => container.closest('.b-popular-doctors-slider-wrapper'),
    },
  },
  swiper: {
    navigation: !isMobile,
    slidesPerView: isMobile ? 1 : 2,
    spaceBetween: isMobile ? 8 : 24,
    centeredSlides: isMobile,
    roundLengths: isMobile,
    preloadImages: false,
  },
})
