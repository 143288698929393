var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VCard",
    {
      staticClass: "pl-4 py-3 mb-2 uiKitTextError--text",
      attrs: {
        flat: "",
        color: "uiKitBgClub",
        "data-club-switch-block": _vm.appointmentType,
        "data-qa": "club_price_block",
      },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-space-between align-center" },
        [
          _c("div", { class: { "less-opacity": !_vm.isClubSwitchEnabled } }, [
            _c("div", { staticClass: "d-flex align-center" }, [
              _c("div", { staticClass: "ui-text ui-text_h6 mr-3" }, [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.clubPrice) +
                    " " +
                    _vm._s(_vm.currencySymbol) +
                    "\n        "
                ),
              ]),
              _vm._v(" "),
              _vm.discount
                ? _c(
                    "div",
                    {
                      staticClass:
                        "ui-text ui-text_subtitle-2 uiKitBgGray0--text rounded club-discount-switch__price-discount",
                      attrs: { "data-qa": "club_discount_switch_percent" },
                    },
                    [
                      _vm._v(
                        "\n          -" + _vm._s(_vm.discount) + "%\n        "
                      ),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "ui-text ui-text_body-2" }, [
              _vm._v("\n        Клубная цена\n      "),
            ]),
          ]),
          _vm._v(" "),
          _c("VSwitch", {
            staticClass: "pt-0 mt-0 pl-1",
            attrs: {
              value: _vm.isClubSwitchEnabled,
              "input-value": _vm.isClubSwitchEnabled,
              color: "uiKitError",
              "data-qa": "club_discount_switch_input",
              "hide-details": "",
              dense: "",
              inset: "",
            },
            on: { click: _vm.handleClickClubDiscountSwitch },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "d-inline-flex align-items-center ui-kit-color-error cursor-pointer py-1 mt-1",
          on: { click: _vm.handleClickShowClubInfoModal },
        },
        [
          _c("VImg", {
            staticClass: "mr-2",
            attrs: {
              src: "/static/_v1/pd/icons/ui-kit/club/club-percent.svg",
              alt: "Иконка Процент Клуба",
              width: "20",
              height: "20",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            { staticClass: "ui-text ui-text_body-2 ui-text_underline" },
            [_vm._v("\n      При оплате невозвратного купона\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ClubDiscountInfoBottomSheet", {
        attrs: {
          "full-price": _vm.price,
          "club-offer-data": _vm.clubOfferData,
        },
        model: {
          value: _vm.isClubInfoVisible,
          callback: function ($$v) {
            _vm.isClubInfoVisible = $$v
          },
          expression: "isClubInfoVisible",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }