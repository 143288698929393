export const ACTIVATORS_GAP_WIDTH = 8

export const REVIEW_ORIGINS = {
  callCenter: {
    icon: 'ui-icon-phone-call',
    text: 'колл-центр',
  },
  pdMobile: {
    icon: 'ui-icon-car-prodoctorov',
    text: 'ПроДокторов-мобиль',
  },
  site: {
    icon: 'ui-icon-site',
    text: 'сайт',
  },
}

export const AVG_ASSESSMENT_BOUNDARIES = {
  maxError: -0.4,
  maxWarning: 0.4,
}

export const ASSESSMENT_TEXT_COLOR = {
  default: 'uiKitText--text',
  error: 'uiKitTextError--text',
  warning: 'uiKitTextWarning--text',
  success: 'uiKitTextSuccess--text',
}

export const STAR_COUNTS = {
  min: 0,
  max: 5,
}
