<template>
  <VApp
    v-if="doctorVideoUrl"
    data-video-card
  >
    <VSheet
      class="rounded-pill overflow-hidden cursor-pointer"
      data-qa="doctor_video_card_play_color"
    >
      <VImg
        v-ripple
        width="32"
        height="32"
        src="/static/_v1/pd/icons/ui-kit/video-card/video-card-play.svg"
        alt="Открыть видеовизитку"
        @click="handleOpenDialog"
      />
    </VSheet>
    <VDialog
      v-model="isDialogVisible"
      data-qa="doctor_video_card_dialog"
      :fullscreen="isMobile"
      :width="!isMobile ? '456px' : ''"
      @click:outside="handleCloseDialog"
    >
      <ModalLayout
        v-if="!isMobile"
        close-to
        title="Видеовизитка"
        hide-overflow-y
        body-height-inherit
        body-flex-basis-auto
        class="height-inherit"
        @modal:back="handleCloseDialog"
      >
        <div class="height-inherit p-6">
          <video
            ref="video"
            controls
            class="video-card-player"
            :src="doctorVideoUrl"
            @ended="handleEnded"
          />
        </div>
      </ModalLayout>
      <div
        v-else
        class="video-card-wrapper ui-kit-bg-text"
        data-qa="doctor_video_card_dialog"
      >
        <div
          class="video-card-player-close ui-icon ui-icon_reset ui-kit-color-bg-gray-0 ui-icon-close-not-a-circle"
          @click="handleCloseDialog"
        />
        <div
          class="video-card-switch-state"
          @click="videoSwitchState"
        />
        <div
          v-if="!isVideoPlaying"
          class="video-card-play"
        >
          <div class="ui-icon ui-icon_reset ui-kit-color-primary ui-icon-player-play" />
        </div>
        <div
          v-if="videoTimeTotal && videoTimeCurrent"
          class="video-card-time-cover"
        >
          <div class="video-card-time">
            <div
              class="ui-text ui-text_body-1 ui-kit-color-bg-gray-0"
              v-text="videoTimeCurrent"
            />
            <div
              class="ui-text ui-text_body-1 ui-kit-color-bg-gray-60 px-1"
              v-text="'/'"
            />
            <div
              class="ui-text ui-text_body-1 ui-kit-color-bg-gray-60"
              v-text="videoTimeTotal"
            />
          </div>
        </div>
        <video
          ref="video"
          playsinline
          class="video-card-player"
          :src="doctorVideoUrl"
          @ended="handleEnded"
          @timeupdate="handleTimeupdate"
          @loadedmetadata="handleLoadedmetadata"
        />
      </div>
    </VDialog>
  </VApp>
</template>

<script>
import { createDevNotice } from 'utils'
import { VIDEO_CARD_GOALS } from 'yandexGoals'
import ManageYandexGoals from 'components/common/mixins/ManageYandexGoals'
import ModalLayout from 'components/mobile/core/ModalLayout'
import { writeQuantityViews } from 'components/common/VideoCard/api'

export default {
  name: 'VideoCard',
  components: {
    ModalLayout,
  },
  mixins: [
    ManageYandexGoals,
  ],
  props: {
    doctorVideoUrl: {
      type: String,
      required: true,
    },
    doctorVideoUuid: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    timeoutId: null,
    videoTimeTotal: 0,
    videoTimeCurrent: 0,
    isVideoPlaying: false,
    isMobile: window.MOBILE_VERSION,
    isDialogVisible: false,
    yaGoalsList: VIDEO_CARD_GOALS,
  }),
  mounted() {
    window.addEventListener('click-video-preview-image', this.handleOpenDialog)
  },
  methods: {
    handleCloseDialog() {
      this.isDialogVisible = false
      this.isVideoPlaying = true

      this.videoSwitchState()

      this.$nextTick(() => {
        if (this.$refs.video) {
          const diff = this.$refs.video.duration - this.$refs.video.currentTime

          if (diff !== 0) { // значит видео НЕ досмотрено до конца
            this.sendYandexGoal('closeWithoutView')
          }

          this.$refs.video.currentTime = 0
        }
      })

      // Костыль, который снижает z-index кнопке. Иначе она может перекрыть собой иные открытые окна.
      // Модальное окно должно иметь высокий z-index когда открыто, иначе иные элементы на странице могут перекрыть видео
      this.timeoutId = setTimeout(() => { // Задержка нужна для десктопной версии, чтобы поиск не мигал при закрытии модального окна с видео
        [...document.querySelectorAll('.video-card-vue')].forEach(node => { node.style.zIndex = '5' })
      }, 500)
    },
    async handleOpenDialog() {
      this.isDialogVisible = true

      if (this.timeoutId) { // Если пользователь быстро закроет и откроет окно - z-index будет некорректным из-за setTimeout выше. Поэтому исполнение сброса z-index необходимо обнулить
        clearTimeout(this.timeoutId)
      }

      // Костыль, который повышает z-index кнопке.
      // Модальное окно должно иметь высокий z-index когда открыто, иначе иные элементы на странице могут перекрыть видео
      [...document.querySelectorAll('.video-card-vue')].forEach(node => { node.style.zIndex = '350' })

      this.videoSwitchState()
      this.sendYandexGoal('play')

      try {
        if (!this.doctorVideoUuid) {
          createDevNotice({
            module: 'VideoCard',
            description: 'Отсутствует поле "doctorVideoUuid"',
            method: 'handleOpenDialog',
          })

          return
        }

        await writeQuantityViews(this.doctorVideoUuid)
      } catch ({ message }) {
        createDevNotice({
          module: 'VideoCard',
          description: message,
          method: 'handleOpenDialog',
        })
      }
    },
    videoSwitchState() {
      this.$nextTick(() => {
        if (!this.$refs.video) {
          return
        }

        if (this.isVideoPlaying) {
          this.isVideoPlaying = false
          this.$refs.video.pause()

          return
        }

        this.isVideoPlaying = true
        this.$refs.video.play()
      })
    },
    handleEnded() {
      this.sendYandexGoal('finish')
    },
    handleTimeupdate() {
      this.videoTimeCurrent = this.convertSeconds(this.$refs.video.currentTime)
    },
    handleLoadedmetadata() {
      this.videoTimeTotal = this.convertSeconds(this.$refs.video.duration)
      this.videoTimeCurrent = this.convertSeconds(this.$refs.video.currentTime)
    },
    convertSeconds(sec) {
      const date = new Date(1970, 0, 0, 0, 0, Number(sec) || 0)
        .toLocaleTimeString('ru')
        .split(':')

      return `${Number(date[1])}:${date[2]}`
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/mixins';

#app[data-video-card] {
  @include v-app-reset;
}

.height-inherit {
  height: inherit;
}

.video-card {
  &-wrapper {
    position: relative;
  }

  &-player {
    width: 100%;
    height: 100vh;
  }

  &-play {
    z-index: 1;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    background-color: $ui-kit-bg-primary;
    border-radius: $border-radius-circle;
  }

  &-player-close {
    top: 16px;
    left: 8px;
    z-index: 5;
    position: absolute;
  }

  &-switch-state {
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 5;
    position: absolute;
    height: calc(100% - 50px);
  }

  &-time-cover {
    width: 100%;
    left: 0;
    bottom: 100px;
    position: absolute;
    display: flex;
    justify-content: center;
  }

  &-time {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    border-radius: $border-radius-md;
    background: rgba($ui-kit-text, 0.8);
  }
}

[data-flavour='full'] {
  .video-card-vue {
    top: 0;
    left: 0;
  }

  .video-card-player {
    height: 100%;
  }
}

@media screen and (min-height: 900px) {
  [data-flavour='full'] {
    .video-card-player {
      max-height: 743px;
    }
  }
}
</style>

<style lang="scss">
.video-card-vue {
  position: absolute;
  top: 8px;
  left: 8px;
}

#app[data-video-card] .v-dialog--fullscreen {
  overflow-y: hidden;
}

#app[data-video-card] .v-dialog:not(.v-dialog--fullscreen) {
  max-height: initial;
  height: 85%;
}

@media screen and (min-height: 900px) {
  [data-flavour='full'] {
    #app[data-video-card] .v-dialog:not(.v-dialog--fullscreen) {
      height: auto;
    }
  }
}
</style>
