var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SideMenu",
    {
      staticClass: "patient-profile-modal",
      attrs: {
        value: _vm.isVisible,
        "side-menu-width": 400,
        "use-alternate-content-template": "",
      },
      on: { "side-menu:click-close": _vm.handleCloseModal },
    },
    [
      _c(
        "ModalLayout",
        {
          attrs: {
            "close-to": "",
            "limit-title": "",
            "header-padding-x-step": 6,
            title: `Пациент ${_vm.patientData.title}`,
            "has-fixed-btn": _vm.patientData.isLoaded,
          },
          on: { "modal:back": _vm.handleCloseModal },
        },
        [
          _c("PatientProfileModalContent", {
            attrs: { "btn-fixed-float": "", "patient-data": _vm.patientData },
            on: { "modal:close": _vm.handleCloseModal },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }