var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: {
        value: _vm.isVisibleModal,
        width: "456",
        "content-class": _vm.dialogContentClasses,
      },
      on: {
        "click:outside": _vm.handleCloseModal,
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.handleCloseModal.apply(null, arguments)
        },
      },
    },
    [
      _c(
        "ModalLayout",
        {
          attrs: {
            "close-to": "",
            "limit-title": "",
            "body-flex-basis-auto": "",
            "is-dark-mode": _vm.isDarkMode,
            title: _vm.modalData.title,
          },
          on: { "modal:back": _vm.handleCloseModal },
        },
        [
          _c(
            "div",
            { staticClass: "px-6 py-4" },
            [
              _c("AwardModalContent", {
                attrs: {
                  "is-dark-mode": _vm.isDarkMode,
                  "modal-data": _vm.modalData,
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex justify-content-end py-2" },
                [
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-2 mr-4",
                      attrs: {
                        text: "",
                        color: _vm.isDarkMode ? "gold" : "black",
                        dark: _vm.isDarkMode,
                      },
                      on: { click: _vm.handleCloseModal },
                    },
                    [_vm._v("\n          Закрыть\n        ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "VBtn",
                    {
                      staticClass: "px-2",
                      attrs: {
                        elevation: "0",
                        color: _vm.isDarkMode ? "gold" : "primary",
                        dark: _vm.isDarkMode,
                      },
                      on: { click: _vm.handleClickRedirect },
                    },
                    [
                      _c(
                        "span",
                        {
                          class: {
                            "ui-text ui-kit-color-text": _vm.isDarkMode,
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.modalData.button.text) +
                              "\n          "
                          ),
                        ]
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }