var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _setup.isMobile ? _setup.VBottomSheet : _setup.SideMenu,
    _vm._g(
      _vm._b(
        {
          tag: "component",
          attrs: { value: _vm.value },
          on: {
            input: function ($event) {
              return _setup.emit("input", $event)
            },
          },
        },
        "component",
        _setup.isMobile
          ? {
              fullscreen: true,
            }
          : {
              useAlternateContentTemplate: true,
            },
        false
      ),
      _setup.isMobile
        ? {}
        : {
            "side-menu:click-close": () => _setup.emit("input", false),
          }
    ),
    [
      _c(
        "div",
        { staticClass: "d-flex flex-column uiKitBgGray0 h-100" },
        [
          _c(
            "div",
            [
              _c(
                "div",
                { staticClass: "px-2 py-4" },
                [
                  _c(
                    "VIcon",
                    {
                      on: {
                        click: function ($event) {
                          return _setup.emit("input", false)
                        },
                      },
                    },
                    [_vm._v("\n          ui-icon-close-not-a-circle\n        ")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("VTextField", {
                staticClass: "px-2 mb-2",
                attrs: {
                  value: _setup.searchText,
                  "background-color": "uiKitBgGray40",
                  color: "uiKitTextInfo",
                  "clear-icon": "ui-icon-close-not-a-circle",
                  "prepend-inner-icon": "ui-icon-search",
                  label: "Специальность или услуга",
                  clearable: "",
                  "hide-details": "",
                  solo: "",
                  flat: "",
                },
                on: { input: _setup.debounceUpdateSearchText },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("VDivider"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "flex-grow-1 overflow-y-auto" },
            [
              _setup.workplacesList.length || _setup.servicesList.length
                ? [
                    _c(_setup.ContentList, {
                      class: { "mb-4": _setup.servicesList.length },
                      attrs: {
                        title: "Приём",
                        "lpu-id": _vm.lpuId,
                        list: _setup.workplacesList,
                        "is-lpu-appointment-type-chosen":
                          _setup.isLpuAppointmentTypeChosen,
                      },
                      on: { "click-item": _setup.handleClickItem },
                    }),
                    _vm._v(" "),
                    _c(_setup.ContentList, {
                      attrs: {
                        title: "Услуги",
                        "lpu-id": _vm.lpuId,
                        list: _setup.servicesList,
                        "is-lpu-appointment-type-chosen": "",
                      },
                      on: { "click-item": _setup.handleClickItem },
                    }),
                  ]
                : _c("div", { staticClass: "text-center py-20 px-2 my-2" }, [
                    _c("img", {
                      staticClass: "mb-2",
                      attrs: {
                        src: "/static/_v1/pd/pictures/ui-kit/search.svg",
                        alt: "Поиск",
                        width: "150",
                        height: "150",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "ui-text ui-text_body-1 uiKitText--text" },
                      [_vm._v("\n          Ничего не найдено\n        ")]
                    ),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }