import { AppointmentModules } from 'modules/appointment-modules'

function getCalendarData({ requestData, appointmentObjectTypes }) {
  if (appointmentObjectTypes.isServices) {
    return new AppointmentModules().getAppointmentServicesSlots(requestData)
  }

  if (appointmentObjectTypes.isDoctor) {
    return new AppointmentModules().getAppointmentDoctorsSlots(requestData)
  }

  return Promise.reject(new Error('Не указан ни один из возможных appointmentObjectTypes'))
}

export default getCalendarData
