/**
 * @typedef Lpu
 * @property { number | null } id
 * @property { string } name
 * @property { string } address
 * @property { boolean } isServiceDirect
 * */

/** @return { Array<Lpu> } */
const getLpuList = () => []

export {
  getLpuList,
}
