var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VDialog",
    {
      attrs: { fullscreen: "", "data-qa": "popup_map_popup_container" },
      on: {
        keydown: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          )
            return null
          return _vm.closeModalWithBackHistory.apply(null, arguments)
        },
      },
      model: {
        value: _vm.isVisibleModal,
        callback: function ($$v) {
          _vm.isVisibleModal = $$v
        },
        expression: "isVisibleModal",
      },
    },
    [
      _c(
        "VBtn",
        {
          staticClass: "ui-kit-bg-bg-gray-0 popup-map__close-button",
          attrs: {
            color: _vm.vuetifyColors.primary,
            icon: "",
            width: "32",
            height: "32",
            "data-qa": "popup_map_close_top_button",
          },
          on: { click: _vm.closeModalWithBackHistory },
        },
        [
          _c("VIcon", { attrs: { size: "20" } }, [
            _vm._v("\n      ui-icon-close-not-a-circle\n    "),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", {
        staticClass: "ui-kit-bg-bg-gray-0 popup-map__map-container",
        attrs: { id: _vm.mapId, "data-qa": "popup_map_map_container" },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "popup-map__bottom-button-container" },
        [
          _c(
            "VBtn",
            {
              attrs: {
                color: _vm.vuetifyColors.primary,
                block: "",
                "data-qa": "popup_map_close_bottom_button",
              },
              on: { click: _vm.closeModalWithBackHistory },
            },
            [
              _c(
                "span",
                {
                  staticClass: "ui-text ui-text_body-1 ui-kit-color-bg-gray-0",
                },
                [_vm._v("\n        Закрыть карту\n      ")]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("PopupDepartureAddressMobile", {
        attrs: {
          "is-visible": _vm.isVisibleDepartureAddressPopup,
          "bounds-available-routes": _vm.boundsAvailableRoutes,
          "arrival-address": _vm.getArrivalAddress,
        },
        on: {
          "popup-departure-address:close": _vm.closeDepartureAddressPopup,
          "popup-departure-address:create-multi-route":
            _vm.createMultiRouteInstance,
        },
      }),
      _vm._v(" "),
      _c("div", {
        ref: _vm.navigationPanelBlock,
        class: _vm.navigationPanelBlock,
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }