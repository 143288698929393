import { CLUB_QA_GOALS } from 'dataQA'
import { REDIRECT_VALUES } from 'www/constants'

/**
 * @description
 *
 * Базовые данные для отрисовки табов
 *
 * @return { Object }
 * */

const getTabsData = () => ({
  tabsData: {
    tabLpu: {
      sortPosition: 1,
      description: 'В клинике',
      isInClub: false,
      dataQA: CLUB_QA_GOALS.sliderTabLpu,
      redirectValue: REDIRECT_VALUES.default, // `default` || `club`
    },
    tabTelemed: {
      sortPosition: 2,
      description: 'Онлайн',
      dataQA: CLUB_QA_GOALS.sliderTabTelemed,
      redirectValue: REDIRECT_VALUES.telemed,
    },
    tabHome: {
      sortPosition: 3,
      description: 'На дому',
      dataQA: CLUB_QA_GOALS.sliderTabHome,
      redirectValue: REDIRECT_VALUES.home,
    },
  },
})

export default getTabsData
