import { getQueryParam } from 'utils'
import { CLUB_EVENTS } from 'www/constants'

let isEnabledClubSwitch = false
let userInClub = window.USER.club.isInClub

function isEnabledClubServicesSwitch() {
  return isEnabledClubSwitch
}

(() => {
  isEnabledClubSwitch = !userInClub && getQueryParam('club_switch_on') !== null

  window.addEventListener(CLUB_EVENTS.discountSwitchClick, ({ detail }) => {
    isEnabledClubSwitch = detail.isEnabled
  })

  window.addEventListener('club-service-discount:updated-user-data', ({ detail }) => {
    if (detail.userClubData.isInClub) {
      userInClub = true
      isEnabledClubSwitch = false
    }
  })
})()

export default isEnabledClubServicesSwitch
