import { NAVIGATION_PANEL_CLASSES } from 'components/common/PopupMap/constants'

function getCarRoutesMiniInfoTemplate(multiRoute) {
  const {
    route,
    activeRoute,
  } = NAVIGATION_PANEL_CLASSES

  const indexActiveRoute = multiRoute.getActiveRoute().properties.get('index')
  const allRoutes = multiRoute.model.getRoutes()

  let routesTemplate = ''

  allRoutes.forEach((currentRoute, index) => {
    const routeActiveClass = currentRoute.properties.get('index') === indexActiveRoute ? ` ${activeRoute}` : ''

    routesTemplate += `
            <div 
                data-route-index="${index}" 
                class="cursor-pointer px-3 py-2 ${route}${routeActiveClass}"
            >
                <div 
                    class="ui-text ui-text_body-1"
                    data-qa="popup_map_duration_car_text"
                >
                    ${currentRoute.properties.get('duration').text}
                </div>
                <div 
                    class="ui-text ui-text_body-2 ui-kit-color-text-secondary"
                    data-qa="popup_map_distance_car_text"                
                >
                    ${currentRoute.properties.get('distance').text}
                </div>
            </div>
        `
  })

  return `
        <div class="mt-4">
            ${routesTemplate}
        </div>
    `
}

export default getCarRoutesMiniInfoTemplate
