import Vue from 'components'
import { setAppointmentTypeStaff } from 'modules/Schedule/functions'
import SelectModuleModal from 'components/common/SelectModule/SelectModuleModal/components/common/SelectModal'

/**
 * @description
 *
 * Производит рендер списка специальностей списка клиник
 *
 * @param { Object } options
 * @param { Object } options.items - данные, которые собраны со страницы
 * @param { Object } options.storage - данные, которые собраны со страницы
 * @param { Object } options.response - данные ответа, которые возвращает 'specialtiesRequest'
 * */

const selectComponents = []

function renderSpecialities({ items, storage, response }) {
  const { specialtyId, specialtyName, specialtyTranslit } = storage
  const isAppliedFilter = specialtyId && specialtyName && specialtyTranslit

  selectComponents.forEach(instance => instance.$destroy())
  selectComponents.length = 0

  items.forEach(({ coverNode, lpuId }) => {
    const selectMountElement = coverNode.querySelector('.vue-select-specialities-mount') // ToDo schedule-important избавиться от привязки к DOM дереву

    if (!selectMountElement) {
      return
    }

    const specialities = response[lpuId]

    if (!specialities) {
      return
    }

    const selectedSpeciality = isAppliedFilter
      ? specialities.find(s => s.id === Number(specialtyId))
      : specialities[0]

    const props = {
      lpuId,
      options: specialities,
      selectedOption: selectedSpeciality,
      appendIcon: 'ui-icon-arrow-right',
      modalTitle: 'Специалист',
      selectButtonClass: window.MOBILE_VERSION ? 'py-2' : 'py-1',
      customEventNames: { clickOption: 'lpu-select-speciality:click-option' },
      customOptionKeys: { title: 'name' },
    }

    const instance = new Vue({
      el: selectMountElement,
      render: h => h(SelectModuleModal, { props }),
    })

    selectComponents.push(instance)

    setAppointmentTypeStaff({ coverNode, option: selectedSpeciality })
  })
}

export default renderSpecialities
