<template>
  <div ref="rootElement">
    <template v-if="filterType !== 'home'">
      <div v-if="!isAppointmentOn || lpuWithAppointmentIds.includes(lpuAddress.lpu.id)">
        <div
          v-if="lpuAddress.has_slots && lpuAddress.appointment_on || !selectedItem.isWorkplace"
          data-has-appointment
        >
          <div v-show="isRequestSlotsDone">
            <PartCalendar
              v-show="!isVisibleFullCalendar || !isMobile"
              :lpu-id="lpuAddress.lpu.id"
              @calendar-time-choice="handleCalendarTimeChoice"
            />
            <FullCalendar
              v-if="isVisibleFullCalendar && isMobile"
              :lpu-id="lpuAddress.lpu.id"
              @calendar-time-choice="handleCalendarTimeChoice"
              @close="handleCloseFullCalendar"
            />
          </div>
          <div v-show="!isRequestSlotsDone">
            <VSheet
              class="d-flex align-center justify-center"
              height="168"
            >
              <VProgressCircular
                color="primary"
                size="48"
                indeterminate
              />
            </VSheet>
          </div>
          <div
            v-if="!isMobile && isUserStaff && lpuAddress.staff_appointment_type"
            class="staff-appointment-type"
          >
            <div class="staff-appointment-type__text">
              {{ lpuAddress.staff_appointment_type }}
            </div>
          </div>
        </div>
        <div
          v-else
          data-has-appointment
        >
          <VBtn
            v-if="appointmentWillEnableTime"
            color="primary"
            disabled
          >
            Запись включится
            <time>{{ appointmentWillEnableTime }}</time>
          </VBtn>
          <VBtn
            v-else-if="isAppointmentOn"
            color="primary"
            depressed
            block
            @click="handleExpressAppointmentButtonClick"
          >
            Запись на приём
          </VBtn>
        </div>
      </div>
    </template>
    <template v-else>
      <div
        v-if="typeof lpuAddress.tabs_data?.price_go === 'number' && !lpuAddress.appointment_direct"
        data-has-appointment
      >
        <div class="ui-text ui-text_body-2 ui-kit-color-text-info mb-4">
          Цена зависит от удалённости клиники.
          Уточните цену и способ оплаты во время звонка администратора.
        </div>
        <VBtn
          color="primary"
          depressed
          block
          @click="handleExpressAppointmentButtonClick"
        >
          <VIcon class="mr-2">
            ui-icon-car-clinic
          </VIcon>
          Вызвать {{ doctor?.is_medic ? 'врача' : 'специалиста' }}
        </VBtn>
      </div>
    </template>
    <VDialog
      v-if="!isMobile"
      v-model="isVisibleFullCalendar"
      width="754"
    >
      <VCard>
        <VCardTitle class="d-flex align-center px-2 py-4">
          <VIcon
            class="mr-8"
            @click="isVisibleFullCalendar = false"
          >
            ui-icon-close-not-a-circle
          </VIcon>
          Полное расписание врача
        </VCardTitle>
        <VDivider />
        <VCardText class="pt-6">
          <FullCalendar
            :lpu-id="lpuAddress.lpu.id"
            @calendar-time-choice="handleCalendarTimeChoice"
            @close="handleCloseFullCalendar"
          />
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { storeToRefs } from 'pinia'
import goTo from 'vuetify/lib/services/goto'
import { format, parseISO } from 'date-fns'
import { ru } from 'date-fns/locale'
import { AppointmentModules } from 'modules/appointment-modules'
import { useDoctorAppointmentCardStore } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/stores'
import { FullCalendar, PartCalendar } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/components'
import { useDoctorPageLpuListStore, useExternalDataStore } from 'components/common/DoctorPageLpuList/stores'
import { LpuAddressList } from 'components/common/DoctorPageLpuList/types'
import { getElementOffsetTop } from 'utils'

const props = defineProps<{
  lpuAddress: LpuAddressList[number]
}>()

const rootElement = ref(null)

const doctorAppointmentCardStore = useDoctorAppointmentCardStore(props.lpuAddress.lpu.id)()
const {
  isRequestSlotsDone,
  timedelta,
  selectedItem,
  isVisibleFullCalendar,
} = storeToRefs(doctorAppointmentCardStore)

const externalDataStore = useExternalDataStore()
const {
  isAppointmentOn,
  lpuWithAppointmentIds,
  appointmentWillEnableTime,
  doctor,
  isMobile,
} = storeToRefs(externalDataStore)

const doctorPageLpuListStore = useDoctorPageLpuListStore()
const { redirectValue, filterType } = storeToRefs(doctorPageLpuListStore)

const appointmentModules = new AppointmentModules()

function handleCalendarTimeChoice({ day, time }: { day: string, time: string }) {
  const slot = `${format(parseISO(day), 'dd.MM.yyyy', { locale: ru })} ${time}`
  const redirectData = {
    slot,
    lpu: props.lpuAddress.lpu.id,
    source: 'doctor-inner',
    timedelta: timedelta.value,
  }

  if (selectedItem.value.isWorkplace) {
    const type = redirectValue.value

    // @ts-ignore
    appointmentModules.redirectToNewAppointment({
      ...redirectData,
      doctor: selectedItem.value.doctorId,
      // @ts-ignore
      doctorSpec: selectedItem.value.speciality.id,
      type,
    })
  } else {
    appointmentModules.redirectToNewAppointmentClinic({
      ...redirectData,
      targetDoctorId: selectedItem.value.doctorId,
      synonymId: selectedItem.value.synonymId,
    })
  }
}

function handleExpressAppointmentButtonClick() {
  // @ts-ignore
  appointmentModules.redirectToNewAppointment({
    doctor: selectedItem.value.doctorId,
    lpu: props.lpuAddress.lpu.id,
    type: redirectValue.value,
    // @ts-ignore
    doctorSpec: selectedItem.value.speciality.id,
  })
}

function handleCloseFullCalendar() {
  if (isMobile.value && rootElement.value) {
    goTo(getElementOffsetTop(rootElement.value as HTMLElement) - 100)
  }
}

const isUserStaff = window.USER.isStaff
</script>
