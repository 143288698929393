import { axiosClient } from 'utils'
import Cookie from 'js-cookie'

const resource = '/api/patient/favourites'
const headers = {
  'X-CSRFToken': Cookie.get('csrftoken'),
}

export default {
  add(type, id) {
    return axiosClient.post(`${resource}/${type}/${id}/`, {}, { headers })
  },
  delete(type, id) {
    return axiosClient.delete(`${resource}/${type}/${id}/`, { headers })
  },
  getList(type, idArray) {
    return axiosClient.post(`${resource}/${type}/`, idArray, { headers })
  },
}
