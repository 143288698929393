<template>
  <VApp class="review-card-tooltips">
    <div class="d-flex">
      <VMenu
        z-index="110"
        attach=""
        :disabled="!assessmentsTransformed.length"
        :close-on-content-click="false"
        open-on-hover
        offset-y
      >
        <template #activator="{ on, attrs }">
          <div
            ref="stars"
            v-bind="attrs"
            v-on="on"
          >
            <slot name="stars" />
          </div>
        </template>
        <VCard
          class="pa-5"
          elevation="0"
        >
          <div
            v-for="(assessment, index) in assessmentsTransformed"
            :key="assessment.name"
            :class="{ 'mt-4': index > 0 }"
          >
            <div class="text-no-wrap">
              {{ assessment.name }}
            </div>
            <div class="d-flex">
              <v-icon
                v-for="i in assessment.filledStars"
                :key="`filled-${i}`"
                color="uiKitRating"
                :class="{ 'ml-1': i > 1 }"
                small
              >
                ui-icon-rating-star-filled
              </v-icon>
              <v-icon
                v-for="i in assessment.outlineStars"
                :key="`outline-${i}`"
                class="ml-1"
                color="uiKitRating"
                small
              >
                ui-icon-rating-star-outline
              </v-icon>
              <div
                class="text-transform-capitalize ml-2"
                :class="assessment.color"
                v-text="assessment.status"
              />
            </div>
          </div>
        </VCard>
      </VMenu>

      <div
        v-if="assessments.length"
        class="ml-2"
      />

      <VMenu
        z-index="110"
        attach=""
        :nudge-left="statusesTooltipOffset"
        :close-on-content-click="false"
        open-on-hover
        offset-y
      >
        <template #activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            @mouseenter="updateOffset"
          >
            <slot
              name="statuses"
            />
          </div>
        </template>
        <VCard
          class="ui-kit-color-text"
          width="304"
          elevation="0"
        >
          <div
            class="pa-4"
          >
            <div class="ui-text ui-text_subtitle-1">
              Отзыв проверен
            </div>
            <div
              v-for="status in verificationStatuses"
              :key="status"
              class="d-flex mt-2"
            >
              <!-- eslint-disable vue/no-v-html -->
              <div v-html="status" />
              <!-- eslint-enable vue/no-v-html -->
              <VSpacer />
              <VIcon color="uiKitTextSuccess">
                ui-icon-check
              </VIcon>
            </div>
          </div>
          <VDivider />
          <div class="d-flex align-center ui-text ui-text_caption ui-kit-color-text-secondary pa-4">
            <VIcon dense>
              {{ originInfo.icon }}
            </VIcon>
            <div class="ml-3">
              Оставлен через {{ originInfo.text }}
            </div>
          </div>
        </VCard>
      </VMenu>
    </div>
  </VApp>
</template>

<script>
import {
  REVIEW_ORIGINS,
  ACTIVATORS_GAP_WIDTH,
  AVG_ASSESSMENT_BOUNDARIES,
  ASSESSMENT_TEXT_COLOR,
  STAR_COUNTS,
} from 'components/common/ReviewCardTooltips/constants'

export default {
  name: 'ReviewCardTooltips',
  props: {
    /**
         * interface Assessment {
         *     name: string
         *     value: string
         *     status: string
         * }
         *
         * type Assessments = Assessment[]
         */
    assessments: {
      type: Array,
      default: () => [],
    },
    /**
         * type VerificationStatuses = string[]
         */
    verificationStatuses: {
      type: Array,
      default: () => [],
    },
    /**
         * type ReviewOrigin = 'callCenter' | 'pdMobile' | 'site' | ''
         */
    reviewOrigin: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    activator: null,
    statusesTooltipOffset: 0,
  }),
  computed: {
    assessmentsTransformed() {
      const getAssessmentTextColor = assessmentValue => {
        if (assessmentValue === null) {
          return ASSESSMENT_TEXT_COLOR.default
        }

        if (assessmentValue < AVG_ASSESSMENT_BOUNDARIES.maxError) {
          return ASSESSMENT_TEXT_COLOR.error
        }

        if (assessmentValue <= AVG_ASSESSMENT_BOUNDARIES.maxWarning) {
          return ASSESSMENT_TEXT_COLOR.warning
        }

        return ASSESSMENT_TEXT_COLOR.success
      }

      return this.assessments.map(assessment => {
        const filledStars = assessment.value === null
          ? STAR_COUNTS.min
          : assessment.value + 3

        return {
          ...assessment,
          color: getAssessmentTextColor(assessment.value),
          filledStars,
          outlineStars: STAR_COUNTS.max - filledStars,
        }
      })
    },
    originInfo() {
      return REVIEW_ORIGINS[this.reviewOrigin] || REVIEW_ORIGINS.site
    },
  },
  methods: {
    updateOffset() {
      this.statusesTooltipOffset = this.$refs.stars.offsetWidth + ACTIVATORS_GAP_WIDTH
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.review-card-tooltips {
  &__stars {
    display: flex;
    align-items: center;
    border-radius: $border-radius-md;
    padding: 0 8px;
    height: 32px;
    cursor: default;
    color: $ui-kit-text-warning;
    background-color: $ui-kit-bg-warning;

    &_positive {
      color: $ui-kit-text-success;
      background-color: $ui-kit-bg-success;
    }

    &_negative {
      color: $ui-kit-text-error;
      background-color: $ui-kit-bg-error;
    }
  }
}
</style>
