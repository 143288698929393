import { ScheduleDoctors } from 'modules/Schedule'
import { reAppointmentScroll } from 'www/doctors.blocks/common/b-doctor-contacts/functions'

reAppointmentScroll()

$(() => {
  const $main = $('.b-doctor-contacts')

  if ($main.length < 1) {
    return
  }

  const colors = ['#1A5DD0', '#ED2227', '#1EB37C', '#BA5BD3', '#FFA928']
  const pinColors = {}

  let iter = 0

  $('.b-doctor-contacts__lpu').each((i, el) => {
    const $el = $(el)
    const lpuId = $el.data('id')

    pinColors[lpuId] = colors[iter]
    iter = (iter + 1) % colors.length
    $el.find('.b-doctor-contacts__lpu-icon-path').css('stroke', pinColors[lpuId])
  })

  new ScheduleDoctors({
    doctorPageType: 'doctor-inner',
    selectorContainersSlots: '.b-doctor-contacts__calendar',
  })
})
