import { dispatchCustomEventGlobally } from 'utils'
import { POPUP_MAP_EVENTS } from 'www/constants/eventNames'
import {
  ICON_SHAPES,
  POPUP_MAP_ID,
  PANEL_OPTIONS,
  MULTI_ROUTE_TYPES,
  CLUSTER_POPUP_CLASSES,
  NAVIGATION_PANEL_CLASSES,
  BUTTON_CLOSE_POPUP_CLASS,
  BUTTON_DEPARTURE_ADDRESS_CLASS,
} from 'components/common/PopupMap/constants'
import {
  addPanels,
  getPlacemarkTemplate,
  getNavigationPanelTemplate,
  getPinForMultiRouteInstance,
  getCarRoutesMiniInfoTemplate,
  getStartEndAddressTextTemplate,
  getMasstransitRoutesMiniInfoTemplate,
  getMasstransitExpandedInfoContentTemplate,
} from 'components/common/PopupMap/functions/common'
import {
  getClusterBalloonTemplate as getClusterBalloonTemplateDesktop,
  getPlacemarkBalloonTemplate as getPlacemarkBalloonTemplateDesktop,
} from 'components/common/PopupMap/functions/desktop'
import {
  getClusterBalloonTemplate as getClusterBalloonTemplateMobile,
  getPlacemarkBalloonTemplate as getPlacemarkBalloonTemplateMobile,
} from 'components/common/PopupMap/functions/mobile'
import getAllPlacemarksBalloonTemplates
  from 'components/common/PopupMap/functions/common/getAllPlacemarksBalloonTemplates'

export default {
  data: vm => ({
    departureAddress: {},
    multiRouteInstance: null,
    isVisibleDepartureAddressPopup: false,
    lpusData: [],
    boundsAvailableRoutes: [0, 0],
    radiusOfRoutesInstance: null,
    panelInstance: null,
    mapInstance: null,
    clusterInstance: null,
    isVisibleModal: false,
    vuetifyColors: vm.$vuetify.theme.themes.light,
    mapId: POPUP_MAP_ID,
    startPinElement: null,
    finishPinElement: null,
    selectedPlacemark: null,
  }),
  computed: {
    getArrivalAddress() {
      return this.selectedPlacemark ? this.selectedPlacemark.options.get('lpu').coords : []
    },
  },
  created() {
    this.createYandexMapScript()

    window.addEventListener('popstate', () => {
      this.closeModal()
    })
  },
  mounted() {
    dispatchCustomEventGlobally(POPUP_MAP_EVENTS.mounted)
  },
  methods: {
    setContentNavigationPanel(contentTemplate) {
      if (window.MOBILE_VERSION) {
        this.$refs[NAVIGATION_PANEL_CLASSES.block].innerHTML = contentTemplate
      } else {
        this.panelInstance.setContent(contentTemplate)
      }
    },
    renderNavigationPanel({ departureAddressText, isExpandedInfo }) {
      if (isExpandedInfo) {
        const contentTemplate = getMasstransitExpandedInfoContentTemplate({
          multiRoute: this.multiRouteInstance,
          departureAddressText,
          arrivalAddress: this.selectedPlacemark.options.get('lpu').address,
        })

        const navigationTemplate = getNavigationPanelTemplate({
          multiRoute: this.multiRouteInstance,
          contentTemplate,
        })

        this.setContentNavigationPanel(navigationTemplate)

        return
      }

      const startEndAddressTextTemplate = getStartEndAddressTextTemplate({
        departureAddressText,
        arrivalAddress: this.selectedPlacemark.options.get('lpu').address,
      })

      const multiRouteType = this.multiRouteInstance.model.getRoutes()[0].getType()

      const contentRouteTemplate = multiRouteType === MULTI_ROUTE_TYPES.driving
        ? getCarRoutesMiniInfoTemplate(this.multiRouteInstance)
        : getMasstransitRoutesMiniInfoTemplate(this.multiRouteInstance)

      const contentTemplate = `
                ${startEndAddressTextTemplate}
                ${contentRouteTemplate}
            `

      const navigationTemplate = getNavigationPanelTemplate({
        multiRoute: this.multiRouteInstance,
        contentTemplate,
      })

      this.setContentNavigationPanel(navigationTemplate)
    },
    addHandlersForMultiRouteInstance(departureAddress) {
      this.multiRouteInstance.model.events.once('requestsuccess', () => {
        this.renderNavigationPanel({
          departureAddressText: departureAddress.text,
        })
      })

      this.multiRouteInstance.events.add('activeroutechange', () => {
        this.renderNavigationPanel({
          departureAddressText: departureAddress.text,
        })
      })
    },
    createMultiRouteInstance(departureAddress) {
      this.closeDepartureAddressPopup()

      this.panelInstance.setContent('')

      this.multiRouteInstance = new ymaps.multiRouter.MultiRoute({
        referencePoints: [
          departureAddress.coords,
          this.selectedPlacemark.options.get('lpu').coords,
        ],
      }, {
        balloonLayout: '',
        boundsAutoApply: true,
        wayPointStartIconLayout: this.startPinElement,
        wayPointFinishIconLayout: this.finishPinElement,
      })

      this.mapInstance.geoObjects.add(this.multiRouteInstance)
      this.departureAddress = departureAddress

      this.addHandlersForMultiRouteInstance(departureAddress)

      this.selectedPlacemark.options.set('iconLayout', '')
    },
    closeDepartureAddressPopup() {
      this.mapInstance.geoObjects.remove(this.radiusOfRoutesInstance)

      this.isVisibleDepartureAddressPopup = false
    },
    async createPanelInstance() {
      addPanels()

      await ymaps.ready(['Panel'])

      this.panelInstance = new ymaps.Panel()
    },
    getPlacemark(lpu) {
      const markTemplate = getPlacemarkTemplate()
      const iconLayout = ymaps.templateLayoutFactory.createClass(markTemplate)

      const balloonContent = window.MOBILE_VERSION
        ? getPlacemarkBalloonTemplateMobile(lpu)
        : getPlacemarkBalloonTemplateDesktop(lpu)

      return new ymaps.Placemark(lpu.coords, {
        balloonContent,
      }, {
        hasHint: false,
        hasBalloon: false,
        hideIconOnBalloonOpen: false,
        iconShape: ICON_SHAPES.rectangle,
        iconLayout,
        lpu,
      })
    },
    createClusterInstance() {
      const clusterIconContentLayoutTemplate = `
                    <div style="color: ${this.vuetifyColors.primary}">
                        $[properties.iconContent]
                    </div>
            `
      const clusterIconContentLayout = ymaps.templateLayoutFactory.createClass(clusterIconContentLayoutTemplate)

      const clusterIconLayoutTemplate = `
                <div class="popup-map__cluster d-flex align-center justify-center ui-kit-bg-bg-gray-0">
                    <div class="ui-text ui-kit-color-primary ui-text_body-1">$[properties.iconContent]</div>
                </div>
            `
      const clusterIconLayout = ymaps
        .templateLayoutFactory
        .createClass(clusterIconLayoutTemplate)

      this.clusterInstance = new ymaps.Clusterer({
        clusterIconLayout,
        hasBalloon: false,
        hasHint: false,
        gridSize: 128,
        clusterIconContentLayout,
        balloonContent: '',
        iconShape: ICON_SHAPES.circle,
      })
    },
    createMapInstance() {
      const clusterBounds = this.clusterInstance.getBounds()
      let mapStateSetting = { controls: [] }

      document.getElementById(this.mapId).innerHTML = ''
      mapStateSetting = clusterBounds[0].toString() !== clusterBounds[1].toString()
        ? {
          ...mapStateSetting,
          bounds: clusterBounds,
        }
        : {
          ...mapStateSetting,
          center: clusterBounds[0],
          zoom: 15,
        }

      this.mapInstance = new ymaps.Map(
        this.mapId,
        mapStateSetting,
        {
          suppressMapOpenBlock: true,
          yandexMapDisablePoiInteractivity: true,
        },
      )

      this.mapInstance.controls.add(
        this.panelInstance,
        window.MOBILE_VERSION
          ? PANEL_OPTIONS.mobile
          : PANEL_OPTIONS.desktop,
      )
      this.mapInstance.geoObjects.add(this.clusterInstance)
    },
    changePopupFromClusterToPlacemark({
      name,
      phone,
      address,
      id,
    }) {
      const balloonContentPlacemarkTemplate = window.MOBILE_VERSION
        ? getPlacemarkBalloonTemplateMobile({ name, phone, address })
        : getPlacemarkBalloonTemplateDesktop({ name, phone, address })

      this.panelInstance.setContent(balloonContentPlacemarkTemplate)

      this.clusterInstance.getGeoObjects().forEach(placemark => {
        if (id === placemark.options.get('lpu').id) {
          this.selectedPlacemark = placemark
          this.setActivePlacemark(this.selectedPlacemark)
          this.mapInstance.panTo(this.selectedPlacemark.geometry.getCoordinates(), { useMapMargin: true })
        }
      })
    },
    openDepartureAddressPopup() {
      // строим на карте круг радиусом в 100км, чтобы подсказки при вводе адреса были более точными
      this.radiusOfRoutesInstance = new ymaps.Circle(
        [
          this.selectedPlacemark.options.get('lpu').coords,
          100000,
        ],
        {},
        {
          hasBalloon: false,
          hasHint: false,
          visible: false,
        },
      )

      this.mapInstance.geoObjects.add(this.radiusOfRoutesInstance)
      this.boundsAvailableRoutes = this.radiusOfRoutesInstance.geometry.getBounds()

      this.isVisibleDepartureAddressPopup = true
    },
    handleClickMap({ target }) {
      if (target.closest(`.${NAVIGATION_PANEL_CLASSES.activeRoute}.${NAVIGATION_PANEL_CLASSES.masstransitRoute}`)) {
        this.renderNavigationPanel({
          departureAddressText: this.departureAddress.text,
          isExpandedInfo: true,
        })

        return
      }

      if (target.closest(`.${NAVIGATION_PANEL_CLASSES.backToMiniInfo}`)) {
        this.renderNavigationPanel({
          departureAddressText: this.departureAddress.text,
        })

        return
      }

      if (target.closest(`.${BUTTON_CLOSE_POPUP_CLASS}`)) {
        this.panelInstance.setContent('')

        if (this.selectedPlacemark) {
          this.resetMap()
        }

        return
      }

      if (target.closest(`.${NAVIGATION_PANEL_CLASSES.autoButton}`)) {
        this.multiRouteInstance.model.setParams({ routingMode: 'auto' }, true)

        return
      }

      if (target.closest(`.${NAVIGATION_PANEL_CLASSES.masstransitButton}`)) {
        this.multiRouteInstance.model.setParams({ routingMode: 'masstransit' }, true)

        return
      }

      if (target.closest(`.${BUTTON_DEPARTURE_ADDRESS_CLASS}`)) {
        this.openDepartureAddressPopup()

        return
      }

      if (target.closest(`.${NAVIGATION_PANEL_CLASSES.closeNavigation}`)) {
        this.resetMap()
        this.setContentNavigationPanel('')

        return
      }

      const lpuItemNode = target.closest(`.${CLUSTER_POPUP_CLASSES.lpu}`)

      if (lpuItemNode) {
        this.changePopupFromClusterToPlacemark(lpuItemNode.dataset)

        return
      }

      const routeElement = target.closest(`.${NAVIGATION_PANEL_CLASSES.route}`)

      if (routeElement) {
        const selectedIndexRoute = routeElement.dataset.routeIndex
        const selectedRoute = this.multiRouteInstance.getRoutes().get(selectedIndexRoute)

        this.multiRouteInstance.setActiveRoute(selectedRoute)
      }
    },
    handleClickPlacemarksAndClusters(event) {
      const target = event.get('target')

      if (this.selectedPlacemark) {
        this.resetMap()
      }

      if (window.MOBILE_VERSION) {
        this.$refs[NAVIGATION_PANEL_CLASSES.block].innerHTML = ''
      }

      if (target.properties.get('balloonContent')) {
        this.selectedPlacemark = target
        this.setActivePlacemark(target)

        this.panelInstance.setContent(target.properties.get('balloonContent'))
      } else {
        this.panelInstance.setContent(
          window.MOBILE_VERSION
            ? getClusterBalloonTemplateMobile(target)
            : getClusterBalloonTemplateDesktop(target),
        )
      }

      this.mapInstance.panTo(target.geometry.getCoordinates(), { useMapMargin: true })
    },
    async renderYandexMap() {
      await this.createPanelInstance()

      this.createClusterInstance()

      this.lpusData.forEach(lpu => {
        this.clusterInstance.add(this.getPlacemark(lpu))
      })

      let panelContent

      if (this.lpusData.length === 1) {
        // eslint-disable-next-line prefer-destructuring
        this.selectedPlacemark = this.clusterInstance.getGeoObjects()[0]
        this.setActivePlacemark(this.selectedPlacemark)

        panelContent = window.MOBILE_VERSION
          ? getPlacemarkBalloonTemplateMobile(this.lpusData[0])
          : getPlacemarkBalloonTemplateDesktop(this.lpusData[0])
      } else {
        panelContent = getAllPlacemarksBalloonTemplates(this.lpusData)
      }

      window.addEventListener('updatePanelContent', event => {
        event.detail.panelContext.setContent.call(event.detail.panelContext, panelContent)
      })

      if (this.mapInstance) {
        this.mapInstance.destroy()
      }

      // setTimout необходим, так как иначе document.getElementById не может найти только что созданный элемент, заменить на nextTick также не получилось
      setTimeout(() => {
        this.createMapInstance()

        if (window.MOBILE_VERSION) {
          this.$refs[NAVIGATION_PANEL_CLASSES.block].addEventListener('click', this.handleClickMap)
        }

        document.getElementById(this.mapId).addEventListener('click', this.handleClickMap)
        this.clusterInstance.events.add('click', this.handleClickPlacemarksAndClusters)
      })
    },
    handleLoadedYandexMap() {
      if (window.ymaps) {
        window.ymaps.ready(() => {
          const eventNameOpenPopup = window.MOBILE_VERSION
            ? POPUP_MAP_EVENTS.openMobile
            : POPUP_MAP_EVENTS.openDesktop;

          [this.startPinElement, this.finishPinElement] = getPinForMultiRouteInstance()
          window.addEventListener(eventNameOpenPopup, this.handleOpenDialog)
        })
      }
    },
    createYandexMapScript() {
      const script = document.createElement('script')

      script.src = window.YANDEX_MAPS_URL
      script.onload = this.handleLoadedYandexMap

      document.head.append(script)
    },
    handleOpenDialog({ detail }) {
      this.lpusData = detail.lpusData

      this.renderYandexMap()
      this.openModal()
    },
    openModal() {
      this.isVisibleModal = true
    },
    closeModal() {
      const map = document.getElementById(this.mapId)

      if (!map) {
        return
      }

      map.removeEventListener('click', this.handleClickMap)
      this.clusterInstance.events.remove('click', this.handleClickPlacemarksAndClusters)
      this.setContentNavigationPanel('')
      this.isVisibleModal = false
    },
    closeModalWithBackHistory() {
      this.closeModal()
      window.history.back()
    },
    setActivePlacemark(target) {
      const placemarkTemplateBig = getPlacemarkTemplate({ isSelected: true })
      const iconLayout = ymaps.templateLayoutFactory.createClass(placemarkTemplateBig)

      target.options.set('iconLayout', iconLayout)
      target.options.set('iconShape', ICON_SHAPES.bigRectangle)
    },
    resetMap() {
      if (this.multiRouteInstance) {
        this.mapInstance.geoObjects.remove(this.multiRouteInstance)
      }

      const placemarkTemplate = getPlacemarkTemplate()
      const iconLayout = ymaps.templateLayoutFactory.createClass(placemarkTemplate)

      this.selectedPlacemark.options.set('iconLayout', iconLayout)
      this.selectedPlacemark.options.set('iconShape', ICON_SHAPES.rectangle)
    },
  },
}
