var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { attrs: { "data-pagination": "" } },
    [
      _c("v-pagination", {
        attrs: {
          length: _vm.pagesQuantity,
          "total-visible": _vm.totalVisible,
          circle: "",
        },
        on: { input: _vm.onPageChanged },
        model: {
          value: _vm.page,
          callback: function ($$v) {
            _vm.page = $$v
          },
          expression: "page",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }