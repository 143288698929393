import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import SnackbarNotification from './SnackbarNotification2'

const mountElement = document.querySelector('.snackbar-notification-2')

if (mountElement) {
  new Vue({
    vuetify,
    el: mountElement,
    components: {
      SnackbarNotification,
    },
  })
}
