export const MODIFIER = 'b-modal'

export const ROUTER_KEY = 'ModalRouter_v2'

export const STATE = {
  enable: 'enable',
  disable: 'disable',
}

export const ACTIONS = {
  add: 'add',
  show: 'show',
  hide: 'hide',
  remove: 'remove',
}
