/**
 * @typedef Speciality
 * @property { number | null } id
 * @property { string } name
 * @property { string } translit
 * */

/**
 * @typedef PriceList
 * @property { number | null } price
 * @property { number | null } priceHome
 * @property { number | null } priceOnline
 * @property { number | null } priceClub
 * */

/**
 * @typedef Age
 * @property { number | null } min
 * @property { number | null } max
 * */

/**
 * @typedef Workplace
 * @property { number | null } id
 * @property { number | null } doctorId
 * @property { boolean } isInClub
 * @property { Speciality } speciality
 * @property { PriceList } priceList
 * @property { Age } age
 * */

/** @return { Array<Workplace> } */
const Workplaces = () => []

/**
 * @typedef Service
 * @property { number | null } id
 * @property { number | null } synonymId
 * @property { number | null } doctorId
 * @property { string } name
 * @property { number | null } price
 * @property { Age } age
 * */

/** @return { Array<Service> } */
const Services = () => []

export {
  Workplaces,
  Services,
}
