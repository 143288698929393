<template>
  <div
    data-qa="doctor_service_prices_price_list"
    class="doctor-service-prices-price-list"
  >
    <div
      v-for="({ name: categoryName, priceList }, categoryIndex) in categoryList"
      :key="categoryIndex"
      :class="{
        'mb-8': isMobile && categoryIndex < categoryList.length - 1,
        'mb-12': !isMobile && categoryIndex < categoryList.length - 1,
      }"
    >
      <div
        data-qa="doctor_service_prices_category"
        class="ui-text ui-text_h6 ui-kit-color-text mb-6"
      >
        {{ categoryName }}
      </div>
      <div
        v-for="(item, index) in priceList"
        :key="index"
        data-qa="doctor_service_prices_price_item"
        class="doctor-service-prices-price-list__item"
        :class="{
          'd-flex': !isMobile,
          'mb-4': isMobile && index < priceList.length - 1,
          'mb-6': !isMobile && index < priceList.length - 1,
          'flex-wrap': isEnabledFFOnlineAppointmentDoctorsPage,
        }"
      >
        <template v-if="isMobile">
          <div class="mb-3">
            <div
              data-qa="doctor_service_prices_price_item_name"
              class="ui-text ui-text_body-1 uiKitText--text"
              v-text="item.name"
            />
            <div
              v-if="item.agesText"
              data-qa="doctor_service_prices_price_item_ages_text"
              class="ui-text ui-text_body-2 uiKitTextSecondary--text"
              v-text="item.agesText"
            />
          </div>
          <div
            class="d-flex justify-content-between align-items-center"
          >
            <div
              data-qa="doctor_service_prices_price_item_price"
              class="ui-text ui-text_subtitle-1 uiKitText--text"
              v-text="item.formattedPrice"
            />
            <div
              v-if="isEnabledFFOnlineAppointmentDoctorsPage"
              :style="{
                width: '180px',
              }"
              class="d-flex justify-content-end"
            >
              <VBtn
                v-if="item.hasSlots && item.isEnabledServiceDirect && !item.isEnabledCalendar"
                block
                depressed
                color="primary"
                data-qa="doctor_service_prices_price_item_show_calendar"
                @click="emitShowCalendar({ synonymId: item.synonymId })"
              >
                <span class="ui-icon-calendar-date-and-clock ui-kit-color-bg-gray-0" />
                <span class="ui-text ui-text_subtitle-1 ui-kit-color-bg-gray-0">
                  Запись онлайн
                </span>
              </VBtn>
              <VBtn
                v-if="item.isEnabledCalendar"
                depressed
                width="56"
                height="40"
                min-width="56"
                color="ui-kit-bg-bg-gray-40"
                data-qa="doctor_service_prices_price_item_hide_calendar"
                @click="emit('click:hide-calendar')"
              >
                <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                  <span class="b-lpu-services__toggle-calendar-icon ui-icon-arrow-up ui-kit-color-icon-secondary" />
                </div>
              </VBtn>
            </div>
          </div>
          <div
            v-show="item.isEnabledCalendar"
            :id="`doctor-price-calendar-wrapper-${item.synonymId}`"
            class="doctor-price-calendar-wrapper mt-4"
            data-qa="doctor_service_prices_calendar_wrapper"
          />
          <VDivider
            v-if="index < priceList.length - 1"
            class="mt-4"
          />
        </template>
        <template v-else>
          <div
            :class="{
              'w-100': !isEnabledFFOnlineAppointmentDoctorsPage,
            }"
            :style="{
              width: isEnabledFFOnlineAppointmentDoctorsPage ? 'calc(100% - 400px)' : '',
            }"
          >
            <div
              data-qa="doctor_service_prices_price_item_name"
              class="ui-text ui-text_body-1 uiKitText--text"
              v-text="item.name"
            />
            <div
              v-if="item.agesText"
              data-qa="doctor_service_prices_price_item_ages_text"
              class="ui-text ui-text_body-2 uiKitTextSecondary--text"
              v-text="item.agesText"
            />
          </div>
          <div class="d-flex justify-content-between align-items-center">
            <div
              data-qa="doctor_service_prices_price_item_price"
              class="ui-text ui-text_subtitle-1 uiKitText--text text-right"
              :class="{
                'pl-2': !isEnabledFFOnlineAppointmentDoctorsPage,
                'd-flex justify-end align-items-center pr-6': isEnabledFFOnlineAppointmentDoctorsPage,
              }"
              :style="{
                width: '130px',
              }"
              v-text="item.formattedPrice"
            />
            <div
              v-if="isEnabledFFOnlineAppointmentDoctorsPage"
              :style="{
                width: '270px',
              }"
              class="d-flex justify-content-end"
            >
              <VBtn
                v-if="item.hasSlots && item.isEnabledServiceDirect && !item.isEnabledCalendar"
                block
                depressed
                color="primary"
                data-qa="doctor_service_prices_price_item_show_calendar"
                @click="emitShowCalendar({ synonymId: item.synonymId })"
              >
                <span class="ui-icon-calendar-date-and-clock ui-kit-color-bg-gray-0" />
                <span class="ui-text ui-text_subtitle-1 ui-kit-color-bg-gray-0">
                  Запись онлайн
                </span>
              </VBtn>
              <VBtn
                v-if="item.isEnabledCalendar"
                depressed
                width="56"
                height="40"
                min-width="56"
                color="ui-kit-bg-bg-gray-40"
                data-qa="doctor_service_prices_price_item_hide_calendar"
                @click="emit('click:hide-calendar')"
              >
                <div class="w-100 h-100 d-flex align-items-center justify-content-center">
                  <span class="b-lpu-services__toggle-calendar-icon ui-icon-arrow-up ui-kit-color-icon-secondary" />
                </div>
              </VBtn>
            </div>
          </div>
          <div
            v-show="item.isEnabledCalendar"
            :id="`doctor-price-calendar-wrapper-${item.synonymId}`"
            class="doctor-price-calendar-wrapper mt-4"
            data-qa="doctor_service_prices_calendar_wrapper"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  categoryList: Array<{
    name: string,
    priceList: Array<{
      name: string,
      formattedPrice: string,
      agesText: string,
      categoryName: string
      hasSlots: boolean
      synonymId: number
      isEnabledCalendar: boolean
      isEnabledServiceDirect: boolean
    }>,
  }>,
}>()

const emit = defineEmits(['click:show-calendar', 'click:hide-calendar'])
const isMobile = window.MOBILE_VERSION
const isEnabledFFOnlineAppointmentDoctorsPage = window.FEATURE_FLAGS.pd_services_online_appointment_doctors_page

function emitShowCalendar({ synonymId }: { synonymId: number }) {
  const el = document.getElementById(`doctor-price-calendar-wrapper-${synonymId}`)

  emit('click:show-calendar', { synonymId, el })
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.doctor-service-prices-price-list {
  overflow-wrap: break-word;
  word-break: break-word;
}

.doctor-price-calendar-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.doctor-price-schedule-mobile {
  width: 100%;
}
</style>
