import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import Pagination from 'www/vue_components/common/Pagination/Pagination'
import { deepMerge } from 'utils'

/**
 * @author Быков Александр(@bykov)
 * */

export default class {
  constructor(options) {
    this.init(options)
  }

  init(options = {}) {
    this._createOptions(options)

    const {
      updated,
      paginationProps,
      mountContainerSelector,
    } = this.options

    this.container = document.querySelector(mountContainerSelector)

    if (!this.container) {
      return
    }

    if (paginationProps.pagesLength < 2) {
      return
    }

    this.container.innerHTML = '<div class="pagination"></div>'

    paginationProps.mobile = paginationProps.typeDevice === 'mobile'

    this.paginationInstance = new Vue({
      el: this.container.querySelector('.pagination'),
      vuetify,
      render: h => h(Pagination, {
        props: paginationProps,
        on: {
          input: updated,
        },
      }),
    })
  }

  redraw(options) {
    this.destroy()
    this.init(options || this.options)
  }

  get() {
    return this.paginationInstance
  }

  destroy() {
    if (!this.container) {
      return
    }

    this.paginationInstance.$destroy()
    this.container.innerHTML = ''
  }

  static pages({ quantityCards = 1, visibleCardsQuantity = 1 }) {
    const division = quantityCards / visibleCardsQuantity
    const remainder = quantityCards % visibleCardsQuantity

    return remainder === 0 ? division : Math.ceil(division)
  }

  _createOptions(options) {
    this.options = deepMerge({
      updated() {},
      mountContainerSelector: null,
      paginationProps: {
        currentPage: 1,
        typeDevice: 'mobile',
        pagesLength: 1,
        updateLocation: false,
        scrollToSelector: '',
      },
    }, options)
  }
}
