import { defineStore } from 'pinia'

const useAuthorizationStore = defineStore('authorizationStore', {
  state: () => ({
    userPhone: '',
    isPhoneConfirmed: false,
    userAuthorizationRef: null,
    userActionAfterAuthorization: null,
  }),
  actions: {
    updateUserPhone(payload) {
      this.userPhone = payload
    },
    updateIsPhoneConfirmed(payload) {
      this.isPhoneConfirmed = payload
    },
    updateUserAuthorizationRef(payload) {
      this.userAuthorizationRef = payload
    },
    updateUserActionAfterAuthorization(payload) {
      this.userActionAfterAuthorization = payload
    },

    resetUserAuthorization() {
      if (!this.userAuthorizationRef?.resetWithoutPhone) {
        return
      }

      this.userAuthorizationRef.resetWithoutPhone()

      this.updateIsPhoneConfirmed(false)
    },
  },
})

export default useAuthorizationStore
