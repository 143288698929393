/**
 * @description
 * Интерфейсы соответствуют полям, которые ожидаются на странице в атрибуте 'data-schedule-storage'(или его переопределением).
 * Это более общие данные, которые не относятся конкретно к карточке, но глобальные для всей страницы.
 * Если поля совпадать не будут - произойдет ошибка валидации.
 * */

export const getLpuStorageInterface = () => ({ // для страниц с запросом календарей lpu
  lpusId: [], // список lpu id, который необходим для запроса за специальностями
  dateStart: null, // текущая дата
  timedelta: null, // timedelta времени сервера
  specialtyId: null, // используется только на списке клиник - id выбранной специальности
  specialtyName: null, // используется только на списке клиник - название выбранной специальности
  specialtyTranslit: null, // используется только на списке клиник - транслит выбранной специальности
})

export const getDoctorsStorageInterface = () => ({ // для страниц с запросом календарей врачей
  dateStart: null, // текущая дата
  timedelta: null, // timedelta времени сервера
  doctorsLpus: [], // список врачей со слотами([{ lpu_id, doctor_id, lpu_timedelta, has_slots }])
  isDoctorListOnline: null, // является ли страница со списком врачей города 'online'(Телемед)
  isFilterByPlaceOnline: null, // выбран ли фильтр по месту приёма 'Онлайн'(Телемед)
})

export const getServicesStorageInterface = () => ({ // для страниц с запросом календарей диагностики и услуг
  dtypeId: null, // dtype id(3 уровень)
  dclassId: null, // dclass id(1 уровень)
  timedelta: null, // timedelta времени сервера
  dateStart: null, // текущая дата
  serviceId: null, // итоговый id услуги, независимо от уровня вложенности(dcategoryId || dclassId || dtypeId)
  dcategoryId: null, // dcategory id(1 уровень)
  dclassTranslit: null, // транслит dclass
})

export const getDoctorsLpuStorageInterface = () => ({ // для страниц с запросом календарей врачей клиники
  dateStart: null, // текущая дата
  timedelta: null, // timedelta времени сервера
  doctorsLpus: [], // список врачей клиники со слотами([{ lpu_id, doctor_id, doctor_fio }])
})

export const getNodesStorageInterface = () => ({ // примесь для интерфейсов, список узлов, которые в итоге будут добавлены каждому интерфейсу Storage
  coverNode: null, // узел-обертка, у которого найден атрибут dataStorage
})
