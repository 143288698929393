var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VApp",
    [
      _c(
        "VDialog",
        {
          staticClass: "mtlink-modal",
          attrs: {
            value: _vm.isVisible,
            fullscreen: "",
            persistent: "",
            "hide-overlay": "",
            transition: "fade-transition",
          },
        },
        [
          _c(
            "ModalLayout",
            {
              ref: "modalLayout",
              staticClass: "mtlink-modal__layout",
              attrs: {
                "close-to": "",
                "hide-dividers": "",
                "class-body": "d-flex flex-direction-column",
              },
              on: { "modal:back": _vm.handleCloseModal },
            },
            [
              _c(
                "div",
                { staticClass: "mtlink-modal__slider" },
                [_c("BannerMtLinkSlider")],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mtlink-modal__actions" }, [
                _c(
                  "button",
                  {
                    staticClass:
                      "b-button b-button_full-width b-button_blue mb-2",
                    on: { click: _vm.handleInstallApp },
                  },
                  [
                    _c("span", { staticClass: "ui-text ui-text_button" }, [
                      _vm._v("\n            Установить приложение\n          "),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass:
                      "b-button b-button_full-width b-button_text b-button_blue",
                    on: { click: _vm.handleCloseModal },
                  },
                  [
                    _c("span", { staticClass: "ui-text ui-text_button" }, [
                      _vm._v("\n            Закрыть\n          "),
                    ]),
                  ]
                ),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }