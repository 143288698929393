import Vue from 'components'
import CalendarMobile from 'components/mobile/AppointmentCalendar/AppointmentCalendar'
import CalendarDesktop from 'components/desktop/AppointmentCalendar/AppointmentCalendar'
import { createDevNotice, dispatchCustomEventGlobally } from 'utils'
import { SCHEDULE_EVENTS, PAGE_TYPE_DATA } from 'modules/Schedule/constants'
import { getRequestDataInterface } from 'modules/Schedule/functions'
import { scheduleRequest } from 'modules/Schedule/api'
import getInitialDay from 'modules/Schedule/classes/ScheduleData/functions/renderData/helpers/getInitialDay'

class ScheduleVue {
  #options = {
    namespace: 'schedule-container',
  }

  constructor(opts = {}) {
    this.#options = { ...this.#options, ...opts }

    const handleClickSynonymPrice = this.handleClickSynonymPrice.bind(this)
    const handleClickDoctorPrice = this.handleClickDoctorPrice.bind(this)

    window.addEventListener(SCHEDULE_EVENTS.clickSynonymPrice, handleClickSynonymPrice)
    window.addEventListener(SCHEDULE_EVENTS.clickAppointmentDoctorButton, handleClickDoctorPrice)
  }

  /**
   * @public
   * @description
   *
   * Производит сбор данных для запроса за расписанием и производит рендер расписания.
   * Метод конкретно обрабатывает страницу цен клиники.
   * В случае ошибки или отсутствия слотов - эмитит событие для возможности обработки вне модуля
   * В случае успешного запроса и наличия расписания - производит рендер и также эмитит событие для возможности его обработки вне модуля
   *
   * @param { Object } opts
   * @param { Number } opts.lpuId - id клиники
   * @param { Object[HTMLElement] } opts.scheduleElements - данные с узлами на странице(кнопка по которой кликнули, контейнер для монтирования расписания и иные элементы)
   * @param { String } opts.dateStart - дата начала расписания, нужно для запроса
   * @param { Number } opts.synonymId - id синонима, нужно для запроса
   * @param { Number } opts.townTimedelta - timedelta, нужно для запроса
   * @param { Boolean } opts.isEnabledDirect - доступна ли у клиники ОЗ, нужно во время редиректа по слоту, чтобы работал нужный флоу
   * @param { Boolean } opts.isEnabledLpuClub - наличие клубного договора 10% у клиники, нужно во время редиректа по слоту, чтобы работал нужный флоу
   * @param { Boolean } opts.isPreliminaryConsultation - требуется ли первичная консультация, нужно во время редиректа по слоту, чтобы работал нужный флоу
   * */

  async handleClickSynonymPrice(opts) {
    try {
      const { detail } = opts
      const pageType = PAGE_TYPE_DATA.services
      const requestDataInterface = getRequestDataInterface({ pageType })
      const {
        lpuId,
        dateStart,
        doctorId,
        synonymId,
        townTimedelta,
        isEnabledDirect,
        isEnabledLpuClub,
        scheduleElements,
        isLpuPriceList,
        isDoctorPriceList,
        isPreliminaryConsultation,
      } = detail

      const hasCalendarContainer = scheduleElements.wrapperRowScheduleContainer.querySelector('.schedule-container-loaded')

      if (hasCalendarContainer) {
        dispatchCustomEventGlobally(SCHEDULE_EVENTS.renderSuccess, {
          scheduleElements,
          synonymId: detail.synonymId,
        })

        return
      }

      requestDataInterface.day_start = dateStart
      requestDataInterface.town_timedelta = townTimedelta

      if (doctorId) {
        requestDataInterface.services.push({
          lpu_id: lpuId,
          doctor_id: doctorId,
          service_id: synonymId,
        })
      } else {
        requestDataInterface.services.push({
          lpu_id: lpuId,
          service_id: synonymId,
        })
      }

      const { result } = await scheduleRequest({
        pageType,
        data: requestDataInterface,
      })

      if (!result?.length) {
        dispatchCustomEventGlobally(SCHEDULE_EVENTS.redirectSynonymPrice, {
          synonymId,
        })

        return
      }

      const { slots } = result[0]
      const component = window.MOBILE_VERSION ? CalendarMobile : CalendarDesktop
      const days = Object.keys(slots)
      const daysWithSlots = days.filter(date => slots[date].length)
      const isEmptySlotList = !daysWithSlots.length

      if (isEmptySlotList) {
        dispatchCustomEventGlobally(SCHEDULE_EVENTS.redirectSynonymPrice, {
          synonymId,
        })

        return
      }

      const initDay = getInitialDay({
        daysWithSlots,
        currentSlots: slots,
      })

      const props = {
        slots,
        data: slots,
        options: slots,
        days,
        lpuId,
        dtypeId: 0, // Для страницы списка цен dtypeId в форму передавать необязательно, так как на backend есть механизм для его получения без GET параметра
        initDay,
        synonymId,
        daysWithSlots,
        serviceIsDirect: isEnabledDirect,
        isEnabledLpuClub,
        serviceIsPreliminaryConsultation: isPreliminaryConsultation,
        useRedirect: true,
        isLpuPriceList,
        isDoctorPriceList,
        isAppointmentLpu: true,
      }

      const { namespace } = this.#options
      const namespaceList = window.MOBILE_VERSION ? `${namespace}-mobile` : `${namespace}-desktop`

      scheduleElements.wrapperRowScheduleContainer.innerHTML = (`
        <div class="schedule-container schedule-container-loaded d-flex justify-content-end ${namespaceList}">
          <div class="schedule"></div>
        </div>
      `)

      new Vue({
        el: scheduleElements.wrapperRowScheduleContainer.querySelector('.schedule'),
        render: h => h(component, { props }),
      })

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.renderSuccess, {
        scheduleElements,
        synonymId: detail.synonymId,
      })
    } catch ({ message }) {
      const { synonymId } = opts.detail

      createDevNotice({
        module: 'ScheduleVue',
        method: 'handleClickSynonymPrice',
        description: message,
      })

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.redirectSynonymPrice, {
        synonymId,
      })
    }
  }

  /**
   * @public
   * @description
   *
   * Производит сбор данных для запроса за расписанием и производит рендер расписания.
   * Метод конкретно обрабатывает страницу цен врача.
   * В случае ошибки или отсутствия слотов - эмитит событие для возможности обработки вне модуля
   * В случае успешного запроса и наличия расписания - производит рендер и также эмитит событие для возможности его обработки вне модуля
   *
   * @param { Object } opts
   * @param { Number } opts.lpuId - id клиники
   * @param { Number } opts.doctorId - id врача
   * @param { Object[HTMLElement] } opts.scheduleElements - данные с узлами на странице(кнопка по которой кликнули, контейнер для монтирования расписания и иные элементы)
   * @param { String } opts.dateStart - дата начала расписания, нужно для запроса
   * @param { Number } opts.townTimedelta - timedelta, нужно для запроса
   * */

  async handleClickDoctorPrice(opts) {
    try {
      const { detail } = opts
      const pageType = PAGE_TYPE_DATA.doctors
      const requestDataInterface = getRequestDataInterface({ pageType })
      const {
        lpuId,
        doctorId,
        dateStart,
        serviceId,
        doctorsLpus,
        townTimedelta,
        scheduleElements,
      } = detail

      const hasCalendarContainer = scheduleElements.wrapperRowScheduleContainer.querySelector('.schedule-container-loaded')

      if (hasCalendarContainer) {
        dispatchCustomEventGlobally(SCHEDULE_EVENTS.renderSuccess, {
          serviceId,
          scheduleElements,
        })

        return
      }

      requestDataInterface.dt_start = dateStart
      requestDataInterface.doctors_lpus = doctorsLpus
      requestDataInterface.town_timedelta = townTimedelta

      const { result } = await scheduleRequest({
        pageType,
        data: requestDataInterface,
      })

      if (!result?.length) {
        dispatchCustomEventGlobally(SCHEDULE_EVENTS.redirectAppointmentDoctor)
        return
      }

      const { slots } = result[0]
      const component = window.MOBILE_VERSION ? CalendarMobile : CalendarDesktop
      const days = Object.keys(slots)
      const daysWithSlots = days.filter(date => slots[date].length)
      const isEmptySlotList = !daysWithSlots.length

      if (isEmptySlotList) {
        dispatchCustomEventGlobally(SCHEDULE_EVENTS.redirectAppointmentDoctor)
        return
      }

      const initDay = getInitialDay({
        daysWithSlots,
        currentSlots: slots,
      })

      const props = {
        lpuId,
        slots,
        doctorId,
        data: slots,
        options: slots,
        days,
        initDay,
        daysWithSlots,
        useRedirect: true,
        isAppointmentDoctor: true,
      }

      const { namespace } = this.#options
      const namespaceList = window.MOBILE_VERSION ? `${namespace}-mobile` : `${namespace}-desktop`

      scheduleElements.wrapperRowScheduleContainer.innerHTML = (`
        <div class="schedule-container schedule-container-loaded d-flex justify-content-end ${namespaceList}">
          <div class="schedule"></div>
        </div>
      `)

      new Vue({
        el: scheduleElements.wrapperRowScheduleContainer.querySelector('.schedule'),
        render: h => h(component, { props }),
      })

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.renderSuccess, {
        serviceId,
        scheduleElements,
      })
    } catch ({ message }) {
      createDevNotice({
        module: 'ScheduleVue',
        method: 'handleClickDoctorPrice',
        description: message,
      })

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.redirectAppointmentDoctor)
    }
  }
}

export default ScheduleVue
