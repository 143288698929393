import { axiosClient, getCSRFToken } from 'utils'

/**
 * @typedef { Object } IConsultation
 * @property { number } service_id
 * @property { string } nearest_dt
 * */
/**
 * @typedef { Object } TResponseDoctorsAndConsultations
 * @property { number } doctor_id
 * @property { IConsultation[] } consultations
 * */
/**
 * @description Запрашивает врачей и их первичные консультации для указанной услуги
 *
 * @param { Object } requestData - данные запроса
 * @param { number } requestData.lpuId - id клиники
 * @param { number } requestData.synonymId - id синонима/услуги
 * @param { string } requestData.appointmentDate - выбранное время записи (слот)
 *
 * @return { Promise<TResponseDoctorsAndConsultations[]> }
 * */
function getDoctorsAndConsultations({
  lpuId,
  synonymId,
  appointmentDate,
}) {
  return axiosClient({
    url: '/ajax/services/require_consultation/relations/',
    method: 'POST',
    data: {
      lpu_id: lpuId,
      service_requires_consultation_id: synonymId,
      target_dt: appointmentDate,
    },
    headers: {
      'Content-Type': 'application/json',
      'X-CSRFToken': getCSRFToken(),
    },
  })
    .then(({ data }) => data)
}

export default getDoctorsAndConsultations
