import {
  NAVIGATION_PANEL_CLASSES,
  TRANSPORT_ICONS,
  TRANSPORT_NAMES,
} from 'components/common/PopupMap/constants'
import { getPinTemplate } from 'components/common/PopupMap/functions/utils'

function getWalkInfoTemplate({ durationText, distanceText, arrivalText }) {
  const {
    arrivalCircle,
    timeWalkContainer,
    durationText: durationTextClass,
    pedestrianIconExternalInfo,
  } = NAVIGATION_PANEL_CLASSES

  const arrivalTextTemplate = arrivalText
    ? ` 
            <div class="pl-12 d-flex align-center">
                <div class="${arrivalCircle} mr-2 box-sizing-content"></div>
                <div 
                    class="ui-text ui-kit-color-text ui-text_body-1"
                    data-qa="popup_map_arrival_masstransit_text"
                >
                    ${arrivalText}
                </div>
            </div>
        `
    : ''

  return `
        ${arrivalTextTemplate}
        <div class="d-flex">
            <div class="d-flex py-6 ${timeWalkContainer}">
                <div 
                    class="pr-1 box-sizing-content ${durationTextClass}"
                    data-qa="popup_map_duration_pedestrian_text"
                >
                    ${durationText}
                </div>
                <div 
                    class="ui-kit-bg-bg-gray-0 ui-icon-pedestrian ui-icon_fz_medium ui-kit-color-text-secondary ${pedestrianIconExternalInfo}"
                >
                </div>
            </div>
            <div
                class="pl-4 ui-text ui-text_body-1 ui-kit-color-text d-flex align-center"
                data-qa="popup_map_distance_pedestrian_text"
            >
                ${distanceText}
            </div>
        </div>
    `
}

function getTransportInfoTemplate({
  iconTransport,
  durationText,
  arrivalText,
  transportNumbers,
}) {
  const {
    pin,
    pinMasstransit,
    addressTextContainer,
    endpointText,
    durationMasstransitContainer,
    durationTextMasstransit,
    durationText: durationTextClass,
  } = NAVIGATION_PANEL_CLASSES

  return `
        <div class="pl-12 d-flex align-center">
            <div 
                class="${iconTransport} ${pin} ${pinMasstransit}"
            >
            </div>
            <div 
                class="ui-text ui-kit-color-text ui-text_body-1 ${addressTextContainer} ${endpointText}"
                data-qa="popup_map_arrival_masstransit_text"
            >
                ${arrivalText}
            </div>
        </div>
        <div class="d-flex box-sizing-content">
            <div class="${durationMasstransitContainer}">
                <div 
                    class="${durationTextClass} ${durationTextMasstransit} box-sizing-content"
                    data-qa="popup_map_durantion_masstransit_text"
                >
                    ${durationText}
                </div>
            </div>
            ${transportNumbers}
        </div>
    `
}

function getMasstransitExpandedInfoContentTemplate({
  multiRoute,
  departureAddressText,
  arrivalAddress,
}) {
  const {
    backToMiniInfo,
    extendedRouteContainer,
    addressTextContainer,
  } = NAVIGATION_PANEL_CLASSES

  const allRoutes = multiRoute.model.getRoutes()
  const indexActiveRoad = multiRoute.getActiveRoute().properties.get('index')

  let routesTemplate = `
        <div class="mt-4 d-flex cursor-pointer ${backToMiniInfo}">
            <div class="ui-icon-arrow-back pr-3 ui-text ui-kit-color-primary"></div>
            <div
                class="ui-text ui-kit-color-primary ui-text_body-1 d-flex align-center"
                data-qa="popup_map_back_to_mini_info_button"
            >
                К списку маршрутов
            </div>
        </div>
    `

  let mainContent = ''

  let lastDepartureText = ''

  allRoutes[indexActiveRoad].getPaths().forEach(path => {
    path.getSegments().forEach(segment => {
      const { properties } = segment

      const typeSegment = properties.get('type')
      const durationSegment = properties.get('duration')

      if (typeSegment === 'walk' || typeSegment === 'transfer') {
        mainContent += getWalkInfoTemplate({
          distanceText: properties.get('distance').text,
          durationText: durationSegment.text,
          arrivalText: lastDepartureText,
        })
      } else {
        const typesOfTransports = {}
        const transportsInfo = properties.get('transports')
        const iconTransport = TRANSPORT_ICONS[transportsInfo[0].type]

        transportsInfo.forEach(transport => {
          if (!typesOfTransports[transport.type]?.length) {
            typesOfTransports[transport.type] = []
          }

          typesOfTransports[transport.type].push(transport.name)
        })

        let infoAboutSegment = ''

        Object.entries(typesOfTransports).forEach(([nameOfTransport, numbersTransport]) => {
          const listNamesTransports = numbersTransport.length > 10
            ? `${numbersTransport.slice(0, 11).join(', ')} и др.`
            : numbersTransport.join(', ')

          infoAboutSegment += `
                        <div 
                            class="ui-text ui-text_body-2 ui-kit-color-text-secondary"
                            data-qa="popup_map_transport_numbers_text"
                        >
                            ${TRANSPORT_NAMES[nameOfTransport]}: ${listNamesTransports}
                        </div>
                    `
        })

        const stopsInfo = properties.get('stops').features

        // нужно, чтобы если в следующем шаге цикла будет путь пешком, то тогда бы построилась закрывающая метка
        lastDepartureText = stopsInfo[stopsInfo.length - 1].properties.name

        mainContent += getTransportInfoTemplate({
          iconTransport,
          durationText: durationSegment.text,
          arrivalText: stopsInfo[0].properties.name,
          departureText: lastDepartureText,
          transportNumbers: `
                        <div 
                            class="p-4 d-flex justify-center flex-direction-column"
                            data-qa="popup_map_transport_numbers_container"
                        >
                            ${infoAboutSegment}
                        </div>    
                    `,
        })
      }
    })
  })

  routesTemplate += `
            <div class="${extendedRouteContainer}">
                <div class="d-flex pl-12">
                    ${getPinTemplate({ text: 'A', classes: 'mr-2 ui-kit-bg-error' })}
                    <div class="d-flex align-center ${addressTextContainer}">
                        <span
                            class="ui-text ui-text_body-1 ui-kit-color-text w-100"
                            data-qa="popup_map_departure_address_text"
                        >
                            ${departureAddressText}
                        </span>
                    </div>
                </div>
                ${mainContent}
                <div class="d-flex pl-12">
                    ${getPinTemplate({ text: 'B', classes: 'mr-2 ui-kit-bg-primary' })}
                    <div class="d-flex align-center ${addressTextContainer}">
                        <span
                            class="ui-text ui-text_body-1 ui-kit-color-text w-100"
                            data-qa="popup_map_arrival_address_text"
                        >
                            ${arrivalAddress}
                        </span>
                    </div>
                </div>
            </div>
        `

  return routesTemplate
}

export default getMasstransitExpandedInfoContentTemplate
