var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "my-3",
      class: {
        "appointment-tab-list": true,
        "appointment-tab-list_mobile": _vm.isMobile,
        "appointment-tab-list_desktop": !_vm.isMobile,
      },
      attrs: { "data-active-tab-value": _vm.redirectValue },
    },
    [
      _vm.isExistClubTab
        ? _c("ClubDiscountSwitch", {
            staticClass: "appointment-tab-list__switch mb-3",
            attrs: {
              title: "Клубная цена",
              "use-global-dispatch": "",
              discount: 30,
              enabled: _vm.isClubSwitchEnabled,
            },
            on: { "club-discount-switch:click": _vm.handleClickSwitch },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "appointment-tab-list__inner" },
        _vm._l(_vm.tabsDataArray, function (tabData, index) {
          return _c("AppointmentTabItem", {
            key: index,
            ref: "tabRefs",
            refInFor: true,
            attrs: {
              "is-in-club": tabData.isInClub,
              "is-club-switch-enabled": _vm.isClubSwitchEnabled,
              "is-active-tab": tabData.isActiveTab,
              "price-list": _vm.formatPriceList(tabData),
              description: tabData.description,
              "data-qa": tabData.dataQA,
            },
            on: {
              click: function ($event) {
                return _vm.handleClickTab({ tabData, event: $event })
              },
            },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.isVisibleActivatorText
        ? _c(
            "div",
            {
              staticClass:
                "d-inline-flex align-items-center ui-kit-color-error cursor-pointer py-1",
              on: { click: _vm.handleClickShowClubInfoModal },
            },
            [
              _c("VImg", {
                staticClass: "mr-2",
                attrs: {
                  src: "/static/_v1/pd/icons/ui-kit/club/club-percent.svg",
                  alt: "Иконка Процент Клуба",
                  width: "20",
                  height: "20",
                },
              }),
              _vm._v(" "),
              _c(
                "span",
                { staticClass: "ui-text ui-text_body-2 ui-text_underline" },
                [_vm._v("\n      " + _vm._s(_vm.activatorText) + "\n    ")]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isVisibleActivatorText
        ? _c("ClubDiscountInfoBottomSheet", {
            attrs: {
              "full-price": _vm.clubTabData.priceList.price,
              "club-offer-data": _vm.clubOfferData,
            },
            model: {
              value: _vm.isClubInfoVisible,
              callback: function ($$v) {
                _vm.isClubInfoVisible = $$v
              },
              expression: "isClubInfoVisible",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }