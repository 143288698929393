import Vue from 'components'
import vuetify from 'modules/vuetify-plugin'
import BaseDialog from './BaseDialog'

const mountElement = document.querySelector('.base-dialog')

if (mountElement) {
  new Vue({
    vuetify,
    el: mountElement,
    components: {
      BaseDialog,
    },
  })
}
