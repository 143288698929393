<template>
  <SideMenu
    class="patient-profile-modal"
    :value="isVisible"
    :side-menu-width="400"
    use-alternate-content-template
    @side-menu:click-close="handleCloseModal"
  >
    <ModalLayout
      close-to
      limit-title
      :header-padding-x-step="6"
      :title="`Пациент ${patientData.title}`"
      :has-fixed-btn="patientData.isLoaded"
      @modal:back="handleCloseModal"
    >
      <PatientProfileModalContent
        btn-fixed-float
        :patient-data="patientData"
        @modal:close="handleCloseModal"
      />
    </ModalLayout>
  </SideMenu>
</template>

<script>
import SideMenu from 'components/desktop/core/SideMenu'
import ModalLayout from 'components/mobile/core/ModalLayout'
import PatientProfileModalContent from 'components/common/PatientProfile/components/common/PatientProfileModalContent'

export default {
  name: 'PatientProfileModal',
  components: {
    SideMenu,
    ModalLayout,
    PatientProfileModalContent,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    patientData: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('modal:close')
    },
  },
}
</script>
