var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "VCombobox",
    _vm._b(
      {
        ref: "combobox",
        staticClass: "search-combobox",
        attrs: {
          "append-icon": "",
          attach: "",
          "clear-icon": "ui-icon-close-not-a-circle",
          "prepend-inner-icon": "ui-icon-search ui-kit-color-text-info",
          "background-color": _vm.combobox.isFocused
            ? "uiKitBgGray0"
            : "uiKitBgGray40",
          items: _vm.dataItems,
          "menu-props": {
            maxHeight: 252,
            allowOverflow: true,
            offsetOverflow: false,
            rounded: true,
          },
          "hide-no-data": !_vm.visibleSearchHint,
          outlined: "",
          clearable: "",
          "no-filter": "",
          "full-width": "",
          "hide-details": "",
        },
        on: {
          "update:search-input": _vm.handleSearchInputDebounced,
          "click:clear": _vm.handleClearInput,
          input: _vm.handleInput,
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item, on }) {
              return [
                _c(
                  "VListItem",
                  _vm._g({}, on),
                  [
                    _c(
                      "VListItemContent",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.handleClickMenuItem(item)
                          },
                        },
                      },
                      [
                        _c("VListItemTitle", [
                          _vm._v("\n          " + _vm._s(item) + "\n        "),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
          },
          {
            key: "no-data",
            fn: function () {
              return [
                _c(
                  "VListItem",
                  [
                    _c(
                      "VListItemContent",
                      [
                        _c(
                          "VListItemTitle",
                          {
                            staticClass:
                              "ui-text ui-kit-color-text-secondary p-2",
                          },
                          [
                            _vm._v(
                              "\n          Введите " +
                                _vm._s(_vm.minQueryLength) +
                                " символа для поиска\n        "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      "VCombobox",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }