import { axiosClient, getCSRFToken } from 'utils'

function requestNewAppointment(url = '', data = {}) {
  return new Promise((resolve, reject) => {
    axiosClient({
      url,
      data,
      method: 'POST',
      headers: {
        'X-CSRFToken': getCSRFToken(),
      },
    })
      .then(response => resolve(response))
      .catch(rejectedData => reject(rejectedData))
  })
}

export default requestNewAppointment
