import {
  isJSON,
  createEventListener,
  dispatchCustomEventGlobally,
} from 'utils'
import { CLASS_NAMES } from 'www/doctors.blocks/common/b-review-card/constants'
import { PATIENT_PROFILE_EVENTS } from 'components/common/PatientProfile/constants'

function addListenerClickAuthorReview() {
  createEventListener({
    selectors: [
      `.${CLASS_NAMES.authorLink}`,
    ],
    handleEvent: ({ targetNode }) => {
      const { patientData } = targetNode.dataset
      const patientDataJson = isJSON(patientData) ? JSON.parse(patientData) : null

      if (!patientDataJson) {
        return
      }

      dispatchCustomEventGlobally(PATIENT_PROFILE_EVENTS.showModal, patientDataJson)
    },
  })
}

export default addListenerClickAuthorReview
