import { format } from 'date-fns'
import { getTimezoneOffsetInHours } from 'utils'
import {
  getDoctorRequestInterface,
  getDoctorOnlineRequestInterface,
} from 'modules/Schedule/models'

/**
 * @description
 * Собирает данные для запроса за расписанием для врачей
 *
 * @param { Object } opts
 * @param { Object } opts.data - данные 'ScheduleData'
 * @param { Array<Object> } opts[doctorsLpusPart] - список с [{ doctor_fio, doctor_id, lpu_id }], аналогичный data.storage.doctorsLpus, просто собран вручную
 * Используется на странице списка врачей клиники для запросов расписания по частям. Если это поле передано - у него приоритет перед data.storage.doctorsLpus
 *
 * @return { Object } - объект в формате 'getDoctorRequestInterface' | 'getDoctorOnlineRequestInterface'
 * */

const getDoctorsRequestData = ({ data, doctorsLpusPart = [] }) => {
  const {
    dateStart,
    timedelta,
    doctorsLpus,
    isDoctorListOnline,
    isFilterByPlaceOnline,
  } = data.storage

  const isRecalcSlots = isDoctorListOnline || isFilterByPlaceOnline
  const baseData = isRecalcSlots ? getDoctorOnlineRequestInterface() : getDoctorRequestInterface()
  const doctorsHasSlots = doctorsLpusPart.length
    ? doctorsLpusPart
    : doctorsLpus.filter(({ has_slots: hasSlots }) => hasSlots)

  baseData.doctors_lpus = doctorsHasSlots

  if (isRecalcSlots) {
    baseData.dt_start = format(new Date() || 1, 'yyyy-MM-dd') // оператор '||' нужен, чтоб IDE не ругался
    baseData.user_timedelta = getTimezoneOffsetInHours()
    baseData.lpu_timedelta = doctorsHasSlots
      .map(({ lpu_id, lpu_timedelta }) => [lpu_id, lpu_timedelta]) // eslint-disable-line camelcase
      .filter(i => i)

    return baseData
  }

  baseData.dt_start = dateStart
  baseData.town_timedelta = timedelta

  return baseData
}

export default getDoctorsRequestData
