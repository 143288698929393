import {
  getElementOffsetTop,
  testRequiredOptions,
  throttle,
} from 'utils'
import { BLOCK_NAME, CLASS_NAMES } from './constants'

/**
 * Регулирует всплытие и скрытие кнопки
 * @example
 * new BtnFixed({
 *     buttonSelector: '.b-example-btn',
 *     activeClassName: 'b-example-btn_fixed',
 *     limitSelectors: {
 *         top: '.b-example__intro',
 *         bottom: '.b-example__footer',
 *     }
 * });
 * @author @klknv
 * @copyright @panova
 */

class BtnFixed {
  /**
   * @param {Object} options
   * @param {string} [options.buttonSelector] Селектор кнопки, которая будет фиксироваться
   * @param {string} [options.activeClassName] Название класса, которое будет добавляться кнопке для всплытия
   * @param {Object} [options.limitSelectors] Объект с селекторами на узлы, которые задают пределы отображения кнопки
   * @param {string} [options.limitSelectors].top Селектор узла, проскроллив который будет всплывать кнопка
   * @param {string} [options.limitSelectors].bottom Селектор узла, проскроллив который будет скрываться кнопка
   * @param {string} [options.limitSelectors].section Селектор узла, проскроллив который будет всплывать/скрываться кнопка
   * @param {string} [options.sectionOffset] Смещение области скролла для элемента `limitSelectors.section`
   * @param {function|undefined} [options.hookAfterShow] - callback функция, которая будет вызвана после всплытия кнопки
   * @param {function|undefined} [options.hookAfterHide] - callback функция, которая будет вызвана после скрытия кнопки
   */
  constructor(options = {}) {
    this._setInstanceData(options)
    this._init()
  }

  _init() {
    if (!this.isValidOptions || !this.buttonNode) {
      return
    }

    if (!this.limitSectionNode && (!this.limitTopNode || !this.limitBottomNode)) {
      return
    }

    this._updateVisibilityNodeV2()
    window.addEventListener('scroll', throttle(this._updateVisibilityNodeV2, 100))
  }

  /**
   * Устанавливает данные экземпляра.
   * @param {Object} options
   * @private
   */
  _setInstanceData(options) {
    this._createOptions(options)

    this.isValidOptions = this._checkOptions(options)

    if (!this.isValidOptions) {
      return
    }

    this.tempMethod = null
    this.buttonNode = document.querySelector(this.options.buttonSelector)
    this.limitTopNode = document.querySelector(this.options.limitSelectors.top)
    this.limitBottomNode = document.querySelector(this.options.limitSelectors.bottom)
    this.limitSectionNode = document.querySelector(this.options.limitSelectors.section)
  }

  /**
   * @param {Object} options
   * @private
   */
  _createOptions(options) {
    this.options = {
      buttonSelector: `.${BLOCK_NAME}`,
      activeClassName: CLASS_NAMES.buttonActive,
      sectionOffset: {
        top: 0,
        bottom: 0,
      },
      limitSelectors: {},
      hookAfterShow: () => {},
      hookAfterHide: () => {},
      ...options,
    }
  }

  /**
   * @private
   */
  _checkOptions() {
    const {
      buttonSelector,
      activeClassName,
      limitSelectors,
    } = this.options

    return testRequiredOptions({
      module: 'BtnFixed',
      requiredOptions: {
        buttonSelector,
        activeClassName,
        limitSelectors,
      },
    })
  }

  /**
   * Проверяет и при необходимости обновляет видимость кнопки.
   * @private
   */
  _updateVisibilityNode = () => {
    const heightOfViewport = document.documentElement.clientHeight
    const topLimit = this._getElementOffsetBottom(this.limitTopNode)
    const bottomLimit = this._getElementOffsetBottom(this.limitBottomNode) - heightOfViewport

    const isBelowLimitTop = window.pageYOffset > topLimit
    const isBelowLimitBottom = window.pageYOffset > bottomLimit

    const method = (isBelowLimitTop && !isBelowLimitBottom) ? 'add' : 'remove'

    this.buttonNode.classList[method](this.options.activeClassName)
  }

  _updateVisibilityNodeV2 = () => {
    const heightOfViewport = document.documentElement.clientHeight
    const topLimit = this.limitSectionNode
      ? getElementOffsetTop(this.limitSectionNode) - heightOfViewport + this.options.sectionOffset.top
      : this._getElementOffsetBottom(this.limitTopNode)
    const bottomLimit = this.limitSectionNode
      ? this._getElementOffsetBottom(this.limitSectionNode) - heightOfViewport + this.options.sectionOffset.bottom
      : this._getElementOffsetBottom(this.limitBottomNode) - heightOfViewport

    const isBelowLimitTop = window.scrollY > topLimit
    const isBelowLimitBottom = window.scrollY > bottomLimit

    const method = (isBelowLimitTop && !isBelowLimitBottom) ? 'add' : 'remove'

    if (method === this.tempMethod) {
      return
    }

    this.tempMethod = method
    this.buttonNode.classList[method](this.options.activeClassName)

    if (method === 'add') {
      this.options.hookAfterShow()
    } else {
      this.options.hookAfterHide()
    }
  }

  /**
   * Возвращает нижний отступ элемента от верхнего края документа.
   * @param {HTMLElement|Node} el
   * @return {number}
   * @private
   */
  _getElementOffsetBottom(el) {
    return getElementOffsetTop(el) + el.offsetHeight
  }
}

export default BtnFixed
