/**
 * @typedef Statuses
 * @property { 1 } success
 * @property { 2 } warning
 * @property { 3 } error
 * */
/** @type Statuses */
const STATUS_TYPES = {
  success: 1,
  warning: 2,
  error: 3,
}

export {
  STATUS_TYPES,
}
