<template>
  <div
    v-if="!patientData.isLoaded"
  >
    <PatientProfileModalSkeleton />
  </div>
  <div
    v-else
  >
    <div class="ui-text ui-kit-color-text p-4">
      <div class="ui-text ui-text_subtitle-1 mb-6">
        Доверие к профилю
      </div>
      <div class="d-flex flex-column align-items-center mb-6">
        <VProgressCircular
          :rotate="-90"
          :size="104"
          :width="8"
          :value="patientData.trust_percent"
          :color="patientData.can_trust ? 'uiKitSuccess' : 'uiKitRating'"
          class="patient-profile-modal__progress-circular mb-4"
        >
          <div class="ui-text ui-text_h5 ui-kit-color-text">
            {{ patientData.trust_percent }}%
          </div>
        </VProgressCircular>
        <span
          v-if="patientData.can_trust"
          :class="[
            'ui-text ui-text_body-2',
            'patient-profile-modal__status patient-profile-modal__status_green',
          ]"
        >
          Можно доверять
        </span>
        <span
          v-else
          :class="[
            'ui-text ui-text_body-2',
            'patient-profile-modal__status patient-profile-modal__status_dark-yellow',
          ]"
        >
          Доверяйте с осторожностью
        </span>
      </div>
      <div class="mb-10">
        <div
          v-for="(criterion, index) in criteriaTrust"
          :key="criterion.title"
          class="patient-profile-modal__criterion"
          :class="{
            'mb-6': criteriaWithoutTrust.length && index === criteriaTrust.length - 1,
          }"
        >
          <div class="ui-text ui-icon_fz_medium ui-kit-color-success ui-icon-check-circle mr-3" />
          <div
            class="ui-text ui-text_body-1"
            :data-qa="dataQALocators.node_criterion"
          >
            {{ criterion.title }}
          </div>
        </div>
        <div
          v-for="criterion in criteriaWithoutTrust"
          :key="criterion.title"
          class="patient-profile-modal__criterion"
        >
          <div class="ui-text ui-icon_fz_medium ui-kit-color-warning ui-icon-circle-warning mr-3" />
          <div
            class="ui-text ui-text_body-1"
            :data-qa="dataQALocators.node_criterion"
          >
            {{ criterion.title }}
          </div>
        </div>
      </div>
      <div class="mb-10">
        <div class="d-flex justify-content-between ui-text ui-text_subtitle-1 mb-4">
          <span class="mr-4">
            Отзывы
          </span>
          <span>
            {{ countAllReviews }}
          </span>
        </div>
        <VProgressLinear
          v-if="countAccurateReviews"
          class="mb-4"
          height="8"
          rounded
          :value="valueProgressReviews"
          color="uiKitSuccess"
          background-color="uiKitError"
        />
        <div
          v-if="patientData.count_rates_positive"
          class="d-flex justify-content-between mb-2"
        >
          <span class="ui-text ui-text_body-1 ui-kit-color-text-secondary mr-4">
            Положительные
          </span>
          <span class="amount-icon amount-icon_green ui-text ui-text_body-2">
            {{ patientData.count_rates_positive }}
          </span>
        </div>
        <div
          v-if="patientData.count_rates_negative"
          class="d-flex justify-content-between mb-2"
        >
          <span class="ui-text ui-text_body-1 ui-kit-color-text-secondary mr-4">
            Отрицательные
          </span>
          <span class="amount-icon amount-icon_coral ui-text ui-text_body-2">
            {{ patientData.count_rates_negative }}
          </span>
        </div>
      </div>
      <div class="d-flex justify-content-between ui-text ui-text_subtitle-1 mb-6">
        <span class="mr-4">
          Записи на приём через <br>ПроДокторов
        </span>
        <span
          v-if="patientData.count_appointments"
          :data-qa="dataQALocators.node_count_appointments"
        >
          {{ patientData.count_appointments }}
        </span>
        <span
          v-else
          :data-qa="dataQALocators.node_count_appointments"
        >
          Нет
        </span>
      </div>
    </div>
    <div
      :class="[
        'b-btn-fixed b-btn-fixed_active patient-profile-modal__fixed-container',
        { 'p-2': btnFixedFloat },
      ]"
    >
      <button
        :data-qa="dataQALocators.node_btn_close"
        :class="[
          'b-btn-fixed__control b-button b-button_blue patient-profile-modal__fixed-btn',
          { 'patient-profile-modal__fixed-btn_float': btnFixedFloat },
        ]"
        @click="handleCloseModal"
      >
        <span class="ui-text ui-text_button">
          Закрыть
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import PatientProfileModalSkeleton from 'components/common/PatientProfile/components/common/PatientProfileModalSkeleton'
import { PATIENT_PROFILE_QA_GOALS } from 'dataQA'

export default {
  name: 'PatientProfileModalContent',
  components: {
    PatientProfileModalSkeleton,
  },
  props: {
    patientData: {
      type: Object,
      default: () => ({}),
    },
    btnFixedFloat: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    dataQALocators: {
      ...PATIENT_PROFILE_QA_GOALS,
    },
  }),
  computed: {
    countAccurateReviews() {
      return this.patientData.count_rates_positive
                + this.patientData.count_rates_negative
    },
    countAllReviews() {
      return this.countAccurateReviews
                + this.patientData.count_rates_canceled
                + this.patientData.count_rates_without_mark
    },
    valueProgressReviews() {
      return (this.patientData.count_rates_positive * 100) / this.countAccurateReviews
    },
    criteriaTrust() {
      return [...this.patientData.trust_criteria].filter(criteria => criteria.can_trust)
    },
    criteriaWithoutTrust() {
      return [...this.patientData.trust_criteria].filter(criteria => !criteria.can_trust)
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit('modal:close')
    },
  },
}
</script>

<style lang="scss">
.patient-profile-modal {
  &__progress-circular .v-progress-circular__overlay {
    stroke-linecap: round;
  }
}
</style>

<style lang="scss" scoped>
@import '~www/themes/doctors/common/variables';

.patient-profile-modal {
  &__status {
    line-height: normal;
    border-radius: $border-radius-lg;
    padding: 4px 8px;

    &_green {
      background-color: $ui-kit-bg-success;
      color: $ui-kit-success;
    }

    &_dark-yellow {
      background-color: $ui-kit-bg-warning;
      color: $ui-kit-warning;
    }
  }

  &__criterion {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  &__fixed-container {
    position: absolute;
  }

  &__fixed-btn {
    &_float {
      width: 144px;
      margin-left: auto;
    }
  }
}

.amount-icon {
  line-height: normal;
  padding: 4px 6px;
  border-radius: $border-radius-md;

  &_green {
    color: $ui-kit-success;
    background-color: $ui-kit-bg-success;
  }

  &_coral {
    color: $ui-kit-error;
    background-color: $ui-kit-bg-error;
  }
}
</style>
