<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-app data-pagination>
    <v-pagination
      v-model="page"
      :length="pagesQuantity"
      :total-visible="totalVisible"
      circle
      @input="onPageChanged"
    />
  </v-app>
</template>

<script>
import { scrollTo, getElementOffsetTop } from 'utils'

export default {
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    pagesLength: {
      type: Number,
      required: true,
    },
    mobile: {
      type: Boolean,
    },
    updated: {
      type: Function,
      default: () => {},
    },
    scrollToSelector: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      page: this.currentPage,
      totalVisible: this.mobile ? 5 : 7,
    }
  },
  computed: {
    pagesQuantity() {
      const displayQuantityButtons = this.page - 1 + this.totalVisible

      if (this.pagesLength > 7 && displayQuantityButtons < this.pagesLength) {
        return displayQuantityButtons
      }

      return this.pagesLength
    },
    targetScroll() {
      const selector = this.scrollToSelector?.length ? this.scrollToSelector : null

      return document.querySelector(selector)
    },
  },
  watch: {
    currentPage(currentPage) {
      this.page = currentPage
    },
  },
  methods: {
    onPageChanged(pageNumber) {
      setTimeout(() => {
        this.scrollTo()
      })

      this.$emit('input', {
        currentPage: pageNumber,
      })
    },
    scrollTo() {
      if (!this.targetScroll) {
        return
      }

      scrollTo(getElementOffsetTop(this.targetScroll), {
        duration: 1000,
      })
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

#app[data-pagination] {
  width: 100%;
  background: transparent;

  .v-application--wrap {
    min-height: auto;
  }

  .v-pagination {
    &__navigation {
      padding: 0;
      outline: none;
      border-style: none;

      i {
        background-image: none;
      }
    }

    li {
      &:last-child .v-pagination__navigation {
        margin: 0 0 0 8px;
      }

      &:first-child .v-pagination__navigation {
        margin: 0 16px 0 0;
      }
    }

    &__item {
      color: $ui-kit-text-secondary;
      border-style: none;
      padding: 0;
      margin: 0 8px 0 0;
      outline: none;

      &--active {
        pointer-events: none;
        color: $ui-kit-bg-gray-0;
      }
    }

    &__more {
      margin: 0 8px 0 0;
      line-height: 38px;
      color: $ui-kit-text-secondary;
    }
  }

  .v-icon {
    font-size: 16px;
  }
}
</style>
