var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VImg", {
    staticClass: "d-inline-flex cursor-pointer",
    attrs: {
      contain: "",
      width: "20",
      height: "24",
      src: _vm.image.src,
      alt: _vm.image.alt,
    },
    on: {
      click: function ($event) {
        return _vm.$emit("click", $event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }