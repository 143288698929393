import { defineStore } from 'pinia'
import { getLpuData } from 'components/common/AppointmentPage/interfaces'

const useLpuDataStore = defineStore('lpuDataStore', {
  state: () => ({
    lpuData: getLpuData(),
  }),
  getters: {
    formattedLpuPhone() {
      return this.lpuData.phone
        ? `+${this.lpuData.phone.replace(/\D+/g, '')}`
        : ''
    },
  },
})

export default useLpuDataStore
