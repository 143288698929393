import { getServicesRequestInterface } from 'modules/Schedule/models'

/**
 * @description
 * Собирает данные для запроса за расписанием для услуг
 *
 * @param { Object } opts
 * @param { Object } opts.data - данные 'ScheduleData'
 * @param { Array<Object> } opts[servicesPart] - список с [{ lpu_id, service_id }], аналогичный, который собирается в переменной 'services', просто собран вручную
 * Используется при выборе услуги для запроса расписания только для 1 клиники. Если это поле передано - у него приоритет перед переменной 'services'
 *
 * @return { Object } - объект в формате 'getServicesRequestInterface'
 * */

const getServicesRequestData = ({ data, servicesPart = [] }) => {
  const baseData = getServicesRequestInterface()
  const { dateStart, timedelta } = data.storage
  const services = servicesPart.length
    ? servicesPart
    : [...data.items].map(([scheduleContainer, itemData]) => {
      const {
        lpuId,
        synonymPreview,
        serviceIsDirect,
        serviceIsExpress,
        serviceIsAvailable,
      } = itemData

      if (!synonymPreview) {
        return
      }

      const { hasIntervals, id: serviceId } = synonymPreview
      const isExpressOrDirect = serviceIsDirect || serviceIsExpress
      const hasEveryNeedData = serviceId && hasIntervals && scheduleContainer && serviceIsAvailable

      if (!hasEveryNeedData) {
        return
      }

      if (!isExpressOrDirect) {
        return
      }

      return {
        lpu_id: lpuId,
        service_id: serviceId,
      }
    })

  baseData.day_start = dateStart
  baseData.town_timedelta = timedelta
  baseData.services = services.filter(i => i)

  return baseData
}

export default getServicesRequestData
