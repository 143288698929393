/**
 * если на странице выполняется подгрузка отзывов через ajax,
 * следует добавить контейнеру отзывов дата атрибут 'data-review-cards-container'
 */

import Hint from 'modules/Hint'
import PopupContent from 'modules/PopupContent'
import ReviewsPage from 'modules/ReviewsPage'
import { scrollToReviewOrReviewsContainer } from 'modules/ReviewsPage/functions'
import { getSliderRenderFn } from 'blocks/library.blocks/common/b-popup-content/utils'
import { dispatchCustomEventGlobally, observeDOM } from 'utils'
import { addListenerClickAuthorReview, addListenerClickReviewMore } from 'www/doctors.blocks/common/b-review-card/functions'
import { EVENT_NAMES as REVIEW_EVENT_NAMES } from 'www/doctors.blocks/common/b-review-card/constants'

const reviewSelectors = {
  CLASS_HINT: 'b-review-card__hint',
  SELECTOR_BTN_TOOLTIP: '.b-review-card__tooltip',
}

addListenerClickAuthorReview()

new PopupContent({
  id: 'embed-images',
  selector: '.b-review-card__embed-img-wrapper',
  modifier: 'b-review-card',
  pagination: 'dynamic',
  createHead: false,
  createDescription: false,
  beforeShow: getSliderRenderFn('.b-review-card__embed-images-container'),
  modalOptions: {
    useHash: false,
  },
})

const hint = new Hint({
  modifier: reviewSelectors.CLASS_HINT,
  isMobile: false,
  text: `
        Клиника связалась с автором отзыва
        и исправила ситуацию. Он подтвердил, что описанную
        проблему решили. Оценка больше не влияет на рейтинг.
    `,
  target: reviewSelectors.SELECTOR_BTN_TOOLTIP,
  maxWidth: 'large',
  tippyOptions: {
    placement: 'bottom-start',
    trigger: 'mouseenter',
    zIndex: 5,
    offset: [-56, 15],
  },
})

// отслеживание изменений DOM после ajax запросов и обновление
observeDOM({
  selectorParent: '[data-review-cards-container]',
  callbacks: [
    hint.update,
    () => dispatchCustomEventGlobally(REVIEW_EVENT_NAMES.reviewAjaxLoaded),
  ],
})

new ReviewsPage()

scrollToReviewOrReviewsContainer()

document.addEventListener('click', addListenerClickReviewMore)
