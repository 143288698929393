import { createDevNotice } from 'utils'
// noinspection ES6CheckImport
import { APPOINTMENT_TYPES } from 'constants'
import { NAMESPACE, DOCTOR_PAGE_TYPE_DATA } from 'modules/DoctorCardEmitter/constants'

/**
 * @description
 * Производит переключение кнопки 'Вызвать врача на дом' на странице списка врачей/странице врача.
 * Поскольку в списке врачей есть возможность переключения workplace врача - карточка с табами и расписанием переключаются с помощью 'display: none'.
 * Функция умеет на основе 'lpuAddrId' и пространства имен переключать состояние кнопки 'Вызвать врача на дом'.
 * В этом ее недостаток - сильная привязка к пространству имен и разметке.
 *
 * @param { Object } payload
 * @param { HTMLElement } payload.target - цель события, на котором произошел клик(конкретный таб)
 * @param { String } payload.cardSelector - селектор родителя карточки врача. В нем содержатся табы и расписание
 * @param { String } payload.redirectValue - значение таба на момент вызова функции
 * @param { String } payload.doctorPageType - тип страницы врача. Одна из констант 'DOCTOR_PAGE_TYPE_DATA'
 * */

const changeHomeListener = payload => {
  try {
    const {
      target,
      cardSelector,
      redirectValue,
      doctorPageType,
    } = payload
    const { home } = APPOINTMENT_TYPES

    const hiddenClassName = doctorPageType === DOCTOR_PAGE_TYPE_DATA.list
      ? NAMESPACE.scheduleWrapperDocListHidden
      : NAMESPACE.scheduleWrapperDocItemHidden

    const cardContainer = target.closest(cardSelector) // родитель, в котором содержатся и слоты, и табы
    const cardLpuAddrId = target.closest(`[${NAMESPACE.dataLpuAddrId}]`) // родитель, в котором есть только табы

    if (!(cardContainer && cardLpuAddrId)) {
      return
    }

    const lpuAddrId = cardLpuAddrId.getAttribute(NAMESPACE.dataLpuAddrId)

    const scheduleWrapperSelector = doctorPageType === DOCTOR_PAGE_TYPE_DATA.list
      ? `.${NAMESPACE.scheduleWrapperDocList}[${NAMESPACE.dataLpuAddrId}="${lpuAddrId}"]`
      : `.${NAMESPACE.scheduleWrapperDocItem}[${NAMESPACE.dataLpuAddrId}="${lpuAddrId}"]`

    const calendarContainerWrapperList = [...cardContainer.querySelectorAll(scheduleWrapperSelector)]

    calendarContainerWrapperList.forEach(container => {
      const dataRedirectValue = container.getAttribute(NAMESPACE.dataType)

      container.classList.add(hiddenClassName)

      if ( // если выбран таб на дом и текущий элемент содержит такой же атрибут или напротив - не содержит
        (redirectValue === home && dataRedirectValue === home)
                || (redirectValue !== home && dataRedirectValue !== home)
      ) {
        container.classList.remove(hiddenClassName)
      }
    })
  } catch ({ message }) {
    createDevNotice({
      module: 'changeHomeListener',
      description: message,
    })
  }
}

export default changeHomeListener
