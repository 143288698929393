export default {
  data: () => ({
    expansionPanel: window.FEATURE_FLAGS.pd_club_components_update
      ? [
        {
          title: 'К каким врачам можно записаться по клубной цене?',
          description:
                      `Только к тем, у кого возле цены приёма стоит метка клуба.
                      Если метки нет, значит клиника не вступила в клуб.`,
        }, {
          title: 'На что действует скидка?',
          description:
                      `Клубная скидка даётся только на консультацию врача.
                      В некоторых случаях врач оказывает допуслуги: мазок, биопсия, инъекция и т.д.
                      Эти услуги оплачиваются отдельно.`,
        }, {
          title: 'Можно ли вернуть предоплату?',
          description:
                      `Вы можете связаться с клиникой
                      и договориться перенести приём.
                      Вернуть деньги нельзя.`,
        }, {
          title: 'Как подтвердить в клинике, что приём уже наполовину оплачен?',
          description:
                      `Обычно в клинике уже об этом знают.
                      Но чтобы избежать заминок, покажите купон.
                      Он придёт на email и будет доступен в личном кабинете на сайте.`,
        }, {
          title: 'Действуют ли клубные цены без предоплаты на сайте?',
          description:
                      'Нет. Клиники дают скидку только за предоплату в день записи.',
        },
      ]
      : [
        {
          title: 'Можно ли пойти на приём по клубной цене без оплаты на сайте?',
          description:
                      `Клубная цена на приём действует только при наличии невозвратного купона.
                       Если вы не приобрели купон на сайте, вам нужно будет оплатить
                       в клинике приём по обычной стоимости.`,
        }, {
          title: 'Записываться по клубной цене можно ко всем врачам?',
          description:
                      `Только к тем, у которых возле стоимости приёма стоит метка клуба.
                       Если метки нет, значит клиника не вступила в клуб и записаться
                       по клубной цене не получится.`,
        }, {
          title: 'Можно вернуть оплату, если я не смогу пойти на приём?',
          description:
                      `Оплата не возвращается. Вы можете связаться с клиникой и договориться перенести приём.
                       Если вам пойдут навстречу, оплата сохранится.`,
        }, {
          title: 'Как я докажу в клинике, что приём уже наполовину оплачен?',
          description:
                      `Обычно в клинике уже знают об этом.
                       Но чтобы точно не было трудностей, покажите администратору
                       невозвратный купон. Его можно скачать после оплаты.
                       Мы также отправим его вам на почту.`,
        },
      ],
  }),
}
