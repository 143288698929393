import { isClosestNode } from 'utils'

/**
 * @description
 * Производит поиск нужного data.item элемента на страницы, у которого родителем является узел с атрибутом 'data-schedule-item' и возвращает массив данных
 *
 * @param { Object } opts
 * @param { Object } opts.data - интерфейс 'getModuleDataInterface'
 * @param { HTMLElement } opts.target - элемент страницы, родителем которого является
 *
 * @return { [HTMLElement, Object] } - массив, первый элемент которого узел для монтирования календаря, а второй элемент - один из интерфейсов 'getLpuItemInterface'
 * | 'getDoctorsItemInterface'
 * | 'getServicesItemInterface'
 * | 'getDoctorsLpuItemInterface'
 * а также данные об узлах 'getNodesItemInterface'
 * */

export const getItemDataWithParent = ({ data, target }) => (
  [...data.items].find(([, { coverNode }]) => isClosestNode(target, coverNode)) || [null, null]
)
