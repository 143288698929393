import { computed, ref } from 'vue'
import { defineStore, storeToRefs } from 'pinia'
import { format } from 'date-fns'
import { ru } from 'date-fns/locale'
import { createDevNotice, getTimezoneOffsetInHours, isNetworkDisconnect } from 'utils'
import { useDoctorPageLpuListStore } from 'components/common/DoctorPageLpuList/stores'
import { requestSlots } from 'components/common/DoctorPageLpuList/components/DoctorAppointmentCard/components/WorkplaceOrServiceCalendar/api'

export default lpuId => defineStore(`doctorAppointmentCard/${lpuId}`, () => {
  const doctorPageLpuListStore = useDoctorPageLpuListStore()
  const { filterType } = storeToRefs(doctorPageLpuListStore)

  const lpuId = ref(0)
  const timedelta = ref(getTimezoneOffsetInHours())
  const hasSlots = ref(false)
  const nowDateString = ref(format(new Date(), 'yyyy-MM-dd', { locale: ru }))
  const selectedItem = ref({
    id: null,
    synonymId: null,
    doctorId: null,
    name: '',
    price: null,
    clubPrice: null,
    agesText: '',
    isWorkplace: false,
    priceList: null,
  })
  const calendarSlots = ref({})
  const fullCalendarSlots = ref({})
  const isRequestSlotsDone = ref(false)
  const isVisibleFullCalendar = ref(false)
  const isRequestedFullCalendar = ref(false)

  const translatedChosenFilter = computed(() => ({
    clinic: 'price',
    home: 'priceHome',
    telemed: 'priceOnline',
  }[filterType.value]))

  function resetSlots() {
    isRequestedFullCalendar.value = false
    calendarSlots.value = {}
    fullCalendarSlots.value = {}
  }

  async function requestCalendarSlots({
    days = 14,
    allSlots = false,
    isFullCalendar = false,
  } = {}) {
    const slotsData = {
      days,
      allSlots,
      nowDateString: nowDateString.value,
      timedelta: timedelta.value,
    }

    if (selectedItem.value.isWorkplace) {
      Object.assign(slotsData, {
        doctorsAndLpus: [{
          lpuId: lpuId.value,
          doctorId: selectedItem.value.doctorId,
          timedelta: timedelta.value,
          hasSlots: hasSlots.value,
        }],
      })
    } else {
      Object.assign(slotsData, {
        services: [{
          lpuId: lpuId.value,
          serviceId: selectedItem.value.synonymId,
          doctorId: selectedItem.value.doctorId,
        }],
      })
    }

    isRequestSlotsDone.value = false

    try {
      const slots = await requestSlots({
        slotsData,
        isWorkplace: selectedItem.value.isWorkplace,
        isTelemed: filterType.value === 'telemed',
      })

      if (isFullCalendar) {
        fullCalendarSlots.value = slots
      } else {
        calendarSlots.value = slots
      }
    } catch (error) {
      const { message, response } = {}
      const { status } = response || {}

      if (isNetworkDisconnect({
        status,
        message,
      })) {
        return
      }

      createDevNotice({
        description: message || error,
        method: 'requestCalendarSlots',
        module: 'useDoctorAppointmentCardStore',
      })
    } finally {
      isRequestSlotsDone.value = true
    }
  }

  return {
    lpuId,
    timedelta,
    hasSlots,
    nowDateString,
    selectedItem,
    calendarSlots,
    fullCalendarSlots,
    isRequestSlotsDone,
    isVisibleFullCalendar,
    isRequestedFullCalendar,
    translatedChosenFilter,
    resetSlots,
    requestCalendarSlots,
  }
})
