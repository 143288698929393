<template>
  <div
    :class="{
      'px-8': isMobile && appointmentObjectTypes.isServices,
      'px-4': isMobile && appointmentObjectTypes.isDoctor,
    }"
    class="ui-text ui-text_body-2 ui-kit-color-text-secondary text-center"
    data-qa="appointment_form_personal_data"
  >
    Записываясь на {{ appointmentObjectTypes.isServices ? 'диагностику' : 'приём' }}, вы даете согласие на
    <a
      target="_blank"
      href="/info/privacy-policy/"
      class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
    >
      обработку персональных данных
    </a>
    и принимаете
    <a
      target="_blank"
      href="/info/terms-of-use/"
      class="b-link b-link_underline_hover b-link_color_neur-blue d-inline"
    >
      пользовательское соглашение
    </a>
  </div>
</template>

<script>
import { mapState } from 'pinia'
import { useAppointmentDataStore } from 'components/common/AppointmentPage/stores'

export default {
  name: 'AppointmentPersonalData',
  data: () => ({
    isMobile: window.MOBILE_VERSION,
  }),
  computed: {
    ...mapState(useAppointmentDataStore, [
      'appointmentObjectTypes',
    ]),
  },
}
</script>
