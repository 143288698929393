import { dispatchCustomEventGlobally } from 'utils'
import { CLUSTER_POPUP_CLASSES } from 'components/common/PopupMap/constants'

/**
 * Модуль для яндекс карт.
 * Добавляет на карту возможность отображать баллуны яндекс меток и кластеров в виде попапа
 *
 * Inspired by https://yandex.ru/dev/maps/jsbox/2.1/sidebar/
 */
function addPanels() {
  ymaps.modules.define('Panel', [
    'util.augment',
    'collection.Item',
  ], (provide, augment, item) => {
    // eslint-disable-next-line func-names
    const Panel = function (options) {
      Panel.superclass.constructor.call(this, options)
    }

    augment(Panel, item, {
      onAddToMap(map) {
        Panel.superclass.onAddToMap.call(this, map)
        this.getParent().getChildElement(this).then(this._onGetChildElement, this).catch(() => null)
      },
      onRemoveFromMap(oldMap) {
        if (this.clusterPopupBlock) {
          this.clusterPopupBlock.parentNode.removeChild(this.clusterPopupBlock)
        }

        Panel.superclass.onRemoveFromMap.call(this, oldMap)
      },
      _onGetChildElement(parentDomContainer) {
        this.clusterPopupBlock = document.createElement('div')

        this.clusterPopupBlock.classList.add(CLUSTER_POPUP_CLASSES.block)

        if (!window.MOBILE_VERSION) {
          this.clusterPopupBlock.classList.add(CLUSTER_POPUP_CLASSES.desktop)
        }

        this.clusterPopupBlock.innerHTML = `
                    <div
                        class="${CLUSTER_POPUP_CLASSES.container}"
                        data-qa="popup_map_panel_container"
                    ></div>
                `

        parentDomContainer.append(this.clusterPopupBlock)

        this.clusterPopupContainer = this.clusterPopupBlock.querySelector(`.${CLUSTER_POPUP_CLASSES.container}`)

        dispatchCustomEventGlobally('updatePanelContent', { panelContext: this })
      },
      setContent(text) {
        this.clusterPopupBlock.style.display = 'flex'
        this.clusterPopupContainer.innerHTML = text
      },
    })

    provide(Panel)
  })
}

export default addPanels
