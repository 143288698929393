/**
 * @description
 * Интерфейсы соответствуют полям, которые ожидаются на странице в атрибуте 'data-schedule-item'(или его переопределением).
 * Это данные конкретной карточки(карточка врача/lpu).
 * Если поля совпадать не будут - произойдет ошибка валидации.
 * */

export const getLpuItemInterface = () => ({ // для страниц с запросом календарей lpu
  lpuId: null, // id клиники
  hasSlots: null, // наличие слотов у врачей клиники
  doctorsLpuHref: null, // ссылка на список врачей клиники
  isAppointmentOn: null, // включен ли вывод слотов в клинике
})

export const getDoctorsItemInterface = () => ({ // для страниц с запросом календарей врачей
  wpListId: [], // список id всех workplaces врача
  doctorId: null, // id врача
  doctorFio: null, // полное имя врача
  doctorNameShort: null, // краткое имя врача
  doctorSpecialtyId: null, // id выбранной специальности
  hasSpecialPlacement: 0, // наличие спец. размещения. Если значение 1, значит нужно редиректить в форму записи с get параметром со значением 1. Иначе - не передавать get параметр
})

export const getServicesItemInterface = () => ({
  lpuId: null, // id клиники
  serviceIsDirect: null, // включена ли 'Онлайн' запись в клинику
  serviceIsExpress: null, // включена ли 'Экспресс' запись в клинику
  serviceIsAvailable: null, // доступна ли запись в клинику
  isEnabledLpuClub: null, // активен ли клубный договор lpu
  synonymPreview: { // 1 синоним для вывода превью
    id: null, // id синонима
    name: null, // название синонима
    price: null, // цена синонима со скидкой 'club'
    dtypeId: null, // привязанный dtype id к синониму
    isPreliminaryConsultation: false, // требует ли процедура первичный приём
    hasIntervals: null, // наличие слотов синонима
    priceWithDiscount: null, // полная цена, без скидки
  },
})

export const getDoctorsLpuItemInterface = () => ({ // для страниц с запросом календарей врачей клиники
  lpuId: null, // id клиники
  doctorId: null, // id врача
  hasSlots: null, // наличие слотов у врача
  doctorFio: null, // полное имя врача
  doctorNameShort: null, // краткое имя врача
  isAppointmentOn: null, // включен ли вывод слотов в клинике
})

export const getNodesItemInterface = () => ({ // примесь для интерфейсов, список узлов, которые в итоге будут добавлены каждому интерфейсу Item
  coverNode: null, // узел-обертка, у которого найден атрибут dataItem
  scheduleNode: null, // узел, в который в итоге нужно монтировать календарь
  scheduleNodeHTML: null, // содержимое узла, в который в итоге нужно монтировать календарь(используется на услугах)
})
