<template>
  <v-app>
    <SelectModuleBottomSheet
      v-if="lpuBranches.length"
      class="mb-4"
      :options="lpuBranches"
      :active-option-index="activeLpuBranchIndex"
      @option:selected="handleLpuBranchSelection"
    >
      <template #select-option-icon="{ isActive }">
        <v-icon
          v-if="isActive"
          color="primary"
          size="24"
        >
          ui-icon-check
        </v-icon>
      </template>
    </SelectModuleBottomSheet>
    <SelectModuleModal
      modal-title="Фильтр"
      :options="filteredReviewTypes"
      :selected-option="selectedReviewType"
      :prepend-icon="createTemplateRadioButton"
      :append-icon="createTemplateAmount"
      :transform-option-title="getWithoutAmount"
      @select-modal:click-option="handleSelectedReviewType"
    />
  </v-app>
</template>

<script>
import { dispatchCustomEventGlobally } from 'utils'
import SelectModuleModal from 'components/common/SelectModule/SelectModuleModal/components/common/SelectModal'
import SelectModuleBottomSheet from 'components/mobile/SelectModule/SelectModuleBottomSheet'
import { onInitialFiltration } from 'modules/ReviewsPage/functions'

export default {
  name: 'ReviewsFilter',
  components: {
    SelectModuleModal,
    SelectModuleBottomSheet,
  },
  props: {
    reviewTypes: {
      type: Array,
      required: true,
    },
    lpuBranches: {
      type: Array,
      required: false,
      default: () => [],
    },
    activeLpuBranchIndex: {
      type: Number,
      required: false,
      default: 0,
    },
    // HACK: беру из шаблона хардкод селектора модалки, куда нужно будет вынести кастомный оверлей вместе с BottomSheet
    customModalSelector: {
      type: String,
      required: false,
      default: undefined,
    },
  },
  data: () => ({
    typesToShowAlways: ['all', 'positive', 'negative'],
    selectedReviewType: {},
  }),
  computed: {
    filteredReviewTypes() {
      return this.reviewTypes.filter(this.useRenderType)
    },
    inCustomModal() {
      return !!this.customModalSelector
    },
  },
  created() {
    [this.selectedReviewType] = this.filteredReviewTypes
    onInitialFiltration(initialType => {
      this.selectedReviewType = this.filteredReviewTypes.find(type => initialType === type.value)

      // Инициализация прослушивателей может быть позже, поэтому нужна задержка
      setTimeout(() => {
        dispatchCustomEventGlobally('review-type:selected', this.selectedReviewType)
      })
    })

    // HACK: все что ниже - хак для исправления бага белой шапки модалки на iOS, который возникает из-за различия
    // уровней вложенности у элемента оверлея и BottomSheet. Из-за этого приходится создавать свой кастомный оверлей
    // и переносить BottomSheet с помощью :attach в компоненте SelectModuleBottomSheet
    if (!this.inCustomModal) {
      return
    }

    const overlayNode = document.createElement('div')

    overlayNode.classList.add('reviews-filter-overlay')
    overlayNode.setAttribute('aria-hidden', 'true')

    window.addEventListener('sm-bottom-sheet:opened', () => {
      document.querySelector(this.customModalSelector).prepend(overlayNode)
    })

    window.addEventListener('sm-bottom-sheet:closed', () => {
      overlayNode.remove()
    })
  },
  methods: {
    createTemplateAmount({ option }) {
      return `
                    <span class="review-amount-icon review-amount-icon_${option.value} py-1 px-2">
                        ${option.amount || 0}
                    </span>
                `
    },
    createTemplateRadioButton({ option }) {
      const isActive = option === this.selectedReviewType

      return `<div class="ui-icon-radio-${isActive ? 'on' : 'off'} ui-text ui-kit-color-primary mr-8"></div>`
    },
    useRenderType(type) {
      return !type.disabled
                || this.typesToShowAlways.includes(type.value)
    },
    getWithoutAmount(text) {
      return text.split(' — ')[0]
    },
    handleLpuBranchSelection({ option }) {
      window.location.href = option.link
    },
    handleSelectedReviewType({ option }) {
      this.selectedReviewType = option

      dispatchCustomEventGlobally('review-type:selected', option)
    },
  },
}
</script>

<style lang="scss">
@import '~www/themes/doctors/common/variables';

.review-amount-icon {
  padding: 0 8px;
  font-size: 12px;
  border-radius: $border-radius-md;
  background-color: $ui-kit-bg-gray-40;

  &_positive {
    background-color: $ui-kit-bg-success;
  }

  &_neutral {
    background-color: $ui-kit-bg-warning;
  }

  &_negative {
    background-color: $ui-kit-bg-error;
  }
}
</style>

<style lang="scss">
@import '~www/themes/doctors/common/mixins';
@import '~www/themes/doctors/common/variables';
@import '~www/themes/doctors/common/keyframes';

#app {
  @include v-app-reset;
}

.reviews-filter-overlay {
  position: fixed;
  inset: 0;
  z-index: 1;
  background: rgba($ui-kit-text, 0.46);
  will-change: opacity;
  pointer-events: none;
  animation: fadeIn 300ms;
}

// HACK: исправлена потеря шрифтов у компонента BottomSheet с :attach
.v-dialog {
  font-family: $font-family-regular;
}
</style>
