var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _setup.isMobile
    ? _c(
        "VExpansionPanels",
        {
          attrs: { accordion: "", multiple: "", tile: "" },
          model: {
            value: _setup.expandedPanels,
            callback: function ($$v) {
              _setup.expandedPanels = $$v
            },
            expression: "expandedPanels",
          },
        },
        _vm._l(
          _setup.lpuAddressListFiltered,
          function (lpuAddress, lpuAddressIndex) {
            return _c(_setup.DoctorPageLpuListLpu, {
              key: `${lpuAddress.id}-${lpuAddressIndex}-${_setup.filterType}`,
              attrs: {
                "is-expanded": _setup.expandedPanels.includes(lpuAddressIndex),
                "lpu-address": lpuAddress,
              },
            })
          }
        ),
        1
      )
    : _c(
        "VCard",
        { staticClass: "rounded-t-0", attrs: { elevation: "1" } },
        _vm._l(_setup.lpuAddressListFiltered, function (lpuAddress) {
          return _c(_setup.DoctorPageLpuListLpu, {
            key: lpuAddress.id,
            attrs: { "lpu-address": lpuAddress },
          })
        }),
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }