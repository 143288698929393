import { MOUNT_SELECTOR } from 'components/common/BannerMtLink/constants'

const mountElements = [...document.querySelectorAll(MOUNT_SELECTOR)]

async function init() {
  const { default: Vue } = await import(/* webpackMode: "eager" */'components')
  const { default: vuetify } = await import(/* webpackMode: "eager" */'modules/vuetify-plugin')
  const { default: BannerMtLink } = await import(/* webpackMode: "eager" */'components/common/BannerMtLink/components/common/BannerMtLink')

  // Создаётся один инстанс модалки на десктопе, который будет использоваться всеми баннерами
  if (!window.MOBILE_VERSION) {
    const { initPopupContent } = await import(/* webpackMode: "eager" */'components/common/BannerMtLink/functions')

    initPopupContent()
  }

  mountElements.forEach(el => {
    new Vue({
      vuetify,
      el,
      components: {
        BannerMtLink,
      },
    })
  })
}

if (mountElements.length) {
  init()
}
