export const NAMESPACE = {
  dataType: 'data-type',
  dataValue: 'data-value',
  dataLpuAddrId: 'data-lpu-addr-id',

  prgSelector: 'form[action]',

  selectorSelectOption: '.b-select__option',
  selectorDoctorAppointmentButton: '.b-doctor-card__appointment-btn',
  selectorDoctorInnerAppointmentButton: '.b-doctor-contacts__appointment-btn',
  selectorBottomSheetAppointmentButton: '[data-appointment-bottom-sheet-button]',

  selectorDoctorAppointmentHomeButton: '.b-doctor-contacts__na-dom-btn',

  scheduleWrapperDocList: 'b-doctor-card__wp-schedule-wrapper',
  scheduleWrapperDocItem: 'b-doctor-contacts__wp-schedule-wrapper',
  scheduleWrapperDocListHidden: 'b-doctor-card__wp-schedule-wrapper_hidden',
  scheduleWrapperDocItemHidden: 'b-doctor-contacts__wp-schedule-wrapper_hidden',

  lpuAddressCardWrapper: 'b-doctor-contacts__wp',

  expansionPanelsHead: 'b-expansion-panels__head',
  expansionPanelsPanelActive: 'b-expansion-panels__panel_active',
}

export const DOCTOR_PAGE_TYPE_DATA = {
  list: 'doctor-list',
  inner: 'doctor-inner',
}
