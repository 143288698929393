/**
 * @typedef IInitConsultingDoctorData
 * @property { number | null } id
 * @property { string } fullName
 * @property { string } imageSrc
 * @property { string } experience
 * @property { string } category
 * @property { string } science
 * @property { string } narrowSpeciality
 *
 * @typedef { IInitConsultingDoctorData[] } TInitConsultingDoctorsData
 * */
/** @return { TInitConsultingDoctorsData } */
const getInitConsultingDoctorsData = () => []

/**
 * @typedef IConsultingDoctor
 * @property { number | null } id
 * @property { Date | null } nearestAppointmentDate
 * @property { string } nearestAppointmentDateString
 * @property { string } formattedNearestAppointmentDate
 * @property { IDoctorConsultation[] } consultations
 *
 * @typedef { IInitConsultingDoctorData & IConsultingDoctor } ISelectedConsultingDoctor
 * */
/** @return { ISelectedConsultingDoctor } */
const getSelectedConsultingDoctor = () => ({
  id: null,
  fullName: '',
  imageSrc: '',
  experience: '',
  category: '',
  science: '',
  narrowSpeciality: '',
  consultations: [],
  nearestAppointmentDate: null,
  nearestAppointmentDateString: '',
  formattedNearestAppointmentDate: '',
})

/**
 * @typedef IInitDoctorsConsultationData
 * @property { number | null } id
 * @property { string } name
 * @property { string } price
 *
 * @typedef { IInitDoctorsConsultationData[] } TInitDoctorsConsultationsData
 * */
/** @return { TInitDoctorsConsultationsData } */
const getInitDoctorsConsultationsData = () => []

/**
 * @typedef IDoctorConsultation
 * @property { number | null } id
 * @property { Date | null } nearestAppointmentDate
 * @property { string } nearestAppointmentDateString
 * @property { string } formattedNearestAppointmentDate
 *
 * @typedef { IInitDoctorsConsultationData & IDoctorConsultation } ISelectedDoctorConsultation
 * */
/** @return { ISelectedDoctorConsultation } */
const getSelectedDoctorConsultation = () => ({
  id: null,
  name: '',
  price: '',
  relatedDoctorId: null,
  nearestAppointmentDate: null,
  nearestAppointmentDateString: '',
  formattedNearestAppointmentDate: '',
})

/**
 * @typedef IInitPreliminaryConsultationData
 * @property { boolean } isPreliminaryConsultation
 * @property { TInitConsultingDoctorsData } initConsultingDoctorsData
 * @property { TInitDoctorsConsultationsData } initDoctorsConsultationsData
 * */
/** @return { IInitPreliminaryConsultationData } */
const getInitPreliminaryConsultationData = () => ({
  isPreliminaryConsultation: false,
  initConsultingDoctorsData: getInitConsultingDoctorsData(),
  initDoctorsConsultationsData: getInitDoctorsConsultationsData(),
})

/**
 * @typedef { IConsultingDoctor[] } TDoctorsAndConsultationsData
 * */
/** @return { TDoctorsAndConsultationsData } */
const getDoctorsAndConsultationsData = () => []

export {
  getInitConsultingDoctorsData,
  getSelectedConsultingDoctor,
  getInitDoctorsConsultationsData,
  getSelectedDoctorConsultation,
  getInitPreliminaryConsultationData,
  getDoctorsAndConsultationsData,
}
