import { getTabsData, getTabInterface } from 'components/common/AppointmentTabList/interfaces'

/**
 * @description
 *
 * Возвращает базовую заготовку для возможности слияния с данными со страницы.
 * Такой подход гарантирует наличие всех используемых полей. Нужно только проверять их значения.
 *
 * @return { Object }
 * */

function getDefaultTabsData() {
  const { tabsData } = getTabsData()

  Object.entries(tabsData)
    .forEach(([key, value]) => {
      tabsData[key] = {
        ...getTabInterface(),
        ...value,
      }
    })

  return { tabsData }
}

export default getDefaultTabsData
