import './index.scss'

import { deepMerge } from 'utils'
import Pagination from 'modules/Pagination/'

/**
 * @author Быков Александр(@bykov)
 * */

export default class {
  constructor(options) {
    this.init(options)
  }

  /**
     * метод для инициализации
     * уничтожает текущую пагинацию(если есть) и инициализирует класс заново
     * @param options - объект с настройками
     * */

  init(options = {}) {
    this._createOptions(options)

    const { selectorCard } = this.options.attrs

    if (!selectorCard) {
      throw new Error('Необходимо передать "selectorCard" для работы методов класса "CardsDisplay"')
    }

    this._createCardsData()
    this._createOptionsPagination()
    this._createPagination()
    this._updatePaginationPage()
    this._renderCards({
      currentPage: this.currentPosition,
    })
  }

  /**
     * вспомогательные методы класса
     * */

  _renderCards({ currentPage }) {
    this._hideAllCards()

    const currentIndex = currentPage - 1
    const cardsPart = this.groups[currentIndex]
    const { classNameHidden } = this.options.attrs

    if (!cardsPart) {
      return
    }

    cardsPart.forEach(item => {
      item.classList.remove(classNameHidden)
    })
  }

  _hideAllCards() {
    const { classNameHidden } = this.options.attrs

    this.cards.forEach(item => {
      item.classList.add(classNameHidden)
      item.classList.remove('hidden') // фикс legacy, связанный с добавлением класса hidden !important
    })
  }

  _updatePaginationPage(currentPosition) {
    if (currentPosition) {
      this.currentPosition = currentPosition
      return
    }

    const { currentPage } = this.options

    this.currentPosition = currentPage
  }

  _eventPaginationUpdate = ({ currentPage }) => {
    this._renderCards({ currentPage })
    this._updatePaginationPage(currentPage)
    this.options.pagination.updated({ currentPage })
  }

  /**
     * все пропы и вспомогательные классы
     * */

  _createCardsData() {
    let cardsGroup = []
    let groupCounter = 0

    const { selectorCard } = this.options.attrs
    const { visibleCardsQuantity } = this.options

    this.groups = []
    this.cards = [...document.querySelectorAll(selectorCard)]

    const quantityCards = this.cards.length

    if (!quantityCards) {
      return
    }

    this.cards.forEach((item, index) => {
      groupCounter++
      cardsGroup.push(item)

      if (visibleCardsQuantity === groupCounter || quantityCards - 1 === index) {
        this.groups.push(cardsGroup)

        cardsGroup = []
        groupCounter = 0
      }
    })
  }

  _createOptions(options) {
    this.options = deepMerge({
      typeDevice: 'mobile',
      currentPage: 1,
      visibleCardsQuantity: 20,
      attrs: {
        selectorCard: null,
        classNameHidden: 'cards-display_hidden',
        classNameVisible: 'cards-display_visible',
      },
      pagination: {
        updated: () => {},
        selectorScrollTo: '',
        selectorMountPagination: null,
      },
    }, options)
  }

  _createOptionsPagination() {
    const quantityCards = this.cards.length
    const { typeDevice, currentPage, visibleCardsQuantity } = this.options

    if (this.Pagination) {
      this.Pagination.destroy()
    }

    if (!quantityCards) {
      this.paginationOptions = undefined
      return
    }

    const pagesQuantity = Pagination.pages({ visibleCardsQuantity, quantityCards })
    const {
      selectorScrollTo,
      selectorMountPagination,
    } = this.options.pagination

    this.paginationOptions = {
      updated: this._eventPaginationUpdate,
      mountContainerSelector: selectorMountPagination,
      paginationProps: {
        currentPage,
        typeDevice,
        pagesLength: pagesQuantity,
        scrollToSelector: selectorScrollTo,
      },
    }
  }

  _createPagination() {
    if (!this.paginationOptions) {
      return
    }

    const { pagesLength } = this.paginationOptions.paginationProps

    this.Pagination = pagesLength > 1 ? new Pagination(this.paginationOptions) : undefined
  }
}
