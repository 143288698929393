<template>
  <div
    v-if="hasOptions"
  >
    <div
      @click="switchVisible"
    >
      <select-module-button
        :title="buttonTitle"
        :subtitle="buttonSubtitle"
        :is-disabled="isDisabledButton"
        :button-block="buttonBlock"
        :max-width="maxWidth"
        :button-class-list="buttonClassList"
      />
    </div>

    <v-bottom-sheet
      v-model="isVisible"
      :overlay-opacity="overlayOpacity"
      :attach="customOverlayAttach"
      scrollable
      @click:outside="handleBottomSheetClose"
    >
      <v-card
        :max-height="maxHeightSheet"
      >
        <v-card-text
          class="p-0"
        >
          <v-list-item
            v-for="(option, index) in options"
            :key="`bottom-sheet-option-${index}`"
            class="px-2 py-1"
            :disabled="!!option.disabled"
            @click="selectOption({
              index,
              option,
            })"
          >
            <v-list-item-content
              class="p-0"
            >
              <v-list-item-title>
                <slot
                  name="select-option-title"
                  :title="option.title"
                >
                  {{ option.title }}
                </slot>
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="option.subtitle"
              >
                {{ option.subtitle }}
              </v-list-item-subtitle>
            </v-list-item-content>
            <slot
              name="select-option-icon"
              :option="option"
              :is-active="isActiveOptionIndex(index)"
            />
          </v-list-item>
        </v-card-text>
      </v-card>
    </v-bottom-sheet>
  </div>
</template>

<script>
import vuetify from 'modules/vuetify-plugin'
import { testRequiredOptions } from 'utils'
import SelectModuleButton from 'components/common/SelectModule/SelectModuleModal/components/common/SelectModuleButton'
import SelectModuleButtonMixin from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModuleButtonMixin'
import SelectModuleInModalMixin from 'components/common/SelectModule/SelectModuleModal/mixins/SelectModuleInModalMixin'

export default {
  name: 'SelectModuleBottomSheet',
  vuetify,
  components: {
    SelectModuleButton,
  },
  mixins: [
    SelectModuleButtonMixin,
    SelectModuleInModalMixin,
  ],
  props: {
    options: {
      type: Array,
      required: true,
      default: () => ([]),
    },
    eventName: {
      type: String,
      required: false,
      default: null,
    },
    activeOptionId: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    activeOptionIndex: {
      type: [String, Number],
      required: false,
      default: 0,
    },
    selectionType: {
      type: String,
      required: false,
      default: 'index',
    },
  },
  data: vm => ({
    isVisible: false,
    maxHeightSheet: '80vh',
    emitNames: {
      selected: 'option:selected',
    },
    selectionTypes: {
      id: 'id',
      index: 'index',
    },
    defaultIndex: 0,
    optionIndex: vm.activeOptionIndex,
  }),
  computed: {
    hasOptions() {
      return !!this.options.length
    },
    selectedOption() {
      if (this.selectionType === this.selectionTypes.index) {
        return this.options[this.optionIndex] || {}
      }

      if (this.selectionType === this.selectionTypes.id) {
        const data = this.options.find(item => Number(item.id) === Number(this.activeOptionId))

        return data || this.options[this.defaultIndex] || {}
      }

      return {}
    },
    isDisabledButton() {
      return this.options.length <= 1
    },
    buttonTitle() {
      return this.selectedOption.title
    },
    buttonSubtitle() {
      return this.selectedOption.subtitle
    },
  },
  watch: {
    activeOptionIndex(optionIndex) {
      this.optionIndex = optionIndex
    },
  },
  methods: {
    switchVisible() {
      if (this.isDisabledButton) {
        return
      }

      this.isVisible = !this.isVisible
      this.handleBottomSheetOpen()
    },
    selectOption(payload) {
      this.isVisible = false
      this.selectOptionEmit(payload)
      this.handleBottomSheetClose()
    },
    isActiveOptionIndex(index) {
      return index === this.optionIndex
    },
    selectOptionEmit(payload) {
      const testPayload = testRequiredOptions({
        module: 'SelectModuleMixin',
        requiredOptions: {
          payload,
        },
      })

      if (!testPayload) {
        return
      }

      this.optionIndex = payload.index

      this.$emit(this.emitNames.selected, payload)

      if (!this.eventName) {
        return
      }

      const customEvent = new CustomEvent(this.eventName, {
        detail: payload,
      })

      document.dispatchEvent(customEvent)
    },
  },

}
</script>
