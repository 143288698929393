<template>
  <VApp data-qa="doctor_service_prices">
    <slot v-if="!isMounted" />
    <template v-else>
      <div
        data-qa="doctor_service_prices_header"
        class="d-flex justify-space-between mb-3"
      >
        <h2
          v-if="headerText"
          data-qa="doctor_service_prices_header_text"
          class="ui-text ui-text_h5 mr-4"
          v-text="headerText"
        />
        <LpuSelect
          v-if="lpuList.length > 1"
          v-model="selectedLpuId"
          :lpu-list="lpuList"
        />
        <div
          v-else-if="firstLpu"
          data-qa="doctor_service_prices_lpu"
          class="doctor-service-prices-lpu"
          :class="{
            'doctor-service-prices-lpu_desktop': !isMobile,
          }"
        >
          <div
            data-qa="doctor_service_prices_lpu_name"
            class="ui-text ui-text_subtitle-1 uiKitText--text"
            v-text="firstLpu.name"
          />
          <div
            data-qa="doctor_service_prices_lpu_address"
            class="ui-text ui-text_body-2 uiKitTextSecondary--text"
            v-text="firstLpu.address"
          />
        </div>
      </div>
      <SearchCombobox
        class="mb-2"
        placeholder="Найти услугу"
        data-qa="doctor_services_search"
        :initial-data="serviceCategoryList"
        @search:default="handleSearchDefault"
        @search:text="handleSearchText"
        @search:class="handleSearchCategory"
      />
      <template
        v-if="isVisibleServiceList"
      >
        <UpdateInfo
          class="mb-8"
          :status="selectedFormattedServicePricesData.updateInfo.status"
          :date-string="selectedFormattedServicePricesData.updateInfo.dateString"
        />
        <PriceList
          :category-list="searchData.categoryList"
          @click:show-calendar="handleShowCalendar"
          @click:hide-calendar="handleHideCalendar"
        />
      </template>
      <div
        v-else
        class="text-center py-17"
      >
        <img
          class="d-block mb-2 mx-auto"
          data-qa="doctor_services_stub"
          src="/static/_v1/pd/pictures/ui-kit/search.svg"
          width="150"
          height="150"
          alt="/"
        >
        <span class="ui-text ui-kit-color-text ui-text_body-1">
          Услуга не найдена
        </span>
      </div>
    </template>
  </VApp>
</template>

<script>
import {
  getFormattedPrice,
  dispatchCustomEventGlobally,
} from 'utils'
import { AppointmentModules } from 'modules/appointment-modules'
import YandexGoals from 'modules/YandexGoals'
import { ScheduleVue } from 'modules/Schedule'
import { SCHEDULE_EVENTS } from 'modules/Schedule/constants'
import SearchCombobox from 'components/common/core/SearchCombobox/SearchCombobox'
import { getAgesText } from 'components/common/DoctorServicePrices/functions'
import {
  getFormattedServicePricesData,
  getServicePricesDataList,
} from 'components/common/DoctorServicePrices/interfaces'
import { postAppointmentButtonState } from 'components/common/DoctorServicePrices/api'
import {
  LpuSelect,
  PriceList,
  UpdateInfo,
} from 'components/common/DoctorServicePrices/components'

const instanceYaGoals = new YandexGoals()

export default {
  name: 'DoctorServicePrices',
  components: {
    LpuSelect,
    PriceList,
    UpdateInfo,
    SearchCombobox,
  },
  props: {
    headerText: {
      type: String,
      default: '',
    },
    initSelectedLpuId: {
      type: Number,
      default: null,
    },
    servicePricesDataList: {
      type: Array,
      default: getServicePricesDataList,
    },
    initialServiceCategoryList: {
      type: Array,
      default: () => [],
    },
    initScheduleData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isEnabledFFOnlineAppointmentDoctorsPage: window.FEATURE_FLAGS.pd_services_online_appointment_doctors_page,
      selectedLpuId: this.initSelectedLpuId || this.servicePricesDataList?.[0].lpu.id,
      firstLpu: this.servicePricesDataList?.[0].lpu,
      isMounted: false,
      isMobile: window.MOBILE_VERSION,
      searchData: {
        type: '',
        query: '',
        categoryList: [],
      },
      appointmentButtonData: {
        lpuId: null,
        doctorId: null,
        services: [],
      },
    }
  },
  computed: {
    formattedServiceCategoriesDataList() {
      return this.servicePricesDataList.map(servicePricesData => ({
        ...servicePricesData,
        categoryList: servicePricesData.serviceCategories?.map(serviceCategory => ({
          name: serviceCategory.name,
          priceList: serviceCategory.servicePrices.map(servicePrice => ({
            synonymId: servicePrice.synonymId,
            name: servicePrice.name,
            formattedPrice: getFormattedPrice(servicePrice.price),
            agesText: getAgesText(servicePrice.age),
            categoryName: servicePrice.categoryName,
            hasSlots: this.isEnabledFFOnlineAppointmentDoctorsPage
              && this.appointmentButtonData.services.includes(servicePrice.synonymId),
            isEnabledCalendar: false,
            isEnabledServiceDirect: servicePricesData.lpu.isServiceDirect,
          })),
        })),
      }))
    },
    selectedFormattedServicePricesData() {
      return this.formattedServiceCategoriesDataList.find(({ lpu }) => lpu.id === this.selectedLpuId)
        || getFormattedServicePricesData()
    },
    lpuList() {
      return this.servicePricesDataList.map(({ lpu }) => lpu)
    },
    isVisibleServiceList() {
      return this.searchData.categoryList.length
    },
    serviceCategoryList() {
      return this.selectedFormattedServicePricesData.categoryList.map(({ name }) => name)
    },
    selectedDoctorId() {
      try {
        return this.selectedFormattedServicePricesData.serviceCategories[0].servicePrices[0].doctorId // doctorId одинаков везде, поэтому можно выбирать из первого попавшегося списка
      } catch {
        return null
      }
    },
    selectedSynonymList() {
      try {
        return [
          ...new Set(
            this.selectedFormattedServicePricesData
              .serviceCategories.map(i => i.servicePrices.map(j => j.synonymId))
              .flat(Infinity),
          ),
        ]
      } catch {
        return []
      }
    },
  },
  watch: {
    'searchData.query': function () {
      this.updateSearchData()
    },
    async selectedLpuId(val) {
      this.appointmentButtonData.lpuId = val

      if (this.isEnabledFFOnlineAppointmentDoctorsPage) {
        await this.updateAppointmentButtonsState()
      }

      this.updateSearchData()
    },
  },
  async mounted() {
    if (this.isEnabledFFOnlineAppointmentDoctorsPage) {
      await this.updateAppointmentButtonsState()

      new ScheduleVue({
        namespace: 'doctor-price-schedule',
      })

      window.addEventListener(SCHEDULE_EVENTS.renderSuccess, this.handleScheduleRenderSuccess)
      window.addEventListener(SCHEDULE_EVENTS.redirectSynonymPrice, this.handleRedirectAppointment)
    }

    await this.$nextTick()

    this.updateSearchData()

    this.isMounted = true
  },
  methods: {
    handleShowCalendar({ synonymId, el }) {
      instanceYaGoals.send({
        name: 'doctor-price-list-click-button',
      })

      const payload = {
        lpuId: this.appointmentButtonData.lpuId,
        doctorId: this.appointmentButtonData.doctorId,
        dateStart: this.initScheduleData.dateStart,
        doctorsLpus: this.initScheduleData.doctorsLpus
          .filter(({ has_slots: hasSlots, lpu_id: lpuId }) => hasSlots && lpuId === this.appointmentButtonData.lpuId),
        townTimedelta: this.initScheduleData.townTimedelta,
        scheduleElements: {
          wrapperRowScheduleContainer: el,
        },
        synonymId,
        isEnabledDirect: true,
        isEnabledLpuClub: false,
        isDoctorPriceList: true,
        isPreliminaryConsultation: false,
      }

      dispatchCustomEventGlobally(SCHEDULE_EVENTS.clickSynonymPrice, payload)
    },
    handleHideCalendar() {
      this.searchData.categoryList = this.searchData.categoryList.map(i => ({
        ...i,
        priceList: i.priceList.map(j => ({
          ...j,
          isEnabledCalendar: false,
        })),
      }))
    },
    handleSearchDefault() {
      this.searchData.type = ''
      this.searchData.query = ''
    },
    handleSearchText(query = '') {
      this.searchData.type = 'text'
      this.searchData.query = query
    },
    handleSearchCategory(query = '') {
      this.searchData.type = 'category'
      this.searchData.query = query
    },
    handleRedirectAppointment({ detail }) {
      const payload = {
        lpu: this.appointmentButtonData.lpuId,
        synonymId: detail.synonymId,
        targetDoctorId: this.appointmentButtonData.doctorId,
      }

      new AppointmentModules().redirectToNewAppointmentClinic(payload)
    },
    handleScheduleRenderSuccess({ detail }) {
      this.searchData.categoryList = this.searchData.categoryList.map(i => ({
        ...i,
        priceList: i.priceList.map(j => ({
          ...j,
          isEnabledCalendar: detail.synonymId === j.synonymId,
        })),
      }))
    },
    updateSearchData() {
      this.searchData.categoryList = this.getSearchDataCategoryList()
    },
    getSearchDataCategoryList() {
      const { type, query } = this.searchData

      if (!query.length) {
        return this.selectedFormattedServicePricesData.categoryList
      }

      const field = type === 'text' ? 'name' : 'categoryName'

      const categoryList = this.getSearchCategoryListResult({ field, query })

      if (categoryList.length) {
        return categoryList
      }

      const otherField = field === 'name' ? 'categoryName' : 'name' // Таким образом можно вводить категорию и искать по категории (не выбирать категории, а именно вводить) и наоборот

      return this.getSearchCategoryListResult({ field: otherField, query })
    },
    getSearchCategoryListResult({ field, query }) {
      const queryName = query.toLowerCase().trim()

      return this.selectedFormattedServicePricesData.categoryList.map(category => ({
        ...category,
        priceList: category.priceList.filter(
          ({ [field]: text }) => text && text.toLowerCase().trim().includes(queryName),
        ),
      }))
        .filter(({ priceList }) => priceList.length)
    },
    async updateAppointmentButtonsState() {
      try {
        if (!(this.selectedDoctorId && this.selectedSynonymList?.length)) {
          return
        }

        const { data } = await postAppointmentButtonState([{
          lpu_id: this.selectedFormattedServicePricesData.lpu.id,
          doctors: [this.selectedDoctorId],
          services: this.selectedSynonymList,
        }])

        const { lpu_id: lpuId } = data[0]

        if (!data[0].doctors.length) {
          this.appointmentButtonData.services = []
          return
        }

        const { doctor_id: doctorId, services } = data[0].doctors[0]

        this.appointmentButtonData.lpuId = lpuId
        this.appointmentButtonData.doctorId = doctorId
        this.appointmentButtonData.services = services
      } catch { /* empty */ } // Не обрабатывается, поскольку если не получим состояние кнопок - не страшно. У пользователя могут быть иные целевые действия на странице
    },
  },
}
</script>

<style lang="scss">
.doctor-service-prices-lpu {
  &_desktop {
    max-width: 359px;
  }
}
</style>
