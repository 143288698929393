<template>
  <div
    class="search-empty"
    data-qa="service_synonym_search_empty"
  >
    <VImg
      contain
      width="150"
      height="150"
      class="search-empty__image"
      src="/static/_v1/pd/pictures/ui-kit/search.svg"
      alt="Иконка поиска"
    />
    <div class="ui-text ui-text_body-1 ui-kit-color-text">
      Такой услуги нет в списке
    </div>
  </div>
</template>

<script>
export default {
  name: 'SynonymListSearchEmpty',
}
</script>

<style lang="scss" scoped>
.search-empty {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__image {
    margin-top: 20vh;
    margin-bottom: 16px;
  }
}
</style>
