import { axiosClient } from 'utils'

/**
 * Смотрим ответы по ссылке ниже
 * https://git.medrocket.ru/private/pd/-/blob/master/docs/club/api.md#get-clubajaxoffer_data
 * */

const cachedRequests = {}

function createRequestClubActiveCard({ wpID } = {}) {
  if (cachedRequests[wpID]) {
    return cachedRequests[wpID]
  }

  cachedRequests[wpID] = new Promise((resolve, reject) => {
    if (!wpID) {
      reject()

      return
    }

    axiosClient
      .get(`/club/ajax/offer_data/?workplace_id=${wpID}`)
      .then(({ data } = {}) => resolve(data))
      .catch(({ response } = {}) => {
        cachedRequests[wpID] = null

        reject(response)
      })
  })

  return cachedRequests[wpID]
}

export default createRequestClubActiveCard
