import {
  BLOCK_NAME as REVIEW_BLOCK_NAME,
  CLASS_NAMES as REVIEW_CLASS_NAMES,
} from 'www/library.mobile.blocks/common/b-review-card/constants'
import { HIDDEN } from 'constants/classNames'

/** @typedef { 'all' | 'positive' | 'neutral' | 'negative' | 'resolved' | 'unconfirmed' | 'deleted' } ReviewTypes */
/** @typedef { Record<ReviewTypes, Element[]> } ReviewNodes */

/**
 * @author Денис Курочкин (@kurochkin)
 */
class ReviewsStorage {
  /**
   * @param {Object} options
   * @param {Element} options.container
   */
  constructor(options = {}) {
    this._createOptions(options)
    this._init()
  }

  /**
   * Возвращает общее количество узлов хранилища, статичных и подгружаемых по AJAX.
   * @param { ReviewTypes } type
   * @returns { number }
   */
  getTotalAmount(type) {
    return this.nodes.static[type].length + this.nodes.ajax[type].length
  }

  /**
   * Возвращает все узлы хранилища, статичные и подгружаемые по AJAX.
   * @param { ReviewTypes } [type]
   * @returns { Element[] }
   */
  getAllNodes(type = 'all') {
    return [...this.nodes.static[type], ...this.nodes.ajax[type]]
  }

  /**
   * Возвращает кэшированные узлы хранилища.
   * @param { ReviewTypes } type
   * @returns { Element[] }
   */
  getCachedNodes(type) {
    this.nodes.ajax[type] = [...this.nodes.cached[type]]

    return this.nodes.ajax[type]
  }

  /**
   * Определяет, есть ли кэшированные узлы для передаваемого типа в хранилище.
   * @param { ReviewTypes } type
   * @returns {boolean}
   */
  hasCachedNodes(type) {
    return !!this.nodes.cached[type].length
  }

  /**
   * @param { ReviewTypes } type
   * @param { Element[] } nodes
   */
  storeAjaxNodes(type, nodes = []) {
    this.nodes.ajax[type].push(...nodes)
    this.nodes.cached[type].push(...nodes)
  }

  /**
   * Удаляет подгруженные по AJAX узлы из хранилища и DOM-дерева.
   * @param { ReviewTypes } type
   */
  removeAjaxNodes(type) {
    this.nodes.ajax[type].forEach(node => node.remove())
    this.nodes.ajax[type] = []
    this.nodes.cached[type].forEach(node => node.classList.add(HIDDEN))
  }

  /**
   * @param {Object} options
   * @private
   */
  _createOptions(options) {
    this.options = {
      container: null,
      ...options,
    }
  }

  /**
   * @private
   */
  _init() {
    this._createNodesStructure()
    this._setNodes()
    this._setData()
  }

  /**
   * @private
   */
  _setData() {
    /** @type { Record<ReviewTypes, number> } */
    this.ajaxPage = {
      all: 1,
      positive: 1,
      neutral: 1,
      negative: 1,
      resolved: 1,
      unconfirmed: 1,
      deleted: 1,
    }
  }

  /** @private */
  _createNodesStructure() {
    /** @return { ReviewNodes } */
    const baseStructure = () => ({
      all: [],
      positive: [],
      neutral: [],
      negative: [],
      resolved: [],
      unconfirmed: [],
      deleted: [],
    })

    this.nodes = {
      static: baseStructure(),
      ajax: baseStructure(),
      cached: baseStructure(),
    }
  }

  /**
   * Устанавливает изначальные значения узлов в хранилище.
   * @private
   */
  _setNodes() {
    const getFilteredNodes = className => this.nodes.static.all.filter(node => node.classList.contains(className))

    this.nodes.static.all = [...this.options.container.querySelectorAll(`.${REVIEW_BLOCK_NAME}`)]
    this.nodes.static.positive = getFilteredNodes(REVIEW_CLASS_NAMES.positive)
    this.nodes.static.neutral = getFilteredNodes(REVIEW_CLASS_NAMES.neutral)
    this.nodes.static.negative = getFilteredNodes(REVIEW_CLASS_NAMES.negative)
    this.nodes.static.resolved = getFilteredNodes(REVIEW_CLASS_NAMES.resolved)
    this.nodes.static.unconfirmed = getFilteredNodes(REVIEW_CLASS_NAMES.unconfirmed)
    this.nodes.static.deleted = getFilteredNodes(REVIEW_CLASS_NAMES.deleted)
  }
}

export default ReviewsStorage
