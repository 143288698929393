import { deepMerge, isContentFullyScrolled } from 'utils'
import { HIDDEN } from 'constants/classNames'
import { ACTIONS } from './constants'
import { SELECTORS } from 'modules/ReviewsPage/constants'

/**
 * Класс, реализующий последовательную загрузку узлов списка их скрытием и показов посредством CSS.
 * @author Денис Курочкин (@kurochkin)
 * @copyright Панова Анастасия(@panova)
 * @example
 * new NodeListLoader({
 *     containerNode: modal.nodes.body,
 *     nodes: reviewNodes, // обязательный аргумент
 *     nodesPreloaded: 20,
 *     hideItemsOnInit: false,
 *     onLoadEventSelectors: ['.b-doctor-details__modal-show-more'],
 *     onLoadFinishedEventSelectors: ['.b-doctor-details__modal-show-more'],
 * });
 */
class NodeListLoader {
  /**
     * @param {Object} options
     * @param {Element|undefined} options.containerNode Элемент списка загружаемых элементов.
     * @param {Element[]} options.nodes Узлы списка, с которыми необходимо взаимодействовать.
     * @param {number|undefined} options.nodesPreloaded Количество изначально загруженных элементов списка.
     * @param {number|undefined} options.nodesPerLoad Количество загружаемых элементов за одну загрузку.
     * @param {boolean|undefined} options.hideItemsOnInit Определяет, загружать ли элементы контейнера, если содержимое контейнера было достаточно проскроленно.
     * @param {boolean|undefined} options.autoLoad Определяет, загружать ли элементы контейнера, если содержимое контейнера было достаточно проскроленно.
     * @param {string[]|undefined} options.onLoadEventSelectors Селекторы элементов, при клике на которые необходимо загружать элементы.
     * @param {string[]|undefined} options.onLoadFinishedEventSelectors Селекторы элементов, скрываемые при загрузке всех элементов.
     * @param {Function|Promise|undefined} options.onBeforeLoad Коллбэк, срабатывающий перед загрузкой элементов
     * @param {Function|undefined} options.onAfterLoad Коллбэк, срабатывающий при завершении загрузки элементов.
     */
  constructor(options = {}) {
    this._createOptions(options)
    this._init()
  }

  /**
     * Возвращает количество узлов в массиве с узлами модуля.
     * @returns {number}
     * @private
     */
  get _nodesTotal() {
    return this.nodes.items.length
  }

  /**
     * Определяет, были ли загружены все элементы списка.
     * @returns {boolean}
     * @private
     */
  get _isFullyLoaded() {
    return this.data.nodesLoadedTotal >= this._nodesTotal
  }

  /**
     * Обновляет экземпляр класса.
     * @param {Object} props
     * @param {Object|null} props.nodes
     * @param {Element[]|undefined} props.nodes.items
     * @public
     */
  update(props = {}) {
    if (props.nodes) {
      this.nodes.items = props.nodes

      this._setState()
      this._setData()
      this._manageEventListeners(ACTIONS.add)
      this.toggleControlsDisplay(ACTIONS.add)

      this._load()
    }
  }

  /**
     * Обновляет узлы, хранимые экземпляром класса.
     * @param {Element[]} nodes
     */
  updateNodes(nodes) {
    this.nodes.items = nodes
  }

  /**
     * При загрузке всех элементов списка, скрывает или показывает элементы, найденные по селекторам из параметров вызова.
     * @param {('add'|'remove')} action
     * @public
     */
  toggleControlsDisplay(action) {
    if (!this.nodes.hideOnLoadFinished) {
      return
    }

    const method = action === ACTIONS.remove ? ACTIONS.add : ACTIONS.remove

    this.nodes.hideOnLoadFinished.forEach(node => node.classList[method](HIDDEN))
  }

  /**
     * Выполняет deep merge базовых и пользовательских опций при создании экземпляра класса.
     * @param {Object} options
     * @private
     */
  _createOptions(options) {
    const nodesContainer = document.querySelector(SELECTORS.container)
    const nodesPreloadedDefault = window.MOBILE_VERSION ? 20 : 10

    const nodesNumber = Number(nodesContainer?.dataset?.rpNodesPreloaded) || nodesPreloadedDefault

    this.options = deepMerge({
      containerNode: null,
      nodes: null,
      nodesPreloaded: nodesNumber,
      nodesPerLoad: nodesNumber,
      hideItemsOnInit: true,
      autoLoad: false,
      onLoadEventSelectors: [],
      onLoadFinishedEventSelectors: [],
      onBeforeLoad: null,
      onAfterLoad: null,
    }, options)
  }

  /**
     * Инициализирует экземпляр класса.
     * @private
     */
  _init() {
    this._checkRequiredOptions()
    this._setNodes()
    this._setData()
    this._setState()
    this._addFilterChangeListener()
    this._manageEventListeners(ACTIONS.add)

    this._load()

    if (this.options.hideItemsOnInit) {
      this._hideNodes(this.options.nodesPreloaded)
    }
  }

  /**
     * Проверяет наличие обязательных опций при создании экземпляра класса,
     * при отсутствии выдает ошибку.
     * @private
     */
  _checkRequiredOptions() {
    if (!this.options.nodes) {
      throw new Error('"nodes" array is required parameter')
    }
  }

  /**
     * Устанавливает параметры элементов экземпляра класса.
     * @private
     */
  _setNodes() {
    const { onLoadFinishedEventSelectors: selectorsToHide } = this.options

    this.nodes = {
      items: this.options.nodes,
    }

    if (selectorsToHide.length) {
      this.nodes.hideOnLoadFinished = [...document.querySelectorAll(selectorsToHide.join())]
    }
  }

  /**
     * Устанавливает параметры данных экземпляра класса.
     * @private
     */
  _setData() {
    this.data = {
      nodesLoadedTotal: 0,
      pageNumber: 0,
    }
  }

  /**
     * Устанавливает состояние экземпляра класса.
     * @private
     */
  _setState() {
    this.state = {
      loading: false,
      fullyLoaded: false,
    }
  }

  /**
     * Загружает элементы в списке, обновляет количество загруженных элементов.
     * @private
     */
  async _load() {
    if (this.state.loading || this.state.fullyLoaded) {
      return
    }

    this.state.loading = true

    if (this.options.onBeforeLoad) {
      await this.options.onBeforeLoad(this)
    }

    this._loadNodes()

    if (this.nodes.items.some(node => node.classList.contains(HIDDEN))) {
      const showNodeIndex = this.options.nodesPreloaded + (this.options.nodesPerLoad * this.data.pageNumber)

      this._showNodes(0, showNodeIndex)
    }

    const isAllNodesShowed = !this.nodes.items.some(node => node.classList.contains(HIDDEN))

    if (this._isFullyLoaded && isAllNodesShowed) {
      this._loadFinishedHandler()
    }

    if (this.options.onAfterLoad) {
      this.options.onAfterLoad(this)
    }

    this.state.loading = false
  }

  /**
     * Возвращает порцию элементов в зависимости от введенных параметров.
     * @param {number} start
     * @param {number|undefined} end
     * @returns {Element[]}
     * @private
     */
  _sliceNodes = (start, end) => this.nodes.items.slice(start, end)

  /**
     * Показывает узлы списка.
     * @private
     */
  _loadNodes() {
    const upToTotalAmount = this.data.nodesLoadedTotal === 0
      ? this.options.nodesPreloaded
      : this.data.nodesLoadedTotal + this.options.nodesPerLoad
    const nodesToLoad = this._sliceNodes(this.data.nodesLoadedTotal, upToTotalAmount)

    const isNeedToShowMoreNodes = (this.data.pageNumber + 1) * this.options.nodesPreloaded
        < this.data.nodesLoadedTotal

    if (isNeedToShowMoreNodes) {
      nodesToLoad.forEach(node => node.classList.remove(HIDDEN))
    }

    this.data.nodesLoadedTotal = upToTotalAmount
  }

  /**
     * Скрывает элементы списка.
     * @param {number} start
     * @param {number|undefined} end
     * @private
     */
  _hideNodes = (start, end = undefined) => {
    this._sliceNodes(start, end).forEach(node => node.classList.add(HIDDEN))
  }

  /**
     * Показывает элементы списка.
     * @param {number} start
     * @param {number|undefined} end
     * @private
     */
  _showNodes = (start, end = undefined) => {
    this._sliceNodes(start, end).forEach(node => node.classList.remove(HIDDEN))
  }

  /**
     * Загружает элементы в списке, если цель события совпадает с селектором пользователя.
     * @param {Event} event
     * @param {EventTarget} event.target
     * @private
     */
  _onLoadSelectorsClick = ({ target }) => {
    if (target.closest(this.options.onLoadEventSelectors.join())) {
      this._load()

      this.data.pageNumber++
    }
  }

  /**
     * Загружает элементы в списке, если содержимое контейнера было достаточно проскроленно.
     * @private
     */
  _onContainerScroll = () => {
    if (isContentFullyScrolled(this.options.containerNode, 300)) {
      this._load()
    }
  }

  /**
     * Обрабатывает событие, когда все узлы списка оказываются загруженными.
     * @private
     */
  _loadFinishedHandler() {
    this.data.nodesLoadedTotal = this._nodesTotal

    this._manageEventListeners(ACTIONS.remove)
    this.toggleControlsDisplay(ACTIONS.remove)

    this.state.fullyLoaded = true
  }

  /**
     * Управляет прослушивателями событий.
     * @param {('add'|'remove')} action
     * @private
     */
  _manageEventListeners(action) {
    const eventListenerMethod = `${action}EventListener`

    if (this.options.onLoadEventSelectors.length) {
      document[eventListenerMethod]('click', this._onLoadSelectorsClick)
    }

    if (this.options.autoLoad) {
      this.options.containerNode[eventListenerMethod]('scroll', this._onContainerScroll)
    }
  }

  _addFilterChangeListener() {
    window.addEventListener('review-type:selected', () => {
      this._manageEventListeners(ACTIONS.add)
      this.state.fullyLoaded = false
    })
  }
}

export default NodeListLoader
