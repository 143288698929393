import { getQueryParam, scrollToElement, setQueryParam } from 'utils'
import { REVIEW_TYPES } from '../constants'

export const getInitialReviewsType = () => {
  const queryParam = getQueryParam('type')

  return REVIEW_TYPES.indexOf(queryParam) !== -1 ? queryParam : null
}

export const onInitialFiltration = cb => {
  const queryParam = getQueryParam('type')

  if (REVIEW_TYPES.indexOf(queryParam) !== -1) {
    cb(queryParam)
  }
}

export const setUrlReviewsType = type => {
  setQueryParam('type', type)
}

export const scrollToReviewsContainer = () => {
  const reviewsContainerElement = document.querySelector('.b-reviews-page__container')

  if (reviewsContainerElement) {
    setTimeout(() => scrollToElement(reviewsContainerElement))
  }
}

export const scrollToReviewOrReviewsContainer = () => {
  if (getQueryParam('rate_id')) {
    scrollToReviewsContainer()

    return
  }

  const [, reviewToScrollId] = window.location.hash.match(/^#rate-(\d+)/) || []

  if (!reviewToScrollId) {
    return
  }

  const reviewToScrollElement = document.querySelector(`[data-review-id="${reviewToScrollId}"]`)
  const isReviewToScrollExistsOrVisible = reviewToScrollElement && !reviewToScrollElement.classList.contains('d-none')

  if (!isReviewToScrollExistsOrVisible) {
    scrollToReviewsContainer()

    return
  }

  setTimeout(() => scrollToElement(reviewToScrollElement))
}
