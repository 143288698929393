export const BLOCK_NAME = 'b-review-card'

export const CLASS_NAMES = {
  body: `${BLOCK_NAME}__body`,
  replyBody: `${BLOCK_NAME}__reply-body`,
  authorLink: `${BLOCK_NAME}__author-link`,
  reply: `${BLOCK_NAME}__reply`,
  replyBodyMore: `${BLOCK_NAME}__reply-body-more`,
  replyBodyPartTruncated: `${BLOCK_NAME}__reply-body-part_truncated`,
}

export const REVIEW_OBJECT_TYPES = {
  doctor: 'doctor',
  lpu: 'lpu',
}

export const EVENT_NAMES = {
  reviewAjaxLoaded: 'review:ajax-loaded', // Вызывается после подгрузки отзывов по ajax (например при фильтрации)
}

/* Объект с уникальными идентификаторами просмотренных отзывов */
export const VIEWED_IDS = {
  [REVIEW_OBJECT_TYPES.doctor]: new Set(),
  [REVIEW_OBJECT_TYPES.lpu]: new Set(),
}

/* Путь по которому будет отправляться список идентификаторов */
export const SEND_VIEWED_IDS_PATHS = {
  prod: 'https://rate-metrics.prodoctorov.ru/hits/update-counters/',
  stage: 'https://rate-metrics-stage.prodoctorov.com/hits/update-counters/',
}

/* Путь по которому будет устанавливаться сессии */
export const SET_SESSION_PATHS = {
  prod: 'https://rate-metrics.prodoctorov.ru/set-session/',
  stage: 'https://rate-metrics-stage.prodoctorov.com/set-session/',
}

/* Время отображения отзыва во вьюпорте для учета его как просмотренный */
export const TIME_IN_VIEWPORT_MS = 2000

/* Настройки обсервера */
export const INTERSECTION_OBSERVER_OPTIONS = {
  /* Верхние и нижние 100px отзыва не учитываются в просмотре отзыва */
  rootMargin: '-100px 0px',
}
