var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "specialization-prices",
      staticClass: "mt-4 ui-text ui-text_body-2 my-2",
      attrs: { "data-qa": "specialization-prices" },
    },
    _vm._l(_vm.currentSpecializations, function (specialization, index) {
      return _c(
        "div",
        {
          key: `${specialization.name}-${index}`,
          staticClass: "mb-2",
          attrs: { "data-qa": "specialization-price" },
        },
        [
          _vm._v("\n    " + _vm._s(specialization.name) + "\n    "),
          specialization.ageRangeText
            ? _c("span", [
                _vm._v(
                  "\n      (" + _vm._s(specialization.ageRangeText) + ")\n    "
                ),
              ])
            : _vm._e(),
          _vm._v("\n    —\n    "),
          specialization.club &&
          _vm.isClubEnabled &&
          _vm.appointmentType === _vm.APPOINTMENT_TYPES.default
            ? _c("span", [
                _c("span", { staticClass: "ui-text ui-kit-color-text-error" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(specialization.club) +
                      _vm._s(_vm.currency.symbol) +
                      "\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "ui-text ui-text_line-through" }, [
                  _vm._v(
                    "\n        " +
                      _vm._s(specialization.default) +
                      _vm._s(_vm.currency.symbol) +
                      "\n      "
                  ),
                ]),
              ])
            : _c("span", [
                specialization[_vm.appointmentType]
                  ? _c("span", [
                      _vm._v(
                        "\n        " +
                          _vm._s(specialization[_vm.appointmentType]) +
                          _vm._s(_vm.currency.symbol) +
                          "\n      "
                      ),
                    ])
                  : _c("span", [_vm._v("\n        Бесплатно\n      ")]),
              ]),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }