import { createDevNotice } from 'utils'
// noinspection ES6CheckImport
import { APPOINTMENT_TYPES } from 'components/common/AppointmentTypeFilterList/constants'
import { NAMESPACE } from 'modules/DoctorCardEmitter/constants'

/**
 * @description
 * Производит переключение кнопки 'Вызвать врача на дом' на странице врача.
 * Поскольку на странице врача есть возможность фильтрации workplace врача - карточка с селектом специальностей и расписанием переключаются с помощью 'display: none'.
 * Функция умеет на основе 'lpuAddrId' и пространства имен переключать состояние кнопки 'Вызвать врача на дом'.
 * В этом ее недостаток - сильная привязка к пространству имен и разметке.
 *
 * @param { Object } payload
 * @param { HTMLElement } payload.cardContainer - Элемент карточки workplace врача
 * @param { String } payload.redirectValue - значение фильтра на момент вызова функции
 * */

const { home } = APPOINTMENT_TYPES
const hiddenClassName = NAMESPACE.scheduleWrapperDocItemHidden

const changeCardContent = payload => {
  try {
    const {
      cardContainer,
      redirectValue,
    } = payload

    if (!cardContainer) {
      return
    }

    const calendarContainerWrapperList = [...cardContainer.querySelectorAll(`.${NAMESPACE.scheduleWrapperDocItem}`)]

    calendarContainerWrapperList.forEach(container => {
      const dataRedirectValue = container.getAttribute(NAMESPACE.dataType)

      container.classList.add(hiddenClassName)

      // если выбран фильтр "на дом" и текущий элемент содержит такой же атрибут
      // или если выбран фильтр отличный от "на дом" и атрибут текущего элемента также отличен от "на дом"
      if ((redirectValue === home && dataRedirectValue === home)
            || (redirectValue !== home && dataRedirectValue !== home)
      ) {
        container.classList.remove(hiddenClassName)
      }
    })
  } catch ({ message }) {
    createDevNotice({
      module: 'changeCardContent',
      description: message,
    })
  }
}

export default changeCardContent
